import React, { useState, useEffect } from "react";
import moment from "moment";
import useWindowDimensions from "../../helpers/useWindowDimension";
import { connect, useDispatch } from "react-redux";

import * as actions from "../../redux/modules/Maintenances/actions";

import Spinner from "../../components/LoadingScreen";
import MaintenanceTable from "../../components/Table/maintenace";

import {
  PageHeader,
  Input,
  Alert,
  DatePicker,
  Button,
  Modal,
  Icon,
  Spin,
} from "antd";

import { AudioOutlined } from "@ant-design/icons";

import Scheduler from "./components/Scheduler";
import Admin_Categories from "./components/Categories";



const Maintenances = ({
  accountingPrepareRegistration,
  history,
}) => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [functionsAddVisible, setFunctionsAddVisible] = useState(false);
  const { width, height } = useWindowDimensions();

  const _urlParams = new URLSearchParams(window.location.search);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState({
    text_search: null,
    searchedItems: [],
    loading: false,
  });

  const functionsAdd = (bool) => {
    setFunctionsAddVisible(bool);
  };

  const [dateFilter, setDateFilter] = useState({
    to:
      _urlParams.get("endDate") ||
      moment(new Date()).add(1, "h").format("YYYY-MM-DD HH:mm"),
    from:
      _urlParams.get("startDate") ||
      moment(new Date()).subtract(30, "d").format("YYYY-MM-DD HH:mm"),
  });
 
  const getData = async (query_params, maintenance_type) => {
    dispatch({
      type: actions.GET_ORDERS,
      query_params: query_params,
    });

    history.push({
      search: `page=${page}&startDate=${query_params.from}&endDate=${query_params.to}`,
    });

    localStorage.setItem("startDate", query_params.from);
    localStorage.setItem("endDate", query_params.to);
  };

  useEffect(() => {
    // Getting query params
    const urlParams = new URLSearchParams(window.location.search);

    // Setting page from query params
    const myParam = urlParams.get("page");
    setPage(myParam == null ? 1 : myParam);

    // Setting date from query params

    console.log(urlParams.get("startDate"));

    getData({
      from: dateFilter.from,
      to: dateFilter.to,
    });
  }, []);

  const onOk = async (dateArr) => {
    setDateFilter({
      from: dateArr[0].format("YYYY-MM-DD HH:mm"),
      to: dateArr[1].format("YYYY-MM-DD HH:mm"),
    });
    getData({
      from: dateArr[0].format("YYYY-MM-DD HH:mm"),
      to: dateArr[1].format("YYYY-MM-DD HH:mm"),
    });
  };

  function onPageChange(pagination) {
    // Getting query params
    const urlParams = new URLSearchParams(window.location.search);

    history.push({
      search: `page=${pagination || 1}&startDate=${dateFilter.from}&endDate=${
        dateFilter.to
      }`,
    });

    // document.body.scrollTop = 0;
    // document.querySelector('body').scrollTo(0,0)
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  const toggleModal = () => {
    setIsModalVisible((previousState) => !previousState);
  };


 

  return (
    <>
      <PageHeader
        style={{ paddingTop: "0 !important" }}
        title={
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              // margin: "10px 0",
            }}
          >
            <h1 style={{ fontSize: 20, fontWeight: 600 }}>
              Povijest održavanja
            </h1>

            <div style={{ display: "flex" }}>
              <Button
                style={{ marginRight: 12 }}
                onClick={() => functionsAdd(true)}
                type="default"
              >
                Kategorija održavanja <Icon type="plus" />
              </Button>
              <Button onClick={toggleModal}>Kalendar</Button>
            </div>
          </div>
        }
      />


      <MaintenanceTable />



      <hr />

      <Modal
        className={"antdModal"}
        style={{ height: "81vh", overflow: "auto", maxWidth: "900px" }}
        visible={functionsAddVisible}
        onCancel={() => functionsAdd(false)}
        footer={null}
        children={
          <Admin_Categories
            categories={accountingPrepareRegistration.data.user_categories}
          />
        }
      />

      {/* Scheduler */}
      <Modal
        visible={isModalVisible}
        destroyOnClose={true}
        footer={null}
        width={width * 0.8}
        centered
        style={{ overflow: "hidden" }}
        closable={false}
        onCancel={toggleModal}
      >
        <Scheduler />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    accountingPrepareRegistration: state.accoutingReducer.prepareRegistration,
  };
};

export default connect(mapStateToProps, null)(Maintenances);
