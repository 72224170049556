import React, { useEffect, useState } from "react";

// UI
import css from "./warehouse.module.scss";
import {
  Row,
  Col,
  Table,
  Button,
  Input,
  message,
  Spin,
  Popconfirm,
} from "antd";
import Loading from "../../../../components/LoadingScreen";

// Components
import CriticalParts from "./components/Cards/CriticalParts";
import Information from "./components/Cards/PartsInformation";
import { useDispatch, useSelector } from "react-redux";
import {
  CONFIRM_PART_REMOVAL,
  GET_PARTS_BY_STATUS,
  WAITING_FOR_CONFIRMATION,
} from "../../../../redux/modules/Parts/actions";
import { StopOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default function Warehouse({ fromLocationParts = false }) {
  const dispatch = useDispatch();

  const { waitingForConfirmation, confirmPartRemoval, getPartsByStatus } = useSelector(
    (state) => state.partsReducer
  );

  const { mqtt_loading, user } = useSelector((state) => state.usersReducer);

  const {
    alerts: { criticalParts },
  } = useSelector((state) => state.dashboardReducer);

  // Variables
  const [type, setType] = useState("parts");
  const [search, set_search] = useState("");

  // Methods
  function handleCardClick(type) {
    setType(type);
  }

  // useEffect(() => {
  //   dispatch({
  //     type: WAITING_FOR_CONFIRMATION,
  //     errorCallback: () => {
  //       message.error("Dogodila se greška kod dohvata podataka", 3);
  //     },
  //   });
  // }, []);

  const tableData = waitingForConfirmation.data.map((x) => ({
    id: x.id,
    part: x.part,
    quantity: x.qty,
    order_name: x.work_order?.name || "-",
    asset: x.work_order?.asset?.name || "-",
    used_by: x.created_by.name,
    code: x.part.code,
  }));

  const COLUMNS = [
    {
      title: "Naziv",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <Link to={`/parts/part/${record.part.id}/about`}>{record.part.name}</Link>
        );
      },
    },
    {
      title: "Šifra",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Potrošena količina",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Nalog",
      dataIndex: "order_name",
      key: "order_name",
    },
    {
      title: "Imovina",
      dataIndex: "asset",
      key: "asset",
    },
    {
      title: "Skinuo",
      dataIndex: "used_by",
      key: "used_by",
    },
    {
      title: "Potvrdi",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <>
          {" "}
          <Button
            style={{ margin: 3 }}
            type="primary"
            onClick={() => {
              dispatch({
                type: CONFIRM_PART_REMOVAL,
                data: {
                  id: record.id,
                  confirm: true,
                },
                errorCallback: () => {
                  message.error("Dogodila se greška!");
                },
              });
            }}
          >
            {" "}
            Potvrdi{" "}
          </Button>{" "}
          <Popconfirm
            title="Potvrdi ovu akciju?"
            onConfirm={() => {
              dispatch({
                type: CONFIRM_PART_REMOVAL,
                data: {
                  id: record.id,
                  confirm: false,
                },
                errorCallback: () => {
                  message.error("Dogodila se greška!");
                },
              });
            }}
            okText="Da"
            cancelText="Ne"
          >
            <Button type="danger" style={{ margin: 3 }}>
              <StopOutlined />
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  let filtredData = [];
  if (search) {
    filtredData = tableData.filter((x) =>
      (x.part.name + x.code + x.order_name + x.used_by)
        .toLowerCase()
        .includes(search.toLocaleLowerCase())
    );
  } else {
    filtredData = tableData;
  }

  console.log(waitingForConfirmation);

  return (
    <div id={fromLocationParts ? "none" : css.warehouseContainer}>
      {/* Header */}
      {!fromLocationParts && (
        <div
          className={css.header}
          style={{ marginLeft: -10, marginTop: -20, marginBottom: 32 }}
        >
          <p className={css.value}>Dashboard</p>
        </div>
      )}
      {/* //? Main row */}
      <Row gutter={[24, 24]}>
        {/* //! Cards */}
        {!fromLocationParts && (
          <Row gutter={[24, 24]}>
            {/* //* Dijelovi na niskoj zalihi */}
            <Col
              style={{ display: (user.data.category === "MAN" || user.data.category === "LDR") ? "none" : null }}
              md={24}
              lg={10}
              xl={8}
            >
              {!fromLocationParts && (
                <div className={"hover"}>
                  <CriticalParts
                    activeCard={type === "criticalParts"}
                    label="Dijelovi na niskoj zalihi"
                    information={criticalParts.length}
                    onClick={() => handleCardClick("criticalParts")}
                  />
                </div>
              )}
            </Col>
            {/* //* Utrošeni dijelovi na održavanju */}
            <Col md={24} lg={10} xl={8}>
              <div className={"hover"}>
                <Information
                  activeCard={type === "parts"}
                  label="Utrošeni dijelovi na održavanju"
                  information={waitingForConfirmation.data.length || 0}
                  onClick={() => handleCardClick("parts")}
                />
              </div>
            </Col>
          </Row>
        )}

        {/* //! Search */}
        <Row gutter={[24, 24]}>
          <Row gutter={[0, 24]} style={{ marginTop: 16 }}>
            <Col span={18}>
              {!fromLocationParts && (
                <div className={css.header} style={{ marginLeft: 10 }}>
                  <p className={css.value}>
                    {type === "parts"
                      ? "Pregled dijelova"
                      : "Pregled dijelova na niskoj zalihi"}
                  </p>
                </div>
              )}
            </Col>
            <Col span={6}>
              <div style={{ paddingRight: 12 }}>
                <Input.Search
                  style={{ display: "block" }}
                  allowClear={true}
                  placeholder="Unesi naziv"
                  enterButton
                  size="default"
                  defaultValue={""}
                  onPressEnter={(event) => set_search(event.target.value)}
                  onSearch={(v) => set_search(v)}
                />
              </div>
            </Col>
          </Row>
        </Row>

        {/* //! Table */}
        <Row gutter={[24, 24]}>
          {/* //* Table */}
          <Col span={24}>
            <Spin
              spinning={
                confirmPartRemoval.status === "loading" ||
                getPartsByStatus.status === "loading"
              }
            >
              <Table
                rowKey={(x) => x.id}
                columns={COLUMNS}
                dataSource={filtredData}
              />
            </Spin>
          </Col>
        </Row>
      </Row>
    </div>
  );
}

function total(arr) {
  if (!Array.isArray(arr)) return;
  let totalNumber = 0;
  for (let i = 0, l = arr.length; i < l; i++) {
    totalNumber += arr[i].quantity;
  }
  return totalNumber;
}
