import { takeLatest, put, call, select, delay } from "redux-saga/effects";

import * as actions from "./actions";

import axios_items from "../../../axios/axios-items";
import { LOGOUT } from "../Users/actions";


const ENDPOINTS_MATERIAL = [
  { type: "video", topic: "add_videos" },
  { type: "document", topic: "add_documents" },
  { type: "image", topic: "add_images" },
];
const ENDPOINTS_MATERIAL_DELETE = [
  { type: "video", topic: "remove_video" },
  { type: "document", topic: "remove_document" },
  { type: "image", topic: "remove_image" },
];

const initialState = {
  machine: {
    status: "",
    data: {},
  },
  updateMachine: {
    status: "",
  },
  deleteMachine: {
    status: "",
  },
  material: {
    status: "",
  },
  deleteMaterial: {
    status: "",
  },
  subassets: [],

};

export default function reducer(state = initialState, action = {}) {
  var tmp;
  var index = -1;
  switch (action.type) {

    case actions.SET_SUBASSET:
      return {
        ...state,
        subassets: action.data
      }

    case actions.GET_MACHINE:
      return {
        ...state,
        machine: {
          status: "loading",
          data: {},
        },
      };
    case actions.GET_MACHINE_SUCCESS:
      return {
        ...state,
        machine: { status: "", data: action.data },
      };
    case actions.GET_MACHINE_FAIL:
      return {
        ...state,
        machine: {
          status: "",
          data: {},
        },
      };
    case actions.UPDATE_MACHINE:
      return {
        ...state,
        updateMachine: { status: "loading" },
      };

    case actions.UPDATE_MACHINE_SUCCESS:
      return {
        ...state,
        updateMachine: { status: "" },
        machine: {
          status: "",
          data: action.data,
        },
      };

    case actions.UPDATE_MACHINE_FAIL:
      return {
        ...state,
        updateMachine: { status: "error" },
      };

    case actions.DELETE_MACHINE:
      return {
        ...state,
        deleteMachine: {
          status: "loading",
        },
      };

    case actions.DELETE_MACHINE_SUCCESS:
      return {
        ...state,
        deleteMachine: {
          status: "",
        },
      };

    case actions.DELETE_MACHINE_FAIL:
      return {
        ...state,
        deleteMachine: {
          status: "error",
        },
      };

    case actions.ADD_MATERIAL:
      return {
        ...state,
        material: { status: "loading" },
      };

    case actions.ADD_MATERIAL_SUCCESS:
      return {
        ...state,
        material: { status: "" },
        machine: {
          data: action.data,
          status: "",
        },
      };

    case actions.ADD_MATERIAL_FAIL:
      return {
        ...state,
        material: { status: "error" },
      };

    case actions.DELETE_MATERIAL:
      return {
        ...state,
        deleteMaterial: { status: action.material_id + action.topic_type },
      };

    case actions.DELETE_MATERIAL_SUCCESS:
      return {
        ...state,
        deleteMaterial: { status: "" },
        machine: {
          data: action.data,
          status: "",
        },
      };

    case actions.DELETE_MATERIAL_FAIL:
      return {
        ...state,
        deleteMaterial: { status: "error" },
      };

    default:
      return {
        ...state,
      };
  }
}

function transformData(data) {
  return data.data;
}

const authToken = () => localStorage.getItem("token");

//Get machiner
export function* watcherGetMachine() {
  yield takeLatest(actions.GET_MACHINE, getMachine);
}

function _getMachine(options) {
  return axios_items(options).get(`items/${options.id}/`);
}

function* getMachine(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      id: payload.machine_id,
    };
    const response = yield call(_getMachine, options);
    const data = transformData(response);
    yield put({
      type: actions.GET_MACHINE_SUCCESS,
      data,
    });

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.GET_MACHINE_FAIL, error });
  }
}
//get machine end

//update machine
export function* watcherUpdateMachine() {
  yield takeLatest(actions.UPDATE_MACHINE, updateMachine);
}

function _updateMachine(options) {
  return axios_items(options).patch(`items/${options.id}/`, options.data);
}

function* updateMachine(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
      id: payload.id,
    };
    const response = yield call(_updateMachine, options);
    const data = transformData(response);
    yield put({
      type: actions.UPDATE_MACHINE_SUCCESS,
      data: data.order,
      id: options.id,
    });

    yield put({
      type: actions.GET_MACHINES_SUCCESS,
      data: data.list,
    });

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.UPDATE_MACHINE_FAIL, error });
  }
}
//update machine end

// Delete machine
export function* watcherDeleteMachine() {
  yield takeLatest(actions.DELETE_MACHINE, deleteMachine);
}

function _deleteMachine(options) {
  return axios_items(options).patch(`items/${options.id}/`, {
    is_active: false,
  });
}

function* deleteMachine(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      id: payload.id,
    };
    const response = yield call(_deleteMachine, options);
    const data = transformData(response);
    yield put({
      type: actions.DELETE_MACHINE_SUCCESS,
      id: options.id,
    });

    yield put({
      type: actions.GET_MACHINES_SUCCESS,
      data: data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (payload.errorCallback) {
      payload.errorCallback();
    }
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.DELETE_MACHINE_FAIL, error });
  }
}
// Delete machine

//add material
export function* watcherAddMaterial() {
  yield takeLatest(actions.ADD_MATERIAL, addMaterial);
}

function _addMaterial(options) {
  const topic = ENDPOINTS_MATERIAL.find((item) => item.type === options.type)
    .topic;
  return axios_items(options).post(
    `items/${options.id}/${topic}/`,
    options.data
  );
}

function* addMaterial(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
      type: payload.topic_type,
      id: payload.item_id,
    };
    const response = yield call(_addMaterial, options);
    const data = transformData(response);
    yield put({
      type: actions.ADD_MATERIAL_SUCCESS,
      data,
    });

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.ADD_MATERIAL_FAIL, error });
  }
}
//add material

//add material
export function* watcherDeleteMaterial() {
  yield takeLatest(actions.DELETE_MATERIAL, deleteMaterial);
}

function _deleteMaterial(options) {
  const topic = ENDPOINTS_MATERIAL_DELETE.find(
    (item) => item.type === options.type
  ).topic;
  return axios_items(options).put(
    `items/${options.id}/${topic}/`,
    options.data
  );
}

function* deleteMaterial(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: { material_id: payload.material_id },
      type: payload.topic_type,
      id: payload.item_id,
    };
    const response = yield call(_deleteMaterial, options);
    const data = transformData(response);
    yield put({
      type: actions.DELETE_MATERIAL_SUCCESS,
      data,
    });

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.DELETE_MATERIAL_FAIL, error });
  }
}
//add material
