import { takeLatest, put, call, select, delay } from "redux-saga/effects";
import * as actions from "./actions";
import store from "../../../store";

import axios_maintenances from "../../../axios/axios-maintenances";
import { DESTROY_TOKEN_FOR_REDIRECT, LOGOUT } from "../Users/actions";

const initialState = {
  order: {
    data: {},
    status: "loading",
  },
  updateWorkOrder: {
    data: {},
    status: "",
  },
  updateReport: {
    status: "",
  },
  deleteReport: {
    status: "",
  },
  inBowl: {
    status: "",
    data: {},
  },
};

export default function reducer(state = initialState, action = {}) {
  var tmp;
  var index = -1;

  switch (action.type) {

   

    case actions.GET_ORDER:
      return {
        ...state,
        order: {
          data: {},
          status: "loading",
        },
      };

    case actions.GET_ORDER_SUCCESS:
      return {
        ...state,
        order: {
          data: action.data,
          status: "",
        },
      };

    case actions.GET_ORDER_FAIL:
      return {
        ...state,
        order: {
          data: {},
          status: "error",
        },
      };

    case actions.UPDATE_WORK_ORDER:
      return {
        ...state,
        updateWorkOrder: {
          data: {},
          status: "loading",
        },
      };

    case actions.UPDATE_WORK_ORDER_SUCCESS:
      return {
        ...state,
        updateWorkOrder: {
          data: {},
          status: "",
        },
        order: {
          data: action.data,
          status: "",
        },
      };

    case actions.UPDATE_WORK_ORDER_FAIL:
      return {
        ...state,
        updateWorkOrder: {
          data: {},
          status: "error",
        },
      };

    case actions.UPDATE_REPORT:
      return {
        ...state,
        updateReport: {
          status: "loading",
        },
      };

    case actions.UPDATE_REPORT_SUCCESS:
      return {
        ...state,
        updateReport: {
          status: "",
        },
      };

    case actions.UPDATE_REPORT_FAIL:
      return {
        ...state,
        updateReport: {
          status: "error",
        },
      };

    case actions.DELETE_REPORT:
      return {
        ...state,
        deleteReport: {
          status: action.report_id,
        },
      };

    case actions.DELETE_REPORT_SUCCESS:
      return {
        ...state,
        deleteReport: {
          status: "",
        },
      };

    case actions.DELETE_REPORT_FAIL:
      return {
        ...state,
        deleteReport: {
          status: "error",
        },
      };

    case actions.IN_BOWL:
      return {
        ...state,
        inBowl: {
          status: "loading",
          data: {},
          message: "",
        },
      };

    case actions.IN_BOWL_SUCCESS:
      tmp = { ...state.order.data };
      return {
        ...state,
        inBowl: {
          status: "",
          data: action.data,
          message: action.message,
        },
        order: {
          status: "",
          data: action.data,
        },
      };

    case actions.IN_BOWL_FAIL:
      return {
        ...state,
        inBowl: {
          status: "error",
          data: {},
          message: action.message,
        },
      };

    default:
      return state;
  }
}

function transformData(data) {
  return data.data;
}

const authToken = () => localStorage.getItem("token");

//get work order
export function* watcherGetWorkOrder() {
  yield takeLatest(actions.GET_ORDER, getWorkOrder);
}

function _getWorkOrder(options) {
  return axios_maintenances(options).get(`work_orders/${options.order_id}/`);
}

function* getWorkOrder(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      order_id: payload.order_id,
    };
    const response = yield call(_getWorkOrder, options);
    const data = transformData(response);
    yield put({
      type: actions.GET_ORDER_SUCCESS,
      data,
    });

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: DESTROY_TOKEN_FOR_REDIRECT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.GET_ORDER_FAIL, error });
  }
}
//get work order end

//update work order
export function* watcherUpdateWorkOrder() {
  yield takeLatest(actions.UPDATE_WORK_ORDER, updateWorkOrder);
}

function _updateWorkOrder(options) {
  console.log(options);
  return axios_maintenances(options).put(
    `work_orders/${options.order_id}/`,
    options.data
  );
}

function* updateWorkOrder(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      order_id: payload.order_id,
      data: payload.data,
    };
    const response = yield call(_updateWorkOrder, options);
    const data = transformData(response);
    yield put({
      type: actions.UPDATE_WORK_ORDER_SUCCESS,
      data,
    });

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: DESTROY_TOKEN_FOR_REDIRECT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.UPDATE_WORK_ORDER_FAIL, error });
  }
}
//update work order end

// Updatae report
export function* watcherUpdateReport() {
  yield takeLatest(actions.UPDATE_REPORT, updateReport);
}

function _updateReport(options) {
  console.log(options);
  return axios_maintenances(options).patch(`reports/${options.report_id}/`, {
    text: options.data,
  });
}

function* updateReport(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      report_id: payload.report_id,
      data: payload.data,
    };
    const response = yield call(_updateReport, options);
    const data = transformData(response);


    let obj = { ...store.getState().maintenanceReducer.order.data };
    let indexOfReport = obj.reports.findIndex(x => x.id === payload.report_id);
    let arrTmp = [...obj.reports];
    arrTmp[indexOfReport] = data;
    obj.reports = arrTmp;

    // Ne znam, nisam pametan
    yield put({
      type: actions.UPDATE_WORK_ORDER_SUCCESS,
      order_id: payload.order_id,
      data: obj,
    });

    yield put({
      type: actions.UPDATE_REPORT_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);

    if (payload.errorCallback) {
      payload.errorCallback();
    }

    if (error.response && error.response.status === 401) {
      yield put({ type: DESTROY_TOKEN_FOR_REDIRECT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.UPDATE_REPORT_FAIL, error });
  }
}
// Update report

// Delete report
export function* watcherDeleteReport() {
  yield takeLatest(actions.DELETE_REPORT, deleteReport);
}

function _deleteReport(options) {
  console.log(options);
  return axios_maintenances(options).patch(`reports/${options.report_id}/`, {
    is_active: false,
  });
}

function* deleteReport(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      report_id: payload.report_id,
    };
    const response = yield call(_deleteReport, options);
    const data = transformData(response);

    let obj = { ...store.getState().maintenanceReducer.order.data };
    let indexOfReport = obj.reports.findIndex(x => x.id === payload.report_id);
    let arrTmp = [...obj.reports];
    arrTmp.splice(indexOfReport,1);
    obj.reports = arrTmp;

    yield put({
      type: actions.UPDATE_WORK_ORDER_SUCCESS,
      order_id: payload.order_id,
      data: obj,
    });

    yield put({
      type: actions.DELETE_REPORT_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);

    if (payload.errorCallback) {
      payload.errorCallback();
    }

    if (error.response && error.response.status === 401) {
      yield put({ type: DESTROY_TOKEN_FOR_REDIRECT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.DELETE_REPORT_FAIL, error });
  }
}
// Delete report

// inBowl
export function* watcher_inBowl() {
  yield takeLatest(actions.IN_BOWL, inBowl);
}

function _inBowl(options) {
  return axios_maintenances(options).post(
    `work_orders/${options.id}/move_in_bowl/`,
    options.data
  );
}

function* inBowl(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
      id: payload.id,
    };
    const response = yield call(_inBowl, options);
    const data = transformData(response);
    yield put({
      type: actions.IN_BOWL_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.IN_BOWL_FAIL, error, message: "" });
  }
}
//inBowl END
