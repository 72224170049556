import React from "react";
import css from "../../../assets.module.scss";

// Chart
import { Line } from "react-chartjs-2";

export default function LineChart({ data = [], labels = [], title = "Graf", color = "purple", interval_type }) {
  const colors = ["#003f5c", "#955196", "#ff6e54"];

  function dataSource(canvas) {
    // Gradient
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 392);
    gradient.addColorStop(0, color === "purple" ? "#BD9BBE" : "#F2AA9D");
    gradient.addColorStop(0.7, "rgba(255, 255, 255, 0.1)");
    gradient.addColorStop(0.9, "rgba(255, 255, 255, 0)");
    gradient.addColorStop(1, "rgba(255, 255, 255, 0)");

    // Dat
    return {
      labels,
      datasets: [
        {
          label: ` ${title}`,
          fill: "start",
          backgroundColor: gradient, // Put the gradient here as a fill color
          borderColor: color === "purple" ? "#955196" : "#ff6e54",
          borderWidth: 2,
          pointBackgroundColor: "#FFFFFF",
          pointBorderWidth: 0,
          data,
        },
      ],
    };
  }

  // Options
  let options = {
    maintainAspectRatio: false,
    responsive: true,
    datasetStrokeWidth: 3,
    pointDotStrokeWidth: 4,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        labels: {
          // This more specific font property overrides the global property
          font: {
            size: 24,
          },
        },
      },
    },
    scales: {
      yAxes: [
        {
          stacked: true,
          gridLines: {
            display: true,
            borderDash: [8, 4],
            color: "#dedede",
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: true,
            borderDash: [8, 4],
            color: "#f2f2f2",
          },
          type: "time",
          time: {
            unit: interval_type,
          },
        },
      ],
    },
  };

  return <Line data={dataSource} options={options} />;
}
