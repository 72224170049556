import React, { Component, useState } from "react";

import { Form, Button, Col, Row, Input, Alert, Select, TreeSelect } from "antd";

import SizeType from "../../../../helpers/deviceTypeSize";
import nestedSorting from "../../../../helpers/NestedSorting";

import { useDispatch, useSelector } from "react-redux";
import {
  SAVE_MACHINE_CATEGORY,
  UPDATE_MACHINE_CATEGORY,
} from "../../../../redux/modules/Machines/actions";

import {getItemsWithouChilds} from "../../../../helpers/getitemsWithouChilds";

const { TextArea } = Input;
const { Option } = Select;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

const CategoryForm = ({ update, updateData, form, set_updateData }) => {
  const [alertSuccessVisible, set_alertSuccessVisible] = useState();

  const dispatch = useDispatch();

  const showSuccess = () => {
    set_alertSuccessVisible(true);
    form.resetFields();
    set_updateData(null);
    setTimeout(() => {
      set_alertSuccessVisible(false);
      form.resetFields();
    }, 3000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        if (update) {
          dispatch({
            type: UPDATE_MACHINE_CATEGORY,
            id: updateData.id,
            data: values,
            successCallback: showSuccess,
          });
          return;
        }

        dispatch({
          type: SAVE_MACHINE_CATEGORY,
          data: values,
          successCallback: showSuccess,
        });
        // spremi novo
      }
    });
  };

  const { getFieldDecorator, getFieldsError } = form;

  const {
    prepareRegistration: {
      data: { categories },
    },
    saveMachineFunction,
    updateMachineType, // type, category, function all the same
  } = useSelector((state) => state.machinesReducer);

  const alert = (
    <Alert
      style={{ marginBottom: "10px", zIndex: "1" }}
      message="Dogodila se greša"
      description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
      type="error"
    />
  );

  const alertSuccess = (
    <Alert
      style={{ marginBottom: "10px", zIndex: "1" }}
      message="Uspješno"
      description={`Uspješno izvršeno!`}
      type="success"
    />
  );

  const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol:
      SizeType() === "xs" || SizeType() === "sm"
        ? {
            xs: {
              span: 24,
              offset: 0,
            },
            sm: {
              span: 16,
              offset: 0,
            },
          }
        : {
            span: 18,
            offset: 0,
          },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 24,
        offset: 0,
      },
    },
  };

  const layoutType =
    SizeType() === "xs" || SizeType() === "sm" ? "vertical" : "horizontal";

  // type, functions, categories sve isto zbog prelaska

  return (
    <React.Fragment>
      {saveMachineFunction.status === "error" || updateMachineType === "error"
        ? alert
        : null}
      {alertSuccessVisible ? (
        alertSuccess
      ) : (
        <Form
          {...formItemLayout}
          layout={layoutType}
          hideRequiredMark
          onSubmit={handleSubmit}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item className={"modal-header"} label="Nadkategorija">
                {getFieldDecorator("parent", {
                  initialValue: update ? updateData.parent : null,
                })(
                  <TreeSelect
                    mode="single"
                    allowClear={true}
                    treeData={nestedSorting(
                      getItemsWithouChilds(
                        categories,
                        update ? updateData.id : null,
                        "parent"
                      ),
                      "parent"
                    )}
                    showSearch
                    placeholder="Odaberi nadkategoriju"
                    optionFilterProp="name"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  />
                )}
              </Form.Item>

              <Form.Item className={"modal-header"} label={"Kategorija"}>
                {getFieldDecorator("name", {
                  initialValue: update ? updateData.name : "",
                  rules: [
                    { required: true, message: "Unesite tekst!" },
                    { min: 3, message: "Minimalno znakova 3!" },
                  ],
                })(<TextArea />)}
              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <Button
                  style={{ float: "right" }}
                  loading={
                    saveMachineFunction.status === "loading" ||
                    updateMachineType.status === "loading"
                  }
                  type="primary"
                  htmlType="submit"
                  disabled={hasErrors(getFieldsError())}
                >
                  Spremi
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </React.Fragment>
  );
};

export default Form.create()(CategoryForm);
