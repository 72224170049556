import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/hr";
import SizeType from "../../helpers/deviceTypeSize";

import {
  Table,
  Button,
  Icon,
  Typography,
  Tabs,
  TreeSelect,
  DatePicker,
  Spin,
  message,
  Select,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import { JURE_FORMAT_DATE, STATUS } from "../../helpers/consts";
import { connect, useDispatch, useSelector } from "react-redux";
import { GET_MACHINES } from "../../redux/modules/Machines/actions";
import { GET_ORDERS_V2 } from "../../redux/modules/Maintenances/actions";
import nestedSorting from "../../helpers/NestedSorting";
import { CONTROL_ID } from "../../AppWrapper";
const { RangePicker } = DatePicker;
const LIMIT = 45;

const MaintenancesTable = ({
  machine_id,
  accountProfile,
  hideMachineColumn,
  prepareRegistration,
  orders,
}) => {
  const [filteredInfo, set_filteredInfo] = useState({
    status: null,
    category: null,
    type: null,
    name: null,
  });
  const [activeTabKey, set_activeTabKey] = useState("2|3");
  const [selectedAsset, set_selectedAsset] = useState([]);
  const { machines } = useSelector((state) => state.machinesReducer);

  const [LOAD_MORE_PARAMS, set_LOAD_MORE_PARAMS] = useState({
    limit: LIMIT,
    offset: 0,
  });

  const [hasLoadMore, setHasLoadMore] = useState(true);

  const [filters, setFilters] = useState({
    maintenance_categories: [],
    account: accountProfile,
    maintenance_types: activeTabKey,
    assets: machine_id ? [machine_id] : [],
    from: moment().subtract(6, "months"),
    to: moment(),
  });

  const [COLUMNS, set_COLUMNS] = useState([
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Opis",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => (
        <Link to={`/maintenances/${record.id}/about`}>{text}</Link>
      ),
    },

    // Hide machine column in maintenances table on machines page
    ...(!hideMachineColumn
      ? [
          {
            title: "Imovina",
            dataIndex: "items",
            key: "items",
            ellipsis: true,
          },
        ]
      : []),
    {
      title: "Tip",
      dataIndex: "type",
      key: "type",
      render: (text, value) => value.type?.name,
      ellipsis: true,
    },
    {
      title: "Kategorija",
      dataIndex: "category",
      key: "category",
      ellipsis: true,
    },

    {
      title: "Tehničari",
      dataIndex: "technicians",
      key: "technicians",
      render: (text, value) => (
        <div>
          <div style={{ fontSize: 12 }}>{value.technicians}</div>
          <div style={{ fontSize: 12 }}>
            {value.real_end
              ? moment(value.real_end).format(JURE_FORMAT_DATE)
              : null}
          </div>
        </div>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
    },
    {
      title: "Prijavio",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record, index) => {
        return (
          <div>
            <div style={{ fontSize: 12 }}>{record.creator}</div>
            <div style={{ fontSize: 12 }}>{text}</div>
          </div>
        );
      },
    },

    {
      title: "Potvrdio",
      dataIndex: "closed_by",
      key: "closed_by",
      render: (text, value) => (
        <div>
          <div style={{ fontSize: 12 }}>{text}</div>
          <div style={{ fontSize: 12 }}>
            {value.closed_at
              ? moment(value.closed_at).format(JURE_FORMAT_DATE)
              : null}
          </div>
        </div>
      ),
    },
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    setActiveTab();
    getWorkOrders_v2(filters, LOAD_MORE_PARAMS, false);
  }, []);

  useEffect(() => {
    if (!window.location.href.includes("machines")) {
      // ako nije na stranici masina
      dispatch({ type: GET_MACHINES });
    }
  }, []);

  const setActiveTab = async () => {
    let tmp = await localStorage.getItem("lastMaintenanceTab");
    if (tmp) {
      set_activeTabKey(tmp);
    }
  };

  const getWorkOrders_v2 = (_filters, loadMoreParams, mergeData) => {
    if (!mergeData) {
      setHasLoadMore(true);
    }
    setFilters(_filters);
    dispatch({
      type: GET_ORDERS_V2,
      data: {
        queryParams: {
          maintenance_types: _filters?.maintenance_types || activeTabKey,
          maintenance_categories:
            _filters?.maintenance_categories.join("|") || [],
          assets: _filters?.assets.join("|") || [],
          limit: loadMoreParams?.limit || LIMIT,
          offset: loadMoreParams?.offset || 0,
          accounts: _filters?.account,
          from: _filters?.from.format("YYYY-MM-DD HH:mm"),
          to: _filters?.to.format("YYYY-MM-DD HH:mm"),
        },
        mergeData: mergeData,
        errorCallback: () => {
          message.error("Došlo je do greške prilikom dohvaćanja podataka");
        },
        successCallback: (data) => {
          if (data.length < LIMIT) {
            setHasLoadMore(false);
          }
          // console.log("success");
        },
      },
    });
  };

  const handleChange = (pagination, filters, type, extra) => {
    console.log(filters);
    //if type === neki tip filter
    const _filteredInfo = { ...filteredInfo };
    _filteredInfo.category = filters?.category;
    _filteredInfo.status = filters?.status;
    _filteredInfo.type = filters?.type;
    _filteredInfo.name = filters?.name;
    _filteredInfo.items = filters?.items;
    set_filteredInfo(_filteredInfo);
    window.localStorage.setItem(
      "maintenances_filters",
      JSON.stringify(_filteredInfo)
    );
  };

  const clearFilters = () => {
    const filteredInfo = {
      category: null,
      type: null,
      status: null,
      items: null,
    };
    set_filteredInfo(filteredInfo);
    window.localStorage.setItem(
      "maintenances_filters",
      JSON.stringify(filteredInfo)
    );
  };

  const tableData = StructOrdersForTable(
    orders.data.filter((x) => !!x.items.length)
  );

  console.log(tableData)

  const handleSelect = (value, node) => {
    let asset = [...selectedAsset];

    if (value.length === 0) {
      set_selectedAsset([]);
      getWorkOrders_v2({ ...filters, assets: [] }, LOAD_MORE_PARAMS, false);
      return;
    } else {
      let missing = findMissingItem(value, selectedAsset);
      if (missing !== null) {
        //adding
        let children = getAllChildrenForItemId(missing, machines.data);
        asset = removeDuplicates([...asset, ...children, missing]);
        set_selectedAsset(removeDuplicates([...value, ...children, missing]));
      } else {
        //removing
        let toRemove = findMissingItem(selectedAsset, value);
        asset = selectedAsset.filter((x) => x !== toRemove);
        set_selectedAsset(selectedAsset.filter((x) => x !== toRemove));
      }
    }

    getWorkOrders_v2({ ...filters, assets: asset }, LOAD_MORE_PARAMS, false);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px 0",
          paddingTop: accountProfile ? 0 : 16,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <RangePicker
            format={JURE_FORMAT_DATE}
            defaultValue={[filters.from, filters.to]}
            onOk={(a) => {
              getWorkOrders_v2(
                {
                  ...filters,
                  from: a[0],
                  to: a[1],
                },
                LOAD_MORE_PARAMS,
                false
              );
            }}
            showTime
            style={{ width: "300px" }}
          />
          <div style={{ marginTop: -2 }}>
            {!machine_id && <TreeSelect
              
              placeholder="Imovina"
              style={{ width: 300, marginLeft: 15 }}
              showSearch
              value={selectedAsset || []}
              multiple={true}
              allowClear={true}
              treeDefaultExpandAll={true}
              showCheckedStrategy={TreeSelect.SHOW_PARENT}
              onChange={handleSelect}
              treeData={nestedSorting(machines.data, "parent")}
              optionFilterProp="title"
              filterTreeNode={(input, option) => {
                return (
                  option.props.name
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }}
            />}
          </div>
          <div style={{ marginTop: -2 }}>
            <Select
              placeholder="Kategorije"
              style={{ width: 300, marginLeft: 15 }}
              showSearch
              defaultValue={filters.maintenance_categories || []}
              mode="multiple"
              allowClear={true}
              onChange={(a) => {
                getWorkOrders_v2({...filters, maintenance_categories: a}, LOAD_MORE_PARAMS, false);
              }}
              optionFilterProp="title"
              filterTreeNode={(input, option) => {
                return (
                  option.props.name
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }}
            >
              {prepareRegistration.data.maintenance_categories.map((item) => (
                <Select.Option key={item.id} value={item.id} name={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          {/* <div style={{ marginTop: -2 }}>{props.search && props.search()}</div>
          <div style={{ marginTop: -2 }}>{props.button && props.button()}</div> */}
        </div>
      </div>

      <Tabs
        activeKey={activeTabKey}
        onTabClick={(a) => {
          set_activeTabKey(a);
          getWorkOrders_v2(
            {
              ...filters,
              maintenance_types: a,
            },
            LOAD_MORE_PARAMS,
            false
          );
        }}
      >
        <Tabs.TabPane tab="Kvarovi" key="2|3">
          {/* <Typography.Title level={4}>Kvarovi</Typography.Title> */}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Preventivna" key="1">
          {/* <Typography.Title level={4}>Preventivna</Typography.Title> */}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Kontrole" key="4">
          {/* <Typography.Title level={4}>Kontrole</Typography.Title>s */}
        </Tabs.TabPane>
      </Tabs>
      <Spin spinning={orders.status === "loading"}>
        <Table
          size="small"
          pagination={false}
          rowKey={(record) => record.id}
          scroll={{
            x: SizeType() === "xs" || SizeType() === "sm" ? true : false,
          }}
          dataSource={tableData}
          columns={COLUMNS}
          onChange={handleChange}
        />
        {hasLoadMore && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{ marginTop: 10, width: 320, marginBottom: 20 }}
              type="dashed"
              onClick={() => {
                getWorkOrders_v2(
                  filters,
                  {
                    limit: LIMIT,
                    offset: orders.data.length,
                  },
                  true
                );
              }}
            >
              Učitaj još
            </Button>
          </div>
        )}
      </Spin>
    </>
  );
};
function removeDuplicates(arr) {
  const uniqueArray = [];

  arr.forEach((item) => {
    if (!uniqueArray.includes(item)) {
      uniqueArray.push(item);
    }
  });

  return uniqueArray;
}

function findMissingItem(array1, array2) {
  for (let i = 0; i < array1.length; i++) {
    if (!array2.includes(array1[i])) {
      return array1[i];
    }
  }
  // If the loop completes without finding a missing item, return null or handle it accordingly.
  return null;
}

function getAllChildrenForItemId(itemId, itemList) {
  const childrenMap = {};
  const resultChildren = [];

  itemList.forEach((listItem) => {
    if (listItem.parent_id !== null) {
      if (!childrenMap[listItem.parent_id]) {
        childrenMap[listItem.parent_id] = [];
      }
      childrenMap[listItem.parent_id].push(listItem);
    }
  });

  function traverse(id) {
    const children = childrenMap[id] || [];
    children.forEach((child) => {
      resultChildren.push(child.id);
      traverse(child.id);
    });
  }

  traverse(itemId);

  return resultChildren;
}

const getFilters = (data, key) => {
  switch (key) {
    case "category":
      return data.map((item) => ({
        text: item.name,
        value: item.name,
      }));
    case "type":
      return data.map((item) => ({ text: item.name, value: item.name }));
    case "status":
      return STATUS.map((item) => ({ text: item.text, value: item.text }));
    case "name":
      let tmp_arr = [];

      data.map((item) => {
        if (!isExsitsName(item, tmp_arr)) {
          tmp_arr.push({ text: item.name, value: item.name });
        }
      });
      return tmp_arr;
    case "items":
      let uniqueArray = [];
      uniqueArray = data.filter(function (item, pos, self) {
        return self.indexOf(item) == pos;
      });
      return uniqueArray.map((name) => ({ text: name, value: name }));
    case "technicians":
      let technicians_uniqueArray = [];
      technicians_uniqueArray = data.filter(function (item, pos, self) {
        return self.indexOf(item) == pos;
      });
      return technicians_uniqueArray.map((name) => ({
        text: name,
        value: name,
      }));
  }
  // let categoryFilter = [{ text: "error", value: "error" }];
  // return categoryFilter;
};

const isExsitsName = (item, data) => {
  for (let i = 0; i < data.length; i++) {
    if (item.name === data[i].text) {
      return true;
    }
  }
  return false;
};

const StructOrdersForTable = (data) => {
  const dataSource = [];

  data.map((item) => {
    dataSource.push({
      id: item.id,
      name: item.name,
      created_at: moment(item.created_at).format(JURE_FORMAT_DATE),
      category:
        item.categories && item.categories.length
          ? item.categories.map((item) => item.name + " ")
          : "-",
      type: item.type,
      real_end: item.real_end,
      technicians:
        item.technicians.length > 0
          ? item.technicians.map((item) => item.account.full_name).join(",")
          : "-",
      status: STATUS.find((status) => status.value === item.status).text,
      creator: item.creator.full_name,
      closed_by: item.close ? item.close.closed_by.full_name : "-",
      items: item.items ? item.items.map((x) => x.name + " ") : "-",
      closed_at: item.close ? item.close.closed_at : "",
    });
  });
  return dataSource;
};

const mapStateToProps = (state) => ({
  prepareRegistration: state.machinesReducer.prepareRegistration,
  orders: state.maintenancesReducer.orders,
});

export default connect(mapStateToProps, null)(MaintenancesTable);
