import { Chart } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.plugins.register(ChartDataLabels);

const colors = [
    "#003f5c",
    "#444e86",
    "#955196",
    "#dd5182",
    "#ff6e54",
    "#ffa600"
]


// Doughnut chart

const doughnutOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        datalabels:{
            color: 'white',
            font: {
                weight: 'bold',
                size: '18'
            },
            display: function(context) {
                var index = context.dataIndex;
                var value = context.dataset.data[index];
                return value === 0 ? false : true;
            }
        }
    },
    legend: {
        display: true,
        position: "left",
        labels: {
            usePointStyle: true,
            padding: 20,
            generateLabels: function(chart) {
                let data = chart.data;
                if (data.labels.length && data.datasets.length) {
                    return data.labels.map(function(label, i) {
                        let meta = chart.getDatasetMeta(0);
                        let ds = data.datasets[0];
                        let arc = meta.data[i];
                        let custom = arc && arc.custom || {};
                        let getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
                        let arcOpts = chart.options.elements.arc;
                        let fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
                        let stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
                        let bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

                        // We get the value of the current label
                        let value = chart.config.data.datasets[arc._datasetIndex].data[arc._index];

                        return {
                            // Instead of `text: label,`
                            // We add the value to the string
                            text: `${label} : ${value}`,
                            fillStyle: fill,
                            strokeStyle: stroke,
                            lineWidth: bw,
                            hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                            index: i
                        };
                    });
                } else {
                    return [];
                }
            }
        },
    },
    elements: {
        arc: {
          borderWidth: 1
        }
    }
}


// Machines
const barOptions = position => {
    return{
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
            enabled: false
        },
        plugins: {
            datalabels:{
                color: 'white',
                font: {
                    weight: 'bold',
                    size: '18'
                },
                display: function(context) {
                    var index = context.dataIndex;
                    var value = context.dataset.data[index];
                    return value === 0 ? false : true;
                }
            }
        },
        legend: {
            display: true,
            position,
            align: "start",
            labels: {
                usePointStyle: true,
                textAlign: "right",
                // padding: 18
            }
        },
        scales: {
            yAxes: [
            {
                display: false,
                stacked: true,
                ticks: {
                beginAtZero: true,
                },
            },
            ],
            xAxes: [
            {
                display: false,
                stacked: true,
            },
            ],
        },
    }
}


export { doughnutOptions, barOptions }