import React from 'react'

// Styles
import { Card, Button, Tooltip, Popconfirm } from 'antd';
import { FileImageFilled, FilePdfFilled, FileExclamationFilled, VideoCameraFilled, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import styles from '../../index.module.css'

// Components
import Image from '../Image'
import Video from '../Video'
import Document from '../Document'
import Invalid from '../Invalid'

// type CardSize = "tiny" | "small" | "medium" | "large"

// interface IProps {
//     name: string,
//     mediaType: string,
//     size: CardSize,
//     index: number,
//     id: number,
//     title: string,
//     hideEditButton?: boolean,
//     hideButtons?: boolean,
//     openFileViewer: (v: number) => void,
//     onDelete: (id: number) => void,
//     onEdit?: (file: IFile | undefined) => void 
// }

const CardComponent = ({ name, title, id, onDelete, onEdit, hideEditButton = false, hideButtons = false, mediaType, size, index, openFileViewer }) => {

    const _type = mediaType;
    const _size = size;

    // Constants
    const sizes = {
        wrapper: {
            tiny: 48,
            small: 180,
            medium: 260,
            large: 340
        },
        meta:{
            tiny: 0,
            small: 140,
            medium: 220,
            large: 300
        },
        cover:{
            tiny: 46,
            small: 122,
            medium: 202,
            large: 282
        },
        document:{
            tiny: 120,
            small: 200,
            medium: 300,
            large: 400
        }
    }

    // Content
    const cover = {
        image: <Image src={name} size={sizes.cover[_size]} tiny={size === 'tiny'}/>,
        document: <Document src={name} size={sizes.cover[_size]} width={sizes.document[_size]} />,
        video: <Video src={name} size={sizes.cover[_size]} />,
        invalid: <Invalid size={sizes.cover[_size]} small={size === "small"} tiny={size === 'tiny'} />
    }

    const metaIcon = {
        image: <FileImageFilled style={{ color: "#e50a0a" }} />,
        document: <FilePdfFilled style={{ color: "#0a19e5" }} />,
        video: <VideoCameraFilled style={{ color: "#02b111" }} />,
        invalid: <FileExclamationFilled style={{ color: "#f09001" }} />
    }

    function handleOnDelete(event, id){
        event?.stopPropagation()
        onDelete(id)
    }

    const meta = 
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: sizes.meta[_size] }}>
            { metaIcon[_type] }
            <div className={styles.metaName}>
                { title }
            </div>
        </div>


    function handleOnEdit(event, file){
        event?.stopPropagation()
        onEdit && onEdit(file)
    }


    return(
        <div 
            className={styles.cardWrapper}
            style={{ 
                width: sizes.wrapper[_size],
                height: sizes.wrapper[_size],
                padding: size === 'tiny' ? 0 : 7,
                borderRadius: size === 'tiny' ? 6 : 20
            }}
        >
            {
                size !== 'tiny' ? 
                    <Card
                        onClick={() => openFileViewer(index)}
                        className={styles.card}
                        cover={cover[_type]}
                        bodyStyle={{ 
                            height: 48, 
                            padding: "0 10px", 
                            display: 'flex', 
                            alignItems: 'center', 
                            fontSize: 10,
                            position: 'relative' 
                        }}
                    >
                        <Card.Meta title={meta} />
                    </Card>
                    :
                    <div 
                        onClick={() => openFileViewer(index)}
                        style={{ 
                            width: 48, height: 48, 
                            display: 'grid', placeItems: 'center', 
                            border: '1px solid #ccc', borderRadius: 6,
                        }}
                    >
                        { cover[_type] }
                    </div>
            }

            <div>
                {
                    !hideButtons && 
                        <>
                            {
                                !hideEditButton && (
                                    <Button 
                                        onClick={e => handleOnEdit(e, {name: name, id: id, file: "", extension: ""})}
                                        type="default" 
                                        shape="circle" 
                                        size="middle" 
                                        style={{
                                            position: 'absolute',
                                            top: 14,
                                            right: 54
                                        }}
                                    >
                                        <EditOutlined />
                                    </Button>
                                )
                            }
                        
                        <Tooltip title={"Ukloni"} placement='bottom'>
                            <Popconfirm
                                onConfirm={(e) => handleOnDelete(e, id)}
                                title={"Nastavi sa akcijom?"}
                                cancelText={"Odustani"}
                                okText={"Nastavi"}
                                placement='top'
                            >
                                <Button 
                                    type="default" 
                                    shape="circle" 
                                    size="small" 
                                    style={{
                                        transform: 'scale(.9)',
                                        position: 'absolute',
                                        zIndex: 999,
                                        top: size === 'tiny' ? 'unset' : 14,
                                        bottom: size === 'tiny' ? 0 : 'unset',
                                        right: size === 'tiny' ? 0 : 14
                                    }}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </Popconfirm>
                        </Tooltip>
                    </>
                }
            </div>
        </div>
    )
}

export default CardComponent;