import React from "react";

import { Tabs } from "antd";
import {
  ProfileOutlined,
  ToolOutlined,
  ReadOutlined,
  SettingOutlined,
  HomeOutlined,
  BulbOutlined,
} from "@ant-design/icons";

import { withRouter } from "react-router";

import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const MachineTabs = (props) => {
  const { TabPane } = Tabs;

  //? Saving tab after page refresh
  let tab = props.location.pathname.slice(props.match.url.length + 1);

  const { machine } = useSelector((state) => state.machineReducer);

  const handleClick = (key) => {
    props.history.replace(key);
    props.setActiveKey();
  };

  return (
    <div id="machine-tabs">
      <Tabs
        defaultActiveKey={tab}
        id="machine-tabs-tabs"
        onChange={handleClick}
      >
        <TabPane
          tab={
            <span>
              <ProfileOutlined />
              <NavLink to={`${props.match.url}/specifications`}>
                Specifikacije
              </NavLink>
            </span>
          }
          key="specifications"
        ></TabPane>
        <TabPane
          tab={
            <span>
              <ToolOutlined />
              <NavLink to={`${props.match.url}/maintenance`}>
                Održavanja
              </NavLink>
            </span>
          }
          key="maintenance"
        ></TabPane>
        <TabPane
          tab={
            <span>
              <ReadOutlined />
              <NavLink to={`${props.match.url}/instructions`}>
                Materijali
              </NavLink>{" "}
            </span>
          }
          key="instructions"
        ></TabPane>
        <TabPane
          tab={
            <span>
              <BulbOutlined />
              <NavLink to={`${props.match.url}/parts`}>Dijelovi</NavLink>
            </span>
          }
          key="parts"
        ></TabPane>
      </Tabs>
    </div>
  );
};

export default withRouter(MachineTabs);
