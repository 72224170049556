import React, { Component } from "react";

import Form from "./Form";
import { Row, Col } from "antd";

import { connect } from "react-redux";

import * as actions from "../../../../../redux/modules/Maintenances/actions";
import {DESTROY_TOKEN_FOR_REDIRECT} from "../../../../../redux/modules/Users/actions";
import {SET_SUBASSET} from "../../../../../redux/modules/Machines/actions";

class NewOrder extends Component {
  state = {};
  render() {

    return (
      <>
        <Row  className={"newOrder"}>
          <Col span={24}>
            <Form
              prepareRegistration={this.props.prepareRegistration}
              machines={this.props.machines}
              saveOrder={this.props.onSaveWorkOrder}
              workOrder={this.props.workOrder}
              orders={this.props.orders}
              update={this.props.update}
              asset={this.props.asset}
              maintenance_for={this.props.maintenance_for}
              order={this.props.order}
              _updateWorkOrder={this.props.onUpdateWorkOrder}
              updateWorkOrder={this.props.updateWorkOrder}
              openCloseMaintenaceForm={this.props.openCloseMaintenaceForm}
              planned_start={this.props.planned_start}
              assetSelected={this.props.assetSelected}
              onDestroyToken={this.props.onDestroyToken}
              onSetTags={this.props.onSetTags}
              subassets={this.props.subassets || []}
              user={this.props.user}
            />
          </Col>
        </Row>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveWorkOrder: (data, callback) =>
      dispatch({ type: actions.NEW_WORK_ORDER, data, callback }),
    onUpdateWorkOrder: (order_id, data, callback) =>
      dispatch({ type: actions.UPDATE_WORK_ORDER, order_id, data, callback }),

    onDestroyToken: () =>   dispatch({type: DESTROY_TOKEN_FOR_REDIRECT}),
    onSetTags: (data) => dispatch({type: SET_SUBASSET, data})

  };
};

const mapStateToProps = (state) => {
  return {
    workOrder: state.maintenancesReducer.workOrder,
    workOrders: state.maintenancesReducer.workOrders,
    updateWorkOrder: state.maintenanceReducer.updateWorkOrder,
    subassets: state.machineReducer.subassets,
    user: state.usersReducer.user,

  };
};

  export default connect(mapStateToProps, mapDispatchToProps)(NewOrder);
