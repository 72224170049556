import React, { useState } from "react";

import { TreeSelect, Drawer, Icon, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import nestedSorting from "../../../../helpers/NestedSorting";
import SizeType from "../../../../helpers/deviceTypeSize";
import { MAINTENANCES_FILTERS_SUCCESS } from "../../../../redux/modules/Analytics/actions";
import { ReloadOutlined } from "@ant-design/icons";

const { SHOW_PARENT } = TreeSelect;

const Filters = ({ filtersVisible, set_filtersVisible }) => {
  const dispatch = useDispatch();
  const [filters, set_filters] = useState({
    assets: [],
    assets_category: [],
    locations: [],
    maintenances_category: [],
    maintenance_types: [],
  });
  

  const {
    prepareRegistration: {
      data: {
        locations,
        categories,
        maintenance_categories,
        maintenance_types,
      },
    },
    machines,
  } = useSelector((state) => state.machinesReducer);

  const { maintenancesReports } = useSelector(
    (state) => state.AnalyticsReducer
  );

  const onChange = (value, key) => {
    const _filters = { ...filters };
    _filters[key] = value;
    set_filters(_filters);
  };

  const tProps = {
    multiple: true,
    placeholder: "Odaberi",
    style: {
      width: "100%",
    },
  };

  let cssClasses = "reportsFilters ";

  cssClasses += filtersVisible ? "height" : "";

  const drawerProps = {
    className: cssClasses,
    maskClosable: true,
    headerStyle: { padding: 6 },
    placement: "top",
    width: SizeType() === "xs" || SizeType() === "sm" ? "90%" : 850,
    height: 390,
    visible: filtersVisible,
    onClose: () => {
      set_filtersVisible(false);
      dispatch({ type: MAINTENANCES_FILTERS_SUCCESS, data: filters });
    },
    destroyOnClose: true,
    title: (
      <span>
        <Icon type="filter" theme="twoTone" /> Filteri
      </span>
    ),
  };
  return (
    <Drawer {...drawerProps}>
      <div
        style={{
          width: "96%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <div style={{ width: "18%" }}>
          <span>Imovina</span>
          <TreeSelect
            allowClear={true}
            defaultValue={maintenancesReports.filters.assets}
            onChange={(val) => {
              onChange(val, "assets");
            }}
            treeData={nestedSorting(machines.data, "parent")}
            {...tProps}
          />
        </div>
        <div style={{ width: "18%" }}>
          <span>Lokacije</span>
          <TreeSelect
            allowClear={true}
            defaultValue={maintenancesReports.filters.locations}
            onChange={(val) => {
              onChange(val, "locations");
            }}
            treeData={nestedSorting(locations, "location_child")}
            {...tProps}
          />
        </div>
        <div style={{ width: "18%" }}>
          <span>Kategorije</span>
          <TreeSelect
            allowClear={true}
            defaultValue={maintenancesReports.filters.assets_category}
            onChange={(val) => {
              onChange(val, "assets_category");
            }}
            treeData={nestedSorting(categories, "parent")}
            {...tProps}
          />
        </div>
        <div style={{ width: "18%" }}>
          <span>Kategorije održavanja</span>
          <TreeSelect
            allowClear={true}
            defaultValue={maintenancesReports.filters.maintenances_categories}
            onChange={(val) => {
              onChange(val, "maintenances_category");
            }}
            treeData={maintenance_categories.map((item) => ({
              key: item.id,
              value: item.id,
              title: item.name,
            }))}
            {...tProps}
          />
        </div>
        <div style={{ width: "18%" }}>
          <span>Tipovi održavanja</span>
          <TreeSelect
            allowClear={true}
            defaultValue={maintenancesReports.filters.maintenance_types}
            onChange={(val) => {
              onChange(val, "maintenance_types");
            }}
            treeData={maintenance_types.map((item) => ({
              key: item.id,
              value: item.id,
              title: item.name,
            }))}
            {...tProps}
          />
        </div>
        <div style={{ width: "30px" }}>
          <span style={{ color: "#fff" }}>n</span>
          <Button
            style={{ marginLeft: "-18px" }}
            onClick={() => {
              dispatch({ type: MAINTENANCES_FILTERS_SUCCESS, data: filters });
            }}
            children={"Primijeni"}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default Filters;
