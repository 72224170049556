import React, { useEffect, useState } from "react";

import { Spin, Tabs } from "antd";

import Tasks from "./tasks";
import Materials from "./materials";
import Reports from "./reports";
import { useHistory, useLocation } from "react-router";
import { getApiEndpoint } from "../../../../axios/endpoints";
import axios from "axios";


const { TabPane } = Tabs;

const Details = () => {
  const location = useLocation();
  const history = useHistory();
  const [orderToken, set_orderToken] = useState(location.pathname.split("/")[2])
  const [order, set_order] = useState({ status: "", data: {} });



  useEffect(() => {
    if(orderToken){
      getOrder();
    }
  }, [orderToken]);

  // const {
  //   state: { order },
  // } = location;

  const getOrder = () => {
    axios
      .get(`${getApiEndpoint()}maintenances/get_open_work_order/${orderToken}`)
      .then((res) => {
        set_order({ status: "", data: res.data });
      })
      .catch((err) => {
        set_order({ status: "error", data: {} });
      });
  };




  console.log(order, "OOORDEEER")


  if(!order){
    history.push("/");
  }



  const TABS = [
    {
      name: "Zadaci",
      path: "tasks",
      content: <Tasks order={order} />,
    },
    {
      name: "Izvješća",
      path: "reprots",
      content: <Reports orderToken={orderToken}  order={order} />,
    },
    {
      name: "Materijali",
      path: "materials",
      content: <Materials  order={order} />,
    },
  ];

  return (
    <Spin spinning={order.status === "loading"} >
    <Tabs type="card" defaultActiveKey={"tasks"}>
      {TABS.map((item) => (
        <TabPane children={item.content} tab={item.name} key={item.path} />
      ))}
    </Tabs>
    </Spin>
  );
};

export default Details;
