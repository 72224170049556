import { Button, Drawer, Icon, Select, TreeSelect } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import nestedSorting from "../../../../helpers/NestedSorting";

import SizeType from "../../../../helpers/deviceTypeSize";
import { PARTS_FILTERS_SUCCESS } from "../../../../redux/modules/Analytics/actions";
import { newNestedSorting } from "../../../../helpers/functions";
import PartsSearch from "../../../../components/PartsSearch";

const Filters = ({ filtersVisible, set_filtersVisible }) => {
  const dispatch = useDispatch();
  const [filters, set_filters] = useState({
    category: [],
    parts: [],
    locations: [],
  });
  const { categories } = useSelector((state) => state.partsReducer);
  const {
    user: {
      data: { assets },
    },
  } = useSelector((state) => state.usersReducer);

  const onChange = (value, key) => {
    const _filters = { ...filters };
    _filters[key] = value;
    set_filters(_filters);
  };

  const tProps = {
    multiple: true,
    placeholder: "Odaberi",
    style: {
      width: "100%",
    },
  };

  let cssClasses = "reportsFilters ";

  cssClasses += filtersVisible ? "height" : "";

  const drawerProps = {
    className: cssClasses,
    maskClosable: true,
    headerStyle: { padding: 6 },
    placement: "top",
    width: SizeType() === "xs" || SizeType() === "sm" ? "90%" : 850,
    height: 390,
    visible: filtersVisible,
    onClose: () => {
      set_filtersVisible(false);
      dispatch({ type: PARTS_FILTERS_SUCCESS, data: filters });
    },
    destroyOnClose: true,
    title: (
      <span>
        <Icon type="filter" theme="twoTone" /> Filteri
      </span>
    ),
  };

  return (
    <Drawer {...drawerProps}>
      <div
        style={{
          width: "96%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          flexDirection: "row",
        }}
      >
        {/* <div style={{ marginLeft: 18, maxWidth: 320, minWidth: 300 }}>
          <span>Dijelovi</span>
          <br />
          <PartsSearch
            onChange={(v) => {
              onChange(v, "parts");
            }}
          />
        </div>
        <div style={{ marginLeft: 18, maxWidth: 320, minWidth: 300 }}>
          <span>Kategorije</span>
          <TreeSelect
            onChange={(val) => {
              onChange(val, "category");
            }}
            treeData={newNestedSorting(categories.data)}
            {...tProps}
          />
        </div> */}

        <div style={{ marginRight: 18, maxWidth: 520, minWidth: 300 }}>
          <span>Imovina</span>
          <TreeSelect
            {...tProps}
            maxTagCount={6}
            allowClear={true}
            multiple={true}
            treeData={nestedSorting(assets, "parent")}
            onChange={(val) => {
              onChange(val, "locations");
            }}
            showSearch
            optionFilterProp="name"
            filterTreeNode={(input, option) => {
              return (
                option.props.name.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              );
            }}
          />
        </div>
        <div style={{ marginLeft: 18, width: "30px" }}>
          <span style={{ color: "#fff" }}>n</span>
          <Button
            style={{ marginLeft: "-18px" }}
            type="primary"
            onClick={() => {
              dispatch({ type: PARTS_FILTERS_SUCCESS, data:  {...filters} });
            }}
            children={"Primijeni"}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default Filters;
