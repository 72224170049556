import { Button, message, Popconfirm, Spin, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CONFIRM_TRANSPORT,
  GET_PARTS_IN_TRANSPORT,
} from "../../../../redux/modules/Parts/actions";

const PartsInTransport = ({}) => {
  const dispatch = useDispatch();

  const [rowKey, set_rowKey] = useState(false);

  const {
    user: {
      data: { id: id },
    },
  } = useSelector((state) => state.usersReducer);

  const [COLUMNS, set_COLUMNS] = useState([
    {
      title: "Naziv",
      dataIndex: "part",
      render: (text, value) => value.part.name,
    },
    { title: "Količina", dataIndex: "qty" },
    {
      title: "Kreirano",
      dataIndex: "created_at",
      render: (text, value) => moment(text).format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "Kretanje",
      dataIndex: "from_storage",
      render: (text, value) => (
        <span>{`${value.from_storage?.name} -> ${value.to_storage?.name}`}</span>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      render: (text, value) => (
        <div>
          {value.can_confirm && (
            <Button
              style={{ margin: 6 }}
              type="primary"
              onClick={() => onConfirm("confirmed", value.id)}
              loading={false}
            >
              Potvrdi
            </Button>
          )}
          {(value.can_confirm || value.created_by.id === id) && (
            <Popconfirm
              title="Nastaviti sa radnjom"
              onConfirm={() => onConfirm("denied", value.id)}
              okText="DA"
              cancelText="NE"
            >
              <Button loading={false} style={{ margin: 6 }} type="danger">
                Odbij
              </Button>
            </Popconfirm>
          )}
        </div>
      ),
    },
  ]);

  const { getPartsInTransport } = useSelector((state) => state.partsReducer);

  useEffect(() => {
    dispatch({
      type: GET_PARTS_IN_TRANSPORT,
      errorCallback: () =>
        message.error("Dogodila se greška kod dohvata podataka"),
    });
  }, []);

  const onConfirm = (status, id) => {
    set_rowKey(id);
    dispatch({
      type: CONFIRM_TRANSPORT,
      id,
      data: { status },
      errorCallback: () => message.error("Dogodila se greška kod potvrde"),
    });
  };

  return (
    <div>
      <Spin spinning={getPartsInTransport.status === "loading"}>
        <Table columns={COLUMNS} dataSource={getPartsInTransport.data} />
      </Spin>
    </div>
  );
};

export default PartsInTransport;
