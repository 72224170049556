import { Checkbox, Form, Input, Button, Upload, Alert, Icon } from "antd";
import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { CONFIRM_ORDER } from "../../../../../redux/modules/Maintenances/actions";

const ConfirmForm = ({ form, set_orderID, order_id }) => {
  const dispatch = useDispatch();
  const { confirmOrder } = useSelector((state) => state.maintenancesReducer);
  const [error, set_error] = useState(false);
  const [files, set_files] = useState([]);

  const removeFile = (file) => {
    let list = files.concat();
    const index = list.indexOf(file);
    list.splice(index, 1);
    set_files([...list]);
  };

  const [confirmed, set_confirmed] = useState(true);

  const { getFieldDecorator, getFieldsError } = form;

  const setError = () => {
    set_error(true);
    setTimeout(() => {
      set_error(false);
    }, 2100);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      let formData = new FormData();
      formData.append("data", JSON.stringify({ ...values, confirmed }));

      files.forEach((item) => {
        formData.append("files", item);
      });
      dispatch({
        type: CONFIRM_ORDER,
        order_id,
        data: formData,
        successCallback: () => set_orderID(null),
        errorCallback: setError,
      });
    });
  };

  const beforeUploadPhoto = (file) => {
    set_files([...files, file]);
  };

  return (
    <>
      <Form layout="horizontal" onSubmit={handleSubmit}>
        <Form.Item label="Komentar">
          {getFieldDecorator("text", {
            initialValue: null,
            rules: [{ required: true, message: "Unesite komentar!" }],
          })(<Input.TextArea rows={5} />)}
        </Form.Item>

        <Form.Item label="Otklonjen">
          <Checkbox
            defaultChecked={true}
            onChange={() => {
              set_confirmed(!confirmed);
            }}
          />
        </Form.Item>
        <Form.Item label="File">
          <Upload
            beforeUpload={beforeUploadPhoto}
            onRemove={removeFile}
            multiple={true}
            showUploadList={true}
            fileList={files}
            accept=".mp4,.webm,.ogg,.png,.jpg,.jpeg,.pdf"
          >
            <Button>
              <Icon type="upload" /> Učitaj
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button
            style={{ float: "right" }}
            loading={confirmOrder.status === "loading"}
            type="primary"
            htmlType="submit"
            disabled={hasErrors(getFieldsError())}
          >
            Spremi
          </Button>
        </Form.Item>
      </Form>
      {error && alert}
    </>
  );
};

export default Form.create()(ConfirmForm);

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

const alert = (
  <Alert
    style={{ marginBottom: "10px", zIndex: "1" }}
    message="Dogodila se greša"
    description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
    type="error"
  />
);
