import { takeLatest, put, call, select } from "redux-saga/effects";

import axios_auth from "../../../axios/axios-auth";
import axios_user from "../../../axios/axios-user";

import store from "../../../store";

import * as actions from "./actions";
import * as machinesActions from "../Machines/actions";

import { getMqttClient } from "../../../helpers/mqtt";

import {
  ACCOUNTS,
  ACCOUNTS_FAIL,
  GET_PREPARE_REGISTRATION,
  GET_PREPARE_REGISTRATION_FAIL,
} from "../Accounting/actions";


import {
  DASHBOARD_TECHNICIANS_SUCCESS,
  ALERTS_CRITICAL_PARTS,
  ALERTS_NOTIFICATIONS,
  DASHBOARD_MAINTENANCES_SUCCESS,
  DASHBOARD_MAINTENANCES_FAIL,
} from "../Dashboard/actions";

import { TOPICS } from "../../../axios/endpoints";
import { GET_ORDERS_TO_CONFIRM_SUCCESS } from "../Maintenances/actions";
const TIME_STAMP = 1000 * 60;

const ARRAY_OF_TOPICS = (user) => {

  return [
    TOPICS.ORDERS_TOPIC.replace("${user_topic}", user.topic),
    TOPICS.DASHBOARD_TECHNICIANS.replace("data_account_id", user.id),
    TOPICS.DASHBOARD_PARTS,
    TOPICS.DASHBOARD_NOTIFICATIONS.replace("data_account_id", user.id),
  ];
};

const initialState = {
  user: {
    data: {},
    token: "",
    firebase_token: "",
    loading: true,
    error: null,
    status: 200,
    expireTime: 3600 * 3,
    permission: false,
  },
  mqtt_loading: true,
};

let mqtt;

export default function reducer(state = initialState, action = {}) {
  var tmp;
  switch (action.type) {
    case actions.LOGOUT_PROCEED:
      return {
        ...state,
        user: {
          token: "",
        },
      };
    case actions.LOGIN:
      return {
        ...state,
        user: {
          data: {},
          token: "",
          loading: "loading_login",
          error: null,
          status: 200,
        },
      };
    case actions.LOGIN_SUCCESS:
      console.log(action.data);
      return {
        ...state,
        user: {
          data: action.data.account,
          token: action.data.token,
          loading: false,
          error: null,
          status: 200,
        },
      };
    case actions.LOGIN_FAIL:
      return {
        ...state,
        user: {
          data: {},
          token: "",
          loading: false,
          error: action.error,
          status: 400,
        },
      };
    case actions.USER:
      return {
        ...state,
        user: {
          data: {},
          loading: "loading_user",
          token: "ff",
          error: null,
          status: 0,
        },
      };
    case actions.USER_SUCCESS:
      return {
        ...state,
        user: {
          data: action.data.account,
          token: action.data.token,
          loading: false,
          error: null,
          status: 200,
        },
      };

    case actions.USER_FAIL:
      return {
        ...state,
        user: {
          data: {},
          token: "",
          loading: false,
          error: action.error,
          status: 401,
        },
      };
    case actions.SET_TOKEN_FROM_LOCALE_STORAGE_PROCEED:
      return {
        ...state,
        user: {
          data: {},
          token: action.token.token,
          loading: false,
          error: null,
          status: 200,
        },
      };

    case actions.LOGOUT:
      return {
        ...state,
        user: {
          data: {},
          token: "",
          loading: false,
          error: null,
          status: 401,
        },
      };

    case actions.TOGGLE_MQTT_LOADING: {
      return {
        ...state,
        mqtt_loading: action.data,
      };
    }

    default:
      return { ...state };
  }
}

function transformData(data) {
  return data.data;
}

//set token from locale storage
function* setTokenFromLocaleStorage(token) {
  yield put({ type: actions.SET_TOKEN_FROM_LOCALE_STORAGE_PROCEED, token });
  // yield put({ type: actions.USER });
}

export function* watcherSetTokenFromLocaleStorage() {
  yield takeLatest(
    actions.SET_TOKEN_FROM_LOCALE_STORAGE,
    setTokenFromLocaleStorage
  );
}
//set token from locale storage end

//logout

function _logout(options) {
  return axios_user(options).post("logout/");
}

function* logout() {
  try {
    const token = yield call(authToken);
    const options = {
      token: token,
    };

    yield call(_logout, options);

    yield localStorage.removeItem("token");
    yield localStorage.removeItem("authTime");
    yield put({ type: actions.LOGOUT_PROCEED });
  } catch (error) {
    console.log(error);
    yield put({ type: actions.LOGOUT_PROCEED });
  }
}

export function* watcherLogout() {
  yield takeLatest(actions.LOGOUT, logout);
}
// login
function postData(options) {
  return axios_auth(options).post("auth/", options.credentials);
}

export function* watcherLogin() {
  yield takeLatest(actions.LOGIN, login);
}

function* login(credentials) {
  try {
    const options = { ...credentials };
    const response = yield call(postData, options);

    const data = transformData(response);

    yield localStorage.setItem("token", data.token);
    yield localStorage.setItem("authTime", new Date().toString());
    if (!response.user) {
      response.user = response.company;
    }

    // dispatch a success action to the store with the new data
    yield put({ type: machinesActions.PREPARE_MACHINE_REGISTRATION });
    yield put({ type: GET_PREPARE_REGISTRATION });
    yield put({ type: machinesActions.GET_MACHINES });
    yield put({ type: ACCOUNTS });
    yield put({ type: actions.LOGIN_SUCCESS, data });
    let topic = data.account.topic;

    if (data.account.category === "REP") {
      data.account.topic = topic + "_mob";
    }

    mqtt = yield getMqttClient(ARRAY_OF_TOPICS(data.account));

    mqtt.on("message", (topic, message) => {
      updateState(topic, message, store, data.account);
    });

    startRefreshingData(store);

    // registerSw();
  } catch (error) {
    yield put({ type: machinesActions.GET_MACHINES_FAIL });
    yield put({ type: machinesActions.PREPARE_MACHINE_REGISTRATION_FAIL });
    yield put({ type: DASHBOARD_MAINTENANCES_FAIL });
    yield put({ type: GET_PREPARE_REGISTRATION_FAIL });
    yield put({ type: ACCOUNTS_FAIL });
    // dispatch a failure action to the store with the error
    yield put({ type: actions.LOGIN_FAIL, error });
  }
}
// End login

// get user by token
function fetchUser(options) {
  return axios_user(options).get(`getuser/?token=${options.token}`);
}

const authToken = () => localStorage.getItem("token"); //kada se ubaci login

export function* watcherGetUserByToken() {
  yield takeLatest(actions.USER, getUser);
}

function* getUser() {
  //payload ukoliko bude potrebe za dohvatanje satanaka na osnovu nekog filtera
  try {
    const token = yield call(authToken);
    const options = {
      token: token,
    };
    const response = yield call(fetchUser, options);
    if (!response.user) {
      response.user = response.company;
    }
    let data = { ...transformData(response), ...{ token: options.token } };

    // dispatch a success action to the store with the new data
    yield put({ type: actions.USER_SUCCESS, data });
    yield put({ type: machinesActions.PREPARE_MACHINE_REGISTRATION });
    yield put({ type: GET_PREPARE_REGISTRATION });
    yield put({ type: machinesActions.GET_MACHINES });
    yield put({ type: ACCOUNTS });

    let topic = data.account.topic; // used for get orders

    if (data.account.category === "REP") {
      data.account.topic = topic + "_mob";
    }

    mqtt = yield getMqttClient(ARRAY_OF_TOPICS(data.account));

    mqtt.on("message", (topic, message) => {
      updateState(topic, message, store, data.account);
    });

    startRefreshingData(store);
  } catch (error) {
    yield put({ type: actions.USER_FAIL, error });
    yield put({ type: machinesActions.PREPARE_MACHINE_REGISTRATION_FAIL });
    yield put({ type: DASHBOARD_MAINTENANCES_FAIL });
    yield put({ type: GET_PREPARE_REGISTRATION_FAIL });
    yield put({ type: machinesActions.GET_MACHINES_FAIL });
    yield put({ type: ACCOUNTS_FAIL });

    if (error.response && error.response.status === 401) {
      yield put({ type: actions.LOGOUT });
    }
    // dispatch a failure action to the store with the error
    console.log(error);
  }
}
// End get user by token

// change password
export function* watcherChangePassword() {
  yield takeLatest(actions.CHANGE_PASSWORD, changePassword);
}

function _changePassword(options) {
  return axios_user(options).put(`password/change_password/`, options.data);
}

function* changePassword(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
    };

    const response = yield call(_changePassword, options);
    const data = transformData(response);
    // dispatch a success action to the store with the new data
    yield put({
      type: actions.CHANGE_PASSWORD_SUCCESS,
      data,
    });

    if (payload.callback && data.message === "") {
      payload.callback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: actions.LOGOUT });
    }
    // dispatch a failure action to the store with the error
    yield put({
      type: actions.CHANGE_PASSWORD_FAIL,
      error,
    });
  }
}
// read notification end

const updateState = (topic, _data, store, user) => {

  store.dispatch({ type: actions.TOGGLE_MQTT_LOADING, data: false });
  try {
    const data = JSON.parse(_data);


    switch (topic) {
      case ARRAY_OF_TOPICS(user)[0]:
        console.log(data.value);
        store.dispatch({
          type: GET_ORDERS_TO_CONFIRM_SUCCESS, 
          data: data.value
        })
        store.dispatch({
          type: DASHBOARD_MAINTENANCES_SUCCESS,
          data: data.value,
        });

        return;

      case ARRAY_OF_TOPICS(user)[1]:
        store.dispatch({
          type: DASHBOARD_TECHNICIANS_SUCCESS,
          data: data,
        });
        return;

      case ARRAY_OF_TOPICS(user)[2]:
        store.dispatch({
          type: ALERTS_CRITICAL_PARTS,
          data: data,
        });
        return;

      case ARRAY_OF_TOPICS(user)[3]:
        store.dispatch({
          type: ALERTS_NOTIFICATIONS,
          data: data,
        });
        return;

      default:
        break;
    }
  } catch (error) {
    console.log(error, "MQTT UPDATER", _data);
  }
};



const startRefreshingData = (store) => {
  const refreshTime = () => {

    try {
      const reducer = store.getState().dashboardReducer;
      store.dispatch({
        type: DASHBOARD_TECHNICIANS_SUCCESS,
        data: { ...reducer.dashboardTechnicians.data },
      });
      store.dispatch({
        type: DASHBOARD_MAINTENANCES_SUCCESS,
        data: [...reducer.dashboardMaintenances.data],
      });
      store.dispatch({
        type: ALERTS_NOTIFICATIONS,
        data: [...reducer.alerts.notifications],
      });
    } catch (error) {
      console.log(error, "LKJHGF");
    }

    setTimeout(refreshTime, TIME_STAMP);
  };

  refreshTime();
};
