import React, { useState, useEffect } from "react";
import useWindowDimensions from "../../../../../../helpers/useWindowDimension";
import { Table, Button } from "antd";
import css from "../../maintenance-manager.module.scss";

import {
  getForMaintenances,
  getForTechnicians,
  getForAssets,
  getForParts,
} from "../DashboardTable/Formats";

let maintenancesBlock = [
  "Prijavljena",
  "Nedodjeljena",
  "Nepotvrđena",
  "Utijeku",
  "Načekanju",
  "Dodjeljena",
];

let techniciansBlock = ["Prijavljen", "Odjavljen", "Zauzeti", "Dostupni"];

let assetsBlock = ["Aktivni", "Neaktivni"];

const DashboardTable = ({
  set_mapsVisible,
  orders,
  accounts,
  machinesDataByStatus,
  machinesDataByOrders,
  criticalParts,
  sectionInfo,
}) => {
  // Variables
  const { height, width } = useWindowDimensions();
  const [tableHeight, setTableHeight] = useState(0);

  // Methods

  useEffect(() => {
    setTableHeight(document.getElementsByTagName("body").offsetHeight - 134);
  }, []);

  useEffect(() => {
    // scrollToTop();
  }, [sectionInfo]);

  function scrollToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  let data = { data: [], columns: [] };

  let typesOfOrders = orders
    .map((item) => item.type.name)
    .filter((item, i, ar) => ar.indexOf(item) === i);

  let newAssetsBlock = assetsBlock.concat(typesOfOrders);

  let parsedSectionInfo = sectionInfo.replace(/ /g, "");

  if (maintenancesBlock.includes(parsedSectionInfo)) {
    let status = "";

    switch (parsedSectionInfo) {
      case maintenancesBlock[0]:
        status = "reported";
        break;
      case maintenancesBlock[1]:
        status = "in_bowl";
        break;
      case maintenancesBlock[2]:
        status = "finished";
        break;
      case maintenancesBlock[3]:
        status = "in_progress";
        break;
      case maintenancesBlock[4]:
        status = "waiting";
        break;
      case maintenancesBlock[5]:
        status = "appointed";
    }
    data = getForMaintenances(orders, status);
  } else if (techniciansBlock.includes(parsedSectionInfo)) {
    let status = "",
      type = "";

    switch (parsedSectionInfo) {
      case techniciansBlock[0]:
        status = "logged_in";
        type = "logged";
        break;
      case techniciansBlock[1]:
        status = "logged_out";
        type = "logged";
        break;
      case techniciansBlock[2]:
        status = "busy";
        type = "status";
        break;
      case techniciansBlock[3]:
        status = "free";
        type = "status";
        break;
    }
    data = getForTechnicians(orders, accounts, status, type);
  } else if (newAssetsBlock.includes(parsedSectionInfo)) {
    let status = "",
      type = "";

    switch (parsedSectionInfo) {
      case newAssetsBlock[0]:
        status = "active";
        type = "status";
        break;
      case newAssetsBlock[1]:
        status = "inactive";
        type = "status";
        break;
      default:
        status = typesOfOrders;
        type = "orders";
        break;
    }

    data = getForAssets(
      machinesDataByStatus,
      machinesDataByOrders,
      parsedSectionInfo,
      status,
      type
    );
  } else if (parsedSectionInfo === "Dijelovi") {
    data = getForParts(criticalParts);
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {" "}
        <p className={css.sectionTitle}>
          {sectionInfo}
          <span className={css.sectionTitleSpan}>
            {data.data.length ? "   (" + data.data.length + ")" : ""}
          </span>
        </p>{" "}
      </div>

      <Table
        id="dashboard-table"
        pagination={{
          pageSize: 5
        }}
        scroll={{
          y: width <= 1024 ? 284 : tableHeight,
          x: width < 1366 && 600,
        }}
        className={css.table}
        columns={data.columns}
        dataSource={data.data}
        size={width <= 1024 ? "middle" : "default"}
      />
    </>
  );
};

export default DashboardTable;
