import React from "react";

import { List, Popconfirm, Icon, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_MACHINE_CATEGORY } from "../../../../redux/modules/Machines/actions";

const ListItem = ({ item, set_updateData }) => {
  const dispatch = useDispatch();

  const childs = item.replies && item.replies.length && (
    <List
      style={{ marginLeft: "32px", paddingTop: "0px" }}
      itemLayout="horizontal"
      dataSource={item.replies}
      renderItem={(item) => (
        <ListItem set_updateData={set_updateData} item={item} />
      )}
    ></List>
  );

  const { updateMachineType } = useSelector((state) => state.machinesReducer);

  return (
    <>
      <Spin spinning={updateMachineType.status === "loading" + item.id}>
        <List.Item
          className={"hover listTreeItem"}
          style={{ padding: "3px", marginLeft: "2px" }}
          extra={
            <>
              <Icon
                onClick={() => set_updateData(item)}
                type="edit"
                style={{
                  cursor: "pointer",
                  marginRight: "20px",
                  fontSize: "18px",
                  color: "#4890ff",
                }}
              />
              <Popconfirm
                id="gdjesam"
                title="Jeste li sigurni da želite izbrisati?"
                placement="left"
                okText="Izbriši"
                cancelText="Odustani"
                onConfirm={() =>
                  dispatch({
                    type: UPDATE_MACHINE_CATEGORY,
                    id: item.id,
                    data: { is_active: false },
                  })
                }
              >
                <Icon
                  href="#"
                  type="delete"
                  style={{
                    cursor: "pointer",
                    marginRight: "0px",
                    fontSize: "18px",
                    color: "#4890ff",
                  }}
                />
              </Popconfirm>
            </>
          }
        >
          <List.Item.Meta
            className={"category-list-item"}
            description={item.name}
          />
        </List.Item>
        {childs !== 0 ? childs : null}
      </Spin>
    </>
  );
};

export default ListItem;
