import React from 'react'

// Styles
import { List, Tooltip, Popconfirm, Button } from 'antd';
import { FileImageFilled, FilePdfFilled, VideoCameraFilled, DeleteOutlined, FileExclamationFilled, EditOutlined } from '@ant-design/icons';
import styles from '../../index.module.css'

// Components
import Image from '../Image'
import Video from '../Video'
import Document from '../Document'
import Invalid from '../Invalid'

// type CardSize = "tiny" | "small" | "medium" | "large"

// interface IProps {
//     name: string,
//     mediaType: string,
//     size: CardSize,
//     index: number,
//     title: string,
//     id: number,
//     hideEditButton?: boolean,
//     hideButtons?: boolean,
//     hideMeta?: boolean,
//     hideMetaType?: boolean,
//     hideMetaIcon?: boolean
//     openFileViewer: (v: number) => void,
//     onDelete: (id: number) => void,
//     onEdit?: (file: IFile | undefined) => void 
// }


const ListItemComponent = (props) => {

    // Props
    const { 
        name, 
        title, 
        id, 
        hideMeta,
        hideMetaIcon,
        hideMetaType,
        hideEditButton = false, 
        hideButtons = false, 
        mediaType, 
        size, 
        index, 
        openFileViewer,
        onDelete, 
        onEdit, 
    } = props

    const _type = mediaType;
    const _size = size;

    function handleOnDelete(event, id){
        event?.stopPropagation()
        onDelete(id)
    }

    function handleOnEdit(event, file){
        event?.stopPropagation()
        onEdit && onEdit(file)
    }

    // Constants
    const sizes = {
        meta:{
            small: 140,
            medium: 140,
            large: 140
        },
        cover:{
            small: 32,
            medium: 52,
            large: 72
        },
        document:{
            small: 52,
            medium: 72,
            large: 92
        }
    }

    // Content
    const cover = {
        image: <Image src={name} size={sizes.cover[_size]} layout="list" />,
        document: <Document src={name} size={sizes.cover[_size]} width={sizes.document[_size]} layout="list" />,
        video: <Video src={name} size={sizes.cover[_size]} layout="list" />,
        invalid: <Invalid size={sizes.cover[_size]} layout="list" small={size === "small"} />
    }

    const metaIcon = {
        image: <FileImageFilled style={{ color: "#e50a0a" }} />,
        document: <FilePdfFilled style={{ color: "#0a19e5" }} />,
        video: <VideoCameraFilled style={{ color: "#02b111" }} />,
        invalid: <FileExclamationFilled style={{ color: "#f09001" }} />
    }

    const meta = hideMeta ? null :
        <div className={styles.metaContainer}>
            { !hideMetaType  &&
                <div 
                    className={styles.metaName} 
                    style={{ marginRight: 10 }}
                >
                    { mediaType }
                </div>
            }
            { !hideMetaIcon && 
                metaIcon[_type] 
            }
        </div>

    return(
        <List.Item 
            style={{ cursor: 'pointer' }}
            actions={hideButtons ? [] : [
                <Tooltip title={"Ukloni"} placement='bottom'>
                    <Popconfirm
                        onConfirm={(e) => handleOnDelete(e, id)}
                        title={"Nastavi"}
                        cancelText={"Odustani"}
                        okText={"Spremi"}
                        placement='top'
                    >
                        <Button 
                            type="default" 
                            shape="circle" 
                            size="middle" 
                        ><DeleteOutlined /></Button>
                    </Popconfirm>
                </Tooltip>,
                ...(!hideEditButton ? [<Button 
                    onClick={e => handleOnEdit(e, {name: name, id: id, file: "", extension: ""})}
                    type="default" 
                    shape="circle" 
                    icon={<EditOutlined />} 
                    size="middle" 
                />] : [])
            ]}
        >
            <List.Item.Meta
              avatar={<div onClick={() => openFileViewer(index)}>{cover[_type]}</div>}
            />
            <div className={styles.listFlex}>
                <div className={styles.listTitle}>
                    { title }
                </div>
                { meta }
            </div>
        </List.Item>
    )
}

export default ListItemComponent;