import React from "react";
import { Button, Form, Input, Select } from "antd";
import { useSelector } from "react-redux";
const {Option} = Select;
// primary_id: this.state.primary_id,
// status: this.state.reason.value,
// reason: this.state.reasonText,
// technician_id: technicianOfOrder(order, currentUser)
//   .id,
// is_primary: isTechnicianPrimary(order, currentUser)

const PauseReasonForm = (props) => {

    const {reasons}= useSelector(state => state.machinesReducer.prepareRegistration.data);


    const onFinish = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            props.onSave(values)
            console.log(values);
        });

    }

    const {getFieldDecorator, getFieldsError} = props.form;


    return <>

    <Form onSubmit={onFinish} >

<Form.Item  label="Razlog:">
                  {getFieldDecorator("status",{
                      rules: [
                        {
                          required: true,
                          message: "Ovo polje je obavezno",
                        },
                      ],
                  })(
                    <Select
                      allowClear={true}
                    >
                      {reasons?.map((item) => (
                        <Option key={item.value} value={item.value}>
                          {item.text}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                 
                {/* <Form.Item label={"Napomena"}>
                    {getFieldDecorator("reason")(
                   <Input.TextArea />
                  )}
                </Form.Item> */}
                <div style={{display: props.form.getFieldValue("status") === "missing_part" ? "block" : "none"}} >
                <Form.Item label="Naziv dijela" >
                    {getFieldDecorator("waiting_part")(
                   <Input />
                  )}
                </Form.Item>
                <Form.Item label="Rok isporuke" >
                    {getFieldDecorator("waiting_part_time")(
                   <Input />
                  )}
                </Form.Item>
                </div>

                <Button
                    disabled={hasErrors(getFieldsError())}
                htmlType="submit" type="primary" >Spremi</Button>
    </Form>
    </>
}

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  }

export default Form.create()(PauseReasonForm);