import React, { useEffect, useRef, useState } from "react";
import { List, Icon, Popconfirm, Typography, message, Spin, Button, Input } from "antd"

// Redux
import { useDispatch, useSelector } from "react-redux";
import { DELETE_CATEGORY, UPDATE_CATEGORY } from "../../../../redux/modules/Accounting/actions";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const { Paragraph } = Typography;

export default function CategoriesList({ item }){

    // Variables
    const dispatch = useDispatch()
    const updateCategory = useSelector(state => state.accoutingReducer.updateUserCategory)
    const [category, setCategory] = useState(item.name);



    const textAreaRef = useRef(null);

    useEffect(() => {
      adjustTextAreaHeight();
    }, [category]);
  
    const adjustTextAreaHeight = () => {
      if (textAreaRef.current) {
        textAreaRef.current.style.height = 'auto';
        textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
      }
    };

    // Methods
    function updateSuccessCallback(name) {
        message.success('Uspješno ste uredili kategoriju.')
        setCategory(name)
    }

    function deleteSuccessCallback() {
        message.success('Uspješno ste izbrisali kategoriju.')
    }

    function errorCallback(text) {
        message.error(text)
    }

    function handleDelete() {
        dispatch({
            type: DELETE_CATEGORY,
            id: item.id,
            successCallback: deleteSuccessCallback,
            errorCallback: () => errorCallback('Došlo je do pogreške tijekom brisanja kategorije.')
        })
    }

    function handleOnChange() {
            dispatch({ 
                type: UPDATE_CATEGORY, 
                data: { name: category },
                id: item.id,
                // successCallback: () => updateSuccessCallback(name),
                errorCallback: () => errorCallback('Došlo je do pogreške tijekom uređivanja kategorije.')
            })
    }

    
    return(
      <List.Item
        style={{ padding: "3px", marginLeft: "2px", paddingTop: 14, marginTop: 2 }}
        extra={
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <Button size="small" type="link" onClick={handleOnChange} >
                <EditOutlined />
            </Button>
            <Popconfirm 
                title="Jeste li sigurni da želite izbrisati?"
                placement="left"
                okText="Izbriši" 
                cancelText="Odustani"
                onConfirm={handleDelete}>
                    <Button size="small" type="link"><DeleteOutlined /></Button>
                  {/* <Icon
                    href="#"
                    type="delete"
                    style={{
                      cursor: "pointer",
                      marginRight: "0px",
                      fontSize: "18px",
                      color: "#4890ff",
                      marginTop: -16,
                    }}
                  /> */}
            </Popconfirm>   
            
            </div>
        }
      >
        <List.Item.Meta 
            className={'category-list-item'}
            description={
                <Spin spinning={updateCategory.status === item.id}>
                <Input.TextArea
                    style={{
                        border: "none",
                        resize: "none",
                    }}
                    // ref={textAreaRef}
                    value={category}
                    onChange={text => setCategory(text.target.value)}
                    autoSize={true}
                    />
                </Spin>
            } />
        </List.Item>
    )
}