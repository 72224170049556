import React, { useState, useEffect } from "react";

import {
  PageHeader,
  Button,
  Icon,
  Alert,
  Drawer,
  Modal,
  Input,
  message,
} from "antd";

import SizeType from "../../helpers/deviceTypeSize";

import Category from "./components/Category";
import Spinner from "../../components/LoadingScreen";
import PartForm from "./components/PartForm";

import { useSelector, useDispatch, connect } from "react-redux";
import { GET_PARTS_CATEGORIES } from "../../redux/modules/Parts/actions";
import PartsList from "./components/PartsContainers";
import { useRouteMatch, Route, Switch } from "react-router-dom";
import ListPartsTabs from "./components/ListPartsTabs";
import ToConfirm from "./components/PartsContainers/ToConfirm";
import PartsInTransport from "./components/PartsContainers/PartsInTransport";
import CriticalParts from "./components/PartsContainers/CrtiticalParts";
import SpendWithoutParts from "./components/PartsContainers/SpendWithoutParts";
import { GET_MACHINE, GET_MACHINES } from "../../redux/modules/Machines/actions";

const { Search } = Input;

const Parts = ({assets}) => {
  const [managePartsVisible, set_managePartsVisible] = useState(false);
  const [addingCategoryVisible, set_addingCategoryVisible] = useState(false);

  const match = useRouteMatch();

  const [spend, set_spend] = useState(null);

  const { getParts, getPartsCategories, categories } = useSelector(
    (state) => state.partsReducer
  );

  const { accounts } = useSelector((state) => state.accoutingReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: GET_MACHINES,
      errorCallback: () =>
        message.error("Dogodila se greška kod dohvata kategorije dijelova..."),
    });

    dispatch({
      type: GET_PARTS_CATEGORIES,
      errorCallback: () =>
        message.error("Dogodila se greška kod dohvata kategorije dijelova..."),
    });
  }, []);

  if (
    getPartsCategories.status === "loading" ||
    accounts.status === "loading"
  ) {
    return <Spinner />;
  }

  return (
    <>
      <PageHeader
        style={{ paddingTop: "0 !important" }}
        title={
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1 style={{ fontSize: 20, fontWeight: 600 }}>Dijelovi</h1>

            <div style={{ display: 'flex' }}>
            <Button
              style={{ marginRight: "12px" }}
              onClick={() => set_addingCategoryVisible(true)}
              type="default"
            >
              Kategorija <Icon type="plus" />
            </Button>

            <Button
              style={{ float: "right" }}
              type="primary"
              onClick={() => set_managePartsVisible(true)}
            >
              {!managePartsVisible ? (
                <>
                  Dodaj <Icon type="plus" />
                </>
              ) : (
                "Zatvori"
              )}
            </Button>
            </div>
          </div>
        }
      />

      {(getParts.status === "error" || getPartsCategories.status === "error") &&
        alert}

      {/* Conditional route displaying based on tab key */}

      <div>
        <ListPartsTabs />
      </div>

      <div id="rute">
        <Switch>
          {/* About part component */}
          <Route exact path={match.path} render={() => <PartsList />} />
          <Route
            path={`${match.path}/partsintransport`}
            render={() => <PartsInTransport />}
          />
          <Route
            path={`${match.path}/criticalqty`}
            render={() => <CriticalParts />}
          />{" "}
          <Route
            path={`${match.path}/spent`}
            render={() => <SpendWithoutParts />}
          />
        </Switch>
      </div>

      <Modal
        destroyOnClose={true}
        className={"antdModal"}
        style={{ maxWidth: "80%" }}
        visible={addingCategoryVisible}
        onCancel={() => set_addingCategoryVisible(false)}
        footer={null}
        children={
          <Category
            set_addingCategoryVisible={set_addingCategoryVisible}
            categories={categories.data}
          />
        }
      />

      <Drawer
        width={SizeType() === "xs" || SizeType() === "sm" ? "90%" : 690}
        visible={!!managePartsVisible}
        onClose={() => {
          set_managePartsVisible(false);
        }}
        destroyOnClose={true}
        title={
          <span>
            <Icon type="snippets" theme="twoTone" /> {"Dodaj dio"}
          </span>
        }
      >
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <PartForm
            assets={assets.data}
            accounts={accounts.data}
            categories={categories.data}
            set_managePartsVisible={set_managePartsVisible}
            managePartsVisible={managePartsVisible} // dodano naknadno odredjeno da ce ovdje ici i objekt dijela kad se dodaje nova kolicina da korisnik ne mora unositi sve podatke od dijelu
          />
        </div>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => ({
  assets: state.machinesReducer.machines,
});

export default connect(mapStateToProps)(Parts);

const alert = (
  <Alert
    style={{ marginBottom: "10px", zIndex: "1" }}
    message="Dogodila se greša"
    description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
    type="error"
  />
);
