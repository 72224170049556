import React, { useState, useEffect } from "react";

import { withRouter } from "react-router";
import SizeType from "../../helpers/deviceTypeSize";

import User from "./components/User";
import Spinner from "../../components/LoadingScreen";

import { PageHeader, Icon, Alert, Drawer, Input, Tabs } from "antd";

import { connect, useDispatch } from "react-redux";

import Table from "./components/Table";

function Accounting({
  accounts,
  prepareRegistration,
  onGetPrepareRegistration,
  history,
  assets,
}) {
  const dispatch = useDispatch();

  // Variables
  const [manageUsersVisible, setManageUsersVisible] = useState(false);
  const [functionsAddVisible, setFunctionsAddVisible] = useState(false);
  const [user_id, setUser_id] = useState(0);
  const [page, setPage] = useState(1);

  // Filtering accounts that are not suppliers
  const data = [...accounts.data.filter((item) => item.category !== "SUP")];

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("page");
    setPage(myParam == null ? 1 : myParam);
  }, []);

  const onPageChange = (pagination) => {
    history.push({ search: `?page=${pagination}` });
  };

  const functionsAdd = (bool) => {
    setFunctionsAddVisible(bool);
  };

  const toggleDrawer = (bool) => {
    if (bool) {
      setManageUsersVisible(bool);
      return;
    }
    setManageUsersVisible(!!bool);
  };

  if (
    prepareRegistration.status === "loading" ||
    accounts.status === "loading"
  ) {
    return <Spinner />;
  }

  const alertError = (
    <Alert
      style={{ marginBottom: "10px", zIndex: "1" }}
      message="Dogodila se greška"
      description="Dogodila se greška prilikom dohvata podataka sa servera molimo vas probajte ponovo!"
      type="error"
    />
  );

  console.log(accounts, "l");

  return (
    <>
      <PageHeader
        style={{ paddingTop: "0 !important" }}
        title={
          <h1 style={{ fontSize: 20, fontWeight: 600 }}>Ljudski resursi</h1>
        }
      />

      <Tabs>
        <Tabs.TabPane key="Upravitelji" tab="Upravitelji">
          <Table
            accounts={{
              data: accounts.data.filter((x) => x.category === "MAN"),
            }}
            prepareRegistration={prepareRegistration}
            toggleDrawer={toggleDrawer}
            manageUsersVisible={manageUsersVisible}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="Tehničari" tab="Tehničari">
          <Table
            accounts={{
              data: accounts.data.filter((x) => x.category === "TEH"),
            }}
            prepareRegistration={prepareRegistration}
            toggleDrawer={toggleDrawer}
            manageUsersVisible={manageUsersVisible}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="Skladištari" tab="Skladištari">
          <Table
            accounts={{
              data: accounts.data.filter((x) => x.category === "WRH"),
            }}
            prepareRegistration={prepareRegistration}
            toggleDrawer={toggleDrawer}
            manageUsersVisible={manageUsersVisible}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="Poslovođe" tab="Poslovođe">
          <Table
            accounts={{
              data: accounts.data.filter((x) => x.category === "REP"),
            }}
            prepareRegistration={prepareRegistration}
            toggleDrawer={toggleDrawer}
            manageUsersVisible={manageUsersVisible}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="Voditelji" tab="Voditelji">
          <Table
            accounts={{
              data: accounts.data.filter((x) => x.category === "LDR"),
            }}
            prepareRegistration={prepareRegistration}
            toggleDrawer={toggleDrawer}
            manageUsersVisible={manageUsersVisible}
          />
        </Tabs.TabPane>
      </Tabs>

      <Drawer
        width={SizeType() === "xs" || SizeType() === "sm" ? "90%" : 790}
        visible={manageUsersVisible}
        onClose={() => {
          toggleDrawer(false);
        }}
        destroyOnClose={true}
        title={
          <span>
            <Icon type="snippets" theme="twoTone" /> Dodaj korisnika
          </span>
        }
      >
        <User
          prepareRegistration={prepareRegistration}
          update={false}
          user={getUserAccount(data, user_id)}
        />
      </Drawer>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    accounts: state.accoutingReducer.accounts,
    prepareRegistration: state.accoutingReducer.prepareRegistration,
    assets: state.machinesReducer.machines,
  };
};

export default withRouter(connect(mapStateToProps, null)(Accounting));

const getUserAccount = (accounts, id) => {
  let model = {
    address: "",
    assets: [],
    can_report: true,
    category: "TEH",
    company: null,
    email: "",
    facility_subcategories: [],
    full_name: "",
    id: 0,
    phone_number: "",
    remark: "",
    topic: "",
  };

  let tmp = accounts.filter((item) => item.category !== "SUP");

  if (tmp === -1 || tmp === undefined) {
    return model;
  }

  tmp = tmp.find((x) => x.id === id);

  if (!tmp) {
    return model;
  }

  return tmp;
};
