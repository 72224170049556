import React, { useState, useEffect } from "react";

import { Row, Col, Button, Typography, Checkbox, List } from "antd";

import SizeType from "../../../../../helpers/deviceTypeSize";

import { getApiEndpoint } from "../.././../../../axios/endpoints";

import css from "../../../machine.module.scss";

const TYPES = {
  video: "v_",
  image: "i_",
  document: "d_",
  link: "l_",
};

let mob = false;

const { Title } = Typography;

const ListToChoseMaterialForTask = (props) => {
  const [formVisible, setFormVisible] = useState({
    visible: true,
    type: "image",
  });
  const [render, setRender] = useState(true);

  const onChange = (id, type) => {
    const _materials = [...props.task.materials];

    const index = props.task.materials.findIndex(
      (item) => item === TYPES[type] + id
    );
    if (index !== -1 && index !== undefined) {
      _materials.splice(index, 1);
    } else {
      _materials.push(TYPES[type] + id);
    }

    props.setMaterials(props.task.id, _materials);
    setRender(!render);
  };

  const check = (id, type) => {
    return props.task.materials.includes(TYPES[type] + id);
  };

  const toggleMaterial_Mob = (bool, type) => {
    setFormVisible({
      visible: bool,
      type,
    });
  };

  if (SizeType() === "xs" || SizeType() === "sm") {
    mob = true;
  }

  const { images, videos, documents, relevant_links } = props;

  return (
    <Row>
      <Col sm={24} xs={24} md={6} style={{ padding: "0 6px" }}>
        <Title level={4}>Videi</Title>
        {!mob || (formVisible.visible && formVisible.type === "video") ? (
          <>
            <List
              dataSource={videos}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    description={
                      <div style={{ textAlign: "center" }}>
                        <video width="300" height="230" controls>
                          <source
                            src={getApiEndpoint() + item.path}
                            type="video/mp4"
                          ></source>
                          <source
                            src={getApiEndpoint() + item.path}
                            type="video/ogg"
                          ></source>
                          <source
                            src={getApiEndpoint() + item.path}
                            type="video/webm"
                          ></source>
                        </video>
                        <div style={{ padding: "6px", textAlign: "center" }}>
                          {" "}
                          <Checkbox
                            checked={check(item.id, "video")}
                            onChange={() => onChange(item.id, "video")}
                          />{" "}
                        </div>
                      </div>
                    }
                  />{" "}
                </List.Item>
              )}
            />{" "}
          </>
        ) : null}
        {SizeType() === "sm" || SizeType() === "xs" ? (
          <div
            className={css.openCloseButton}
            onClick={() => toggleMaterial_Mob(!formVisible.visible, "video")}
          >
            {formVisible.visible && formVisible.type === "video"
              ? "Zatvori"
              : "Otvori"}
          </div>
        ) : null}
      </Col>



      <Col sm={24} xs={24} md={6} style={{ padding: "0 6px" }}>
        <Title level={4}>Slike</Title>
        {!mob || (formVisible.visible && formVisible.type === "image") ? (
          <>
            <List
              dataSource={images}
              renderItem={(item) => (
                <List.Item>
                  {" "}
                  <List.Item.Meta
                    description={
                      <>
                        <div style={{ textAlign: "center" }}>
                          <a
                            href={getApiEndpoint() + item.path}
                            target="_blank"
                            style={{
                              backgroundImage: `url(${
                                getApiEndpoint() + item.path
                              })`,
                              overflow: "hidden",
                              display: "inline-block",
                              width: "200px",
                              height: "200px",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                        </div>
                        <div style={{ padding: "6px", textAlign: "center" }}>
                          {" "}
                          <Checkbox
                            checked={check(item.id, "image")}
                            onChange={() => onChange(item.id, "image")}
                          />{" "}
                        </div>
                      </>
                    }
                  />{" "}
                </List.Item>
              )}
            />{" "}
          </>
        ) : null}
        {SizeType() === "sm" || SizeType() === "xs" ? (
          <div
            className={css.openCloseButton}
            onClick={() => toggleMaterial_Mob(!formVisible.visible, "image")}
          >
            {formVisible.visible && formVisible.type === "image"
              ? "Zatvori"
              : "Otvori"}
          </div>
        ) : null}
      </Col>
      <Col sm={24} xs={24} md={6} style={{ padding: "0 6px" }}>
        <Title level={4}>Dokumenti</Title>
        {!mob || (formVisible.visible && formVisible.type === "document") ? (
          <>
            <List
              style={{ width: "100%" }}
              dataSource={documents}
              renderItem={(item) => (
                <List.Item
                  style={{ display: "flex" }}
                  extra={
                    <Checkbox
                      checked={check(item.id, "document")}
                      onChange={(id) => onChange(item.id, "document")}
                    />
                  }
                >
                  <List.Item.Meta
                    description={
                      <a
                        style={{ overflow: "hidden" }}
                        href={getApiEndpoint() + item.path}
                        target="_blank"
                      >
                        {item.name}
                      </a>
                    }
                  />{" "}
                </List.Item>
              )}
            />{" "}
          </>
        ) : null}
        {SizeType() === "sm" || SizeType() === "xs" ? (
          <div
            className={css.openCloseButton}
            onClick={() => toggleMaterial_Mob(!formVisible.visible, "document")}
          >
            {formVisible.visible && formVisible.type === "document"
              ? "Zatvori"
              : "Otvori"}
          </div>
        ) : null}
      </Col>
      <Col sm={24} xs={24} md={6} style={{ padding: "0 6px" }}>
        <Title level={4}>Poveznice</Title>
        {!mob || (formVisible.visible && formVisible.type === "link") ? (
          <>
            <div style={{ overflow: "hidden" }}></div>
            <List
              dataSource={relevant_links}
              renderItem={(item) => (
                <List.Item
                  extra={
                    <Checkbox
                      checked={check(item.id, "link")}
                      onChange={() => onChange(item.id, "link")}
                    />
                  }
                >
                  <List.Item.Meta
                    description={
                      <a target="_blank" href={item.link}>
                        {item.name}
                      </a>
                    }
                  />
                </List.Item>
              )}
            />{" "}
          </>
        ) : null}
        {SizeType() === "sm" || SizeType() === "xs" ? (
          <div
            className={css.openCloseButton}
            onClick={() => toggleMaterial_Mob(!formVisible.visible, "link")}
          >
            {formVisible.visible && formVisible.type === "link"
              ? "Zatvori"
              : "Otvori"}
          </div>
        ) : null}
      </Col>
    </Row>
  );
};

export default ListToChoseMaterialForTask;
