import React from 'react';

// UI
import { FrownTwoTone } from '@ant-design/icons';
import css from '../../scheduler.module.scss'


export default function Error(){
    return(
        <div className={css.errorContainer}>
            <FrownTwoTone style={{ fontSize: 32 }} twoToneColor="#ff1845" />
            <h1 className={css.errorText}>Greška</h1>
            <p>Došlo je do greške pri učitavanju kalendara.</p>
        </div>
    )
}