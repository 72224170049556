import React, { useState, useEffect } from 'react'

import { connect } from "react-redux";


import MaintenanceTable from "../../../../../../components/Table/maintenace";

function Maintenances({ user}) {
    return(
        <>
            <MaintenanceTable
                accountProfile={user.id}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      orders: state.maintenancesReducer.orders,
    };
  };
  
export default connect(mapStateToProps, null)(Maintenances);
