import React from "react";

import { Button, Popconfirm, Icon } from "antd";

import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons";

const DeleteIcon = (props) => {
  if (props.loading) {
    return <LoadingOutlined />;
  }
  return (
    <>
      <Popconfirm
        id="gdjesam"
        title="Jeste li sigurni da želite izbrisati?"
        placement="left"
        okText="Izbriši"
        cancelText="Odustani"
        onConfirm={() =>
          props._deleteMaterial(
            props.data.item_id,
            props.data.material_id,
            props.data.topic
          )
        }
      >
        <Icon
          href="#"
          type="delete"
          style={{
            cursor: "pointer",
            marginRight: "0px",
            fontSize: "24px",
            color: "#4890ff",
          }}
        />
      </Popconfirm>
    </>
  );

  return (
    <Icon
      type="delete"
      style={{
        cursor: "pointer",
        marginRight: "20px",
        fontSize: "18px",
        color: "#4890ff",
        width: "20px",
        height: "20px",
      }}
    >
      <Popconfirm
        title="Jeste li sigurni da želite izbrisati?"
        placement="left"
        okText="Izbriši"
        cancelText="Odustani"
        onConfirm={() =>
          props._deleteMaterial(
            props.data.item_id,
            props.data.material_id,
            props.data.topic
          )
        }
      ></Popconfirm>
    </Icon>
  );
};

export default DeleteIcon;
