import React, { Component } from "react";

import { connect } from "react-redux";

// AntDesign Components
import { Alert } from "antd";

import Spinner from "../../components/LoadingScreen";

import Reporter from "./components/Reporter";
import MaintenanceManager from "./components/MaintenanceManager";
import Technician from "./components/Tehnician";
import Warhouse from "./components/Warhouse";

class Dashboard extends Component {
  state = {};

  getDashboardComponent = (user) => {
    switch (user.category) {
      // case "WRH":
      //   return <Warhouse />;
      case "TEH":
        return <Technician />;
      case "MAN":
      case "WRH":
      case "REP":
      case "LDR":

        return <MaintenanceManager />;
      // return (
      //   <Reporter prepareRegistration={this.props.prepareRegistration.data} />
      // );
      default:
        return <h3>Nepoznat korisniči račun!</h3>;
    }
  };

  render() {
    const { currentUser, prepareRegistration, orders, machines, mqtt_loading } =
      this.props;

    if (
      prepareRegistration.status === "loading" ||
      orders.status === "loading" ||
      machines.status === "loading" ||
      (mqtt_loading &&
        (currentUser.category === "MAN" || currentUser.category === "LDR"))
    ) {
      return <Spinner />;
    }

    const alert = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1" }}
        message="Dogodila se greša"
        description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
        type="error"
      />
    );
    const renderDashboard = this.getDashboardComponent(currentUser);

    return (
      <>
        {false ? (
          <Alert
            style={{ marginBottom: "10px", zIndex: "1" }}
            message="Dogodila se greša"
            description="Dogodila se greška prilikom dohvata podataka sa ERP-a molimo vas probajte ponovo!"
            type="error"
          />
        ) : null}

        <div style={{ width: "100%", height: "100%", position: "relative" }}>
          <div>{prepareRegistration.status === "error" ? alert : null}</div>
          {renderDashboard}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    prepareRegistration: state.machinesReducer.prepareRegistration,
    loading: state.usersReducer.user.loading,
    mqtt_loading: state.usersReducer.mqtt_loading,
    currentUser: state.usersReducer.user.data,
    orders: state.dashboardReducer.dashboardMaintenances,
    machines: state.machinesReducer.machines,
    dashboardTechnicians: state.dashboardReducer.dashboardTechnicians,
  };
};

export default connect(mapStateToProps)(Dashboard);
