import React, { Component } from "react";

import { Button, PageHeader, Icon, Drawer, Modal } from "antd";
import { connect } from "react-redux";

import Spinner from "../../components/LoadingScreen";

import SupplierForm from "./components/Supplier/SupplierForm";

import * as accountActions from "../../redux/modules/Accounting/actions";
import Admin_Categories from "./components/Categories";

import SizeType from "../../helpers/deviceTypeSize";

import SuppliersTable from "../../components/Table/suppliers";

class Supplers extends Component {
  state = {
    manageSupplierVisible: false,
    functionsAddVisible: false,
    update: false,
    supplier: {},
    page: 1,
  };

  toggleDrawer = (bool, supplier_id, update) => {
    if (!!bool) {
      this.setState({
        supplier: this.props.accounts.data
          .filter((item) => item.category === "SUP")
          .find((item) => item.id === supplier_id),
        update,
        manageSupplierVisible: bool,
      });
      return;
    }
    this.setState({ supplier: {}, update, manageSupplierVisible: bool });
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("page");
    this.props.onGetAccounts();
    this.setState({ page: myParam == null ? 1 : myParam });
  }

  onPageChange = (pagination) => {
    this.props.history.push({ search: `?page=${pagination}` });
  };

  functionsAdd = (bool) => {
    this.setState({ functionsAddVisible: bool });
  };

  render() {
    const { prepareRegistration } = this.props;
    const { accounts } = this.props;
    if (
      prepareRegistration.status === "loading" ||
      accounts.status === "loading"
    ) {
      return <Spinner />;
    }

    const data = accounts.data.filter((item) => item.category === "SUP");

    return (
      <div>
        <PageHeader title="Partneri" />

        {/* <Button type="primary" onClick={() => this.toggleDrawer(true)}>
          {!this.state.manageSupplierVisible ? (
            <>
              Dodaj <Icon type="plus" />
            </>
          ) : (
            "Zatvori"
          )}
        </Button> */}
        <SuppliersTable
          data={data}
          toggleDrawer={this.toggleDrawer}
          onPageChange={this.onPageChange}
          page={this.state.page}
          buttonAdd={() => (
            <Button type="primary" onClick={() => this.toggleDrawer(true)}>
              {!this.state.manageSupplierVisible ? (
                <>
                  Dodaj <Icon type="plus" />
                </>
              ) : (
                "Zatvori"
              )}
            </Button>
          )}
        />
        <hr />

        <div style={{ padding: "20px 0" }}>
          <Button onClick={() => this.functionsAdd(true)} type="default">
            Funkcija <Icon type="plus" />
          </Button>
        </div>

        <Drawer
          width={SizeType() === "xs" || SizeType === "sm" ? "90%" : 690}
          visible={this.state.manageSupplierVisible}
          onClose={() => this.toggleDrawer(false)}
          title={
            <span>
              <Icon type="snippets" theme="twoTone" /> Dodaj Partnera
            </span>
          }
        >
          <SupplierForm
            categories={prepareRegistration.data.company_categories}
            supplier={this.state.supplier}
            update={this.state.update}
          />
        </Drawer>
        <Modal
          className={"antdModal"}
          style={{maxWidth: "900px" }}
          visible={this.state.functionsAddVisible}
          onCancel={() => this.functionsAdd(false)}
          footer={null}
          destroyOnClose={true}
          children={
            <Admin_Categories
              close={this.functionsAdd}
              categories={
                this.props.prepareRegistration.data.company_categories
              }
            />
          }
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onGetPrepareRegistration: () =>
      dispatch({ type: accountActions.GET_PREPARE_REGISTRATION }),
    onGetAccounts: () => dispatch({ type: accountActions.ACCOUNTS }),
  };
};

const mapStateToProps = (state) => {
  return {
    prepareRegistration: state.accoutingReducer.prepareRegistration,
    accounts: state.accoutingReducer.accounts,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Supplers);
