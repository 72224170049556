export const ALERTS_CRITICAL_PARTS = "ALERTS_CRITICAL_PARTS";
export const ALERTS_NOTIFICATIONS = "ALERTS_NOTIFICATIONS";


export const DASHBOARD_TECHNICIANS = "DASHBOARD_TECHNICIANS";
export const DASHBOARD_TECHNICIANS_SUCCESS = "DASHBOARD_TECHNICIANS_SUCCESS";
export const DASHBOARD_TECHNICIANS_FAIL = "DASHBOARD_TECHNICIANS_FAIL";

export const DASHBOARD_MAINTENANCES = "DASHBOARD_MAINTENANCES";
export const DASHBOARD_MAINTENANCES_SUCCESS = "DASHBOARD_MAINTENANCES_SUCCESS";
export const DASHBOARD_MAINTENANCES_FAIL = "DASHBOARD_MAINTENANCES_FAIL";
