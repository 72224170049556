import axios from "axios";

import { getApiEndpoint } from "./endpoints";

const instance = (options) =>
  axios.create({
    baseURL: getApiEndpoint() + "api/parts/",
    timeout: 6000,
    headers: {
      Authorization: "Token " + options.token,
    },
  });

export default instance;
