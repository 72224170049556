import React, { useState, useEffect } from "react";

import {
  Button,
  PageHeader,
  Icon,
  Drawer,
  Modal,
  Switch,
  Input,
  Alert,
} from "antd";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";

import Admin_Categories from "./Categories";
import Admin_Types from "./Types";

import MachinesTable from "../../../components/Table/machines";

const { Search } = Input;

function Machines({
  prepareRegistration,
  machine,
  machines,
  onSaveMachine,
  button,
}) {
  const history = useHistory();
  // Variables
  // const [machineFormVisible, setMachineFormVisible] = useState(false);
  const [functionsAddVisible, setFunctionsAddVisible] = useState(false);
  const [addingFunctionsType, setAddingFunctionsType] = useState("");
  const [addingTypesVisible, setAddingTypesVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState({
    text_search: null,
    searchedItems: [],
    loading: false,
  });

  const handleSearch = async (text_search, event) => {
    if (event.target.nodeName === "INPUT") {
      setSearch({
        text_search: null,
        searchedItems: [],
        loading: false,
      });
      return;
    }
    if (text_search === null) {
      return;
    }
    const _search = {
      text_search,
      loading: true,
      searchedItems: search.searchedItems,
    };
    setSearch(_search);

    let tmpArr = [];
    for (let i = 0; i < machines.data.length; i++) {
      if (
        machines.data[i].name.toLowerCase().includes(text_search.toLowerCase())
      ) {
        tmpArr.push(machines.data[i]);
      }
    }
    const after_search = {
      text_search,
      loading: false,
      searchedItems: tmpArr,
    };

    setSearch(after_search);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("page");
    setPage(myParam == null ? 1 : myParam);
  }, []);

  const functionsAdd = (bool, type) => {
    setAddingFunctionsType(type);
    setFunctionsAddVisible(bool);
  };

  const onPageChange = (pagination) => {
    history.push({ search: `?page=${pagination}` });
  };

  return (
    <div>
      {machines.status === "error" ? (
        <Alert
          style={{ maxWidth: "639px", marginTop: "10px", zIndex: "1" }}
          message="Dogodila se greška"
          description="Dogodila se greška prilikom dohvata podataka molimo vas probajte ponovo!"
          type="error"
        />
      ) : null}

      {
        <MachinesTable
          search={() => (
            <Search
              style={{ width: "300px", marginRight: "16px" }}
              allowClear={true}
              placeholder="Unesi naziv"
              enterButton
              size="default"
              defaultValue={""} // bilo iz lokal storage uklonjeno na zahtjevo DURE
              onPressEnter={(event) =>
                handleSearch(event.target.value, {
                  target: { nodeName: "BUTTON" },
                })
              }
              onSearch={handleSearch}
            />
          )}
          data={
            search.text_search !== null && !search.loading
              ? search.searchedItems
              : machines.data
          }
          filters={prepareRegistration.data}
          onPageChange={onPageChange}
          page={page}
          button={button}
        />
      }
      <hr />

      <div style={{ padding: "20px 0" }}>
        <Button
          style={{ marginRight: "18px" }}
          onClick={() => functionsAdd(true, "functions")}
          type="default"
        >
          Kategorija imovine <Icon type="plus" />
        </Button>
      </div>

      <Modal
        destroyOnClose={true}
        className={"antdModal"}
        style={{ maxWidth: "900px" }}
        visible={functionsAddVisible}
        onCancel={() => functionsAdd(false)}
        footer={null}
        children={<Admin_Categories type={addingFunctionsType} />}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    prepareRegistration: state.machinesReducer.prepareRegistration,
    machine: state.machinesReducer.machine,
    machines: state.machinesReducer.machines,
  };
};

export default connect(mapStateToProps, null)(Machines);
