import React, { useState } from "react";
import { Drawer, Button, Icon } from "antd";
import useWindowDimensions from '../../helpers/useWindowDimension'
import { SearchOutlined } from '@ant-design/icons';
import SizeType from "../../helpers/deviceTypeSize"

const NavBar = ({ menu }) => {
  const [visible, setVisible] = useState(false);
  const { width } = useWindowDimensions();

  // Show menu icon
  if(width >= 1024){
    return null;
  }

  return (
    <nav className="navbar">
      <Button style={{ padding: '0px', display: 'flex', marginTop: '12px'}} type="link" onClick={() => setVisible(true)}>
        <Icon style={{ color: '#fff', fontSize: '18px' }} type="menu" />
      </Button>
      <Drawer
        title="Fiks"
        placement="left"
        onClick={() => setVisible(false)}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        {menu}
      </Drawer>
      {/* <a href="/">
        <img src={logo} className="logo" alt="logo" />
      </a> */}
    </nav>
  );
};

export default NavBar;