import React, { Component } from "react";

import SizeType from "../../../../helpers/deviceTypeSize";

import MachinesForm from "../../../Machines/components/MachinesForm";
import SectionTitle from "../SectionTitle";

import css from "../../machine.module.scss";

import { Button, Col, Drawer, Icon, Typography, Row } from "antd";
import SubAssets from "../SubAssets";

class Specifications extends Component {
  componentDidMount() {}

  render() {
    const {
      prepareRegistration,
      machines,
      machine,
      showUpdateForm,
      hideUpdateForm,
      updateFormVisible,
    } = this.props;

    if (Object.keys(machine.data).length === 0) {
      return (
        <Typography.Title level={6}>Imovina ne postoji...</Typography.Title>
      );
    }

    const description =
      machine.data.info.description === ""
        ? "---"
        : machine.data.info.description;

    const parent = machines.data.find(
      (item) => item.id === machine.data.parent.id
    );

    return (
      <div>
        <Row>
          <Col span={12} >
        
          <div style={{padding: 12}}>
          <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <SectionTitle
              title="Specifikacije"
              subtext="Pregled specifikacija odabrane imovine"
            />
          </div>
          {/* <div>
            <Button
              type={"primary"}
              onClick={showUpdateForm}
            >
              Uredi
            </Button>
          </div> */}
        </div>

        {/* ERP ID */}
        {infoItem("ERP ID", machine.data.erp_id)}

        {/* Kategorija */}
        {infoItem(
          "Kategorija",
          machine.data.categories
            ? machine.data.categories.map((x) => x.name).join(", ")
            : undefined
        )}

        {/* Funkcija */}

        {/* Nadimovina */}
        {infoItem("Nadimovina", parent && parent.name)}

        {/* Autopilot */}
        {infoItem("Autopilot", machine.data.autopilot ? "Da" : "Ne")}

        {/* Opis */}
        {infoItem("Opis", description)}

        {/* Partner */}
        {infoItem("Partner", machine.data.info.supplier?.full_name)}

        {/* Lokacija */}
        {infoItem("Lokacija", machine.data.info.location?.name)}

        {/* Rukovoditelj */}
        {infoItem("Rukovoditelj", machine.data.info.manager?.full_name)}

        {/* Proizvođač */}
        {infoItem("Proizvođač", machine.data.info.manufacturer)}

        {/* Datum proizvodnje */}
        {infoItem("Datum proizvodnje", machine.data.info.year)}

        {/* Serijski broj */}
        {infoItem("Serijski broj", machine.data.info.serial_number)}

        {/* Model */}
        {infoItem("Model", machine.data.info.model)}

        {/* Boja */}
        {infoItem("Boja", machine.data.info.color)}

         {/* Performanse */}
         {infoItem("Performanse", machine.data.info.performance + "%")}

        {/* Osiguranje */}
        {infoItem(
          "Osiguranje",
          machine.data.insurers
            ? machine.data.insurers.map((x) => x.insurer.full_name).join(", ")
            : undefined
        )}
</div>
          </Col>
          <Col span={12} >
          <div style={{padding: 12}}>
          <SubAssets />

          </div>
          </Col>
        </Row>
   
      </div>
    );
  }
}

export default Specifications;

const infoItem = (label, value) => {
  return (
    <div className={css.infoItem}>
      <h3 className={css.infoItemLabel}>{label}: </h3>
      <h3 className={css.infoItemValue}>{value || "---"}</h3>
    </div>
  );
};
