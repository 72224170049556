import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory, Switch, Route } from "react-router-dom";
import {
  Alert,
  PageHeader,
  Drawer,
  Icon,
  Popconfirm,
  Button,
  message,
} from "antd";
import Spinner from "../../../components/LoadingScreen";
import { DeleteOutlined, LeftCircleOutlined } from "@ant-design/icons";
import {
  GET_PART,
  GET_PARTS_CATEGORIES,
  REMOVE_PART,
  UPDATE_PART,
} from "../../../redux/modules/Parts/actions";

import SizeType from "../../../helpers/deviceTypeSize";
import css from "../../Accounting/Accounting.module.scss";

// Components
import PartForm from "../components/PartForm";
import PartsTabs from "../components/Tabs";
import About from "../components/About";
import Entry from "../components/History/Entry";
import Consumption from "../components/History/Consumption";

const Part = ({ match, location }) => {
  // Variables
  const dispatch = useDispatch();
  const {
    getPart,
    part,
    getPartsCategories,
    categories,
    updatePart,
    removePart,
  } = useSelector((state) => state.partsReducer);
  const { accounts } = useSelector((state) => state.accoutingReducer);
  const params = useParams();
  const history = useHistory();

  const [update, set_update] = useState(null);
  const [error, set_error] = useState(false);

  const [activeKey, setActiveKey] = useState("");

  // Methods
  // Setting active tab key
  function _setActiveKey() {
    // Define active key
    const _activeKey = location.pathname.split("/").slice(-1)[0] || "about";
    setActiveKey(_activeKey);
  }

  const setError = () => {
    set_error(true);

    setTimeout(() => {
      set_error(false);
    }, 2100);
  };

  useEffect(() => {
    dispatch({ type: GET_PARTS_CATEGORIES });
  }, []);

  if (
    getPartsCategories.status === "loading" ||
    accounts.status === "loading"
  ) {
    return <Spinner />;
  }

  return (
    <div>
      <div className={css.backFlexRow}>
        <div className={css.backFlexRow}>
          {/* Back icon with hover effect */}
          <div
            onClick={() => {
              history.push("/parts/parts");
            }}
            className={[css.hoverDiv + " hover"]}
          >
            {/* Icon */}
            <LeftCircleOutlined className={css.backIcon} />
          </div>

          <div>
            {/* Username */}
            <h1 className={css.username}>{part.data.name}</h1>
            {/* User ID */}
            <h1 className={css.userID}>ID dijela: {part.data.id}</h1>
          </div>
        </div>

        <div className={css.buttonsView}>
          {/* Edit user button */}
          <Button
            onClick={() => set_update(part.data)}
            loading={updatePart.status === "loading_update"}
            style={{ marginTop: 20 }}
            type="primary"
          >
            Uredi
          </Button>
          <Popconfirm
            id="delete_user"
            title="Jeste li sigurni da želite izbrisati korisnika?"
            placement="left"
            okText="Izbriši"
            cancelText="Odustani"
            onConfirm={() => {
              dispatch({
                id: params.id,
                type: REMOVE_PART,
                successCallback: () => history.replace("/parts/parts"),
                errorCallback: () => {
                  message.error("Dogodila se greška kod brisanja dijela...");
                },
              });
            }}
          >
            <Button
              style={{ marginTop: 20, marginLeft: 20 }}
              loading={removePart.status === "loading"}
              type="danger"
            >
              Izbriši
            </Button>
          </Popconfirm>
          {error && alert}
        </div>
      </div>
      {(getPart.status === "error" ||
        getPartsCategories.status === "error" ||
        accounts.status === "error") &&
        alert}
      <Drawer
        width={SizeType() === "xs" || SizeType() === "sm" ? "90%" : 690}
        visible={!!update}
        onClose={() => {
          set_update(null);
        }}
        destroyOnClose={true}
        title={
          <span>
            <Icon type="snippets" theme="twoTone" /> {!!update ? "Uredi dio" : "Dodaj dio"}
          </span>
        }
      >
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <PartForm
            update={update}
            accounts={accounts.data}
            categories={categories.data}
            set_update={set_update}
          />
        </div>
      </Drawer>
      {/* Profile tabs */}
      <PartsTabs
        setActiveKey={_setActiveKey}
  
        activeKey={activeKey}
        match={match}
        data={part}
      />
      {/* Conditional route displaying based on tab key */}
      <div>
        <Switch>
          {/* About part component */}
          <Route
            path={`${match.path}/about`}
            render={() => <About part={part} />}
          />

          {/* Part entry history component */}
          <Route
            path={`${match.path}/entry_history`}
            render={() => <Entry part={part} />}
          />

          {/* Part consumption history component */}
          <Route
            path={`${match.path}/consumption_history`}
            render={() => <Consumption part={part} />}
          />
        </Switch>
      </div>
    </div>
  );
};

export default Part;

const alert = (
  <Alert
    style={{ marginBottom: "10px", zIndex: "1" }}
    message="Dogodila se greša"
    description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
    type="error"
  />
);
