import React, { useEffect, useState } from "react";

import { Select, message, Spin, Button } from "antd";
import Axios from "axios";
import { getApiEndpoint } from "../axios/endpoints";
import { CloseOutlined } from "@ant-design/icons";

const PartsSearch = ({ wrapperStyle = {}, selectStyle = {}, onChange }) => {
  const [parts, set_parts] = useState([]);
  const [value, set_value] = useState(undefined);

  const [loadingSearch, set_loadingSearch] = useState(false);

  useEffect(() => {
    fetchSearch();
  }, []);

  function debounce(func, timeout = 600) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }
  const fetchSearch = async (value) => {
    const token = await localStorage.getItem("token");
    set_loadingSearch(true);
    let url = `${getApiEndpoint()}api/parts/parts/search/?name=${value}`;
    if(!value){
      url = `${getApiEndpoint()}api/parts/parts/search/`;
    }
    Axios.get(url, {
      headers: { Authorization: "Token " + token },
    })
      .then((res) => {
        set_parts([...res.data.data]);
        set_loadingSearch(false);

      })
      .catch((err) => {
        message.error("Dogodila se greška kod dohvata");
        set_loadingSearch(false);
      });
  };
  const search = debounce((value) => {
    console.log("fetching");
    fetchSearch(value);});

  return (
    <div style={{ ...wrapperStyle, display: "flex", justifyContent: "flex-start", alignItems: "center", }}>
      <Select
      value={value}
        style={{ width: 430, ...selectStyle }}
        onSearch={(value) => {
          const v = value;
          if (value) {
            search(v?.toLowerCase() || "");
          } 
        }}
        onSelect={(v) => {
          set_value(v);
          onChange(Array.isArray(v) ? v : [v], parts.find(x => x.id === v));
        }}
        mode="single"
        placeholder="Pretraži"
        allowClear={true}
        showSearch
        filterOption={false}
      >
        {parts.map((item) => (
          <Select.Option key={item.id} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
      {loadingSearch ? <Spin style={{ marginLeft: 12 }} spinning={true} /> : <Button
      onClick={() => {
        set_value(undefined);
        search("")
        onChange(undefined, undefined);
      }}
      style={{width: 33, marginLeft: 6, padding: 0}} >
          <CloseOutlined />
        </Button>}

    </div>
  );
};

export default PartsSearch;

