import React, { Component } from "react";

import SizeType from "../../../../../helpers/deviceTypeSize";
import { getMaintenanceFormModel } from "../../../../../helpers/machines";

import { withRouter } from "react-router";

import {
  Alert,
  Form,
  Row,
  Col,
  Input,
  Button,
  Select,
  Icon,
  Upload,
  InputNumber,
  Checkbox,
  TreeSelect,
  message,
} from "antd";
import { getApiEndpoint } from "../../../../../axios/endpoints";
import axios from "axios";
import moment from "moment";

import nestedSorting from "../../../../../helpers/NestedSorting";

import { connect } from "react-redux";
import { LOGOUT } from "../../../../../redux/modules/Users/actions";
import { newNestedSorting } from "../../../../../helpers/functions";
import Axios from "axios";

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

const { TextArea } = Input;
const { Option } = Select;

const expireTimeType = [
  { value: "hour", name: "Sati" },
  { value: "day", name: "Dani" },
  { value: "month", name: "Mjeseci" },
  { value: "year", name: "Godine" },
];

class Maintenance extends Component {
  state = {
    sameLocationErrorVisible: false,
    alertSuccessVisible: false,
    photoList: [],
    machine_run: false,
    useUnclosed: false,
    unclosed: { data: [], status: "" },
    pickedAsset: false,
    subassets: [],
    loadingTags: false
  };

  getUnclosed = () => {
    let url = `${getApiEndpoint()}maintenances/work_orders/`;

    this.setState({ unclosed: { data: [], status: "loading" } });

    axios
      .get(url, {
        headers: {
          Authorization: "Token " + this.props.userToken,
        },
        params: { status: "unclosed" },
      })
      .then((res) => {
        this.setState({ unclosed: { data: res.data, status: "" } });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          this.props.onLogout();
        }
        console.log(err, "GET_UNCLOSED");
        this.setState({ unclosed: { data: [], status: "error" } });
      });
  };

  componentDidMount() {
    this.getUnclosed();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const tmpStartDate = { ...values.start_date };
        values.planned_start = moment(tmpStartDate)
          .add(
            new Date(values.start_time).getMinutes() +
              new Date(values.start_time).getHours() * 60,
            "m"
          )
          .format("YYYY-MM-DD HH:mm");

        values.planned_end = moment(values.planned_start)
          .add(parseInt(values.estimated_time), "h")
          .format("YYYY-MM-DD HH:mm");
        let machines = [this.props.match.params.id];

        const data = {
          name: values.name,
          description:  "",
          tags: values.subassets || [],
          planned_start: values.planned_start,
          planned_end: values.planned_end,
          categories: values.categories,
          type: values.type,
          items: [values.machine],
          tasks: [],
          technicians: values.technician
            ? [
                { account: values.technician, is_primary: 1 },
                ...values.sub_technicians.map((item) => ({
                  account: item,
                  is_primary: 0,
                })),
              ]
            : [],
          every: values.repeat_time,
          estimated_time: values.estimated_time || null,
          estimated_time_minute: values.estimated_time_minute || null,
          period: values.repeat_type,
          machine_run: this.state.machine_run,
          estimated_time: null,
          estimated_time_minute: null,
          work_capacity: values.work_capacity,
          production_work_order: values.production_work_order
        };

        let formData = new FormData();
        formData.append("data", JSON.stringify(data));

        this.state.photoList.forEach((item) => {
          formData.append("files", item);
        });

        this.props.saveOrder(formData, this.showSuccess.bind(this));
      }
    });
  };

  showSuccess = () => {
    this.setState({
      alertSuccessVisible: true,
      photoList: [],
      machine_run: false,
      useUnclosed: false,
    });
    this.props.form.resetFields();
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      this.setState({ alertSuccessVisible: false });
    }, 3000);
  };

  beforeUploadPhoto = (file) => {
    this.setState((state) => ({
      photoList: [...state.photoList, file],
    }));

    return false;
  };

  getTagsOfAsset = async (asset_id) => {
    this.setState({ loadingTags: true });

    let token = await localStorage.getItem("token");

    try {
      let response = await Axios.get(
        getApiEndpoint() + "items/tags/?asset=" + asset_id,
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      );
      this.setState({subassets: response.data})
    } catch (error) {
      if (error.response && error.response.status === 401) {
        this.props.onDestroyToken();
        return;
      }

      message.error("Dogodila se greska kod dohvata podimovine");
    }

    this.setState({ loadingTags: false });
  };

  removeFilePhoto = (file) => {
    let photoList = this.state.photoList.concat();
    const index = photoList.indexOf(file);
    photoList.splice(index, 1);
    this.setState({ photoList: [...this.state.photoList] });
    setTimeout(() => {
      this.forceUpdate();
    }, 36);
  };

  render() {
    const { getFieldDecorator, getFieldsError, setFieldsValue } = this.props.form;

    const alertSuccess = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1" }}
        message="Uspješno"
        description="Uspješno ste prijavili kvar!"
        type="success"
      />
    );

    const formItemLayout = {
      labelCol: {
        span: 6,
      },
      wrapperCol:
        SizeType() === "xs" || SizeType() === "sm"
          ? {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 24,
                offset: 0,
              },
            }
          : {
              span: 18,
              offset: 0,
            },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };
    const layoutType =
      SizeType() === "xs" || SizeType() === "sm" ? "vertical" : "horizontal";

    const {
      prepareRegistration,
      machines,
      machine,
      workOrder,
      workOrders,
    } = this.props;

    const maintenanceFormModel = getMaintenanceFormModel(null, {
      name: "machine",
      type: 2,
    });



    return (
      <React.Fragment>
        {true && (
          <div>
            {"Obnovi "}

            <Checkbox
              checked={this.state.useUnclosed}
              onChange={() => {
                this.setState({ useUnclosed: !this.state.useUnclosed });
              }}
            />
          </div>
        )}
        {this.state.alertSuccessVisible ? alertSuccess : null}
        <Form
          {...formItemLayout}
          layout={layoutType}
          onSubmit={this.handleSubmit}
        >
          <Row type="flex" justify="start" gutter={16}>
            <Col span={24}>
              {/* NAZIV */}
              <Form.Item label="Opis kvara:">
                {getFieldDecorator("name", {
                  initialValue: maintenanceFormModel.name,
                  rules: [
                    { required: true, message: "Unesite opis održavanja!" },
                    { min: 3, message: "Minimalno znakova 3!" },
                  ],
                })(<Input placeholder="Unesite opis" />)}
              </Form.Item>
           
              <Form.Item label="Radni nalog proizvodnje:">
                {getFieldDecorator("production_work_order", {
                  initialValue: maintenanceFormModel.production_work_order,
                  
                })(<Input placeholder="Unesite nalog proizvodnje" />)}
              </Form.Item>

              {/* imovina za ODRŽAVANJA */}
              <Form.Item label="Imovina:">
                {getFieldDecorator("machine", {
                  initialValue: null,
                  rules: [{ required: true, message: "Unesite imovinu!" }],
                })(
                  <TreeSelect
                    placeholder="Please select"
                    showSearch
                    multiple={false}
                    onSelect={(id) => {
                      this.getTagsOfAsset(id);
                      this.setState({ pickedAsset: id });
                    }}
                    allowClear={true}
                    treeData={nestedSorting(machines, "parent")}
                    optionFilterProp="title"
                    filterTreeNode={(input, option) => {
                      console.log(input, option);
                      return (
                        option.props.name
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item  label="Podimovina:">
                  {getFieldDecorator("subassets", {
                    initialValue: maintenanceFormModel.subassets,
                  })(
                    <Select
                      loading={this.state.loadingTags}
                      allowClear={true}
                      disabled={!this.props.form.getFieldValue("machine")}
                      // notFoundContent={additionalAddTagButton}
                      optionFilterProp="name"
                      mode="multiple"
                    >
                      {this.state.subassets?.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                      {/* {additionalAddTagButton} */}
                    </Select>
                  )}
                </Form.Item>

              {this.state.useUnclosed &&
                this.props.form &&
                this.props.form.getFieldValue("machine") && (
                  <Form.Item label="Nalog za obnoviti:">
                    {getFieldDecorator("unclosed", {
                      initialValue: null,
                      rules: [{ required: true, message: "Unesite nalog!" }],
                    })(
                      <Select
                        placeholder="Odaberi nalog"
                        mode="single"
                        allowClear={true}
                        showSearch
                        optionFilterProp="name"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.state.unclosed.data &&
                          this.state.unclosed.data.length &&
                          this.state.unclosed.data
                            .filter(
                              (x) =>
                                x.items[0].id ===
                                this.props.form.getFieldValue("machine")
                            )
                            .map((item) => (
                              <Option key={item.id + "_!"} value={item.id}>
                                {item.name}
                              </Option>
                            ))}
                      </Select>
                    )}
                  </Form.Item>
                )}

              {/* KATEGORIJA ODRŽAVANJA */}
              <Form.Item label="Kategorija:">
                {getFieldDecorator("categories", {
                  initialValue: [],
                  rules: [
                    { required: true, message: "Ovo polje je obavezno!" },
                  ],
                })(
                  <Select
                  placeholder="Unesite kategoriju"
                  mode="multiple"
                  allowClear={true}
                  onSelect={(id) => {
                    setFieldsValue({categories: [id]})
                  }}
                  showSearch
                  optionFilterProp="name"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {prepareRegistration.maintenance_categories.map(
                    (item) => (
                      <Option key={item.id + "_!"} value={item.id}>
                        {item.name}
                      </Option>
                    )
                  )}
                </Select>
                )}
              </Form.Item>

              {/* TIP ODRŽAVANJA */}
              <Form.Item label="Tip:">
                {getFieldDecorator("type", {
                  rules: [{ required: true, message: "Unesite tip!" }],
                  initialValue: null,
                })(
                  <Select
                    mode="single"
                    allowClear={true}
                    showSearch
                    placeholder="Odaberi tip"
                    optionFilterProp="name"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {prepareRegistration.maintenance_types
                      .filter((item) => item.id !== 1)
                      .map((item) => (
                        <Option key={item.id + "_!"} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>

              {/* OPIS */}
              {/* <Form.Item label="Opis:">
                {getFieldDecorator("description", {
                  initialValue: maintenanceFormModel.description,
                  rules: [],
                })(<TextArea placeholder="Unesite opis" />)}
              </Form.Item> */}

              <Form.Item label="Imovina aktivan">
                <Checkbox
                  checked={this.state.machine_run}
                  onChange={() => {
                    this.setState({ machine_run: !this.state.machine_run });
                  }}
                />
              </Form.Item>
              {this.state.machine_run && (
                <Form.Item label="Kapacitet imovine">
                  {getFieldDecorator("work_capacity", {
                    initialValue: maintenanceFormModel.work_capacity,
                  })(
                    <InputNumber
                      style={{ width: "100px" }}
                      min={0}
                      max={100}
                      placeholder={""}
                    />
                  )}
                  <span style={{ marginLeft: "6px" }}> %</span>
                </Form.Item>
              )}

              <Form.Item label="Fotografija">
                <Upload
                  beforeUpload={this.beforeUploadPhoto}
                  onRemove={this.removeFilePhoto}
                  multiple={true}
                  showUploadList={true}
                  fileList={this.state.photoList}
                  accept=".png,.jpg,.jpeg,.tiff"
                >
                  <Button>
                    <Icon type="upload" /> Učitaj
                  </Button>
                </Upload>
              </Form.Item>

              <Form.Item {...tailFormItemLayout}>
                <Button
                  style={{ float: "right" }}
                  loading={workOrder.status === "loading"}
                  type="primary"
                  htmlType="submit"
                  disabled={hasErrors(getFieldsError())}
                >
                  Spremi
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

const userHaveRightFunction = (arr, value) => {
  let check = arr.find((item) => item.id === value);
  if (check === -1 || check === undefined) {
    return false;
  }
  return true;
};

const mapStateToProps = (state) => {
  return {
    userToken: state.usersReducer.user.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch({ type: LOGOUT }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Form.create()(Maintenance)));
