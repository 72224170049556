import React, { useEffect, useMemo } from "react";

import css from "../assets.module.scss";

import { Alert, Col, Row, Typography } from "antd";

import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../components/LoadingScreen";

// Components
// import Availability from "./Cards/Availability";
import Information from "./Cards/Information";
import Line from "./Charts/Line";

import { Bar } from "@ant-design/charts";
import {
  MTBF,
  MTBM,
  MTTR,
  TOTAL_DOWNTIME,
  TOTAL_DOWNTIME_PER_ASSET,
} from "../../../../redux/modules/Analytics/actions";

const TimeSpendOnAssets = ({}) => {
  // Variables
  const dispatch = useDispatch();

  const {
    pickedDate,
    assetsReports: { filters },
    totalDowntimePerAsset,
    totalDowntime,
    mtbf,
    mtbm,
    mttr,
  } = useSelector((state) => state.AnalyticsReducer);

  const getTableHeightSetup = (wraper) => {
    let tableWraper = document.getElementById(wraper);

    if (!tableWraper) {
      return 0;
    }
    let headerHeight = 0;
    let tableTh = 0;

    headerHeight = tableWraper.getElementsByTagName("h3")[0].offsetHeight;
    tableTh = tableWraper.getElementsByClassName("ant-table-thead")[0].offsetHeight;

    let wraperHeight = tableWraper.offsetHeight;

    return wraperHeight - (headerHeight + tableTh + 105);
  };

  useEffect(() => {
    const queryParams = {
      from: pickedDate.data.from,
      to: pickedDate.data.to,
      locations: filters.locations.join("|"),
      assets: "11", //Odlučeno da će se samo proizvodna imovina zakucatfilters.assets.join("|"),
      assets_category: filters.assets_category.join("|"),
    };
    getReports(queryParams);
  }, [pickedDate, filters]);

  const getReports = (queryParams) => {
    dispatch({ type: MTBM, queryParams });
    dispatch({ type: TOTAL_DOWNTIME, queryParams });
    dispatch({ type: TOTAL_DOWNTIME_PER_ASSET, queryParams });
    dispatch({ type: MTTR, queryParams });
    dispatch({ type: MTBF, queryParams });
  };
  const memedConfig = useMemo(() => {
    let data = [];
    mtbf.data.data.map((x) => {
      if (x.mtbf > 0) {
        data.push({
          ...x,
          mtbf: Math.ceil(x.mtbf / 1000 / 60 / 60),
        });
      }
    });
    let config = {
      interaction: {
        tooltip: {
          render: (e, { title, items }) => {
            console.log(e, title, items);
            return (
              <div
                style={{
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                <Typography.Text style={{ color: "black" }}>{title}: </Typography.Text>
                <Typography.Text style={{ color: "black", fontWeight: 500 }}>
                  {items[0].value?.toFixed(1)}h
                </Typography.Text>
              </div>
            );
          },
        },
      },
      label: {
        text: "mtbf",
        formatter: (v) => `${v?.toFixed(1)}h`,
        style: {
          fontWeight: 600,
          fontSize: 15,
          textAnchor: "start",
          fill: "#000",
          dx: 64,
        },
      },
      data: data,
      xField: "asset_name",
      yField: "mtbf",
      animation: { appear: { delay: 750 } },
      axis: {
        y: { labelFormatter: "~s", labelSpacing: 10 },
        x: {
          labelSpacing: 10,
        },
      },
    };
    return config;
  }, [mtbf.data]);

  if (totalDowntime.status === "loading") {
    return <Loading />;
  }

  return (
    <>
      {totalDowntime.status === "error" ||
      mtbm.status === "error" ||
      totalDowntimePerAsset.status === "error" ||
      mtbf.status === "error" ? (
        <Alert
          style={{ width: "639px", marginTop: "10px", zIndex: "1" }}
          message="Dogodila se greška"
          description="Dogodila se greška prilikom dohvata podataka molimo vas probajte ponovo!"
          type="error"
        />
      ) : null}

      <div id={css.assetsContainer}>
        <Row gutter={[24, 24]}>
          {/* MTBT Card */}
          <Col lg={24} xl={8}>
            <Information
              text={
                "Srednje vrijeme između kvarova. Ovim pokazateljom se mjeri pouzdanost, učinkovitost, kvaliteta i sigurnost imovine. Za izračun se uzima vrijeme rada imovine koje se dijeli sa brojem incidentnih održavanja."
              }
              label={(Math.ceil(mtbf.data.total_avg_time) / 1000 / 60 / 60).toFixed(1) + "h"}
              information="MTBF"
              labelStyle={{ fontSize: 14, fontWeight: 400 }}
              hideHoursLabel
            />
          </Col>
          {/* MTBT Card */}
          <Col lg={24} xl={8}>
            <Information
              text={
                " Srednje vrijeme između održavanja.  Ovim pokazateljom se mjeri pouzdanost imovine. Za izračun se uzima vrijeme rada imovine koje se dijeli sa brojem svih održavanja bez obzira na tip."
              }
              label={(Math.ceil(mtbm.data.MTBM) / 1000 / 60 / 60).toFixed(1) + "h"}
              information="MTBM"
              labelStyle={{ fontSize: 14, fontWeight: 400 }}
              hideHoursLabel
            />
          </Col>
          {/* MTBT Card */}
          <Col lg={24} xl={8}>
            <Information
              text={
                "Srednje vrijeme popravka kvara. Ovim pokazateljem se mjeri učinkovitost otklanjanja kvara tj. koliko je u prosjeku potrebno da se otkloni kvar. Za izračun se uzima vrijeme rada na incidentnim održavanjima (od prijave kvara do potvrde otklanjanja kvara) koje se dijeli sa brojem incidentnih održavanja."
              }
              label={(Math.ceil(mttr.data.total_avg_time) / 1000 / 60 / 60).toFixed(1) + "h"}
              information="MTTR"
              labelStyle={{ fontSize: 14, fontWeight: 400 }}
              hideHoursLabel
            />
          </Col>
        </Row>

        {/* Srednje vrijeme popravka kvara */}
        {/* Header */}
        <div className={css.header} style={{ marginTop: 40, marginBottom: 16 }}>
          <p className={css.value} style={{ color: "unset" }}>Srednje vrijeme popravka kvara (h) (MTTR)</p>
          <div style={{ display: "flex", gap: 20}}>
            <p>Ty = Vrijeme popravka</p>
            <p>Tx = Vremenska crta</p>
          </div>
        </div>
        <div className={css.lineChart}>
          <Line
            interval_type={mttr.data.interval_type}
            labels={mttr.data.data.map((x) => new Date(x.interval))}
            data={mttr.data.data.map((x) => (Math.ceil(x.avg_time) / 1000 / 60 / 60).toFixed(1))}
            title="Srednje vrijeme popravka kvara (h)"
            color="orange"
          />
        </div>

        {/* //* Cards - bottom row */}

        <div className={css.header} style={{ marginBottom: 24, marginTop: 40 }}>
          <p className={css.value} style={{ color: "unset" }}> Srednje vrijeme između kvarova (MTBF)</p>
        </div>
        {/* Bar chart */}
        <div style={{ height:Math.max(180, memedConfig.data.length * 40), marginTop: -20 }}>
          {memedConfig && <Bar {...memedConfig} />}
        </div>
      </div>
    </>
  );
};

export default TimeSpendOnAssets;
