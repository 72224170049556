import { FileExcelFilled } from "@ant-design/icons";
import { BlobProvider, Document, Font, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Button, message } from "antd";
import moment from "moment";

// Fonts
import PoppinsLight from '../../../../../assets/fonts/Poppins/Poppins-Light.ttf';
import PoppinsRegular from '../../../../../assets/fonts/Poppins/Poppins-Regular.ttf';
import PoppinsMedium from '../../../../../assets/fonts/Poppins/Poppins-Medium.ttf';
import PoppinsSemiBold from '../../../../../assets/fonts/Poppins/Poppins-SemiBold.ttf';
import PoppinsBold from '../../../../../assets/fonts/Poppins/Poppins-Bold.ttf';


export default function WorkEffectivenessPDF({ data, date, footerData, action = "open" }) {
  // Fonts
  Font.register({
    family: 'Poppins',
    fonts: [
      { src: PoppinsLight, fontWeight: 300 },
      { src: PoppinsRegular, fontWeight: 400 },
      { src: PoppinsMedium, fontWeight: 500 },
      { src: PoppinsSemiBold, fontWeight: 600 },
      { src: PoppinsBold, fontWeight: 700 },
    ]
  });
  return (
    <BlobProvider document={PDFDocument(data, date, footerData)}>
      {({ blob, error }) => {
        // Creating blob
        const documentBlob = new Blob([blob], { type: "application/pdf" });

        function open() {
          if (action === "open") {
            const newWindow = window.open(URL.createObjectURL(documentBlob), "_blank");
            setTimeout(() => {
              newWindow.document.title = "Vremenski i opći učinak";
            }, 150);
          } else if (action === "download") {
            const downloadUrl = URL.createObjectURL(documentBlob);
            const a = document.createElement("a");
            a.href = downloadUrl;
            a.download = "Vremenski i opći učinak";
            a.click();
          }
        }

        // Displaying error banner
        if (error) {
          message.error(error);
        }

        // Showing PDF
        return (
          <Button onClick={open}>
            Izvezi <FileExcelFilled />{" "}
          </Button>
        );
      }}
    </BlobProvider>
  );
}

// PDF Document
const PDFDocument = (data, date, footerData) => {
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap orientation="landscape">
        <View style={styles.titleWrapper}>
          <Text style={styles.titleText}>Vremenski i opći učinak</Text>
          <Text style={styles.titleDate}>{`${moment(date.from).format("LL")} - ${moment(date.to).format("LL")}`}</Text>
        </View>
        <TableHeader total={data.length} />
        {data && data.length > 0 && data.map((item, index) => <TableItem item={item} key={index} />)}
        <TableFooter data={footerData} />
      </Page>
    </Document>
  );
};

function TableHeader({ total }) {
  return (
    <View style={styles.tableHeaderWrapper}>
      <Text style={[styles.tableHeaderItem, { width: "110%" }]}>
        Tehničar <Text style={[styles.tableHeaderItem, { color: "red", fontWeight: 600 }]}>{` (${total})`}</Text>
      </Text>
      <Text style={[styles.tableHeaderItem]}>Prosječno vrijeme odaziva tehničara</Text>
      <Text style={[styles.tableHeaderItem]}>Prosječno vrijeme izvršenja održavanja</Text>
      <Text style={[styles.tableHeaderItem, { width: "100%" }]}>Izvršeno incidentnih</Text>
      <Text style={[styles.tableHeaderItem, { width: "100%" }]}>Izvršeno korektivnih</Text>
      <Text style={[styles.tableHeaderItem, { width: "100%" }]}>Izvršeno preventivnih</Text>
      <Text style={[styles.tableHeaderItem, { width: "90%" }]}>Ukupno</Text>
      <Text style={[styles.tableHeaderItem, { borderRight: "none", width: "100%" }]}>Vrijeme na poslu</Text>
    </View>
  );
}

function TableItem({ item }) {
  return (
    <View style={styles.tableItemWrapper} wrap={false}>
      <Text style={[styles.tableItem, { width: "110%" }]}>{item.first_name + " " + item.last_name}</Text>
      <Text style={[styles.tableItem]}>{item.avg_response_time?.toFixed(1)} h</Text>
      <Text style={[styles.tableItem]}>{item.avg_execution_time?.toFixed(1) || "-"} h</Text>
      <Text style={[styles.tableItem, { width: "100%" }]}>
        <Text style={[styles.tableItem, { fontWeight: 600, color: "#000" }]}>{`${item.inc.count}  `}</Text>
        {item.inc.total_time?.toFixed(1)} h
      </Text>
      <Text style={[styles.tableItem, { width: "100%" }]}>
        <Text style={[styles.tableItem, { fontWeight: 600, color: "#000" }]}>{`${item.corr.count}  `}</Text>
        {item.corr.total_time?.toFixed(1)} h
      </Text>
      <Text style={[styles.tableItem, { width: "100%" }]}>
        <Text style={[styles.tableItem, { fontWeight: 600, color: "#000" }]}>{`${item.prev.count}  `}</Text>
        {item.prev.total_time?.toFixed(1)} h
      </Text>
      <Text style={[styles.tableItem, { backgroundColor: "#f1f1f1", width: "90%" }]}>
        <Text style={[styles.tableItem, { fontWeight: 600, color: "#000" }]}>{`${
          item.inc.count + item.corr.count + item.prev.count
        }  `}</Text>
        {(item.inc.total_time + item.corr.total_time + item.prev.total_time)?.toFixed(1)} h
      </Text>
      <Text style={[styles.tableItem, { width: "100%" }]}>{item.work_time?.toFixed(1)} h</Text>
    </View>
  );
}

function TableFooter({ data }) {
  let totalCount = data.inc_count + data.corr_count + data.pre_count;
  let totalTime =
    Number(data.inc_time?.toFixed(1)) + Number(data.corr_time?.toFixed(1)) + Number(data.pre_time?.toFixed(1));

  return (
    <View style={styles.tableFooterWrapper} wrap={false}>
      <Text style={[styles.tableItem, { width: "110%" }]}></Text>
      <Text style={[styles.tableItem]}></Text>
      <Text style={[styles.tableItem]}></Text>
      <Text style={[styles.tableItem, { width: "100%" }]}>
        <Text
          style={[styles.tableItem, { fontWeight: 600, color: "#000" }]}
        >{`${data.inc_count}  `}</Text>
        {data.inc_time?.toFixed(1)} h
      </Text>
      <Text style={[styles.tableItem, { width: "100%" }]}>
        <Text
          style={[styles.tableItem, { fontWeight: 600, color: "#000" }]}
        >{`${data.corr_count}  `}</Text>
        {data.corr_time?.toFixed(1)} h
      </Text>
      <Text style={[styles.tableItem, { width: "100%" }]}>
        <Text
          style={[styles.tableItem, { fontWeight: 600, color: "#000" }]}
        >{`${data.pre_count}  `}</Text>
        {data.pre_time?.toFixed(1)} h
      </Text>
      <Text style={[styles.tableItem, { width: "90%" }]}>
        <Text style={[styles.tableItem, { fontWeight: 600, color: "#000"}]}>{`${totalCount}  `}</Text>
        {totalTime?.toFixed(1)} h
      </Text>
      <Text style={[styles.tableItem, { width: "100%" }]}></Text>
    </View>
  );
}

// Styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: "20px 28px 48px",
    fontFamily: "Poppins"
  },
  titleWrapper: {
    marginBottom: 20,
  },
  titleText: {
    fontSize: 20,
    fontFamily: "Poppins",
    fontWeight: 600,
  },
  titleDate: {
    fontFamily: "Poppins",
    fontWeight: 300,
    fontSize: 10,
    opacity: 0.65,
  },
  tableHeaderWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#fafafa",
    borderBottom: "1px solid #e8e8e8",
  },
  tableHeaderItem: {
    width: "100%",
    fontSize: 8,
    fontFamily: "Poppins",
    padding: "10px 7px",
    textAlign: "left",
    borderRight: "1px solid #e8e8e8",
  },
  tableItemWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    borderBottom: "1px solid #e8e8e8",
  },
  tableItem: {
    width: "100%",
    fontSize: 8,
    fontFamily: "Poppins",
    padding: "6px 7px",
    textAlign: "left",
    color: "#555",
  },
  tableFooterWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#f1f1f1",
    borderBottom: "1px solid #e8e8e8",
  },
});
