import moment from "moment";

function formatDate(date) {
  console.log(date)
  let start = date[0];
  let end = date[1];
  return { start, end };
}

function formatWeek(week) {
  let start = moment(week).startOf("week").format("YYYY-MM-DD");
  let end = moment(week).endOf("week").format("YYYY-MM-DD");
  return { start, end };
}

function formatMonth(month) {
  let start = moment(month).startOf("month").format("YYYY-MM-DD");
  let end = moment(month).endOf("month").format("YYYY-MM-DD");
  return { start, end };
}

export { formatDate, formatWeek, formatMonth };
