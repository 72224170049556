import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message, Modal, Table as T, Tooltip, Typography, Row, Col, PageHeader, Button, Drawer, Input } from "antd";
import css from "./maintenance-manager.module.scss";

// Chart
import { Doughnut, HorizontalBar } from "react-chartjs-2";
import { doughnutOptions, barOptions } from "./ChartConfig";
import NewOrder from "../../../Machine/components/Maintenance/NewOrder";

// Data
import {
  // Maintenances
  maintenancesData,
  // Machines
  machinesDataByOrders,
  machinesDataByStatus,
  // Technicians
  technicianDataByAvailability,
  technicianDataByStatus,
} from "./ChartData";

// Components
import CardName from "./components/CardName";
import Information from "./components/Cards/Information";
import Notification from "./components/Cards/Notification";
import DashboardTable from "./components/DashboardTable/Table";
import Maps from "../../../Accounting/components/Mpas";
import { GET_PARTS_BY_STATUS, WAITING_FOR_CONFIRMATION } from "../../../../redux/modules/Parts/actions";
import Warehouse from "../Warhouse";
import { Link } from "react-router-dom";
import { REJECT_ORDER_SUCCESS } from "../../../../redux/modules/Maintenances/actions";
import moment from "moment";
import { JURE_FORMAT_DATE, STATUS } from "../../../../helpers/consts";
import ICON_MAP from "../../../../assets/icons/google_map.png"
import CriticalParts from "../../../Parts/components/PartsContainers/CrtiticalParts";
import WaitingsOrdersByPart from "./components/WaitingsOrdersByPart";
import { CONTROL_ID } from "../../../../AppWrapper";

const getFilters = (data, key) => {
  switch (key) {
    case "category":
      return data.map((item) => ({
        text: item.name,
        value: item.name,
      }));
    case "type":
      return data.map((item) => ({ text: item.name, value: item.name }));
    case "status":
      return STATUS.map((item) => ({ text: item.text, value: item.text }));
    case "name":
      let tmp_arr = [];

      data.map((item) => {
        if (!isExsitsName(item, tmp_arr)) {
          tmp_arr.push({ text: item.name, value: item.name });
        }
      });
      return tmp_arr;
    case "items":
      let uniqueArray = [];
      uniqueArray = data.filter(function (item, pos, self) {
        return self.indexOf(item) == pos;
      });
      return uniqueArray.map((name) => ({ text: name, value: name }));
    case "technicians":
      let technicians_uniqueArray = [];
      technicians_uniqueArray = data.filter(function (item, pos, self) {
        return self.indexOf(item) == pos;
      });
      return technicians_uniqueArray.map((name) => ({
        text: name,
        value: name,
      }));
  }
  // let categoryFilter = [{ text: "error", value: "error" }];
  // return categoryFilter;
};

export default function Dashboard() {
  const dispatch = useDispatch();

  const [filteredInfo, set_filteredInfo] = useState();

  // Variables

  const [mapsVisible, set_mapsVisible] = useState(false);
  const [sectionInfo, setSectionInfo] = useState("Nedodjeljena");
  const [parsedAssets, set_parsedAssets] = useState([]);  
  const [confirmPartsVisible, set_confirmPartsVisible] = useState(false);
  const [criticalPartsVisible, set_criticalPartsVisible] = useState(false);

  const {prepareRegistration, machines} = useSelector(state => state.machinesReducer)

  const { dashboardMaintenances } = useSelector(
    (state) => state.dashboardReducer
  );

  const { dashboardTechnicians } = useSelector(
    (state) => state.dashboardReducer
  );

  const {user} = useSelector(state => state.usersReducer);


  const { criticalParts, dummy, notifications } = useSelector(
    (state) => state.dashboardReducer.alerts
  );

  const { waitingForConfirmation } = useSelector((state) => state.partsReducer);

  const [createOrderVisible, set_createOrderVisible] = useState(false);
  const [search, set_search] = useState("");

  useEffect(() => {
    set_parsedAssets(parseDataFromOrdersToAssets(dashboardMaintenances.data));
    dispatch({
      type: WAITING_FOR_CONFIRMATION,
      errorCallback: () => {
        message.error("Dogodila se greška kod dohvata podataka", 3);
      },
    });
  }, [dashboardMaintenances]);

  // Filtering dashboardMaintenances by status
  let statuses = ["in_bowl", "in_progress", "waiting", "appointed", "finished"];
  let _orders = dashboardMaintenances.data.filter((item) =>
    statuses.includes(item.status)
  );

  // Data
  const _machinesDataByStatus = machinesDataByStatus(parsedAssets);
  const _machinesDataByOrders = machinesDataByOrders([..._orders]);
  const _technicianDataByAvailability = technicianDataByAvailability(
    dashboardTechnicians.data.accounts.filter((item) => item.logged)
  );
  const _technicianDataByStatus = technicianDataByStatus(
    dashboardTechnicians.data.accounts
  );
  const getElementAtEvent = (element, type = "bar") => {
    if (!element.length) {
      return;
    }

    // console.log(element[0]);

    if (type === "doughnut") {
      setSectionInfo(element[0]._model.label);
    } else {
      setSectionInfo(element[0]._model.datasetLabel);
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Opis",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => (
        <Link
          to={{
            pathname: `/maintenances/${record?.id}/about`,
          }}
        >
          {text}
        </Link>
      ),
    },
  
    // Hide machine column in maintenances table on machines page

    {
      title: "Imovina",
      dataIndex: "items",
      key: "items",
      render: (text, record, index) => (
        <Link
          to={{
            pathname: `/machines/${record.items[0]?.id}/specifications`,
          }}
        >
          {record.items[0]?.name}
        </Link>
      ),
      ellipsis: true,
    },

    {
      title: "Tip",
      dataIndex: "type",
      key: "type",
      render: (text, record, index) => record.type?.name,
      ellipsis: true,
    },
    // {
    //   title: "Kategorija",
    //   dataIndex: "category",
    //   key: "category",
    //   render: (text, record, index) => record.category?.name || "",
    //   ellipsis: true,
    // },
    {
      title: "Prijavio",
      dataIndex: "creator",
      key: "creator",
      
      
      render: (text, record, index) => <div><div>{record.creator.full_name}</div><div>{moment(record.created_at).format(JURE_FORMAT_DATE)}</div></div>,
    },

    {
      title: "Tehničari",
      dataIndex: "technicians",
      key: "technicians",
      render: (text, record, index) =>
        record.technicians?.map((x) => x.account.full_name).join(" "),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, value) => {
        return STATUS.find((status) => status.value === value.status).text;
      },
      filters: getFilters(dashboardMaintenances.data || [], "status"),
      filteredValue: filteredInfo?.status || null,
      // onFilter: (value, record) => {
      //   console.log(filteredInfo?.status.includes(value), filteredInfo, value);
      //   return filteredInfo?.status.includes(value);
      // },
      ellipsis: true,
    },
  ];

  const handleChange = (pagination, filters) => {
    const _filteredInfo = { ...filteredInfo };
    _filteredInfo.category = filters?.category;
    _filteredInfo.status = filters?.status;
    _filteredInfo.type = filters?.type;
    _filteredInfo.name = filters?.name;
    _filteredInfo.items = filters?.items;

    set_filteredInfo(_filteredInfo);
  };
  let filtredData = [...dashboardMaintenances.data].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  ) || [];
  if (filteredInfo?.status && !!filteredInfo?.status.length) {
    filtredData = filtredData.filter((x) =>
      filteredInfo?.status.includes(
        STATUS.find((status) => status.value === x.status).text
      )
    );
  }

  if (filteredInfo?.type && !!filteredInfo?.type.length) {
    filtredData = filtredData.filter((x) =>
      filteredInfo?.status.includes(x.type?.name)
    );
  }

  if(search){
    filtredData = filtredData.filter(x => x.items[0].name.toLowerCase().includes(search));
  }

  const preventiveMaintenances = [];
  const controlMaintenances = [];
  const failures = [];
  for (let index = 0; index < filtredData.length; index++) {
    const element = filtredData[index];
    if(element.type.id === 1){
      preventiveMaintenances.push(element);
    }else if(element.type.id === CONTROL_ID){
      controlMaintenances.push(element);
    }else{
      failures.push(element);
    }
  }
  return (
    <>
      <PageHeader title={"Nadzorna ploča"} extra={
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-start", flexDirection: "row"}} >
          <Input placeholder="Pretrazi po imovini" onChange={({target: {value}}) => {
            let tmp = value.toLowerCase();
            set_search(tmp)}} />
          <div style={{width: 45}} />
          {(user.data.category === "MAN" || user.data.category === "LDR") &&  <Button onClick={() => set_createOrderVisible(true)} type="primary">Novi nalog</Button>}
       
        </div>
        }  />
      <div style={{height: 39}} />
      <div>
      <div style={{width: "100%"}}>
          <Information
            onClick={() => set_criticalPartsVisible(true)}
            count={criticalParts.length}
            icon="low"
            description={
              criticalParts.length === 1
                ? "Dio na niskoj zalihi"
                : "Dijelova na niskoj zalihi"
            }
          />

       
            <div  style={{width: 15, height: 20}} />
            <WaitingsOrdersByPart orders={dashboardMaintenances.data.filter(x => x.waiting_reasons.length > 0)} />
      </div>

        {/* KVAROCI */}
        <Typography.Title level={4} >Kvarovi</Typography.Title>
        <T
          className="dashboardErrorsTable"
          onChange={handleChange}
          rowKey={(x) => x.id}
          columns={columns}
          dataSource={
            failures
          }
        />

          {/* PREVENTIVNA */}
          <Typography.Title level={4} >Preventivna</Typography.Title>
          <T
          className="dashboardPreventiveOrdersTable"
          onChange={handleChange}
          rowKey={(x) => x.id}
          columns={columns}
          dataSource={
            preventiveMaintenances}
        />

       
      
        {/*Kontrla */}
        <Typography.Title level={4} >Kontrola</Typography.Title>
        <T
          className="dashboardControlOrdersTable"
          onChange={handleChange}
          rowKey={(x) => x.id}
          columns={columns.filter(x => x.key !== "technicians")}
          dataSource={controlMaintenances}
        />
        <Row gutter={24}>
          <Col span={12} >
            
<div>
              <Typography.Title level={4}>
                Tehničara {dashboardTechnicians.data.in_total}
              </Typography.Title>

              <div className={css.machinesChartContainerHorizontal}>
                <HorizontalBar
                  data={_technicianDataByStatus}
                  options={barOptions("top")}
                  getElementAtEvent={getElementAtEvent}
                />
              </div>

              <div className={css.machinesChartContainerHorizontal}>
                <HorizontalBar
                  data={_technicianDataByAvailability}
                  options={barOptions("bottom")}
                  getElementAtEvent={getElementAtEvent}
                />
              </div>
              </div>
              </Col>
              <Col span={12} >

            <div>
            <Typography.Title level={4}>
                Imovina u održavanju  
              </Typography.Title>
              <div className={css.machinesChartContainerHorizontal}>
                <HorizontalBar
                  getElementAtEvent={getElementAtEvent}
                  data={_machinesDataByStatus}
                  options={barOptions("top")}
                />
              </div>
              <div className={css.machinesChartContainerHorizontal}>
                <HorizontalBar
                  data={_machinesDataByOrders}
                  options={barOptions("bottom")}
                  getElementAtEvent={getElementAtEvent}
                />
              </div>
            </div>
       
          </Col>
        </Row>

        <DashboardTable
              sectionInfo={sectionInfo}
              orders={dashboardMaintenances.data}
              accounts={dashboardTechnicians.data.accounts}
              machinesDataByStatus={parsedAssets}
              machinesDataByOrders={[..._orders]}
              criticalParts={criticalParts}
              set_mapsVisible={set_mapsVisible}
            />
        <Modal
            destroyOnClose={true}
            className={"antdModal"}
            style={{ maxWidth: "900px" }}
            centered
            visible={criticalPartsVisible}
            onCancel={() => set_criticalPartsVisible(false)}
            footer={null}
            children={<CriticalParts />}
          />
        <Modal
            destroyOnClose={true}
            className={"antdModal"}
            style={{ maxWidth: "900px" }}
            centered
            visible={confirmPartsVisible}
            onCancel={() => set_confirmPartsVisible(false)}
            footer={null}
            children={<Warehouse />}
          />
          <Drawer destroyOnClose={true}   title="Nalog" width={750} children={  
           <NewOrder
            prepareRegistration={prepareRegistration.data}
            machines={machines.data}
            asset={null}
            orders={[]}
            update={false}
            order={{ data: null }}
            maintenance_for={"machine"}
            openCloseMaintenaceForm={(v) => set_createOrderVisible(v)}
            assetSelected={false}
            planned_start={null}
          />}
           visible={createOrderVisible} 
           onClose={() => set_createOrderVisible(false)} />
        
       
      </div>


      {/* STAROOOO DOLE */}

    
    </>
  );
}

const parseDataFromOrdersToAssets = (payload) => {
  let tmpArrAssets = [];

  payload.forEach((element) => {
    if (element.status !== "finished" || element.status !== "closed") {
      let last_id = 0;
      let el_to_push = {};

      element.items.forEach((item) => {
        el_to_push = {
          name: item.name,
          machine_run: element.machine_run,
          machine_id: item.id,
          location: item.info.location?.name,
          // category: item.info.cate.name,
        };
        last_id = item.id;
      });

      let index = tmpArrAssets.findIndex((item) => item.machine_id === last_id);
      if (index === -1 || index === undefined) {
        tmpArrAssets.push(el_to_push);
      } else {
        tmpArrAssets[index] = el_to_push;
      }
    }
  });

  return tmpArrAssets;
};

const isExsitsName = (item, data) => {
  for (let i = 0; i < data.length; i++) {
    if (item.name === data[i].text) {
      return true;
    }
  }
  return false;
};
