import React, { Component, useState } from "react";

import { Row, Col, Tabs } from "antd";
import {  useSelector } from "react-redux";
import CategoriesList from "./CategoriesList";
import CategoryForm from "./CategoriesForm";


const { TabPane } = Tabs;

const Categories = ({}) => {
    const [updateData, set_updateData] = useState(null);

 

    const { categories } = useSelector(
      (state) => state.machinesReducer.prepareRegistration.data
    );

    return (
      <>
        <Row>
          <Col md={12} sm={24}>
            <CategoryForm
              update={!!updateData}
              updateData={updateData}
              set_updateData={set_updateData}
            />
          </Col>
          <Col md={12} sm={24}>
            <CategoriesList set_updateData={set_updateData} list={categories} />
          </Col>
        </Row>
      </>
    );
}

 
 
export default Categories;
