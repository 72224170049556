import React, { useEffect, useRef, useState } from "react";

import { Alert, Button, Spin, Table, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../components/LoadingScreen";
import css from "../workeffectiveness.module.scss";

import { Link } from "react-router-dom";
import { TECHNICIANS_RESPONSE_TIME, TECHNICIAN_GENEAL_EFFECT } from "../../../../redux/modules/Analytics/actions";

// Components
import { Bar } from "@ant-design/plots";
import CALL_ICON from "../../../../assets/icons/call-icon.png";
import FLAG_ICON from "../../../../assets/icons/flag-icon.png";
import SUCCESS_ICON from "../../../../assets/icons/success-icon.png";
import TOTAL_ICON from "../../../../assets/icons/total-icon.png";
import TOTAL_UNCLOSED_ICON from "../../../../assets/icons/total-unclosed-icon.png";
import { crohHumanizer } from "../../../../helpers/consts";
import Information from "./Cards/Information";
import { useReactToPrint } from "react-to-print";
import MaintenancePDF from "./PDF";

const AvgUserResponseTime = ({}) => {
  // Hooks
  const dispatch = useDispatch();
  const {
    techniciansResponseTime,
    techniciansLoginAndWorkTime,
    pickedDate,
    technicianGenealEffect,

    workEffectivenessReports: { filters },
  } = useSelector((state) => state.AnalyticsReducer);

  const divRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => divRef.current,
  });

  // Methods
  const getReports = (queryParams) => {
    dispatch({ type: TECHNICIANS_RESPONSE_TIME, queryParams });
    // dispatch({ type: TECHNICIANS_LOGIN_AND_WORK_TIME, queryParams }); ne korsiti se od 3.7. 2024
    // dispatch({ type: TECHNICIAN_AVAILABILITY_TREND, queryParams }); ne korsiti se od 3.7. 2024
  };

  useEffect(() => {
    const queryParams = {
      from: pickedDate.data.from,
      to: pickedDate.data.to,
      technicians: filters.technicians.join("|"),
      assets_category: filters.assets_category.join("|"),
      locations: filters.locations.join("|"),
    };
    getReports(queryParams);
  }, [pickedDate, filters]);

  useEffect(() => {
    const queryParams = {
      from: pickedDate.data.from,
      to: pickedDate.data.to,
      technicians: filters.technicians.join("|"),
    };
    getBarReports(queryParams);
  }, [pickedDate, filters]);

  const getBarReports = (queryParams) => {
    dispatch({ type: TECHNICIAN_GENEAL_EFFECT, queryParams });
  };

  const barData = technicianGenealEffect.data.general_effect_per_technician.flatMap(
    ({ name, closed, unclosed, account_category }) => {
      const total = closed + unclosed;
      const closedPercentage = total === 0 ? 0 : (closed / total) * 100;
      const unclosedPercentage = total === 0 ? 0 : (unclosed / total) * 100;
      let _name = name + (account_category === "LDR" ? " (Voditelj)" : "");
      return [
        {
          name: _name,
          type: "Zaključeni",
          value: closed,
          percentage: `${closed} (${closedPercentage?.toFixed(1)}%)`,
        },
        {
          name: _name,
          type: "Nezaključeni",
          value: unclosed,
          percentage: `${unclosed} (${unclosedPercentage.toFixed(1)}%)`,
        },
      ];
    }
  );

  const config = {
    data: barData,
    xField: "name",
    yField: "value",
    colorField: "type",
    stack: true,
    label: {
      text: ({ value, percentage }) => {
        return value === 0 ? "" : percentage;
      },
      transform: [
        {
          type: "contrastReverse",
        },
        {
          type: "overflowHide",
        },
      ],
      position: "inside",
      style: {
        textAnchor: "start",
        fontWeight: 600,
        color: "black",
      },
    },
    interaction: {
      tooltip: {
        render: (e, { title, items }) => {
          return (
            <div
              style={{
                fontFamily: "Poppins, sans-serif",
                display: "flex",
                flexDirection: "column",
                gap: 12,
                minWidth: 180,
              }}
            >
              <Typography.Text style={{ color: "black" }}>{title}</Typography.Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 8,
                }}
              >
                {items.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                      <div
                        style={{
                          width: 8,
                          height: 8,
                          borderRadius: 8,
                          backgroundColor: item.color,
                        }}
                      ></div>
                      <Typography.Text>{`${item.name}: `}</Typography.Text>
                    </div>
                    <Typography.Text strong>{item.value}</Typography.Text>
                  </div>
                ))}
              </div>
            </div>
          );
        },
      },
    },
    // legend: false,
    axis: {
      x: {
        labelFormatter: (v) => {
          let parsedName = v.split("|");
          return parsedName[0];
        },
      },
    },
  };

  const dataSource = techniciansResponseTime.data.data.data?.map((x) => ({
    name: x.first_name + " " + x.last_name,
    ...x,
  }));

  const COLUMNS = [
    {
      title: (
        <div>
          Tehničar<span style={{ color: "red", fontWeight: 500 }}>{` (${dataSource.length})`}</span>
        </div>
      ),
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => (
        <div>
          <Link target={"_blank"} to={`/accounting/${record.account_id}/about`}>
            {record.first_name + " " + record.last_name + (record.account_category === "LDR" ? " (Voditelj)" : "")}
          </Link>
        </div>
      ),
    },

    {
      title: "Prosječno vrijeme odaziva tehničara",
      dataIndex: "avg_response_time",
      width: 284,
      key: "avg_response_time",
      render: (text, record, index) => (
        <div style={{ width: 284, textAlign: "center" }}>{record.avg_response_time?.toFixed(1)} h</div>
      ),
    },
    // TODO
    {
      title: "Prosječno vrijeme izvršenja održavanja",
      dataIndex: "time",
      width: 284,
      key: "time",
      render: (text, record, index) => (
        <div style={{ width: 284, textAlign: "center" }}>{record.avg_execution_time?.toFixed(1) || "-"} h</div>
      ),
    },

    {
      title: "Izvršeno incidentnih",
      dataIndex: "incident_time",
      width: 160,
      render: (text, record, index) => (
        <>
          <Typography.Text strong>{record.inc.count}</Typography.Text>
          <Typography.Text style={{ marginLeft: 18 }}>{record.inc.total_time?.toFixed(1)} h</Typography.Text>
        </>
      ),
    },
    {
      title: "Izvršeno korektivnih",
      dataIndex: "corrective_time",
      width: 160,
      render: (text, record, index) => (
        <>
          <Typography.Text strong>{record.corr.count}</Typography.Text>
          <Typography.Text style={{ marginLeft: 18 }}>{record.corr.total_time?.toFixed(1)} h</Typography.Text>
        </>
      ),
    },
    {
      title: "Izvršeno preventivnih",
      dataIndex: "preventive_time",
      width: 160,
      render: (text, record, index) => (
        <>
          <Typography.Text strong>{record.prev.count}</Typography.Text>
          <Typography.Text style={{ marginLeft: 18 }}>{record.prev.total_time?.toFixed(1)} h</Typography.Text>
        </>
      ),
    },
    {
      title: "Ukupno",
      dataIndex: "total_time",
      width: 160,
      render: (text, record, index) => {
        const total = record.inc.total_time + record.corr.total_time + record.prev.total_time;
        const count = record.inc.count + record.corr.count + record.prev.count;
        return (
          <div>
            <Typography.Text strong>{count}</Typography.Text>
            <Typography.Text style={{ marginLeft: 18 }}>{total?.toFixed(1)} h</Typography.Text>
          </div>
        );
      },
    },
    {
      title: "Vrijeme na poslu",
      dataIndex: "work_time",
      key: "work_time",
      width: 128,
      render: (text, record, index) => <div style={{ width: 128 }}>{record.work_time?.toFixed(1)} h</div>,
    },
  ];

  if (techniciansResponseTime.status === "loading" || techniciansLoginAndWorkTime.status === "loading") {
    return <Loading />;
  }

  return (
    <div id="avg-user-response-time">
      {techniciansResponseTime.status === "error" || techniciansLoginAndWorkTime.status === "error" ? (
        <Alert
          style={{ width: "639px", marginTop: "10px", zIndex: "1" }}
          message="Dogodila se greška"
          description="Dogodila se greška prilikom dohvata podataka molimo vas probajte ponovo!"
          type="error"
        />
      ) : null}
      <div id={css.workEffectivenessContainer}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 20,
          }}
        >
          <Information
            totalWidth={400}
            text={"Ovim pokazateljom se mjeri spremnost tehničara za pokretanje nedodjeljenog održavanja."}
            information={techniciansResponseTime.data.data.total_avg_response_time?.toFixed(1)}
            label="Prosječno vrijeme odaziva tehničara "
            hours
            icon={CALL_ICON}
          />
          {/* Prosječno vrijeme izvršenja održavanja */}
          <Information
            totalWidth={400}
            text="Ovim pokazateljom se mjeri učinkovitost tehničara u izvršenju održavanja."
            information={+techniciansResponseTime.data.data.total_avg_execution_time?.toFixed(1)}
            label="Prosječno vrijeme izvršenja održavanja"
            hours
            icon={FLAG_ICON}
          />
          <Information
            text={
              "Pokazatelj o uspješnosti izvršenja održavanja. Za izračun se uzima ukupan broj izvršenih održavanja po svim tipovima od kojeg se oduzmu održavanja koja nisu uspješno otklonjena, te se dobivena vrijednost potom dijeli sa ukupnim brojem izvršenih održavanja."
            }
            totalWidth={400}
            information={technicianGenealEffect.data.execution_success_percent?.toFixed(1) + "%"}
            label="Uspješnost izvršenja održavanja"
            icon={SUCCESS_ICON}
          />

          <Information
            totalWidth={400}
            text={
              "Prikaz ukupnog broja održavanja na kojima je radio tehničar ili tehničari. Za izračun se uzimaju održavanja po svim tipovima."
            }
            information={technicianGenealEffect.data.total_number_of_maintenance}
            label="Ukupan broj održavanja"
            icon={TOTAL_ICON}
          />
          <Information
            totalWidth={400}
            text={
              "Prikaz ukupnog broja održavanja koja nisu uspješno izvršena, a koja je izvršio tehničar ili tehničari. Za izračun se uzimaju održavanja po svim tipovima."
            }
            information={technicianGenealEffect.data.total_number_of_unclosed_maintenance}
            label="Ukupan broj neotklonjenih održavanja"
            icon={TOTAL_UNCLOSED_ICON}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
          <MaintenancePDF
            data={dataSource}
            date={pickedDate.data}
            footerData={techniciansResponseTime.data.data}
            action="open"
          />
        </div>
        <div ref={divRef} className="avg-printable-content">
          <h2 className="table-title">Vremenski i opći učinak</h2>
          <Table
            style={{ marginTop: 20 }}
            pagination={false}
            rowKey={(record) => record.account_id}
            columns={COLUMNS}
            dataSource={dataSource}
            footer={(data) => {
              let totalCount =
                techniciansResponseTime.data.data.inc_count +
                techniciansResponseTime.data.data.corr_count +
                techniciansResponseTime.data.data.pre_count;

              let totalTime =
                Number(techniciansResponseTime.data.data.inc_time?.toFixed(1)) +
                Number(techniciansResponseTime.data.data.corr_time?.toFixed(1)) +
                Number(techniciansResponseTime.data.data.pre_time?.toFixed(1));

              return (
                <div
                  style={{
                    width: "100%",
                    padding: 0,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <div style={{ width: 160, padding: "16px" }}>
                    <Typography.Text strong>{techniciansResponseTime.data.data.inc_count}</Typography.Text>
                    <Typography.Text style={{ marginLeft: 18 }}>
                      {techniciansResponseTime.data.data.inc_time?.toFixed(1)}h
                    </Typography.Text>
                  </div>
                  <div style={{ width: 160, padding: "16px" }}>
                    <Typography.Text strong>{techniciansResponseTime.data.data.corr_count}</Typography.Text>
                    <Typography.Text style={{ marginLeft: 18 }}>
                      {techniciansResponseTime.data.data.corr_time?.toFixed(1)}h
                    </Typography.Text>
                  </div>
                  <div style={{ width: 160, padding: "16px" }}>
                    <Typography.Text strong>{techniciansResponseTime.data.data.pre_count}</Typography.Text>
                    <Typography.Text style={{ marginLeft: 18 }}>
                      {techniciansResponseTime.data.data.pre_time?.toFixed(1)}h
                    </Typography.Text>
                  </div>
                  <div
                    style={{
                      width: 160,
                      padding: "16px",
                      paddingRight: 0,
                      backgroundColor: "#f1f1f1",
                    }}
                  >
                    <Typography.Text strong>{totalCount}</Typography.Text>
                    <Typography.Text style={{ marginLeft: 18 }}>{totalTime?.toFixed(1)}h</Typography.Text>
                  </div>
                  <div
                    style={{
                      width: 160,
                    }}
                  />
                </div>
              );
            }}
          />
        </div>
        {/* Chart */}
        {/* Header */}
        <div className={css.header} style={{ marginBottom: 24, marginTop: 40 }}>
          <p className={css.value} style={{ color: "unset" }}>
            Pregled općeg učinka po tehničaru
          </p>
        </div>
        <Spin spinning={technicianGenealEffect.status === "loading"}>
          {/* barData.length / 2 because we have 2 bars per user */}
          <div style={{ height: Math.max(180, (barData.length / 2) * 52) }}>
            <Bar {...config} />
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default AvgUserResponseTime;

const formatForReports = (time) => {
  return crohHumanizer(Math.ceil(time));
};
