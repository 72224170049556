import { Button } from "antd";
import React from "react";

const LoadMoreButton = ({ onClick, loading }) => {
  return (
    <div style={{padding: "9px 0", textAlign: "center"}} >
      <Button
        loading={loading}
        onClick={onClick}
        type="dashed"
        style={{ width: "300px" }}
      >
        Učitaj još
      </Button>
    </div>
  );
};

export default LoadMoreButton;
