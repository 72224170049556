import React, { useState, useEffect } from "react";

import { withRouter } from "react-router";
import Spinner from "../../../components/LoadingScreen";

import {  Button, Icon, Alert, Input } from "antd";

import {  useDispatch } from "react-redux";


import AccountsTable from "../../../components/Table/accounts";

const { Search } = Input;

function Table({
  accounts,
  prepareRegistration,
  toggleDrawer,
  manageUsersVisible,
  history,
}) {
  const dispatch = useDispatch();

  // Variables
  const [functionsAddVisible, setFunctionsAddVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState({
    text_search: null,
    searchedItems: [],
    loading: false,
  });

  // Filtering accounts that are not suppliers
  // accounts.data = accounts.data.filter((item) => item.category !== "SUP");

  const handleSearch = async (text_search, event) => {
    if (event.target.nodeName === "INPUT") {
      setSearch({
        text_search: null,
        searchedItems: [],
        loading: false,
      });
      return;
    }
    if (text_search === null) {
      return;
    }
    const _search = {
      text_search,
      loading: true,
      searchedItems: search.searchedItems,
    };
    setSearch(_search);

    let tmpArr = [];
    for (let i = 0; i < accounts.data.length; i++) {
      if (
        accounts.data[i].full_name
          .toLowerCase()
          .includes(text_search.toLowerCase())
      ) {
        tmpArr.push(accounts.data[i]);
      }
    }
    const after_search = {
      text_search,
      loading: false,
      searchedItems: tmpArr,
    };

    setSearch(after_search);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("page");
    setPage(myParam == null ? 1 : myParam);
  }, []);

  const onPageChange = (pagination) => {
    history.push({ search: `?page=${pagination}` });
  };


  if (
    prepareRegistration.status === "loading" ||
    accounts.status === "loading"
  ) {
    return <Spinner />;
  }

  const alertError = (
    <Alert
      style={{ marginBottom: "10px", zIndex: "1" }}
      message="Dogodila se greška"
      description="Dogodila se greška prilikom dohvata podataka sa servera molimo vas probajte ponovo!"
      type="error"
    />
  );

  return (
    <>
      <div>
        <div>{accounts.status === "error" ? alertError : null}</div>
        <div>{accounts.status === "loading" ? <Spinner /> : null}</div>

        <div
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <AccountsTable
            categories={prepareRegistration.data.user_categories}
            button={() => (
                <Button
                    style={{ float: "right" }}
                    type="primary"
                    onClick={() => toggleDrawer(true)}
                    >
                    {!manageUsersVisible ? (
                        <>
                        Dodaj <Icon type="plus" />
                        </>
                    ) : (
                        "Zatvori"
                    )}
                </Button>
            )}
            search={() => (
                <Search
                style={{ width: "300px", marginRight: "16px" }}
                allowClear={true}
                placeholder="Unesi naziv"
                enterButton
                size="default"
                defaultValue={""}
                onPressEnter={(event) =>
                    handleSearch(event.target.value, {
                    target: { nodeName: "BUTTON" },
                    })
                }
                onSearch={handleSearch}
            />
            )}
            data={
              search.text_search !== null && !search.loading
                ? search.searchedItems
                : accounts.data.filter((item) => item.category !== "SUP")
            }
            toggleDrawer={toggleDrawer}
            onPageChange={onPageChange}
            page={page}
          />
        </div>
      </div>

    </>
  );
}


export default withRouter(Table);
