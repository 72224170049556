import React, { Component } from "react";

import { connect } from "react-redux";


import { withRouter } from "react-router";

import { erp_actions } from "../../../../redux/modules/ERP";


import User from "./User";

// AntDesign Components
import { Alert, Switch as Antd_switch, Row, Col } from "antd";

class Accounting extends Component {
  state = {
    importFromERP: false,
  };

  componentDidMount() {}

  handleTabClick = (key) => {
    this.props.history.push(`/accounting/${key}`); // < == router router v4
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
    if (
      this.props.erp_users.status === "error" &&
      this.props.erp_users.status !== prevProps.erp_users.status
    ) {
      this.setState({ importFromERP: false });
    }
  }

  onRouteChanged() {
    console.log(this.props);
  }

  render() {
    const {  erp_users, update, user } = this.props;

    return (
      <div>
        {this.props.erp_users.status === "error" ? (
          <Alert
            style={{ marginBottom: "10px", zIndex: "1" }}
            message="Dogodila se greša"
            description="Dogodila se greška prilikom dohvata podataka sa ERP-a molimo vas probajte ponovo!"
            type="error"
          />
        ) : null}

        <Row type="flex" align="middle">
          <Col sm={12} xs={24}>
            {" "}
          </Col>
          <Col sm={12} xs={24}>
            {" "}
            <div
              style={{
                display: "flex",
                padding: "9px 0",
              }}
            >
              <div style={{ marginRight: "9px" }}>Koristi ERP podatke:</div>
              <Antd_switch
                checked={this.state.importFromERP}
                loading={erp_users.status === "loading"}
                title="Uvezi iz ERP-a"
                onChange={() => {
                  if (!this.state.importFromERP) {
                    this.props.onGetErpUsers();
                  }
                  this.setState({ importFromERP: !this.state.importFromERP });
                }}
              />
            </div>
          </Col>
        </Row>

        <User
          importFromERP={this.state.importFromERP}
          type={"technican"}
          erp_users={this.props.erp_users}
          update={update}
          user={user}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    erp_users: state.erpReducer.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetErpUsers: () => dispatch({ type: erp_actions.ERP_USERS }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Accounting)
);
