import React from "react";
import { Tabs } from "antd";
import {
  ProfileOutlined,
  ToolOutlined,
  FileSearchOutlined,
  SettingOutlined
} from "@ant-design/icons";

import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";

const { TabPane } = Tabs;

function ProfileTabs(props) {
  // Variables
  
  //? Saving tab after page refresh
  let tab = props.location.pathname.slice(props.match.url.length + 1)

  const TABS = [
    { name: "Osnovno", path: "about", icon: <ProfileOutlined /> },
    { name: "Održavanja", path: "maintenances", icon: <ToolOutlined /> },
    { name: "Dokumenti", path: "documents", icon: <FileSearchOutlined /> },
    {
      name: "Imovina",
      path: "assets",
      icon: (
        <>
          {" "}
          <SettingOutlined
            style={{
              position: "relative",
              display: "inlineBlock",
              left: "-7px",
              bottom: "-4px",
              margin: 0,
            }}
          />
          <SettingOutlined
            style={{
              position: "relative",
              display: "inlineBlock",
              left: "-10px",
              top: "-4px",
              margin: 0,
            }}
          />
        </>
      ),
    },
  ];

  // Methods
  const handleClick = (key) => {
    props.history.replace(key);
    props.setActiveKey();
  };

  return (
    <div style={{ marginBottom: 0, marginTop: 20 }}>
      <Tabs defaultActiveKey={tab} onChange={handleClick}>
        {TABS.map((item) => (
          <TabPane
            tab={
              <>
                {item.icon}
                <NavLink to={`${props.match.url}/${item.path}`}>
                  {item.name}
                </NavLink>
              </>
            }
            key={item.path}
          />
        ))}
      </Tabs>
    </div>
  );
}

export default withRouter(ProfileTabs);
