import React, { Component } from "react";
import { Form, Icon, Input, Button, Alert } from "antd";

import * as actions from "../../redux/modules/Users/actions";
import { connect } from "react-redux";

import css from './auth.module.scss'
import Logo from '../../assets/icons/logo.png'


function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class Login extends Component {
  componentDidMount() {
    this.props.form.validateFields();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onLogin(values.email, values.password);
      }
    });
  };

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched
    } = this.props.form;

    // Only show error after a field is touched.
    const emailError = isFieldTouched("email") && getFieldError("email");
    const passwordError =
      isFieldTouched("password") && getFieldError("password");

      const {user} = this.props;

    return (
    
      <div className={css.loginContainer}>    
        <Form
          layout="vertical"
          onSubmit={this.handleSubmit}
          className={css.loginForm}
          style={{ padding: 12 }}
        >
           <div className={css.info}>
            <img className={css.appLogo} src={Logo}/>
            <h1 className={css.appName}>Fiks</h1>
            <h4 className={css.loginText}>Prijavite se</h4>
          </div>
          <Form.Item
            validateStatus={emailError ? "error" : ""}
            help={emailError || ""}
            style={{ margin: "10px 28px", marginBottom: 10 }}
            label="E-mail adresa"
          >
            {getFieldDecorator("email", {
              rules: [
                { required: true, message: "Please input your email!" }
              ]
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="email"
                placeholder="E-Mail..."
                autoComplete="new-email"
                style={{ height: "38px" }}
              />
            )}
          </Form.Item>
          <Form.Item
            validateStatus={passwordError ? "error" : ""}
            help={passwordError || ""}
            style={{ margin: "10px 28px", marginBottom: 32 }}
            label="Lozinka"
          >
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "Please input your Password!" }
              ]
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="password"
                placeholder="Lozinka..."
                autoComplete="new-password"
                style={{ height: "38px" }}
              />
            )}
          </Form.Item>
          <Form.Item style={{ width: 100, margin: "auto" }}>
            <Button
            loading={this.props.user.loading === "loading_login"}
              block
              type="primary"
              htmlType="submit"
              disabled={hasErrors(getFieldsError())}
            >
              Login
            </Button>
          </Form.Item>
          {this.props.user.status === 400 ? (
          <Alert
            style={{ maxWidth: "360px", "margin": "0 auto", marginTop: 20 }}
            message="Netocni podaci"
            description="Vas E-mail ili lozinka ne odgovaraju."
            type="warning"
            showIcon
          />
        ) : null}
        </Form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLogin: (email, password) =>
      dispatch({ type: actions.LOGIN, credentials: { email, password } })
  };
};

const mapStateToProps = state => {
  return {
    user: state.usersReducer.user
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: "login" })(Login));
