import humanizeDuration from "humanize-duration";

export const TYPES_OF_USERS = [
  { value: "TEH", text: "Tehničar" },
  { value: "MAN", text: "Upravitelj" },
  { value: "REP", text: "Poslovođa" },
  { value: "WRH", text: "Skladištar" },
  { value: "LDR", text: "Voditelj" },
];

export const crohHumanizer = humanizeDuration.humanizer({
  language: "hr",
  units: ["h", "m"],
  maxDecimalPoints: 0,
});

export const LIMIT_FOR_LOAD_MORE = 60000;

export const UNITS_OF_MESURE = [
  // litar metar kilogram komad
  {
    text: "Komad",
    sign: "ko",
  },
  {
    text: "Litar",
    sign: "l",
  },
  {
    text: "Metar",
    sign: "m",
  },
  {
    text: "Kilogram",
    sign: "kg",
  },
];

export const ALLOWED_SIZE = 5;

export const UNITS_OF_MESURE_CURRENCY = [
  {
    text: "Marka",
    sign: "BAM",
  },
  {
    text: "Euro",
    sign: "€",
  },
];

export const STATUS = [
  { text: "Nedodijeljen", value: "in_bowl" },
  { text: "Prijavljen", value: "reported" },
  { text: "Odbačen", value: "discarded" },
  { text: "Dodijeljen", value: "appointed" },
  { text: "U tijeku", value: "in_progress" },
  { text: "Na čekanju", value: "waiting" },
  { text: "Završen", value: "finished" },
  { text: "Potvrđeno završen", value: "closed" },
  { text: "Neotklonjen", value: "unclosed" },
  { text: "Zakazan", value: "scheduled" },
];

export const LOCATION_TYPES = {
  GEOLOCATION: "geo",
  WARHOUSE: "wrh",
};
export const STATUS_KEYS = {
  IN_BOWL: "in_bowl",
  REPORTED: "reported",
  DISCARDED: "discarded",
  APPOINTED: "appointed",
  IN_PROGRESS: "in_progress",
  WAITING: "waiting",
  FINISHED: "finished",
  CLOSED: "closed",
  UNCLOSED: "unclosed",
  SCHEDULED: "scheduled",
};

export const ALLOWED_EXTENSIONS = [
  {
    type: "video",
    text: "videozapis",
    extensions: ".mp4,.webm,.ogg",
  },
  { type: "image", text: "sliku", extensions: ".png,.jpg,.jpeg" },
  {
    type: "document",
    text: "dokument",
    extensions: ".pdf",
  },
];

export const INSURANCE_ORDERS_STATUS_KEYS = {
  REPORTED: "reported",
  CONFIRMED: "confirmed",
  DENIED: "denied",
  IN_PROGRESS: "in_progress",
  SUCCESS: "success",
  FAILED: "failed",
  WAITING: "waiting",
};

export const INSURANCE_ORDERS_STATUS = [
  { value: "reported", text: "Prijavljen" },
  { value: "denied", text: "Odbačen" },
  {
    value: "confirmed",
    text: "Potvrđen",
  },
  { value: "waiting", text: "Na čekanju" },
  { value: "in_progress", text: "U tijeku" },
  { value: "success", text: "Uspješno" },
  { value: "failed", text: "Neuspješno" },
];


export const JURE_FORMAT_DATE = "DD.MM.YYYY. HH:mm";  