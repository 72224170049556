import React, { useState } from "react";

import { List, Icon, Popconfirm, Spin, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  DELETE_CATEGORY,
  UPDATE_SUPPLIER_CATEGORY,
} from "../../../../redux/modules/Accounting/actions";

const CategoriesList = ({ list, setUpdate }) => {
  const dispatch = useDispatch();

  const { updateSupplierCategory } = useSelector(
    (store) => store.accoutingReducer
  );

  const deleteListItem = (id) => {
    dispatch({
      type: UPDATE_SUPPLIER_CATEGORY,
      id,
      data: { is_active: false },
      errorCallback: () => {
        message.error("Upss dogodila se greška pokušaj ponovo", 3);
      },
    });
  };

  return (
    <Spin spinning={updateSupplierCategory.status === "loading"}>
      <List
        style={{ marginLeft: "32px", paddingTop: "0px",maxHeight: "75vh", overflow: "auto" }}
        header={<div className={"category-list"}>Postojeće funkcije</div>}
        itemLayout="horizontal"
        dataSource={list}
        renderItem={(item) => (
          <List.Item
            style={{ padding: "3px", marginLeft: "2px" }}
            extra={
              <>
                <Icon
                  type="edit"
                  style={{
                    cursor: "pointer",
                    marginRight: "20px",
                    fontSize: "18px",
                    color: "#4890ff",
                  }}
                  onClick={() => {
                    setUpdate(item);
                  }}
                />
                <Popconfirm
                  title="Jeste li sigurni da želite izbrisati?"
                  placement="left"
                  okText="Izbriši"
                  cancelText="Odustani"
                  onConfirm={() => deleteListItem(item.id)}
                >
                  <Icon
                    href="#"
                    type="delete"
                    style={{
                      cursor: "pointer",
                      marginRight: "0px",
                      fontSize: "18px",
                      color: "#4890ff",
                    }}
                  />
                </Popconfirm>
              </>
            }
          >
            <List.Item.Meta
              className={"category-list-item"}
              description={item.name}
            />
          </List.Item>
        )}
      />
    </Spin>
  );
};

export default CategoriesList;
