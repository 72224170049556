import { List, Input, Alert, Button, Checkbox, Select, Form } from "antd";
import React, { Component } from "react";

import {
  isTechnicianPrimary,
  technicianOfOrder,
} from "../../../helpers/getSpecValueFromOrder";
import SizeType from "../../../helpers/deviceTypeSize";

const { Option } = Select;

const reasons = [
  { value: "missing_part", text: "nedostaje zamjenski dio." },
  { value: "next_order", text: "Preuzimam drugi nalog" },
  { value: "need_help", text: "Ne znam napravit" },
  { value: "end_shift", text: "kraj smjene." },
  { value: "other", text: "ostalo" },
];

class Reason extends Component {
  state = {
    reason: {
      text: "",
      value: "",
    },
    primary_id: 0,
    reasonText: "",
  };

  setValue = (reason) => {
    this.setState({ reason });
  };

  setReasontext = (reasonText) => {
    this.setState({ reasonText: reasonText.target.value });
  };

  onChange = (primary_id) => {
    this.setState({ primary_id });
  };

  render() {
    const { pauseOrder, reason_type, currentUser, rejectOrder } = this.props;

    const order = this.props.orders.data.find(
      (item) => item.id === this.props.order_id
    );

    if (
      order.technicians.filter(
        (x) => x.account.id !== currentUser.id && x.is_working
      ).length === 0 &&
      reason_type === "reject" &&
      isTechnicianPrimary(order, currentUser)
    ) {
      return (
        <>
          <h2>
            Ne možete odstupiti sa naloga jer ste jedini preostali tehničar!
          </h2>
          <h3>Možete stavit nalog na čekanje!</h3>
        </>
      );
    }

    const alert = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1" }}
        message="Dogodila se greša"
        description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
        type="error"
      />
    );

    const initialValue = order
      ? order.technicians.filter(
          (x) => x.account.id !== currentUser.id && x.is_working
        ).length === 1
        ? order.technicians.filter(
            (x) => x.account.id !== currentUser.id && x.is_working
          )[0].id
        : null
      : 0;

    console.log(this.props.reason_type);

    const { getFieldDecorator, getFieldsError } = this.props.form;
    const layoutType =
      SizeType() === "xs" || SizeType() === "sm" ? "vertical" : "horizontal";
    return (
      <div>
        {pauseOrder.status === "error" || rejectOrder.status === "error"
          ? alert
          : null}
        <div
          style={{ padding: "6px", display: "flex", justifyContent: "center" }}
        >
          {reason_type === "reject" &&
          isTechnicianPrimary(order, currentUser) &&
          order.technicians.length > 1 ? (
            <Form layout={layoutType}>
              <Form.Item labelAlign="left" label="Odgovorni">
                {getFieldDecorator("primary_id", {
                  initialValue,
                  rules: [
                    {
                      required: true,
                      message: "Odaberi stavku",
                    },
                  ],
                })(
                  <Select
                    onChange={this.onChange}
                    style={{ width: "270px" }}
                    mode="single"
                    allowClear={true}
                    showSearch
                    placeholder="Odaberi odgovornog"
                    optionFilterProp="name"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {order.technicians
                      .filter(
                        (x) => x.account.id !== currentUser.id && x.is_working
                      )
                      .map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.account.full_name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Form>
          ) : null}
        </div>
        <hr />
        <List
          header={<h3>Unesite razlog</h3>}
          itemLayout="vertical"
          dataSource={reasons}
          renderItem={(item) => (
            <List.Item extra={null}>
              <List.Item.Meta
                title={
                  <div>
                    {item.text}
                    <div style={{ float: "right" }}>
                      {" "}
                      <Checkbox
                        checked={item.value === this.state.reason.value}
                        onChange={() => this.setValue(item)}
                      />
                    </div>
                  </div>
                }
                description={
                  item.value === "other" &&
                  item.value === this.state.reason.value ? (
                    <Input.TextArea
                      onChange={this.setReasontext}
                      rows={5}
                      minLength={6}
                    />
                  ) : null
                }
              />
            </List.Item>
          )}
        />
        <Button
          loading={
            pauseOrder.status === this.props.order_id ||
            rejectOrder.status === "loading"
          }
          disabled={
            disablePauseOrderButton({
              reason: this.state.reason,
              reasonText: this.state.reasonText,
            }) ||
            (reason_type === "reject"
              ? !this.props.form.getFieldValue("primary_id") &&
                isTechnicianPrimary(order, currentUser)
              : false)
          }
          type="primary"
          onClick={() =>
            this.props._pauseOrder(
              this.props.order_id,
              {
                primary_id: this.props.form.getFieldValue("primary_id"),
                status: this.state.reason.value,
                reason: this.state.reasonText,
                technician_id: technicianOfOrder(order, currentUser).id,
                is_primary: isTechnicianPrimary(order, currentUser),
              },
              reason_type
            )
          }
        >
          Spremi
        </Button>
      </div>
    );
  }
}

const disablePauseOrderButton = (data) => {
  if (data.reason.value === "") {
    return true;
  }
  if (data.reason.value === "other" && data.reasonText.length < 6) {
    return true;
  }
  return false;
};

export default Form.create()(Reason);
