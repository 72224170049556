export const setUserModel = (data, update) => {
  let model = {
    last_name: "",
    first_name: "",
    categories: [],
    photo: [],
    address: "",
    phoneNumber: "",
    email: "",
    document: [],
    remark: "",
    deviceNumber: "",
    type: "TEH",
    facility_subcategories: [],
    assets: [],
    location: null,
    codeks_id: "",
  };

  if (update) {
    model.last_name = data.user.last_name;
    model.first_name = data.user.first_name;
    model.categories = data.user.categories.map((x) => x.id);
    model.photo = data.photo;
    model.address = data.address;
    model.phoneNumber = data.phone_number;
    model.email = data.email;
    model.document = data.document;
    model.remark = data.remark;
    model.deviceNumber = data.device_id;
    model.type = data.category;
    model.facility_subcategories = data.facility_subcategories.map(
      (item) => item.id
    );
    model.assets = data.assets ? data.assets.map((x) => x.id) : []; // dok ne dovrsi dragan
    model.location = data.location?.id;
    model.codeks_id = data.user.codeks_id;
  }

  return model;
};

export const setSupplierModel = (data, update) => {
  let model = {
    id_pdv: "",
    url: "",
    is_internal: false,
    first_name: "",
    last_name: "",
    categories: [],
    photo: [],
    address: "",
    phoneNumber: "",
    email: "",
    document: [],
    remark: "",
    deviceNumber: "",
    type: "SUP",
  };

  if (update) {
    model = {
      ...model,
      ...data,
    };

    model.first_name = data.full_name;
    model.categories = data.company.categories.map((x) => x.id);
  }

  return model;
};
