import { takeLatest, put, call, select, delay } from "redux-saga/effects";

import * as actions from "./actions";

import axios_maintenances from "../../../axios/axios-maintenances";
import {
  DESTROY_TOKEN_FOR_REDIRECT,
  LOGOUT,
  USER_SUCCESS,
} from "../Users/actions";

import allUsersConfirmOrder from "../../../helpers/allUsersConfirmOrder";

import store from "../../../store";
import { INSURANCE_ORDERS_STATUS_KEYS } from "../../../helpers/consts";

const initialState = {
  workOrder: {
    status: "",
    data: {},
  },
  workOrders: {
    status: "",
    data: [],
  },
  orders: {
    data: [],
    status: "loading",
    confirmations: [], // neznam cemu sluzi mislim nicemu
  },
  setUsersToOrder: {
    status: "",
  },
  startOrder: {
    status: "",
  },
  finishOrder: {
    status: "",
  },
  pauseOrder: {
    status: "",
  },

  rejectOrder: {
    status: "",
  },
  ordersToConfirm: {
    status: "",
    message: "",
    data: "",
  },
  confirmOrder: {
    status: "",
    message: "",
  },
  calendarMaintenances: {
    status: "",
    data: [],
  },
  insuranceOrders: {
    status: "",
    data: [],
  },
  insurance: {
    status: "loading",
    data: {},
  },
  addInsuranceMaterial: {
    status: "",
    data: {},
  },
  removeInsuranceReportMaterials: {
    status: "",
    data: {},
  },
  changeInsuranceOrderStatus: {
    status: "",
    data: {},
  },
};

export default function reducer(state = initialState, action = {}) {
  var tmp;
  var index = -1;

  switch (action.type) {
    case actions.NEW_WORK_ORDER:
      return {
        ...state,
        workOrder: {
          status: "loading",
          data: {},
        },
      };
    case actions.NEW_WORK_ORDER_SUCCESS:
      let calendarMaintenances = [...state.calendarMaintenances.data];
      calendarMaintenances.push(action.data);

      if (state.orders.data) {
        tmp = [...state.orders.data];
        tmp.push(action.data);
      }

      return {
        ...state,
        calendarMaintenances: { status: "", data: calendarMaintenances },
        workOrder: { status: "", data: action.data },
        orders: { status: "", data: tmp, confirmations: [] },
      };
    case actions.NEW_WORK_ORDER_FAIL:
      return {
        ...state,
        workOrder: {
          status: "error",
          data: {},
        },
      };

    case actions.GET_ORDERS:
      return {
        ...state,
        orders: {
          data: [],
          status: "loading",
          confirmations: [],
        },
      };

    case actions.GET_ORDERS_V2:
      return {
        ...state,
        orders: {
          data: state.orders.data,
          status: "loading",
          confirmations: [],
        },
      };

    case actions.GET_ORDERS_SUCCESS:
      if (action.data.mergeData) {
        tmp = [...state.orders.data, ...action.data.data];
      }else{
        tmp = action.data.data;
      }
      console.log(tmp, "tmp")
      return {
        ...state,
        orders: {
          data: tmp || [],
          status: "",
          confirmations: pipeAllConfirmations(tmp),
        },
      };

    case actions.GET_ORDERS_FAIL:
      return {
        ...state,
        orders: {
          data: [],
          status: "error",
          confirmations: [],
        },
      };

    case actions.SET_USERS_TO_ORDERS:
      return {
        ...state,
        setUsersToOrder: {
          status: action.order_id,
        },
      };

    case actions.SET_USERS_TO_ORDERS_SUCCESS:
      tmp = [...state.orders.data];
      index = tmp.findIndex((item) => item.id === action.id);
      tmp[index] = action.data;
      return {
        ...state,
        setUsersToOrder: {
          status: "",
        },
        orders: {
          status: "",
          data: tmp,
          confirmations: pipeAllConfirmations(tmp),
        },
      };

    case actions.SET_USERS_TO_ORDERS_FAIL:
      return {
        ...state,
        setUsersToOrder: {
          status: "error",
        },
      };

    case actions.START_ORDER:
      return {
        ...state,
        startOrder: {
          status: action.order_id,
        },
      };

    case actions.START_ORDER_SUCCESS:
      tmp = [...state.orders.data];
      index = tmp.findIndex((item) => item.id === action.id);
      tmp[index] = action.data;
      tmp = [
        ...setAllOrdersSameFlagBusy(
          tmp,
          action.busy ? "busy" : "free",
          action.currentUser_id
        ),
      ];
      return {
        ...state,
        startOrder: {
          status: "",
        },
        orders: {
          status: "",
          data: tmp,
          confirmations: pipeAllConfirmations(tmp),
        },
      };

    case actions.START_ORDER_FAIL:
      return {
        ...state,
        startOrder: {
          status: "error",
        },
      };

    case actions.FINISH_ORDER:
      return {
        ...state,
        finishOrder: {
          status: action.order_id,
        },
      };

    case actions.FINISH_ORDER_SUCCESS:
      tmp = [...state.orders.data];
      index = tmp.findIndex((item) => item.id === action.id);
      tmp[index] = action.data;
      tmp = [
        ...setAllOrdersSameFlagBusy(
          tmp,
          action.busy ? "busy" : "free",
          action.currentUser_id
        ),
      ];
      return {
        ...state,
        finishOrder: {
          status: "",
        },
        orders: {
          status: "",
          data: tmp,
          confirmations: pipeAllConfirmations(tmp),
        },
      };

    case actions.FINISH_ORDER_FAIL:
      return {
        ...state,
        finishOrder: {
          status: "error",
        },
      };
    case actions.PAUSE_ORDER:
      return {
        ...state,
        pauseOrder: {
          status: action.order_id,
        },
      };

    case actions.PAUSE_ORDER_SUCCESS:
      tmp = [...state.orders.data];
      index = tmp.findIndex((item) => item.id === action.id);
      tmp[index] = action.data;
      tmp = [
        ...setAllOrdersSameFlagBusy(
          tmp,
          action.busy ? "busy" : "free",
          action.currentUser_id
        ),
      ];
      return {
        ...state,
        pauseOrder: {
          status: "",
        },
        orders: {
          status: "",
          data: tmp,
          confirmations: pipeAllConfirmations(tmp),
        },
      };

    case actions.PAUSE_ORDER_FAIL:
      return {
        ...state,
        pauseOrder: {
          status: "error",
        },
      };

    case actions.CONFIRM_ORDER:
      return {
        ...state,
        confirmOrder: {
          status: "loading",
        },
      };

    case actions.CONFIRM_ORDER_SUCCESS:
      tmp = [...state.orders.data];
      index = tmp.findIndex((item) => item.id === action.order_id);
      tmp[index] = action.data;
      return {
        ...state,
        orders: {
          status: "",
          data: tmp,
          confirmations: pipeAllConfirmations(tmp),
        },
        confirmOrder: {
          status: "",
        },
      };

    case actions.CONFIRM_ORDER_FAIL:
      return {
        ...state,
        confirmOrder: {
          status: "error",
        },
      };

    case actions.REJECT_ORDER:
      return {
        ...state,
        rejectOrder: {
          status: "loading",
        },
      };

    case actions.REJECT_ORDER_SUCCESS:
      tmp = [...state.orders.data];
      index = tmp.findIndex((item) => item.id === action.id);
      tmp[index] = action.data;
      tmp = [
        ...setAllOrdersSameFlagBusy(
          tmp,
          action.busy ? "busy" : "free",
          action.currentUser_id
        ),
      ];
      return {
        ...state,
        rejectOrder: {
          status: "",
        },
        orders: {
          status: "",
          data: tmp,
          confirmations: pipeAllConfirmations(tmp),
        },
      };

    case actions.REJECT_ORDER_FAIL:
      return {
        ...state,
        rejectOrder: {
          status: "error",
        },
      };

    case actions.GET_ORDERS_TO_CONFIRM:
      return {
        ...state,
        ordersToConfirm: {
          data: [],
          status: "loading",
          message: "",
        },
      };

    case actions.GET_ORDERS_TO_CONFIRM_SUCCESS:
      return {
        ...state,
        ordersToConfirm: {
          data: action.data.filter((x) => x.status === "finished"), // zbog mqtt topica
          status: "",
          message: "",
        },
      };

    case actions.GET_ORDERS_TO_CONFIRM_FAIL:
      return {
        ...state,
        ordersToConfirm: {
          data: [],
          status: "error",
          message: "",
        },
      };

    case actions.CALENDAR_MAINTENANCES:
      return {
        ...state,
        calendarMaintenances: {
          status: "loading",
          data: [],
          message: "",
        },
      };

    case actions.CALENDAR_MAINTENANCES_SUCCESS:
      return {
        ...state,
        calendarMaintenances: {
          status: "",
          data: action.data,
          message: action.message,
        },
      };

    case actions.CALENDAR_MAINTENANCES_FAIL:
      return {
        ...state,
        calendarMaintenances: {
          status: "error",
          data: [],
          message: action.message,
        },
      };

    case actions.INSURANCE_ORDERS:
      return {
        ...state,
        insuranceOrders: {
          status: "loading",
          data: [],
          message: "",
        },
      };

    case actions.INSURANCE_ORDERS_SUCCESS:
      return {
        ...state,
        insuranceOrders: {
          status: "",
          data: action.data,
          message: action.message,
        },
      };

    case actions.INSURANCE_ORDERS_FAIL:
      return {
        ...state,
        insuranceOrders: {
          status: "error",
          data: [],
          message: action.message,
        },
      };
    case actions.INSURANCE:
      return {
        ...state,
        insurance: {
          status: "loading",
          data: {},
          message: "",
        },
      };

    case actions.INSURANCE_SUCCESS:
      return {
        ...state,
        insurance: {
          status: "",
          data: action.data,
          message: action.message,
        },
      };

    case actions.INSURANCE_FAIL:
      return {
        ...state,
        insurance: {
          status: "error",
          data: {},
          message: action.message,
        },
      };

    case actions.ADD_INSURANCE_MATERIAL:
      return {
        ...state,
        addInsuranceMaterial: {
          status: "loading",
          data: {},
          message: "",
        },
      };

    case actions.ADD_INSURANCE_MATERIAL_SUCCESS:
      return {
        ...state,
        addInsuranceMaterial: {
          status: "",
          data: {},
          message: action.message,
        },
        insurance: {
          data: action.data,
          status: "",
        },
      };

    case actions.ADD_INSURANCE_MATERIAL_FAIL:
      return {
        ...state,
        addInsuranceMaterial: {
          status: "error",
          data: {},
          message: action.message,
        },
      };

    case actions.REMOVE_INSURANCE_REPORT_MATERIALS:
      return {
        ...state,
        removeInsuranceReportMaterials: {
          status: "loading",
          data: {},
          message: "",
        },
      };

    case actions.REMOVE_INSURANCE_REPORT_MATERIALS_SUCCESS:
      tmp = [...state.insurance.data.reports];
      index = tmp.findIndex((x) => x.id === action.id);
      tmp.splice(index, 1);

      return {
        ...state,
        removeInsuranceReportMaterials: {
          status: "",
          data: {},
          message: action.message,
        },
        insurance: {
          data: { ...state.insurance.data, reports: tmp },
          status: "",
        },
      };

    case actions.REMOVE_INSURANCE_REPORT_MATERIALS_FAIL:
      return {
        ...state,
        removeInsuranceReportMaterials: {
          status: "error",
          data: {},
          message: action.message,
        },
      };

    case actions.CHANGE_INSURANCE_ORDER_STATUS:
      return {
        ...state,
        changeInsuranceOrderStatus: {
          status: "loading",
          data: {},
          message: "",
        },
      };

    case actions.CHANGE_INSURANCE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        changeInsuranceOrderStatus: {
          status: "",
          data: {},
          message: action.message,
        },
        insurance: {
          data: action.data,
          status: "",
        },
      };

    case actions.CHANGE_INSURANCE_ORDER_STATUS_FAIL:
      return {
        ...state,
        changeInsuranceOrderStatus: {
          status: "error",
          data: {},
          message: action.message,
        },
      };

    default:
      return {
        ...state,
      };
  }
}

function transformData(data) {
  return data.data;
}

const authToken = () => localStorage.getItem("token");

//post work order
export function* watcherNewWorkOrder() {
  yield takeLatest(actions.NEW_WORK_ORDER, newWorkOrder);
}

function _newWorkOrder(options) {
  return axios_maintenances(options).post(`work_orders/`, options.data);
}

function* newWorkOrder(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
    };
    const response = yield call(_newWorkOrder, options);
    const data = transformData(response);
    yield put({
      type: actions.NEW_WORK_ORDER_SUCCESS,
      data,
    });

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: DESTROY_TOKEN_FOR_REDIRECT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.NEW_WORK_ORDER_FAIL, error });
  }
}
//post work order end

//get work orders
export function* watcherGetWorkOrders() {
  yield takeLatest(actions.GET_ORDERS, getWorkOrders);
}

function _getWorkOrders(options) {
  return axios_maintenances(options).get(`work_orders/`, {
    params: options.query_params || {},
  });
}

function* getWorkOrders(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      query_params: payload.query_params,
    };
    const response = yield call(_getWorkOrders, options);
    const data = transformData(response);
    yield put({
      type: actions.GET_ORDERS_SUCCESS,
      data,
    });

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: DESTROY_TOKEN_FOR_REDIRECT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.GET_ORDERS_FAIL, error });
  }
}
//get work orders end

//get work orders V2
export function* watcherGetWorkOrders_v2() {
  yield takeLatest(actions.GET_ORDERS_V2, getWorkOrders_v2);
}

function _getWorkOrders_v2(options) {
  console.log(options);
  return axios_maintenances(options).get(`work_orders/v2/`, {
    params: options.query_params || {},
  });
}

function* getWorkOrders_v2(payload) {
  console.log(payload);
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      query_params: payload.data.queryParams,
    };
    const response = yield call(_getWorkOrders_v2, options);
    const data = transformData(response);
    console.log(payload.data.mergeData, "GHJKGHJKL ČLKJČLKJ TTTT");
    yield put({
      type: actions.GET_ORDERS_SUCCESS,
      data: {
        data: data,
        mergeData: payload.data.mergeData,
      },
    });

    payload.data.successCallback && payload.data.successCallback(data);
  } catch (error) {
    console.log(error);
    payload.data.errorCallback && payload.data.errorCallback();
    if (error.response && error.response.status === 401) {
      yield put({ type: DESTROY_TOKEN_FOR_REDIRECT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.GET_ORDERS_FAIL, error });
  }
}
//get work orders end

//set users for order
export function* watchersetUsersToOrder() {
  yield takeLatest(actions.SET_USERS_TO_ORDERS, setUsersToOrder);
}

function _setUsersToOrder(options) {
  return axios_maintenances(options).post(
    `work_orders/${options.id}/add_technicians/`,
    options.data
  );
}

function* setUsersToOrder(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
      id: payload.order_id,
    };
    const response = yield call(_setUsersToOrder, options);
    const data = transformData(response);
    yield put({
      type: actions.SET_USERS_TO_ORDERS_SUCCESS,
      data,
      id: payload.order_id,
    });

    if (payload.callback) {
      payload.callback("appointed");
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: DESTROY_TOKEN_FOR_REDIRECT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.SET_USERS_TO_ORDERS_FAIL, error });
  }
}
//set users for order

// start order
export function* watcherStartOrder() {
  yield takeLatest(actions.START_ORDER, startOrder);
}

function _startOrder(options) {
  return axios_maintenances(options).post(
    `work_orders/${options.id}/${
      options.flag === "continue" ? "continue_work" : "start_work"
    }/`,
    options.data
  );
}

function* startOrder(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      id: payload.order_id,
      flag: payload.flag, // flag s endpoint to start order or continue order
      data: payload.data,
    };
    const response = yield call(_startOrder, options);
    const data = transformData(response);
    yield put({
      type: actions.START_ORDER_SUCCESS,
      data,
      id: payload.order_id,
      busy: true,
      currentUser_id: store.getState().usersReducer.user.data.id,
    });

    if (payload.callback) {
      console.log(payload.callback);
      payload.callback("in_progress");
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: DESTROY_TOKEN_FOR_REDIRECT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.START_ORDER_FAIL, error });
  }
}
//start order end

// finsih order
export function* watcherFinishOrder() {
  yield takeLatest(actions.FINISH_ORDER, finishOrder);
}

function _finishOrder(options) {
  return axios_maintenances(options).post(
    `work_orders/${options.id}/finish_work/`,
    options.data
  );
}

function* finishOrder(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      id: payload.order_id,
      data: payload.data,
    };
    const response = yield call(_finishOrder, options);
    const data = transformData(response);
    yield put({
      type: actions.FINISH_ORDER_SUCCESS,
      data,
      id: payload.order_id,
      busy: false,
      currentUser_id: store.getState().usersReducer.user.data.id,
    });

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: DESTROY_TOKEN_FOR_REDIRECT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.FINISH_ORDER_FAIL, error });
  }
}
//finish order end

// pause order
export function* watcherPauseOrder() {
  yield takeLatest(actions.PAUSE_ORDER, pauseOrder);
}

function _pauseOrder(options) {
  return axios_maintenances(options).post(
    `work_orders/${options.id}/pause_work/`,
    options.data
  );
}

function* pauseOrder(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      id: payload.order_id,
      data: payload.data,
      flag: payload.flag,
    };
    const response = yield call(_pauseOrder, options);
    const data = transformData(response);
    yield put({
      type: actions.PAUSE_ORDER_SUCCESS,
      data,
      id: payload.order_id,
      busy: false,
      currentUser_id: store.getState().usersReducer.user.data.id,
    });

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: DESTROY_TOKEN_FOR_REDIRECT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.PAUSE_ORDER_FAIL, error });
  }
}
//pause order end

// confirm order
export function* watcherConfirmOrder() {
  yield takeLatest(actions.CONFIRM_ORDER, confirmOrder);
}

function _confirmOrder(options) {
  return axios_maintenances(options).post(
    `work_orders/${options.id}/close/`,
    options.data
  );
}

function* confirmOrder(payload) {
  try {
    const token = yield call(authToken);

    const options = {
      token: token,
      id: payload.order_id,
      data: payload.data,
    };
    const response = yield call(_confirmOrder, options);

    yield put({
      type: actions.CONFIRM_ORDER_SUCCESS,
      order_id: payload.order_id,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.CONFIRM_ORDER_FAIL, error });
  }
}
//confirm order end

// reject order
export function* watcherRejectOrder() {
  yield takeLatest(actions.REJECT_ORDER, rejectOrder);
}

function _rejectOrder(options) {
  return axios_maintenances(options).post(
    `work_orders/${options.id}/back_off/`,
    options.data
  );
}

function* rejectOrder(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      id: payload.order_id,
      data: payload.data,
      flag: payload.flag,
    };
    const response = yield call(_rejectOrder, options);
    const data = transformData(response);
    yield put({
      type: actions.REJECT_ORDER_SUCCESS,
      data,
      id: payload.order_id,
      busy: false,
      currentUser_id: store.getState().usersReducer.user.data.id,
    });

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: DESTROY_TOKEN_FOR_REDIRECT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.REJECT_ORDER_FAIL, error });
  }
}
//reject order end

// Calendar Maintenances
export function* watcherCalendarMaintenances() {
  yield takeLatest(actions.CALENDAR_MAINTENANCES, calendarMaintenances);
}

function _calendarMaintenances(options) {
  return axios_maintenances(options).get(`work_orders/calendar/`, {
    params: options.queryParams,
  });
}

function* calendarMaintenances(payload) {
  try {
    const token = yield select(authToken);

    console.log(token);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };

    console.log("REDUX => ", payload.queryParams);

    const response = yield call(_calendarMaintenances, options);
    const data = transformData(response);
    yield put({
      type: actions.CALENDAR_MAINTENANCES_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.CALENDAR_MAINTENANCES_FAIL, error, message: "" });
  }
}

// insuranceOrders
export function* watcher_insuranceOrders() {
  yield takeLatest(actions.INSURANCE_ORDERS, insuranceOrders);
}

function _insuranceOrders(options) {
  return axios_maintenances(options).get(`get_insurance_orders/ `, {
    params: options.queryParams,
  });
}

function* insuranceOrders(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_insuranceOrders, options);
    const data = transformData(response);
    yield put({
      type: actions.INSURANCE_ORDERS_SUCCESS,
      data,
    });

    const { user } = store.getState().usersReducer;

    let tmp = { ...user.data };
    tmp.num_of_reported_insurance_orders = data.filter(
      (x) => x.status === INSURANCE_ORDERS_STATUS_KEYS.REPORTED
    ).length;

    yield put({
      type: USER_SUCCESS,
      data: {
        token: user.token,
        account: tmp,
      },
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.INSURANCE_ORDERS_FAIL, error, message: "" });
  }
}
//insuranceOrders END

// insurance
export function* watcher_insurance() {
  yield takeLatest(actions.INSURANCE, insurance);
}

function _insurance(options) {
  return options.order_token
    ? axios_maintenances(options).get(
        `get_insurance_order/${options.order_token}`,
        {
          params: options.queryParams,
        }
      )
    : axios_maintenances(options).get(`get_insurance_orders/${options.id}/`, {
        params: options.queryParams,
      });
}

function* insurance(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
      id: payload.id,
      order_token: payload.order_token, // in case of open order for partners
    };
    const response = yield call(_insurance, options);
    const data = transformData(response);
    yield put({
      type: actions.INSURANCE_SUCCESS,
      data,
      id: payload.id,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.INSURANCE_FAIL, error, message: "" });
  }
}
//insurance END

// addInsuranceMaterial
export function* watcher_addInsuranceMaterial() {
  yield takeLatest(actions.ADD_INSURANCE_MATERIAL, addInsuranceMaterial);
}

function _addInsuranceMaterial(options) {
  return axios_maintenances(options).post(
    `insert_insurance_materials/`,
    options.data
  );
}

function* addInsuranceMaterial(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
    };
    const response = yield call(_addInsuranceMaterial, options);
    const data = transformData(response);
    yield put({
      type: actions.ADD_INSURANCE_MATERIAL_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({
      type: actions.ADD_INSURANCE_MATERIAL_FAIL,
      error,
      message: "",
    });
  }
}
//addInsuranceMaterial END

// removeInsuranceReportMaterials
export function* watcher_removeInsuranceReportMaterials() {
  yield takeLatest(
    actions.REMOVE_INSURANCE_REPORT_MATERIALS,
    removeInsuranceReportMaterials
  );
}

function _removeInsuranceReportMaterials(options) {
  return axios_maintenances(options).post(
    `remove_insurance_report_materials/`,
    options.data
  );
}

function* removeInsuranceReportMaterials(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
    };
    const response = yield call(_removeInsuranceReportMaterials, options);
    const data = transformData(response);
    yield put({
      type: actions.REMOVE_INSURANCE_REPORT_MATERIALS_SUCCESS,
      data,
      id: payload.data.report_id,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({
      type: actions.REMOVE_INSURANCE_REPORT_MATERIALS_FAIL,
      error,
      message: "",
    });
  }
}
//removeInsuranceReportMaterials END

// changeInsuranceOrderStatus
export function* watcher_changeInsuranceOrderStatus() {
  yield takeLatest(
    actions.CHANGE_INSURANCE_ORDER_STATUS,
    changeInsuranceOrderStatus
  );
}

function _changeInsuranceOrderStatus(options) {
  if (options.openOrder) {
    return axios_maintenances(options).post(
      `waiting_insurance_order/`,
      options.data
    );
  }
  return axios_maintenances(options).post(
    `change_insurance_order_status/`,
    options.data
  );
}

function* changeInsuranceOrderStatus(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
      openOrder: payload.openOrder,
    };
    const response = yield call(_changeInsuranceOrderStatus, options);
    const data = transformData(response);
    yield put({
      type: actions.CHANGE_INSURANCE_ORDER_STATUS_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({
      type: actions.CHANGE_INSURANCE_ORDER_STATUS_FAIL,
      error,
      message: "",
    });
  }
}
//changeInsuranceOrderStatus END

// Calendar Maintenances

const pipeAllConfirmations = (orders) => {
  return null;
  const arr = [];
  console.log(orders);
  orders.map((item) => {
    if (
      item.technicians.filter((user) => user.confirmed !== null).length &&
      item.status === "appointed"
    ) {
      //corfimations
      // if confirmed is false that means that order is in procces of confirming
      arr.push(item);
    }
  });

  return arr;
};

const setAllOrdersSameFlagBusy = (orders, flag, currentUser_id) => {
  for (let i = 0; i < orders.length; i++) {
    if (orders[i].status !== "in_bowl") {
      orders[i].technicians.find(
        (tech) => tech.account.id === currentUser_id
      ).account.status = flag;
    }
  }

  return orders;
};
