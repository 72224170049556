import React, { useState } from "react";

import {
  NavLink,
  useHistory,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import { PageHeader, Tabs } from "antd";
import {
  SettingOutlined,
  ToolOutlined,
  SlidersOutlined,
  FileSearchOutlined,
  BulbOutlined,
} from "@ant-design/icons";

import Assets from "./Assets";
import Maintenances from "./Maintenances";
import Parts from "./Parts";
import WorkEffectiveness from "./WorkEffectiveness";
import Murga from "./TT_cables";
import DatePicker from "./components/DatePicker";
import css from "./analytics.module.scss";
import { PARTNER } from "../../axios/endpoints";

const { TabPane } = Tabs;

const Analytics = ({}) => {
  const match = useRouteMatch();
  const history = useHistory();

  const [activeKey, set_activeKey] = useState(
    history.location.pathname.split("/")[2]
  );

  return (
    <>
      <PageHeader
        title={
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "18px",
            }}
          >
            <span>Analitika</span>
            <DatePicker />
          </div>
        }
      />
      <div className={css.cardContainer}>
        <Tabs
          type="card"
          defaultActiveKey={activeKey}
          onChange={(key) => {
            history.replace(`/analytics/${key}/reports_1`);
          }}
        >
          {TABS.map((item) => item && (
            <TabPane
              tab={
                <>
                  {item.icon}
                  <NavLink to={`${match.url}/${item.path}/reports_1`}>
                    {item.name}
                  </NavLink>
                </>
              }
              key={item.path}
            />
          ))}
        </Tabs>
      </div>
      <div>
        <Switch>
          <Route path={`${match.path}/assets`} render={() => <Assets />} />
          <Route
            path={`${match.path}/maintenances`}
            render={() => <Maintenances />}
          />
          <Route path={`${match.path}/parts`} render={() => <Parts />} />
          <Route
            path={`${match.path}/workeffectiveness`}
            render={() => <WorkEffectiveness />}
          />
          {(PARTNER === "neuros" || PARTNER === "tt_cables") && (
            <Route path={`${match.path}/tt_cables`} render={() => <Murga />} />
          )}
        </Switch>
      </div>
    </>
  );
};

// Variables
const TABS = [
  {
    name: "Imovina",
    path: "assets",
    icon: (
      <>
        {" "}
        <SettingOutlined
          style={{
            position: "relative",
            display: "inlineBlock",
            left: "-7px",
            bottom: "-4px",
            margin: 0,
          }}
        />
        <SettingOutlined
          style={{
            position: "relative",
            display: "inlineBlock",
            left: "-10px",
            top: "-4px",
            margin: 0,
          }}
        />
      </>
    ),
  },
  {
    name: "Održavanje",
    path: "maintenances",
    icon: <ToolOutlined />,
  },
  {
    name: "Radni učinak",
    path: "workeffectiveness",
    icon: <SlidersOutlined />,
  },
  {
    name: "Dijelovi",
    path: "parts",
    icon: <BulbOutlined />,
  },

  PARTNER === "neuros" || PARTNER === "tt_cables"
    ? {
        name: "Proizvodnja",
        path: "tt_cables",
        icon: <FileSearchOutlined />,
      }
    : null,
];

export default Analytics;
