import React, { useEffect, useState } from "react";
import { useParams, Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";

import { LeftCircleOutlined } from "@ant-design/icons";
import Spinner from "../../../../components/LoadingScreen";
import ProfileTabs from "./components/ProfileTabs";
import About from "./components/About";
import Assets from "./components/Assets";
import Maintenances from "./components/Maintenences";
import Documents from "./components/Documents";
import css from "../../Accounting.module.scss";
import { Button, Popconfirm, Drawer, Icon, message, Checkbox } from "antd";
import SizeType from "../../../../helpers/deviceTypeSize";
import User from "../User";

import { connect } from "react-redux";
import * as actions from "../../../../redux/modules/Accounting/actions";

function AccountingProfile({
  history,
  match,
  location,
  account: { data, status },
  deleteUser,
  prepareRegistration,
  onGetAccount,
  onDeleteUser,
}) {
  // Variables
  const { id } = useParams();
  const [activeKey, setActiveKey] = useState("");
  const [manageUsersVisible, setManageUsersVisible] = useState(false);

  // Methods
  useEffect(() => {
    // Get Accounts when component loads
    onGetAccount(match.params.id);
  }, []);

  // Update user
  function toggleDrawer(bool) {
    if (bool) {
      setManageUsersVisible(bool);
      return;
    }
    setManageUsersVisible(!!bool);
  }

  // Setting active tab key
  function _setActiveKey() {
    // Define active key
    const _activeKey = location.pathname.split("/").slice(-1)[0] || "about";
    setActiveKey(_activeKey);
  }

  // Deleting user
  function handleDelete() {
    onDeleteUser(data.account.id, successCallback, errorCallback);
  }

  function successCallback() {
    message.success(
      `Uspješno ste izbrisali korisnika.`
    );
    history.push("/accounting");
  }

  function errorCallback() {
    message.error("Došlo je do pogreške prilikom brisanja");
  }

  // Display spinner if onGetAccounts is loading
  if (status === "loading") {
    return <Spinner />;
  }

  return (
    <>
      <div className={css.backFlexRow}>
        <div className={css.backFlexRow}>
          {/* Back icon with hover effect */}
          <div
            onClick={() => {
              history.push("/accounting");
            }}
            className={[css.hoverDiv + " hover"]}
          >
            {/* Icon */}
            <LeftCircleOutlined className={css.backIcon} />
          </div>

          <div>
            {/* Username */}
            <h1 className={css.username}>{data.account.full_name}</h1>
            {/* User ID */}
            <h1 className={css.userID}>ID korisnika: {data.account.id}</h1>
          </div>
        </div>

        <div className={css.buttonsView}>
          {/* Edit user button */}
          <Button
            onClick={() => toggleDrawer(true)}
            style={{ marginTop: 20 }}
            type="primary"
          >
            Uredi
          </Button>
          <Popconfirm
            id="delete_user"
            title="Jeste li sigurni da želite izbrisati korisnika?"
            placement="left"
            okText="Izbriši"
            cancelText="Odustani"
            onConfirm={handleDelete}
          >
            <Button
              style={{ marginTop: 20, marginLeft: 20 }}
              loading={deleteUser.status === "loading"}
              type="danger"
            >
              Izbriši
            </Button>
          </Popconfirm>
        </div>
      </div>

      {/* Profile tabs */}
      <ProfileTabs
        setActiveKey={_setActiveKey}
        activeKey={activeKey}
        match={match}
        data={data.account}
      />

      {/* Conditional route displaying based on tab key */}
      <div>
        <Switch>
          {/* About user component */}
          <Route
            path={`${match.path}/about`}
            render={() => <About user={data.account} />}
          />
          {/* User maintenances component */}
          <Route
            path={`${match.path}/maintenances`}
            render={() => (
              <Maintenances
                work_orders={data.work_orders}
                user={data.account}
              />
            )}
          />
          {/* User documents component */}
          <Route
            path={`${match.path}/documents`}
            render={() => <Documents user={data.account} />}
          />
          <Route
            path={`${match.path}/assets`}
            render={() => <Assets user={data.account} />}
          />
        </Switch>
      </div>

      <Drawer
        width={SizeType() === "xs" || SizeType() === "sm" ? "90%" : 690}
        visible={manageUsersVisible}
        onClose={() => toggleDrawer(false)}
        destroyOnClose={true}
        title={
          <span>
            <Icon type="snippets" theme="twoTone" /> Uređivanje korisnika
          </span>
        }
      >
        <User
          prepareRegistration={prepareRegistration}
          update={true}
          user={data.account}
        />
      </Drawer>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    account: state.accoutingReducer.account,
    prepareRegistration: state.accoutingReducer.prepareRegistration,
    deleteUser: state.accoutingReducer.deleteUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAccount: (user_id) => dispatch({ type: actions.ACCOUNT, user_id }),

    onDeleteUser: (user_id, successCallback, errorCallback) =>
      dispatch({
        type: actions.DELETE_USER,
        user_id,
        successCallback,
        errorCallback,
      }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountingProfile)
);
