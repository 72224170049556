import { UploadOutlined } from "@ant-design/icons";
import { Button, Input, message, Modal, Upload } from "antd";
import { options } from "preact";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { ALLOWED_EXTENSIONS } from "../../../../../../helpers/consts";
import axios_maintenances from "../../../../../../axios/axios-maintenances";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT } from "../../../../../../redux/modules/Users/actions";
import { GET_ORDER_SUCCESS } from "../../../../../../redux/modules/Maintenances/actions";

const AddReportForm = ({ formVisible, set_formVisible }) => {
  const { id } = useParams();
  const [files, set_files] = useState([]);
  const [text, set_text] = useState("");
  const [loading, set_loading] = useState(false);
  const dispatch = useDispatch();

  const order = useSelector((state) => state.maintenanceReducer.order.data);
  const user = useSelector((state) => state.usersReducer.user.data);

  const onRemove = (file) => {
    let index = files.findIndex((x) => x === file);
    let tmpArr = [...files];
    tmpArr.splice(index, 1);
    set_files(tmpArr);
  };


  const onFinish = async () => {
    set_loading(true);
    try {
      let token = await localStorage.getItem("token");
      let formData = new FormData();
      let data = { text, technician_id: technicianOfOrder(order, user).id };

      formData.append("data", JSON.stringify(data));

      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        formData.append("files", element);
      }
      let options = {
        token,
      };
      let res = await axios_maintenances(options).post(
        `work_orders/${id}/add_report/`,
        formData
      );


      dispatch({ type: GET_ORDER_SUCCESS, data: res.data });
     

      set_formVisible(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        message.warning("Niste prijavljeni.");
        dispatch({ type: LOGOUT });
        return;
      }
      console.log(error);
      message.error("Dogodila se greska.");
    }
    set_loading(false);
  };

  return (
    <div>
      <Modal
        okButtonProps={{ disabled: !text, loading }}
        cancelText="Zatvori"
        okText="Spremi"
        visible={formVisible}
        onOk={() => onFinish()}
        title={"Dodaj izvještaj"}
        onCancel={() => set_formVisible(false)}
        destroyOnClose
      >
        <Input.TextArea onChange={({ target: { value } }) => set_text(value)} />
        <div style={{ height: 21 }} />
        <Upload
          onRemove={onRemove}
          showUploadList
          fileList={files}
          multiple={true}
          accept={ALLOWED_EXTENSIONS.map((x) => x.extensions).join(",")}
          beforeUpload={(file, _files) => {
            set_files([...files, ..._files]);
            return false;
          }}
        >
          <Button>
            Ucitaj <UploadOutlined />
          </Button>
        </Upload>
      </Modal>
    </div>
  );
};

export default AddReportForm;

export const technicianOfOrder = (order, user) => {
  
  if (!order || !user) {
    return false;
  }

  const technician = order.technicians?.find(
    (tec) => tec.account.id === user.id
  );
  if (technician === -1 || technician === undefined) {
    return null;
  }

  return technician;
};
