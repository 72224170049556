import { takeLatest, put, call, select } from "redux-saga/effects";

import * as actions from "./actions";
import moment from "moment";
import axios_analytics from "../../../axios/axios-analytics";
import axios_parts from "../../../axios/axios-parts";
import { LOGOUT } from "../Users/actions";

const initialState = {
  stockPurchase: {
    status: "",
    data: {
      data: {
        data: [],
        total_num_of_orders: 0,
        count_rows: 0,
        total_avg_qty_per_order: 0,
      },
      message: "",
    },
  },
  assetsReports: {
    filters: {
      assets: [],
      assets_category: [],
      locations: [],
    },
  },
  murgaReports: {
    filters: {
      maintenance_categories: [],
      locations: [],
      assets: [],
      assets_category: [],
      maintenance_types: [],
    },
  },
  partsReports: {
    filters: {
      category: [],
      parts: [],
      locations: [],
    },
  },
  workEffectivenessReports: {
    filters: {
      technicians: [],
      assets_category: [],
      locations: [],
    },
  },
  maintenancesReports: {
    filters: {
      assets: [],
      assets_category: [],
      locations: [],
      maintenances_category: [],
      maintenance_types: [],
    },
  },
  techniciansResponseTime: {
    status: "",
    data: {
      data: {
        data: [],
        total_avg_response_time: 0,
        total_avg_execution_time: 0,
      },
      
    },
  },
  totalDowntime: {
    status: "",
    data: {
      interval_type: "",
      data: [{ interval: 0, report_to_close_total_time: 0 }],
    },
  },
  totalSpentParts: {
    status: "",
    data: {
      data: {
        data: [],
        total_price: 0,
        count_rows: 0,
      },
      message: "",
    },
  },
  workOrderWorkingAvgTime: {
    status: "",
    data: {},
  },
  numOfDowntimesPerCategory: {
    status: "",
    data: {},
  },
  pickedDate: {
    status: "",
    data: {
      from: moment().startOf("month").format("YYYY-MM-DD"),
      to: moment().endOf("month").format("YYYY-MM-DD"),
    },
  },
  totalDowntimePerAsset: {
    status: "",
    data: [],
  },
  mtbm: {
    status: "",
    data: { MTBM: 0 },
  },
  maintenanceNumberPerAsset: {
    status: "",
    data: { count: 0, data: [] },
  },

  mttr: {
    status: "",
    data: { interval_type: 0, data: [] },
  },
  mtbf: {
    status: "",
    data: { interval_type: 0, data: [] },
  },
  numOfDowntimesPerCategory: {
    status: "",
    data: { count: { total: 0, data: [] }, time: { total: 0, data: [] } },
  },
  techniciansLoginAndWorkTime: {
    status: "",
    data: { total_login_time: 0, total_working_time: 0, data: [] },
  },
  technicianAvailabilityTrend: {
    status: "",
    data: {
      interval_type: "",
      total_available_percent: 0,
      total_available_time: 0,
      total_login_time: 0,
      data: [],
    },
  },
  technicianGenealEffect: {
    status: "",
    data: {
      execution_success_percent: 0,
      total_number_of_maintenance: 0,
      total_number_of_unclosed_maintenance: 0,
      general_effect_per_technician: [],
      reports_info_per_technician: [],
    },
  },

  assetMaintenanceSummary: {
    status: "",
    data: { total_failure_time: 0, data: [] },
  },
  assetMaintenanceList: {
    status: "",
    data: {
      total_failure_time: 0,
      data: [
        {
          order_id: 176,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Samp plašt",
          description:
            "U smjeni po 2-3 puta padnu osigurači od extrudera ili namatača",
          categories: "Elektronika",
          created_at: "2021-03-07T22:00:58.078091",
          closed_at: "2021-06-08T09:32:33.688557",
          failure_time: "7986695610.466",
          machine_run: true,
        },
        {
          order_id: 218,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Grijac",
          description: "Potrebno spojiti grijac na glavi",
          categories: "Elektronika",
          created_at: "2021-03-16T15:41:54.595828",
          closed_at: "2021-06-08T01:04:11.484206",
          failure_time: "7201336888.378",
          machine_run: false,
        },
        {
          order_id: 308,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Kvar namatac",
          description: "",
          categories: "Elektronika",
          created_at: "2021-03-28T20:18:07.886978",
          closed_at: "2021-06-15T22:32:22.404426",
          failure_time: "6833654517.448",
          machine_run: false,
        },
        {
          order_id: 273,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Senzor na namatacu",
          description: "",
          categories: "Elektronika",
          created_at: "2021-03-25T11:01:30.818335",
          closed_at: "2021-06-08T01:06:29.469878",
          failure_time: "6440698651.543",
          machine_run: false,
        },
        {
          order_id: 304,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Usisivac",
          description: "Usisivac nije u funkciji",
          categories: "Elektronika",
          created_at: "2021-03-27T16:02:16.432066",
          closed_at: "2021-06-10T03:09:38.208268",
          failure_time: "6430041776.202",
          machine_run: false,
        },
        {
          order_id: 292,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Senzor na namatacu",
          description: "Senzor nije u funkciji",
          categories: "Elektronika",
          created_at: "2021-03-26T18:40:21.901436",
          closed_at: "2021-06-08T01:04:05.007363",
          failure_time: "6326623105.927",
          machine_run: false,
        },
        {
          order_id: 339,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Zrak",
          description: "",
          categories: "Zrak",
          created_at: "2021-04-01T01:43:46.109424",
          closed_at: "2021-06-08T01:03:49.820492",
          failure_time: "5872803711.068",
          machine_run: false,
        },
        {
          order_id: 365,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Senzor na namatacu",
          description: "",
          categories: "Elektronika",
          created_at: "2021-04-05T08:37:57.386327",
          closed_at: "2021-06-10T03:08:44.282639",
          failure_time: "5682646896.312",
          machine_run: false,
        },
        {
          order_id: 489,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Greska na izlaznom capstanu",
          description: "",
          categories: "Elektronika",
          created_at: "2021-04-20T21:10:19.447558",
          closed_at: "2021-06-08T01:04:25.931985",
          failure_time: "4161246484.427",
          machine_run: false,
        },
        {
          order_id: 495,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Capstani",
          description: "Gusjenice ispadaju u radu",
          categories: "Mehanika",
          created_at: "2021-04-21T13:29:35.813843",
          closed_at: "2021-06-08T09:30:54.384712",
          failure_time: "4132878570.869",
          machine_run: true,
        },
        {
          order_id: 205,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Piovan",
          description: "Kvar piovana",
          categories: "Elektronika",
          created_at: "2021-03-13T17:35:12.325649",
          closed_at: "2021-04-27T09:56:34.180296",
          failure_time: "3856881854.647",
          machine_run: false,
        },
        {
          order_id: 723,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Lezaj na rocku u kadi",
          description: "",
          categories: "Mehanika",
          created_at: "2021-05-27T10:30:56.633922",
          closed_at: "2021-07-07T17:36:01.480220",
          failure_time: "3567904846.298",
          machine_run: false,
        },
        {
          order_id: 549,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Sklopka",
          description: "",
          categories: "Elektronika",
          created_at: "2021-04-29T09:24:07.345815",
          closed_at: "2021-06-08T01:03:42.266052",
          failure_time: "3425974920.237",
          machine_run: false,
        },
        {
          order_id: 568,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Capstan",
          description: "Pukla gusjenica",
          categories: "Mehanika",
          created_at: "2021-05-03T07:00:46.835653",
          closed_at: "2021-06-08T09:30:39.648931",
          failure_time: "3119392813.278",
          machine_run: true,
        },
        {
          order_id: 621,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Extruder",
          description: "Ext 1 pregrijavanje motora",
          categories: "Elektronika",
          created_at: "2021-05-11T18:19:09.706004",
          closed_at: "2021-06-15T22:29:02.881430",
          failure_time: "3038993175.426",
          machine_run: false,
        },
        {
          order_id: 606,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Namatač",
          description: "Neće da se upali",
          categories: "Elektronika",
          created_at: "2021-05-08T02:18:10.750253",
          closed_at: "2021-06-08T01:03:07.810947",
          failure_time: "2673897060.694",
          machine_run: true,
        },
        {
          order_id: 306,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Extruder",
          description: "Extruder neće da starta",
          categories: "Elektronika",
          created_at: "2021-03-28T04:09:53.702164",
          closed_at: "2021-04-27T09:58:21.555415",
          failure_time: "2612907853.251",
          machine_run: false,
        },
        {
          order_id: 671,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Usisivač",
          description: "Kvar usisivača",
          categories: "Elektronika",
          created_at: "2021-05-20T10:32:12.882523",
          closed_at: "2021-06-15T22:30:34.992111",
          failure_time: "2289502109.588",
          machine_run: false,
        },
        {
          order_id: 664,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Odmatač",
          description: "Kvar odmatača",
          categories: "Elektronika",
          created_at: "2021-05-19T09:26:10.536507",
          closed_at: "2021-06-08T01:02:54.365613",
          failure_time: "1697803829.106",
          machine_run: false,
        },
        {
          order_id: 705,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Usisivač",
          description: "Neispravan usisivač na ispuni",
          categories: "Elektronika",
          created_at: "2021-05-25T14:41:48.426148",
          closed_at: "2021-06-08T09:10:03.784138",
          failure_time: "1189695357.99",
          machine_run: true,
        },
        {
          order_id: 783,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Ext 1",
          description: "Ext 1 drive fault",
          categories: "Elektronika",
          created_at: "2021-06-08T05:04:44.214774",
          closed_at: "2021-06-15T22:31:07.900711",
          failure_time: "667583685.937",
          machine_run: false,
        },
        {
          order_id: 770,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Izlazni capstan",
          description: "Izlazni capstan izbacuje greške",
          categories: "Elektronika",
          created_at: "2021-06-06T13:39:11.383913",
          closed_at: "2021-06-11T23:05:11.491900",
          failure_time: "465960107.987",
          machine_run: true,
        },
        {
          order_id: 772,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Motor od extrudera",
          description: "",
          categories: "Elektronika",
          created_at: "2021-06-06T16:27:23.183152",
          closed_at: "2021-06-12T01:09:21.037704",
          failure_time: "463317854.552",
          machine_run: false,
        },
        {
          order_id: 931,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Tocak u kanalu",
          description: "",
          categories: "Mehanika",
          created_at: "2021-06-22T05:47:10.045710",
          closed_at: "2021-06-24T06:46:15.378867",
          failure_time: "176345333.157",
          machine_run: false,
        },
        {
          order_id: 782,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Temperature",
          description: "Kvar temperatura",
          categories: "Elektronika",
          created_at: "2021-06-08T03:02:30.156310",
          closed_at: "2021-06-10T03:08:36.738577",
          failure_time: "173166582.267",
          machine_run: false,
        },
        {
          order_id: 848,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Vodilica kabela",
          description: "Vodilica kabela zablokirala (točak u kanalu)",
          categories: "Mehanika",
          created_at: "2021-06-14T08:54:51.599000",
          closed_at: "2021-06-15T22:27:25.439656",
          failure_time: "135153840.656",
          machine_run: false,
        },
        {
          order_id: 889,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Ulje u Piovan",
          description: "",
          categories: "Mehanika",
          created_at: "2021-06-17T11:03:45.585423",
          closed_at: "2021-06-18T21:13:40.543830",
          failure_time: "122994958.407",
          machine_run: false,
        },
        {
          order_id: 944,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Veliki tocak",
          description:
            "Potrebno tocak podesiti da ne dolazi do ostecenja kabela",
          categories: "Mehanika",
          created_at: "2021-06-23T00:55:05.718923",
          closed_at: "2021-06-24T03:08:14.032224",
          failure_time: "94388313.301",
          machine_run: true,
        },
        {
          order_id: 928,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Crijevo od zraka",
          description: "",
          categories: "Zrak",
          created_at: "2021-06-21T23:43:23.098024",
          closed_at: "2021-06-22T06:48:15.228937",
          failure_time: "25492130.913",
          machine_run: false,
        },
        {
          order_id: 899,
          asset_id: 28,
          asset_name: "Samp E120 - BS A2-Plašt",
          order_name: "Piovani",
          description: "",
          categories: "Elektronika",
          created_at: "2021-06-18T18:23:26.524539",
          closed_at: "2021-06-18T21:12:31.270523",
          failure_time: "10144745.984",
          machine_run: false,
        },
      ],
    },
  },
  reporterGeneralEffect: {
    status: "",
    data: {
      data: [],
      avg_time_until_closed: "",
      total_time_until_closed: "",
    },
  },
  reportForAdministration: {
    status: "",
    data: {
      file_content: "",
    },
  },
};

// -------------------------------------------------------------------

export default function reducer(state = initialState, action = {}) {
  var tmp;
  var index = -1;
  switch (action.type) {
    case actions.STOCK_PURCHASE:
      return {
        ...state,
        stockPurchase: {
          status: "loading",
          data: state.stockPurchase.data,
        },
      };

    case actions.STOCK_PURCHASE_SUCCESS:
      return {
        ...state,
        stockPurchase: {
          status: "",
          data: action.data,
        },
      };

    case actions.STOCK_PURCHASE_FAIL:
      return {
        ...state,
        stockPurchase: {
          status: "error",
          data: state.stockPurchase.data,
        },
      };
    case actions.TOTAL_SPENT_PARTS:
      return {
        ...state,
        totalSpentParts: {
          status: "loading",
          data: state.totalSpentParts.data,
        },
      };

    case actions.TOTAL_SPENT_PARTS_SUCCESS:
      return {
        ...state,
        totalSpentParts: {
          status: "",
          data: action.data,
        },
      };

    case actions.TOTAL_SPENT_PARTS_FAIL:
      return {
        ...state,
        totalSpentParts: {
          status: "error",
          data: state.totalSpentParts.data,
        },
      };
    case actions.TT_CABLES_FILTERS_SUCCESS:
      tmp = { ...state.murgaReports };
      tmp.filters = action.data;
      return {
        ...state,
        murgaReports: tmp,
      };
    case actions.WORK_EFFECTIVENESS_FILTERS_SUCCESS:
      tmp = { ...state.workEffectivenessReports };
      tmp.filters = action.data;
      return {
        ...state,
        workEffectivenessReports: tmp,
      };
    case actions.MAINTENANCES_FILTERS_SUCCESS:
      tmp = { ...state.maintenancesReports };
      tmp.filters = action.data;
      return {
        ...state,
        maintenancesReports: tmp,
      };

    case actions.PARTS_FILTERS_SUCCESS:
      tmp = { ...state.partsReports };
      tmp.filters = action.data;
      return {
        ...state,
        partsReports: tmp,
      };

    case actions.ASSETS_FILTERS_SUCCESS:
      tmp = { ...state.assetsReports };
      tmp.filters = action.data;
      return {
        ...state,
        assetsReports: tmp,
      };

    case actions.TECHNICIANS_RESPONSE_TIME:
      return {
        ...state,
        techniciansResponseTime: {
          status: "loading",
          data: {
            data: {
              total_avg_response_time: 0,
              total_avg_execution_time: 0,
              data: [],
            },
            interval_type: "",
            total_available_time: "",
            total_login_time: "",
            total_available_percent: 0,
          },
          message: "",
        },
      };

    case actions.TECHNICIANS_RESPONSE_TIME_SUCCESS:
      return {
        ...state,
        techniciansResponseTime: {
          status: "",
          data: {
            data: action.data,
          },
          message: action.message,
        },
      };

    case actions.TECHNICIANS_RESPONSE_TIME_FAIL:
      return {
        ...state,
        techniciansResponseTime: {
          status: "error",
          data: {
            data: {
              data: [],
              total_avg_response_time: 0,
              total_avg_execution_time: 0,
            }
          },
          message: action.message,
        },
      };

    case actions.WORK_ORDER_WORKING_AVG_TIME:
      return {
        ...state,
        workOrderWorkingAvgTime: {
          status: "loading",
          data: {},
          message: "",
        },
      };

    case actions.WORK_ORDER_WORKING_AVG_TIME_SUCCESS:
      return {
        ...state,
        workOrderWorkingAvgTime: {
          status: "",
          data: action.data,
          message: action.message,
        },
      };

    case actions.WORK_ORDER_WORKING_AVG_TIME_FAIL:
      return {
        ...state,
        workOrderWorkingAvgTime: {
          status: "error",
          data: {},
          message: action.message,
        },
      };
    case actions.PICKED_DATE:
      return {
        ...state,
        pickedDate: {
          status: "loading",
          data: {},
          message: "",
        },
      };

    case actions.PICKED_DATE_SUCCESS:
      return {
        ...state,
        pickedDate: {
          status: "",
          data: action.data,
          message: action.message,
        },
      };

    case actions.PICKED_DATE_FAIL:
      return {
        ...state,
        pickedDate: {
          status: "error",
          data: {},
          message: action.message,
        },
      };

    case actions.TOTAL_DOWNTIME:
      return {
        ...state,
        totalDowntime: {
          status: "loading",
          data: {
            interval_type: "",
            data: [{ interval: 1, report_to_close_total_time: 0 }],
          },
          message: "",
        },
      };

    case actions.TOTAL_DOWNTIME_SUCCESS:
      return {
        ...state,
        totalDowntime: {
          status: "",
          data: action.data,
          message: action.message,
        },
      };

    case actions.TOTAL_DOWNTIME_FAIL:
      return {
        ...state,
        totalDowntime: {
          status: "error",
          data: {
            interval_type: "",
            data: [{ interval: 1, report_to_close_total_time: 0 }],
          },
          message: action.message,
        },
      };

    case actions.TOTAL_DOWNTIME_PER_ASSET:
      return {
        ...state,
        totalDowntimePerAsset: {
          status: "loading",
          data: [],
          message: "",
        },
      };

    case actions.TOTAL_DOWNTIME_PER_ASSET_SUCCESS:
      return {
        ...state,
        totalDowntimePerAsset: {
          status: "",
          data: action.data,
          message: action.message,
        },
      };

    case actions.TOTAL_DOWNTIME_PER_ASSET_FAIL:
      return {
        ...state,
        totalDowntimePerAsset: {
          status: "error",
          data: [],
          message: action.message,
        },
      };

    case actions.MTBM:
      return {
        ...state,
        mtbm: {
          status: "loading",
          data: state.mtbm,
          message: "",
        },
      };

    case actions.MTBM_SUCCESS:
      return {
        ...state,
        mtbm: {
          status: "",
          data: action.data,
          message: action.message,
        },
      };

    case actions.MTBM_FAIL:
      return {
        ...state,
        mtbm: {
          status: "error",
          data: {},
          message: action.message,
        },
      };

    case actions.MAINTENANCE_NUMBER_PER_ASSET:
      return {
        ...state,
        maintenanceNumberPerAsset: {
          status: "loading",
          data: { count: 0, data: [] },
          message: "",
        },
      };

    case actions.MAINTENANCE_NUMBER_PER_ASSET_SUCCESS:
      return {
        ...state,
        maintenanceNumberPerAsset: {
          status: "",
          data: action.data,
          message: action.message,
        },
      };

    case actions.MAINTENANCE_NUMBER_PER_ASSET_FAIL:
      return {
        ...state,
        maintenanceNumberPerAsset: {
          status: "error",
          data: { count: 0, data: [] },
          message: action.message,
        },
      };

    case actions.MTTR:
      return {
        ...state,
        mttr: {
          status: "loading",
          data: { interval_type: 0, data: [] },
          message: "",
        },
      };

    case actions.MTTR_SUCCESS:
      return {
        ...state,
        mttr: {
          status: "",
          data: action.data,
          message: action.message,
        },
      };

    case actions.MTTR_FAIL:
      return {
        ...state,
        mttr: {
          status: "error",
          data: { interval_type: 0, data: [] },
          message: action.message,
        },
      };

    case actions.MTBF:
      return {
        ...state,
        mtbf: {
          status: "loading",
          data: { interval_type: 0, data: [] },
          message: "",
        },
      };

    case actions.MTBF_SUCCESS:
      return {
        ...state,
        mtbf: {
          status: "",
          data: action.data,
          message: action.message,
        },
      };

    case actions.MTBF_FAIL:
      return {
        ...state,
        mtbf: {
          status: "error",
          data: { interval_type: 0, data: [] },
          message: action.message,
        },
      };

    case actions.NUM_OF_DOWNTIMES_PER_CATEGORY:
      return {
        ...state,
        numOfDowntimesPerCategory: {
          status: "loading",
          data: { count: { total: 0, data: [] }, time: { total: 0, data: [] } },
          message: "",
        },
      };

    case actions.NUM_OF_DOWNTIMES_PER_CATEGORY_SUCCESS:
      return {
        ...state,
        numOfDowntimesPerCategory: {
          status: "",
          data: action.data,
          message: action.message,
        },
      };

    case actions.NUM_OF_DOWNTIMES_PER_CATEGORY_FAIL:
      return {
        ...state,
        numOfDowntimesPerCategory: {
          status: "error",
          data: { count: { total: 0, data: [] }, time: { total: 0, data: [] } },
          message: action.message,
        },
      };

    case actions.TECHNICIANS_LOGIN_AND_WORK_TIME:
      return {
        ...state,
        techniciansLoginAndWorkTime: {
          status: "loading",
          data: { total_login_time: 0, total_working_time: 0, data: [] },
          message: "",
        },
      };

    case actions.TECHNICIANS_LOGIN_AND_WORK_TIME_SUCCESS:
      return {
        ...state,
        techniciansLoginAndWorkTime: {
          status: "",
          data: action.data,
          message: action.message,
        },
      };

    case actions.TECHNICIANS_LOGIN_AND_WORK_TIME_FAIL:
      return {
        ...state,
        techniciansLoginAndWorkTime: {
          status: "error",
          data: { total_login_time: 0, total_working_time: 0, data: [] },
          message: action.message,
        },
      };

    case actions.TECHNICIAN_AVAILABILITY_TREND:
      return {
        ...state,
        technicianAvailabilityTrend: {
          status: "loading",
          data: {
            interval_type: "",
            total_available_percent: 0,
            total_available_time: 0,
            total_login_time: 0,
            data: [],
          },
          message: "",
        },
      };

    case actions.TECHNICIAN_AVAILABILITY_TREND_SUCCESS:
      return {
        ...state,
        technicianAvailabilityTrend: {
          status: "",
          data: action.data,
          message: action.message,
        },
      };

    case actions.TECHNICIAN_AVAILABILITY_TREND_FAIL:
      return {
        ...state,
        technicianAvailabilityTrend: {
          status: "error",
          data: {
            interval_type: "",
            total_available_percent: 0,
            total_available_time: 0,
            total_login_time: 0,
            data: [],
          },
          message: action.message,
        },
      };

    case actions.TECHNICIAN_GENEAL_EFFECT:
      return {
        ...state,
        technicianGenealEffect: {
          status: "loading",
          data: {
            execution_success_percent: 0,
            total_number_of_maintenance: 0,
            total_number_of_unclosed_maintenance: 0,
            general_effect_per_technician: [],
            reports_info_per_technician: [],
          },
          message: "",
        },
      };

    case actions.TECHNICIAN_GENEAL_EFFECT_SUCCESS:
      return {
        ...state,
        technicianGenealEffect: {
          status: "",
          data: action.data,
          message: action.message,
        },
      };

    case actions.TECHNICIAN_GENEAL_EFFECT_FAIL:
      return {
        ...state,
        technicianGenealEffect: {
          status: "error",
          data: {
            execution_success_percent: 0,
            total_number_of_maintenance: 0,
            total_number_of_unclosed_maintenance: 0,
            general_effect_per_technician: [],
            reports_info_per_technician: [],
          },
          message: action.message,
        },
      };

    case actions.ASSET_MAINTENANCE_SUMMARY:
      return {
        ...state,
        assetMaintenanceSummary: {
          status: "loading",
          data: { total_failure_time: 0, data: [], avg_failure_time: 0 },
          message: "",
        },
      };

    case actions.ASSET_MAINTENANCE_SUMMARY_SUCCESS:
      return {
        ...state,
        assetMaintenanceSummary: {
          status: "",
          data: action.data,
          message: action.message,
        },
      };

    case actions.ASSET_MAINTENANCE_SUMMARY_FAIL:
      return {
        ...state,
        assetMaintenanceSummary: {
          status: "error",
          data: { total_failure_time: 0, data: [], avg_failure_time: 0 },
          message: action.message,
        },
      };

    case actions.ASSET_MAINTENANCE_LIST:
      return {
        ...state,
        assetMaintenanceList: {
          status: "loading",
          data: { total_failure_time: 0, data: [] },
          message: "",
        },
      };

    case actions.ASSET_MAINTENANCE_LIST_SUCCESS:
      return {
        ...state,
        assetMaintenanceList: {
          status: "",
          data: action.data,
          message: action.message,
        },
      };

    case actions.ASSET_MAINTENANCE_LIST_FAIL:
      return {
        ...state,
        assetMaintenanceList: {
          status: "error",
          data: { total_failure_time: 0, data: [] },
          message: action.message,
        },
      };

    case actions.REPORTER_GENERAL_EFFECT:
      return {
        ...state,
        reporterGeneralEffect: {
          status: "loading",
          data: {
            data: [],
            avg_time_until_closed: "",
            total_time_until_closed: "",
          },
          message: "",
        },
      };

    case actions.REPORTER_GENERAL_EFFECT_SUCCESS:
      return {
        ...state,
        reporterGeneralEffect: {
          status: "",
          data: action.data,
          message: action.message,
        },
      };

    case actions.REPORTER_GENERAL_EFFECT_FAIL:
      return {
        ...state,
        reporterGeneralEffect: {
          status: "error",
          data: {
            data: [],
            avg_time_until_closed: "",
            total_time_until_closed: "",
          },
          message: action.message,
        },
      };

    case actions.REPORT_FOR_ADMINISTRATION:
      return {
        ...state,
        reportForAdministration: {
          status: "loading",
          data: { file_content: "" },
          message: "",
        },
      };

    case actions.REPORT_FOR_ADMINISTRATION_SUCCESS:
      return {
        ...state,
        reportForAdministration: {
          status: "",
          data: action.data,
          message: action.message,
        },
      };

    case actions.REPORT_FOR_ADMINISTRATION_FAIL:
      return {
        ...state,
        reportForAdministration: {
          status: "error",
          data: { file_content: "" },
          message: action.message,
        },
      };

    default:
      return {
        ...state,
      };
  }
}

const authToken = () => localStorage.getItem("token");

function transformData(data) {
  return data.data;
}

// techniciansResponseTime
export function* watcher_techniciansResponseTime() {
  yield takeLatest(actions.TECHNICIANS_RESPONSE_TIME, techniciansResponseTime);
}

function _techniciansResponseTime(options) {
  return axios_analytics(options).get(`technicians_response_time/`, {
    params: options.queryParams,
  });
}

function* techniciansResponseTime(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_techniciansResponseTime, options);
    const data = transformData(response);
    yield put({
      type: actions.TECHNICIANS_RESPONSE_TIME_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({
      type: actions.TECHNICIANS_RESPONSE_TIME_FAIL,
      error,
      message: "",
    });
  }
}
//techniciansResponseTime END

// workOrderWorkingAvgTime
export function* watcher_workOrderWorkingAvgTime() {
  yield takeLatest(
    actions.WORK_ORDER_WORKING_AVG_TIME,
    workOrderWorkingAvgTime
  );
}

function _workOrderWorkingAvgTime(options) {
  return axios_analytics(options).get(`work_order_working_avg_time/`, {
    params: options.queryParams,
  });
}

function* workOrderWorkingAvgTime(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_workOrderWorkingAvgTime, options);
    const data = transformData(response);
    yield put({
      type: actions.WORK_ORDER_WORKING_AVG_TIME_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({
      type: actions.WORK_ORDER_WORKING_AVG_TIME_FAIL,
      error,
      message: "",
    });
  }
}
//workOrderWorkingAvgTime END

// totalDowntime
export function* watcher_totalDowntime() {
  yield takeLatest(actions.TOTAL_DOWNTIME, totalDowntime);
}

function _totalDowntime(options) {
  return axios_analytics(options).get(`/total_downtime/`, {
    params: options.queryParams,
  });
}

function* totalDowntime(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_totalDowntime, options);
    const data = transformData(response);
    yield put({
      type: actions.TOTAL_DOWNTIME_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.TOTAL_DOWNTIME_FAIL, error, message: "" });
  }
}
//totalDowntime END

// totalDowntimePerAsset
export function* watcher_totalDowntimePerAsset() {
  yield takeLatest(actions.TOTAL_DOWNTIME_PER_ASSET, totalDowntimePerAsset);
}

function _totalDowntimePerAsset(options) {
  return axios_analytics(options).get(`/total_downtime_per_asset/`, {
    params: options.queryParams,
  });
}

function* totalDowntimePerAsset(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_totalDowntimePerAsset, options);
    const data = transformData(response);
    yield put({
      type: actions.TOTAL_DOWNTIME_PER_ASSET_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({
      type: actions.TOTAL_DOWNTIME_PER_ASSET_FAIL,
      error,
      message: "",
    });
  }
}
//totalDowntimePerAsset END

// mtbm
export function* watcher_mtbm() {
  yield takeLatest(actions.MTBM, mtbm);
}

function _mtbm(options) {
  return axios_analytics(options).get(`/mtbm/`, {
    params: options.queryParams,
  });
}

function* mtbm(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_mtbm, options);
    const data = transformData(response);
    yield put({
      type: actions.MTBM_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.MTBM_FAIL, error, message: "" });
  }
}
//mtbm END

// maintenanceNumberPerAsset
export function* watcher_maintenanceNumberPerAsset() {
  yield takeLatest(
    actions.MAINTENANCE_NUMBER_PER_ASSET,
    maintenanceNumberPerAsset
  );
}

function _maintenanceNumberPerAsset(options) {
  return axios_analytics(options).get(`/maintenance_number_per_asset/`, {
    params: options.queryParams,
  });
}

function* maintenanceNumberPerAsset(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_maintenanceNumberPerAsset, options);
    const data = transformData(response);
    yield put({
      type: actions.MAINTENANCE_NUMBER_PER_ASSET_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({
      type: actions.MAINTENANCE_NUMBER_PER_ASSET_FAIL,
      error,
      message: "",
    });
  }
}
//maintenanceNumberPerAsset END

// mttr
export function* watcher_mttr() {
  yield takeLatest(actions.MTTR, mttr);
}

function _mttr(options) {
  return axios_analytics(options).get(`/mttr/`, {
    params: options.queryParams,
  });
}

function* mttr(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_mttr, options);
    const data = transformData(response);
    yield put({
      type: actions.MTTR_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.MTTR_FAIL, error, message: "" });
  }
}
//mttr END

// mtbf
export function* watcher_mtbf() {
  yield takeLatest(actions.MTBF, mtbf);
}

function _mtbf(options) {
  return axios_analytics(options).get(`/mtbf/`, {
    params: options.queryParams,
  });
}

function* mtbf(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_mtbf, options);
    const data = transformData(response);
    yield put({
      type: actions.MTBF_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.MTBF_FAIL, error, message: "" });
  }
}
//mtbf END

// numOfDowntimesPerCategory
export function* watcher_numOfDowntimesPerCategory() {
  yield takeLatest(
    actions.NUM_OF_DOWNTIMES_PER_CATEGORY,
    numOfDowntimesPerCategory
  );
}

function _numOfDowntimesPerCategory(options) {
  return axios_analytics(options).get(`/num_of_downtimes_per_category/`, {
    params: options.queryParams,
  });
}

function* numOfDowntimesPerCategory(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_numOfDowntimesPerCategory, options);
    const data = transformData(response);
    yield put({
      type: actions.NUM_OF_DOWNTIMES_PER_CATEGORY_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({
      type: actions.NUM_OF_DOWNTIMES_PER_CATEGORY_FAIL,
      error,
      message: "",
    });
  }
}
//numOfDowntimesPerCategory END

// techniciansLoginAndWorkTime
export function* watcher_techniciansLoginAndWorkTime() {
  yield takeLatest(
    actions.TECHNICIANS_LOGIN_AND_WORK_TIME,
    techniciansLoginAndWorkTime
  );
}

function _techniciansLoginAndWorkTime(options) {
  return axios_analytics(options).get(`/technicians_login_and_work_time/`, {
    params: options.queryParams,
  });
}

function* techniciansLoginAndWorkTime(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_techniciansLoginAndWorkTime, options);
    const data = transformData(response);
    yield put({
      type: actions.TECHNICIANS_LOGIN_AND_WORK_TIME_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({
      type: actions.TECHNICIANS_LOGIN_AND_WORK_TIME_FAIL,
      error,
      message: "",
    });
  }
}
//techniciansLoginAndWorkTime END

// technicianAvailabilityTrend
export function* watcher_technicianAvailabilityTrend() {
  yield takeLatest(
    actions.TECHNICIAN_AVAILABILITY_TREND,
    technicianAvailabilityTrend
  );
}

function _technicianAvailabilityTrend(options) {
  return axios_analytics(options).get(`/technician_availability_trend/`, {
    params: options.queryParams,
  });
}

function* technicianAvailabilityTrend(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_technicianAvailabilityTrend, options);
    const data = transformData(response);
    yield put({
      type: actions.TECHNICIAN_AVAILABILITY_TREND_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({
      type: actions.TECHNICIAN_AVAILABILITY_TREND_FAIL,
      error,
      message: "",
    });
  }
}
//technicianAvailabilityTrend END

// technicianGenealEffect
export function* watcher_technicianGenealEffect() {
  yield takeLatest(actions.TECHNICIAN_GENEAL_EFFECT, technicianGenealEffect);
}

function _technicianGenealEffect(options) {
  return axios_analytics(options).get(`/technician_general_effect/`, {
    params: options.queryParams,
  });
}

function* technicianGenealEffect(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_technicianGenealEffect, options);
    const data = transformData(response);
    yield put({
      type: actions.TECHNICIAN_GENEAL_EFFECT_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({
      type: actions.TECHNICIAN_GENEAL_EFFECT_FAIL,
      error,
      message: "",
    });
  }
}
//technicianGenealEffect END

// assetMaintenanceSummary
export function* watcher_assetMaintenanceSummary() {
  yield takeLatest(actions.ASSET_MAINTENANCE_SUMMARY, assetMaintenanceSummary);
}

function _assetMaintenanceSummary(options) {
  return axios_analytics(options).get(`/assets_maintenance_summary/`, {
    params: options.queryParams,
  });
}

function* assetMaintenanceSummary(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_assetMaintenanceSummary, options);
    const data = transformData(response);
    yield put({
      type: actions.ASSET_MAINTENANCE_SUMMARY_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({
      type: actions.ASSET_MAINTENANCE_SUMMARY_FAIL,
      error,
      message: "",
    });
  }
}
//assetMaintenanceSummary END

// assetMaintenanceList
export function* watcher_assetMaintenanceList() {
  yield takeLatest(actions.ASSET_MAINTENANCE_LIST, assetMaintenanceList);
}

function _assetMaintenanceList(options) {
  return axios_analytics(options).get(`/asset_maintenance_list/`, {
    params: options.queryParams,
  });
}

function* assetMaintenanceList(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_assetMaintenanceList, options);
    const data = transformData(response);
    yield put({
      type: actions.ASSET_MAINTENANCE_LIST_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({
      type: actions.ASSET_MAINTENANCE_LIST_FAIL,
      error,
      message: "",
    });
  }
}
//assetMaintenanceList END

// reporterGeneralEffect
export function* watcher_reporterGeneralEffect() {
  yield takeLatest(actions.REPORTER_GENERAL_EFFECT, reporterGeneralEffect);
}

function _reporterGeneralEffect(options) {
  return axios_analytics(options).get(`/reporter_general_effect/`, {
    params: options.queryParams,
  });
}

function* reporterGeneralEffect(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_reporterGeneralEffect, options);
    const data = transformData(response);
    yield put({
      type: actions.REPORTER_GENERAL_EFFECT_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({
      type: actions.REPORTER_GENERAL_EFFECT_FAIL,
      error,
      message: "",
    });
  }
}
//reporterGeneralEffect END

// totalSpentParts
export function* watcher_totalSpentParts() {
  yield takeLatest(actions.TOTAL_SPENT_PARTS, totalSpentParts);
}

function _totalSpentParts(options) {
  return axios_parts(options).get(`reports/total_spent/`, {
    params: options.queryParams,
  });
}

function* totalSpentParts(payload) {
  let data = {};
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_totalSpentParts, options);
    data = transformData(response);
    yield put({
      type: actions.TOTAL_SPENT_PARTS_SUCCESS,
      data: data,
    });

    if (payload.successCallback) {
      payload.successCallback(data);
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback(data);
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.TOTAL_SPENT_PARTS_FAIL, error, message: "" });
  }
}
//totalSpentParts END

// stockPurchase
export function* watcher_stockPurchase() {
  yield takeLatest(actions.STOCK_PURCHASE, stockPurchase);
}

function _stockPurchase(options) {
  return axios_parts(options).get(`reports/stock_purchase/`, {
    params: options.queryParams,
  });
}

function* stockPurchase(payload) {
  let data = {};
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_stockPurchase, options);
    data = transformData(response);
    yield put({
      type: actions.STOCK_PURCHASE_SUCCESS,
      data: data,
    });

    if (payload.successCallback) {
      payload.successCallback(data);
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback(data);
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.STOCK_PURCHASE_FAIL, error, message: "" });
  }
}
//stockPurchase END

// Report for administration
export function* watcher_reportForAdministration() {
  yield takeLatest(actions.REPORT_FOR_ADMINISTRATION, reportForAdministration);
}

function _reportForAdministration(options) {
  return axios_analytics(options).get(`/report_for_administration/`, {
    params: options.queryParams,
  });
}

function* reportForAdministration(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_reportForAdministration, options);
    const data = transformData(response);
    yield put({
      type: actions.REPORT_FOR_ADMINISTRATION_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({
      type: actions.REPORT_FOR_ADMINISTRATION_FAIL,
      error,
      message: "",
    });
  }
}
// Report for administration END
