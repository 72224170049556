import { Input, message, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Columns from "../../../../components/Columns";
import LoadMoreButton from "../../../../components/LoadMoreButton";
import { LIMIT_FOR_LOAD_MORE } from "../../../../helpers/consts";

import { sortTableColumns } from "../../../../helpers/functions";
import { GET_PARTS } from "../../../../redux/modules/Parts/actions";

const LOCAL_STORAGE_KEY = "paginationPartsTable";

const PartsList = ({}) => {
  const initialColumns = [
    {
      title: "Naziv artikla",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => (
        <div>
          {"  "}
          <Link to={`/parts/part/${record.id}/about`}>{record.name}</Link>
        </div>
      ),
      visible: true,
    },
    {
      title: "Kategorija artikla",
      dataIndex: "category",
      key: "category",
      render: (text, value) => value.category?.name,
      visible: true,
    },
    {
      title: "Šifra",
      dataIndex: "code",
      key: "code",
      visible: true,
    },
    {
      title: "Cijena",
      dataIndex: "avg_price",
      key: "price",
      visible: true,
    },
    {
      title: "Zaliha",
      dataIndex: "total_current_qty",
      key: "quantity",
      visible: true,
    },
    {
      title: "Imovina",
      dataIndex: "items",
      key: "items",
      render: (text, record, index) => (<span>{record.items?.map(x => x.name).join(", ")}</span>),
      visible: true,
    },
    {
      title: "Kritična zaliha",
      dataIndex: "critical_qty",
      key: "critical_qty",
      render: (text, record, index) => (
        <div>
          <span
            style={{
              color: +record.qty < +record.critical_qty ? "rgb(255, 0, 0)" : "",
            }}
          >
            {record.critical_qty}
          </span>
        </div>
      ),
      visible: true,
    },

    {
      title: "Opis artikla",
      dataIndex: "description",
      key: "description",
      visible: true,
    },
  ];

  const location = useLocation();

  const { parts, getParts } = useSelector((state) => state.partsReducer);

  const [search, set_search] = useState("");

  const [COLUMNS, set_COLUMNS] = useState(initialColumns);

  const [pagination, set_pagination] = useState({pageSize: 24, current: 1});

  const dispatch = useDispatch();

  const onChange = (a) => {
    set_pagination(a);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(a))
  };

  const setPrevPagination = async () => {
    let tmp = await localStorage.getItem(LOCAL_STORAGE_KEY);
    if(tmp){
      let parsed = JSON.parse(tmp);
      set_pagination(parsed);
    }
  } // used when client coems back from profile


  useEffect(() => {
    setPrevPagination();
  }, [])

  const getPartsFun = (next, mergeData) => {
    // new call if user chan
    dispatch({
      type: GET_PARTS,
      queryParams: {
        limit: LIMIT_FOR_LOAD_MORE,
        order_by: "name",
        cursor: "name|id",
        next,
      },
      mergeData,

      errorCallback: () => {
        message.error("Dogodila se greška kod dohvata podataka...");
      },
    });
  };

  useEffect(() => {
      getPartsFun(parts.data.cursor.next);
    sortTableColumns(initialColumns, location.pathname, set_COLUMNS);
  }, []);

  let filtredData = [];

  if (!search) {
    filtredData = parts.data.data;
  } else {
    filtredData = parts.data.data.filter((x) =>
      (x.code + x.name + x.items?.map(x=>x.name).join("")).toLowerCase().includes(search.toLowerCase())
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: 12,
        }}
      >
        <div>
          <Columns
            columns={COLUMNS}
            memorizedName={location.pathname}
            onChange={async (c) => {
              // c je  kolone i vidljivo nevidljivo default sortirano, orderToMemorize pamti raspored u storage
              set_COLUMNS(c);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              width: "320px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {" "}
            <div style={{ height: "12px" }}></div>
            <Input.Search
              allowClear={true}
              placeholder="Unesi naziv ili šifru"
              enterButton
              size="default"
              onChange={({target: {value}}) => {
                if(!value){
                  set_search("");
                }else{
                  set_search(value);
                }
              }}
              // onPressEnter={(event) => set_search(event.target.value)}
              // onSearch={(value) => {
              //   set_search(value);
              // }}
            />
          </div>
        </div>
      </div>
      <Spin spinning={getParts.status === "loading"}>
        <Table
          rowKey={(record) => record.id}
          size="small"
          dataSource={filtredData}
          columns={COLUMNS.filter((x) => x.visible)}
          onChange={onChange}
          pagination={pagination}
        />
        {parts.data.cursor.next && (
          <LoadMoreButton
            loading={getParts.status === "loading"}
            onClick={() => getPartsFun(parts.data.cursor.next, true)}
          />
        )}
      </Spin>
    </>
  );
};

export default PartsList;
