import React from "react";

import { Popconfirm, List, Icon, Button, Alert, Spin } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { REMOVE_PART_CATEGORY } from "../../../../redux/modules/Parts/actions";
import css from "../../parts.module.scss";

const CategoryList = ({ setError, item, set_update, deep }) => {
  const dispatch = useDispatch();

  const { removePartCategory } = useSelector((state) => state.partsReducer);

  const onDelete = (id) => {
    dispatch({
      type: REMOVE_PART_CATEGORY,
      id,
      data: { is_active: false },
      errorCallback: () => setError(),
    });
    // Delete list item
  };

  const childs = item.replies && item.replies.length && (
    <List
      style={{ paddingTop: "0px" }}
      itemLayout="horizontal"
      dataSource={item.replies}
      renderItem={(item) => (
        <CategoryList deep={deep + 1} setError={setError} set_update={set_update} item={item} />
      )}
    />
  );


  return (
    <>
      <List.Item
      className={css.hover}
        style={{ padding: "6px", marginLeft: deep * 12  }}
        extra={
          <>
            <Icon
              onClick={() => set_update(item)}
              type="edit"
              style={{
                cursor: "pointer",
                marginRight: "20px",
                fontSize: "18px",
                color: "#4890ff",
              }}
            />
            <Popconfirm
              id="gdjesam"
              title="Jeste li sigurni da želite izbrisati?"
              placement="left"
              okText="Izbriši"
              cancelText="Odustani"
              onConfirm={() => onDelete(item.id, false)}
            >
              {removePartCategory.status === "loading" + item.id ? (
                <Spin />
              ) : (
                <Icon
                  href="#"
                  type="delete"
                  style={{
                    cursor: "pointer",
                    marginRight: "0px",
                    fontSize: "18px",
                    color: "#4890ff",
                  }}
                />
              )}
            </Popconfirm>
          </>
        }
      >
        <List.Item.Meta
          className={"category-list-item"}
          description={item.name}
        />
      </List.Item>
      {childs !== 0 ? childs : null}
    </>
  );
};

export default CategoryList;

const alert = (
  <Alert
    style={{ marginBottom: "10px", zIndex: "1" }}
    message="Dogodila se greša"
    description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
    type="error"
  />
);
