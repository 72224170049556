import React, { useEffect, useState } from "react";

import { message, PageHeader, Table, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { INSURANCE_ORDERS } from "../../redux/modules/Maintenances/actions";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/hr";

import {
  INSURANCE_ORDERS_STATUS,
} from "../../helpers/consts";
import Loading from "../../components/LoadingScreen";

const { Search } = Input;

const Insurance = ({}) => {
  const dispatch = useDispatch();

  const [search, set_search] = useState("");

  const [filteredInfo, set_filteredInfo] = useState({
    status: null,
  });

  useEffect(() => {
    dispatch({
      type: INSURANCE_ORDERS,
      errorCallback: () => {
        message.error("Ups dogodila se greška kod dohvata podataka", 3);
      },
    });
  }, []);

  const { insuranceOrders } = useSelector((state) => state.maintenancesReducer);

  const COLUMNS = [
    {
      title: "Nalog",
      key: "name",
      dataIndex: "name",
      render: (text, record) => {
        return <Link to={`/insurance/${record.id}/`}>Pregledaj</Link>;
      },
    },
    {
      title: "Kreiran",
      key: "created_at",
      dataIndex: "created_at",
      render: (text, record) => (
        <span>{moment(text).locale("hr").format("LLL") + "h"}</span>
      ),
    },

    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      filters: INSURANCE_ORDERS_STATUS.map((x) => ({
        text: x.text,
        value: x.value,
      })),
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => {
        return record.status.includes(value);
      },
      render: (text, record) => {
        return (
          <span>
            {
              INSURANCE_ORDERS_STATUS.find((x) => x.value === record.status)
                .text
            }{" "}
          </span>
        );
      },
    },

    {
      title: "Nalog održavanja",
      key: "order",
      dataIndex: "order",
      render: (text, record) => {
        return (
          <Link
            target="_blank"
            to={`/maintenances/${record.work_order.id}/about`}
          >
            {record.work_order.name}
          </Link>
        );
      },
    },
    {
      title: "Imovina",
      key: "asset",
      dataIndex: "asset",
      render: (text, record) => {
        return (
          <Link
            target="_blank"
            to={`/machines/${record.work_order.item.id}/specifications`}
          >
            {record.work_order.item.name}
          </Link>
        );
      },
    },
  ];

  if (insuranceOrders.status === "loading") {
    return <Loading />;
  }

  console.log(search);

  const filtredOrders = search
    ? insuranceOrders.data.filter((x) =>
        x.work_order.item.name.toLowerCase().includes(search.toLowerCase())
      )
    : insuranceOrders.data;

  return (
    <>
      <PageHeader
        style={{ paddingTop: "0 !important" }}
        title={
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              // margin: "10px 0",
            }}
          >
            {" "}
            <h1 style={{ fontSize: 20, fontWeight: 600 }}>Osiguranje</h1>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Search
                allowClear={true}
                placeholder="Unesi naziv imovine"
                enterButton
                size="default"
                onPressEnter={(event) => set_search(event.target.value)}
                onSearch={(value) => set_search(value)}
              />
            </div>
          </div>
        }
      />

      <Table
        onChange={(pagination, filters) => {
          set_filteredInfo({
            status: filters.status,
          });
        }}
        rowKey={(x) => x.id}
        columns={COLUMNS}
        dataSource={filtredOrders}
      />
    </>
  );
};

export default Insurance;
