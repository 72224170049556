import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApiEndpoint } from "../../axios/endpoints";
import { DESTROY_TOKEN_FOR_REDIRECT } from "../../redux/modules/Users/actions";
import Spinner from "../../components/LoadingScreen";
import { message, Row, Col, List, Input, Badge, Button, Upload, Modal, Popconfirm } from "antd";
import SectionTitle from "../Machine/components/SectionTitle";
import { DeleteOutlined, PlusSquareOutlined, UploadOutlined } from "@ant-design/icons";
import { ALLOWED_EXTENSIONS } from "../../helpers/consts";
import { DELETE_MATERIAL } from "../../redux/modules/Machines/actions";

const Materials = () => {

const deleteMaterial = useSelector(state => state.machineReducer.deleteMaterial);
  const [loading, set_loading] = useState(true);
  const [data, set_data] = useState([]);
  const [activeAsset, set_activeAsset] = useState();
  const dispatch = useDispatch();
  const [search, set_search] = useState("");
  const [addDocVisible, set_addDocVisible] = useState(0);
  const [files, set_files] = useState([]);
  const [loadingFile, set_loadingFile] = useState(0);

  const saveFiles = async () => {
      let asset_id = addDocVisible;
      set_loadingFile(asset_id);

      let token = await localStorage.getItem("token");

      let fd = new FormData();

      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        fd.append("files", element);
      }

      try {

        let response = await Axios.post(`${getApiEndpoint()}items/items/${asset_id}/add_documents/`, fd, {
          headers: {Authorization: "Token " + token}
        })

        let index = data.findIndex(x => x.id === asset_id);
        let tmp = {...data[index]};

        tmp.documents = response.data.documents;
        let tmp_data = [...data];
        tmp_data[index] = tmp;
        set_data(tmp_data);
        set_addDocVisible(false);
        set_activeAsset(tmp);
        
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 401) {
          dispatch({ type: DESTROY_TOKEN_FOR_REDIRECT });
          return;
        }
  
        message.error("Dogodila se greska kod spremanja");
      }

      set_loadingFile(0)
  };



  const getAllMaterials = async () => {
    set_loading(true);
    let token = await localStorage.getItem("token");

    try {
      let response = await Axios.get(
        getApiEndpoint() + "items/items/all_files/",
        {
          headers: { Authorization: "Token " + token },
        }
      );

      set_data(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: DESTROY_TOKEN_FOR_REDIRECT });
        return;
      }

      message.error("Dogodila se greska kod dohvata...");
    }

    set_loading(false);
  };

  useEffect(() => {
    getAllMaterials();
  }, []);

  if (loading === true) {
    return <Spinner />;
  }

  let filtredData = data;
  if (search) {
    filtredData = data.filter((x) =>
      x.name.toLowerCase().includes(search.toLowerCase())
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div>
          <SectionTitle title={"Dokumenti"} />
        </div>
        <div>
          <Input
            placeholder="Pretraga"
            onChange={({ target: { value } }) => {
              set_search(value);
            }}
          />
        </div>
      </div>

      <Row>
        <Col
          style={{ padding: 12, maxHeight: "70vh", overflowY: "scroll" }}
          span={8}
        >
          <List
            dataSource={filtredData || []}
            renderItem={(item, i) => {
              return (
                <List.Item
                  actions={[
                    <Button onClick={() => {
                      set_addDocVisible(item.id);
                      set_files([]);
                      document.getElementById("uplButton")?.click()}}>
                      <PlusSquareOutlined />
                    </Button>
                  ]}
                  className="hover documents"
                >
                  <List.Item.Meta
                    style={{
                      backgroundColor:
                        activeAsset?.id === item.id ? "#e6f7ff" : "",
                      padding: 6,
                    }}
                    onClick={() => {
                      set_activeAsset(item);
                    }}
                    title={item.name}
                  />
                  <Badge
                    style={{ backgroundColor: "#7546FF" }}
                    count={item.documents ? item.documents?.length : 0}
                  />
                </List.Item>
              );
            }}
          />
        </Col>
        <Col style={{ padding: 12 }} span={16}>
          <List
            dataSource={activeAsset?.documents || []}
            renderItem={(item, i) => (
              <List.Item actions={[ <Popconfirm onConfirm={() => {
                dispatch({
                  type: DELETE_MATERIAL,
                  item_id: activeAsset.id,
                  material_id: item.id,
                  topic_type: "document",
                  callback: () => {
                    let tmp = activeAsset?.documents || [];
                    let index = tmp.findIndex(x => x.id === item.id);
                    if(index !== -1 && activeAsset){
                      tmp.splice(index, 1);
                      set_activeAsset({...activeAsset, documents: tmp});
                    }

                  }
                })
              }} title="Nastaviti sa akcijom?">
                <Button loading={deleteMaterial.status === item.id + "document"} type="dashed" style={{marginLeft: 12}} >
                  <DeleteOutlined />
                </Button>
              </Popconfirm>]} className="hover">
                <List.Item.Meta
                  title={
                    <div>
                      <a
                        href="#"
                        onClick={() => {
                          window.open(
                            getApiEndpoint() + item.path,
                            "_blank",
                            "fullscreen=yes"
                          );
                          return false;
                        }}
                      >
                        {item.name}
                      </a>
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Modal
      okButtonProps={{
        loading: !!loadingFile,
        disabled: !files.length
      }}
      
      onOk={saveFiles}
      forceRender={true}
        title="Dodaj dokument"
        visible={!!addDocVisible}
        onCancel={() => set_addDocVisible(0)}
      >
        <Upload
        fileList={files}
        onRemove={f => {
          let index = files.findIndex(x => x === f);
          let tmp = [...files];
          if(index !== -1 ){
            tmp.splice(index , 1);
          }

          set_files(tmp);
        }}
          accept={
            ALLOWED_EXTENSIONS.find((x) => x.type === "document").extensions
          }
          beforeUpload={(file, files) => {
            set_files(files);
            return false;
          }}
        >
          <Button id={"uplButton"}>
            Ucitaj <UploadOutlined />
          </Button>
        </Upload>
      </Modal>
    </div>
  );
};

export default Materials;
