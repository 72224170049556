import React, { useState } from "react";

import { Button, Drawer, Icon, Tabs } from "antd";

import Filters from "./components/Filters";

import NumOfMaintenancesByCategory from "./components/NumOfMaintenancesByCategory";
import UnfinishedOrders from './components/UnfinishedOrders'
import ScheduleCompliance from './components/ScheduleCompliance'

import {
  useHistory,
  useRouteMatch,
  NavLink,
  Switch,
  Route,
} from "react-router-dom";

const { TabPane } = Tabs;

const Maintenances = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const [activeKey, set_activeKey] = useState(
    history.location.pathname.split("/").slice(-1)[0]
  );
  const [filtersVisible, set_filtersVisible] = useState(false);

  return (
    <>
      <div>
        <Tabs
          defaultActiveKey={activeKey}
          onChange={(key) => {
            history.replace(`/analytics/maintenances/${key}`);
          }}
        >
          {TABS.map((item) => (
            <TabPane
              tab={
                <>
                  {item.icon}
                  <NavLink to={`${match.url}/${item.path}`}>
                    {item.name}
                  </NavLink>
                </>
              }
              key={item.path}
            />
          ))}
        </Tabs>
      </div>
      <div>
        <Switch>
        <Route
            path={`${match.path}/reports_1`}
            render={() => <NumOfMaintenancesByCategory category_for_report={"proizvodni_strojevi"} />}
          />
          <Route
            path={`${match.path}/reports_2`}
            render={() => <NumOfMaintenancesByCategory category_for_report={"klima_uređaji"} />}
          />
          <Route
            path={`${match.path}/reports_3`}
            render={() => <NumOfMaintenancesByCategory category_for_report={"kompresorska_stanica"} />}
          />
          <Route
            path={`${match.path}/reports_4`}
            render={() => <NumOfMaintenancesByCategory category_for_report={"kranovi"} />}
          />
          <Route
            path={`${match.path}/reports_5`}
            render={() => <NumOfMaintenancesByCategory category_for_report={"pumpe_i_rashladni_uređaji"} />}
          />
          <Route
            path={`${match.path}/reports_6`}
            render={() => <NumOfMaintenancesByCategory category_for_report={"vilicari"} />}
          />

        </Switch>
      </div>

     
    </>
  );
};

export default Maintenances;

//

const TABS = [
  {
    name: "Proizvodni strojevi",
    path: "reports_1",
  },
  {
    name: "Klima uređaji",
    path: "reports_2",
  },
  {
    name: "Kompresorska stanica",
    path: "reports_3",
  },
  {
    name: "Kranovi",
    path: "reports_4",
  },
  {
    name: "Pumpe i rashladni uređaji",
    path: "reports_5",
  },
  {
    name: "Viličari",
    path: "reports_6",
  },
 
];
