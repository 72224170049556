import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Button, Icon, List } from "antd";
import nestedSorting from "../../../../helpers/NestedSorting";
import Asset from "./Asset";

const Tree = ({ assets, target, toggleForm }) => {
  let {machines} = useSelector((state) => state.machinesReducer);

  if (assets) {
    machines = { data: assets, status: "" };
  }

  const [parent, set_parent] = useState(null);

  useEffect(() => {
    let parent = machines.data.find(
      (x) => x.item_parent && x.id === x.item_parent.id
    );
    if (!parent || parent === undefined || parent === -1) {
      set_parent(null);
      return;
    }

    set_parent(parent);
  }, []);

  const assetsTree = nestedSorting(machines.data, "parent");




  return (
    <>
    <List
      header={<div style={{display: "flex", justifyContent: "flex-end", alignItems: "center", flexDirection: "row"}} >
      {/* <Button type="primary" onClick={() => toggleForm()} >  Dodaj <Icon type="plus" /></Button> */}
      </div>}
      style={{ marginTop: 32 }}
      itemLayout="horizontal"
      dataSource={assetsTree}
      renderItem={(item) => (
        <Asset assets={machines.data} target={target} assetsTree={assetsTree} item={item} />
      )}
    />
    </>
  );
};

export default Tree;
