import React, { Component } from "react";

import * as actions from "../../redux/modules/Users/actions";

import { connect } from "react-redux";

import ChangePassword from "./ChangePassword";

class Profile extends Component {
  state = {};
  render() {
    return <ChangePassword changePassword={this.props.changePassword} _changePassword={this.props.onChangePassword} currentUser={this.props.currentUser} />;
  }
}

const mapStateToProps = (state) => {
  return {
    changePassword: state.usersReducer.changePassword,
    currentUser: state.usersReducer.user.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangePassword: (userID, data, callback) =>
      dispatch({ type: actions.CHANGE_PASSWORD, userID, data, callback }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
