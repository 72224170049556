import { CloseOutlined } from "@ant-design/icons";
import { message } from "antd";
import { ALLOWED_SIZE } from "./consts";
import { case_insensitive_comp, sortByBoolean } from "./NestedSorting";


//ukoliko se radi o incidetnom održavanju i kreirala se checklisa
//korisnik može završiti narudžbu samo ako je barem jedan od checklisti završen
export const canFinishOrder = (order) => {
  if(order.type.id !== 2){
    return true;
  }
  if(order.type.id === 2 && !order.description_checklist){
    return true;
  }
  if(order.description_checklist.filter(x => !x.checked).length === order.description_checklist.length){
    return false;
  }
  return true;
  
}



export const newNestedSorting = (data, disabledNodes = []) => {
  if (data.length === 1) {
    return data.map((item) => {
      console.log({
        ...item,
        key: item.id,
        value: item.id,
        title: item.name,
        parent: item.parent_id,
      });

      return {
        ...item,
        key: item.id,
        value: item.id,
        title: item.name,
        parent: item.parent_id,
      };
    });
  }

  let items_ids = data.map((x) => x.id);

  data = sortByBoolean(data, "parent_id");
  data = data.sort(case_insensitive_comp);
  var comments = [
    ...data.map((item) => {
      const disabled = disabledNodes.includes(item.id);

      let has_parent = true;
      if (
        item["parent_id"] !== null &&
        !items_ids.includes(item["parent_id"])
      ) {
        has_parent = false; // znači da unutra poslanog niza ne postoji njegov parent stoga cemo mu null dodjeliti da bi se moglo kreirat stablo
      }

      return {
        ...item,
        key: item.id,
        value: item.id,
        title: item.name,
        parent: item["parent_id"],
      };
    }),
  ];

  var nodes = {};

  //insert artificial root node
  nodes[-1] = {
    name: "Fake root",
    replies: [],
    children: [],
  };

  //index nodes by their id
  comments.forEach(function (item) {
    if (item.parent == null) {
      item.parent = -1;
    }
    nodes[item.id] = item;
    item.replies = [];
    item.children = [];
  });

  //put items into parent replies
  comments.forEach(function (item) {
    var parent = nodes[item.parent];
    if (parent) {
      parent.replies.push(item);
      parent.children.push(item);
    }
  });

  //root node replies are top level comments

  return nodes[-1].replies;
};

export const sortTableColumns = async (columns, memorizedName, setCallback) => {
  let sortBy = await localStorage.getItem(memorizedName);

  sortBy = sortBy
    ? JSON.parse(sortBy)
    : columns.map((x) => ({ visible: x.visible, dataIndex: x.dataIndex }));

  const sorted = [];

  try {
    sortBy.forEach((x) => {
      const column = columns.find((y) => y.dataIndex === x.dataIndex);
      if (column === undefined) {
        console.log(x);
      }
      column.visible = x.visible;
      sorted.push(column);
    });

    setCallback(sorted);
  } catch (error) {
    setCallback(columns);
    localStorage.removeItem(memorizedName);
  }
};

export const SizeFilesOk = (file, files) => {
  let arr = [];
  if (Array.isArray(file)) {
    arr = files;
  } else {
    arr = [file];
  }

  let filesNameWithOverSize = [];
  arr.forEach((x) => {
    let sizeMB = x.size / (1024 * 1024);
    if (sizeMB > ALLOWED_SIZE) {
      filesNameWithOverSize.push(x.name);
    }
  });

  if (filesNameWithOverSize.length) {
    message.warning(
      `Maksimalna veličina dokumenta je 5MB, prekoračeno ${filesNameWithOverSize.join(
        ", "
      )}.`,
      12
    );
    return false;
  }

  return true;
};

export const list_to_tree = (data, link = "parent_id") => {
  const idMapping = data.reduce((acc, el, i) => {
    acc[el.id] = i;
    return acc;
  }, {});

  console.log(idMapping);

  let root;
  data.forEach((el) => {
    // Handle the root element
    if (el[link] === null) {
      root = {
        ...el,
        key: el.id,
        value: el.id,
        title: el.name,
        parent: el[link] ? el[link].id : null,
      };
      return;
    }
    // Use our mapping to locate the parent element in our data array
    const parentEl = data[idMapping[el[link]]];
    // Add our current el to its parent's `children` array
    parentEl.children = [
      ...(parentEl.children || []),
      {
        ...el,
        key: el.id,
        value: el.id,
        title: el.name,
        parent: el[link] ? el[link].id : null,
      },
    ];
  });

  return root;
};



// Function that formats number to number of decimal points and adds a suffix if provided
export const decimalPointsWithSuffix = (number, decimalPoints = 2, suffix = "") => {
  if (number === null || number === "-") {
    return "-"
  }
  return number.toFixed(decimalPoints) + suffix;
};