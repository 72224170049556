import React, { useEffect, useState } from "react";

import {
  Form,
  Button,
  Input,
  Alert,
  TreeSelect,
  Select,
  InputNumber,
  Upload,
  Icon,
  message,
  Modal,
  Spin,
} from "antd";

import { connect, useDispatch, useSelector } from "react-redux";

import SizeType from "../../../../helpers/deviceTypeSize";
import { ADD_PART, UPDATE_PART } from "../../../../redux/modules/Parts/actions";
import nestedSorting from "../../../../helpers/NestedSorting";
import {
  UNITS_OF_MESURE,
  UNITS_OF_MESURE_CURRENCY,
} from "../../../../helpers/consts";
import { newNestedSorting, SizeFilesOk } from "../../../../helpers/functions";
import Category from "../Category";
import { PlusCircleOutlined } from "@ant-design/icons";
import { getAllssets } from "../../../Accounting/components/User/User";
import childsOfParent from "../../../../helpers/cildsOfParent";
import { GET_MACHINES } from "../../../../redux/modules/Machines/actions";

const PartForm = ({
  update,
  form,
  set_update,
  categories,
  accounts,
  set_managePartsVisible,
  managePartsVisible,
  createPartFromSpend,
  close,
}) => {
  const { getFieldDecorator, getFieldsError, resetFields } = form;


  const { addPart, updatePart } = useSelector((state) => state.partsReducer);
  const {
    prepareRegistration: {
      data: { locations },
    },
    machines,
  } = useSelector((state) => state.machinesReducer);
  const assetsLoading = useSelector((state) => state.machinesReducer.machines.loading);
  const [error, set_error] = useState(false);
  const [files, set_files] = useState([]);
  const [visibleAddCategory, set_visibleAddCategory] = useState(false);


  useEffect(() => {
    dispatch({type: GET_MACHINES});
  }, []);


  const dispatch = useDispatch();

  const setError = () => {
    set_error(true);

    setTimeout(() => {
      set_error(false);
    }, 2100);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (err) return;
      if (!!update) {
        dispatch({
          id: update.id,
          type: UPDATE_PART,
          data: { ...values },
          updateType: "update",
          successCallback: () => {resetFields();set_update(null); },
          errorCallback: setError,
        });
        return;
      }

      const dataCreate = {
        category: values.category,
        description: values.description,
        code: values.code,
        uom: values.uom,
        items: values.items,
        name: values.name,
        critical_qty: values.critical_qty,
        spend: createPartFromSpend ? createPartFromSpend.id : null,
      };

      if (!createPartFromSpend) {
        dataCreate.part_storage = {
          qty: values.qty,
          location: values.location,
          supplier: values.supplier,
          price: values.price,
          qty_uom: values.uom,
          price_uom: UNITS_OF_MESURE_CURRENCY.find((x) => x.text === "Marka")
            .sign,
        };
      }

      let formData = new FormData();
      formData.append("data", JSON.stringify(dataCreate));

      files.forEach((item) => {
        formData.append("files", item);
      });

      dispatch({
        type: ADD_PART,
        data: formData,
        successCallback: () => {
          if (close) {
            resetFields();
            close();
            return;
          }
          set_managePartsVisible(false);
        },
        errorCallback: setError,
      });
    });
  };

  const beforeUploadPhoto = (file) => {
    if (!SizeFilesOk(file)) {
      return false;
    }

    set_files([...files, file]);
    return false;
  };

  const removeFile = (file) => {
    let list = files.concat();
    const index = list.indexOf(file);
    list.splice(index, 1);
    set_files([...list]);
  };

  const model = PART_MODEL(update, !!update);

  const isAddingQuantity = typeof managePartsVisible === "object";

 
  let items = machines.data ? [...machines.data] : [];

  return (
    <>
      <Spin spinning={assetsLoading === "loading"} />
      <Form
        style={{ width: "90%" }}
        {...formItemLayout}
        layout={layoutType}
        onSubmit={handleSubmit}
      >
        {!machines.data?.length && !update && (
          <Alert
            message="Dio ne može biti dodan jer u bazi ne postoji niti jedno skladište"
            type="warning"
          />
        )}
        <Form.Item className={"modal-header"} label={"Šifra"}>
          {getFieldDecorator("code", {
            initialValue: isAddingQuantity
              ? managePartsVisible.code
              : model.code,
            rules: [{ required: true, message: "Ovo polje je obavezno!" }],
          })(
            <Input
              disabled={isAddingQuantity}
              style={{ width: "100px" }}
              placeholder={""}
            />
          )}
        </Form.Item>
        {!isAddingQuantity && (
          <Form.Item className={"modal-header"} label={"Naziv"}>
            {getFieldDecorator("name", {
              initialValue: createPartFromSpend
                ? createPartFromSpend.name
                : model.name,
              rules: [
                { required: true, message: "Unesite tekst!" },
                { min: 3, message: "Minimalno znakova 3!" },
              ],
            })(<Input />)}
          </Form.Item>
        )}
        {!isAddingQuantity && (
          <Form.Item style={{position: "relative"}} label="Kategorija">
            {getFieldDecorator("category", {
              initialValue: model.category,
              rules: [{ required: true, message: "Ovo polje je obavezno" }],
            })(
              <TreeSelect
                allowClear={true}
                treeData={newNestedSorting(categories)}
              />
            )}
          <PlusCircleOutlined style={{position: "absolute", right: -33, top: -1, fontSize: 24}} onClick={() => set_visibleAddCategory(true)}  />
          </Form.Item>
       
        )}

        {
          !isAddingQuantity && (

            <Form.Item label="Imovina:">
           
            {getFieldDecorator("items", {
              initialValue: model.items,
            })(
              <TreeSelect
                showSearch
                multiple={true}
                allowClear={true}
                placeholder="Odaberi imovinu"
                treeData={nestedSorting(
                  getAllssets(items || [], []),
                  "parent",
                  childsOfParent(
                    form.getFieldValue("items") || [],
                    getAllssets(items || [], [])
                  )
                )}
                optionFilterProp="title"
                filterTreeNode={(input, option) => {
                  console.log(input, option);
                  return (
                    option.props.name
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              />
            )}
          </Form.Item>

          )
        }

        {!isAddingQuantity && (
          <Form.Item className={"modal-header"} label={"Kritična zaliha"}>
            {getFieldDecorator("critical_qty", {
              initialValue: model.critical_qty,
            })(<InputNumber style={{ width: "100px" }} placeholder={""} />)}
          </Form.Item>
        )}

        {!isAddingQuantity && (
          <Form.Item className={"modal-header"} label={"Barkod"}>
            {getFieldDecorator("barcode", {
              initialValue: model.barcode,
            })(<Input placeholder={""} />)}
          </Form.Item>
        )}

        {!isAddingQuantity && (
          <Form.Item className={"modal-header"} label={"Opis"}>
            {getFieldDecorator("description", {
              initialValue: model.description,
              rules: [],
            })(<Input.TextArea rows={5} />)}
          </Form.Item>
        )}
        {!isAddingQuantity && (
          <Form.Item label="Mjerna jedinica">
            {getFieldDecorator("uom", {
              initialValue: model.uom,
            })(
              <Select
                mode="single"
                allowClear={true}
                showSearch
                optionFilterProp="name"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {UNITS_OF_MESURE.map((x, i) => (
                  <Select.Option key={i} value={x.sign}>
                    {x.text}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        )}

        <hr />

        {!update && !createPartFromSpend && (
          <Form.Item className={"modal-header"} label={"Zaliha"}>
            {getFieldDecorator("qty", {
              initialValue: model.quantity,
              rules: [{ required: true, message: "Ovo polje je obavezno!" }],
            })(<InputNumber style={{ width: "100px" }} placeholder={""} />)}
          </Form.Item>
        )}
        {!update && !createPartFromSpend && (
          <Form.Item className={"modal-header"} label={"Cijena"}>
            {getFieldDecorator("price", {
              initialValue: model.price,
              rules: [{ required: true, message: "Ovo polje je obavezno!" }],
            })(<InputNumber style={{ width: "100px" }} placeholder={""} />)}
          </Form.Item>
        )}

        {!update && !createPartFromSpend && (
          <Form.Item label="Lokacija">
            {getFieldDecorator("location", {
              initialValue: isAddingQuantity
                ? managePartsVisible.location
                  ? managePartsVisible.location.id
                  : null
                : model.location,
              rules: [{ required: true, message: "Ovo polje je obavezno" }],
            })(
              <TreeSelect
                allowClear={true}
                multiple={false}
                treeData={nestedSorting(
                  machines.data.filter((x) => x.is_warehouse),
                  "parent"
                )}
                showSearch
                optionFilterProp="name"
                filterTreeNode={(input, option) => {
                  return (
                    option.props.name
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              ></TreeSelect>
            )}
          </Form.Item>
        )}
        {!update && !createPartFromSpend && (
          <Form.Item label="Dobavljač">
            {getFieldDecorator("supplier", {
              initialValue: model.supplier,
            })(
              <Select
                mode="single"
                allowClear={true}
                showSearch
                optionFilterProp="name"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {accounts
                  .filter((x) => x.category === "SUP")
                  .map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.full_name}
                    </Select.Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        )}

        {!update && !createPartFromSpend && (
          <Form.Item label="File">
            <Upload
              beforeUpload={beforeUploadPhoto}
              onRemove={removeFile}
              showUploadList={true}
              fileList={files}
              accept=".png,.jpg,.jpeg,.pdf"
            >
              <Button>
                <Icon type="upload" /> Učitaj
              </Button>
            </Upload>
          </Form.Item>
        )}
        <Form.Item {...tailFormItemLayout}>
          <Button
            style={{ float: "right" }}
            loading={
              addPart.status === "loading" || updatePart.status === "loading"
            }
            type="primary"
            htmlType="submit"
            disabled={hasErrors(getFieldsError())}
          >
            Spremi
          </Button>
        </Form.Item>
      </Form>
            <Modal 
              width={900}
              visible={visibleAddCategory}
              footer={null}
              onCancel={() => set_visibleAddCategory(false)}
            >
              <Category categories={categories} set_addingCategoryVisible={set_visibleAddCategory} />
            </Modal>
      <div style={{ maxHeight: "300px" }}>{error && alert}</div>
    </>
  );
};



export default Form.create()(PartForm);

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

const alert = (
  <Alert
    style={{ marginBottom: "10px", zIndex: "1" }}
    message="Dogodila se greša"
    description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
    type="error"
  />
);

const alertSuccess = (
  <Alert
    style={{ marginBottom: "10px", zIndex: "1" }}
    message="Uspješno"
    description={`Uspješno ste dodali kategoriju`}
    type="success"
  />
);

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol:
    SizeType() === "xs" || SizeType() === "sm"
      ? {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 20,
            offset: 0,
          },
        }
      : {
          span: 18,
          offset: 0,
        },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
};

const layoutType =
  SizeType() === "xs" || SizeType() === "sm" ? "vertical" : "horizontal";

const PART_MODEL = (data, isUpdate) => {
  let model = {
    category: null,
    name: null,
    critical_qty: null,
    price: null,
    description: null,
    supplier: null,
    quantity: null,
    code: null,
    location: null,
    uom: UNITS_OF_MESURE.find((x) => x.text === "Komad").sign,
    price_uom: UNITS_OF_MESURE_CURRENCY.find((x) => x.text === "Marka").sign,
    barcode: "",
    items: [],
  };


  if (isUpdate) {
    model.location = data.location ? data.location.id : null;
    model.category = data.category ? data.category.id : null;
    model.name = data.name;
    model.critical_qty = data.critical_qty;
    model.price = data.price;
    model.description = data.description;
    model.supplier = data.supplier ? data.supplier.id : null;
    model.quantity = data.quantity;
    model.code = data.code;
    model.uom = data.uom;
    model.price_uom = data.price_uom;
    model.barcode = data.barcode;
    model.items = data.items?.map(x => x.id) || [];
  }

  return model;
};
