import React from "react";

import { Steps, Icon } from "antd";

import TimeTicking from "../../../../components/TimeTicking";

const { Step } = Steps;

const OrderTimeView = (props) => {
  if (props.status === "waiting") {
    return (
      <Steps direction={"horizontal"} size="small">
        <Step
          status="process"
          icon={<Icon type={"pause"} />}
          description={<TimeTicking realStart={props.real_start} />}
        />
      </Steps>
    );
  }

  return (
    <>
      <Steps direction={"horizontal"} size="small">
        <Step
          status="process"
          icon={<Icon type={"loading"} />}
          description={<TimeTicking realStart={props.real_start} />}
        />
      </Steps>
    </>
  );
};

export default OrderTimeView;
