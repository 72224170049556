import { takeLatest, put, call, select, delay } from "redux-saga/effects";

import * as actions from "./actions";
import store from "../../../store";

import axios_accounting from "../../../axios/axios-accounting";
import {
  DESTROY_TOKEN_FOR_REDIRECT,
  LOGOUT,
  USER,
} from "../../modules/Users/actions";
import { TYPES_OF_USERS } from "../../../helpers/consts";

const initialState = {
  registerUser: {
    data: {},
    status: "",
  },
  updateUser: {
    data: {},
    status: "",
  },
  deleteUser: {
    status: "",
  },
  prepareRegistration: {
    data: {},
    status: "loading",
  },
  user_category: {
    status: "",
  },
  updateUserCategory: {
    status: "",
  },
  deleteUserCategory: {
    status: "",
  },
  company_category: {
    status: "",
  },
  accounts: {
    status: "loading",
    data: [],
  },
  account: {
    status: "loading",
    data: {},
  },
  locations: {
    status: "",
    data: [],
  },
  supplier: {
    status: "loading",
    data: {},
  },
  saveAccountLocations: {
    status: "",
    data: [],
  },
  addDocument: {
    status: "",
  },
  removeDocument: {
    status: "",
  },
  updateSupplierCategory: {
    status: "",
    data: {},
  },
};

export default function reducer(state = initialState, action = {}) {
  var tmp;
  var index = -1;
  switch (action.type) {
    case actions.SAVE_ACCOUNT_LOCATIONS:
      return {
        ...state,
        saveAccountLocations: {
          status: "loading",
          data: [],
          message: "",
        },
      };

    case actions.SAVE_ACCOUNT_LOCATIONS_SUCCESS:
      return {
        ...state,
        saveAccountLocations: {
          status: "",
          data: [],
          message: action.message,
        },
      };

    case actions.SAVE_ACCOUNT_LOCATIONS_FAIL:
      return {
        ...state,
        saveAccountLocations: {
          status: "error",
          data: [],
          message: action.message,
        },
      };
    case actions.REGISTER_USER:
      return {
        ...state,
        registerUser: {
          data: {},
          status: "loading",
        },
      };
    case actions.REGISTER_USER_SUCCESS:
      tmp = [...state.accounts.data];
      tmp.push(action.data);
      return {
        ...state,
        registerUser: {
          data: action.data,
          status: "",
        },
        accounts: {
          data: tmp,
          status: "",
        },
      };
    case actions.REGISTER_USER_FAIL:
      return {
        ...state,
        registerUser: {
          data: {},
          status: "error",
        },
      };
    case actions.UPDATE_USER:
      return {
        ...state,
        updateUser: {
          data: {},
          status: "loading",
        },
      };
    case actions.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUser: {
          data: action.data,
          status: "",
        },
        account: {
          data: { account: action.data },
          status: "",
        },
      };
    case actions.UPDATE_USER_FAIL:
      return {
        ...state,
        updateUser: {
          data: {},
          status: "error",
        },
      };

    case actions.DELETE_USER:
      return {
        ...state,
        deleteUser: {
          status: "loading",
        },
      };

    case actions.DELETE_USER_SUCCESS:
      tmp = [...state.accounts.data];
      index = tmp.findIndex((x) => x.id === action.id);
      tmp.splice(index, 1);
      return {
        ...state,
        deleteUser: {
          status: "",
        },
        accounts: {
          data: tmp,
          status: "",
        },
      };

    case actions.DELETE_USER_FAIL:
      return {
        ...state,
        deleteUser: {
          status: "error",
        },
      };

    case actions.GET_SUPPLIER:
      return {
        ...state,
        supplier: {
          status: "loading",
          data: {},
        },
      };

    case actions.GET_SUPPLIER_SUCCESS:
      return {
        ...state,
        supplier: {
          status: "",
          data: action.data,
        },
      };

    case actions.GET_SUPPLIER_FAIL:
      return {
        ...state,
        supplier: {
          status: "error",
          data: {},
        },
      };

    case actions.GET_PREPARE_REGISTRATION:
      return {
        ...state,
        prepareRegistration: {
          data: {},
          status: "loading",
        },
      };
    case actions.GET_PREPARE_REGISTRATION_SUCCESS:
      return {
        ...state,
        prepareRegistration: {
          data: action.data,
          status: "",
        },
      };
    case actions.GET_PREPARE_REGISTRATION_FAIL:
      return {
        ...state,
        prepareRegistration: {
          data: {},
          status: "error",
        },
      };
    case actions.SAVE_CATEGORY:
      return {
        ...state,
        user_category: {
          status: "loading",
        },
      };
    case actions.SAVE_CATEGORY_SUCCESS:
      tmp = { ...state.prepareRegistration.data };
      tmp.user_categories = action.data;
      return {
        ...state,
        user_category: {
          status: "",
        },
        prepareRegistration: {
          status: "",
          data: tmp,
        },
      };
    case actions.SAVE_CATEGORY_ERROR:
      return {
        ...state,
        user_category: {
          status: "error",
        },
      };

    case actions.UPDATE_CATEGORY:
      return {
        ...state,
        updateUserCategory: {
          status: action.id,
        },
      };
    case actions.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        updateUserCategory: {
          status: "",
        },
      };
    case actions.UPDATE_CATEGORY_ERROR:
      return {
        ...state,
        updateUserCategory: {
          status: "error",
        },
      };

    case actions.DELETE_CATEGORY:
      return {
        ...state,
        deleteUserCategory: {
          status: action.id,
        },
      };
    case actions.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        deleteUserCategory: {
          status: "",
        },
      };
    case actions.DELETE_CATEGORY_ERROR:
      return {
        ...state,
        deleteUserCategory: {
          status: "error",
        },
      };

    case actions.ACCOUNTS:
      return {
        ...state,
        accounts: {
          data: [],
          status: "loading",
        },
      };
    case actions.ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: {
          data: action.data,
          status: "",
        },
      };

    case actions.ACCOUNTS_FAIL:
      return {
        ...state,
        accounts: {
          data: [],
          status: "error",
        },
      };

    case actions.ACCOUNT:
      return {
        ...state,
        account: {
          data: {},
          status: "loading",
        },
      };
    case actions.ACCOUNT_SUCCESS:
      return {
        ...state,
        account: {
          data: action.data,
          status: "",
        },
      };

    case actions.ACCOUNT_FAIL:
      return {
        ...state,
        account: {
          data: {},
          status: "error",
        },
      };

    case actions.SAVE_COMPANY_CATEGORY:
      return {
        ...state,
        user_category: {
          status: "loading",
        },
      };
    case actions.SAVE_COMPANY_CATEGORY_SUCCESS:
      tmp = { ...state.prepareRegistration.data };
      tmp.company_categories = action.data;
      return {
        ...state,
        user_category: {
          status: "",
        },
        prepareRegistration: {
          status: "",
          data: tmp,
        },
      };
    case actions.SAVE_COMPANY_CATEGORY_FAIL:
      return {
        ...state,
        user_category: {
          status: "error",
        },
      };

    case actions.ADD_DOCUMENT:
      return {
        ...state,
        addDocument: {
          status: "loading",
        },
      };

    case actions.ADD_DOCUMENT_SUCCESS:
      return {
        ...state,
        addDocument: {
          status: "",
        },
      };

    case actions.ADD_DOCUMENT_FAIL:
      return {
        ...state,
        addDocument: {
          status: "error",
        },
      };

    case actions.REMOVE_DOCUMENT:
      return {
        ...state,
        removeDocument: {
          status: "loading" + action.id,
        },
      };

    case actions.REMOVE_DOCUMENT_SUCCESS:
      return {
        ...state,
        removeDocument: {
          status: "",
        },
      };

    case actions.REMOVE_DOCUMENT_FAIL:
      return {
        ...state,
        removeDocument: {
          status: "error",
        },
      };

    case actions.UPDATE_SUPPLIER_CATEGORY:
      return {
        ...state,
        updateSupplierCategory: {
          status: "loading",
          data: {},
          message: "",
        },
      };

    case actions.UPDATE_SUPPLIER_CATEGORY_SUCCESS:
      tmp = [...state.prepareRegistration.data.company_categories];
      index = tmp.findIndex((x) => x.id === action.id);
      if (action.delete) {
        tmp.splice(index, 1);
      } else {
        tmp[index] = action.data;
      }
      return {
        ...state,
        updateSupplierCategory: {
          status: "",
          data: {},
          message: action.meessage,
        },
        prepareRegistration: {
          data: {
            ...prepareRegistration.data,
            company_categories: tmp,
          },
          status: "",
        },
      };

    case actions.UPDATE_SUPPLIER_CATEGORY_FAIL:
      return {
        ...state,
        updateSupplierCategory: {
          status: "error",
          data: {},
          message: action.message,
        },
      };

    default:
      return { ...state };
  }
}

function transformData(data) {
  return data.data;
}

const authToken = () => localStorage.getItem("token");

const getUserTopic = (key) => {
  if (TYPES_OF_USERS.map((x) => x.value).includes(key)) {
    return "user";
  }
  return "company";
};

//register user
export function* watcherRegisterUser() {
  yield takeLatest(actions.REGISTER_USER, registerUser);
}

function _registerUser(options) {
  return axios_accounting(options).post(
    `register/${options.topic}/`,
    options.data
  );
}

function* registerUser(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
      topic: getUserTopic(payload.topic),
    };
    const response = yield call(_registerUser, options);
    const data = transformData(response);
    yield put({
      type: actions.REGISTER_USER_SUCCESS,
      data,
    });
    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: DESTROY_TOKEN_FOR_REDIRECT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.REGISTER_USER_FAIL, error });
  }
}
// register user end

// Update user
export function* watcherUpdateUser() {
  yield takeLatest(actions.UPDATE_USER, updateUser);
}

function _updateUser(options) {
  return axios_accounting(options).put(
    `accounts/${options.user_id}/`,
    options.data
  );
}

function* updateUser(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      user_id: payload.user_id,
      data: payload.data,
      topic: getUserTopic(payload.topic),
    };
    const response = yield call(_updateUser, options);
    const data = transformData(response);
    yield put({
      type: actions.UPDATE_USER_SUCCESS,
      user_id: payload.user_id,
      data,
    });

    let users = [...store.getState().accoutingReducer.accounts.data];
    let index = users.findIndex((item) => item.id === payload.user_id);
    users[index] = data;

    yield put({
      type: actions.ACCOUNTS_SUCCESS,
      data: users,
    });

    if (payload.isSupplier) {
      yield put({
        type: actions.GET_SUPPLIER_SUCCESS,
        data,
      });
    }

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: DESTROY_TOKEN_FOR_REDIRECT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.UPDATE_USER_FAIL, error });
  }
}

// Update user end

// Delete user

export function* watcherDeleteUser() {
  yield takeLatest(actions.DELETE_USER, deleteUser);
}

function _deleteUser(options) {
  return axios_accounting(options).patch(`accounts/${options.user_id}/`, {
    is_active: false,
  });
}

function* deleteUser(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      user_id: payload.user_id,
    };
    const response = yield call(_deleteUser, options);
    const data = transformData(response);
    yield put({
      type: actions.DELETE_USER_SUCCESS,
      id: payload.user_id,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);

    if (payload.errorCallback) {
      payload.errorCallback();
    }

    if (error.response && error.response.status === 401) {
      yield put({ type: DESTROY_TOKEN_FOR_REDIRECT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.DELETE_USER_FAIL, error });
  }
}

// Delete user

//Get prepare
export function* watcherGetPrepareRegistration() {
  yield takeLatest(actions.GET_PREPARE_REGISTRATION, prepareRegistration);
}

function _prepareRegistration(options) {
  return axios_accounting(options).get(`prepare_registration/`);
}

function* prepareRegistration(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
    };
    const response = yield call(_prepareRegistration, options);
    const data = transformData(response);
    yield put({
      type: actions.GET_PREPARE_REGISTRATION_SUCCESS,
      data,
    });
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: DESTROY_TOKEN_FOR_REDIRECT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.GET_PREPARE_REGISTRATION_FAIL, error });
  }
}
//get prepare end

//Save category
export function* watcherSaveCategory() {
  yield takeLatest(actions.SAVE_CATEGORY, saveCategory);
}

function _saveCategory(options) {
  return axios_accounting(options).post(`user_categories/`, options.data);
}

function* saveCategory(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
    };
    const response = yield call(_saveCategory, options);
    const data = transformData(response);
    yield put({
      type: actions.SAVE_CATEGORY_SUCCESS,
      data,
    });

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: DESTROY_TOKEN_FOR_REDIRECT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.SAVE_CATEGORY_ERROR, error });
  }
}
//save subcategory end

// Update category
export function* watcherUpdateCategory() {
  yield takeLatest(actions.UPDATE_CATEGORY, updateCategory);
}

function _updateCategory(options) {
  return axios_accounting(options).patch(
    `user_categories/${options.id}/`,
    options.data
  );
}

function* updateCategory(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
      id: payload.id,
    };
    const response = yield call(_updateCategory, options);
    const data = transformData(response);

    yield put({
      type: actions.UPDATE_CATEGORY_SUCCESS,
      data,
      id: payload.id,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (payload.errorCallback) {
      payload.errorCallback();
    }
    if (error.response && error.response.status === 401) {
      yield put({ type: DESTROY_TOKEN_FOR_REDIRECT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.UPDATE_CATEGORY_ERROR, error });
  }
}
// Update category

// Delete category
export function* watcherDeleteCategory() {
  yield takeLatest(actions.DELETE_CATEGORY, deleteCategory);
}

function _deleteCategory(options) {
  return axios_accounting(options).patch(`user_categories/${options.id}/`, {
    is_active: false,
  });
}

function* deleteCategory(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
      id: payload.id,
    };
    const response = yield call(_deleteCategory, options);
    const data = transformData(response);

    let prepare = {
      ...store.getState().accoutingReducer.prepareRegistration.data,
    };
    prepare.user_categories = prepare.user_categories.filter(
      (item) => +item.id !== +data.id
    );
    console.log("PREPARE => ", prepare);

    yield put({
      type: actions.DELETE_CATEGORY_SUCCESS,
      data,
      id: payload.id,
    });

    yield put({
      type: actions.GET_PREPARE_REGISTRATION_SUCCESS,
      data: prepare,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (payload.errorCallback) {
      payload.errorCallback();
    }
    if (error.response && error.response.status === 401) {
      yield put({ type: DESTROY_TOKEN_FOR_REDIRECT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.DELETE_CATEGORY_ERROR, error });
  }
}
// Delete category

//accounts
export function* watcherAccounts() {
  yield takeLatest(actions.ACCOUNTS, accounts);
}

function _accounts(options) {
  return axios_accounting(options).get(`accounts/`);
}

function* accounts(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
    };
    const response = yield call(_accounts, options);
    const data = transformData(response);
    yield put({
      type: actions.ACCOUNTS_SUCCESS,
      data,
    });

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: DESTROY_TOKEN_FOR_REDIRECT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.ACCOUNTS_FAIL, error });
  }
}
//accounts end

// Account
export function* watcherAccount() {
  yield takeLatest(actions.ACCOUNT, account);
}

function _account(options) {
  return axios_accounting(options).get(`accounts/${options.user_id}/`);
}

function* account(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      user_id: payload.user_id,
    };
    const response = yield call(_account, options);
    console.log("RESPONSE  ", response);
    const data = transformData(response);
    yield put({
      type: actions.ACCOUNT_SUCCESS,
      data,
    });

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: DESTROY_TOKEN_FOR_REDIRECT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.ACCOUNT_FAIL, error });
  }
}
// Account end

// Supplier
export function* watcherSupplier() {
  yield takeLatest(actions.GET_SUPPLIER, supplier);
}

function _supplier(options) {
  return axios_accounting(options).get(`accounts/${options.user_id}/`);
}

function* supplier(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      user_id: payload.user_id,
    };
    const response = yield call(_supplier, options);
    const data = transformData(response);
    yield put({
      type: actions.GET_SUPPLIER_SUCCESS,
      data: data.account,
    });

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: DESTROY_TOKEN_FOR_REDIRECT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.GET_SUPPLIER_FAIL, error });
  }
}
// Supplier end

//Save company category
export function* watcherSaveCompanyCategory() {
  yield takeLatest(actions.SAVE_COMPANY_CATEGORY, saveCompanyCategory);
}

function _saveCompanyCategory(options) {
  return axios_accounting(options).post(`company_categories/`, options.data);
}

function* saveCompanyCategory(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
    };
    const response = yield call(_saveCompanyCategory, options);
    const data = transformData(response);
    yield put({
      type: actions.SAVE_COMPANY_CATEGORY_SUCCESS,
      data,
    });

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: DESTROY_TOKEN_FOR_REDIRECT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.SAVE_COMPANY_CATEGORY_FAIL, error });
  }
}
//save subcategory end

// AddDocument
export function* watcher_addDocument() {
  yield takeLatest(actions.ADD_DOCUMENT, addDocument);
}

function _addDocument(options) {
  return axios_accounting(options).post("documents/", options.data);
}

function* addDocument(payload) {
  try {
    // Token
    const token = yield select(authToken);

    // Options
    const options = {
      token: token,
      data: payload.data,
      id: payload.id,
    };

    // Response
    const response = yield call(_addDocument, options);
    const data = transformData(response);

    // Yields
    yield put({
      type: actions.ADD_DOCUMENT_SUCCESS,
      data,
      id: payload.id,
    });

    if (payload.source === "account") {
      let user = { ...store.getState().accoutingReducer.account.data };
      user.account.documents = data;
      yield put({
        type: actions.ACCOUNT_SUCCESS,
        data: user,
      });
    } else if (payload.source === "supplier") {
      let supplier = { ...store.getState().accoutingReducer.supplier.data };
      supplier.documents = data;
      yield put({
        type: actions.GET_SUPPLIER_SUCCESS,
        data: supplier,
      });
    }

    // Success callback
    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    // Logging out error
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    // Error callback
    if (payload.errorCallback) {
      payload.errorCallback();
    }

    // Dispatch a failure action to the store with the error
    yield put({ type: actions.ADD_DOCUMENT_FAIL, error, message: "" });
  }
}
// AddDocument end

// RemoveDocument
export function* watcher_removeDocument() {
  yield takeLatest(actions.REMOVE_DOCUMENT, removeDocument);
}

function _removeDocument(options) {
  return axios_accounting(options).put(`/documents/${options.id}/remove/`);
}

function* removeDocument(payload) {
  try {
    // Token
    const token = yield select(authToken);

    // Options
    const options = {
      token: token,
      id: payload.id,
    };

    // Response
    const response = yield call(_removeDocument, options);

    // Yields
    yield put({
      type: actions.REMOVE_DOCUMENT_SUCCESS,
      id: payload.id,
    });

    if (payload.source === "account") {
      let user = { ...store.getState().accoutingReducer.account.data };
      user.account.documents = user.account.documents.filter(
        (item) => item.id !== payload.id
      );
      yield put({
        type: actions.ACCOUNT_SUCCESS,
        data: user,
      });
    } else if (payload.source === "supplier") {
      let supplier = { ...store.getState().accoutingReducer.supplier.data };
      supplier.documents = supplier.documents.filter(
        (item) => item.id !== payload.id
      );
      yield put({
        type: actions.GET_SUPPLIER_SUCCESS,
        data: supplier,
      });
    }

    // Success callback
    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    // Logging out error
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    // Error callback
    if (payload.errorCallback) {
      payload.errorCallback();
    }

    // Dispatch a failure action to the store with the error
    yield put({ type: actions.REMOVE_DOCUMENT_FAIL, error, message: "" });
  }
}
// RemoveDocument end

// updateSupplierCategory
export function* watcher_deleteSupplierCategory() {
  yield takeLatest(actions.UPDATE_SUPPLIER_CATEGORY, updateSupplierCategory);
}

function _deleteSupplierCategory(options) {
  if (options.data.is_active === false) {
    return axios_accounting(options).put(
      `company_categories/${options.id}/remove/`
    );
  }
  return axios_accounting(options).patch(
    `company_categories/${options.id}/`,
    options.data
  );
}

function* updateSupplierCategory(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
      id: payload.id,
    };
    const response = yield call(_deleteSupplierCategory, options);
    const data = transformData(response);
    yield put({
      type: actions.UPDATE_SUPPLIER_CATEGORY_SUCCESS,
      data,
      id: payload.id,
      delete: payload.data.is_active === false,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({
      type: actions.UPDATE_SUPPLIER_CATEGORY_FAIL,
      error,
      message: "",
    });
  }
}
//updateSupplierCategory END
