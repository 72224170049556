import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Input, List, message, Modal } from "antd"
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApiEndpoint } from "../../../axios/endpoints";
import { SET_SUBASSET } from "../../../redux/modules/Machines/actions";
import { DESTROY_TOKEN_FOR_REDIRECT } from "../../../redux/modules/Users/actions";
import SectionTitle from "./SectionTitle"


const SubAssets = () => {

    const [addTagVisible, set_addTagVisible] = useState(false);
    const dispatch = useDispatch();
    const asset = useSelector(state => state.machineReducer.machine.data); 
    const subassets = useSelector(state => state.machineReducer.subassets); 
    const [loading, set_loading] = useState(0);

    useEffect(() => {
        if(asset.tags){
            dispatch({type: SET_SUBASSET, data: asset.tags || []})
        }

    }, [asset])

    const del = async (id) =>{

        set_loading(id);
        let token = await localStorage.getItem("token");

        try {
            let response = null;
            let tmp = [...subassets];
           
                response = await Axios.delete(getApiEndpoint() + "items/tags/" + id + "/",   {headers: {
                    Authorization: 'Token ' + token,
                }})

                let index = tmp.findIndex(x => x.id === id);

                if(index !== -1){
                    tmp.splice(index, 1);
                }
            dispatch({type: SET_SUBASSET, data: tmp});

        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch({type: DESTROY_TOKEN_FOR_REDIRECT});
                return;
              }

              message.error("Dogodila se greska kod spremanja");
        }

        set_loading(0)

    }


    return <>
    <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center"}} >
    <div>
    <SectionTitle subtext={"Lista podimovine"}  title={"Podimovina"}/>
    </div>
    <Button type="primary" onClick={() => set_addTagVisible({id: 0, name: ""})} >
                Dodaj</Button>
    </div>
      

        <List   dataSource={subassets || []} renderItem={(x, i) => 
        <List.Item actions={[
            <Button onClick={() => {
                set_addTagVisible(x);
            }} ><EditOutlined /></Button>,
            <Button loading={loading === x.id} onClick={() => del(x.id)}  style={{marginLeft: 12}} ><DeleteOutlined /></Button>
        ]}  >
            <List.Item.Meta  title={x.name} />
        </List.Item>
      
        } />
        <Modal destroyOnClose={true} title={addTagVisible?.id === 0 ? "Dodaj" : "Uredi"} footer={null} visible={addTagVisible} onCancel={() => set_addTagVisible(undefined)}>
            <AddTag asset={asset} asset_id={asset.id} tag={addTagVisible} close={() => set_addTagVisible(undefined)} />
        </Modal>
    </>
}
 
export default SubAssets;

export const AddTag = ({asset_id, tag, close}) => {

    const [name, set_name] = useState(tag?.name);
    const dispatch = useDispatch();
    const [loading, set_loading] = useState(false);
    const subassets = useSelector(state => state.machineReducer.subassets || []); 

    const submit = async () => {

        set_loading(true);
        let token = await localStorage.getItem("token");

        try {
            let response = null;
            let tmp = [...subassets];
            if(tag?.id === 0){
                response = await Axios.post(getApiEndpoint() + "items/tags/", {name, item: asset_id}, {headers: {
                    Authorization: 'Token ' + token,
                }})
                tmp.push(response.data);
            }else{
                response = await Axios.patch(getApiEndpoint() + "items/tags/" + tag?.id + "/", {name}, {headers: {
                    Authorization: 'Token ' + token,
                }})

                let index = tmp.findIndex(x => x.id === tag?.id);

                if(index !== -1){
                    tmp[index] = response.data;
                }
            }


            dispatch({type: SET_SUBASSET, data: tmp});

            if(close){
                close();
            }


        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch({type: DESTROY_TOKEN_FOR_REDIRECT});
                return;
              }

              message.error("Dogodila se greska kod spremanja");
        }

        set_loading(false)

    }

    return <div>
        <Input  value={name} onChange={({target: {value}}) => {
            set_name(value);
        }}  />
        <Button loading={loading} type="primary" style={{marginTop: 12}} disabled={!name} onClick={() => {
                submit();
        }} >Spremi</Button>
    </div>
}