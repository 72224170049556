const deviceTypeSize = () => {
  const width = window.screen.width;

  if (width < 576) {
    return "xs";
  }
  if (width < 768) {
    return "sm";
  }

  if (width < 1024) {
    return "md";
  }
  if (width < 1200) {
    return "lg";
  }

  return "xl";
};

export default deviceTypeSize;
  