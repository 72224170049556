import React, { useEffect, useState } from "react";
import moment from "moment";
import { Alert, Button, message, Modal } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { crohHumanizer, STATUS, STATUS_KEYS } from "../../helpers/consts";
import EditTime from "./components/EditTime";
import Loading from "../../components/LoadingScreen";
import axios from "axios";
import { getApiEndpoint } from "../../axios/endpoints";

import EDIT_ICON from "../../assets/icons/edit.png"
import USER_WORKING from "../../assets/icons/clock-filled.png"
import USER_NOT_WORKING from "../../assets/icons/clock-empty.png"
import PLAY from "../../assets/icons/play.png"

const ICON_SIZE = { width: 18, height: 18 };

const OpenOrder = ({}) => {
  const location = useLocation();

  const [order, set_order] = useState({
    status: "loading",
    data: {},
  });

  const [orderToken, set_orderToken] = useState(
    location.pathname.split("/").slice(-1)[0]
  );
  const [start_order, set_start_order] = useState({ status: "", data: {} });
  const [finish_order, set_finish_order] = useState({ status: "", data: {} });
  const [update_time, set_update_time] = useState({ status: "", data: {} });

  const getOrder = () => {
    axios
      .get(`${getApiEndpoint()}maintenances/get_open_work_order/${orderToken}`)
      .then((res) => {
        set_order({ status: "", data: res.data });
      })
      .catch((err) => {
        set_order({ status: "error", data: {} });
      });
  };

  const updateTime = () => {
    set_update_time({ status: "loading", data: "" });
  };

  const startOrder = () => {
    set_start_order({ status: "loading", data: "" });
    axios
      .post(
        `${getApiEndpoint()}maintenances/open_work_orders/${orderToken}/start_work/`
      )
      .then((res) => {
        set_start_order({ status: "", data: {} });
        set_order({ status: "", data: res.data });
      })
      .catch((err) => {
        message.error("Dogodila se greška!", 3);
        set_start_order({ status: "error", data: {} });
      });
  };

  const finsihOrder = () => {
    set_finish_order({ status: "loading", data: "" });
    axios
      .post(
        `${getApiEndpoint()}maintenances/open_work_orders/${orderToken}/finish_work/`,
        { schedule_id: order.data.schedule ? order.data.schedule.id : null }
      )
      .then((res) => {
        set_finish_order({ status: "", data: {} });
        set_order({ status: "", data: res.data });
      })
      .catch((err) => {
        message.error("Dogodila se greška!", 3);
        set_finish_order({ status: "error", data: {} });
      });
  };

  const history = useHistory();

  const [editTime, set_editTime] = useState();

  useEffect(() => {
    getOrder();
  }, []);

  if (order.status === "error") {
    return (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1" }}
        message="Dogodila se greša"
        description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
        type="error"
      />
    );
  }
  if (order.status === "loading") {
    return <Loading />;
  }

  const machines =
    order.data.items.length > 1
      ? order.data.items.map((item) => `${item.name} ${item.info.model} `)
      : order.data.items[0].name;

  const categories =
    order.data.categories.length > 0
      ? order.data.categories.map((item) => `${item.name} `)
      : "---";

  const created_at_formatted =
    moment(order.data.created_at).locale("hr").format("LLL") + "h";
  const planned_start_formatted =
    moment(order.data.planned_start).locale("hr").format("LLL") + "h";
  const estimated_time_formatted =
    order.data.estimated_time == null &&
    order.data.estimated_time_minute == null
      ? "---"
      : (order.data.estimated_time || 0) +
        (order.data.estimated_time === 1 ? " sat " : " sata ") +
        (order.data.estimated_time_minute || 0) +
        " minuta";

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      {" "}
      <div style={{ maxWidth: 420, width: "100%" }}>
        <h3 style={{ textAlign: "center" }}>{order.data.name}</h3>

        {Information("Imovina", machines)}
        {Information("Kategorije", categories)}
        {Information("Tip", order.data.type.name)}
        {Information("Lokacija", order.data.items[0].info.location?.name)}
        {Information("ID", order.data.id)}
        {Information("Prijavio", order.data.creator.full_name)}
        {Information("Datum stvaranja", created_at_formatted)}
        {Information("Planirani start", planned_start_formatted)}
        {Information("Potrebno vrijeme", estimated_time_formatted, () => (
          <Button
            style={{ padding: "0 6px", marginRight: 18 }}
            onClick={() => set_editTime(order.data)}
          >
            <img style={{ width: 18, height: 18 }} src={EDIT_ICON} />
          </Button>
        ))}
        {Information(
          "Imovina aktivna",
          order.data.machine_run ? "Aktiva" : "Neaktivna"
        )}
        {Information("Opis", order.data.description)}
        <Button
          style={{
            float: "right",
            overflow: "hidden",
            backgroundColor: "rgb(70, 117, 255)",
          }}
          type={"primary"}
          onClick={() => {
            history.push(`/openorder/${orderToken}/details`, {
              order: order.data,
            });
          }}
        >
          Detaljno
        </Button>
        <div style={{ height: 42 }} />
        <div>
          <h3>Korisnici na nalogu</h3>
          {order.data.technicians.map((x) => (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "row",
              }}
              key={x.account.id}
            >
              <img
                style={ICON_SIZE}
                src={x.is_working ? USER_WORKING : USER_NOT_WORKING}
              />
              <span style={{ marginLeft: 12 }}>{x.account.full_name}</span>
            </div>
          ))}
        </div>

        {order.data.status === STATUS_KEYS.IN_PROGRESS && (
          <div
            style={{
              marginTop: 33,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div>
              <span>Nalog u tijeku!</span>
              <img style={{ width: 27, height: 27 }} src={PLAY} />
            </div>

            <div>
              Trajanje:{" "}
              <span style={{ fontWeight: "bold" }}>
                {crohHumanizer(
                  moment(new Date()).diff(moment(order.data.real_start))
                )}
              </span>
            </div>
          </div>
        )}

        {!(
          order.data.status === STATUS_KEYS.UNCLOSED ||
          order.data.status === STATUS_KEYS.CLOSED ||
          order.data.status === STATUS_KEYS.FINISHED
        ) ? (
          <div
            style={{
              marginTop: 33,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            {order.data.status === STATUS_KEYS.IN_PROGRESS ? (
              <Button
                loading={finish_order.status === "loading"}
                onClick={() => {
                  finsihOrder();
                }}
              >
                Završi
              </Button>
            ) : (
              <Button
                loading={start_order.status === "loading"}
                onClick={() => {
                  startOrder();
                }}
              >
                Kreni
              </Button>
            )}
          </div>
        ) : (
          <h2 style={{ padding: "40px 0", textAlign: "center" }}>Završeno</h2>
        )}
      </div>
      <Modal
        visible={!!editTime}
        closable={true}
        footer={null}
        onCancel={() => {
          set_editTime(false);
        }}
        destroyOnClose={true}
      >
        <EditTime
          set_editTime={set_editTime}
          setOrder={set_order}
          orderToken={orderToken}
          order={editTime}
        />
      </Modal>
    </div>
  );
};

const Information = (label = "---", value = "---", button) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <p style={{ fontWeight: 300 }}>{label}: </p>
      <p style={{ fontWeight: 600 }}>
        {button && button()}
        {value}
      </p>
    </div>
  );
};

export default OpenOrder;
