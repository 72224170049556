import { Button, message, Spin, Upload } from "antd";
import Axios from "axios";
import React, { useState } from "react";
import { ALLOWED_EXTENSIONS } from "../../../../helpers/consts";
import { getApiEndpoint } from "../../../../axios/endpoints";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GET_PART_SUCCESS } from "../../../../redux/modules/Parts/actions";

const NewDocuments = ({ close }) => {
  const [files, set_files] = useState([]);

  const { id } = useParams();

  const dispatch = useDispatch();

  const [loading, set_loading] = useState(false);

  const beforeUpload = (file, _files) => {
    set_files([...files, ..._files]);
    return false;
  };

  const saveDocuments = async () => {
    console.log(files);
    set_loading(true);

    const formData = new FormData();
    const token = await localStorage.getItem("token");

    files.forEach((file) => {
      formData.append("files", file);
    });

    Axios.post(
      `${getApiEndpoint()}api/parts/parts/${id}/upload_materials/`,
      formData,
      { headers: { Authorization: "Token " + token } }
    )
      .then((res) => {
        dispatch({ type: GET_PART_SUCCESS, data: res.data });
        set_loading(false);
        close();
      })
      .catch((err) => {
        set_loading(false);
        message.error("Dogodila se greška kod spremanja...");
      });
  };

  const onRemove = (file) => {
    let index = files.findIndex((x) => x === file);
    let arr = [...files];
    arr.splice(index, 1);
    set_files(arr);
  };

  return (
    <div>
      <Spin spinning={false}>
        <Upload
          onRemove={onRemove}
          showUploadList={true}
          multiple={true}
          beforeUpload={beforeUpload}
          accept={`${ALLOWED_EXTENSIONS[0].extensions},${ALLOWED_EXTENSIONS[1].extensions},${ALLOWED_EXTENSIONS[2].extensions}`}
        >
          <Button type="dashed">Učitaj</Button>
        </Upload>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
          }}
        >
          <Button
            onClick={saveDocuments}
            disabled={!files.length}
            style={{ marginRight: 12 }}
            type="primary"
            loading={loading}
          >
            Spremi
          </Button>
        </div>
      </Spin>
    </div>
  );
};

export default NewDocuments;
