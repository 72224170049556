import { Button, PageHeader, Icon, Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_MACHINE
} from "../../redux/modules/Machines/actions";

import Table from "./components/Table";
import Tree from "./components/Tree";

import Loading from "../../components/LoadingScreen";
import MachinesForm from "./components/MachinesForm";

import SizeType from "../../helpers/deviceTypeSize";
import TREE from '../../assets/icons/tree.png'
import TABLE from '../../assets/icons/table.jpg'

const Assets = ({}) => {
  const dispatch = useDispatch();

  const [machineFormVisible, setMachineFormVisible] = useState(false);
  const { machines, prepareRegistration } = useSelector(
    (state) => state.machinesReducer
  );

  const { machine } = useSelector((state) => state.machinesReducer);

  const [tab, set_tab] = useState("tree");

  if (machines.status === "loading") {
    return <Loading />;
  }

  const render =
    tab === "table" ? (
      <Table
        button={() => (
          <Button
            type="primary"
            onClick={() => setMachineFormVisible((prevState) => !prevState)}
          >
            {!machineFormVisible ? (
              <>
                Dodaj <Icon type="plus" />
              </>
            ) : (
              "Zatvori"
            )}
          </Button>
        )}
      />
    ) : (
      <Tree toggleForm={() => setMachineFormVisible((prevState) => !prevState)}  />
    );

  return (
    <div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
         
          
          <Button
            disabled={tab === "tree"}
            onClick={() => set_tab("tree")}
            children={
              <img
                style={{ width: "21px", height: "21px" }}
                src={TREE}
              />
            }
          />
          <div style={{ width: 12 }}></div>
           <Button
            disabled={tab === "table"}
            onClick={() => set_tab("table")}
            children={
              <img
                style={{ width: "21px", height: "21px" }}
                src={TABLE}
              />
            }
          />
        </div>
      </div>
      <PageHeader title="Imovina" />
      {render}

      <Drawer
        width={SizeType() === "xs" || SizeType() === "sm" ? "90%" : 640}
        visible={machineFormVisible}
        onClose={() => {
          setMachineFormVisible((prevState) => !prevState);
        }}
        destroyOnClose={true}
        title={
          <span>
            <Icon type="snippets" theme="twoTone" /> Dodaj imovinu
          </span>
        }
      >
        <MachinesForm
          close={() => setMachineFormVisible(false)}
          machines={machines.data}
          prepareRegistration={prepareRegistration.data}
          machine={machine}
          saveMachine={(data, callback) =>
            dispatch({ type: ADD_MACHINE, data, callback })
          }
       
          type={"company"}
        />
      </Drawer>
    </div>
  );
};

export default Assets;
