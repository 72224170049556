import React, { Component } from "react";

import { Row, Col, Tabs } from "antd";
import { connect } from "react-redux";
import List from "./TypesList";
import Form from "./TypesForm";

import * as actions from "../../../../redux/modules/Machines/actions";
import { pipeArrOfTypes } from "../../../../helpers/machines";

const { TabPane } = Tabs;

class Categories extends Component {
  state = {};

 

  render() {
    const { prepareRegistration } = this.props;
     
    return (
      <>
        <Row>
          <Col md={12} sm={24}>
            <Form
              types={prepareRegistration.data.types}
              type={this.props.type}
              saveType={this.props.onSaveType}
            />
          </Col>
          <Col md={12} sm={24}>
            <List
              list={prepareRegistration.data.types}
            />
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    type: state.machinesReducer.type,
    prepareRegistration: state.machinesReducer.prepareRegistration,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveType: (data, callback) =>
      dispatch({ type: actions.SAVE_TYPES, data, callback }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
