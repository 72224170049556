import React from "react";
import moment from "moment";
import "moment/locale/hr";
import css from "../../../../Accounting.module.scss";
import { Checkbox } from "antd";

export default function About({ user }) {
  const date_joined_formatted =
    moment(user.date_joined).locale("hr").format("LLL") + "h";
  const last_login_formatted =
    moment(user.last_login).locale("hr").format("LLL") + "h";
  const facility_subcategories = [
    ...user.facility_subcategories.map((item) => item.name),
  ].join(", ");

 
  return (
    <>
      {/* Spacing */}
      <div style={{ height: 16 }} />

      {/* Informations */}

      {/* ERP ID */}
      {infoItem("ERP ID", user.user.erp_id)}


      {infoItem("Prima izvještaj",  user.should_get_maintenance_email_report ? "Da" : "Ne")}

      {/* Kategorija */}
      {infoItem(
        "Kategorija",
        user.user.categories
          ? user.user.categories.map((x) => x.name).join(", ")
          : undefined
      )}

      {/* Adresa */}
      {infoItem("Adresa", user.address)}

      {/* E-mail */}
      {infoItem("E-mail", user.email)}

      {/* Kodeks ID*/}
      {infoItem("PIS ID", user.user.codeks_id)}

      {/* Telefonski broj */}
      {infoItem("Telefonski broj", user.phone_number)}

      {/* Datum kreiranja */}
      {infoItem("Datum kreiranja", date_joined_formatted)}

      {/* Datum zadnje prijave */}
      {infoItem("Datum zadnje prijave", last_login_formatted)}

      {/* Uloga */}
      {infoItem("Uloga", user.category)}

      {/* Broj uređaja */}
      {infoItem("Broj uređaja", user.device_id)}

      {/* Napomena */}
      {infoItem("Napomena", user.remark)}

      {/* Napomena */}
      {infoItem("Lokacija", user.location?.name)}

      {/* Prijava */}
      {infoItem("Prijava", user.can_report ? "Da" : "Ne")}

      {/* Potvrda */}
      {infoItem("Potvrda", user.should_confirm ? "Da" : "Ne")}
      {/* Prima obavijest */}
      {infoItem("Prima obavijest", user.should_receive_notifications ? "Da" : "Ne")}
      {/* Osiguranje */}
      {infoItem("Osiguranje", user.insurance_mail ? "Da" : "Ne")}
    </>
  );
}

const infoItem = (label, value) => {
  return (
    <div className={css.infoItem}>
      <h3 className={css.infoItemLabel}>{label}: </h3>
      <h3 className={css.infoItemValue}>{value || "---"}</h3>
    </div>
  );
};
