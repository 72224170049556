import React from 'react';

// UI
import { Spin } from "antd";
import css from '../../scheduler.module.scss'

export default function Loading(){
    return(
        <div className={css.loadingContainer}>
            <Spin />
        </div>
    )
}