import React from "react";
import { Link } from "react-router-dom";

// Moment
import moment from "moment";
import "moment/locale/hr";

// UI
import { Button, Popover } from "antd";
import { SettingOutlined, SettingTwoTone } from "@ant-design/icons";
import css from "../../scheduler.module.scss";

export default function RenderEventContent({ payload }) {
  const info = payload.event._def;

  // Formatting time
  const estimated_time =
    info.extendedProps.estimated_time == null ? 
      "-" : 
      `${  info.extendedProps.estimated_time}h i ${info.extendedProps.estimated_time_minute || 0}m`;

  const content = (
    <div className={css.eventContainer}>
      {/* Title */}
      <div className={css.titleContainer}>
        <SettingOutlined className={css.titleIcon} />
        <p className={css.title}>{info.title}</p>
      </div>
      {/* Divider */}
      <div className={css.divider} />
      {/* Group */}
      <div className={css.group}>
        <p className={css.groupLabel}>Planirano trajanje</p>
        <p className={css.groupValue}>{estimated_time}</p>
      </div>
      {/* Group */}
      <div className={css.group}>
        <p className={css.groupLabel}>Početak</p>
        <p className={css.groupValue}>
          {moment(info.extendedProps.start).locale("hr").format("LLL")}h
        </p>
      </div>
      {/* Group */}
      <div className={css.group}>
        <p className={css.groupLabel}>Status</p>
        <p className={css.groupValue}>
          <SettingTwoTone
            style={{ marginRight: 6 }}
            twoToneColor={
              info.extendedProps.status === "Aktivan" ? "#00d60e" : "#ff1845"
            }
          />
          {info.extendedProps.status}
        </p>
      </div>
      {/* Divider */}
      <div className={css.divider} />
      <div className={css.eventContainerButtons}>
        <Button className={css.eventButton} type="primary">
          <Link
            target="_blank"
            to={`/maintenances/${info.extendedProps.maintenance_id}/about`}
          >
            Održavanje
          </Link>
        </Button>
        <Button className={css.eventButton} type="primary">
          <Link
            target="_blank"
            to={`/machines/${info.extendedProps.asset_id}/specifications`}
          >
            Imovina
          </Link>
        </Button>
      </div>
    </div>
  );

  return (
    <Popover placement="left" content={content}>
      <div
        className={css.eventDisplay}
        style={{ backgroundColor: info.extendedProps.color }}
      >
        <p className={css.eventTime}>{payload.timeText}</p>
        <p className={css.eventTitle}>{info.title}</p>
      </div>
    </Popover>
  );
}
