import React from "react";
import { Layout } from "antd";
import useWindowDimensions from '../../helpers/useWindowDimension'

const SideBar = ({ menu , collapsed }) => {

  const { width } = useWindowDimensions()

  if(width <= 1024){
    return null;
  }

  return (
    <Layout.Sider
      className="sidebar"
      breakpoint={"lg"}
      theme="light"
      collapsible
      collapsed={collapsed}
      collapsedWidth={80}
      trigger={null}
      width={212}
      style={{ borderTop: "1px solid #4675ff", marginTop: -1, paddingTop: 20 }}
    >
        {menu}
    </Layout.Sider>
  );
};

export default SideBar;