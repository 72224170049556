import { takeLatest, put, call, select } from "redux-saga/effects";

import axios from "axios";

import {getApiEndpoint} from "../../../axios/endpoints";

const base_url = getApiEndpoint();

export const erp_actions = {
  ERP_USERS: "ERP_USERS",
  ERP_USERS_SUCCESS: "ERP_USERS_SUCCESS",
  ERP_USERS_FAIL: "ERP_USERS_FAIL",
  ERP_MACHINES: "ERP_MACHINES",
  ERP_MACHINES_SUCCESS: "ERP_MACHINES_SUCCESS",
  ERP_MACHINES_FAIL: "ERP_MACHINES_FAIL",
};

const initialState = {
  users: {
    status: "",
    data: [],
  },
  machines: {
    status: "",
    data: [],
  },
};

export default function reducer(state = initialState, action = {}) {
  var tmp;
  switch (action.type) {
    case erp_actions.ERP_MACHINES:
      return {
        ...state,
        machines: {
          status: "loading",
          data: [],
        },
      };

    case erp_actions.ERP_MACHINES_SUCCESS:
      return {
        ...state,
        machines: {
          status: "",
          data: action.data,
        },
      };

    case erp_actions.ERP_MACHINES_FAIL:
      return {
        ...state,
        machines: {
          status: "error",
          data: [],
        },
      };

    case erp_actions.ERP_USERS:
      return {
        ...state,
        users: {
          status: "loading",
          data: [],
        },
      };

    case erp_actions.ERP_USERS_SUCCESS:
      return {
        ...state,
        users: {
          status: "",
          data: action.data,
        },
      };

    case erp_actions.ERP_USERS_FAIL:
      return {
        ...state,
        users: {
          status: "error",
          data: [],
        },
      };
    default:
      return { ...state };
  }
}

function transformData(data) {
  return data.data;
}
const authToken = () => localStorage.getItem("token");
// erp users

function _get_erp_users() {
  return axios({
    method: "get",
    url: base_url + "erp/get_erp_users/",
    headers: {
      Authorization: "Token " + authToken(),
    },
    timeout: 9000,
  });
}

export function* watcherGetERP_users() {
  yield takeLatest(erp_actions.ERP_USERS, get_erp_users);
}

function* get_erp_users() {
  try {
    const response = yield call(_get_erp_users);

    const data = transformData(response);

    yield put({ type: erp_actions.ERP_USERS_SUCCESS, data });
  } catch (error) {
    console.log(error);
    // dispatch a failure action to the store with the error
    yield put({ type: erp_actions.ERP_USERS_FAIL, error });
  }
}

//erp users end

// erp machines

function _get_erp_machines(options) {
  return axios({
    method: "get",
    url: base_url + "erp/get_erp_items/",
    headers: {
      Authorization: "Token " + authToken(),
    },
    timeout: 9000,
  });
}

export function* watcherGetERP_machines() {
  yield takeLatest(erp_actions.ERP_MACHINES, get_erp_machines);
}

function* get_erp_machines() {
  try {
    const response = yield call(_get_erp_machines);

    const data = transformData(response);

    yield put({ type: erp_actions.ERP_MACHINES_SUCCESS, data });
  } catch (error) {
    console.log(error);
    // dispatch a failure action to the store with the error
    yield put({ type: erp_actions.ERP_MACHINES_FAIL, error });
  }
}

//erp machines end
