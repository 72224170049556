import React, { useState } from "react";
import css from "../../../workeffectiveness.module.scss";
import { Popover } from "antd";
import Content from "../../Popover";
import ICON from "../../../../../../assets/icons/information.png";

export default function Information({
  label = "",
  information = 0,
  hours = false,
  text = "",
  width = undefined,
  totalWidth = "auto",
  marginTop = 0,
  icon = ICON
}) {
  // Variables
  const [popoverVisible, setPopoverVisible] = useState(false);

  // Methods
  function togglePopover() {
    setPopoverVisible((previousState) => !previousState);
  }

  return (
    <Popover
      trigger="click"
      visible={popoverVisible}
      onVisibleChange={togglePopover}
      content={<Content title={label} text={text} />}
    >
      <div id={css.informationCard} style={{ maxWidth: width, width: totalWidth, marginTop: marginTop }}>
        {/* Icon */}
        <div className={css.iconBackground}>
          <img src={icon} className={css.icon} />
        </div>

        {/* Informations */}
        <div className={css.infoContainer}>
          <p className={css.value}>
            {information}
            {hours && "h"}
          </p>
          <p className={css.label}>{label}</p>
        </div>

        {/* Indicator */}
        <div className={css.togglePopover}>
          <div className={css.togglePopoverCircle} />
          <div className={css.togglePopoverCircle} />
          <div className={css.togglePopoverCircle} />
        </div>
      </div>
    </Popover>
  );
}
