const colors = [
  "#003f5c",
  "#444e86",
  "#955196",
  "#dd5182",
  "#ff6e54",
  "#ffa600",
  "#ff7c43",
  "#ffa600",
];

const hoverColors = [
  "#003f5c90",
  "#444e8690",
  "#95519690",
  "#dd518290",
  "#ff6e5490",
  "#ffa60090",
];

// Maintances

const maintenancesData = (payload, toConfirm) => {
  return {
    maintainAspectRatio: true,
    responsive: true,
    labels: [
      "    Prijavljena",
      "    Nedodjeljena",
      "    Dodjeljena",
      "    U tijeku",
      "    Na čekanju",
      "    Nepotvrđena",
    ],
    datasets: [
      {
        data: [
          payload.filter((x) => x.status === "reported").length,
          payload.filter((x) => x.status === "in_bowl").length,
          payload.filter((x) => x.status === "appointed").length,
          payload.filter((x) => x.status === "in_progress").length,
          payload.filter((x) => x.status === "waiting").length,
          payload.filter((x) => x.status === "finished").length,
        ],
        backgroundColor: colors,
        hoverBackgroundColor: hoverColors,
      },
    ],
  };
};

// Machines

const machinesDataByOrders = (payload) => {
  console.log(payload, "LLLLLL");

  let typesOfOrders = [];

  payload.forEach((element) => {
    if (!typesOfOrders.map((x) => x.id).includes(element.type.id)) {
      typesOfOrders.push(element.type);
    }
  });

  let datasets = [];

  typesOfOrders.forEach((element, index) => {
    datasets.push({
      name: element.name,
      key: index,
      label: element.name,
      data: [payload.filter((item) => item.type.name === element.name).length],
      backgroundColor: element.color,
      borderWidth: 1,
    });
  });

  return {
    labels: ["Po održavanjima"],
    datasets,
  };
};

const machinesDataByStatus = (payload) => {
  // Data structure
  const datasets = [
    {
      name: "Aktivni",
      key: "active",
      label: "Aktivni  ",
      data: [payload.filter((x) => x.machine_run).length],
      backgroundColor: colors[0],
      // hoverBackgroundColor: hoverColors[0],
      borderWidth: 1,
    },
    {
      name: "Neaktivni",
      key: "inactive",
      label: "Neaktivni",
      data: [payload.filter((x) => !x.machine_run).length],
      backgroundColor: colors[1],
      // hoverBackgroundColor: hoverColors[1],
      borderWidth: 1,
    },
  ];

  return {
    labels: ["Po statusu"],
    datasets,
    datalabels: {
      display: false,
    },
  };
};

// Technicians

const technicianDataByStatus = (payload) => {
  // Data structure
  const datasets = [
    {
      name: "Prijavljen",
      key: "logged_in",
      label: "Prijavljen  ",
      data: [payload.filter((x) => x.logged).length],
      backgroundColor: colors[0],
      // hoverBackgroundColor: hoverColors[0],
      borderWidth: 1,
    },
    {
      name: "Odjavljen",
      key: "logged_out",
      label: "Odjavljen",
      data: [payload.filter((x) => !x.logged).length],
      backgroundColor: colors[1],
      // hoverBackgroundColor: hoverColors[1],
      borderWidth: 1,
    },
  ];

  return {
    labels: [" "],
    datasets,
    datalabels: {
      display: false,
    },
  };
};

const technicianDataByAvailability = (payload) => {
  // Data structure
  const datasets = [
    {
      name: "Zauzeti",
      key: "busy",
      label: "Zauzeti  ",
      data: [payload.filter((x) => x.status === "busy").length],
      backgroundColor: colors[2],
      // hoverBackgroundColor: hoverColors[2],
      borderWidth: 1,
    },
    {
      name: "Dostupni",
      key: "free",
      label: "Dostupni   ",
      data: [payload.filter((x) => x.status !== "busy").length],
      backgroundColor: colors[4],
      // hoverBackgroundColor: hoverColors[4],
      borderWidth: 1,
    },
  ];

  return {
    labels: [" "],
    datasets,
    datalabels: {
      display: false,
    },
  };
};

export {
  // Maintenances
  maintenancesData,
  // Machines
  machinesDataByOrders,
  machinesDataByStatus,
  // Technicians
  technicianDataByAvailability,
  technicianDataByStatus,
};
