import React, { useState } from "react";
import css from "../../../warehouse.module.scss";
import { Popover } from "antd";
import Content from "../../Popover";
import ICON from '../../../../../../../assets/icons/information.png'

export default function Information({
  label = "",
  information = 0,
  value = false,
  onClick,
  activeCard,
}) {
  // Variables
  const [popoverVisible, setPopoverVisible] = useState(false);

  // Methods
  function togglePopover() {
    setPopoverVisible((previousState) => !previousState);
  }

  return (
    // <Popover
    //   visible={popoverVisible}
    //   content={
    //     <Content
    //       close={setPopoverVisible}
    //       title={label}
    //       text="Neke informacije o kartici"
    //     />
    //   }
    // >
      <div
        style={{ borderLeft: activeCard && `1px solid #f3a300`, minWidth: 400 }}
        id={css.informationCard}
        onClick={onClick}
      >
        {/* Icon */}
        <div className={css.iconBackground}>
          <img
            src={ICON}
            className={css.icon}
          />
        </div>

        {/* Informations */}
        <div className={css.infoContainer}>
          <p className={css.value}>
            {information}
            {value && "€"}
          </p>
          <p className={css.label}>{label}</p>
        </div>

        {/* Indicator */}
        {/* <div
          onClick={() => {
            setPopoverVisible(!popoverVisible);
          }}
          className={css.togglePopover}
        >
          <div className={css.togglePopoverCircle} />
          <div className={css.togglePopoverCircle} />
          <div className={css.togglePopoverCircle} />
        </div> */}
      </div>
    // </Popover>
  );
}
