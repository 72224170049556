import SizeType from '../../../../helpers/deviceTypeSize';

import React, { Component } from 'react';

import Desktop from './Device/Desktop';
import Mobile from './Device/Mobile';

export default class componentName extends Component {
  render() {
    return (
      <>
        { SizeType() === 'xs' || SizeType() === 'sm' || SizeType() ===  'md' ? <Mobile /> : <Desktop /> }
      </>
    );
  }
}
