import React, { useState, useEffect } from "react";

// UI
import { Drawer, TreeSelect, Select, message } from "antd";
import css from "./scheduler.module.scss";

// Redux
import { CALENDAR_MAINTENANCES } from "../../../../redux/modules/Maintenances/actions";
import { useSelector, useDispatch } from "react-redux";

// Calendar
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import hrLocale from "@fullcalendar/core/locales/hr";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import nestedSorting from "../../../../helpers/NestedSorting";

// Config
import { headerToolbar } from "./config";

// Components
import Error from "./components/Error";
import Loading from "./components/Loading";
import RenderEventContent from "./components/RenderEventContent";
import DrawerComponent from "./components/DrawerComponent";
import childsOfParent from "../../../../helpers/cildsOfParent";

const { Option } = Select;

const { SHOW_PARENT, SHOW_ALL } = TreeSelect;
// Constants
const CALENDAR_STATUSES = ["in_bowl", "appointed", "reported", "scheduled"];

export default function Calendar() {
  // Variables
  const dispatch = useDispatch();

  const { data, status } = useSelector(
    (state) => state.maintenancesReducer.calendarMaintenances
  );

  const [typesAndUsers, set_typesAndUsers] = useState({ users: [], types: [] });

  const {
    machines,
    prepareRegistration: {
      data: { locations },
    },
  } = useSelector((state) => state.machinesReducer);

  const [
    addMaintenanceDrawerVisible,
    setAddMaintenanceDrawerVisible,
  ] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [filters, set_filters] = useState({
    asset: [],
    type: null,
    location: [],
    users: [],
  });

  // Methods
  useEffect(() => {
    dispatch({
      type: CALENDAR_MAINTENANCES,
      queryParams: {
        statuses: CALENDAR_STATUSES.join("|"),
      },
    });
  }, []);

  useEffect(() => {
    set_typesAndUsers(pipeAllUserAndType(data));
  }, [data]);

  function toggleAddMaintenanceDrawer(payload) {
    let clickedDate = moment(payload.startStr).format();
    let yesterday = new Date(new Date().setDate(new Date().getDate() - 1));

    if (moment(clickedDate).isAfter(yesterday)) {
      if (payload) setSelectedDate(payload.startStr);
      setAddMaintenanceDrawerVisible((previousState) => !previousState);
    }
  }

  const setFilters = (value, key) => {
    const _filters = { ...filters };
    _filters[key] = value;
    console.log(value);
    set_filters(_filters);
  };

  // Renders
  const filtersList = (
    <div className={css.buttonsContainer}>
      <TreeSelect
        onChange={(e) => {
          setFilters(e, "asset");
        }}
        defaultValue={null}
        style={{ width: 180, overflow: "auto", height: 32 }}
        showSearch
        multiple={true}
        allowClear={true}
        placeholder="Odaberi imovinu"
        treeData={nestedSorting(machines.data, "parent")}
        optionFilterProp="name"
        filterTreeNode={(input, option) => {
          return (
            option.props.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
      />

      <Select
        onChange={(e) => {
          setFilters(e, "type");
        }}
        placeholder="Odaberi tip"
        defaultValue={[]}
        style={{ width: 180, overflow: "auto", height: 32 }}
        mode="single"
        allowClear={true}
        showSearch
        placeholder="Odaberi tip"
        optionFilterProp="name"
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {typesAndUsers.types.map((item, index) => (
          <Option key={index} value={item.id}>
            {item.name}
          </Option>
        ))}
      </Select>
      <TreeSelect
        defaultValue={[]}
        style={{ width: 180 }}
        placeholder="Odaberi lokaciju"
        onChange={(e) => {
          setFilters(e, "location");
        }}
        style={{ width: 180, overflow: "auto", height: 32 }}
        allowClear={true}
        treeData={nestedSorting(locations, "location_child")}
        showSearch={true}
        multiple={true}
        optionFilterProp="name"
        filterTreeNode={(input, option) => {
          return (
            option.props.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
      />

      <Select
        onChange={(e) => {
          setFilters(e, "users");
        }}
        placeholder="Odaberi tehnicare"
        defaultValue={[]}
        style={{ width: 180, overflow: "auto", height: 32 }}
        mode="multiple"
        allowClear={true}
        showSearch
        placeholder="Odaberi tehničare"
        optionFilterProp="name"
        filterOption={(input, option) =>
          option.props.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {typesAndUsers.users.map((item, index) => (
          <Option key={index} value={item.id}>
            {item.name}
          </Option>
        ))}
      </Select>
    </div>
  );

  // If data is loading
  if (status === "loading") {
    return <Loading />;
  }

  // If there was an error with data fetch
  if (status === "error") {
    return <Error />;
  }

  // Events data model
  const events = filterOrders(data, filters, locations, machines.data).map(
    (item) => {
      return {
        id: item.id,
        title: `${item.items[0].name}`,
        start: item.planned_start,
        extendedProps: {
          start: item.planned_start,
          estimated_time: item.estimated_time,
          maintenance_id: item.id,
          asset_id: item.items[0].id,
          color: item.type.color,
          status: item.items[0].status === "active" ? "Aktivan" : "Neaktivan",
        },
      };
    }
  );

  return (
    <div>
      {/* Filters */}
      {filtersList}

      {/* Calendar */}
      <FullCalendar
        locale={hrLocale}
        aspectRatio={2}
        dayMaxEvents={5}
        selectable={true}
        selectMirror={true}
        moreLinkClick="popover"
        plugins={[dayGridPlugin, interactionPlugin]}
        select={toggleAddMaintenanceDrawer}
        headerToolbar={headerToolbar}
        initialView="dayGridMonth"
        eventContent={(event) => <RenderEventContent payload={event} />}
        events={events}
      />

      {/* Drawer */}
      <Drawer
        title="Dodajte održavanje"
        placement="right"
        closable={false}
        onClose={() => setAddMaintenanceDrawerVisible(false)}
        destroyOnClose={true}
        visible={addMaintenanceDrawerVisible}
        getContainer={false}
        width={680}
        style={{ position: "absolute" }}
      >
        {/* Drawer component => New order */}
        <DrawerComponent
          planned_start={selectedDate}
          openCloseMaintenaceForm={toggleAddMaintenanceDrawer}
        />
      </Drawer>
    </div>
  );
}

const pipeAllUserAndType = (data) => {
  let users = [];
  let types = [];

  data.forEach((element) => {
    if (!types.map((x) => x.id).includes(element.type.id)) {
      types.push(element.type);
    }

    if (element.technicians && element.technicians.length) {
      element.technicians.forEach((i) => {
        if (!users.map((x) => x.id).includes(i.account.id)) {
          users.push({ id: i.account.id, name: i.account.full_name });
        }
      });
    }
  });

  return { types, users };
};

const filterOrders = (data, filters, locations, assets) => {
  let orders = data;

  console.log(filters);

  if (
    !filters.location.length &&
    !filters.type &&
    !filters.asset.length &&
    !filters.users.length
  ) {
    return orders;
  }

  if (filters.location.length) {
    orders = orders.filter((x) =>
      childsOfParent(
        filters.location,
        locations,
        [],
        "location_child"
      ).includes(x.items[0].info.location?.id)
    );
  }

  if (filters.asset.length) {
    orders = orders.filter((x) =>
      childsOfParent(filters.asset, assets, [], "parent").includes(
        x.items[0].id
      )
    );
  }

  if (filters.type) {
    orders = orders.filter((x) => x.type.id === filters.type);
  }

  if (filters.users.length) {
    orders = orders.filter((x) => {
      let { technicians } = x;

      let check = false;

      technicians.forEach((tech) => {
        if (filters.users.includes(tech.account.id)) {
          check = true;
        }
      });

      return check;
    });
  }

  return orders;
};
