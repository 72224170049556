import React, { useState, useEffect, useRef } from "react";
import css from "../../Maintenance/maintenance.module.scss";
import moment from "moment";
import "moment/locale/hr";
import { Button, Popconfirm, Spin, message } from "antd";
import { getApiEndpoint } from "../../../axios/endpoints";
import { DeleteTwoTone, FilePdfFilled } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  DELETE_REPORT,
  REMOVE_INSURANCE_REPORT_MATERIALS,
  UPDATE_REPORT,
} from "../../../redux/modules/Maintenances/actions";
import { useParams } from "react-router-dom";
import SizeType from "../../../helpers/deviceTypeSize";

export default function Report({ item, index, openOrder }) {
  // Variables
  const [editing, setEditing] = useState(false);
  const [newText, setNewText] = useState("");
  const textRef = useRef();



  console.log(openOrder);

  const user = useSelector((store) => {
    if (openOrder) {
      return "SUP";
    }
    return store.usersReducer.user.data.category;
  });
  const updateReport = useSelector(
    (state) => state.maintenanceReducer.updateReport
  );
  const deleteReport = useSelector(
    (state) => state.maintenanceReducer.deleteReport
  );
  const apiENdpoint = getApiEndpoint().slice(0, -1);
  const format = (date) => moment(date).locale("hr").format("LLL") + "h";
  const dispatch = useDispatch();
  const params = useParams();

  // Methods
  useEffect(() => {
    if (editing) {
      textRef.current.value = item.text || "";
      textRef.current.focus();
    }
  }, [editing]);

  function openInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  function onBlur() {
    setEditing(false);
  }

  function handleDelete() {
    dispatch({
      type: REMOVE_INSURANCE_REPORT_MATERIALS,
      data: { report_id: item.id },
      successCallback: () =>
        message.success(`Uspješno ste izbrisali izvještaj`),
      errorCallback: () =>
        message.error("Došlo je do pogreške prilikom brisanja"),
    });
  }

  function successCallback() {
    message.success(`Uspješno ste uredili izvještaj`);
    setEditing(false);
  }

  const text_input = editing ? (
    <Spin spinning={updateReport.status === "loading"}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <input
          ref={textRef}
          // onBlur={onBlur}
          className={css.textArea}
          type="textarea"
          onChange={(event) => setNewText(event.target.value)}
          value={newText}
          placeholder={item.text}
        />

        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            onClick={onBlur}
            type="default"
            style={{ alignSelf: "flex-start" }}
          >
            Odustani
          </Button>
        </div>
      </div>
    </Spin>
  ) : (
    <p className={css.reportText}>{item.text}</p>
  );

  return (
    <Spin spinning={deleteReport.status === item.id}>
      <div
        key={`${item.created_at}_${index}`}
        className={css.reportItemContainer}
      >
        <div className={css.reportsFlexRow}>
          {/* Report name */}
        {!openOrder &&  <h3 className={[css.reportUsername, { fontsize: 15 }]}>
            {item.creator?.full_name}
          </h3>}
          <div style={{ display: "flex", flexDirection: "row" }}>
            {/* Report date */}
            <h3 className={css.reportDate}>{format(item.created_at)}</h3>

            {(user === "MAN" || user === "LDR")? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: 20,
                }}
              >
                <Popconfirm
                  id="delete_report"
                  title="Jeste li sigurni da želite izbrisati?"
                  placement="left"
                  okText="Izbriši"
                  cancelText="Odustani"
                  onConfirm={handleDelete}
                >
                  <DeleteTwoTone style={{ fontSize: 20, cursor: "pointer" }} />
                </Popconfirm>
              </div>
            ) : null}
          </div>
        </div>
        {/* Report text */}
        {text_input}

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {item.materials.map((x, index) => (
            <div key={index}>
              {" "}
              {getFileBlock(x, apiENdpoint, index, openInNewTab)}
            </div>
          ))}
        </div>
      </div>
    </Spin>
  );
}

const getFileBlock = (item, apiENdpoint, index, openInNewTab) => {
  switch (item.type) {
    case "vid":
      return (
        <div
          style={{
            margin: SizeType() === "xs" || SizeType() === "sm" ? 0 : "6px",
            width: "300px",
          }}
          onClick={() => openInNewTab(apiENdpoint + item.path)}
        >
          <video width="300" height="270" controls>
            <source src={apiENdpoint + item.path} type="video/mp4"></source>
            <source src={apiENdpoint + item.path} type="video/ogg"></source>
            <source src={apiENdpoint + item.path} type="video/webm"></source>
          </video>
        </div>
      );

    case "img":
      return (
        <div
          width="300"
          style={{
            margin: SizeType() === "xs" || SizeType() === "sm" ? 0 : "6px",
            width: "300px",
            textAlign: "center",
          }}
          onClick={() => openInNewTab(apiENdpoint + item.path)}
        >
          <img
            style={{ width: "100%" }}
            height="270"
            src={apiENdpoint + item.path}
          />
        </div>
      );

    case "doc":
      return (
        <>
          <div style={{ marginLeft: 27 }}>
            <a
              onClick={() => {
                openInNewTab(apiENdpoint + item.path);
              }}
            >
              <FilePdfFilled style={{ fontSize: 33 }} />
              <div></div>
              <span>{item.name}</span>
            </a>
          </div>
        </>
      );
    default:
      return (
        <a
          onClick={() => {
            openInNewTab(apiENdpoint + item.path);
          }}
        >
          {item.path}
        </a>
      );
  }
};
