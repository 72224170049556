import React from "react";
import css from "../maintenances.module.scss";
import { Table, Row, Col } from "antd";

// Components
import Availability from "./Cards/Availability";
import Information from "./Cards/Information";
import Line from './Charts/Line'
import GroupedBar from './Charts/GroupedBar'

export default function Expenses(){

    // Methods
    let reducer = (accumulator, currentValue) => accumulator + currentValue;

    return(
        <div id={css.maintenancesContainer}>
            {/* //? Main row */}
            <Row gutter={[24, 48]}>
                {/* //! Cards and charts */}
                <Col lg={24} xl={16}>
                    {/* //* Cards */}
                    <Row gutter={[24, 24]}>
                        {/* //? First row */}
                        <Row gutter={[24, 24]}>
                            {/* Sukladnost održavanja prema rasporedu */}
                            <Col md={24} lg={12}>
                                <Availability 
                                    progress={88}
                                    label="Sukladnost održavanja prema rasporedu"
                                />
                            </Col>
                            {/* Broj sukladnih održavanja prema rasporedu */}
                            <Col md={24} lg={12}>
                                <Information 
                                    information={113}
                                    label="Broj sukladnih održavanja prema rasporedu"
                                    value
                                />
                            </Col>
                        </Row>
                        {/* //? Second row */}
                        <Row gutter={[24, 24]}>
                            {/* Sukladnost održavanja prema rasporedu */}
                            <Col md={24} lg={12}>
                                <Availability 
                                    progress={0}
                                    label="PMP"
                                />
                            </Col>
                            {/* Broj neusklađenih održavanja prema rasporedu */}
                            <Col md={24} lg={12}>
                                <Information 
                                    information={3}
                                    label="Broj neusklađenih održavanja prema rasporedu"
                                    value
                                />
                            </Col>
                        </Row>
                    </Row>

                    {/* //* Chart */}
                    <Row gutter={[24, 48]}>
                        {/* Trend sukladnosti održavanja prema rasporedu */}
                        <Col span={24}>
                            {/* Header */}
                            <div className={css.header} style={{ marginBottom: 24 }}>
                                <p className={css.value}>Trend sukladnosti održavanja prema rasporedu</p>
                            </div>
                            <div className={css.lineChart}>
                            <Line
                                labels={testLabels}
                                data={testData}
                                title="Trend sukladnosti održavanja prema rasporedu"
                                color="orange"
                            />
                            </div>
                        </Col>
                    </Row>

                    {/* //* Cards */}
                    <Row gutter={[24, 48]}>
                        {/* Sukladnost vremena održavanja prema rasporedu */}
                        <Col md={24} lg={8}>
                            <Availability 
                                progress={24}
                                label="Sukladnost vremena održavanja prema rasporedu"
                            />
                        </Col>
                        {/* Planirano vrijeme rada na održavanju */}
                        <Col md={24} lg={8}>
                            <Information 
                                information={300}
                                label="Planirano vrijeme rada na održavanju"
                            />
                        </Col>
                        {/* Stvarno vrijeme rada na održavanju */}
                        <Col md={24} lg={8}>
                            <Information 
                                information={310}
                                label="Stvarno vrijeme rada na održavanju"
                            />
                        </Col>
                    </Row>

                    {/* //* Chart */}
                    <Row gutter={[24, 48]}>
                        {/* Trend vremenske sukladnosti održavanja prema rasporedu */}
                        <Col span={24}>
                            {/* Header */}
                            <div className={css.header} style={{ marginBottom: 24 }}>
                                <p className={css.value}>Trend vremenske sukladnosti održavanja prema rasporedu</p>
                            </div>
                            <div className={css.lineChart}>
                                <Line
                                    labels={testLabels}
                                    data={_testData}
                                    title="Trend vremenske sukladnosti održavanja prema rasporedu"
                                    color="purple"
                                />
                            </div>
                        </Col>
                    </Row>

                    {/* //* Chart */}
                    <Row gutter={[24, 48]}>
                        {/* Usporedba sukladnosti i vremenske sukladnosti održavanja */}
                        <Col span={24}>
                            {/* Header */}
                            <div className={css.header} style={{ marginBottom: 24 }}>
                                <p className={css.value}>Usporedba sukladnosti i vremenske sukladnosti održavanja</p>
                            </div>
                            <div className={css.barChart} style={{ height: groupedBarData[0].length * 80 }}> {/*  80 => Bar height */}
                                <GroupedBar 
                                    data={groupedBarData}
                                    labels={groupedBarLabels}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>

                {/* //! Table */}
                <Col lg={24} xl={8}>
                    <Table
                        columns={COLUMNS}
                        dataSource={[]}
                    />
                </Col>
            </Row>
        </div>
    )
}

// Test data
const testData = [14, 12, 19, 3, 12, 19, 3, 5, 10, 2]
const _testData = [12, 19, 3, 5, 10, 2, 14, 12, 19, 3]
const groupedBarData = [
    [14, 12, 19, 3, 12, 19, 3, 5, 10, 2],
    [12, 19, 3, 5, 10, 2, 14, 12, 19, 3]
]


// Test labels
const groupedBarLabels = [
    "Imovina 1", 
    "Imovina 2", 
    "Imovina 3", 
    "Imovina 4", 
    "Imovina 5", 
    "Imovina 6", 
    "Imovina 7", 
    "Imovina 8", 
    "Imovina 9", 
    "Imovina 10",   
]
const testLabels = [
  "1.", 
  "2.", 
  "3.", 
  "4.", 
  "5.", 
  "6.", 
  "7.", 
  "8.", 
  "9.", 
  "10.", 
]


// Table
const COLUMNS = [
    {
      title: "Opis održavanja",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Sukladnost rasporedu (%)",
      dataIndex: "compliance",
      key: "compliance",
    },
    {
      title: "Vremenska sukladnost rasporedu (%)",
      dataIndex: "time_compliance",
      key: "time_compliance",
    },
  ];