export const live_status = (data) => {
  return [
    {
      status: "active",
      text: "aktivan",
      color: "#389e0d",
    },
    {
      status: "non-active",
      text: "neaktivan",
      color: "#cf1322",
    },
    {
      status: "broken",
      text: "prijavljen", // prijavljen kvar od strane operatera
      color: "#d46b08",
    },
    {
      status: "waiting-maintenance",
      text: "nalog poslan",
      color: "#d4b106",
    },
    {
      status: "maintenance",
      text: "u tijeku",
      color: "#08979c",
    },
    { status: "stand-by", text: "pripravan", color: "#ad6800" },
  ];
};
