import React, { useEffect, useState } from "react";

import {
  message,
  PageHeader,
  Row,
  Col,
  Button,
  Drawer,
  Icon,
  Dropdown,
  Menu,
  Spin,
  Modal,
  Select,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Maintenances/components/Scheduler/components/Loading";
import moment from "moment";
import "moment/locale/hr";
import {
  CHANGE_INSURANCE_ORDER_STATUS,
  INSURANCE,
} from "../../redux/modules/Maintenances/actions";
import { USER_SUCCESS } from "../../redux/modules/Users/actions";
import { useHistory, useRouteMatch, Link } from "react-router-dom";
import css from "../Parts/parts.module.scss";
import {
  INSURANCE_ORDERS_STATUS,
  INSURANCE_ORDERS_STATUS_KEYS,
} from "../../helpers/consts";
import Reports from "./components/materials";
import SizeType from "../../helpers/deviceTypeSize";
import MaterialForm from "./components/materialForm";
import {
  DownOutlined,
  LeftCircleOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import Popover from "../Machines/components/Popover";
let oldStatus = "";

const Profile = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const [state, set_state] = useState({
    materialForm: false,
    openOrder: !!match.params.order_token,
    modalVisible: false,
    insurer_id: -1,
    status: "",
  });

  let disabled = true;

  const {
    maintenancesReducer: { insurance, changeInsuranceOrderStatus },
    usersReducer: { user },
  } = useSelector((state) => state);

  const setInsurerId = (order) => {
    if (order && order.insurers.length === 1) {
      set_state({ ...state, insurer_id: order.insurers[0].id }); // ukoliko je samo jedan
    }
  };

  useEffect(() => {
    if (Object.keys(insurance.data).length) {
      setInsurerId(insurance.data);
    }
  }, [insurance]);

  useEffect(() => {
    dispatch({
      type: INSURANCE,
      id: match.params.id,
      order_token: match.params.order_token,
      errorCallback: () => {
        message.error("Ups dogodila se greška kod spremanja podataka", 3);
      },
    });
  }, []);

  if (insurance.status === "loading") {
    return <Loading />;
  }

  if (state.openOrder && !Object.keys(insurance.data).length) {
    return <h3>Nalog ne postoji!</h3>;
  }

  const setInsurer = (status) => {
    oldStatus = insurance.data.status;
    dispatch({
      type: CHANGE_INSURANCE_ORDER_STATUS,
      openOrder: state.openOrder,
      data: {
        insurer_id: state.insurer_id,
        status,
        id: insurance.data.id,
      },
      errorCallback: () => {
        message.error("Ups dogodila se greška kod dohvata podataka", 3);
      },
      successCallback: () => {
        set_state({ ...state, modalVisible: false });
        if (oldStatus === INSURANCE_ORDERS_STATUS_KEYS.REPORTED) {
          let tmp = { ...user.data };
          tmp.num_of_reported_insurance_orders =
            tmp.num_of_reported_insurance_orders - 1;

          dispatch({
            type: USER_SUCCESS,
            data: {
              token: user.token,
              account: tmp,
            },
          });
        }
      },
    });
  };

  const menu = (
    <Menu>
      {INSURANCE_ORDERS_STATUS.map((x, index) => {
        return (
          <Menu.Item
            style={{
              backgroundColor:
                x.value === insurance.data.status
                  ? "#e6f7ff"
                  : validatieStatus(insurance.data.status, x.value) &&
                    "#f5f5f5",
            }}
            key={index}
          >
            <span
              onClick={() => {
                if (validatieStatus(insurance.data.status, x.value)) {
                  return;
                }
                if (x.value !== INSURANCE_ORDERS_STATUS_KEYS.IN_PROGRESS) {
                  setInsurer(x.value);
                  return;
                }

                if (state.insurer_id !== -1) {
                  setInsurer(x.value);
                  return;
                }

                if (
                  insurance.data.status ===
                    INSURANCE_ORDERS_STATUS_KEYS.WAITING &&
                  x.value === INSURANCE_ORDERS_STATUS_KEYS.IN_PROGRESS
                ) {
                  setInsurer(x.value);
                  return;
                }

                set_state({ ...state, modalVisible: true, status: x.value });
              }}
            >
              {x.text}
            </span>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  disabled = true;

  return (
    <div>
      {" "}
      <PageHeader
        style={{ paddingTop: "0 !important" }}
        title={
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              // margin: "10px 0",
            }}
          >
            {!state.openOrder && (
              <div
                onClick={() => {
                  history.push("/insurance");
                }}
                className={[css.hoverDiv + " hover"]}
              >
                {/* Icon */}
                <LeftCircleOutlined className={css.backIcon} />
              </div>
            )}
            <h1 style={{ fontSize: 20, fontWeight: 600, margin: "0 9px" }}>
              Osiguranje
            </h1>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            ></div>
          </div>
        }
        extra={
          !state.openOrder ? (
            <>
              <Spin spinning={changeInsuranceOrderStatus.status === "loading"}>
                <Dropdown
                  disabled={
                    insurance.data.status ===
                      INSURANCE_ORDERS_STATUS_KEYS.SUCCESS ||
                    insurance.data.status ===
                      INSURANCE_ORDERS_STATUS_KEYS.FAILED ||
                    insurance.data.status ===
                      INSURANCE_ORDERS_STATUS_KEYS.DENIED
                  }
                  placement="topRight"
                  arrow
                  overlay={menu}
                >
                  <div>
                    <span style={{ fontWeight: "bold" }} className={"hover"}>
                      {INSURANCE_ORDERS_STATUS.find(
                        (x) => x.value === insurance.data.status
                      ).text.toUpperCase()}
                    </span>
                    {insurance.data.status !==
                      INSURANCE_ORDERS_STATUS_KEYS.SUCCESS &&
                      insurance.data.status !==
                        INSURANCE_ORDERS_STATUS_KEYS.FAILED &&
                      INSURANCE_ORDERS_STATUS_KEYS.DENIED !==
                        insurance.data.status && (
                        <DownOutlined style={{ marginLeft: 9 }} />
                      )}
                  </div>
                </Dropdown>
                <Popover
                  style={{ left: -30 }}
                  title={"Statusi"}
                  text={
                    <div>
                      Prijavljen – nalog osiguranja je prijavljen i postaje
                      aktualan <br /> Odbačen – nalog osiguranja je odbačen i
                      prestaje biti aktualan
                      <br /> Potvrđen – nalog osiguranja je potvrđen i svi
                      odabrani korisnici dobiju potvrdu o tome email-om <br />{" "}
                      Na čekanju - nalog osiguranja je pauziran jer osiguranje
                      vrši provjeru nad kvarom imovine <br />U tijeku – nalog
                      osiguranja je u tijeku i odabrani ili zadani osiguratelj
                      će potvrdu o tome primiti email-om (ukoliko je samo jedan
                      osiguratelj definiran za određenu imovinu, korak biranja
                      osiguratelja će izostati)
                      <br /> Neuspješno – nalog osiguranja je okončan neuspješno
                      <br /> Uspješno – nalog osiguranja je okončan uspješno
                    </div>
                  }
                />
              </Spin>
            </>
          ) : (
            <>
              <div
                style={{
                  marginLeft: "9px",
                  fontSize: "15px",
                  textAlign: "center",
                  padding: "6px",
                  fontWeight: "bold",
                  fontSize: "14px",
                  fontVariant: "tabular-nums",
                  lineHeight: "1.5",
                  listStyle: "none",
                }}
              >
                {
                  INSURANCE_ORDERS_STATUS.find(
                    (x) => x.value === insurance.data.status
                  ).text
                }
              </div>
              <Button
                loading={changeInsuranceOrderStatus.status === "loading"}
                type={"dashed"}
                onClick={() => setInsurer(INSURANCE_ORDERS_STATUS_KEYS.WAITING)}
                disabled={validatieStatus(
                  insurance.data.status,
                  INSURANCE_ORDERS_STATUS_KEYS.WAITING
                )}
              >
                Na čekanju
              </Button>
            </>
          )
        }
      />
      <div style={{ maxWidth: 600, marginTop: 21 }}>
        {information(
          "Naziv imovine",
          insurance.data.work_order.item.name,
          state.openOrder,
          `/machines/${insurance.data.work_order.item.id}/specifications`
        )}
        {information(
          "Nalog održavanja",
          insurance.data.work_order.name,
          state.openOrder,
          `/maintenances/${insurance.data.work_order.id}/about`
        )}
        {!state.openOrder &&
          information(
            "Prijavio",
            insurance.data.potentially_reported_by?.full_name
          )}
        {information(
          "Kreiran",
          moment(insurance.data.created_at).locale("hr").format("LLL") + "h"
        )}
        {information(
          "Status",
          INSURANCE_ORDERS_STATUS.find((x) => x.value === insurance.data.status)
            .text
        )}
        {!state.openOrder &&
          information("Potvrdio", insurance.data.confirmed_by?.full_name)}
        {information(
          "Link",
          "../" + insurance.data.insurance_url.split("/")[4],
          false,
          false,
          insurance.data.insurance_url
        )}
        {information("Poslao osiguranju", insurance.data.reporter?.full_name)}

        {information(
          "Polica osiguranja",
          insurance.data.insurance_policy,
          false,
          false,
          insurance.data.insurance_policy
        )}
      </div>
      {/* MATERIALS */}
      <hr />
      <div>
        {insurance.data.reports?.length
          ? insurance.data.reports.map((item, index) => {
              return (
                <div key={index}>
                  <Reports
                    subtitle=""
                    reports={insurance.data.reports}
                    openOrder={state.openOrder}
                    key={index}
                    item={item}
                    index={index}
                  />
                  <hr />
                </div>
              );
            })
          : null}
      </div>
      <div>
        {!state.openOrder && (
          <Button
            onClick={() => {
              set_state({ ...state, materialForm: true });
            }}
            type={"primary"}
          >
            Dodaj
          </Button>
        )}
      </div>
      <Drawer
        width={SizeType() === "xs" || SizeType() === "sm" ? "90%" : 640}
        visible={state.materialForm}
        onClose={() => {
          set_state({ ...state, materialForm: false });
        }}
        destroyOnClose={true}
        title={
          <span>
            <Icon type="snippets" theme="twoTone" /> Kreiranje materijala
          </span>
        }
      >
        <MaterialForm
          set_materialForm={(value) =>
            set_state({ ...state, materialForm: value })
          }
        />
      </Drawer>
      <Modal
        onCancel={() => {
          set_state({ ...state, modalVisible: false, insurer_id: -1 });
        }}
        visible={state.modalVisible}
        destroyOnClose={true}
        confirmLoading={changeInsuranceOrderStatus.status === "loading"}
        okText={"Spremi"}
        okButtonProps={{ disabled: state.insurer_id === -1 }}
        onOk={() => {
          setInsurer(state.status);
        }}
        title="Osiguranje"
      >
        <div>
          <Select
            notFoundContent={"Nema dodano osiguranje!"}
            placeholder="Odaberi osiguranje"
            style={{ width: "100%", maxWidth: 300 }}
            onChange={(value) => {
              set_state({ ...state, insurer_id: value });
            }}
          >
            {insurance.data.insurers.map((x, index) => (
              <Select.Option key={index} value={x.id}>
                {x.full_name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </Modal>
    </div>
  );
};

export default Profile;

const information = (label, value, openOrder, link, linkForCopy) => {
  if (openOrder) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "3px",
        }}
      >
        <h3 className={css.infoItemLabel}>{label}: </h3>
        <h3 className={css.infoItemValue}>{value || "---"}</h3>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "3px",
      }}
    >
      <h3 className={css.infoItemLabel}>{label}: </h3>
      {link ? (
        <Link
          to={link}
          className={css.infoItemValue}
          style={{ fontSize: 15 }}
          target={"blank"}
        >
          {value}
        </Link>
      ) : (
        <h3 className={css.infoItemValue} style={{ fontSize: 15 }}>
          {value || "---"}{" "}
          {(label === "Link" || label === "Polica osiguranja") && (
            <Button
              style={{ marginLeft: 21 }}
              onClick={() => {
                copyToClipboard(linkForCopy)
                  .then(() => message.success("Kopirano!"))
                  .catch(() => console.log("Neuspjelo kopiranje"));
              }}
            >
              <CopyOutlined />
            </Button>
          )}
        </h3>
      )}
    </div>
  );
};

const validatieStatus = (currentStatus, status) => {
  if (currentStatus === INSURANCE_ORDERS_STATUS_KEYS.WAITING) {
    if (status === INSURANCE_ORDERS_STATUS_KEYS.IN_PROGRESS) {
      return false;
    }
    return true;
  }
  if (currentStatus === INSURANCE_ORDERS_STATUS_KEYS.REPORTED) {
    if (
      status === INSURANCE_ORDERS_STATUS_KEYS.DENIED ||
      status === INSURANCE_ORDERS_STATUS_KEYS.CONFIRMED
    ) {
      return false;
    }
    return true;
  }
  if (currentStatus === INSURANCE_ORDERS_STATUS_KEYS.CONFIRMED) {
    if (
      status === INSURANCE_ORDERS_STATUS_KEYS.IN_PROGRESS ||
      status === INSURANCE_ORDERS_STATUS_KEYS.FAILED
    ) {
      return false;
    }
    return true;
  }

  if (currentStatus === INSURANCE_ORDERS_STATUS_KEYS.IN_PROGRESS) {
    if (
      status === INSURANCE_ORDERS_STATUS_KEYS.SUCCESS ||
      status === INSURANCE_ORDERS_STATUS_KEYS.FAILED ||
      status === INSURANCE_ORDERS_STATUS_KEYS.WAITING
    ) {
      return false;
    }
    return true;
  }
  return true;
};

function copyToClipboard(textToCopy) {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  } else {
    // text area method
    let textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand("copy") ? res() : rej();
      textArea.remove();
    });
  }
}
