export const SAVE_LOCATION = "SAVE_LOCATION";
export const SAVE_LOCATION_SUCCESS = "SAVE_LOCATION_SUCCESS";
export const SAVE_LOCATION_FAIL = "SAVE_LOCATION_FAIL";

export const DELETE_LOCATION = "DELETE_LOCATION";
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS";
export const DELETE_LOCATION_FAIL = "DELETE_LOCATION_FAIL";

export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";
export const UPDATE_LOCATION_FAIL = "UPDATE_LOCATION_FAIL";

export const PREPARE_MACHINE_REGISTRATION = "PREPARE_MACHINE_REGISTRATION";
export const PREPARE_MACHINE_REGISTRATION_SUCCESS =
  "PREPARE_MACHINE_REGISTRATION_SUCCESS";
export const PREPARE_MACHINE_REGISTRATION_FAIL =
  "PREPARE_MACHINE_REGISTRATION_FAIL";




export const UPDATE_MACHINE_CATEGORY = "UPDATE_MACHINE_CATEGORY";
export const UPDATE_MACHINE_CATEGORY_SUCCESS =
  "UPDATE_MACHINE_CATEGORY_SUCCESS";
export const UPDATE_MACHINE_CATEGORY_FAIL = "UPDATE_MACHINE_CATEGORY_FAIL";

export const ADD_MACHINE = "MACHINE";
export const ADD_MACHINE_SUCCESS = "MACHINE_SUCCESS";
export const ADD_MACHINE_FAIL = "MACHINE_FAIL";

export const DELETE_MACHINE = "DELETE_MACHINE";
export const DELETE_MACHINE_SUCCESS = "DELETE_MACHINE_SUCCESS";
export const DELETE_MACHINE_FAIL = "DELETE_MACHINE_FAIL";

export const GET_MACHINES = "MACHINES";
export const GET_MACHINES_SUCCESS = "GET_MACHINES_SUCCESS";
export const GET_MACHINES_FAIL = "GET_MACHINES_FAIL";

export const SAVE_TYPES = "SAVE_TYPES";
export const SAVE_TYPES_SUCCESS = "SAVE_TYPES_SUCCESS";
export const SAVE_TYPES_FAIL = "SAVE_TYPES_FAIL";

export const GET_MACHINE = "GET_MACHINE";
export const GET_MACHINE_SUCCESS = "GET_MACHINE_SUCCESS";
export const GET_MACHINE_FAIL = "GET_MACHINE_FAIL";

export const UPDATE_MACHINE = "UPDATE_MACHINE";
export const UPDATE_MACHINE_SUCCESS = "UPDATE_MACHINE_SUCCESS";
export const UPDATE_MACHINE_FAIL = "UPDATE_MACHINE_FAIL";

export const ADD_MATERIAL = "ADD_MATERIAL";
export const ADD_MATERIAL_SUCCESS = "ADD_MATERIAL_SUCCESS";
export const ADD_MATERIAL_FAIL = "ADD_MATERIAL_FAIL";

export const DELETE_MATERIAL = "DELETE_MATERIAL";
export const DELETE_MATERIAL_SUCCESS = "DELETE_MATERIAL_SUCCESS";
export const DELETE_MATERIAL_FAIL = "DELETE_MATERIAL_FAIL";
 

export const SAVE_MACHINE_CATEGORY = "SAVE_MACHINE_CATEGORY";
export const SAVE_MACHINE_CATEGORY_SUCCESS = "SAVE_MACHINE_CATEGORY_SUCCESS";
export const SAVE_MACHINE_CATEGORY_FAIL = "SAVE_MACHINE_CATEGORY_FAIL";

export const SET_SUBASSET = "SET_SUBASSET";
