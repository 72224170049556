import React from "react";

import _Tasks from "../../../Maintenance/components/Tasks";

const Tasks = ({ order }) => {
  if (!order.tasks) {
    order.tasks = [];
  }
  return <_Tasks order={{ data: order }} />;
};

export default Tasks;
