import React from "react";

import _Materials from "../../../Machine/components/Instructions";

const Materials = ({ order }) => {
  if (!order.materials) {
    order.materials = [];
  }
  let _order = order.data;

  return (
    <_Materials
      openOrder={true}
      documents={_order?.items[0]?.documents || []}
      images={_order?.items[0]?.images || []}
      videos={_order?.items[0]?.videos || []}
      relevant_links={_order?.items[0]?.relevant_links || []}
      deleteMaterial={() => {}}
    />
  );
};

export default Materials;
