import { Modal, Table, Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { JURE_FORMAT_DATE } from "../../../../../helpers/consts";
import Information from "./Cards/Information";

const WaitingsOrdersByPart = ({orders}) => {

    const [COLUMNS, set_COLUMNS] = useState([
        {
            dataIndex: "",
            title: "Dio",
            render: (text, value) => <span>{value.waiting_reasons[0].waiting_part}</span>
        },
        {
            dataIndex: "",
            title: "Vrijeme isporuke",
            render: (text, value) => <span>{value.waiting_reasons[0].waiting_part_time}</span>
        },
        {
            dataIndex: "",
            title: "Imovina",
            render: (text, value) => <span>{value.items[0].name}</span>
        },
        {
          dataIndex: "",
          title: "Pauzirao",
          render: (text, value) => <div>
            <Typography.Text>{value.waiting_reasons[0]?.created_by?.full_name}</Typography.Text><br />
            <Typography.Text type="secondary"  style={{fontSize: 12}}  >{moment(value.waiting_reasons[0]?.start_time).format(JURE_FORMAT_DATE)}</Typography.Text>
          </div>
        },
        {
            dataIndex: "id",
            title: "ID",
            render: (text, value, index) => (
                <Link
                  to={{
                    pathname: `/maintenances/${value?.id}/about`,
                  }}
                >
                  {value.id}
                </Link>
              ),
        }
    ])

    return <>
    <div>
    <Typography.Title level={4} >Nalozi na čekanju</Typography.Title>
              <Table columns={COLUMNS}  dataSource={orders} />
              </div>
    </>
}


export default WaitingsOrdersByPart;