export const ADD_PART_CATEGORY = "ADD_PART_CATEGORY";
export const ADD_PART_CATEGORY_SUCCESS = "ADD_PART_CATEGORY_SUCCESS";
export const ADD_PART_CATEGORY_FAIL = "ADD_PART_CATEGORY_FAIL";

export const PART = "PART";
export const PART_SUCCESS = "PART_SUCCESS";
export const PART_FAIL = "PART_FAIL";

export const GET_PARTS = "GET_PARTS";
export const GET_PARTS_SUCCESS = "GET_PARTS_SUCCESS";
export const GET_PARTS_FAIL = "GET_PARTS_FAIL";

export const ADD_PART = "ADD_PART";
export const ADD_PART_SUCCESS = "ADD_PART_SUCCESS";
export const ADD_PART_FAIL = "ADD_PART_FAIL";

export const GET_PARTS_CATEGORIES = "GET_PARTS_CATEGORIES";
export const GET_PARTS_CATEGORIES_SUCCESS = "GET_PARTS_CATEGORIES_SUCCESS";
export const GET_PARTS_CATEGORIES_FAIL = "GET_PARTS_CATEGORIES_FAIL";

export const UPDATE_PART_CATEGORY = "UPDATE_PART_CATEGORY";
export const UPDATE_PART_CATEGORY_SUCCESS = "UPDATE_PART_CATEGORY_SUCCESS";
export const UPDATE_PART_CATEGORY_FAIL = "UPDATE_PART_CATEGORY_FAIL";

export const REMOVE_PART_CATEGORY = "REMOVE_PART_CATEGORY";
export const REMOVE_PART_CATEGORY_SUCCESS = "REMOVE_PART_CATEGORY_SUCCESS";
export const REMOVE_PART_CATEGORY_FAIL = "REMOVE_PART_CATEGORY_FAIL";

export const UPDATE_PART = "UPDATE_PART";
export const UPDATE_PART_SUCCESS = "UPDATE_PART_SUCCESS";
export const UPDATE_PART_FAIL = "UPDATE_PART_FAIL";

export const GET_PART = "GET_PART";
export const GET_PART_SUCCESS = "GET_PART_SUCCESS";
export const GET_PART_FAIL = "GET_PART_FAIL";

export const PART_TO_ORDER = "PART_TO_ORDER";
export const PART_TO_ORDER_SUCCESS = "PART_TO_ORDER_SUCCESS";
export const PART_TO_ORDER_FAIL = "PART_TO_ORDER_FAIL";

export const GET_PARTS_BY_STATUS = "GET_PARTS_BY_STATUS";
export const GET_PARTS_BY_STATUS_SUCCESS = "GET_PARTS_BY_STATUS_SUCCESS";
export const GET_PARTS_BY_STATUS_FAIL = "GET_PARTS_BY_STATUS_FAIL";

export const CONFIRM_PART_REMOVAL = "CONFIRM_PART_REMOVAL";
export const CONFIRM_PART_REMOVAL_SUCCESS = "CONFIRM_PART_REMOVAL_SUCCESS";
export const CONFIRM_PART_REMOVAL_FAIL = "CONFIRM_PART_REMOVAL_FAIL";

export const GET_PART_ENTERIES = "GET_PART_ENTERIES";
export const GET_PART_ENTERIES_SUCCESS = "GET_PART_ENTERIES_SUCCESS";
export const GET_PART_ENTERIES_FAIL = "GET_PART_ENTERIES_FAIL";

export const ADD_PART_TO_STORAGE = "ADD_PART_TO_STORAGE";
export const ADD_PART_TO_STORAGE_SUCCESS = "ADD_PART_TO_STORAGE_SUCCESS";
export const ADD_PART_TO_STORAGE_FAIL = "ADD_PART_TO_STORAGE_FAIL";

export const REMOVE_PART_ENTERY = "REMOVE_PART_ENTERY";
export const REMOVE_PART_ENTERY_SUCCESS = "REMOVE_PART_ENTERY_SUCCESS";
export const REMOVE_PART_ENTERY_FAIL = "REMOVE_PART_ENTERY_FAIL";

export const REMOVE_PART = "REMOVE_PART";
export const REMOVE_PART_SUCCESS = "REMOVE_PART_SUCCESS";
export const REMOVE_PART_FAIL = "REMOVE_PART_FAIL";

export const TRANSPORT_PART = "TRANSPORT_PART";
export const TRANSPORT_PART_SUCCESS = "TRANSPORT_PART_SUCCESS";
export const TRANSPORT_PART_FAIL = "TRANSPORT_PART_FAIL";

export const GET_PARTS_IN_TRANSPORT = "GET_PARTS_IN_TRANSPORT";
export const GET_PARTS_IN_TRANSPORT_SUCCESS = "GET_PARTS_IN_TRANSPORT_SUCCESS";
export const GET_PARTS_IN_TRANSPORT_FAIL = "GET_PARTS_IN_TRANSPORT_FAIL";

export const SPEND_PART = "SPEND_PART";
export const SPEND_PART_SUCCESS = "SPEND_PART_SUCCESS";
export const SPEND_PART_FAIL = "SPEND_PART_FAIL";

export const CONFIRM_TRANSPORT = "CONFIRM_TRANSPORT";
export const CONFIRM_TRANSPORT_SUCCESS = "CONFIRM_TRANSPORT_SUCCESS";
export const CONFIRM_TRANSPORT_FAIL = "CONFIRM_TRANSPORT_FAIL";

export const WAITING_FOR_CONFIRMATION = "WAITING_FOR_CONFIRMATION";
export const WAITING_FOR_CONFIRMATION_SUCCESS =
  "WAITING_FOR_CONFIRMATION_SUCCESS";
export const WAITING_FOR_CONFIRMATION_FAIL = "WAITING_FOR_CONFIRMATION_FAIL";

export const REMOVE_PART_SPENT = "REMOVE_PART_SPENT";
export const REMOVE_PART_SPENT_SUCCESS = "REMOVE_PART_SPENT_SUCCESS";
export const REMOVE_PART_SPENT_FAIL = "REMOVE_PART_SPENT_FAIL";

export const HISTORY_PART_SPENT = "HISTORY_PART_SPENT";
export const HISTORY_PART_SPENT_SUCCESS = "HISTORY_PART_SPENT_SUCCESS";
export const HISTORY_PART_SPENT_FAIL = "HISTORY_PART_SPENT_FAIL";

export const SPENTS_WITHOUT_PARTS = "SPENTS_WITHOUT_PARTS";
export const SPENTS_WITHOUT_PARTS_SUCCESS = "SPENTS_WITHOUT_PARTS_SUCCESS";
export const SPENTS_WITHOUT_PARTS_FAIL = "SPENTS_WITHOUT_PARTS_FAIL";

export const TOTAL_SPENT_PARTS = "TOTAL_SPENT_PARTS";
export const TOTAL_SPENT_PARTS_SUCCESS = "TOTAL_SPENT_PARTS_SUCCESS";
export const TOTAL_SPENT_PARTS_FAIL = "TOTAL_SPENT_PARTS_FAIL";

export const ALL_PART_STORAGES = "ALL_PART_STORAGES";
export const ALL_PART_STORAGES_SUCCESS = "ALL_PART_STORAGES_SUCCESS";
export const ALL_PART_STORAGES_FAIL = "ALL_PART_STORAGES_FAIL";
