import React, { useState } from "react";

import { Button, Tabs } from "antd";

import Filters from "./components/Filters";

import {
  useHistory,
  useRouteMatch,
  NavLink,
  Switch,
  Route,
} from "react-router-dom";

import AvgUserResponseTime from "./components/avgUserResponseTime";

const { TabPane } = Tabs;

const WorkEffectiveness = ({}) => {
  const match = useRouteMatch();
  const history = useHistory();
  const [activeKey, set_activeKey] = useState(
    history.location.pathname.split("/").slice(-1)[0]
  );

  return (
    <>
      <div>
        <Tabs
          defaultActiveKey={activeKey}
          onChange={(key) => {
            history.replace(`/analytics/workeffectiveness/${key}`);
          }}
        >
          {TABS.map((item) => (
            <TabPane
              tab={
                <>
                  {item.icon}
                  <NavLink to={`${match.url}/${item.path}`}>
                    {item.name}
                  </NavLink>
                </>
              }
              key={item.path}
            />
          ))}
        </Tabs>
      </div>
      <div>
        <Switch>
          <Route
            path={`${match.path}/reports_1`}
            render={() => <AvgUserResponseTime />}
          />
          {/* <Route
            path={`${match.path}/reports_2`}
            render={() => <AvgTimeSpendOnMaintenances />}
          />
          <Route path={`${match.path}/reports_3`} render={() => <div>3</div>} /> */}
        </Switch>
      </div>
    </>
  );
};

export default WorkEffectiveness;

const TABS = [
  {
    name: "Vremenski i opći učinak",
    path: "reports_1",
  },
  // {
  //   name: "Opći učinak",
  //   path: "reports_2",
  // },
  // {
  //   name: "Izvještaj 3",
  //   path: "reports_3",
  // },
];
