import { takeLatest, put, call, select, delay } from "redux-saga/effects";

import * as actions from "./actions";

import axios_items from "../../../axios/axios-items";
import { LOGOUT } from "../../modules/Users/actions";
import store from "../../../store";

const initialState = {
  locations: {
    status: "",
  },
  deleteLocation: {
    status: "",
  },
  updateLocation: {
    status: "",
  },
  prepareRegistration: {
    status: "loading",
    data: {
      categories: [],
      loactaions: [],
      // ...
    },
  },
  category: {
    status: "",
  },

  machines: {
    status: "",
    data: [],
  },
  machine: {
    status: "",
    data: {},
  },
  updateMachineType: {
    status: "",
    data: {},
  },
  saveMachineFunction: {
    status: "",
    data: {},
  },
};

export default function reducer(state = initialState, action = {}) {
  var tmp;
  var index = -1;
  switch (action.type) {
    case actions.SAVE_MACHINE_CATEGORY:
      return {
        ...state,
        saveMachineFunction: {
          status: "loading",
          data: {},
          message: "",
        },
      };

    case actions.SAVE_MACHINE_CATEGORY_SUCCESS:
      tmp = { ...state.prepareRegistration.data };
      tmp.categories = action.data.item_categories;

      return {
        ...state,
        saveMachineFunction: {
          status: "",
          data: {},
          message: action.message,
        },
        prepareRegistration: {
          data: tmp,
          status: "",
        },
      };

    case actions.SAVE_MACHINE_CATEGORY_FAIL:
      return {
        ...state,
        saveMachineFunction: {
          status: "error",
          data: {},
          message: action.message,
        },
      };
    case actions.SAVE_LOCATION:
      return {
        ...state,
        locations: {
          status: "loading",
        },
      };

    case actions.SAVE_LOCATION_SUCCESS:
      return {
        ...state,
        locations: {
          status: "",
        },
      };

    case actions.SAVE_LOCATION_FAIL:
      return {
        ...state,
        locations: {
          status: "error",
        },
      };

    case actions.DELETE_LOCATION:
      return {
        ...state,
        deleteLocation: {
          status: action.location_id,
        },
      };

    case actions.DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        deleteLocation: {
          status: "",
        },
      };

    case actions.DELETE_LOCATION_FAIL:
      return {
        ...state,
        deleteLocation: {
          status: "error",
        },
      };

    case actions.UPDATE_LOCATION:
      return {
        ...state,
        updateLocation: {
          status: action.location_id,
        },
      };

    case actions.UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        updateLocation: {
          status: "",
        },
      };

    case actions.UPDATE_LOCATION_FAIL:
      return {
        ...state,
        updateLocation: {
          status: "error",
        },
      };

    case actions.PREPARE_MACHINE_REGISTRATION:
      return {
        ...state,
        prepareRegistration: {
          data: {},
          status: "loading",
        },
      };

    case actions.PREPARE_MACHINE_REGISTRATION_SUCCESS:
      return {
        ...state,
        prepareRegistration: {
          data: action.data,
          status: "",
        },
      };
    case actions.PREPARE_MACHINE_REGISTRATION_FAIL:
      return {
        ...state,
        prepareRegistration: {
          data: {},
          status: "error",
        },
      };

    case actions.UPDATE_MACHINE_CATEGORY:
      return {
        ...state,
        updateMachineType: {
          status: "loading" + action.id,
          data: {},
          message: "",
        },
      };

    case actions.UPDATE_MACHINE_CATEGORY_SUCCESS:
      tmp = { ...state.prepareRegistration.data };
      tmp.categories = action.data.item_categories;
      return {
        ...state,
        updateMachineType: {
          status: "",
          data: {},
          message: action.message,
        },
        prepareRegistration: {
          data: tmp,
          status: "",
        },
      };

    case actions.UPDATE_MACHINE_CATEGORY_FAIL:
      return {
        ...state,
        updateMachineType: {
          status: "error",
          data: {},
          message: action.message,
        },
      };

    case actions.GET_MACHINES:
      return {
        ...state,
        machines: {
          status: "loading",
          data: [],
        },
      };

    case actions.GET_MACHINES_SUCCESS:
      return {
        ...state,
        machines: {
          status: "",
          data: action.data,
        },
      };

    case actions.GET_MACHINES_FAIL:
      return {
        ...state,
        machines: {
          status: "error",
          data: [],
        },
      };

    case actions.ADD_MACHINE:
      return {
        ...state,
        machine: {
          status: "loading",
          data: {},
        },
      };

    case actions.ADD_MACHINE_SUCCESS:
      tmp = [...state.machines.data];
      tmp.push(action.data);
      return {
        ...state,
        machine: {
          status: "",
          data: action.data,
        },
        machines: {
          status: "",
          data: tmp,
        },
      };

    case actions.ADD_MACHINE_FAIL:
      return {
        ...state,
        machine: {
          status: "error",
          data: {},
        },
      };

    default:
      return { ...state };
  }
}

function transformData(data) {
  return data.data;
}

const authToken = () => localStorage.getItem("token");

//Save location
export function* watcherSaveLoaction() {
  yield takeLatest(actions.SAVE_LOCATION, saveLocation);
}

function _saveLocation(options) {
  return axios_items(options).post(`locations/`, options.data);
}

function* saveLocation(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
    };
    const response = yield call(_saveLocation, options);
    const data = transformData(response);

    let obj = { ...store.getState().machinesReducer.prepareRegistration.data };
    obj.locations = data.locations;
    yield put({
      type: actions.PREPARE_MACHINE_REGISTRATION_SUCCESS,
      data: obj,
    });
    yield put({
      type: actions.SAVE_LOCATION_SUCCESS,
      data,
    });

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.SAVE_LOCATION_FAIL, error });
  }
}
//save location end

// Delete location

export function* watcherDeleteLocation() {
  yield takeLatest(actions.DELETE_LOCATION, deleteLocation);
}

function _deleteLocation(options) {
  return axios_items(options).patch(`locations/${options.location_id}/`, {
    is_active: false,
  });
}

function* deleteLocation(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      location_id: payload.location_id,
    };
    const response = yield call(_deleteLocation, options);
    const data = transformData(response);
    yield put({
      type: actions.DELETE_LOCATION_SUCCESS,
      data,
      location_id: payload.location_id,
    });

    let obj = { ...store.getState().machinesReducer.prepareRegistration.data };
    obj.locations = data.locations;

    yield put({
      type: actions.PREPARE_MACHINE_REGISTRATION_SUCCESS,
      data: obj,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);

    if (payload.errorCallback) {
      payload.errorCallback();
    }

    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.DELETE_LOCATION_FAIL, error });
  }
}

// Delete location

// Update location

export function* watcherUpdateLocation() {
  yield takeLatest(actions.UPDATE_LOCATION, updateLocation);
}

function _updateLocation(options) {
  return axios_items(options).patch(
    `locations/${options.location_id}/`,
    options.data
  );
}

function* updateLocation(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      location_id: payload.location_id,
      data: payload.data,
    };
    const response = yield call(_updateLocation, options);
    const data = transformData(response);
    yield put({
      type: actions.UPDATE_LOCATION_SUCCESS,
      data,
      location_id: payload.location_id,
    });

    let obj = { ...store.getState().machinesReducer.prepareRegistration.data };
    obj.locations = data.locations;

    yield put({
      type: actions.PREPARE_MACHINE_REGISTRATION_SUCCESS,
      data: obj,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);

    if (payload.errorCallback) {
      payload.errorCallback();
    }

    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.UPDATE_LOCATION_FAIL, error });
  }
}

// Update location

//prepare registration
export function* watcherPrepareMachineRegistration() {
  yield takeLatest(actions.PREPARE_MACHINE_REGISTRATION, prepareRegistration);
}

function _prepareRegistration(options) {
  return axios_items(options).get(`prepare/`);
}

function* prepareRegistration(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
    };
    const response = yield call(_prepareRegistration, options);
    const data = transformData(response);
    yield put({
      type: actions.PREPARE_MACHINE_REGISTRATION_SUCCESS,
      data,
    });

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.PREPARE_MACHINE_REGISTRATION_FAIL, error });
  }
}
//prepare registration end

// saveMachineFunction
export function* watcher_saveMachineFunction() {
  yield takeLatest(actions.SAVE_MACHINE_CATEGORY, saveMachineFunction);
}

function _saveMachineFunction(options) {
  return axios_items(options).post(`subcategories/`, options.data);
}

function* saveMachineFunction(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
    };
    const response = yield call(_saveMachineFunction, options);
    const data = transformData(response);
    yield put({
      type: actions.SAVE_MACHINE_CATEGORY_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.SAVE_MACHINE_CATEGORY_FAIL, error, message: "" });
  }
}
//saveMachineFunction END

// updateMachineType
export function* watcher_updateMachineType() {
  yield takeLatest(actions.UPDATE_MACHINE_CATEGORY, updateMachineType);
}

function _updateMachineType(options) {
  return axios_items(options).patch(
    `subcategories/${options.id}/`,
    options.data
  );
}

function* updateMachineType(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
      id: payload.id,
    };
    const response = yield call(_updateMachineType, options);
    const data = transformData(response);
    yield put({
      type: actions.UPDATE_MACHINE_CATEGORY_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({
      type: actions.UPDATE_MACHINE_CATEGORY_FAIL,
      error,
      message: "",
    });
  }
}
//updateMachineType END

//get machines
export function* watcherGetMachines() {
  yield takeLatest(actions.GET_MACHINES, getMachines);
}

function _getMachines(options) {
  return axios_items(options).get(`items/`);
}

function* getMachines(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
    };
    const response = yield call(_getMachines, options);
    const data = transformData(response);
    yield put({
      type: actions.GET_MACHINES_SUCCESS,
      data,
    });

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.GET_MACHINES_FAIL, error });
  }
}
//get machines end

//add machine
export function* watcherAddMachine() {
  yield takeLatest(actions.ADD_MACHINE, addMachine);
}

function _addMachine(options) {
  return axios_items(options).post(`items/`, options.data);
}

function* addMachine(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
    };
    const response = yield call(_addMachine, options);
    const data = transformData(response);
    yield put({
      type: actions.ADD_MACHINE_SUCCESS,
      data,
    });

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.ADD_MACHINE_FAIL, error });
  }
}
//add machines end
