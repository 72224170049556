import React from "react";

import { Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import css from "./popover.module.scss";

export default function Informations({ text, style, title }) {
  const content = (
    <div className={css.popoverContainer}>
      {/* Title */}
      <div className={css.titleContainer}>
        <InfoCircleOutlined className={css.titleIcon} />
        <p className={css.title}>{title || "Autopilot"}</p>
      </div>
      {/* Divider */}
      <div className={css.divider} />
      {/* Text */}
      <div className={css.text}>
        <p className={css.textlabel}>{text}</p>
      </div>
    </div>
  );

  return (
    <Popover placement="topLeft" content={content}>
      <InfoCircleOutlined
        style={{
          position: "absolute",
          fontSize: 18,
          left: -100,
          top: 0,
          opacity: 0.5,
          cursor: "pointer",
          ...style
        }}
      />
    </Popover>
  );
}
