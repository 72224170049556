import React, { useState } from "react";

import { Form, Select, Button, message, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import { getApiEndpoint } from "../../../axios/endpoints";
import { SPENTS_WITHOUT_PARTS_SUCCESS } from "../../../redux/modules/Parts/actions";

const PairSpendForm = ({ spend, data, close }) => {
  const [parts, set_parts] = useState([]);
  const dispatch = useDispatch();

  const [part, set_part] = useState(null);

  const [loading, set_loading] = useState(false);
  const [loadingSearch, set_loadingSearch] = useState(false);

  const onSave = async () => {
    const token = await localStorage.getItem("token");
    set_loading(true);

    Axios.post(
      `${getApiEndpoint()}api/parts/spend/connect_to_part/`,
      { part, spend: spend.id },
      { headers: { Authorization: "Token " + token } }
    )
      .then((res) => {
        set_loading(false);
        const arr = [...data];
        const index = data.findIndex((x) => x.id === spend.id);
        arr.splice(index, 1);
        dispatch({ type: SPENTS_WITHOUT_PARTS_SUCCESS, data: arr });
        close()
      })
      .catch((err) => {
        message.error("Dogodila se greška kod spremanja");
        console.log(err);
        set_loading(false);
      });
  };

  function debounce(func, timeout = 600) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }
  const fetchSearch = async (value) => {
    const token = await localStorage.getItem("token");
    set_loadingSearch(true);
    Axios.get(`${getApiEndpoint()}api/parts/parts/search/?name=${value}`, {
      headers: { Authorization: "Token " + token },
    })
      .then((res) => {
        set_loadingSearch(false);
        set_parts([...res.data.data]);
      })
      .catch((err) => {
        message.error("Dogodila se greška kod dohvata");
        set_loadingSearch(false);
      });
  };
  const search = debounce((value) => fetchSearch(value));

  return (
    <>
      <Select
        style={{ width: 320 }}
        onSearch={(value) => {
          const v = value;
          if (value) {
            search(v.toLowerCase());
          } else {
            set_parts([]);
          }
        }}
        onSelect={(v) => set_part(v)}
        mode="single"
        placeholder="Pretraži"
        allowClear={true}
        showSearch
        filterOption={false}
      >
        {parts.map((item) => (
          <Select.Option key={item.id} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
      <Spin style={{ marginLeft: 12 }} spinning={loadingSearch} />
      <Button
        style={{ float: "right" }}
        loading={loading}
        type="primary"
        htmlType="submit"
        disabled={!part}
        onClick={onSave}
      >
        Spremi{" "}
      </Button>
    </>
  );
};

export default Form.create()(PairSpendForm);

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}
