import React, { Component } from "react";

import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { LeftCircleOutlined } from "@ant-design/icons";

import * as actions from "../../redux/modules/Maintenances/actions";

import css from "./maintenance.module.scss";

import NewOrder from "../Machine/components/Maintenance/NewOrder";
import Spinner from "../../components/LoadingScreen/index";
import MaintenanceTabs from "./components/MaintenanceTabs";
// Tab components
import About from "./components/About";
import Tasks from "./components/Tasks";
import Reports from "./components/Reports";
import Technicians from "./components/Technicians";
import Parts from "./components/Parts";

import { PageHeader, Button, Alert, Popconfirm, message, Drawer, Modal } from "antd";
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import Form from "../Dashboard/components/Reporter/ConfirmOrder/Form";
import Comands from "./components/Comands";
import Control from "./components/Comands/Control";
import NameChecklist from "./components/NameChecklist";

const STATUS = [
  {
    text: "Nedodijeljen",
    value: "in_bowl",
    color: "warning",
    icon: <ExclamationCircleOutlined />,
  },
  {
    text: "Prijavljen",
    value: "reported",
    color: "processing",
    icon: <ClockCircleOutlined />,
  },
  {
    text: "Zakazan",
    value: "scheduled",
    color: "processing",
    icon: <ClockCircleOutlined />,
  },
  {
    text: "Odbačen",
    value: "discarded",
    color: "error",
    icon: <CloseCircleOutlined />,
  },
  {
    text: "Neotklonjen",
    value: "unclosed",
    color: "error",
    icon: <CloseCircleOutlined />,
  },
  {
    text: "Dodijeljen",
    value: "appointed",
    color: "processing",
    icon: <ClockCircleOutlined />,
  },
  {
    text: "U tijeku",
    value: "in_progress",
    color: "processing",
    icon: <SyncOutlined spin />,
  },
  {
    text: "Na čekanju",
    value: "waiting",
    color: "default",
    icon: <ClockCircleOutlined />,
  },
  {
    text: "Nedodiljeljen/Pauziran",
    value: "in_bowl_waiting",
    color: "default",
    icon: <ClockCircleOutlined />,
  },
  {
    text: "Završen",
    value: "finished",
    color: "success",
    icon: <CheckCircleOutlined />,
  },
  {
    text: "Potvrđeno završen",
    value: "closed",
    color: "success",
    icon: <CheckCircleOutlined />,
  },
];

class Maintenance extends Component {
  state = {
    maintenanceFormVisible: false,
    activeKey: "",
    queryParams: "null",
    count: 1,
    confirmOrderVisible: false,
    comandsVisible: false
  };
  componentDidMount() {
    this.props.onGetOrder(this.props.match.params.id);
    this.setActiveKey();
    this.setState({ queryParams: this.props.location.state });

    // this.props.history.push('about')
  }

  setActiveKey = () => {
    const activeKey =
      this.props.location.pathname.split("/").slice(-1)[0] || "about";
    this.setState({ activeKey });
  };

  openCloseMaintenaceForm = (bool) => {
    if (bool) {
      this.setState({ maintenanceFormVisible: bool });
      return;
    }
    this.setState({ maintenanceFormVisible: false });
  };

  errorCallback = () => {
    message.error("Došlo je do pogreške prilikom brisanja imovine.");
  };

  successCallback = () => {
    message.success("Uspješno!");
  };

  inBowl = () => {
    this.props.onInBowl(
      this.props.order.data.id,
      this.successCallback.bind(this),
      this.errorCallback.bind(this)
    );
  };

  render() {

    const { match, order, machines, prepareRegistration, currentUser } = this.props;

    let order_status = STATUS.find(
      (status) => status.value === order.data.status
    );

    if (order_status === -1 || order_status === undefined) {
      order_status = {};
    }
    if (
      order.status === "loading" ||
      machines.status === "loading" ||
      prepareRegistration.status === "loading"
    ) {
      return <Spinner />;
    }

    const alert = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1" }}
        message="Dogodila se greška"
        description="Dogodila se greška prilikom dohvata sa servera probaj ponovo!"
        type="error"
      />
    );

    const assets = order.data.items && 
      order.data.items.length > 1
        ? order.data.items.map((item) => `${item.name} ${item.info?.model || ""} `)
        : order.data.items[0].name + " " + order.data.items[0].info?.model || "";

    return (
      <>
      <Modal title={"Komande"} footer={null} visible={this.state.comandsVisible} onCancel={() => this.setState({comandsVisible: false})}>
        <Comands close={() => this.setState({comandsVisible: false})} /> 
      </Modal>
        <PageHeader
          id="maintenance-page-header"
          title={null}
          extra={
            <div className={css.flexRow}>
              <div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    className={"hover"}
                    onClick={this.props.goBack}
                    style={{ width: "40px" }}
                  >
                    <LeftCircleOutlined
                      style={{ fontSize: 20, marginTop: "8px" }}
                    />
                  </div>
                  {/* Order name */}
                  <h1 className={css.orderName}>
                    <span style={{ fontSize: 18, fontWeight: 400, opacity: .75, paddingRight: 6 }}>Imovina: </span>

                    { order.data.items[0].name}
                  </h1>
                </div>
                {/* Order status */}
                <div className={[order_status.color]}>
                  {order_status.icon}
                  <span style={{ marginLeft: 6 }}>{order_status.text}</span>
                </div>
              </div>

              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 15,
                  }}
                >
                  
                   {order.data.type.id !== 4? 
                   <Button
                  onClick={() =>this.setState({comandsVisible: true})}
                        style={{ marginRight: 20 }}
                      >
                        Komande
                      </Button> : <>{order.data.status !== "finished" && order.data.status !== "closed" ? <Control order={order.data} /> : null}</>}
                  {order.data.status === "reported" && (
                    <Button
                      style={{ marginRight: 20 }}
                      loading={this.props.inBowl.status === "loading"}
                      type={"dashed"}
                      onClick={this.inBowl}
                    >
                      Nedodjeljen
                    </Button>
                  )}
                  {order.data.status !== "closed" &&
                  order.data.status !== "finished" ? (
                    <Button
                      type={this.state.maintenanceFormVisible ? "" : "primary"}
                      onClick={() =>
                        this.openCloseMaintenaceForm(
                          !this.state.maintenanceFormVisible
                        )
                      }
                    >
                      {this.state.maintenanceFormVisible ? "Zatvori" : "Uredi"}
                    </Button>
                  ) : null}

                  {this.state.maintenanceFormVisible ? null : (
                    <Popconfirm
                      title="Jeste li sigurni da želite odbaciti?"
                      placement="left"
                      okText="Izbriši"
                      cancelText="Odustani"
                      onConfirm={() => {
                        let updateFormData = new FormData();
                        updateFormData.append(
                          "data",
                          JSON.stringify({ status: "discarded" })
                        );
                        this.props.onDiscardOrder(
                          order.data.id,
                          updateFormData,
                          () => {
                            this.props.history.push("/maintenances");
                          }
                        );
                      }}
                      okType="danger"
                    >
                      <Button
                        disabled={
                         false
                        }
                        loading={
                          this.props.updateWorkOrder.status === "loading"
                        }
                        type="danger"
                        style={{ marginLeft: 20 }}
                      >
                        Izbriši
                      </Button>
                      
                    </Popconfirm>
                    
                  )}
                  
                  {order.data.status === "finished" && <Button
                  onClick={() =>this.setState({confirmOrderVisible: true})}
                        style={{ marginLeft: 20 }}
                      >
                        Potvrdi
                      </Button>}
                </div>

                

                {this.props.updateWorkOrder.status === "error" && alert}
              </div>
            </div>
          }
        />

        {order.status === "error" ||
        machines.status === "error" ||
        prepareRegistration.status === "error"
          ? alert
          : null}

        <Drawer
          visible={this.state.maintenanceFormVisible}
          onClose={() => this.openCloseMaintenaceForm(false)}
          destroyOnClose={true}
          width={700}
          title="Uredi nalog"
        >
          <NewOrder
            prepareRegistration={this.props.prepareRegistration.data}
            machines={this.props.machines.data}
            openCloseMaintenaceForm={this.openCloseMaintenaceForm}
            order={order}
            update={true}
            maintenance_for={"machine"}
            assetSelected={true}
            // images, videos, documents, relevant_links
          />
        </Drawer>
        <>
          <MaintenanceTabs
            setCounter={this.props.setCounter}
            setActiveKey={this.setActiveKey}
            activeKey={this.state.activeKey}
            order={order}
          />

          <div>
            <Switch>
              <Route
                path={`${match.path}/about`}
                render={() => <About currentUser={currentUser} order={order} />}
              />
              <Route
                path={`${match.path}/tasks`}
                render={() => <Tasks order={order} />}
              />
              <Route
                path={`${match.path}/reports`}
                render={() => (
                  <Reports
                    reports={order.data.reports}
                    title={"Izvještaji"}
                    subtitle={"Pregled izvještaja na nalogu"}
                  />
                )}
              />
              <Route
                path={`${match.path}/technicians`}
                render={() => <Technicians order={order} />}
              />
              <Route
                path={`${match.path}/`}
                render={() => <Parts order={order} />}
              />
            </Switch>
          </div>
        </>

        <Modal visible={this.state.confirmOrderVisible} onCancel={() => this.setState({confirmOrderVisible: false})} footer={null} >
          <Form set_orderID={(id) => {this.setState({confirmOrderVisible: !!id})
            this.props.onGetOrderSucces({
              ...order.data,
              status: "closed"
            })
        } } order_id={order.data.id} />
        </Modal>
      </>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    order: state.maintenanceReducer.order,
    machines: state.machinesReducer.machines,
    prepareRegistration: state.machinesReducer.prepareRegistration,
    updateWorkOrder: state.maintenanceReducer.updateWorkOrder,
    inBowl: state.maintenanceReducer.inBowl,
    currentUser: state.usersReducer.user.data
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onGetOrderSucces: (data) => dispatch({type: actions.GET_ORDER_SUCCESS, data}),
    onGetOrder: (order_id) => dispatch({ type: actions.GET_ORDER, order_id }),
    onInBowl: (id, successCallback, errorCallback) =>
      dispatch({
        type: actions.IN_BOWL,
        id,
        successCallback,
        errorCallback,
      }),
    onDiscardOrder: (order_id, data, callback) =>
      dispatch({
        type: actions.UPDATE_WORK_ORDER,
        order_id,
        data,
        callback,
      }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Maintenance)
);

const alert = (
  <Alert
    style={{ marginBottom: "10px", zIndex: "1", maxWidth: "600px" }}
    message="Dogodila se greška"
    description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
    type="error"
  />
);
