import React, { useState } from "react";
import css from "../../../assets.module.scss";
import { Popover } from "antd";
import Content from "../../Popover";
import ICON from '../../../../../../assets/icons/information.png'

export default function Information({
  label = "",
  information = 0,
  text = "",
  hideHoursLabel = false,
  suffix = "",
  labelStyle = {},
}) {

  // Variables
  const [popoverVisible, setPopoverVisible] = useState(false);

  // Methods
  function togglePopover() {
    setPopoverVisible((previousState) => !previousState);
  }

  return (
    <Popover
      trigger="click"
      visible={popoverVisible}
      onVisibleChange={togglePopover}
      content={<Content title={label} text={text} />}
    >
      <div id={css.informationCard}>
        {/* Icon */}
        <div className={css.iconBackground}>
          <img
            src={ICON}
            className={css.icon}
          />
        </div>

        {/* Informations */}
        <div className={css.infoContainer}>
          <p className={css.value}>{information}{!hideHoursLabel ? "h" : suffix}</p>
          <p className={css.label} style={labelStyle}>{label}</p>
        </div>

        {/* Indicator */}
        <div className={css.togglePopover}>
          <div className={css.togglePopoverCircle} />
          <div className={css.togglePopoverCircle} />
          <div className={css.togglePopoverCircle} />
        </div>
      </div>
    </Popover>
  );
}
