import { Alert, Row, Col, Table, Button } from "antd";
import React, { useState, useEffect } from "react";
import {
  ASSET_MAINTENANCE_LIST,
  ASSET_MAINTENANCE_LIST_SUCCESS,
  ASSET_MAINTENANCE_SUMMARY,
} from "../../../../redux/modules/Analytics/actions";
import ExportCSV from "../../components/ExportCSV";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../../../components/LoadingScreen";
import { crohHumanizer } from "../../../../helpers/consts";
import { Link } from "react-router-dom";
import moment from "moment";
import Information from "../../Assets/components/Cards/Information";

const Reports_1 = ({}) => {
  const [dataCSV_table_1, set_dataCSV_table_1] = useState([{ id: 0, name: "" }]);

  const [assetName, set_assetName] = useState("");
  const [dataCSV_table_2, set_dataCSV_table_2] = useState([{ id: 0, name: "" }]);
  const getReports = (queryParams) => {
    dispatch({ type: ASSET_MAINTENANCE_SUMMARY, queryParams });
  };

  const [filteredInfo, set_filteredInfo] = useState([]);
  const [total_failure_time, set_total_failure_time] = useState(0);

  const dispatch = useDispatch();
  const {
    pickedDate,
    murgaReports: { filters },
    assetMaintenanceSummary,
    assetMaintenanceList,
  } = useSelector((state) => state.AnalyticsReducer);
  useEffect(() => {
    const queryParams = {
      from: pickedDate.data.from,
      to: pickedDate.data.to,
      maintenance_categories: filters.maintenance_categories.join("|"),
      assets_category: filters.assets_category.join("|"),
      locations: filters.locations.join("|"),
      maintenance_types: filters.maintenance_types.join("|"),
      assets: filters.assets.join("|"),
    };
    getReports(queryParams);
    dispatch({
      type: ASSET_MAINTENANCE_LIST_SUCCESS,
      data: { total_failure_time: 0, data: [] },
    });
  }, [pickedDate, filters]);

  useEffect(() => {
    let tmpArr = assetMaintenanceSummary.data.data.map((x) => ({
      Naziv: x.asset_name,
      "Broj naloga": x.order_num,
      "Vrijeme(h)": +(Math.ceil(+x.failure_time) / 1000 / 60 / 60).toFixed(1),
    }));

    let suma = 0;
    let orders_sum = 0;
    for (let index = 0; index < assetMaintenanceSummary.data.data.length; index++) {
      const element = assetMaintenanceSummary.data.data[index];
      suma += Math.ceil(+element.failure_time) / 1000 / 60 / 60;
      orders_sum += +element.order_num;
    }
    tmpArr.push({
      Naziv: "Ukupno",
      "Broj naloga": orders_sum,
      "Vrijeme(h)": suma,
    });
    set_dataCSV_table_1(tmpArr);
  }, [assetMaintenanceSummary]);

  useEffect(() => {
    let sum = 0;

    let tmpArr = assetMaintenanceList.data.data.map((x) => {
      let desc = x.order_name;
      if (x.description) {
        desc += ", " + x.description;
      }
      sum += Math.ceil(+x.failure_time) / 1000 / 60 / 60;
      return {
        Naziv: x.asset_name,
        Opis: desc,
        "Vrijeme(h)": +(Math.ceil(+x.failure_time) / 1000 / 60 / 60).toFixed(1),
        Kategorije: x.categories,
        Kreiran: moment(new Date(x.created_at)).format("LLL"),
        Zatvoren: moment(new Date(x.closed_at)).format("LLL"),
        Aktivna: x.machine_run ? "DA" : "NE",
      };
    });

    tmpArr.push({
      Naziv: "Ukupno",
      Opis: "-",
      "Vrijeme(h)": sum,
      Kategorije: "-",
      Kreiran: "-",
      Zatvoren: "-",
      Aktivna: "-",
    });

    set_dataCSV_table_2(tmpArr);

    set_total_failure_time(
      crohHumanizer(Math.ceil(+assetMaintenanceList.data.total_failure_time), {
        units: ["h"],
        maxDecimalPoints: 0,
      })
    );
  }, [assetMaintenanceList]);

  if (assetMaintenanceSummary.status === "loading") {
    return <Loading />;
  }

  const handleChange = (pagnation, filters, a, b) => {
    let sum = 0;

    b.currentDataSource.forEach((element) => {
      sum += +element.failure_time;
    });

    set_total_failure_time(
      crohHumanizer(Math.ceil(sum), {
        units: ["h"],
        maxDecimalPoints: 1,
      })
    );

    let _filteredInfo = [...filteredInfo];
    _filteredInfo = filters.categories;
    set_filteredInfo(_filteredInfo);
  };

  const TABLE_2 = [
    {
      title: "Naziv",
      dataIndex: "asset_name",
      key: "asset_name",
      render: (text, record, index) => (
        <div>
          <Link target={"_blank"} to={`/machines/${record.asset_id}/specifications`}>
            {record.asset_name}
          </Link>
        </div>
      ),
    },
    {
      title: "Opis",
      dataIndex: "description",
      key: "description",
      render: (text, record, index) => (
        <div>
          <Link target={"_blank"} to={`/maintenances/${record.order_id}/about`}>
            {record.order_name}
          </Link>
          <span>
            <br />
            {record.description}
          </span>
        </div>
      ),
    },
    {
      title: "Kategorija",
      dataIndex: "categories",
      key: "categories",
      filters: getFilters(assetMaintenanceList.data.data),
      filteredValue: filteredInfo || null,
      onFilter: (value, record) => {
        return record.categories.includes(value);
      },
      ellipsis: true,
    },
    {
      title: "Kreiran",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record, index) => <span>{moment(new Date(text)).format("LLL")}</span>,
    },
    {
      title: "Trajanje",
      dataIndex: "failure_time",
      key: "failure_time",
      render: (text, record, index) => (
        <span>
          {Number(record.failure_time / 1000 / 60 / 60).toFixed(1)}h
        </span>
      ),
    },
  ];

  const TABLE_1 = [
    {
      title: "Naziv",
      dataIndex: "asset_name",
      key: "asset_name",
      render: (text, record, index) => (
        <div>
          <Link target={"_blank"} to={`/machines/${record.asset_id}/specifications`}>
            {record.asset_name}
          </Link>
        </div>
      ),
    },
    {
      title: "Vrijeme zastoja",
      dataIndex: "failure_time",
      key: "failure_time",
      render: (text, record, index) => (
        <span>
          {Number(record.failure_time / 1000 / 60 / 60).toFixed(1)}
        </span>
      ),
    },
    {
      title: "Broj naloga",
      dataIndex: "order_num",
      key: "order_num",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, render, index) => (
        <Button
          onClick={() => {
            set_assetName(render.asset_name);
            dispatch({
              type: ASSET_MAINTENANCE_LIST,
              queryParams: {
                asset_id: render.asset_id,
                from: pickedDate.data.from,
                to: pickedDate.data.to,
                maintenance_types: filters.maintenance_types.join("|"),
                maintenance_categories: filters.maintenance_categories.join("|"),
              },
            });
          }}
          type="primary"
        >
          Dohvati
        </Button>
      ),
    },
  ];

  return (
    <div>
      {assetMaintenanceSummary.status === "error" || assetMaintenanceList.status === "error" ? (
        <Alert
          style={{ width: "639px", marginTop: "10px", zIndex: "1" }}
          message="Dogodila se greška"
          description="Dogodila se greška prilikom dohvata podataka molimo vas probajte ponovo!"
          type="error"
        />
      ) : null}

      <Row style={{ margin: "20px 0" }} gutter={[24]}>
        <Col span={8}>
          <Information
            hideHoursLabel={true}
            text={""}
            information={
              (Number(assetMaintenanceSummary.data.total_failure_time) / 3600000).toFixed(
                1
              ) + "h"
            }
            label="Ukupno vrijeme zastoja"
          />
        </Col>
        <Col span={8}>
          {" "}
          <Information
            text={""}
            hideHoursLabel={true}
            information={sumOrderNum(assetMaintenanceSummary.data?.data || [])}
            label="Ukupan broj zastoja"
          />
        </Col>
        <Col span={8}>
          <Information
            hideHoursLabel={true}
            text={""}
            information={
              (Number(assetMaintenanceSummary.data.avg_failure_time) / 3600000).toFixed(1) +
              "h"
            }
            label="Prosječno vrijeme zastoja"
          />
        </Col>
      </Row>

      <Row>
        <Col span={window.innerWidth < 1440 ? 24 : 14} style={{ marginTop: 24 }}>
          <div style={{ padding: 12 }}>
            <Table columns={TABLE_1} rowKey={(item) => item.asset_id} dataSource={assetMaintenanceSummary.data.data} />
            <div style={{ marginBottom: 60 }}>
              <ExportCSV
                csvData={dataCSV_table_1}
                fileName={`Proizvodnja_svi ${pickedDate.data.from} - ${pickedDate.data.to}`}
              />
            </div>
          </div>
        </Col>
        <Col span={window.innerWidth < 1440 ? 24 : 10}>
          <div style={{ padding: 12 }}>
            {assetMaintenanceList.status === "loading" ? (
              <Loading />
            ) : (
              <>
                <div style={{ height: 24 }}>
                  <span>Ukupno: {total_failure_time}</span>
                </div>
                <Table
                  rowKey={(item, index) => {
                    return item.order_id;
                  }}
                  columns={TABLE_2}
                  dataSource={assetMaintenanceList.data.data}
                  onChange={handleChange}
                />
                {assetMaintenanceList.data.data.length ? (
                  <div style={{ marginBottom: 60 }}>
                    <ExportCSV
                      csvData={dataCSV_table_2}
                      fileName={`Proizvodnja_${assetName} ${pickedDate.data.from} - ${pickedDate.data.to}`}
                    />
                  </div>
                ) : null}
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Reports_1;

const getFilters = (data) => {
  const filters = [];

  data.forEach((element) => {
    const filter_string = element.categories;

    if (!filters.map((x) => x.value).includes(filter_string)) {
      filters.push({ text: filter_string, value: filter_string });
    }
  });

  return filters;
};

function sumOrderNum(assets) {
  return assets.reduce((total, asset) => total + asset.order_num, 0);
}
