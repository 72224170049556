import { Button } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import SectionTitle from "../SectionTitle";
import Report from "./components/Report";
import AddReportForm, { technicianOfOrder } from "./components/Report/addReportForm";

export default function Reports({ reports, openOrder, title, subtitle }) {

  const order = useSelector((state) => state.maintenanceReducer.order.data);
  const user = useSelector((state) => state.usersReducer.user.data);
  const [addFormVisible, set_addFormVisible] = useState(false);





  return (
    <>
    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}} >
      <div><SectionTitle title={title} subtext={subtitle} /></div>
     {technicianOfOrder(order, user) && order.technicians.length?<div><Button onClick={() => set_addFormVisible(true)}  type="primary" >Dodaj</Button></div> : null}
    </div>

    
      
      {/* Looping through reports */}
      {reports && reports.length
        ? reports.map((item, index) => {
            return (
              <Report
                key={index}
                openOrder={openOrder}
                item={item}
                index={index}
              />
            );
          })
        : null}
        <AddReportForm formVisible={addFormVisible} set_formVisible={set_addFormVisible} />
    </>
  );
}
