import React, { useState } from "react";
import SizeType from "../../helpers/deviceTypeSize";
import { Table, Button, Icon } from "antd";

import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

class AccountsTable extends React.Component {
  state = {
    filteredInfo: {
      category: null,
      functions: null,
    },
    numOfRows: 0,
  };

  componentDidMount() {
    this.setPage();
    this.setState({ numOfRows: this.props.data.length });
  }

  setPage = () => {
    // try {
    //   document
    //     .querySelector(
    //       `li.ant-pagination-item.ant-pagination-item-${this.props.page}`
    //     )
    //     .click();
    // } catch (error) {
    //   console.log(error);
    // }
  };

  handleChange = (pagination, filters, sorter, extra) => {
    this.props.onPageChange(pagination.current);
    const filteredInfo = {
      category: filters.category || null,
      functions: filters.functions || null,
    };
    this.setState({ filteredInfo });
    this.setState({ numOfRows: extra.currentDataSource.length });
  };

  clearFilters = () => {
    this.setState({ numOfRows: this.props.data.length });
    const filteredInfo = {
      category: null,
      functions: null,
    };
    this.setState({ filteredInfo });
  };

  render() {
    const tableData = StructAccountsForTable(
      this.props.data,
      this.state.filteredInfo || {},
      this.props.categories,
      this.props.toggleDrawer
    );

    return (
      <>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px 0",
          }}
        >
          <p style={{ fontSize: 16, fontWeight: 300 }}>
            Ukupno:{" "}
            <span style={{ fontWeight: 600 }}>{this.state.numOfRows}</span>
          </p>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button onClick={this.clearFilters}>
              Očisti filtere <Icon type="delete" />
            </Button>
            <div style={{ marginLeft: "15px" }}>{this.props.search && this.props.search()}</div>
            <div>{this.props.button && this.props.button()}</div>
          </div>
        </div>
        <Table
          rowKey={(record) => record.id}
          scroll={{
            x: SizeType() === "xs" || SizeType() === "sm" ? true : false,
          }}
          dataSource={tableData.dataSource}
          columns={tableData.columns}
          onChange={this.handleChange}
          pagination={false}
        />
      </>
    );
  }
}

const getFitlers = (data, type) => {
  switch (type) {
    case "category":
      return [
        { text: "Tehničar", value: "TEH" },
        { text: "Poslovođa", value: "MAN" },
        { text: "Prijavitelj", value: "REP" },
        { text: "Voditelj", value: "LDR" },
      ];
    case "functions":
      return data ? data.map((item) => ({ text: item.name, value: item.name })) : [];
  }

  let categoryFilter = [{ text: "error", value: "error" }];
  return categoryFilter;
};

const StructAccountsForTable = (data, filteredInfo, filters, callback) => {
  const columns = [
    {
      title: "Naziv",
      dataIndex: "full_name",
      key: "full_name",
      render: (text, record, index) => (
        <div>
          {/* <EditOutlined
            onClick={() => callback(true, record.id, true)}
            className={"hover"}
            style={{ fontSize: "18px", marginRight: "12px" }} 
          /> */}
          {"  "}
          <Link to={`/accounting/${record.id}/about`}>{record.full_name}</Link>
        </div>
      ),
    },
    {
      title: "Funkcija",
      dataIndex: "functions",
      key: "functions",
      filters: getFitlers(filters, "functions"),
      filteredValue: filteredInfo.functions || null,
      onFilter: (value, record) => {
        return record.functions.includes(value);
      },
      ellipsis: true,
    },
    {
      title: "Tel. broj",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Adresa",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Napomena",
      dataIndex: "remark",
      key: "remark",
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  const dataSource = [];

  data.map((item) => {
    const categories = [
      ...item?.user?.categories?.map((item) => item.name),
    ].join(",");
    dataSource.push({
      id: item.id,
      functions: categories,
      phone_number: item.phone_number,
      address: item.address,
      remark: item.remark,
      full_name: item.user.first_name + " " + item.user.last_name,
      email: item.email,
      category: item.category,
    });
  });

  return {
    columns,
    dataSource,
  };
};

export default AccountsTable;
