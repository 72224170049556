import React, { useState, useEffect, useRef } from "react";
import { Button, Popconfirm, Spin, message, TreeSelect, List } from "antd";
import css from "../../locations.module.scss";

import { EditTwoTone, DeleteTwoTone } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  DELETE_LOCATION,
  UPDATE_LOCATION,
} from "../../../../redux/modules/Machines/actions";
import nestedSorting from "../../../../helpers/NestedSorting";

import {getItemsWithouChilds} from "../../../../helpers/getitemsWithouChilds";
import ICON from '../../../../assets/icons/pin.png'

export default function Location({ item, locationTree, locations }) {
  // Variables
  const [editing, setEditing] = useState(false);
  const [newText, setNewText] = useState(item.name);
  const [parent, set_parent] = useState(null);

  useEffect(() => {
    let parent = locations.find(
      (x) => item.location_child && x.id === item.location_child.id
    );
    if (!parent || parent === undefined || parent === -1) {
      set_parent(null);
      return;
    }

    set_parent(parent);
  }, []);

  const { prepareRegistration } = useSelector((state) => state.machinesReducer);

  const user = useSelector((state) => state.usersReducer.user.data.category);
  const updateLocation = useSelector(
    (state) => state.machinesReducer.updateLocation
  );
  const deleteLocation = useSelector(
    (state) => state.machinesReducer.deleteLocation
  );

  const dispatch = useDispatch();

  // Methods

  function onBlur() {
    setEditing(false);
  }

  function successCallback() {
    onBlur();
    message.success(`Uspješno ste uredili lokaciju: ${item.name}`);
  }

  function handleSubmit() {
    if (newText !== "") {
      dispatch({
        type: UPDATE_LOCATION,
        location_id: item.id,
        data: {
          name: newText,
          location_child: parent ? parent.id : null,
        },
        successCallback,
        errorCallback: () =>
          message.error("Došlo je do pogreške prilikom uređivanja"),
      });
    } else {
      onBlur();
    }
  }

  function handleDelete() {
    dispatch({
      type: DELETE_LOCATION,
      location_id: item.id,
      successCallback: () =>
        message.success(`Uspješno ste izbrisali lokaciju: ${item.name}`),
      errorCallback: () =>
        message.error("Došlo je do pogreške prilikom brisanja"),
    });
  }

  const parent_id = parent ? parent.id : null;

  const text_input = editing ? (
    <Spin spinning={updateLocation.status === item.id}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <input
            id="textArea"
            // onBlur={onBlur}
            className={css.textArea}
            type="textarea"
            onChange={(event) => setNewText(event.target.value)}
            defaultValue={item.name}
          />
          <div></div>
          <TreeSelect
            style={{ minWidth: "270px" }}
            defaultValue={parent_id}
            onChange={(value) => {
              let parent = {};
              parent.id = !value ? null : value;
              console.log(parent);
              set_parent(parent);
            }}
            allowClear={true}
            placeholder="Odaberi nadlokaciju"
            treeData={nestedSorting(
              getItemsWithouChilds(locations, item.id, "location_child"),
              "location_child"
            )}
          />
          <div style={{ height: "12px" }}></div>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            onClick={handleSubmit}
            type="primary"
            style={{ alignSelf: "flex-start", marginRight: 20 }}
          >
            Uredi
          </Button>
          <Button
            onClick={onBlur}
            type="default"
            style={{ alignSelf: "flex-start" }}
          >
            Odustani
          </Button>
        </div>
      </div>
    </Spin>
  ) : (
    <p className={css.locationName}>{item.name}</p>
  );

  const childs = item.replies && item.replies.length && (
    <List
      style={{ marginLeft: "33px" }}
      itemLayout="horizontal"
      dataSource={item.replies}
      renderItem={(item) => (
        <Location
          item={item}
          locationTree={locationTree}
          locations={prepareRegistration.data.locations}
        />
      )}
    />
  );

  return (
    <>
      <Spin spinning={deleteLocation.status === item.id}>
        <div className={css.container}>
          <div className={css.info}>
            <img
              className={css.icon}
              src={ICON}
            />
            {text_input}
          </div>
          <div>
            {(user === "MAN" || user === "LDR") && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: 20,
                }}
              >
                <EditTwoTone
                  onClick={() => setEditing((prevState) => !prevState)}
                  style={{ fontSize: 24, cursor: "pointer", marginRight: 20 }}
                />
                <Popconfirm
                  id="delete_location"
                  title="Jeste li sigurni da želite izbrisati?"
                  placement="left"
                  okText="Izbriši"
                  cancelText="Odustani"
                  onConfirm={handleDelete}
                >
                  <DeleteTwoTone style={{ fontSize: 20, cursor: "pointer" }} />
                </Popconfirm>
              </div>
            )}
          </div>
        </div>
      </Spin>
      {childs !== 0 ? childs : null}
    </>
  );
}
