import React, { useState } from "react";

import SizeType from "../../helpers/deviceTypeSize";

import { Table, Button, Icon } from "antd";
import { Link } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";

const filters = [{ text: "Tehničar", value: "TEH" }];

class AccountsTable extends React.Component {
  state = {
    filteredInfo: null,
  };

  componentDidMount() {
    this.setPage();
  }

  setPage = () => {
    try {
      document
        .querySelector(
          `li.ant-pagination-item.ant-pagination-item-${this.props.page}`
        )
        .click();
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (pagination, filters) => {
    this.props.onPageChange(pagination.current);
    console.log(filters);
    this.setState({ filteredInfo: filters });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  render() {
    const tableData = StructAccountsForTable(
      this.props.data,
      this.filteredInfo || {},
      filters,
      this.props.toggleDrawer
    );

    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            zIndex: 10,
            alignItems: "center",
            paddingBottom: 16,
          }}
        >
          <Button style={{ marginRight: 18 }} onClick={this.clearFilters}>
            Očisti filtere <Icon type="delete" />
          </Button>
          {this.props.buttonAdd()}
        </div>
        <div>
          <Table
            rowKey={(record) => record.id}
            scroll={{
              x: SizeType() === "xs" || SizeType() === "sm" ? true : false,
            }}
            dataSource={tableData.dataSource}
            columns={tableData.columns}
            onChange={this.handleChange}
          />
        </div>
      </>
    );
  }
}

const getFitlers = (data) => {
  let categoryFilter = [
    { text: "Tehničar", value: "TEH" },
    { text: "Poslovođa", value: "MAN" },
  ];
  return categoryFilter;
};

const StructAccountsForTable = (data, filteredInfo, filters, callback) => {
  const columns = [
    {
      title: "Naziv",
      dataIndex: "full_name",
      key: "full_name",
      render: (text, record, index) => (
        <div>
          {/* <EditOutlined
            onClick={() => callback(true, record.id, true)}
            className={"hover"}
            style={{ fontSize: "18px", marginRight: "12px" }} 
          /> */}
          {"  "}
          <Link to={`/suppliers/${record.id}/about`}>{record.full_name}</Link>
        </div>
      ),
    },
    {
      title: "Funkcija",
      dataIndex: "categories",
      key: "categories",
    },
    {
      title: "Tel. broj",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Adresa",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Napomena",
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  const dataSource = [];

  data.map((item) => {
    const categories = [
      ...item.company.categories.map((item) => item.name),
    ].join(",");

    dataSource.push({
      id: item.id,
      categories,
      phone_number: item.phone_number || "-",
      address: item.address,
      remark: item.remark,
      full_name: item.company.first_name,
      email: item.email,
    });
  });

  return {
    columns,
    dataSource,
  };
};

export default AccountsTable;
