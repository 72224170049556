import React, { useEffect, useState } from "react";
import { Button, Drawer, Table, Input, Popconfirm, message, Spin } from "antd";
import moment from "moment";
import "moment/locale/hr";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import FormQuantity from "../../About/FormQuantity";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_PART_ENTERIES,
  REMOVE_PART_ENTERY,
} from "../../../../../redux/modules/Parts/actions";
import { useParams } from "react-router-dom";
import { LIMIT_FOR_LOAD_MORE } from "../../../../../helpers/consts";
import LoadMoreButton from "../../../../../components/LoadMoreButton";

export default function Entry({}) {
  const [editEntery, set_editEntery] = useState(null);
  const [search, set_search] = useState("");

  const dispatch = useDispatch();
  const { id } = useParams();

  const { getPartEnteries, removePartEntery } = useSelector(
    (state) => state.partsReducer
  );

  const removeSpent = (value) => {
    dispatch({
      type: REMOVE_PART_ENTERY,
      id: value.id,
      errorCallback: () => {
        message.error("Dogodila se greška kod brisanja...");
      },
    });
  };

  const getPartEnteriesFun = (next, restparams = {}) => {
    dispatch({
      type: GET_PART_ENTERIES,
      id,
      queryParams: {
        ...restparams,
        order_by: "id",
        sort: "desc",
        limit: LIMIT_FOR_LOAD_MORE,
      },
      dataToMerge: next === true ? [] : getPartEnteries.data.data,
      next: next === true ? null : next,
      errorCallback: () =>
        message.error("Dogodila s e greška kod dohvata podataka...", 3),
    });
  };

  useEffect(() => {
    if (getPartEnteries.data.cursor.next) {
      getPartEnteriesFun(getPartEnteries.data.cursor.next);
    }
  }, []);
  // Variables

  let dataSource = [];
  const columns = [
    {
      title: "Količina",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Cijena",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Dobavljač",
      dataIndex: "supplier",
      key: "supplier",
    },
    {
      title: "Ime unositelja",
      dataIndex: "creator",
      key: "creator",
    },

    {
      title: "Datum unosa",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Lokacija",
      dataIndex: "location",
    },
    {
      title: "",
      render: (text, value) => (
        <div style={{ textAlign: "center" }}>
          <Popconfirm
            onConfirm={() => {
              removeSpent(value);
            }}
            title="Potvrdi akciju"
            okText="Da"
            cancelText="Ne"
          >
            <Button
              loading={removePartEntery.status === "loading" + value.id}
              style={{ margin: 5 }}
            >
              <DeleteFilled />{" "}
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  dataSource = getPartEnteries.data.data.map((x, index) => ({
    key: index,
    id: x.id,
    creator: x.creator.name,
    price: x.price,
    supplier: x.supplier == null ? "-" : x.supplier.full_name,
    quantity: x.qty,
    created_at: moment(x.created_at).locale("hr").format("LLL") + "h",
    location: x.storage?.name,
    code: x.part?.code,
  }));

  let filtredData = [];

  if (!search) {
    filtredData = dataSource;
  } else {
    filtredData = dataSource.filter((x) =>
      x.creator.toLowerCase().includes(search.toLowerCase())
    );
  }

  return (
    <>
      <div style={{ textAlign: "right", paddingRight: 12, marginBottom: 21 }}>
        <Input.Search
          style={{ display: "inline-block", width: 320 }}
          allowClear={true}
          placeholder="Unesi ime unositelja"
          enterButton
          size="default"
          onPressEnter={(event) => set_search(event.target.value)}
          onSearch={(value) => {
            set_search(value);
          }}
        />
      </div>
      <Table pagination={true} columns={columns} dataSource={filtredData} />
      {getPartEnteries.data.cursor.next && (
        <LoadMoreButton
          loading={getPartEnteries.status === "loading"}
          onClick={() => getPartEnteriesFun(getPartEnteries.data.cursor.next)}
        />
      )}
      <Drawer
        title="Uredi utrošak"
        visible={!!editEntery}
        onClose={() => set_editEntery(false)}
        width={450}
      >
        <FormQuantity part={editEntery} update={true} />
      </Drawer>
    </>
  );
}
