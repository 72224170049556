import React, { Component } from "react";

import SizeType from "../../../../../helpers/deviceTypeSize";
import { getMaintenanceFormModel } from "../../../../../helpers/machines";

import ListToChoseMaterialForTask from "../../Instructions/components/ListToChoseMaterialForTask";

import { getApiEndpoint } from "../../../../../axios/endpoints";
import axios from "axios";

import Spinner from "../../../../../components/LoadingScreen";
import nestedSorting from "../../../../../helpers/NestedSorting";

import { withRouter } from "react-router";

import {
  Alert,
  Form,
  Row,
  Col,
  Input,
  InputNumber,
  Button,
  Select,
  List,
  DatePicker,
  TimePicker,
  Modal,
  Checkbox,
  Radio,
  TreeSelect,
  message,
  Typography,
  Upload,
} from "antd";

import moment from "moment";

import {
  EyeInvisibleOutlined,
  EyeOutlined,
  FileProtectOutlined,
  PlusCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Axios from "axios";
import { AddTag } from "../../SubAssets";
import { ALLOWED_EXTENSIONS } from "../../../../../helpers/consts";
import { CONTROL_ID } from "../../../../../AppWrapper";

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

const { TextArea } = Input;
const { Option } = Select;

const UPDATE_TYPE = [
  {
    name: "Nalog",
    value: 1,
  },
  {
    name: "Template",
    value: 2,
  },
  {
    name: "Sve",
    value: 3,
  },
];

const expireTimeType = [
  { value: "hour", name: "Sati" },
  { value: "day", name: "Dani" },
  { value: "month", name: "Mjeseci" },
  { value: "year", name: "Godine" },
];

class Maintenance extends Component {
  state = {
    orderFiles: [],
    addSubAssetVisible: false,
    sameOrderNameVisible: false,
    alertSuccessVisible: false,
    tasks_visible: true,
    tasks: [],
    fileList: [],
    photoList: [],
    videoList: [],
    toggleMaterialModalVisible: false,
    taskForMaterials: {},
    machine_run: true,
    materialsForUpdateOrder: {
      status: "",
      data: {},
    },
    warningModalVisible: 0, //kada je nalog u tijeku ukoliko se promjeni glavni tehničar nalog ide u pausu
  };

  toggleMaterialModal = (bool, taskForMaterials) => {
    if (bool) {
      this.setState({ toggleMaterialModalVisible: true, taskForMaterials });
      return;
    }
    this.setState({ toggleMaterialModalVisible: false, taskForMaterials });
  };

  toggleWarningModal = (bool) => {
    if (
      this.props.order.data?.status === "in_progress" &&
      bool &&
      this.state.warningModalVisible !== 2
    ) {
      this.setState({ warningModalVisible: 1 });
      return;
    }

    this.setState({ warningModalVisible: 2 });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let tmpStartDate;
        if (
          this.props.form.getFieldValue("update_type") !== 2 &&
          values.start_date &&
          values.start_time
        ) {
          tmpStartDate = values.start_date.format("YYYY-MM-DD");

          values.planned_start = moment(tmpStartDate)
            .add(
              +values.start_time.hours() * 60 + +values.start_time.minutes(),
              "m"
            )
            .format("YYYY-MM-DD HH:mm");
          values.planned_end = moment(values.planned_start)
            .add(
              +values.estimated_time * 60 + +values.estimated_time_minute,
              "m"
            )
            .format("YYYY-MM-DD HH:mm");
        }

        const tasksForBackend = [];

        for (let i = 0; i < this.state.tasks.length; i++) {
          tasksForBackend.push(prepareTasksForBackend(this.state.tasks[i]));
        }

        const data = {
          name: values.name,
          description: values.description || "",
          tags: values.subassets || [],
          planned_start:
            values.planned_start || moment().format("YYYY-MM-DD HH:mm"),
          planned_end: values.planned_end,
          categories: values.categories,
          type: values.type,
          items: [values.asset],
          tasks: tasksForBackend,
          technicians: values.technician
            ? [
                { account: values.technician, is_primary: 1 },
                ...values.sub_technicians.map((item) => ({
                  account: item,
                  is_primary: 0,
                })),
              ]
            : [],
          every: values.repeat_time || null,
          period: values.repeat_type,
          estimated_time: values.estimated_time || null,
          estimated_time_minute: values.estimated_time_minute || null,
          schedule: this.props.update ? this.props.order.data.schedule : null,
          update_type: values.update_type,
          machine_run: this.state.machine_run,
          work_capacity: values.work_capacity,
          production_work_order: values.production_work_order,
        };

        if (this.props.update) {
          let updateFormData = new FormData();
          updateFormData.append("data", JSON.stringify(data));
          this.props._updateWorkOrder(
            this.props.match.params.id,
            updateFormData,
            this.showSuccess.bind(this)
          );
          return;
        }

        let formData = new FormData();
        formData.append("data", JSON.stringify(data));

        // this.state.fileList.forEach((item) => {
        //   formData.append("files", item);
        // });
        // this.state.photoList.forEach((item) => {
        //   formData.append("files", item);
        // });

        // this.state.videoList.forEach((item) => {
        //   formData.append("files", item);
        // });

        this.state.orderFiles?.forEach((x) => {
          formData.append("order_files", x);
        });

        this.props.saveOrder(formData, this.showSuccess.bind(this));
      }
    });
  };

  getMaterialsForUpdate = () => {
    this.setState({
      materialsForUpdateOrder: {
        status: "loading",
        data: {},
      },
    });

    axios
      .get(
        `${getApiEndpoint()}items/items/${this.props.order.data.items[0].id}/`
      ) // niz zato sto jedan nalog moze imati vise itema
      .then((res) => {
        this.setState({
          materialsForUpdateOrder: {
            status: "",
            data: res.data,
          },
        });
      })
      .catch((err) => {
        this.setState({
          materialsForUpdateOrder: {
            status: "error",
            data: {},
          },
        });
      });
  };

  componentDidMount() {
    this.setState({
      tasks: this.props.update ? prepareTasks(this.props.order.data.tasks) : [],
      machine_run: this.props.update ? this.props.order.data.machine_run : true,
    });

    if (this.props.update) {
      this.getMaterialsForUpdate();
      this.getTagsOfAsset(this.props.order.data.items[0]?.id);
    }

    if (this.props.assetSelected && !this.props.update) {
      this.setState({ pickedAsset: this.props.asset?.id });
      this.getTagsOfAsset(this.props.asset?.id);
    }
  }

  getTagsOfAsset = async (asset_id) => {
    this.setState({ loadingTags: true });

    let token = await localStorage.getItem("token");

    try {
      let response = await Axios.get(
        getApiEndpoint() + "items/tags/?asset=" + asset_id,
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      );
      this.props.onSetTags(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        this.props.onDestroyToken();
        return;
      }

      message.error("Dogodila se greska kod dohvata podimovine");
    }

    this.setState({ loadingTags: false });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.order.data !== this.props.order.data) {
      this.setState({ tasks: this.props.order.data.tasks });
    }
  }

  toggletasks = () => {
    this.setState({
      tasks_visible: !this.state.tasks_visible,
    });
  };

  isExsitsName = () => {
    if (this.props.update) {
      return false;
    }
    const check = this.props.orders.data.find(
      (item) =>
        item.name === this.props.form.getFieldValue("name") &&
        item.id === parseInt(this.props.match.params.id)
    );
    if (check !== undefined && check !== -1) {
      this.setState({ sameOrderNameVisible: true });
      return true;
    } else {
      this.setState({ sameOrderNameVisible: false });
      return false;
    }
  };

  setMaterials = (task_id, materials) => {
    const tasks = [...this.state.tasks];
    const taskIndex = tasks.findIndex((item) => item.id === task_id);
    const task = { ...tasks[taskIndex] };

    task.materials = materials;

    tasks[taskIndex] = task;

    this.setState({ tasks, taskForMaterials: task });
  };

  addtaskToList = () => {
    const data = {
      title: "",
      description: this.props.form.getFieldValue("task"),
      id: new Date().getTime(),
      materials: [],
    };
    let tmp = [...this.state.tasks];
    tmp.push(data);
    setTimeout(() => {
      this.setState({ tasks: tmp });
      this.props.form.resetFields(["task_title", "task"]);
    }, 33);
  };

  removeTask = (id) => {
    let tmp = [...this.state.tasks];
    let index = tmp.find((item) => item.id === id);
    if (index !== undefined && index !== -1) {
      tmp.splice(index, 1);
      this.setState({ tasks: tmp });
    }
  };

  showSuccess = () => {
    this.setState({
      alertSuccessVisible: true,
      tasks: [],
      fileList: [],
      photoList: [],
      videoList: [],
    });
    this.props.form.resetFields();
    this.props.openCloseMaintenaceForm(false);
  };

  beforeUpload = (file) => {
    this.setState((state) => ({
      fileList: [...state.fileList, file],
    }));
    return false;
  };

  removeFile = (file) => {
    let fileList = this.state.fileList.concat();
    const index = fileList.indexOf(file);
    fileList.splice(index, 1);
    this.setState({ fileList, fileList });
  };

  beforeUploadPhoto = (file) => {
    this.setState((state) => ({
      photoList: [...state.photoList, file],
    }));

    return false;
  };

  removeFilePhoto = (file) => {
    let photoList = this.state.photoList.concat();
    const index = photoList.indexOf(file);
    photoList.splice(index, 1);
    this.setState({ photoList: [...this.state.photoList] });
    setTimeout(() => {
      this.forceUpdate();
    }, 36);
  };

  beforeUploadVideo = (file) => {
    this.setState((state) => ({
      videoList: [...state.videoList, file],
    }));
    return false;
  };

  removeFileVideo = (file) => {
    let videoList = this.state.videoList.concat();
    const index = videoList.indexOf(file);
    videoList.splice(index, 1);
    this.setState({ videoList: [...this.state.videoList] });
    setTimeout(() => {
      this.forceUpdate();
    }, 36);
  };

  technicianSelected = (id) => {
    if (this.props.form.getFieldValue("sub_technicians").includes(id)) {
      const sub_technicians = [
        ...this.props.form.getFieldValue("sub_technicians"),
      ];
      let index = sub_technicians.findIndex((x) => x === id);
      sub_technicians.splice(index, 1);
      this.props.form.setFieldsValue({ sub_technicians });
    }
  };

  render() {
    const { getFieldDecorator, getFieldsError, setFieldsValue } =
      this.props.form;

    const alert = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1", maxWidth: "600px" }}
        message="Dogodila se greška"
        description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
        type="error"
      />
    );

    const alertSuccess = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1" }}
        message="Uspješno"
        description="Uspješno!"
        type="success"
      />
    );

    const alertSameLocation = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1" }}
        message="Dogodila se greška"
        description="Nalog sa ovim imenom već postoji!"
        type="error"
      />
    );

    const formItemLayout = {
      labelCol: {
        span: 6,
      },
      wrapperCol:
        SizeType() === "xs" || SizeType() === "sm"
          ? {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 24,
                offset: 0,
              },
            }
          : {
              span: 18,
              offset: 0,
            },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };
    const layoutType =
      SizeType() === "xs" || SizeType() === "sm" ? "vertical" : "horizontal";

    const {
      prepareRegistration,
      workOrder,
      updateWorkOrder,
      maintenance_for,
      update,
      order,
      planned_start,
      machines,
      user,
    } = this.props;

    const { materialsForUpdateOrder } = this.state;

    const maintenanceFormModel = getMaintenanceFormModel(order.data, {
      name: maintenance_for,
      update: update,
      assetSelected: this.props.assetSelected ? this.props.asset : null,
    });


    let pickedAssetForType =  machines.find(x => x.id === this.state.pickedAsset);

    if (materialsForUpdateOrder.status === "loading") {
      return <Spinner />;
    }

    const additionalAddTagButton = (
      <Select.OptGroup
        label={
          <div
            className="hover"
            onClick={() => this.setState({ addSubAssetVisible: true })}
            style={{ textAlign: "center", padding: "6px 12%" }}
          >
            <PlusCircleOutlined style={{ fontSize: 27 }} />
          </div>
        }
      >
        <Option style={{ display: "none" }} value={0} key={"fghjkghj"}>
          fe
        </Option>
      </Select.OptGroup>
    );




    return (
      <React.Fragment>
        {/* <h1>{ machinesForm ? "MACHINE" : "CALENDAR" }</h1> */}
        {materialsForUpdateOrder.status === "error" ? (
          <Alert
            style={{ marginBottom: "10px", zIndex: "1" }}
            message="Dogodila se greška"
            description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
            type="error"
          />
        ) : null}

        <div>
          {this.state.alertSuccessVisible ? alertSuccess : null}
          {this.state.sameOrderNameVisible ? alertSameLocation : null}
          <Form
            {...formItemLayout}
            layout={"vertical"}
            hideRequiredMark={false}
            onSubmit={this.handleSubmit}
          >
            <Row type="flex" justify="start" gutter={16}>
              <Col span={24}>
                <Form.Item label="Imovina:">
                  {getFieldDecorator("asset", {
                    initialValue: maintenanceFormModel.asset,
                    rules: [
                      {
                        required: true,
                        message: "Potrebno je odabrat kategoriju",
                      },
                    ],
                  })(
                    <TreeSelect
                      disabled={this.props.assetSelected}
                      placeholder="Please select"
                      showSearch
                      multiple={false}
                      allowClear={true}
                      onSelect={(id) => {
                        this.getTagsOfAsset(id);
                        this.props.form.setFieldsValue({categories: []});
                        this.setState({ pickedAsset: id });
                      }}
                      treeData={nestedSorting(machines, "parent")}
                      optionFilterProp="title"
                      filterTreeNode={(input, option) => {
                        return (
                          option.props.name
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    />
                  )}
                </Form.Item>

                <Form.Item label="Podimovina:">
                  {getFieldDecorator("subassets", {
                    initialValue: maintenanceFormModel.subassets,
                  })(
                    <Select
                      allowClear={true}
                      disabled={!this.props.form.getFieldValue("asset")}
                      notFoundContent={additionalAddTagButton}
                      optionFilterProp="name"
                      mode="multiple"
                    >
                      {this.props.subassets?.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                      {additionalAddTagButton}
                    </Select>
                  )}
                </Form.Item>

                {/* NAZIV */}
                <Form.Item label="Opis:">
                  {getFieldDecorator("name", {
                    initialValue: maintenanceFormModel.name,
                    rules: [
                      { required: true, message: "Unesite opis imovine" },
                      { min: 3, message: "Minimalno znakova 3!" },
                    ],
                  })(<Input.TextArea placeholder="Unesite opis" />)}
                </Form.Item>
                <Form.Item label="Radni nalog proizvodnje:">
                  {getFieldDecorator("production_work_order", {
                    initialValue: maintenanceFormModel.production_work_order,
                  })(<Input placeholder="Unesite nalog proizvodnje" />)}
                </Form.Item>
                <Modal
                  footer={null}
                  title="Dodaj"
                  visible={this.state.addSubAssetVisible}
                  onCancel={() => this.setState({ addSubAssetVisible: false })}
                >
                  <AddTag
                    close={() => this.setState({ addSubAssetVisible: false })}
                    tag={{ id: 0, name: "" }}
                    asset_id={this.state.pickedAsset || this.props.asset?.id}
                  />
                </Modal>

                {/* KATEGORIJA ODRŽAVANJA */}

                <Form.Item label="Kategorija:">
                  {getFieldDecorator("categories", {
                    initialValue: maintenanceFormModel.categories,
                    rules: [
                      {
                        required: true,
                        message: "Potrebno je odabrat kategoriju",
                      },
                    ],
                  })(
                    <Select
                      placeholder="Unesite kategoriju"
                      mode="multiple"
                      allowClear={true}
                      onSelect={(id) => {
                        setFieldsValue({ categories: [id] });
                      }}
                      showSearch
                      optionFilterProp="name"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {filterCategoriesBasedOnAsset(
                        prepareRegistration.maintenance_categories,
                        user.data?.items_categories?.[pickedAssetForType?.parent_id || pickedAssetForType?.id]
                      ).map((item) => (
                        <Option key={item.id + "_!"} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>

                {/* TIP ODRŽAVANJA */}
                <Form.Item label="Tip:">
                  {getFieldDecorator("type", {
                    rules: [{ required: true, message: "Unesite tip!" }],
                    initialValue: maintenanceFormModel.type,
                  })(
                    <Select
                      disabled={this.props.update}
                      mode="single"
                      allowClear={true}
                      showSearch
                      placeholder="Odaberi tip"
                      optionFilterProp="name"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {prepareRegistration.maintenance_types.map((item) => (
                        <Option key={item.id + "_!"} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>

                {/* Plabirani početak */}
                {this.props.form.getFieldValue("update_type") !== 2 ? (
                  <Form.Item label="Planirani početak">
                    <Input.Group compact="" style={{ display: "flex" }}>
                      {/* Planirani početak rada */}
                      <Form.Item style={{ width: "calc(100% - 200px)" }}>
                        {getFieldDecorator("start_date", {
                          initialValue: maintenanceFormModel.planned_start
                            ? moment(maintenanceFormModel.planned_start)
                            : planned_start
                            ? moment(planned_start, "YYYY-MM-DD")
                            : null,
                        })(
                          <DatePicker
                            disabledDate={(current) => {
                              return moment().add(-1, "days") >= current;
                            }}
                            format="YYYY-MM-DD"
                            placeholder="Odaberi planirani datum rada"
                          />
                        )}
                      </Form.Item>
                      <Form.Item
                        style={{
                          width: "200px",
                          position: "relative",
                          top: "-4px",
                        }}
                      >
                        {getFieldDecorator("start_time", {
                          initialValue: maintenanceFormModel.planned_start
                            ? moment(maintenanceFormModel.planned_start)
                            : null,
                        })(
                          <TimePicker
                            minuteStep={5}
                            placeholder="Vrijeme"
                            format="HH:mm"
                          />
                        )}
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                ) : null}

                {/* OPIS */}
                {/* <Form.Item label="Opis:">
                  {getFieldDecorator("description", {
                    initialValue: maintenanceFormModel.description,
                    rules: [],
                  })(<TextArea placeholder="Unesite opis" />)}
                </Form.Item> */}

                {/* TASKS */}
                <Form.Item
                  style={{ marginBottom: 0, paddingBottom: 0, display: "none" }}
                  label="Zadatak:"
                >
                  {getFieldDecorator("task_title")(
                    <Input
                      style={{ width: "86%", minWidth: "230px" }}
                      placeholder="Naslov zadatka"
                    />
                  )}
                </Form.Item>

                <Form.Item className="dispalyNoneLabel" label=" ">
                  {getFieldDecorator("task")(
                    <TextArea
                      rows={5}
                      style={{
                        width: "86%",
                        minWidth: "230px",
                        marginTop: "6px",
                      }}
                      placeholder="Zadatak"
                    />
                  )}
                  <Button
                    disabled={!this.props.form.getFieldValue("task")}
                    style={{
                      position: "relative",
                      bottom: "21px",
                      left: "9px",
                    }}
                    onClick={() => this.addtaskToList()}
                    type="primary"
                    icon="plus"
                  />
                  <div></div>
                  {this.state.tasks.length === 0 ? null : (
                    <List
                      style={{
                        paddingTop: "9px",
                        maxHeight: "309px",
                        overflowY: "auto",
                      }}
                      header={
                        <div>
                          <span>Zadaci</span>{" "}
                          <span style={{ marginLeft: "9px", color: "#1890ff" }}>
                            {this.state.tasks.length}
                          </span>
                          <Button
                            style={{ float: "right", bottom: "6px" }}
                            onClick={this.toggletasks}
                          >
                            {this.state.tasks_visible ? (
                              <EyeInvisibleOutlined />
                            ) : (
                              <EyeOutlined />
                            )}
                          </Button>
                        </div>
                      }
                      dataSource={this.state.tasks}
                      renderItem={(item) => (
                        <List.Item
                          style={{
                            display: this.state.tasks_visible ? "" : "none",
                          }}
                          extra={
                            <>
                              <Button
                                icon="delete"
                                style={{ marginRight: "9px" }}
                                onClick={() => this.removeTask(item.id)}
                              />
                              {/* <Button
                                icon="file"
                                onClick={() =>
                                  this.toggleMaterialModal(true, item)
                                }
                              /> */}
                            </>
                          }
                        >
                          <List.Item.Meta
                            description={
                              <div
                                style={{ paddingLeft: "9px" }}
                                href={item.task}
                                target="_blank"
                              >
                                <h4>{item.title}</h4>
                                <span>{item.description}</span>
                              </div>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  )}
                </Form.Item>

                {/* Tehničari */}
                <Form.Item
                  style={{
                    display:
                      this.props.form.getFieldValue("type") !== CONTROL_ID
                        ? ""
                        : "none",
                  }}
                  label="Izvršitelj:"
                >
                  {getFieldDecorator("technician", {
                    initialValue: maintenanceFormModel.technician,
                  })(
                    <Select
                      onChange={this.toggleWarningModal}
                      onSelect={this.technicianSelected}
                      mode="single"
                      allowClear={true}
                      showSearch
                      placeholder="Odaberi tehničara"
                      optionFilterProp="name"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {prepareRegistration.accounts.map((item) => (
                        <Option key={item.id + "_!"} value={item.id}>
                          {item.full_name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>

                {/* Dodatni tehničari */}
                <Form.Item
                  style={{
                    display:
                      this.props.form.getFieldValue("type") !== CONTROL_ID
                        ? ""
                        : "none",
                  }}
                  label="Dodatni izvršitelji:"
                >
                  {getFieldDecorator("sub_technicians", {
                    initialValue: maintenanceFormModel.sub_technicians,
                  })(
                    <Select
                      disabled={!this.props.form.getFieldValue("technician")}
                      mode="multiple"
                      allowClear={true}
                      showSearch
                      placeholder="Odaberi izvršitelja"
                      optionFilterProp="name"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {prepareRegistration.accounts
                        .filter(
                          (item) =>
                            this.props.form.getFieldValue("technician") !==
                            item.id
                        )
                        .map((item) => (
                          <Option key={item.id + "_!"} value={item.id}>
                            {item.full_name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
                {this.props.update && (
                  <Form.Item
                    style={{
                      display:
                        this.props.form.getFieldValue("type") === 1
                          ? ""
                          : "none",
                    }}
                    label="Tip uređivanja"
                  >
                    {getFieldDecorator("update_type", {
                      initialValue: 1,
                      rules: [],
                    })(
                      <Radio.Group buttonStyle="solid">
                        {UPDATE_TYPE.map((item) => (
                          <Radio.Button key={item.value} value={item.value}>
                            {item.name}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    )}
                  </Form.Item>
                )}
                {this.props.form.getFieldValue("update_type") !== 1 &&
                (this.props.form.getFieldValue("type") === 1 ||
                  this.props.form.getFieldValue("type") === CONTROL_ID) ? (
                  <Form.Item required={this.props.update} label={"Ponavljaj"}>
                    <Input.Group compact="" style={{ display: "flex" }}>
                      <Form.Item style={{ width: "calc(100% - 100px)" }}>
                        {getFieldDecorator("repeat_time", {
                          initialValue: maintenanceFormModel.repeat_time,
                          rules: [
                            {
                              required: !!this.props.update,
                              message: "Ovo polje je obavezno",
                            },
                          ],
                        })(
                          <Input
                            placeholder="Unesi period ponavljanja"
                            type="number"
                          />
                        )}
                      </Form.Item>
                      <Form.Item
                        style={{
                          width: "100px",
                          position: "relative",
                          top: "-4px",
                        }}
                      >
                        {getFieldDecorator("repeat_type", {
                          initialValue:
                            maintenanceFormModel.maintance_time_type,
                          rules: [
                            {
                              required: !!this.props.update,
                              message: "Ovo polje je obavezno",
                            },
                          ],
                        })(
                          <Select
                            mode="single"
                            allowClear={true}
                            showSearch
                            style={{ width: "100px", marginTop: "4px" }}
                            placeholder="Odaberi tip ponavljanja"
                            optionFilterProp="name"
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {expireTimeType.map((item) => (
                              <Option key={item.name + "_!"} value={item.value}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                ) : null}
                {/* Planirano vrijeme trajanja */}
                <Form.Item label={"Trajanje"}>
                  <Input.Group compact="" style={{ display: "flex" }}>
                    <Form.Item>
                      {getFieldDecorator("estimated_time", {
                        initialValue: maintenanceFormModel.estimated_time,
                        rules: [],
                      })(
                        <Input min={0} type={"number"} placeholder={"Sati"} />
                      )}
                    </Form.Item>
                    <Form.Item>
                      {getFieldDecorator("estimated_time_minute", {
                        initialValue:
                          maintenanceFormModel.estimated_time_minute,
                        rules: [],
                      })(
                        <Input
                          min={0}
                          max={60}
                          type={"number"}
                          placeholder={"Minute"}
                        />
                      )}
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item label="Imovina aktivna">
                  <Checkbox
                    checked={this.state.machine_run}
                    onChange={() => {
                      this.setState({ machine_run: !this.state.machine_run });
                    }}
                  />
                </Form.Item>
                {this.state.machine_run && (
                  <Form.Item label="Kapacitet imovine">
                    {getFieldDecorator("work_capacity", {
                      initialValue: maintenanceFormModel.work_capacity,
                    })(
                      <InputNumber
                        style={{ width: "100px" }}
                        min={0}
                        max={100}
                        placeholder={""}
                      />
                    )}
                    <span style={{ marginLeft: "6px" }}> %</span>
                  </Form.Item>
                )}

                {workOrder.status === "error" ||
                updateWorkOrder.status === "error"
                  ? alert
                  : null}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    flexDirection: "row",
                  }}
                >
                  <div>
                    {!this.props.update && (
                      <Upload
                        beforeUpload={(file, files) => {
                          this.setState({ orderFiles: files });
                          return false;
                        }}
                        multiple={true}
                        accept={
                          ALLOWED_EXTENSIONS.find((x) => x.type === "document")
                            ?.extensions
                        }
                      >
                        <Button>
                          Učitaj
                          <UploadOutlined />
                        </Button>
                      </Upload>
                    )}
                  </div>
                  <div>
                    <Form.Item {...tailFormItemLayout}>
                      <Button
                        style={{ float: "right" }}
                        loading={
                          workOrder.status === "loading" ||
                          updateWorkOrder.status === "loading"
                        }
                        type="primary"
                        htmlType="submit"
                        disabled={hasErrors(getFieldsError())}
                      >
                        Spremi
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>

          {/* <Modal
            closable
            destroyOnClose={true}
            style={{ padding: "21px" }}
            onCancel={() => this.toggleWarningModal(false)}
            footer={null}
            visible={this.state.warningModalVisible === 1}
          >
            <Alert
              style={{ marginTop: "18px" }}
              message="Upozorenje"
              description="Ukoliko promijeniš glavnog tehničara dok je nalog u tijeku, nalog ce preći u status na čekanju!"
              type="warning"
            />
          </Modal> */}

          <Modal
            width={"98%"}
            closable
            destroyOnClose={true}
            onCancel={() => this.toggleMaterialModal(false)}
            footer={null}
            visible={this.state.toggleMaterialModalVisible}
          >
            {/* <ListToChoseMaterialForTask
              documents={
                this.props.update
                  ? this.state.materialsForUpdateOrder.data.documents
                  : order.data.documents
              }
              relevant_links={
                this.props.update
                  ? this.state.materialsForUpdateOrder.data.relevant_links
                  : order.data.links
              }
              videos={
                this.props.update
                  ? this.state.materialsForUpdateOrder.data.videos
                  : order.data.videos
              }
              images={
                this.props.update
                  ? this.state.materialsForUpdateOrder.data.images
                  : order.data.images
              }
              task={this.state.taskForMaterials}
              setMaterials={this.setMaterials}
            /> */}
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Form.create()(Maintenance));

export const filterCategoriesBasedOnAsset = (
  categories,
  allowed_categories
) => {
  if (!allowed_categories) {
    return categories;
  }
  return categories.filter((item) => allowed_categories.includes(item.id));
};

const prepareTasksForBackend = (task) => {
  let i_ids,
    v_ids,
    d_ids,
    l_ids = [];

  i_ids = task.materials
    .filter((item) => item.split("_")[0] === "i")
    .map((item) => +item.split("_")[1]);
  v_ids = task.materials
    .filter((item) => item.split("_")[0] === "v")
    .map((item) => +item.split("_")[1]);
  d_ids = task.materials
    .filter((item) => item.split("_")[0] === "d")
    .map((item) => +item.split("_")[1]);
  l_ids = task.materials
    .filter((item) => item.split("_")[0] === "l")
    .map((item) => +item.split("_")[1]);

  const data = {
    ...task,
    i_ids,
    v_ids,
    d_ids,
    l_ids,
  };

  return data;
};

const prepareTasks = (tasks) => {
  for (let i = 0; i < tasks.length; i++) {
    tasks[i].materials = [
      ...tasks[i].documents.map((item) => "d_" + item.id),
      ...tasks[i].videos.map((item) => "v_" + item.id),
      ...tasks[i].links.map((item) => "l_" + item.id),
      ...tasks[i].images.map((item) => "i_" + item.id),
    ];
  }

  return tasks;
};

const checkCanClear = (x) => {
  if (x === undefined) {
    return false;
  }

  if (x.length > 0) {
    return false;
  }

  return true;
};
