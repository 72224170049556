import React, { Component } from "react";
import SizeType from "../../../../helpers/deviceTypeSize";

import DeleteIcon from "../../../../components/deleteIcon";

import MaterialForm from "./components/AddForm";

import * as actions from "../../../../redux/modules/Machines/actions";
import { getApiEndpoint } from "../../../../axios/endpoints";

import { Row, Col, Button, Typography, List, Tooltip, Alert } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import { connect } from "react-redux";

import css from "../../machine.module.scss";

const { Title } = Typography;

class Instructions extends Component {
  state = {
    formVisible: {
      visible: true,
      type: "image",
    },
    formType: "",
  };

  toggleMaterialForm = (materialFormVisible, formType) => {
    if (materialFormVisible) {
      this.setState({ materialFormVisible, formType });
      return;
    }
    this.setState({ materialFormVisible, formType: "" });
  };

  toggleMaterial_Mob = (bool, type) => {
    this.setState({
      formVisible: {
        visible: bool,
        type,
      },
    });
  };

  deleteMaterial = (item_id, material_id, topic) => {};

  // Link needs to have http as prefix to correctly redirect
  formatLink = (link) =>
    link.startsWith("http://") || link.startsWith("https://")
      ? link
      : `http://${link}`;

  render() {
    const mob = SizeType() === "xs" || SizeType() === "sm" ? true : false;

    const alert = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1" }}
        message="Dogodila se greša"
        description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
        type="error"
      />
    );

    let {
      images,
      videos,
      documents,
      relevant_links,
      deleteMaterial,
    } = this.props;

    return (
      <div>
        {this.state.materialFormVisible ? (
          <MaterialForm
            toggleMaterialForm={this.toggleMaterialForm}
            formType={this.state.formType}
            material={this.props.material}
            _addMaterial={this.props.onAddMaterial}
            id={this.props.id}
          />
        ) : (
          <>
            <Row>
              <Col>
                <div style={{ maxWidth: "600px" }}>
                  {deleteMaterial.status === "error" ? alert : null}
                </div>
              </Col>
            </Row>
            <Row style={{ marginBottom: 64 }}>
              <Col sm={24} xs={24} md={12} style={{ paddingRight: 40 }}>
                <Title level={4}>
                  <div className={css.instructionsTitleContainer}>
                    <div>
                      <h1 className={css.instructionsTitleMainText}>
                        Dokumenti
                      </h1>
                      <h4 className={css.instructionsTitleSecondaryText}>
                        {documents.length
                          ? `Ukupno (${documents.length}) dokumenata`
                          : "Nema materijala u vidu dokumenata"}
                      </h4>
                    </div>
                    <div
                      style={{
                        display: this.props.openOrder && "none",
                        overflow: "hidden",
                      }}
                    >
                      <div style={{ float: "right" }}>
                        <Button
                          onClick={() =>
                            this.toggleMaterialForm(true, "document")
                          }
                          type={"primary"}
                        >
                          Dodaj
                        </Button>
                      </div>
                    </div>
                  </div>
                </Title>
                {!mob ||
                (this.state.formVisible.visible &&
                  this.state.formVisible.type === "document") ? (
                  <>
                    <List
                      style={{
                        width: "100%",
                        height: "27vh",
                        overflow: "auto",
                      }}
                      dataSource={documents}
                      renderItem={(item) => (
                        <List.Item
                          style={{ display: "flex", alignItems: "flex-start" }}
                          extra={
                            <DeleteIcon
                              loading={
                                deleteMaterial.status === item.id + "document"
                              }
                              _deleteMaterial={this.props.onDeleteMaterial}
                              data={{
                                item_id: this.props.id,
                                material_id: item.id,
                                topic: "document",
                              }}
                            />
                          }
                        >
                          <List.Item.Meta
                            description={
                              <a
                                style={{ overflow: "hidden" }}
                                href={getApiEndpoint() + item.path}
                                target="_blank"
                              >
                                {item.name}
                              </a>
                            }
                          />{" "}
                        </List.Item>
                      )}
                    />{" "}
                  </>
                ) : null}
                {SizeType() === "sm" || SizeType() === "xs" ? (
                  <div
                    className={css.openCloseButton}
                    onClick={() =>
                      this.toggleMaterial_Mob(
                        !this.state.formVisible.visible,
                        "document"
                      )
                    }
                  >
                    {this.state.formVisible.visible &&
                    this.state.formVisible.type === "document"
                      ? "Zatvori"
                      : "Otvori"}
                  </div>
                ) : null}
              </Col>
              <Col sm={24} xs={24} md={12} style={{ paddingRight: 40 }}>
                <Title level={4}>
                  <div className={css.instructionsTitleContainer}>
                    <div>
                      <h1 className={css.instructionsTitleMainText}>
                        Poveznice
                      </h1>
                      <h4 className={css.instructionsTitleSecondaryText}>
                        {relevant_links.length
                          ? `Ukupno (${relevant_links.length}) poveznica`
                          : "Nema materijala u vidu poveznica"}
                      </h4>
                    </div>
                    <div
                      style={{
                        display: this.props.openOrder && "none",
                        overflow: "hidden",
                      }}
                    >
                      <Tooltip
                        placement="topRight"
                        title="Poveznice se dodaju kod uređivanja imovine."
                      >
                        <QuestionCircleOutlined
                          className={css.instructionsRelevantLinks}
                        />
                      </Tooltip>
                    </div>
                  </div>
                </Title>
                {!mob ||
                (this.state.formVisible.visible &&
                  this.state.formVisible.type === "link") ? (
                  <>
                    <div style={{ overflow: "hidden" }}></div>
                    <List
                      style={{
                        width: "100%",
                        height: "27vh",
                        overflow: "auto",
                      }}
                      dataSource={relevant_links}
                      renderItem={(item) => (
                        <List.Item>
                          <a target="_blank" href={this.formatLink(item.link)}>
                            {item.name}
                          </a>
                        </List.Item>
                      )}
                    />{" "}
                  </>
                ) : null}
                {SizeType() === "sm" || SizeType() === "xs" ? (
                  <div
                    className={css.openCloseButton}
                    onClick={() =>
                      this.toggleMaterial_Mob(
                        !this.state.formVisible.visible,
                        "link"
                      )
                    }
                  >
                    {this.state.formVisible.visible &&
                    this.state.formVisible.type === "link"
                      ? "Zatvori"
                      : "Otvori"}
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row style={{ marginBottom: 64 }}>
              <Col sm={24} xs={24} md={12} style={{ paddingRight: 40 }}>
                <Title level={4}>
                  <div className={css.instructionsTitleContainer}>
                    <div>
                      <h1 className={css.instructionsTitleMainText}>
                        Videozapisi
                      </h1>
                      <h4 className={css.instructionsTitleSecondaryText}>
                        {videos.length
                          ? `Ukupno (${videos.length}) videozapisa`
                          : "Nema materijala u vidu videozapisa"}
                      </h4>
                    </div>
                    <div
                      style={{
                        display: this.props.openOrder && "none",
                        overflow: "hidden",
                      }}
                    >
                      {" "}
                      <div>
                        <div style={{ float: "right" }}>
                          <Button
                            onClick={() =>
                              this.toggleMaterialForm(true, "video")
                            }
                            type={"primary"}
                          >
                            Dodaj
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Title>
                {!mob ||
                (this.state.formVisible.visible &&
                  this.state.formVisible.type === "video") ? (
                  <>
                    <List
                      style={{
                        width: "100%",
                        height: "27vh",
                        overflow: "auto",
                      }}
                      dataSource={videos}
                      renderItem={(item) => (
                        <List.Item
                          style={{ display: "flex", alignItems: "flex-start" }}
                          extra={
                            <DeleteIcon
                              loading={
                                deleteMaterial.status === item.id + "video"
                              }
                              _deleteMaterial={this.props.onDeleteMaterial}
                              data={{
                                item_id: this.props.id,
                                material_id: item.id,
                                topic: "video",
                              }}
                            />
                          }
                        >
                          <List.Item.Meta
                            description={
                              <video width="300" height="230" controls>
                                <source
                                  src={getApiEndpoint() + item.path}
                                  type="video/mp4"
                                ></source>
                                <source
                                  src={getApiEndpoint() + item.path}
                                  type="video/ogg"
                                ></source>
                                <source
                                  src={getApiEndpoint() + item.path}
                                  type="video/webm"
                                ></source>
                              </video>
                            }
                          />{" "}
                        </List.Item>
                      )}
                    />{" "}
                  </>
                ) : null}
                {SizeType() === "sm" || SizeType() === "xs" ? (
                  <div
                    className={css.openCloseButton}
                    onClick={() =>
                      this.toggleMaterial_Mob(
                        !this.state.formVisible.visible,
                        "video"
                      )
                    }
                  >
                    {this.state.formVisible.visible &&
                    this.state.formVisible.type === "video"
                      ? "Zatvori"
                      : "Otvori"}
                  </div>
                ) : null}
              </Col>

              <Col sm={24} xs={24} md={12} style={{ paddingRight: 40 }}>
                <Title level={4}>
                  <div className={css.instructionsTitleContainer}>
                    <div>
                      <h1 className={css.instructionsTitleMainText}>Slike</h1>
                      <h4 className={css.instructionsTitleSecondaryText}>
                        {images.length
                          ? `Ukupno (${images.length}) slika`
                          : "Nema materijala u vidu slika"}
                      </h4>
                    </div>
                    <div
                      style={{
                        display: this.props.openOrder && "none",
                        overflow: "hidden",
                      }}
                    >
                      <div style={{ float: "right" }}>
                        <Button
                          onClick={() => this.toggleMaterialForm(true, "image")}
                          type={"primary"}
                        >
                          Dodaj
                        </Button>
                      </div>
                    </div>
                  </div>
                </Title>
                {!mob ||
                (this.state.formVisible.visible &&
                  this.state.formVisible.type === "image") ? (
                  <>
                    <List
                      style={{
                        width: "100%",
                        height: "27vh",
                        overflow: "auto",
                      }}
                      dataSource={images}
                      renderItem={(item) => (
                        <List.Item
                          style={{ display: "flex", alignItems: "flex-start" }}
                          extra={
                            <DeleteIcon
                              loading={
                                deleteMaterial.status === item.id + "image"
                              }
                              _deleteMaterial={this.props.onDeleteMaterial}
                              data={{
                                item_id: this.props.id,
                                material_id: item.id,
                                topic: "image",
                              }}
                            />
                          }
                        >
                          {" "}
                          <List.Item.Meta
                            description={
                              <a
                                href={getApiEndpoint() + item.path}
                                target="_blank"
                                style={{
                                  overflow: "hidden" ,
                                  backgroundImage: `url(${
                                    getApiEndpoint() + item.path
                                  })`,
                                  display: "inline-block",
                                  width: "200px",
                                  height: "200px",
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                }}
                              />
                            }
                          />{" "}
                        </List.Item>
                      )}
                    />{" "}
                  </>
                ) : null}
                {SizeType() === "sm" || SizeType() === "xs" ? (
                  <div
                    className={css.openCloseButton}
                    onClick={() =>
                      this.toggleMaterial_Mob(
                        !this.state.formVisible.visible,
                        "image"
                      )
                    }
                  >
                    {this.state.formVisible.visible &&
                    this.state.formVisible.type === "image"
                      ? "Zatvori"
                      : "Otvori"}
                  </div>
                ) : null}
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    material: state.machineReducer.material,
    deleteMaterial: state.machineReducer.deleteMaterial,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddMaterial: (item_id, data, topic_type, callback) =>
      dispatch({
        type: actions.ADD_MATERIAL,
        item_id,
        data,
        topic_type,
        callback,
      }),

    onDeleteMaterial: (item_id, material_id, topic_type) =>
      dispatch({
        type: actions.DELETE_MATERIAL,
        item_id,
        material_id,
        topic_type,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Instructions);
