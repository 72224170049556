import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Checkbox, Spin, Typography, message } from "antd";
import { technicianOfOrder } from "../../../helpers/getSpecValueFromOrder";
import { getApiEndpoint } from "../../../axios/endpoints";
import { LOGOUT } from "../../../redux/modules/Users/actions";
import { GET_ORDER_SUCCESS } from "../../../redux/modules/Maintenances/actions";
const NameChecklist = ({order, currentUser}) => {

    const [checklist, set_checklist] = useState(order.description_checklist || [])

    const [error, setError] = useState(false);
    const [loading, set_loading] = useState("");
    const dispatch = useDispatch();


    useEffect(() => {
        set_checklist(order.description_checklist);
    }, [order.description_checklist]);

   
    const updateChecklist = async (description_checklist) => {
        let url = `${getApiEndpoint()}maintenances/work_orders/${order.id}/update_basic_data/`;
        let token = await localStorage.getItem('token');
    
        axios
          .patch(
            url,
            {description_checklist},
            {
              headers: {
                Authorization: 'Token ' + token,
              },
            },
          )
          .then((res) => {
              dispatch({type: GET_ORDER_SUCCESS, data: res.data})
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              dispatch({type: LOGOUT});
            }
            const tmp = [...description_checklist];
            set_checklist(tmp);
            message.error("Doslo je do greske, pokusajte ponovo")
          }).finally(() => {
            setTimeout(() => {
                set_loading("")
            }, 360)
            
          });
      };
    return <div style={{paddingTop: 6}} >
        {checklist.map((x, i) => 
            <div
             key={i}
             style={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 6
            }}>
                {loading === x.text ? <Spin spinning={loading} size="small" /> : <Checkbox 
                     disabled={order.status !== "in_progress" && !technicianOfOrder(order, currentUser)}
                     checked={x.checked}
                     onChange={(newValue) => {
                        const tmp = [...checklist];
                        let index = tmp.findIndex(y => y.id === x.id)
                        set_loading(tmp[index].text)

                        tmp[index] = {
                            ...tmp[index],
                            checked: !tmp[index].checked
                        }
                        set_checklist(tmp);
                            updateChecklist(tmp);
                        
                     }}
                />}
                  <h1 style={{
                    marginLeft: 12,
                      fontSize: "24px",
                      fontWeight: 600,
                      marginBottom: 0,
                      paddingBottom: 0
                  }}>{x.text}</h1>

                
            </div> )}
       </div>


}

export default NameChecklist;

 