import { Alert, Button, Col, Row, Table, Typography, message } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios_analytics from "../../../../axios/axios-analytics";
import Loading from "../../../../components/LoadingScreen";
import { MAINTENANCE_NUMBER_PER_ASSET } from "../../../../redux/modules/Analytics/actions";
import css from "../maintenances.module.scss";

// Components
import { Bar } from "@ant-design/charts";
import { FileExcelOutlined } from "@ant-design/icons";
import { LOGOUT } from "../../../../redux/modules/Users/actions";
import { downloadExcelFromBase64 } from "../../Parts/components/WaitingParts";
import Statistic from "./Cards/Statistic";

// Constants
const hiddenCategories = {
  kranovi: ["Voda", "Zrak", "Hidraulika"],
  kompresorska_stanica: ["Voda", "Hidraulika"],
  pumpe_i_rashladni_uređaji: ["Zrak", "Hidraulika"],
  klima_uređaji: ["Voda", "Zrak", "Hidraulika"],
};

const NumOfMaintenancesByCategory = ({ category_for_report }) => {
  const [maintenanceCategoryTable, set_maintenanceCategoryTable] = useState([]);
  const [summedCategories, set_summedCategories] = useState({
    total_count: undefined,
    total_duration: undefined,
    per_type: [],
  });
  const {
    maintenancesReports: { filters },
    pickedDate,
    maintenanceNumberPerAsset,
  } = useSelector((state) => state.AnalyticsReducer);
  const [loading, set_loading] = useState(false);
  const [loadingSummed, set_loadingSummed] = useState(false);
  const [loadingExcel, set_loadingExcel] = useState(false);
  const [COLUMNS, set_COLUMNS] = useState([
    {
      title: "Naziv imovine",
      dataIndex: "asset_name",
    },
    {
      title: "Mehanika",
      render: (text, value) => {
        return (
          <div>
            <Typography.Text style={{ fontWeight: "bold" }}>{value.mechanical_data.category_count}</Typography.Text>
            <Typography.Text style={{ marginLeft: 12 }}>
              {(value.mechanical_data.category_time / 3600).toFixed(1)}h
            </Typography.Text>
          </div>
        );
      },
    },
    {
      title: "Elektronika",
      render: (text, value) => {
        return (
          <div>
            <Typography.Text style={{ fontWeight: "bold" }}>{value.electrical_data.category_count}</Typography.Text>
            <Typography.Text style={{ marginLeft: 12 }}>
              {(value.electrical_data.category_time / 3600).toFixed(1)}h
            </Typography.Text>
          </div>
        );
      },
    },
    {
      title: "Voda",
      render: (text, value) => {
        return (
          <div>
            <Typography.Text style={{ fontWeight: "bold" }}>{value.water_data.category_count}</Typography.Text>
            <Typography.Text style={{ marginLeft: 12 }}>
              {(value.water_data.category_time / 3600).toFixed(1)}h
            </Typography.Text>
          </div>
        );
      },
    },
    {
      title: "Zrak",
      render: (text, value) => {
        return (
          <div>
            <Typography.Text style={{ fontWeight: "bold" }}>{value.air_data.category_count}</Typography.Text>
            <Typography.Text style={{ marginLeft: 12 }}>
              {(value.air_data.category_time / 3600).toFixed(1)}h
            </Typography.Text>
          </div>
        );
      },
    },
    {
      title: "Hidraulika",
      render: (text, value) => {
        return (
          <div>
            <Typography.Text style={{ fontWeight: "bold" }}>{value.hydraulic_data.category_count}</Typography.Text>
            <Typography.Text style={{ marginLeft: 12 }}>
              {(value.hydraulic_data.category_time / 3600).toFixed(1)}h
            </Typography.Text>
          </div>
        );
      },
    },
  ]);

  const getListCategoriesTable = async (queryParams) => {
    set_loading(true);
    let token = localStorage.getItem("token");
    try {
      let res = await axios_analytics({ token }).get(`/maintenance_category_table_analytics/`, {
        params: queryParams,
      });
      set_maintenanceCategoryTable(res.data.filter((x) => x.category_for_report === category_for_report));
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch({ type: LOGOUT });
        return;
      }
      message.error("Dogodila se greska");
    }

    set_loading(false);
  };

  const getSumedCategories = async (queryParams) => {
    set_loadingSummed(true);
    let token = localStorage.getItem("token");
    try {
      let res = await axios_analytics({ token }).get(`/maintenance_category_analytics/`, {
        params: queryParams,
      });
      set_summedCategories(res.data);
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch({ type: LOGOUT });
        return;
      }
      message.error("Dogodila se greska");
    }

    set_loadingSummed(false);
  };

  const dispatch = useDispatch();

  const getReports = (queryParams) => {
    getSumedCategories(queryParams);
    getListCategoriesTable(queryParams);
    dispatch({ type: MAINTENANCE_NUMBER_PER_ASSET, queryParams });
  };

  const memedConfig = useMemo(() => {
    let data = [];
    maintenanceNumberPerAsset.data.data.map((x) => {
      data.push({
        count: x.count,
        name: x.name,
      });
    });
    let config = {
      interaction: {
        tooltip: {
          render: (e, { title, items }) => {
            console.log(e, title, items);
            return (
              <div
                style={{
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                <Typography.Text style={{ color: "black" }}>{title}: </Typography.Text>
                <Typography.Text style={{ color: "black", fontWeight: 500 }}>{items[0].value}</Typography.Text>
              </div>
            );
          },
        },
      },
      label: {
        text: "count",
        style: {
          fontWeight: 600,
          fontSize: 15,
          textAnchor: "start",
          fill: ({ count }) => (count === Math.max(...data.map((x) => x.count)) ? "#fff" : "#000"),
          dx: ({ count }) => (count === Math.max(...data.map((x) => x.count)) ? -16 : 24),
        },
      },
      data: data,
      xField: "name",
      yField: "count",
      animation: { appear: { delay: 750 } },
      axis: {
        y: { labelFormatter: "~s", labelSpacing: 10 },
        x: {
          labelSpacing: 10,
        },
      },
    };
    console.log(data, config);
    return config;
  }, [maintenanceNumberPerAsset]);

  useEffect(() => {
    const queryParams = {
      from: pickedDate.data.from,
      to: pickedDate.data.to,
      locations: filters.locations.join("|"),
      assets: filters.assets.join("|"),
      assets_category: filters.assets_category.join("|"),
      maintenances_category: filters.maintenances_category.join("|"),
      maintenance_types: filters.maintenance_types.join("|"),
      category_for_report: category_for_report,
    };
    getReports(queryParams);
  }, [pickedDate, filters, category_for_report]);

  const createExcel = async () => {
    set_loadingExcel(true);
    let token = localStorage.getItem("token");
    const queryParams = {
      start_date: pickedDate.data.from || undefined,
      end_date: pickedDate.data.to || undefined,
      locations: filters.locations.join("|") || undefined,
      assets: filters.assets.join("|") || undefined,
      assets_category: filters.assets_category.join("|") || undefined,
      maintenances_category: filters.maintenances_category.join("|") || undefined,
      maintenance_types: filters.maintenance_types.join("|") || undefined,
      category_for_report: category_for_report,
    };
    try {
      let res = await axios_analytics({ token }).post(`export_maintenance_category/`, queryParams);
      getExcel(res.data.uid);
    } catch (error) {
      set_loadingExcel(false);
      if (error.response && error.response.status === 401) {
        dispatch({ type: LOGOUT });
        return;
      }
      message.error("Dogodila se greska kod kreiranja Excela.");
    }
  };

  const getExcel = async (uid) => {
    let token = localStorage.getItem("token");
    try {
      let res = await axios_analytics({ token }).get(`get_excel/?uid=${uid}`);
      console.log(res.data);
      downloadExcelFromBase64(
        res.data.file_content,
        `Kategorije_odrzavanja${pickedDate.data.from}-${pickedDate.data.to}`
      );
      set_loadingExcel(false);
    } catch (error) {
      set_loadingExcel(false);
      if (error.response && error.response.status === 401) {
        dispatch({ type: LOGOUT });
        return;
      }
      message.error("Dogodila se greska kod dohvata Excela.");
    }
  };

  // Card categories - top row - hidding categories
  const categories = useMemo(() => {
    return summedCategories.per_type.filter((x) => {
      // If category is not in hiddenCategories - return it
      if (!hiddenCategories[category_for_report]) {
        return x;
      }
      return !hiddenCategories[category_for_report].includes(x.category);
    });
  }, [category_for_report, summedCategories]);

  // Filtered colums
  const columns = useMemo(() => {
    return COLUMNS.filter((x) => {
      if (!hiddenCategories[category_for_report]) {
        return x;
      }
      return !hiddenCategories[category_for_report].includes(x.title);
    });
  }, [category_for_report, COLUMNS]);

  if (maintenanceNumberPerAsset.status === "loading") {
    return <Loading />;
  }

  return (
    <>
      {/* <h3>Broj održavanja po kategoriji</h3> */}
      {maintenanceNumberPerAsset.status === "error" ? (
        <Alert
          style={{ width: "639px", marginTop: "10px", zIndex: "1" }}
          message="Dogodila se greška"
          description="Dogodila se greška prilikom dohvata podataka molimo vas probajte ponovo!"
          type="error"
        />
      ) : null}

      <div id={css.maintenancesContainer}>
        {/* //? Main row */}
        <Row gutter={[24]}>
          <Col span={24}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                overflowX: "auto",
                flexDirection: "row",
                gap: 20,
                marginBottom: 20,
                flexWrap: "wrap",
              }}
            >
              {categories.map((x) => (
                <Statistic
                  title={x.category}
                  count={x.count}
                  count_percentage={x.count_percentage}
                  duration={(x.duration / 3600).toFixed(1)}
                  duration_percentage={x.duration_percentage}
                />
              ))}
            </div>
          </Col>
          <Col span={24}>
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 12 }}>
              <Button
                loading={loadingExcel}
                onClick={() => {
                  createExcel();
                }}
              >
                Izvezi <FileExcelOutlined style={{ marginLeft: 3 }} />
              </Button>
            </div>
            <Table
              columns={columns}
              rowKey={(item) => item.asset_id}
              dataSource={maintenanceCategoryTable}
              loading={loading}
            />
          </Col>
        </Row>
        {/* //! Donut charts */}

        {/* //! Bar chart */}
        <Row gutter={[24, 48]}>
          <Col span={24}>
            {/* Header */}
            <div className={css.header} style={{ marginBottom: 24 }}>
              <p className={css.value} style={{ color: "unset" }}>Broj održavanja po imovini</p>
              <p className={css.label} style={{ color: "unset" }}>Ukupno {maintenanceNumberPerAsset.data.count} održavanja</p>
            </div>
            {/* Bar chart */}
            <div
              style={{
                position: "relative",
                overflowX: "auto",
                overflowY: "hidden",
              }}
            >
              <div
                style={{
                  height: maintenanceNumberPerAsset.data.data.length * 40,
                  width: "100%",
                }}
              >
                <Bar {...memedConfig} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NumOfMaintenancesByCategory;

function roundToTwoDecimalPlaces(number) {
  return Math.round(number * 100) / 100;
}
