import React, { useMemo } from 'react';
import css from '../../maintenance-manager.module.scss'

// Icons
import LOW_GREEN from '../../../../../../assets/icons/low_green.png'
import LOW_YELLOW from '../../../../../../assets/icons/low_yellow.png'
import LOW_RED from '../../../../../../assets/icons/low_red.png'
import CHECK_GREEN from '../../../../../../assets/icons/check_green.png'
import CHECK_YELLOW from '../../../../../../assets/icons/check_yellow.png'
import CHECK_RED from '../../../../../../assets/icons/check_red.png'

export default function Information({ count = "", description = "", onClick, icon = "" }){

    // Variables
    const LIMITS = [0, 5]

    const isLowIcon = icon === 'low'
    const LOW_ICONS = { LOW_GREEN, LOW_YELLOW, LOW_RED }
    const CHECK_ICONS = { CHECK_GREEN, CHECK_YELLOW, CHECK_RED }

    const _icon = useMemo(() => {
        if(count <= LIMITS[0]){
            return(
                <div className={css.informationIconBackground} style={{ backgroundColor: "#DDFFEB" }}>
                    <img 
                        className={css.informationIcon} 
                        src={isLowIcon ? LOW_ICONS.LOW_GREEN : CHECK_ICONS.CHECK_GREEN} />
                </div>
            )
        }
        else if(count > LIMITS[0] && count < LIMITS[1]){
            return(
                <div className={css.informationIconBackground} style={{ backgroundColor: "#FFF3DC" }}>
                    <img 
                        className={css.informationIcon} 
                        src={isLowIcon ? LOW_ICONS.LOW_YELLOW : CHECK_ICONS.CHECK_YELLOW} />
                </div>
            )
        }
        else{
            return(
                <div className={css.informationIconBackground} style={{ backgroundColor: "#FCE0EA" }}>
                    <img 
                        className={css.informationIcon} 
                        src={isLowIcon ? LOW_ICONS.LOW_RED : CHECK_ICONS.CHECK_RED} />
                </div>
            )
        }
    })

    return(
        <div onClick={() => onClick && onClick('Dijelovi')} className={css.informationNote}>
            <div className={css.informationContainer}>
                { _icon }
                <div className={css.informationFlex}>
                    <p className={css.informationValue}>{ count === "" ? <span>&nbsp;&nbsp;</span> : count}</p>
                    <p className={css.informationDescription}>{ description }</p>
                </div>
            </div>
        </div>
    )
}
 
