import React, { Component } from "react";

import { Row, Col, Tabs } from "antd";
import { connect } from "react-redux";
import CategoriesList from "./CategoriesList";
import CategoryForm from "./CategoriesForm";

import * as actions from "../../../../redux/modules/Accounting/actions";

const { TabPane } = Tabs;

class Categories extends Component {
  state = {};

  render() {
    const { categories } = this.props;

    console.log(categories)

    return (
      <>
        <Row>
          <Col md={12} sm={24}>
            <CategoryForm
              category={this.props.category}
              saveCategory={this.props.onSaveCategory}
              categories
            />
          </Col>
          <Col md={12} sm={24}>
            <CategoriesList 
              list={categories} />
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    category: state.accoutingReducer.user_category,
    prepareRegistration: state.accoutingReducer.prepareRegistration,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveCategory: (data, callback) =>
      dispatch({ type: actions.SAVE_CATEGORY, data, callback }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
