import React, { useState } from "react";

import { Button, Tabs } from "antd";

import Filters from "./components/Filters";

import {
  useHistory,
  useRouteMatch,
  NavLink,
  Switch,
  Route,
} from "react-router-dom";

import Reprorts_1 from "./components/AssetState";
import Expenses from "./components/Expenses";
import { useSelector } from "react-redux";
import SecondReport from "./components/SecondReport";
import AssetTimes from "./components/AssetTimes";

const { TabPane } = Tabs;

const Assets = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const { pickedData } = useSelector((state) => state.AnalyticsReducer);

  const [activeKey, set_activeKey] = useState(
    history.location.pathname.split("/").slice(-1)[0]
  );
  const [filtersVisible, set_filtersVisible] = useState(false);

  return (
    <>
      <div>
        <Tabs
          defaultActiveKey={activeKey}
          onChange={(key) => {
            history.replace(`/analytics/assets/${key}`);
          }}
        >
          {TABS.map((item) => (
            <TabPane
              tab={
                <>
                  {item.icon}
                  <NavLink to={`${match.url}/${item.path}`}>
                    {item.name}
                  </NavLink>
                </>
              }
              key={item.path}
            />
          ))}
        </Tabs>
      </div>
      <div>
        <Switch>
          <Route
            path={`${match.path}/reports_1`}
            render={() => <Reprorts_1 />}
          />
          <Route
            path={`${match.path}/reports_2`}
            render={() => <SecondReport />}
          />,
          <Route
            path={`${match.path}/reports_3`}
            render={() => <AssetTimes />}
          />
        </Switch>
      </div>

      <Filters
        filtersVisible={filtersVisible}
        set_filtersVisible={set_filtersVisible}
      />
    </>
  );
};

export default Assets;

//

const TABS = [
  {
    name: "Status proizvodnih strojeva",
    path: "reports_1",
  },
  {
    name: "Izvještaj održavanja",
    path: "reports_2",
  },
  {
    name: "Vrijeme rada imovine",
    path: "reports_3",
  }
];
