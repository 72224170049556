import React, { Component } from "react";

import { Form, Button, Col, Row, Input, Alert, TreeSelect } from "antd";

import SizeType from "../../../../helpers/deviceTypeSize";
import { LOCATION_TYPES } from "../../../../helpers/consts";

const { TextArea } = Input;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class LocationForm extends Component {
  state = {
    sameLocationErrorVisible: false,
    alertSuccessVisible: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err && !this.isExsitsLocation()) {
        values.type = this.props.type;
        this.props.saveLocation(values, this.showSuccess.bind(this));
      }
    });
  };

  isExsitsLocation = () => {
    const check = this.props.locations_list.find(
      (item) => item.name === this.props.form.getFieldValue("name")
    );
    if (check !== undefined && check !== -1) {
      this.setState({ sameLocationErrorVisible: true });
      return true;
    } else {
      this.setState({ sameLocationErrorVisible: false });
      return false;
    }
  };

  showSuccess = () => {
    this.setState({ alertSuccessVisible: true });
    this.props.form.resetFields();
    setTimeout(() => {
      this.setState({ alertSuccessVisible: false });
    }, 3000);
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;

    const alert = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1" }}
        message="Dogodila se greša"
        description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
        type="error"
      />
    );

    const alertSuccess = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1" }}
        message="Uspješno"
        description="Uspješno ste dodali lokaciju!"
        type="success"
      />
    );

    const alertSameLocation = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1" }}
        message="Dogodila se greša"
        description="Lokacija već postoji!"
        type="error"
      />
    );

    const formItemLayout = {
      labelCol: {
        span: 6,
      },
      wrapperCol:
        SizeType() === "xs" || SizeType() === "sm"
          ? {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 24,
                offset: 0,
              },
            }
          : {
              span: 18,
              offset: 0,
            },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };
    const layoutType =
      SizeType() === "xs" || SizeType() === "sm" ? "vertical" : "horizontal";

    const { locations } = this.props;

    return (
      <React.Fragment>
        {this.state.alertSuccessVisible ? alertSuccess : null}
        {this.state.sameLocationErrorVisible ? alertSameLocation : null}
        {locations.status === "error" ? alert : null}
        <Form
          {...formItemLayout}
          layout={layoutType}
          hideRequiredMark
          onSubmit={this.handleSubmit}
        >
          <Row type="flex" justify="start" gutter={16}>
            <Col span={24}>
              <Form.Item label={"Nadlokacija"}>
                {getFieldDecorator("location_child", {
                  initialValue: null,
                  rules: [
                    {
                      required: this.props.type === LOCATION_TYPES.WARHOUSE,
                      message: "Ovo polje je obavezno",
                    },
                  ],
                })(
                  <TreeSelect
                    placeholder="Please select"
                    onChange={(value) => {
                      console.log("odabarn   " + value);
                    }}
                    allowClear={true}
                    placeholder="Odaberi nadlokaciju"
                    treeData={this.props.locationTree}
                  ></TreeSelect>
                )}
              </Form.Item>
              <Form.Item label="Lokacija:">
                {getFieldDecorator("name", {
                  initialValue: null,
                  rules: [
                    { required: true, message: "Unesite lokaciju!" },
                    { min: 3, message: "Minimalno znakova 3!" },
                  ],
                })(<TextArea />)}
              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <Button
                  style={{ float: "right" }}
                  loading={locations.status === "loading"}
                  type="primary"
                  htmlType="submit"
                  disabled={hasErrors(getFieldsError())}
                >
                  Spremi
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

export default Form.create()(LocationForm);
