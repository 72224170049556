import { Col, Progress, Row, Typography } from "antd";
import React from "react";

const Statistic = ({ title, count, count_percentage, duration, duration_percentage }) => {
  return (
    <div
      style={{
        width: 300,
        padding: "10px 10px 4px 12px",
        borderRadius: 4,
        backgroundColor: "#f1f1f1",
        // boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.07)"
      }}
    >
      <div style={{ display: "flex" }}>
        <Typography.Text style={{ textAlign: "left", marginBottom: 12, fontSize: 18, fontWeight: 600 }}>
          {title}
        </Typography.Text>
      </div>
      <Row style={{ alignItems: "center" }}>
        <Col span={12} style={{ display: "flex" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography.Text style={{ opacity: 0.65 }}>Nalozi</Typography.Text>
            <div style={{ display: "flex", gap: 8, alignItems: "center", marginTop: 10 }}>
              <Typography.Text style={{ fontSize: 16, marginTop: -4 }} strong>
                {count}
              </Typography.Text>
              <div style={{ width: 1, height: 28, backgroundColor: "#ddd", transform: "translateY(-2px)" }}></div>
              <Typography.Text style={{ fontSize: 16, marginTop: -4 }} strong>
                {count_percentage.toFixed(1) + "%"}
              </Typography.Text>
            </div>
          </div>
        </Col>

        <Col span={12} style={{ display: "flex" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography.Text style={{ opacity: 0.65 }}>Vrijeme</Typography.Text>
            <div style={{ display: "flex", gap: 8, alignItems: "center", marginTop: 10 }}>
              <Typography.Text style={{ fontSize: 16, marginTop: -4 }} strong>
                {duration}h
              </Typography.Text>
              <div style={{ width: 1, height: 32, backgroundColor: "#ddd", transform: "translateY(-2px)" }}></div>
              <Typography.Text style={{ fontSize: 16, marginTop: -4 }} strong>
                {duration_percentage.toFixed(1) + "%"}
              </Typography.Text>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Statistic;
