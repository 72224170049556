import { takeLatest, put, call, select, delay } from "redux-saga/effects";

import * as actions from "./actions";
import { LOGOUT } from "../Users/actions";

import store from "../../../store";

import axios_parts from "../../../axios/axios-parts";
import { GET_ORDER, GET_ORDER_SUCCESS } from "../Maintenances/actions";

const initialState = {
  allPartStorages: {
    status: "",
    data: { data: [], message: "" },
  },

  spentsWithoutParts: {
    status: "",
    data: [],
  },
  historyPartSpent: {
    status: "",
    data: {
      data: [],
      cursor: {
        next: true,
        prev: null,
      },
    },
  },

  removePartSpent: {
    status: "",
    data: {},
  },

  waitingForConfirmation: {
    status: "",
    data: [],
  },

  confirmTransport: {
    status: "",
    data: {},
  },
  spendPart: {
    status: "",
    data: {},
  },
  getPartsInTransport: {
    status: "",
    data: [],
  },

  transportPart: {
    status: "",
    data: {},
  },

  removePart: {
    status: "",
    data: {},
  },

  removePartEntery: {
    status: "",
    data: {},
  },

  addPartToStorage: {
    status: "",
    data: {},
  },

  getPartEnteries: {
    status: "",
    data: {
      data: [],
      cursor: {
        next: true,
        prev: null,
      },
    },
    message: "",
  },

  parts: {
    data: {
      cursor: { prev: null, next: null },
      data: [],
    },
  },
  part: {
    data: {},
  },
  categories: {
    status: "", // MOZDA BI BILA BOLJA OPCIJA U PREPARE UBACIT
    data: [],
  },
  addPart: {
    status: "",
    data: {},
  },
  getPartsCategories: {
    status: "loading",
    data: [],
  },

  addPartCategory: {
    status: "",
    data: {},
  },
  getParts: {
    status: "loading",
    data: {},
  },
  updatePartCategory: {
    status: "",
    data: [],
  },
  removePartCategory: {
    status: "",
    data: [],
  },
  getPart: {
    status: "loading",
    data: {},
  },
  updatePart: {
    status: "",
    data: {},
  },
  addPartToOrder: {
    status: "",
    data: {},
  },
  getPartsByStatus: {
    status: "",
    data: [],
  },
  waitingParts: {
    data: [],
  },
  confirmPartRemoval: {
    status: "",
    data: {},
  },
};

export default function reducer(state = initialState, action = {}) {
  var tmp;
  var index = -1;
  let arr = [];
  switch (action.type) {
    case actions.ALL_PART_STORAGES:
      return {
        ...state,
        allPartStorages: {
          status: "loading",
          data: { data: [], message: "" },
        },
      };

    case actions.ALL_PART_STORAGES_SUCCESS:
      return {
        ...state,
        allPartStorages: {
          status: "",
          data: action.data,
        },
      };

    case actions.ALL_PART_STORAGES_FAIL:
      return {
        ...state,
        allPartStorages: {
          status: "error",
          data: { data: [], message: "" },
        },
      };
    case actions.SPENTS_WITHOUT_PARTS:
      return {
        ...state,
        spentsWithoutParts: {
          status: "loading",
          data: [],
        },
      };

    case actions.SPENTS_WITHOUT_PARTS_SUCCESS:
      return {
        ...state,
        spentsWithoutParts: {
          status: "",
          data: action.data,
        },
      };

    case actions.SPENTS_WITHOUT_PARTS_FAIL:
      return {
        ...state,
        spentsWithoutParts: {
          status: "error",
          data: [],
        },
      };
    case actions.HISTORY_PART_SPENT:
      return {
        ...state,
        historyPartSpent: {
          status: "loading",
          data: state.historyPartSpent.data,
        },
      };

    case actions.HISTORY_PART_SPENT_SUCCESS:
      arr = [...action.dataToMerge, ...action.data.data];

      return {
        ...state,
        historyPartSpent: {
          status: "",
          data: { data: arr, cursor: action.data.cursor },
        },
      };

    case actions.HISTORY_PART_SPENT_FAIL:
      return {
        ...state,
        historyPartSpent: {
          status: "error",
          data: state.historyPartSpent.data,
        },
      };
    case actions.REMOVE_PART_SPENT:
      return {
        ...state,
        removePartSpent: {
          status: "loading" + action.id,
          data: {},
        },
      };

    case actions.REMOVE_PART_SPENT_SUCCESS:
      arr = [...state.historyPartSpent.data.data];
      index = arr.findIndex((x) => x.id === action.id);
      if (index !== -1 && index !== undefined) arr.splice(index, 1);
      return {
        ...state,
        removePartSpent: {
          status: "",
          data: action.data,
        },
        historyPartSpent: {
          status: "",
          data: {
            cursor: state.historyPartSpent.data.cursor,
            data: arr,
          },
        },
      };

    case actions.REMOVE_PART_SPENT_FAIL:
      return {
        ...state,
        removePartSpent: {
          status: "error",
          data: {},
        },
      };
    case actions.WAITING_FOR_CONFIRMATION:
      return {
        ...state,
        waitingForConfirmation: {
          status: "loading",
          data: [],
        },
      };

    case actions.WAITING_FOR_CONFIRMATION_SUCCESS:
      return {
        ...state,
        waitingForConfirmation: {
          status: "",
          data: action.data,
        },
      };

    case actions.WAITING_FOR_CONFIRMATION_FAIL:
      return {
        ...state,
        waitingForConfirmation: {
          status: "error",
          data: [],
        },
      };
    case actions.CONFIRM_TRANSPORT:
      return {
        ...state,
        confirmTransport: {
          status: "loading",
          data: {},
        },
      };

    case actions.CONFIRM_TRANSPORT_SUCCESS:
      return {
        ...state,
        confirmTransport: {
          status: "",
          data: action.data,
        },
        getPartsInTransport: {
          status: "",
          message: action.data.message,
          data: action.data,
        },
      };

    case actions.CONFIRM_TRANSPORT_FAIL:
      return {
        ...state,
        confirmTransport: {
          status: "error",
          data: {},
        },
      };
    case actions.SPEND_PART:
      return {
        ...state,
        spendPart: {
          status: "loading",
          data: {},
        },
      };

    case actions.SPEND_PART_SUCCESS:
      return {
        ...state,
        spendPart: {
          status: "",
          data: action.data,
        },
        part: {
          status: "",
          data: action.data,
          message: action.data.message,
        },
      };

    case actions.SPEND_PART_FAIL:
      return {
        ...state,
        spendPart: {
          status: "error",
          data: {},
        },
      };
    case actions.GET_PARTS_IN_TRANSPORT:
      return {
        ...state,
        getPartsInTransport: {
          status: "loading",
          data: [],
        },
      };

    case actions.GET_PARTS_IN_TRANSPORT_SUCCESS:
      return {
        ...state,
        getPartsInTransport: {
          status: "",
          data: action.data,
        },
      };

    case actions.GET_PARTS_IN_TRANSPORT_FAIL:
      return {
        ...state,
        getPartsInTransport: {
          status: "error",
          data: [],
        },
      };
    case actions.TRANSPORT_PART:
      return {
        ...state,
        transportPart: {
          status: "loading",
          data: {},
        },
      };

    case actions.TRANSPORT_PART_SUCCESS:
      return {
        ...state,
        transportPart: {
          status: "",
          data: {},
        },
        part: {
          status: "",
          message: "",
          data: action.data,
        },
      };

    case actions.TRANSPORT_PART_FAIL:
      return {
        ...state,
        transportPart: {
          status: "error",
          data: {},
        },
      };
    case actions.REMOVE_PART:
      return {
        ...state,
        removePart: {
          status: "loading",
          data: {},
        },
      };

    case actions.REMOVE_PART_SUCCESS:
      arr = [...state.parts.data.data];
      index = arr.findIndex((x) => x.id == action.id);
      if (index !== undefined && index !== -1) {
        arr.splice(index, 1);
      }

      return {
        ...state,
        removePart: {
          status: "",
          data: action.data,
        },
        parts: {
          data: { data: arr, cursor: state.parts.data.cursor },
          status: "",
        },
      };

    case actions.REMOVE_PART_FAIL:
      return {
        ...state,
        removePart: {
          status: "error",
          data: {},
        },
      };
    case actions.GET_PART_ENTERIES:
      return {
        ...state,
        getPartEnteries: state.getPartEnteries,
      };

    case actions.GET_PART_ENTERIES_SUCCESS:
      arr = [...action.dataToMerge, ...action.data.data];
      return {
        ...state,
        getPartEnteries: {
          status: "",
          data: { data: arr, cursor: action.data.cursor },
          message: action.data.message,
        },
      };

    case actions.GET_PART_ENTERIES_FAIL:
      return {
        ...state,
        getPartEnteries: state.getPartEnteries,
      };
    case actions.REMOVE_PART_ENTERY:
      return {
        ...state,
        removePartEntery: {
          status: "loading" + action.id,
          data: {},
        },
      };

    case actions.REMOVE_PART_ENTERY_SUCCESS:
      arr = [...state.getPartEnteries.data.data];
      index = arr.findIndex((x) => x.id === action.id);
      arr.splice(index, 1);
      return {
        ...state,
        removePartEntery: {
          status: "",
          data: action.data,
        },
        getPartEnteries: {
          status: "",
          data: {
            data: arr,
            cursor: { ...state.getPartEnteries.data.cursor },
          },
        },
      };

    case actions.REMOVE_PART_ENTERY_FAIL:
      return {
        ...state,
        removePartEntery: {
          status: "error",
          data: {},
        },
      };
    case actions.UPDATE_PART:
      return {
        ...state,
        updatePart: {
          status: "loading_" + action.updateType,
          data: {},
          message: "",
        },
      };

    case actions.UPDATE_PART_SUCCESS:
      return {
        ...state,
        updatePart: {
          status: "",
          message: action.message,
        },
        part: { data: action.data },
      };

    case actions.UPDATE_PART_FAIL:
      return {
        ...state,
        updatePart: {
          status: "error",
          data: {},
          message: action.message,
        },
      };
    case actions.GET_PART:
      return {
        ...state,
        getPart: {
          status: "loading",
          data: {},
          message: "",
        },
      };

    case actions.GET_PART_SUCCESS:
      return {
        ...state,
        getPart: {
          status: "",
          data: {},
          message: action.message,
        },

        part: { data: action.data },
      };

    case actions.GET_PART_FAIL:
      return {
        ...state,
        getPart: {
          status: "error",
          data: action.data,
          message: action.message,
        },
      };

    case actions.REMOVE_PART_CATEGORY:
      return {
        ...state,
        removePartCategory: {
          status: "loading" + action.id,
          data: [],
          message: "",
        },
      };

    case actions.REMOVE_PART_CATEGORY_SUCCESS:
      tmp = [...state.categories.data];
      index = tmp.findIndex(x => x.id === action.id);
      tmp.splice(index, 1);
      return {
        ...state,
        categories: { data: tmp },

        removePartCategory: {
          status: "",
          data: action.data,
          message: action.message,
        },

      };

    case actions.REMOVE_PART_CATEGORY_FAIL:
      return {
        ...state,
        removePartCategory: {
          status: "error",
          data: [],
          message: action.message,
        },
      };
    case actions.UPDATE_PART_CATEGORY:
      return {
        ...state,
        updatePartCategory: {
          status: "loading",
          data: [],
          message: "",
        },
      };

    case actions.UPDATE_PART_CATEGORY_SUCCESS:
      arr = [...state.categories.data];
      index = arr.findIndex((x) => x.id === action.id);
      arr[index] = action.data.data;
        return {
        ...state,
        categories: { data: arr },
        updatePartCategory: {
          status: "",
          data: action.data,
          message: action.message,
        },
      };

    case actions.UPDATE_PART_CATEGORY_FAIL:
      return {
        ...state,
        updatePartCategory: {
          status: "error",
          data: [],
          message: action.message,
        },
      };

    case actions.GET_PARTS_CATEGORIES:
      return {
        ...state,
        getPartsCategories: {
          status: "loading",
          message: "",
        },
      };

    case actions.GET_PARTS_CATEGORIES_SUCCESS:
      return {
        ...state,
        getPartsCategories: {
          status: "",
          message: action.message,
        },

        categories: { data: action.data.data },
      };

    case actions.GET_PARTS_CATEGORIES_FAIL:
      return {
        ...state,
        getPartsCategories: {
          status: "error",
          message: action.message,
        },
      };
    case actions.ADD_PART:
      return {
        ...state,
        addPart: {
          status: "loading",
          data: {},
          message: "",
        },
      };

    case actions.ADD_PART_SUCCESS:
      arr = [...state.parts.data.data];

      index = arr.findIndex((x) => x.id === action.id);

      if (index === undefined || index === -1) {
        arr.push(action.data);
      } else {
        arr[index] = action.data;
      }

      return {
        ...state,
        addPart: {
          status: "",
          message: action.message,
        },

        parts: {
          data: {
            cursor: state.parts.data.cursor,
            data: arr,
          },
        },
      };

    case actions.ADD_PART_FAIL:
      return {
        ...state,
        addPart: {
          status: "error",
          data: {},
          message: action.message,
        },
      };
    case actions.ADD_PART_CATEGORY:
      return {
        ...state,
        addPartCategory: {
          status: "loading",
          data: {},
        },
      };

    case actions.ADD_PART_CATEGORY_SUCCESS:
      arr = state.categories.data;
      arr.push(action.data);
      return {
        ...state,
        addPartCategory: {
          status: "",
          data: {},
        },

        categories: { data: arr },
      };

    case actions.ADD_PART_CATEGORY_FAIL:
      return {
        ...state,
        addPartCategory: {
          status: "error",
          data: {},
        },
      };

    case actions.GET_PARTS:
      return {
        ...state,
        getParts: {
          status: "loading",
          data: {},
          message: "",
        },
      };

    case actions.GET_PARTS_SUCCESS:
      // arr = [...state.parts.data.data, ...action.data.data];
      return {
        ...state,
        getParts: {
          status: "",
          message: action.message,
        },
        parts: {
          data: {
            cursor: action.data.cursor,
            data: action.data.data,
          },
        },
      };

    case actions.GET_PARTS_FAIL:
      return {
        ...state,
        getParts: {
          status: "error",
          data: {},
          message: action.message,
        },
      };

    case actions.PART_TO_ORDER:
      return {
        ...state,
        addPartToOrder: {
          status: "loading",
          data: {},
          message: "",
        },
      };

    case actions.PART_TO_ORDER_SUCCESS:
      return {
        ...state,
        addPartToOrder: {
          status: "",
          data: action.data,
          message: action.message,
        },
      };

    case actions.PART_TO_ORDER_FAIL:
      return {
        ...state,
        addPartToOrder: {
          status: "error",
          data: {},
          message: action.message,
        },
      };
    case actions.GET_PARTS_BY_STATUS:
      return {
        ...state,
        getPartsByStatus: {
          status: "loading",
          data: [],
          message: "",
        },
      };

    case actions.GET_PARTS_BY_STATUS_SUCCESS:
      return {
        ...state,
        getPartsByStatus: {
          status: "",
          data: [],
          message: action.message,
        },
        waitingParts: {
          data: action.data,
        },
      };

    case actions.GET_PARTS_BY_STATUS_FAIL:
      return {
        ...state,
        getPartsByStatus: {
          status: "error",
          data: [],
          message: action.message,
        },
      };

    case actions.CONFIRM_PART_REMOVAL:
      return {
        ...state,
        confirmPartRemoval: {
          status: "loading",
          data: {},
          message: "",
        },
      };

    case actions.CONFIRM_PART_REMOVAL_SUCCESS:
      arr = [...state.waitingForConfirmation.data];
      index = arr.findIndex((x) => x.id === action.id);
      arr.splice(index, 1);

      const partsList = [...state.parts.data.data];
      index = partsList.findIndex((x) => x.id === action.data.data.id);
      if (index !== -1 && index !== undefined) {
        partsList[index] = action.data.data;
      }
      return {
        ...state,
        confirmPartRemoval: {
          status: "",
          data: {},
          message: action.message,
        },
        parts: {
          data: { data: partsList, cursor: state.parts.data.cursor },
          status: "",
        },
        waitingForConfirmation: { data: arr, status: "" },
      };

    case actions.CONFIRM_PART_REMOVAL_FAIL:
      return {
        ...state,
        confirmPartRemoval: {
          status: "error",
          data: {},
          message: action.message,
        },
      };

    case actions.ADD_PART_TO_STORAGE:
      return {
        ...state,
        addPartToStorage: {
          status: "loading",
          data: {},
        },
      };

    case actions.ADD_PART_TO_STORAGE_SUCCESS:
      return {
        ...state,
        addPartToStorage: {
          status: "",
          data: action.data,
        },
      };

    case actions.ADD_PART_TO_STORAGE_FAIL:
      return {
        ...state,
        addPartToStorage: {
          status: "error",
          data: {},
        },
      };

    default:
      return state;
  }
}

function transformData(data) {
  return data.data;
}

const authToken = () => localStorage.getItem("token");

// addPartCategory
export function* watcher_addPartCategory() {
  yield takeLatest(actions.ADD_PART_CATEGORY, addPartCategory);
}

function _addPartCategory(options) {
  return axios_parts(options).post("categories/", options.data);
}

function* addPartCategory(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
    };
    const response = yield call(_addPartCategory, options);
    const data = transformData(response);
    yield put({
      type: actions.ADD_PART_CATEGORY_SUCCESS,
      data: data.data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.ADD_PART_CATEGORY_FAIL, error, message: "" });
  }
}
//addPartCategory END

// getParts
export function* watcher_getParts() {
  yield takeLatest(actions.GET_PARTS, getParts);
}

function _getParts(options) {
  return axios_parts(options).get("parts/", {
    params: options.queryParams,
  });
}

function* getParts(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_getParts, options);
    const data = transformData(response);
    yield put({
      type: actions.GET_PARTS_SUCCESS,
      data: data.data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.GET_PARTS_FAIL, error, message: "" });
  }
}
//getParts END

// addPart
export function* watcher_addPart() {
  yield takeLatest(actions.ADD_PART, addPart);
}

function _addPart(options) {
  return axios_parts(options).post("parts/", options.data);
}

function* addPart(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
    };
    const response = yield call(_addPart, options);
    const data = transformData(response);
    yield put({
      type: actions.ADD_PART_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.ADD_PART_FAIL, error, message: "" });
  }
}
//addPart END

// getPartsCategories
export function* watcher_getPartsCategories() {
  yield takeLatest(actions.GET_PARTS_CATEGORIES, getPartsCategories);
}

function _getPartsCategories(options) {
  return axios_parts(options).get("categories/", {
    params: options.queryParams,
  });
}

function* getPartsCategories(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_getPartsCategories, options);
    const data = transformData(response);
    yield put({
      type: actions.GET_PARTS_CATEGORIES_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.GET_PARTS_CATEGORIES_FAIL, error, message: "" });
  }
}
//getPartsCategories END

// updatePartCategory
export function* watcher_updatePartCategory() {
  yield takeLatest(actions.UPDATE_PART_CATEGORY, updatePartCategory);
}

function _updatePartCategory(options) {
  return axios_parts(options).put(`categories/${options.id}/`, options.data);
}

function* updatePartCategory(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
      id: payload.id,
    };
    const response = yield call(_updatePartCategory, options);
    const data = transformData(response);
    yield put({
      type: actions.UPDATE_PART_CATEGORY_SUCCESS,
      data,
      id: payload.id,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.UPDATE_PART_CATEGORY_FAIL, error, message: "" });
  }
}
//updatePartCategory END

// removePartCategory
export function* watcher_removePartCategory() {
  yield takeLatest(actions.REMOVE_PART_CATEGORY, removePartCategory);
}

function _removePartCategory(options) {
  return axios_parts(options).delete(`categories/${options.id}/`, options.data);
}

function* removePartCategory(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
      id: payload.id,
    };
    const response = yield call(_removePartCategory, options);
    const data = transformData(response);
    yield put({
      type: actions.REMOVE_PART_CATEGORY_SUCCESS,
      data,
      id: payload.id,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.REMOVE_PART_CATEGORY_FAIL, error, message: "" });
  }
}
//removePartCategory END

// getPart
export function* watcher_getPart() {
  yield takeLatest(actions.GET_PART, getPart);
}

function _getPart(options) {
  return axios_parts(options).get(`parts/${options.id}/`, {
    params: options.queryParams,
  });
}

function* getPart(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
      id: payload.id,
    };
    const response = yield call(_getPart, options);
    const data = transformData(response);
    yield put({
      type: actions.GET_PART_SUCCESS,
      data,
      id: payload.id,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({
      type: actions.GET_PART_FAIL,
      error,
      message: "",
      data: error.response?.data,
    });
  }
}
//getPart END

// updatePart
export function* watcher_updatePart() {
  yield takeLatest(actions.UPDATE_PART, updatePart);
}

function _updatePart(options) {
  return axios_parts(options).patch(`parts/${options.id}/`, options.data);
}

function* updatePart(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
      id: payload.id,
    };
    const response = yield call(_updatePart, options);
    const data = transformData(response);

    yield put({
      type: actions.UPDATE_PART_SUCCESS,
      data,
      id: payload.id,
      updateType: payload.updateType,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.UPDATE_PART_FAIL, error, message: "" });
  }
}
//updatePart END

// addPartToOrder
export function* watcher_addPartToOrder() {
  yield takeLatest(actions.PART_TO_ORDER, addPartToOrder);
}

function _addPartToOrder(options) {
  // lose nazvan naziv moze se uttrošit dio a da ne pripada nalogu
  const url =
    options.flag === "+" ? `add_to_work_order/` : `remove_from_work_order/`;
  return axios_parts(options).post(url, options.data);
}

function* addPartToOrder(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      flag: payload.flag,
      data: payload.data,
    };
    const response = yield call(_addPartToOrder, options);
    const data = transformData(response);
    yield put({
      type: actions.PART_TO_ORDER_SUCCESS,
      data,
    });

    if (payload.data.work_order_id) {
      yield put({
        type: GET_ORDER_SUCCESS,
        data: updateOrderParts(
          { ...store.getState().maintenanceReducer.order.data },
          data
        ),
      });
    } else {
      yield put({ type: actions.GET_PARTS_SUCCESS, data: { results: data } });
    }

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.PART_TO_ORDER_FAIL, error, message: "" });
  }
}
//addPartToOrder END

const updateOrderParts = (order, newPart) => {
  order.parts = newPart;
  return order;
};

// getPartsByStatus
export function* watcher_getPartsByStatus() {
  yield takeLatest(actions.GET_PARTS_BY_STATUS, getPartsByStatus);
}

function _getPartsByStatus(options) {
  return axios_parts(options).get(`/get_parts_by_status/`, {
    params: options.queryParams,
  });
}

function* getPartsByStatus(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_getPartsByStatus, options);
    const data = transformData(response);
    yield put({
      type: actions.GET_PARTS_BY_STATUS_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.GET_PARTS_BY_STATUS_FAIL, error, message: "" });
  }
}
//getPartsByStatus END

// confirmPartRemoval
export function* watcher_confirmPartRemoval() {
  yield takeLatest(actions.CONFIRM_PART_REMOVAL, confirmPartRemoval);
}

function _confirmPartRemoval(options) {
  return axios_parts(options).post(`parts/confirm_spend/`, options.data);
}

function* confirmPartRemoval(payload) {
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
    };
    const response = yield call(_confirmPartRemoval, options);
    const data = transformData(response);
    yield put({
      type: actions.CONFIRM_PART_REMOVAL_SUCCESS,
      data,
    });

    if (payload.successCallback) {
      payload.successCallback();
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback();
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.CONFIRM_PART_REMOVAL_FAIL, error, message: "" });
  }
}
//confirmPartRemoval END

// getPartEnteries
export function* watcher_getPartEnteries() {
  yield takeLatest(actions.GET_PART_ENTERIES, getPartEnteries);
}

function _getPartEnteries(options) {
  return axios_parts(options).get(`parts/${options.id}/entry_list/`, {
    params: options.queryParams,
  });
}

function* getPartEnteries(payload) {
  let data = {};
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
      id: payload.id,
    };
    const response = yield call(_getPartEnteries, options);
    data = transformData(response);
    yield put({
      type: actions.GET_PART_ENTERIES_SUCCESS,
      data: data.data,
      dataToMerge: payload.dataToMerge,
    });

    if (payload.successCallback) {
      payload.successCallback(data);
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback(data);
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.GET_PART_ENTERIES_FAIL, error, message: "" });
  }
}
//getPartEnteries END

// addPartToStorage
export function* watcher_addPartToStorage() {
  yield takeLatest(actions.ADD_PART_TO_STORAGE, addPartToStorage);
}

function _addPartToStorage(options) {
  return axios_parts(options).post(
    `parts/${options.id}/add_to_storage/`,
    options.data
  );
}

function* addPartToStorage(payload) {
  let data = {};
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
      id: payload.id,
    };
    const response = yield call(_addPartToStorage, options);
    data = transformData(response);
    yield put({ type: actions.GET_PART_SUCCESS, data: data.data });
    yield put({
      type: actions.ADD_PART_TO_STORAGE_SUCCESS,
      data: data.data,
      id: payload.id,
    });

    if (payload.successCallback) {
      payload.successCallback(data);
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback(data);
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.ADD_PART_TO_STORAGE_FAIL, error, message: "" });
  }
}
//addPartToStorage END

// removePartEntery
export function* watcher_removePartEntery() {
  yield takeLatest(actions.REMOVE_PART_ENTERY, removePartEntery);
}

function _removePartEntery(options) {
  return axios_parts(options).delete(`entries/${options.id}/`);
}

function* removePartEntery(payload) {
  let data = {};
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
      id: payload.id,
    };
    const response = yield call(_removePartEntery, options);
    data = transformData(response);
    yield put({
      type: actions.REMOVE_PART_ENTERY_SUCCESS,
      data: data.data,
      id: payload.id,
    });

    if (payload.successCallback) {
      payload.successCallback(data);
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback(data);
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.REMOVE_PART_ENTERY_FAIL, error, message: "" });
  }
}
//removePartEntery END

// removePart
export function* watcher_removePart() {
  yield takeLatest(actions.REMOVE_PART, removePart);
}

function _removePart(options) {
  return axios_parts(options).delete(`parts/${options.id}/`, options.data);
}

function* removePart(payload) {
  let data = {};
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
      id: payload.id,
    };
    const response = yield call(_removePart, options);
    data = transformData(response);
    yield put({
      type: actions.REMOVE_PART_SUCCESS,
      data: data.data,
      id: payload.id,
    });

    if (payload.successCallback) {
      payload.successCallback(data);
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback(data);
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.REMOVE_PART_FAIL, error, message: "" });
  }
}
//removePart END

// transportPart
export function* watcher_transportPart() {
  yield takeLatest(actions.TRANSPORT_PART, transportPart);
}

function _transportPart(options) {
  return axios_parts(options).post(`transport/create_request/`, options.data);
}

function* transportPart(payload) {
  let data = {};
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
    };
    const response = yield call(_transportPart, options);
    data = transformData(response);
    yield put({
      type: actions.TRANSPORT_PART_SUCCESS,
      data: data.data,
    });

    if (payload.successCallback) {
      payload.successCallback(data);
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback(data);
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.TRANSPORT_PART_FAIL, error, message: "" });
  }
}
//transportPart END

// getPartsInTransport
export function* watcher_getPartsInTransport() {
  yield takeLatest(actions.GET_PARTS_IN_TRANSPORT, getPartsInTransport);
}

function _getPartsInTransport(options) {
  return axios_parts(options).get(`transport/`, {
    params: options.queryParams,
  });
}

function* getPartsInTransport(payload) {
  let data = {};
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_getPartsInTransport, options);
    data = transformData(response);
    yield put({
      type: actions.GET_PARTS_IN_TRANSPORT_SUCCESS,
      data: data.data,
    });

    if (payload.successCallback) {
      payload.successCallback(data);
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback(data);
    }
    // dispatch a failure action to the store with the error
    yield put({
      type: actions.GET_PARTS_IN_TRANSPORT_FAIL,
      error,
      message: "",
    });
  }
}
//getPartsInTransport END

// spendPart
export function* watcher_spendPart() {
  yield takeLatest(actions.SPEND_PART, spendPart);
}

function _spendPart(options) {
  return axios_parts(options).post(`parts/spend/`, options.data);
}

function* spendPart(payload) {
  let data = {};
  try {
    const token = yield call(authToken);
    const options = {
      token: token,
      data: payload.data,
    };
    const response = yield call(_spendPart, options);
    data = transformData(response);
    yield put({
      type: actions.SPEND_PART_SUCCESS,
      data: data.data,
    });

    if(payload.data.work_order){
      yield put({
        type: GET_ORDER,
        order_id: payload.data.work_order,
      });
    }

    if (payload.successCallback) {
      payload.successCallback(data);
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback(data);
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.SPEND_PART_FAIL, error, message: "" });
  }
}
//spendPart END

// confirmTransport
export function* watcher_confirmTransport() {
  yield takeLatest(actions.CONFIRM_TRANSPORT, confirmTransport);
}

function _confirmTransport(options) {
  return axios_parts(options).post(
    `transport/${options.id}/change_status/`,
    options.data
  );
}

function* confirmTransport(payload) {
  let data = {};
  try {
    const token = yield call(authToken);
    const options = {
      token: token,
      data: payload.data,
      id: payload.id,
    };
    const response = yield call(_confirmTransport, options);
    data = transformData(response);
    yield put({
      type: actions.CONFIRM_TRANSPORT_SUCCESS,
      data: data.data,
    });

    if (payload.successCallback) {
      payload.successCallback(data);
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback(data);
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.CONFIRM_TRANSPORT_FAIL, error, message: "" });
  }
}
//confirmTransport END

// waitingForConfirmation
export function* watcher_waitingForConfirmation() {
  yield takeLatest(actions.WAITING_FOR_CONFIRMATION, waitingForConfirmation);
}

function _waitingForConfirmation(options) {
  return axios_parts(options).get(`/spend/waiting_for_confirmation/`, {
    params: options.queryParams,
  });
}

function* waitingForConfirmation(payload) {
  let data = {};
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_waitingForConfirmation, options);
    data = transformData(response);
    yield put({
      type: actions.WAITING_FOR_CONFIRMATION_SUCCESS,
      data: data.data,
    });

    if (payload.successCallback) {
      payload.successCallback(data);
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback(data);
    }
    // dispatch a failure action to the store with the error
    yield put({
      type: actions.WAITING_FOR_CONFIRMATION_FAIL,
      error,
      message: "",
    });
  }
}
//waitingForConfirmation END

// removePartSpent
export function* watcher_removePartSpent() {
  yield takeLatest(actions.REMOVE_PART_SPENT, removePartSpent);
}

function _removePartSpent(options) {
  return axios_parts(options).post(`parts/remove_spent/`, options.data);
}

function* removePartSpent(payload) {
  let data = {};
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      data: payload.data,
    };
    const response = yield call(_removePartSpent, options);
    data = transformData(response);
    yield put({
      type: actions.REMOVE_PART_SPENT_SUCCESS,
      data: data.data,
      id: payload.id,
      work_order: payload.work_order,
    });

    if (payload.successCallback) {
      payload.successCallback(data);
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback(data);
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.REMOVE_PART_SPENT_FAIL, error, message: "" });
  }
}
//removePartSpent END

// historyPartSpent
export function* watcher_historyPartSpent() {
  yield takeLatest(actions.HISTORY_PART_SPENT, historyPartSpent);
}

function _historyPartSpent(options) {
  return axios_parts(options).get(`parts/${options.id}/spend_list/`, {
    params: options.queryParams,
  });
}

function* historyPartSpent(payload) {
  let data = {};
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
      id: payload.id,
    };
    const response = yield call(_historyPartSpent, options);
    data = transformData(response);
    yield put({
      type: actions.HISTORY_PART_SPENT_SUCCESS,
      data: data.data,
      dataToMerge: payload.dataToMerge,
    });

    if (payload.successCallback) {
      payload.successCallback(data);
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback(data);
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.HISTORY_PART_SPENT_FAIL, error, message: "" });
  }
}
//historyPartSpent END

// spentsWithoutParts
export function* watcher_spentsWithoutParts() {
  yield takeLatest(actions.SPENTS_WITHOUT_PARTS, spentsWithoutParts);
}

function _spentsWithoutParts(options) {
  return axios_parts(options).get(`spend/without_part/`, {
    params: options.queryParams,
  });
}

function* spentsWithoutParts(payload) {
  let data = {};
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_spentsWithoutParts, options);
    data = transformData(response);
    yield put({
      type: actions.SPENTS_WITHOUT_PARTS_SUCCESS,
      data: data.data,
    });

    if (payload.successCallback) {
      payload.successCallback(data);
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback(data);
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.SPENTS_WITHOUT_PARTS_FAIL, error, message: "" });
  }
}
//spentsWithoutParts END

// allPartStorages
export function* watcher_allPartStorages() {
  yield takeLatest(actions.ALL_PART_STORAGES, allPartStorages);
}

function _allPartStorages(options) {
  return axios_parts(options).get(`storages/ `, {
    params: options.queryParams,
  });
}

function* allPartStorages(payload) {
  let data = {};
  try {
    const token = yield select(authToken);
    const options = {
      token: token,
      queryParams: payload.queryParams,
    };
    const response = yield call(_allPartStorages, options);
    data = transformData(response);
    yield put({
      type: actions.ALL_PART_STORAGES_SUCCESS,
      data: data,
    });

    if (payload.successCallback) {
      payload.successCallback(data);
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      yield put({ type: LOGOUT });
    }

    if (payload.errorCallback) {
      payload.errorCallback(data);
    }
    // dispatch a failure action to the store with the error
    yield put({ type: actions.ALL_PART_STORAGES_FAIL, error, message: "" });
  }
}
//allPartStorages END
