import React, { useEffect } from "react";

// UI
import { Spin } from "antd";

// Redux
import { useSelector, useDispatch } from "react-redux";

// Components
import Loading from "../Loading";
import NewOrder from "../../../../../Machine/components/Maintenance/NewOrder";

export default function DrawerComponent({ planned_start, openCloseMaintenaceForm }) {
  // Variables
  const dispatch = useDispatch();
  const prepareRegistration = useSelector(
    (state) => state.machinesReducer.prepareRegistration
  );
  const { machines, machine } = useSelector((state) => state.machinesReducer);
  const orders = useSelector((state) => state.maintenancesReducer.orders);

  if (
    prepareRegistration.status === "loading" ||
    machines.status === "loading" ||
    orders.status === "loading"
  ) {
    return <Loading />;
  }

  return (
    <div>
      <NewOrder
      openCloseMaintenaceForm={openCloseMaintenaceForm}
        prepareRegistration={prepareRegistration.data}
        machines={machines.data}
        machine={machine}
        orders={orders}
        update={false}
        order={{ data: machines.data[0] }}
        maintenance_for={"machine"}
        planned_start={planned_start}
        assetSelected={false}
        // openCloseMaintenaceForm={openCloseMaintenaceForm}
      />
    </div>
  );
}
