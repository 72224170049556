import { Col, Row, PageHeader } from "antd";
import React, { Component } from "react";

import NewOrder from "../Dashboard/components/Reporter/NewOrder";
import ConfirmOrder from "../Dashboard/components/Reporter/ConfirmOrder";

class ReportConfirm extends Component {
  state = {};
  render() {
    return (
      <div style={{maxWidth: "1200px"}}>
        <Row>
          <Col span={11}>
            <PageHeader title="Nepotvrđeni nalozi" />
            <ConfirmOrder />
          </Col>
          <Col span={1}>
            {" "}
            <div
              style={{ width: "1px", backgroundColor: "black", flexGrow: 1 }}
            ></div>{" "}
          </Col>
          <Col span={12}>
            <PageHeader title="Prijava" />
            <NewOrder prepareRegistration={this.props.prepareRegistration} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default ReportConfirm;
