import { Button, List, Modal } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/hr";

import ConfirmForm from "./Form";

const ConfirmOrder = ({}) => {
  const [orderID, set_orderID] = useState(null);

  const orders = useSelector((state) =>
    state.maintenancesReducer.ordersToConfirm.data  
  );

  const renderItem = (item) => {

    const machines =
      item.items.length > 1
        ? item.items.map((item) => `${item.name} ${item.info.model} `)
        : item.items[0].name;

    const categories =
      item.categories.length > 0
        ? item.categories.map((item) => `${item.name} `)
        : "---";

    const created_at_formatted =
      moment(item.created_at).locale("hr").format("LLL") + "h";
    const planned_start_formatted =
      moment(item.planned_start).locale("hr").format("LLL") + "h";
    const estimated_time_formatted =
      item.estimated_time == null && item.estimated_time_minute == null
        ? "---"
        : (item.estimated_time || 0) +
          (item.estimated_time === 1 ? " sat " : " sata ") +
          (item.estimated_time_minute || 0) +
          " minuta";

    return (
      <>
        <div style={{ marginTop: 16, marginBottom: 140 }}>
          <p style={{ fontSize: 20, fontWeight: 600, marginBottom: 20 }}>
            {item.name}
          </p>

          {/* Informations */}
          {Information("Imovina", machines)}
          {Information("Kategorije", categories)}
          {Information("Tip", item.type.name)}
          {Information("Lokacija")}
          {Information("ID", item.id)}
          {Information("Prijavio", item.creator.full_name)}
          {Information("Datum stvaranja", created_at_formatted)}
          {Information("Planirani start", planned_start_formatted)}
          {Information("Potrebno vrijeme", estimated_time_formatted)}
          {Information(
            "Imovina aktivna",
            item.machine_run ? "Aktiva" : "Neaktivna"
          )}
          {Information("Opis", item.description)}

          <Button
            style={{ float: "right", marginTop: 16 }}
            onClick={() => set_orderID(item.id)}
            type="primary"
          >
            Potvrdi
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      {" "}
      <List dataSource={orders} renderItem={renderItem} />
      <Modal
        destroyOnClose={true}
        footer={null}
        visible={!!orderID}
        onCancel={() => set_orderID(null)}
      >
        <ConfirmForm set_orderID={set_orderID} order_id={orderID} />
      </Modal>
    </>
  );
};

const Information = (label = "---", value = "---", button) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <p style={{ fontWeight: 300 }}>{label}: </p>
      <p style={{ fontWeight: 600 }}>
        {button} {value}
      </p>
    </div>
  );
};

export default ConfirmOrder;
