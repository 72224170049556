import React from "react";
import { Link } from "react-router-dom";
import { STATUS_KEYS } from "../../../../../../helpers/consts";

import formatDays from "../../../../../../helpers/formatDays";

// Helpers

const formatDate = (data, status) => {
  switch (status) {
    case STATUS_KEYS.IN_BOWL:
      return "Kreiran prije " + formatDays(data.created_at);
    case STATUS_KEYS.FINISHED:
      return "Završen prije " + formatDays(data.real_end);
    case STATUS_KEYS.IN_PROGRESS:
      return "Pokrenut prije " + formatDays(data.real_start);
    case STATUS_KEYS.WAITING:
      return "Pokrenut prije " + formatDays(data.real_start);
    case STATUS_KEYS.APPOINTED:
      return "Preuzet prije " + formatDays(data.taking_at);
    case STATUS_KEYS.REPORTED:
      return "Kreiran prije " + formatDays(data.created_at);
  }
  return;
};

const formatTechnicianDate = (date, status) => {
  switch (status) {
    case "logged_in":
      return "Prijavljen prije " + formatDays(date);
    case "logged_out":
      return "Odjavljen prije " + formatDays(date);
  }
  return;
};

const STATUS = {
  in_bowl: "Nedodjeljen",
  in_progress: "U tijeku",
  waiting: "Na čekanju",
  appointed: "Dodjeljen",
  finished: "Nepotvrđen",
};

// Maintenances

const getForMaintenances = (orders, status) => {
  let data = [];
  const columns = [
    {
      title: "Naziv",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => (
        <div>
          <Link target={"_blank"} to={`/maintenances/${record.id}/about`}>
            {record.name}
          </Link>
        </div>
      ),
    },
    {
      title: "Opis",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Imovina",
      dataIndex: "machine",
      key: "machine",
    },
    {
      title: "Tip",
      dataIndex: "type",
      key: "type",
    },
  ];

  if (orders && orders.length) {
    data = orders
      .filter((item) => item.status === status)
      .map((x) => ({
        key: x.id,
        id: x.id,
        name: x.name,
        description: formatDate(x, status),
        machine: x.items[0].name,
        type: x.type.name,
      }));
  }

  return { columns, data };
};

// Technicians

const getForTechnicians = (orders, accounts, status, type) => {
  let data = [];

  const secondColumn = () => {
    switch (status) {
      case "logged_in":
        return {
          title: "Vrijeme prijave",
          dataIndex: "info",
          key: "last_login",
        };
      case "logged_out":
        return {
          title: "Vrijeme odjave",
          dataIndex: "info",
          key: "last_loggout",
        };
      case "busy":
        return {
          title: "Nalog",
          dataIndex: "info",
          key: "busy",
          render: (text, record, index) => {
            return (
              <div>
                <Link
                  target={"_blank"}
                  to={`/maintenances/${findOrder(record).id}/about`}
                >
                  {findOrder(record).name}
                </Link>
              </div>
            );
          },
        };
      case "free":
        return {};
    }
  };

  const columns = [
    {
      title: "Ime i prezime",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => (
        <div>
          <Link target={"_blank"} to={`/accounting/${record.id}/about`}>
            {record.name}
          </Link>
        </div>
      ),
    },
    ...(status !== "free" ? [secondColumn()] : []),
  ];

  const findOrder = (account) => {
    let order = null;
    order = orders
      .filter((item) => item.status === "in_progress")
      .find((item) =>
        item.technicians.some(
          (technician) => technician.account.id === account.id
        )
      );

    if (!order || order === -1) {
      return {};
    }

    return order;
  };

  if (accounts && accounts.length) {
    if (type === "logged") {
      let logged = status === "logged_in";
      console.log(accounts);
      data = accounts
        .filter((item) => item.logged === logged)
        .map((x) => ({
          key: x.id,
          id: x.id,
          name: x.full_name,
          info: logged
            ? formatTechnicianDate(x.last_login, status)
            : formatTechnicianDate(x.last_logout, status),
        }));
    } else {
      data = accounts
        .filter((item) => item.logged)
        .filter((item) => item.status === status)
        .map((x) => ({
          key: x.id,
          id: x.id,
          name: x.full_name,
          info: status === "busy" && findOrder(x).name,
        }));
    }
  }

  return { columns, data };
};

// Assets

const getForAssets = (dataByStatus, dataByOrders, info, status, type) => {
  let data = [];
  let columns = [];

  if (type === "status") {
    columns = [
      {
        title: "Naziv",
        dataIndex: "name",
        key: "name",
        render: (text, record, index) => (
          <div>
            <Link
              target={"_blank"}
              to={`/machines/${record.id}/specifications`}
            >
              {record.name}
            </Link>
          </div>
        ),
      },
      {
        title: "Kategorija",
        dataIndex: "category",
        key: "category",
      },
      {
        title: "Lokacija",
        dataIndex: "location",
        key: "location",
      },
    ];
  } else {
    columns = [
      {
        title: "Naziv",
        dataIndex: "name",
        key: "name",
        render: (text, record, index) => (
          <div>
            <Link target={"_blank"} to={`/maintenances/${record.id}/about`}>
              {record.name}
            </Link>
          </div>
        ),
      },
      {
        title: "Imovina",
        dataIndex: "machine",
        key: "machine",
        render: (text, record, index) => (
          <div>
            <Link
              target={"_blank"}
              to={`/machines/${record.machine_id}/specifications`}
            >
              {record.machine}
            </Link>
          </div>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
    ];
  }

  if (
    dataByStatus &&
    dataByStatus.length &&
    dataByOrders &&
    dataByOrders.length
  ) {
    if (type === "status") {
      let machine_run = status === "active";
      data = dataByStatus
        .filter((item) => item.machine_run === machine_run)
        .map((x) => ({
          key: x.machine_id,
          id: x.machine_id,
          name: x.name,
          category: x.category,
          location: x.location,
        }));
    } else {
      data = dataByOrders
        .filter((item) => item.type.name === info)
        .map((x) => ({
          key: x.id,
          id: x.id,
          machine_id: x.items[0].id,
          name: x.name,
          machine: x.items[0].name,
          status: STATUS[x.status],
        }));
    }
  }

  return { columns, data };
};

// Critical Parts

const getForParts = (criticalParts) => {

  let data = [];

  const columns = [
    {
      title: "Naziv",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => (
        <div>
          <Link target={"_blank"} to={`/parts/${record.id}/about`}>
            {record.name}
          </Link>
        </div>
      ),
    },
    {
      title: "Šifra",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Stanje",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Kritična razina",
      dataIndex: "critical_quantity",
      key: "critical_quantity",
    },
  ];

  data = criticalParts.map((x) => ({
    key: x.id,
    id: x.id,
    name: x.name,
    code: x.code,
    quantity: x.quantity,
    critical_quantity: x.critical_quantity,
  }));

  return { columns, data };
};

export { getForMaintenances, getForTechnicians, getForAssets, getForParts };
