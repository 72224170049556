import React, { useState } from "react";
import moment from "moment";

import { DatePicker, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { PICKED_DATE_SUCCESS } from "../../../redux/modules/Analytics/actions";
import { formatDate, formatWeek, formatMonth } from "./formats";
import { JURE_FORMAT_DATE } from "../../../helpers/consts";

const { RangePicker, WeekPicker, MonthPicker } = DatePicker;
const { Option } = Select;

export default function _DatePicker() {
  // Variables
  const dispatch = useDispatch();
  const [type, setType] = useState("month");
  const { pickedDate } = useSelector((state) => state.AnalyticsReducer);
  const dateFormat = undefined;

  // Methods
  function correctDate(a, b) {
    let date = moment();
    if (!b[0] || !b[1] || !a.length) {
      b = [];
      b[0] = date.startOf("month").format("YYYY-MM-DD");
      b[1] = date.endOf("month").format("YYYY-MM-DD");
      a[0] = date.startOf("month");
      a[1] = date.endOf("month");
    }
    switch (type) {
      case "date":
        return formatDate(b);
      case "week":
        return formatWeek(a);
      case "month":
        return formatMonth(a);
    }
  }

  function onOk(a, b) {
    dispatch({
      type: PICKED_DATE_SUCCESS,
      data: {
        from: correctDate(a, b).start,
        to: correctDate(a, b).end,
      },
    });
  }

  function PickerWithType({ type }) {
    switch (type) {
      // Date picker
      case "date":
        return (
          <RangePicker
            defaultValue={[
              moment(pickedDate.data.from),
              moment(pickedDate.data.to),
            ]}
            style={{ width: "280px" }}
            onChange={onOk}
          />
        );

      // Week picker
      case "week":
        return (
          <WeekPicker
            defaultValue={moment(pickedDate.data.from)}
            style={{ width: "280px" }}
            onChange={onOk}
          />
        );

      // Month picker
      case "month":
        return (
          <MonthPicker
            defaultValue={moment(pickedDate.data.from)}
            style={{ width: "280px" }}
            onChange={onOk}
          />
        );
    }
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <Select
        value={type}
        onChange={setType}
        style={{ marginRight: 16, width: 120 }}
      >
        <Option value="date">Datum</Option>
        <Option value="week">Tjedan</Option>
        <Option value="month">Mjesec</Option>
      </Select>
      <PickerWithType type={type} />
    </div>
  );
}
