import { Button, List, Modal } from "antd";
import React, { useState } from "react";
import SectionTitle from "../SectionTitle";
import Card from "./components/card";
import SpendForm from "../../../Parts/components/About/SpendForm";

export default function Parts({ order }) {
  const [part, set_part] = useState(false);
  

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <SectionTitle title="Dijelovi" subtext="Pregled dijelova na nalogu" />
        </div>
        <Button type={"primary"} onClick={() => set_part(true)}>
          Utroši
        </Button>
      </div>

      <List
        grid={{ gutter: 16, column: 3 }}
        itemLayout={"horizontal"}
        dataSource={order.data.parts}
        renderItem={(item) => <Card item={item} order_id={order.data.id} />}
      />
      <Modal
        closable={true}
        onCancel={() => set_part(false)}
        footer={null}
        destroyOnClose={true}
        visible={!!part}
        title="Utroši dio"
      >
        <SpendForm _part={null} work_order={order.data.id} close={() => set_part(false)} />
      </Modal>
    </>
  );
}
