import React, { Component } from "react";

import { Popconfirm, List, Icon } from "antd";

import ListItem from "./ListItem";
import nestedSorting from "../../../../helpers/NestedSorting";

class CategoriesList extends Component {
  state = {};

  render() {
    console.log(this.props);

    const { list } = this.props;

    const listHeader = "Postojeće kategorije";

    const listTree = nestedSorting(list, "parent");

    return (
      <List
        style={{ marginLeft: "32px", paddingTop: "0px", maxHeight: "75vh", overflow: "auto" }}
        header={<div className={"category-list"}>{listHeader}</div>}
        itemLayout="horizontal"
        dataSource={listTree}
        renderItem={(item) => (
          <ListItem set_updateData={this.props.set_updateData} item={item} />
        )}
      ></List>
    );
  }
}

export default CategoriesList;
