export const childsOfParent = (ids, data, arr = [], key = "parent") => {
  ids.forEach((asset_id) => {
    arr.push(asset_id);
    data.forEach((element) => {
      if (element[key] && element[key].id == asset_id) {
        childsOfParent([element.id], data, arr, key);
      }
    });
  });

  return [...new Set(arr)];
};

export const newChildsOfParent = (ids, data, arr = []) => {
  ids.forEach((asset_id) => {
    arr.push(asset_id);
    data.forEach((element) => {
      if (element["parent_id"] == asset_id) {
        childsOfParent([element.id], data, arr);
      }
    });
  });

  return [...new Set(arr)];
};

export default childsOfParent;
