import { Button, Col, message, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios_analytics from "../../../../axios/axios-analytics";
import { LOGOUT } from "../../../../redux/modules/Users/actions";
import { FileExcelFilled } from "@ant-design/icons";
import Information from "../../Assets/components/Cards/Information";

const WaitingParts = () => {
  const { pickedDate, partsReports } = useSelector(
    (state) => state.AnalyticsReducer
  );

  const dispatch = useDispatch();
  const [data, set_data] = useState({
    data: [],
    total_waiting_time: 0,
    avg_part_wait_time: 0,
    total_waiting_parts: 0,
  });
  const [loading, set_loading] = useState(true);
  const [loadingExcel, set_loadingExcel] = useState(false);
  const [COLUMNS, set_COLUMNS] = useState([
    {
      title: "Nalog",
      render: (text, record) => {
        let order = record.work_order;
        return <Link to={`/maintenances/${order?.id}/about`}>{order?.id || "Ne postoji link"}</Link>;
      },
    },
    {
      title: "Dio",
      dataIndex: "waiting_part",
    },
    {
      title: "Imovina",
      render: (text, record) => {
        let equipment = record.item;
        return `${equipment?.name}`;
      },
    },
    {
      title: "Vrijeme čekanja",
      render: (text, record) => {
        return `${record.part_waiting_duration.toFixed(1)} h`;
      },
    },
    {
      title: "Interval",
      render: (text, record) => {
        return `${record.date_range}`;
      },
    },
  ]);

  useEffect(() => {
    getWaitingParts();
  }, [pickedDate, partsReports.filters]);

  const getWaitingParts = async () => {
    set_loading(true);
    let token = localStorage.getItem("token");
    let queryParams = {
      from: pickedDate.data.from,
      to: pickedDate.data.to,
      assets: partsReports.filters.locations.join("|"),
    };
    try {
      let res = await axios_analytics({ token }).get(`parts_waiting_reason/`, {
        params: queryParams,
      });
      set_data(res.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({ type: LOGOUT });
        return;
      }
      console.log(error);
    }
    set_loading(false);
  };

  const createExcel = async () => {
    set_loadingExcel(true);
    let token = localStorage.getItem("token");
    let queryParams = {
      start_date: pickedDate.data.from,
      end_date: pickedDate.data.to,
      assets: partsReports.filters.locations?.length > 0 ? partsReports.filters.locations.join("|") : undefined,
    };
    try {
      let res = await axios_analytics({ token }).post(
        `export_part_waiting_reason/`,
       queryParams
      );
      getExcel(res.data.uid);
    } catch (error) {
      set_loadingExcel(false);
      if (error.response && error.response.status === 401) {
        dispatch({ type: LOGOUT });
        return;
      }
      message.error("Dogodila se greska kod kreiranja Excela.");
    }
  };

  const getExcel = async (uid) => {
    let token = localStorage.getItem("token");
    try {
      let res = await axios_analytics({ token }).get(`get_excel/?uid=${uid}`);
      console.log(res.data);
      downloadExcelFromBase64(res.data.file_content, `DijeloviNaCekanju_${pickedDate.data.from}-${pickedDate.data.to}`);
      set_loadingExcel(false);
    } catch (error) {
      set_loadingExcel(false);
      if (error.response && error.response.status === 401) {
        dispatch({ type: LOGOUT });
        return;
      }
      message.error("Dogodila se greska kod dohvata Excela.");
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
      
      </div>
      <Row style={{ marginTop: 24 }} gutter={[24]}>
        <Col span={8}>
          <Information
            hideHoursLabel={true}
            text={""}
            information={+data.total_waiting_time.toFixed(1) + "h"}
            label="Ukupno vrijeme čekanja"
          />
        </Col>
        <Col span={8}>
          <Information text={""} hideHoursLabel={true} information={data.total_waiting_parts} label="Ukupno čekanih dijelova" />
        </Col>
        <Col span={8}>
          <Information
            hideHoursLabel={true}
            text={""}
            information={+data.avg_part_wait_time.toFixed(1) + "h"}
            label="Srednje vrijeme čekanja"
          />
        </Col>
      </Row>

      {/* Export button */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
          marginTop: 40,
        }}
      >
        <div />
        <Button
          style={{ marginBottom: 12 }}
          loading={loadingExcel}
          onClick={createExcel}
          disabled={data.data?.length === 0}
        >
          Izvezi <FileExcelFilled />{" "}
        </Button>
      </div>

      {/* Table */}
      <Table
        pagination={false}
        loading={loading}
        columns={COLUMNS}
        dataSource={data.data}
      />
    </div>
  );
};

export default WaitingParts;

export function downloadExcelFromBase64(base64String, fileName) {
  // Convert base64 to byte array
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Create blob from byte array
  const blob = new Blob([byteArray], { type: "application/vnd.ms-excel" });

  // Create URL for blob
  const url = URL.createObjectURL(blob);

  // Create download link and click it
  const link = document.createElement("a");
  link.setAttribute("download", fileName);
  link.setAttribute("href", url);
  link.click();
}
