import React, { useState, useMemo } from 'react'

// UI
import css from '../../../../Accounting.module.scss'
import { Button, Row, Col, List, Upload, Icon, message } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

// Redux
import { useDispatch, useSelector } from "react-redux";

// Components
import ListItem from './components/ListItem'
import { ADD_DOCUMENT } from '../../../../../../redux/modules/Accounting/actions';

// Constants
const ALLOWED_EXTENSIONS = ".pdf,.xlsx"

export default function Documents() {

    // Variables
    const dispatch = useDispatch()
    const { documents, id } = useSelector(state => state.accoutingReducer.account.data.account)
    const _addDocument = useSelector(state => state.accoutingReducer.addDocument)
    const [fileList, setFileList] = useState([])
    const [formIsVisible, setFormIsVisible] = useState(false)

    // Methods
    function successCallback(){
        message.success("Uspješno ste dodali dokument.")
        // Clearing file list
        setFileList([])
    }

    function errorCallback(){
        message.error("Došlo je do pogreške prilikom dodavanja dokumenta.")
    }

    function addDocument(){
        const formData = new FormData();

        let data = JSON.stringify({account_id: id })
        formData.append('data', data)

        fileList.forEach(item => {
            formData.append('files', item)
        })

        dispatch({
            type: ADD_DOCUMENT,
            id,
            data: formData,
            successCallback,
            errorCallback,
            source: 'account'
        })
    };

    function beforeUpload(file){
        setFileList(prevArray => [...prevArray, file])
        return false;
    };

    function removeFile(file){
        let list = fileList.concat();
        const index = list.indexOf(file);
        list.splice(index, 1);
        setFileList(list)
    };

    function toggleForm() {
        setFormIsVisible(previousState => !previousState)
    }

    // 🔥
    const description = useMemo(() => {
        return (
            documents.length
                ? `Ukupno (${documents.length}) dokumenata`
                : "Nema dokumenata"
        )
    }, [documents])

    const form = useMemo(() => {
        if(formIsVisible){
            return (
                <Col md={24} lg={12}>
                    <div className={css.instructionsTitleContainer}>
                        <div>
                            <h1 className={css.instructionsTitleMainText}>Dodajte dokument</h1>
                            <h4 className={css.instructionsTitleSecondaryText}>Dozvoljene ekstenzije: { ALLOWED_EXTENSIONS }</h4>
                        </div>
                        <Button
                            style={{ marginTop: 10 }}
                            onClick={addDocument}
                            type="primary"
                            disabled={fileList.length === 0}
                            loading={_addDocument.status === "loading"}
                        >
                            Spremi
                        </Button>
                    </div>
                    <Upload
                        beforeUpload={beforeUpload}
                        onRemove={removeFile}
                        multiple={true}
                        fileList={fileList}
                        showUploadList={true}
                        accept={ALLOWED_EXTENSIONS}
                    >
                        <Button type={"primary"} style={{ marginTop: 10 }}>
                            <Icon type="upload" /> Učitajte dokument
                        </Button>
                    </Upload>
                </Col>
            )
        }
        return null
    }, [formIsVisible, fileList, _addDocument])

    return(
        <Row gutter={[24, 24]}>
            <Col md={24} lg={12}>
                <div className={css.instructionsTitleContainer}>
                    <div>
                        {/* Title */}
                        <h1 className={css.instructionsTitleMainText}>Dokumenti</h1>
                        {/* Description */}
                        <h4 className={css.instructionsTitleSecondaryText}>
                            { description }
                        </h4>
                    </div>

                    <div>
                        {/* CTA */}
                        <Button onClick={toggleForm} type={formIsVisible ? "default" : "primary"}>
                            { formIsVisible ? "Zatvori" : "Dodaj" }
                        </Button>
                    </div>
                </div>

                {/* List of documents */}
                <List
                    dataSource={documents}
                    renderItem={item => <ListItem item={item} />}
                />
            </Col>
            {/* Form */}
            { form }
        </Row>
    )
}