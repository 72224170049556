const nestedSorting = (data, key, disabledNodes = []) => {
  console.log(data);
  let items_ids = data?.map((x) => x.id);

  if (data.length === 1) {
    return data.map((item) => {
      const disabled = disabledNodes.includes(item.id);
      return {
        ...item,
        key: item.id,
        value: item.id,
        title: item.name,
        parent: item[key] ? item[key].id : null,
        selectable: !disabled,
      };
    });
  }

  data = sortByBoolean(data, key);
  data = data.sort(case_insensitive_comp);
  var comments = [
    ...data.map((item) => {
      const disabled = disabledNodes.includes(item.id);

      let has_parent = true;
      if (item[key] !== null && !items_ids.includes(item[key].id)) {
        has_parent = false; // znači da unutra poslanog niza ne postoji njegov parent stoga cemo mu null dodjeliti da bi se moglo kreirat stablo
      }

      return {
        ...item,
        key: item.id,
        value: item.id,
        title: item.name,
        parent: item[key] && has_parent ? item[key].id : null,
        selectable: !disabled,
        // isLeaf: !isLeaf(data, item.id, disabled, key),
      };
    }),
  ];

  var nodes = {};

  //insert artificial root node
  nodes[-1] = {
    name: "Fake root",
    replies: [],
    children: [],
  };

  //index nodes by their id
  comments.forEach(function (item) {
    if (item.parent == null) {
      item.parent = -1;
    }
    nodes[item.id] = item;
    item.replies = [];
    item.children = [];
  });

  //put items into parent replies
  comments.forEach(function (item) {
    var parent = nodes[item.parent];
    if (parent) {
      parent.replies.push(item);
      parent.children.push(item);
    }
  });

  //root node replies are top level comments

  return nodes[-1].replies;
};

export default nestedSorting;

export const sortByBoolean = (data, key) =>
  data.sort(function (x, y) {
    // true values first
    return !!x[key] === !!y[key] ? 0 : !!x[key] ? -1 : 1;
    // false values first
    // return (x === y)? 0 : x? 1 : -1;
  });

export function case_insensitive_comp(strA, strB) {
  return strA.name.toLowerCase().localeCompare(strB.name.toLowerCase());
}

const isLeaf = (data, id, disabled, key) => {
  if (disabled) {
    return false;
  }
  const child = data.find((x) => x[key] && x[key].id === id);

  if (child === -1 || child === undefined) {
    return false;
  }

  return true;
};
