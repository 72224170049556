import React, { Component } from "react";

import { Form, Button, Input, Alert } from "antd";

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class ChangePassword extends Component {
  state = {
    successVisible: false,
  };

  alertSuccess = () => {
    this.setState({ successVisible: true });
    setTimeout(() => {
      this.setState({ successVisible: false });
    }, 3000);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.password !== values.password2) {
          this.props.form.setFields({
            password2: {
              errors: [new Error("Lozinka se ne podudara!")],
            },
          });
          return;
        }

        this.props._changePassword(
          this.props.currentUser.id,
          {
            old_password: values.oldPassword,
            new_password: values.password,
          },
          this.alertSuccess.bind(this)
        );
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;

    const alert = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1" }}
        message="Dogodila se greša"
        description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
        type="error"
      />
    );
    const alertWrongOldPassword = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1" }}
        message="Dogodila se greša"
        description="Niste ispravno unijeli staru lozinku!"
        type="error"
      />
    );
    const alertSuccess = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1", maxWidth: "400px" }}
        message="Uspješno!"
        type="success"
      />
    );

    return <h3>U IZRADI</h3>

    return (
      <>
        {this.props.changePassword.status === "error" ? alert : null}
        {this.state.successVisible ? alertSuccess : null}
        {this.props.changePassword.status === "wrongOldPassword" ? alertWrongOldPassword : null}
        <Form
          style={{ maxWidth: "400px" }}
          layout="vertical"
          onSubmit={this.handleSubmit}
        >
          <Form.Item label="Stara lozinka">
            {getFieldDecorator("oldPassword", {
              rules: [
                {
                  required: true,
                  message: "Unesite staru lozinku!",
                },
              ],
            })(<Input.Password placeholder="Lozinka" />)}
          </Form.Item>
          <Form.Item label="Lozinka">
            {getFieldDecorator("password", {
              rules: [
                {
                  required: true,
                  message: "Unesite lozinku!",
                },
                { min: 6, message: "minimalno 6 znakova" },
              ],
            })(<Input.Password placeholder="Lozinka" />)}
          </Form.Item>

          <Form.Item label="Potvrdite lozinku">
            {getFieldDecorator("password2", {
              dependencies: ["password"],
              rules: [
                {
                  required: true,
                  message: "Unesite lozinku!",
                },
                { min: 6, message: "Minimalno 6 znakova!" },
              ],
            })(<Input.Password placeholder="Potvrdite lozinku" />)}
          </Form.Item>
          <Form.Item>
            <Button
              loading={this.props.changePassword.status === "loading"}
              type="primary"
              htmlType="submit"
              disabled={hasErrors(getFieldsError())}
            >
              Spremi
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }
}

export default Form.create()(ChangePassword);
