import React from "react";
import SectionTitle from "../SectionTitle";

import { List, Avatar, Empty, Button, message } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { STATUS_KEYS } from "../../../../helpers/consts";

export default function Technicians({
  order,
  order: {
    data: { technicians },
  },
}) {
  return (
    <>
      <SectionTitle title="Tehničari" subtext="Pregled tehničara na nalogu" />

      {technicians.length ? (
        <List
          style={{ width: 600 }}
          itemLayout="horizontal"
          dataSource={technicians}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                }
                title={
                  <>
                    <span>{item.account.full_name}</span><span style={{display: "inline-block", width: 15}}></span>
                    {order.data.status === STATUS_KEYS.CLOSED || order.data.status === STATUS_KEYS.FINISHED ? (
                      <span>{!item.is_worked ? "Nije radio" : null}</span>
                    ): null}
                  </>
                }
                description={
                  <>
                    <div>{item.account.email}</div>
                    {item.supplier_url && (
                      <div>
                        {" "}
                        <span>{item.supplier_url}</span>
                        <Button
                          style={{ marginLeft: 21 }}
                          onClick={() => {
                            copyToClipboard(item.supplier_url)
                              .then(() => message.success("Kopirano!"))
                              .catch(() => console.log("Neuspjelo kopiranje"));
                          }}
                        >
                          <CopyOutlined />
                        </Button>
                      </div>
                    )}
                  </>
                }
              />
            </List.Item>
          )}
        />
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
}

function copyToClipboard(textToCopy) {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  } else {
    // text area method
    let textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand("copy") ? res() : rej();
      textArea.remove();
    });
  }
}
