import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../../../components/LoadingScreen";
import { REPORT_FOR_ADMINISTRATION } from "../../../../../redux/modules/Analytics/actions";
import css from "../../assets.module.scss";

const SecondReport = () => {
  // Hooks
  const dispatch = useDispatch();

  // State
  const { reportForAdministration } = useSelector((state) => state.AnalyticsReducer);
  const { pickedDate } = useSelector((state) => state.AnalyticsReducer);

  // Methods
  useEffect(() => {
    const queryParams = {
      start: pickedDate.data.from,
      end: pickedDate.data.to,
    };

    fetchData(queryParams);
  }, [pickedDate]);

  function fetchData(queryParams) {
    dispatch({ type: REPORT_FOR_ADMINISTRATION, queryParams });
  }

  if (reportForAdministration.status === "loading") {
    return <Loading />;
  }

  return (
    <div id={css.secondReportContainer}>
      <embed
        src={`data:application/pdf;base64,${reportForAdministration.data.file_content}`}
        type="application/pdf"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default SecondReport;
