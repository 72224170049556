import { DownOutlined } from "@ant-design/icons/lib/icons";
import { List } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import React, { useEffect, useState } from "react";

import ReactDragListView from "react-drag-listview";
const Columns = ({
  columns = [],
  onChange,
  title = "Kolone",
  memorizedName,
}) => {
  const [listOpen, set_listOpen] = useState(false);
  const [listItemHeight, set_listItemHeight] = useState(0);

  const listInnerCss = `columnsList ${listOpen ? "columnsListOpend" : ""}`;

  useEffect(() => {
    const el = document.getElementsByClassName("ListItemCustomClass");
    set_listItemHeight(el[0]?.offsetHeight || 0);
  }, []);

  const onDragEnd = async (fromIndex, toIndex) => {
    if (toIndex < 0) return; // Ignores if outside designated area

    const items = [...columns];
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);

    const sortBy = items.map((x) => ({
      visible: x.visible,
      dataIndex: x.dataIndex,
    }));

    await localStorage.setItem(
      memorizedName,
      JSON.stringify(
        items.map((x) => ({ visible: x.visible, dataIndex: x.dataIndex }))
      )
    );

    onChange(items);
  };

  const onChangeVisibility = async (index) => {
    const items = [...columns];
    const item = { ...items[index] };
    item.visible = !item.visible;
    items[index] = item;

    await localStorage.setItem(
      memorizedName,
      JSON.stringify(
        items.map((x) => ({ visible: x.visible, dataIndex: x.dataIndex }))
      )
    );

    onChange(items);
  };

  if (!columns.length) {
    return null;
  }

  return (
    <div style={{ maxWidth: 210, minWidth: 120 }}>
      <div
        onClick={() => set_listOpen(!listOpen)}
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
          padding: "6px 9px",
          border: "1px solid #e8e8e8",
          backgroundColor: "rgba(226, 226, 226, 0.3)",
          borderRadius: 3,
        }}
      >
        <div style={{ color: "rgba(0, 0, 0, 0.85)" }}>{title}</div>
        <div>
          <div
            className="IconDropDown"
            style={{ transform: listOpen ? "rotate(180deg)" : "" }}
          >
            <DownOutlined color="rgba(0, 0, 0, 0.85)" />
          </div>
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <div
          className={listInnerCss}
          style={{ maxHeight: listOpen && listItemHeight * columns.length }}
        >
          <ReactDragListView
            nodeSelector=".ant-list-item.draggble"
            onDragEnd={onDragEnd}
          >
            <List
              style={{
                padding: "0 6px",
                borderRadius: 3,
                border: "1px solid rgb(232 232 232)",
              }}
              dataSource={columns}
              renderItem={(item, index) => {
                if (item.visible === "always") {
                  return <span></span>;
                }
                return (
                  <List.Item
                    className="ListItemCustomClass draggble"
                    actions={[
                      <Checkbox
                        checked={item.visible}
                        onChange={() => onChangeVisibility(index)}
                      />,
                    ]}
                  >
                    <List.Item.Meta description={item.title} />
                  </List.Item>
                );
              }}
            />
          </ReactDragListView>
        </div>
      </div>
    </div>
  );
};

export default Columns;
