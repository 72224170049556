import React from "react";

const OrderDetails = (props) => (
  <>
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        maxWidth: "210px",
      }}
    >
      <div>Kategorija:</div>
      <div>{props.category ? props.category.name : "Nije navedena"}</div>
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        maxWidth: "210px",
      }}
    >
      <div>Tip:</div>
      <div className={props.type.id === 2 ? "blink_me" : ""}>
        {props.type.name}
      </div>
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        maxWidth: "210px",
      }}
    >
      <div>Lokacija:</div>
      <div>{"Lokacija"}</div>
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        maxWidth: "210px",
      }}
    >
      <div>Imovina:</div>
      <div>
        {props.items.map((item) => (
          <span key={new Date().getTime()}> {item.name}</span>
        ))}
      </div>
    </div>
    <div style={{ height: "12px" }}></div>
  </>
);

export default OrderDetails;
