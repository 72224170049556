import { Button, Drawer, message, Modal, Popconfirm, Spin, Table } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SPENTS_WITHOUT_PARTS,
  SPENTS_WITHOUT_PARTS_SUCCESS,
} from "../../../../redux/modules/Parts/actions";
import PairSpendForm from "../PairSpendForm";
import PartForm from "../PartForm";

const SpendWithoutParts = ({}) => {
  const dispatch = useDispatch();

  const { spentsWithoutParts, categories } = useSelector(
    (state) => state.partsReducer
  );

  const { accounts } = useSelector((state) => state.accoutingReducer);

  const [spendPairForm, set_spendPairForm] = useState(null);
  const [createPart, set_createPart] = useState();

  useEffect(() => {
    dispatch({
      type: SPENTS_WITHOUT_PARTS,
      errorCallback: () => message.error("Dogodila se greška kod dohvata..."),
    });
  }, []);

  const COLUMNS = [
    {
      title: "Naziv",
      dataIndex: "part",
      render: (text, value) => value.name,
    },
    { title: "Količina", dataIndex: "qty" },
    {
      title: "Kreirano",
      dataIndex: "created_at",
      render: (text, value) => moment(text).format("HH:mm DD-MM-YYYY"),
    },

    {
      title: "",
      dataIndex: "action",
      render: (text, value) => (
        <div>
          <Button
            style={{ margin: 6 }}
            type="primary"
            onClick={() => set_spendPairForm(value)}
            loading={false}
          >
            Upari
          </Button>
          <Button
            loading={false}
            style={{ margin: 6 }}
            type="primary"
            onClick={() => set_createPart(value)}
          >
            Kreiraj
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Spin spinning={spentsWithoutParts.status === "loading"}>
        <Table
          rowKey={(item) => item.id}
          dataSource={spentsWithoutParts.data}
          columns={COLUMNS}
        />
      </Spin>
      <Modal
        visible={!!spendPairForm}
        onCancel={() => set_spendPairForm(null)}
        footer={null}
        title="Upari"
      >
        <PairSpendForm
          close={() => set_spendPairForm(null)}
          data={spentsWithoutParts.data}
          spend={spendPairForm}
        />
      </Modal>
      <Drawer
        title="Kreiranje dijela iz utroška"
        width={696}
        visible={!!createPart}
        onClose={() => set_createPart(null)}
      >
        <PartForm
          accounts={accounts.data}
          categories={categories.data}
          createPartFromSpend={createPart}
          close={() => {
            const arr = [...spentsWithoutParts.data];
            const index = arr.findIndex((x) => x.id === createPart.id);
            arr.splice(index, 1);
            dispatch({ type: SPENTS_WITHOUT_PARTS_SUCCESS, data: arr });
            set_createPart(null);
          }}
        />
      </Drawer>
    </div>
  );
};

export default SpendWithoutParts;
