import { Button, Form, message, Modal, Spin } from "antd";
import Axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApiEndpoint } from "../../../../axios/endpoints";
import {
  GET_ORDER,
} from "../../../../redux/modules/Maintenances/actions";
import { DESTROY_TOKEN_FOR_REDIRECT } from "../../../../redux/modules/Users/actions";
import { technicianOfOrder } from "../Reports/components/Report/addReportForm";
import PauseReasonForm from "./PauseReasonForm";
import Finish from "./Finish";
import { canFinishOrder } from "../../../../helpers/functions";

const Comands = ({ close }) => {
  const { order } = useSelector((state) => state.maintenanceReducer);
  const { user } = useSelector((state) => state.usersReducer);

  const ComandTypes = [
    { name: "Preuzmi", commandAction: "take_order" },
    { name: "Kreni", commandAction: "start_work" },
    { name: "Pauza", commandAction: "pause_work" },
    { name: "Zavrsi", commandAction: "finish_work" },
  ];

  const [loading, set_loading] = useState(false);
  const dispatch = useDispatch();
  const [pauseModalVisible, set_pauseModalVisible] = useState(false);
  const [finishModalVisible, set_finishModalVisible] = useState(false);

  const isActionDisabled = (commandAction, order) => {
    switch (commandAction) {
      case "take_order":
         return !( order.status === "in_bowl" || order.status === "reported" ||  order.status === "waiting") ;
        case "start_work":
            if(!isUserParticipiantOfOrder(order, user.data)){
              return true;
            }
            return( order.status !== "appointed" && order.status !== "waiting")
        case "pause_work":
          if(!isUserParticipiantOfOrder(order, user.data)){
            return true;
          }
            return order.status !== "in_progress"
        case "finish_work":
          if(!isUserParticipiantOfOrder(order, user.data)){
            return true;
          }
            return order.status !== "in_progress"
  
      default:
          break;
  }
  };

  const getBodyBasedOnCommand = (commandAction, _body) => {
    if (_body) {
      return _body; //Kada je u pitanju pausa
    }
    let body = undefined;
    switch (commandAction) {
      case "take_order":
        return { technicians: [{ account: user.data.id, is_primary: 1 }] };

      case "finish_work":
        return { schedule_id: order.data.schedule?.id || null };

      default:
        break;
    }
  };

  const action = async (commandAction, body) => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    let endpointFlag = commandAction;


    if(endpointFlag === "start_work" && order.data.status === "waiting"){
        endpointFlag = "continue_work"; // nije isto nastaviti i kreniti ....:(   
    }
    try {
      let res = await Axios.post(
        `${getApiEndpoint()}maintenances/work_orders/${
          order.data.id
        }/${endpointFlag}/`,
        getBodyBasedOnCommand(commandAction, body),
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      );
    //   close();
      dispatch({ type: GET_ORDER, order_id: order.data.id });
    } catch (error) {
      console.log(error.response.data);
      
      if (error.response && error.response.status === 401) {
        dispatch({ type: DESTROY_TOKEN_FOR_REDIRECT });
        return;
      }

      message.error(error.response?.data?.errors?.non_field_errors[0] || "Dogodila se greška.");
    }

    set_loading(false);
  };


  return (
    <>
      <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {ComandTypes.map((x, i) => (
            <Button
                disabled={isActionDisabled(x.commandAction, order.data)}
              onClick={() => {
                if (x.commandAction !== "pause_work" && x.commandAction !== "finish_work") {
                  action(x.commandAction);
                } else if(x.commandAction === "pause_work") {
                  set_pauseModalVisible(true);
                }else if(x.commandAction === "finish_work"){
                  if(canFinishOrder(order.data) === false){
                    message.warning("Označite stavke koje su završene!");
                    return;
                  }
                   set_finishModalVisible(true);
                }
              }}
              type="primary"
              key={i}
            >
              {x.name}
            </Button>
          ))}
        </div>
      </Spin>
      <Modal
        title="Pauza"
        visible={pauseModalVisible}
        onCancel={() => set_pauseModalVisible(false)}
        footer={null}
        destroyOnClose
      >
        <PauseReasonForm
          onSave={(values) =>{
            action("pause_work", {
                ...values,
                order_id: order.data.id,
                technician_id: technicianOfOrder(order.data, user)?.id,
                is_primary: isTechnicianPrimary(order.data, user),
              })
              
            set_pauseModalVisible(false);


          }
           
          }
          close={() => set_pauseModalVisible(false)}
        />
      </Modal>
      <Modal
        title="Završi"
        visible={finishModalVisible}
        onCancel={() => set_finishModalVisible(false)}
        footer={null}
        destroyOnClose
      >
        <Finish close={() => {
          set_finishModalVisible(false);
          close();
        }} />
      </Modal>
    </>
  );
};

export default Comands;

export const isTechnicianPrimary = (order, user) => {
  // currentUser
  const technician = order.technicians.find(
    (tec) => tec.account.id === user.id
  );
  if (technician === -1 || technician === undefined) {
    return false;
  }

  return technician.is_primary;
};

export const isUserParticipiantOfOrder = (order, user) => {
  // currentUser
  const technician = order.technicians.find(
    (tec) => tec.account.id === user.id
  );
  if (technician === -1 || technician === undefined) {
    return false;
  }

  return true;
};
