import React, { useEffect, useState } from "react";

import { Button, message, Tabs } from "antd";

import Filters from "./components/Filters";
import {
  useHistory,
  useRouteMatch,
  NavLink,
  Switch,
  Route,
} from "react-router-dom";

// Components
import { useDispatch } from "react-redux";
import { GET_PARTS_CATEGORIES } from "../../../redux/modules/Parts/actions";
import WaitingParts from "./components/WaitingParts";

const { TabPane } = Tabs;

const Parts = ({}) => {
  const match = useRouteMatch();
  const history = useHistory();

  const dispatch = useDispatch();

  const [activeKey, set_activeKey] = useState("reports_1");
  const [filtersVisible, set_filtersVisible] = useState(false);

  useEffect(() => {
    dispatch({
      type: GET_PARTS_CATEGORIES,
      errorCallback: () =>
        message.error("Dogodila se greška kod dohvata kategorije dijelova..."),
    });
  }, []);

  return (
    <>
      <div>
        <Tabs
          tabBarExtraContent={
            <Button
              onClick={() => {
                set_filtersVisible(true);
              }}
            >
              Filteri
            </Button>
          }
          defaultActiveKey={activeKey}
          onChange={(key) => {
            history.replace(`/analytics/parts/${key}`);
          }}
        >
          {TABS.map((item) => (
            <TabPane
              tab={
                <>
                  {item.icon}
                  <NavLink to={`${match.url}/${item.path}`}>
                    {item.name}
                  </NavLink>
                </>
              }
              key={item.path}
            />
          ))}
        </Tabs>
      </div>
      <div>
        <Switch>
        <Route
            path={`${match.path}/reports_1`}
            render={() => <WaitingParts />}
          />
        </Switch>
      </div>

      <Filters
        filtersVisible={filtersVisible}
        set_filtersVisible={set_filtersVisible}
      />
    </>
  );
};

export default Parts;

const TABS = [
  {
    name: "Na čekanju",
    path: "reports_1",
  },
];
