import { Alert, Row, Col, Table, Button } from "antd";
import React, { useState, useEffect } from "react";
import { ASSET_MAINTENANCE_SUMMARY, REPORTER_GENERAL_EFFECT } from "../../../../redux/modules/Analytics/actions";
import ExportCSV from "../../components/ExportCSV";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../../../components/LoadingScreen";
import { crohHumanizer } from "../../../../helpers/consts";
import Information from "../../Assets/components/Cards/Information";

const Reports_2 = ({}) => {
  const [dataCSV_table_1, set_dataCSV_table_1] = useState([{ id: 0, name: "" }]);

  const { reporterGeneralEffect, pickedDate } = useSelector((state) => state.AnalyticsReducer);

  const getReports = (queryParams) => {
    dispatch({ type: REPORTER_GENERAL_EFFECT, queryParams });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    const queryParams = {
      from: pickedDate.data.from,
      to: pickedDate.data.to,
    };
    getReports(queryParams);
  }, [pickedDate]);

  useEffect(() => {
    const queryParams = {
      from: pickedDate.data.from,
      to: pickedDate.data.to,
    };

    getReports(queryParams);
  }, []);

  useEffect(() => {
    let tmpArr = reporterGeneralEffect.data.data.map((x) => ({
      Korisnik: x.full_name,
      "Broj prijava": x.report_num,
      "Broj potvrda": x.confirm_num,
      "Prosječno vrijeme do potvrde": crohHumanizer(Math.ceil(+x.avg_time)),
    }));

    set_dataCSV_table_1(tmpArr);
  }, [reporterGeneralEffect]);

  if (reporterGeneralEffect.status === "loading") {
    return <Loading />;
  }

  const TABLE_1 = [
    {
      title: "Korisnik",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Broj prijava",
      dataIndex: "report_num",
      key: "report_num",
    },
    {
      title: "Broj potvrda",
      dataIndex: "confirm_num",
      key: "confirm_num",
    },
    {
      title: "Prosječno vrijeme do potvrde",
      dataIndex: "avg_time",
      key: "avg_time",
      render(text, record) {
        return crohHumanizer(Math.ceil(+record.avg_time));
      },
    },
  ];

  return (
    <div>
      {reporterGeneralEffect.status === "error" ? (
        <Alert
          style={{ width: "639px", marginTop: "10px", zIndex: "1" }}
          message="Dogodila se greška"
          description="Dogodila se greška prilikom dohvata podataka molimo vas probajte ponovo!"
          type="error"
        />
      ) : null}
      <Row gutter={24} style={{ margin: "20px 0" }}>
        <Col span={8}>
          <Information
            hideHoursLabel={true}
            text={""}
            information={crohHumanizer(Math.ceil(+reporterGeneralEffect.data.total_time_until_closed), {
              maxDecimalPoints: 0,
            })}
            label="Ukupno vrijeme do potvrde"
          />
        </Col>
        <Col span={8}>
          <Information
            text={""}
            hideHoursLabel={true}
            information={crohHumanizer(Math.ceil(+reporterGeneralEffect.data.avg_time_until_closed), {
              maxDecimalPoints: 0,
            })}
            label="Prosječno vrijeme do potvrde"
          />
        </Col>
      </Row>

      {/* Export button */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 40,
          marginBottom: 20

        }}
      >
        <div />
        <ExportCSV
          csvData={dataCSV_table_1}
          fileName={`Učinak prijavitelja ${pickedDate.data.from} - ${pickedDate.data.to}`}
        />
      </div>

      <Row gutter={[24, 24]}>
        <Col span={24}>
          {" "}
          <Table
            pagination={false}
            rowKey={(item) => item.avg_time}
            columns={TABLE_1}
            dataSource={reporterGeneralEffect.data.data}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Reports_2;
