import moment from "moment";

import { crohHumanizer } from "./consts";

const formatDays = (time) => {


  return crohHumanizer(moment(new Date()).diff(moment(time)));
};

export default formatDays;

function parse(time) {
  let s = moment(new Date(time)).format("D hh [h]:mm [m]");
  s = s.split(" ");

  let dayString = s[0] == 1 ? " dan i " : " dana i ";
  s[1] = +s[1] - 1;
  s[0] = +s[0] - 1 + dayString;

  return s.join("");
}
