import childsOfParent from "./cildsOfParent";
import { LOCATION_TYPES } from "./consts";

const getWarehouseLocations = (locations, asset_locations_ids) => {
  let child_ids = childsOfParent(
    asset_locations_ids,
    locations,
    [],
    "location_child"
  );

  let tmp_locations = [];

  let wrh_locations = locations.filter(
    (x) => x.type === LOCATION_TYPES.WARHOUSE
  );

  asset_locations_ids.forEach((e) => {
    wrh_locations.push(locations.find((x) => x.id === e));
  });

  wrh_locations.forEach((element) => {
    let newRef = { ...element };
    if (asset_locations_ids.includes(newRef.id)) {
      newRef["location_child"] = null;
      newRef["location_child_id"] = null;
    }
    if (child_ids.includes(newRef.id)) {
      tmp_locations.push(newRef);
    }
  });

  // jos dodat lokaciju skladista sto nije tip skladiste al je vezana na imovinu


  return tmp_locations;
};

export default getWarehouseLocations;
