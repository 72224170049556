import React, { Component } from "react";
import { Route, Link, Switch } from "react-router-dom";
import { Redirect, withRouter } from "react-router";

import "./App.scss";

import { connect } from "react-redux";

import Login from "./containers/Auth";
import Dashboard from "./containers/Dashboard";
import Logout from "./containers/Auth/Logout";
import Profile from "./containers/Profile";
import Spinner from "./components/LoadingScreen";
import Accounting from "./containers/Accounting";
import AccountingProfile from "./containers/Accounting/components/Profile";
import Suppliers from "./containers/Suppliers";
import SupplierProfile from "./containers/Suppliers/components/Profile";
import Locations from "./containers/Locations";
import Machines from "./containers/Machines";
import Machine from "./containers/Machine";
import Order from "./containers/Dashboard/components/Order";
import Maintenances from "./containers/Maintenances";
import Maintenance from "./containers/Maintenance";
import Parts from "./containers/Parts";
import Part from "./containers/Parts/Part";
import Analytics from "./containers/Analytics";
import Insurances from "./containers/Insurance";
import Insurance from "./containers/Insurance/profile";

import LOGO_IMG from "./assets/icons/logo.png";

import moment from "moment";

import * as actions from "./redux/modules/Users/actions";

import ProtectedRoute from "./helpers/ProtectedRoute";

import {
  ToolOutlined,
  DownOutlined,
  BarcodeOutlined,
  UsergroupAddOutlined,
  PushpinOutlined,
  SettingOutlined,
  BulbOutlined,
  LineChartOutlined,
  ExceptionOutlined,
  FilePdfOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";

import TypeSize from "./helpers/deviceTypeSize";

import NavBar from "./components/Sidebar/NavBar";
import SideBar from "./components/Sidebar/SideBar";
import { Chart } from "chart.js";

// AntDesign Components
import { Layout, Avatar, Col, Row, Icon, Dropdown, Menu, Button } from "antd";
import OpenOrder from "./containers/OpenOrder";
import Details from "./containers/OpenOrder/components/Details";
import Materials from "./containers/Materials";
import ReportConfirm from "./containers/ReportConfirm";
import { GET_PREPARE_REGISTRATION } from "./redux/modules/Accounting/actions";
import Planing from "./containers/Planing";
import { deploy } from "./axios/endpoints";
import logo from "./assets/icons/header_logo.png";

export const CONTROL_ID = 4;

const { Header, Content } = Layout;

class AppWrapper extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    key: "0",
    contentIndex: 0,
    count: 0,
    collapsed: false,
  };

  toggleCollapsed = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  componentDidMount() {
    const token = localStorage.getItem("token") || "";
    this.props.onSetTokenFromLocaleStorage(token);
    this.props.onGetUser();
    // this.props.onGetPrepare();
    this.setState({ count: 1 });
    Chart.defaults.global.defaultFontFamily = "Poppins";
  }

  setVisible = (bool) => {
    this.setState({ visibleMenu: bool });
  };

  changeSelectedKey = (event) => {
    const key = event.key;
    this.setState({ key });
    let tmp = +key;
    this.setState({ key: tmp });
  };

  setCounter = () => {
    this.state.count++;
  };

  goBack = async () => {
    let page = (await localStorage.getItem("page")) || 1;

    let from =
      (await localStorage.getItem("startDate")) || moment(new Date()).subtract(3, "d").format("YYYY-MM-DD HH:mm");
    let to = (await localStorage.getItem("endDate")) || moment(new Date()).add(1, "h").format("YYYY-MM-DD HH:mm");

    this.props.history.push(`/maintenances?page=${page || 1}&startDate=${from}&endDate=${to}`);
  };

  render() {
    if (
      this.props.loading === "loading_user" ||
      this.props.prepareRegistration.status === "loading" ||
      this.props.accountingPreprare.status === "loading"
    ) {
      return <Spinner />;
    }

    let location = this.props.location.pathname.split("/")[1];

    if (location === "insuranceorder" || location === "openorder") {
      location = "openorder";
    }

    if (!this.props.auth && location !== "openorder") {
      return <Route path="/" component={Login} />;
    }

    let defaultMenuKey = `/${this.props.location.pathname.split("/")[1]}`;

    const { currentUser } = this.props;
    // currentUser.category = "WRH"

    let externalUser = false;

    const profilMenu = (
      <Menu>
        <Menu.Item>
          <Link to="/logout"> Logout</Link>
        </Menu.Item>
      </Menu>
    );

    const _Menu = location !== "openorder" && (
      <Menu
        mode="inline"
        defaultSelectedKeys={[defaultMenuKey]}
        defaultOpenKeys={["dashboard"]}
        style={{ height: "100%", borderRight: 0, position: "relative" }}
      >
        <Menu.Item key="/dashboard" title="Dashboard">
          {" "}
          <Link to="/dashboard">
            <Icon theme="twoTone" type="dashboard" />
            {this.state.collapsed ? null : "Nadzorna ploča"}
          </Link>
        </Menu.Item>
        {((currentUser.category === "MAN" || currentUser.category === "LDR") || currentUser.category === "LDR") || true ? (
          <Menu.Item key="/maintenances" title="Povijest održavanja">
            <Link to="/maintenances">
              <ToolOutlined style={{ color: "#1890ff" }} />
              {this.state.collapsed ? null : "Povijest održavanja"}
            </Link>
          </Menu.Item>
        ) : null}

        {(currentUser.category === "MAN" || currentUser.category === "LDR") ? (
          <Menu.Item key="/machines" title="Imovina">
            <Link to="/machines">
              <SettingOutlined
                style={{
                  color: "#1890ff",
                  position: "relative",
                  display: "inlineBlock",
                  left: this.state.collapsed ? 0 : "-7px",
                  bottom: this.state.collapsed ? 0 : "-4px",
                  margin: 0,
                }}
              />
              <SettingOutlined
                style={{
                  color: "#1890ff",
                  position: "relative",
                  display: "inlineBlock",
                  left: "-10px",
                  top: "-4px",
                  margin: 0,
                }}
              />
              {this.state.collapsed ? "" : "Imovina"}
            </Link>
          </Menu.Item>
        ) : null}

        {(currentUser.category === "MAN" || currentUser.category === "LDR") ? (
          <Menu.Item key="/planning" title="Planiranje održavanja">
            <Link to="/planning">
              <FileDoneOutlined style={{ color: "#1890ff" }} />
              {this.state.collapsed ? null : "Planiranje održavanja"}
            </Link>
          </Menu.Item>
        ) : null}

        {(currentUser.category === "MAN" || currentUser.category === "LDR") ? (
          <Menu.Item key="/accounting" title="Ljudski resursi">
            <Link to="/accounting">
              <UsergroupAddOutlined style={{ color: "#1890ff" }} />
              {this.state.collapsed ? null : "Ljudski resursi"}
            </Link>
          </Menu.Item>
        ) : null}

        {(currentUser.category === "MAN" || currentUser.category === "LDR") ? (
          <Menu.Item key="/materials" title="Dokumenti">
            <Link to="/materials">
              <FilePdfOutlined style={{ color: "#1890ff" }} />
              {this.state.collapsed ? null : "Dokumenti"}
            </Link>
          </Menu.Item>
        ) : null}

        {(currentUser.category === "MAN" || currentUser.category === "LDR") || currentUser.category === "WRH" ? (
          <Menu.Item key="/parts" title="Dijelovi">
            <Link to="/parts/parts">
              <BulbOutlined style={{ color: "#1890ff" }} />
              {this.state.collapsed ? null : "Dijelovi"}
            </Link>
          </Menu.Item>
        ) : null}

        {(currentUser.category === "MAN" || currentUser.category === "LDR") ? (
          <Menu.Item key="/analytics/asstes" title="Analitika">
            <Link to="/analytics/assets/reports_1">
              <LineChartOutlined style={{ color: "#1890ff" }} />
              {this.state.collapsed ? null : "Analitika"}
            </Link>
          </Menu.Item>
        ) : null}

        {(currentUser.category === "MAN" || currentUser.category === "LDR") ? (
          <Menu.Item key="/insurance" title="Osiguranje">
            <Link to="/insurance">
              <ExceptionOutlined style={{ color: "#1890ff" }} />
              {this.state.collapsed ? null : "Osiguranje"}
              {currentUser.num_of_reported_insurance_orders ? (
                <div
                  style={{
                    marginLeft: 15,
                    marginLeft: 15,
                    display: "inline-block",
                    width: 27,
                    height: 27,
                    position: "relative",
                    fontSize: 15,
                    border: "1px solid",
                    color: "#f3a305",
                    borderRadius: "50%",
                    borderWidth: 2,
                    padding: 2,
                    lineHeight: 1.3,
                    textAlign: "center",
                  }}
                >
                  {currentUser.num_of_reported_insurance_orders}
                </div>
              ) : null}
            </Link>
          </Menu.Item>
        ) : null}

        {(currentUser.category === "MAN" || currentUser.category === "LDR") ? (
          <Menu.Item key="/suppliers" title="Partneri">
            <Link to="/suppliers">
              <BarcodeOutlined style={{ color: "#1890ff" }} />
              {this.state.collapsed ? null : "Partneri"}
            </Link>
          </Menu.Item>
        ) : null}
        {(currentUser.category === "MAN" || currentUser.category === "LDR") ? (
          <Menu.Item key="/locations" title="Lokacije">
            <Link to="/locations">
              <PushpinOutlined style={{ color: "#1890ff" }} />
              {this.state.collapsed ? null : "Lokacije"}
            </Link>
          </Menu.Item>
        ) : null}

        {currentUser.category === "REP" ? (
          <Menu.Item key="/reportconfirm" title="Prijavi">
            <Link to="/reportconfirm">
              <PushpinOutlined style={{ color: "#1890ff" }} />
              {this.state.collapsed ? null : "Prijavi/Potvrdi"}
            </Link>
          </Menu.Item>
        ) : null}
      </Menu>
    );

    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Header
          id="header"
          style={{
            padding: "0 24px",
            background: location !== "openorder" ? "#4675ff" : "white",
          }}
        >
          {location !== "openorder" ? (
            <Row>
              <Col span={24} xs={24} sm={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* Logo wrapper */}
                <div
                  style={{
                    // backgroundColor: "#fff",
                    width: 212,
                    height: "100%",
                    transform: "translateX(-32px)",
                    paddingLeft: 32,
                    borderBottom: "1px solid #4675ff",
                    position: "relative",
                  }}
                >
                  <img src={logo} style={{ width: 120 }} />
                </div>

                <div
                  style={{
                    color: "#fff",
                    display: "flex",
                    flexDirection: "row-reverse",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    position: "relative",
                    float: "right",
                  }}
                >
                  <nav className="navbar">
                    <NavBar menu={_Menu} />
                  </nav>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      icon="user"
                      size="small"
                      style={{ marginRight: "10px", border: deploy === "test" ? "3px solid yellow" : "" }}
                    />{" "}
                    <Dropdown overlay={profilMenu}>
                      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()} style={{ color: "white" }}>
                        {currentUser.user.first_name} {currentUser.user.last_name} <DownOutlined />
                      </a>
                    </Dropdown>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <div style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <span
                  style={{
                    textAlign: "center",
                    fontSize: "42px",
                    paddingBottom: 0,
                    fontFamily: '"Righteous", cursive',
                    marginRight: 12,
                  }}
                >
                  Fiks
                </span>
                <img style={{ height: 50, width: 50 }} src={LOGO_IMG} />
              </div>
            </div>
          )}
        </Header>
        <Layout>
          {location !== "openorder" && <SideBar menu={_Menu} collapsed={this.state.collapsed} />}
          <Content className={"content " + "content-" + TypeSize()}>
            <Switch>
              <Route path="/logout" component={Logout} />

              <Route path="/openorder/:order_token/details" component={Details} />
              <Route path="/openorder/:order_token" component={OpenOrder} />
              <Route path="/insuranceorder/:order_token" component={Insurance} />

              <ProtectedRoute
                path="/dashboard/order/:id"
                component={Order}
                // key={new Date().getTime()}
              />
              <ProtectedRoute exact path="/dashboard" component={Dashboard} />

              <ProtectedRoute exact path="/accounting" component={Accounting} />
              <ProtectedRoute path="/accounting/:id" component={AccountingProfile} />
              <ProtectedRoute exact path="/profile" component={Profile} />
              <ProtectedRoute exact path="/suppliers" component={Suppliers} />
              <ProtectedRoute exact path="/materials" component={Materials} />
              <ProtectedRoute path="/suppliers/:id" component={SupplierProfile} />
              <ProtectedRoute exact path="/locations" component={Locations} />
              <ProtectedRoute exact path="/machines" component={Machines} />
              <ProtectedRoute
                path="/machines/:id"
                component={Machine}
                // key={new Date().getTime()}
              />
              <ProtectedRoute
                path="/maintenances/:id"
                component={Maintenance}
                goBack={this.goBack}
                setCounter={this.setCounter}
              />

              <ProtectedRoute path={"/maintenances"} component={Maintenances} />
              <ProtectedRoute path="/parts/part/:id" component={Part} />
              <ProtectedRoute path={"/parts/parts"} component={Parts} />
              <ProtectedRoute path={"/analytics"} component={Analytics} />
              <ProtectedRoute path={"/planning"} component={Planing} />

              <ProtectedRoute path={"/insurance/:id"} component={Insurance} />
              <ProtectedRoute path={"/insurance"} component={Insurances} />
              <ProtectedRoute path={"/reportconfirm"} component={ReportConfirm} />

              <Redirect to="/dashboard" />
            </Switch>
            <span id="version" style={{ display: "none" }}>
              1.0.4
            </span>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSetTokenFromLocaleStorage: (token) => dispatch({ type: actions.SET_TOKEN_FROM_LOCALE_STORAGE, token }),
    onGetUser: () => dispatch({ type: actions.USER }),
    onGetPrepare: () => dispatch({ type: GET_PREPARE_REGISTRATION }),
  };
};

const mapStateToProps = (state) => {
  return {
    auth: state.usersReducer.user.token || false,
    currentUser: state.usersReducer.user.data,
    loading: state.usersReducer.user.loading,
    prepareRegistration: state.machinesReducer.prepareRegistration,
    accountingPreprare: state.accoutingReducer.prepareRegistration,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppWrapper));
