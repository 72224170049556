export const NEW_WORK_ORDER = "NEW_WORK_ORDER";
export const NEW_WORK_ORDER_SUCCESS = "NEW_WORK_ORDER_SUCCESS";
export const NEW_WORK_ORDER_FAIL = "NEW_WORK_ORDER_FAIL";

export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL";

export const GET_ORDERS_V2 = "GET_ORDERS_V2";


export const SET_USERS_TO_ORDERS = "SET_USERS_TO_ORDERS";
export const SET_USERS_TO_ORDERS_SUCCESS = "SET_USERS_TO_ORDERS_SUCCESS";
export const SET_USERS_TO_ORDERS_FAIL = "SET_USERS_TO_ORDERS_FAIL";

export const START_ORDER = "START_ORDER";
export const START_ORDER_SUCCESS = "START_ORDER_SUCCESS";
export const START_ORDER_FAIL = "START_ORDER_FAIL";

export const FINISH_ORDER = "FINISH_ORDER";
export const FINISH_ORDER_SUCCESS = "FINISH_ORDER_SUCECSS";
export const FINISH_ORDER_FAIL = "FINISH_ORDER_FAIL";

export const PAUSE_ORDER = "PAUSE_ORDER";
export const PAUSE_ORDER_SUCCESS = "PAUSE_ORDER_SUCCESS";
export const PAUSE_ORDER_FAIL = "PAUSE_ORDER_FAIL";

export const CONFIRM_ORDER = "CONFIRM_ORDER";
export const CONFIRM_ORDER_SUCCESS = "CONFIRM_ORDER_SUCCESS";
export const CONFIRM_ORDER_FAIL = "CONFIRM_ORDER_FAIL";

export const REJECT_ORDER = "REJECT_ORDER";
export const REJECT_ORDER_SUCCESS = "REJECT_ORDER_SUCCESS";
export const REJECT_ORDER_FAIL = "REJECT_ORDER_FAIL";

export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAIL = "GET_ORDER_FAIL";

export const UPDATE_WORK_ORDER = "UPDATE_WORK_ORDER";
export const UPDATE_WORK_ORDER_SUCCESS = "UPDATE_WORK_ORDER_SUCCESS";
export const UPDATE_WORK_ORDER_FAIL = "UPDATE_WORK_ORDER_FAIL";

export const UPDATE_REPORT = "UPDATE_REPORT";
export const UPDATE_REPORT_SUCCESS = "UPDATE_REPORT_SUCCESS";
export const UPDATE_REPORT_FAIL = "UPDATE_REPORT_FAIL";

export const DELETE_REPORT = "DELETE_REPORT";
export const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS";
export const DELETE_REPORT_FAIL = "DELETE_REPORT_FAIL";

export const GET_ORDERS_TO_CONFIRM = "GET_ORDERS_TO_CONFIRM";
export const GET_ORDERS_TO_CONFIRM_SUCCESS = "GET_ORDERS_TO_CONFIRM_SUCCESS";
export const GET_ORDERS_TO_CONFIRM_FAIL = "GET_ORDERS_TO_CONFIRM_FAIL";

export const CALENDAR_MAINTENANCES = "CALENDAR_MAINTENANCES";
export const CALENDAR_MAINTENANCES_SUCCESS = "CALENDAR_MAINTENANCES_SUCCESS";
export const CALENDAR_MAINTENANCES_FAIL = "CALENDAR_MAINTENANCES_FAIL";

export const IN_BOWL = "IN_BOWL";
export const IN_BOWL_SUCCESS = "IN_BOWL_SUCCESS";
export const IN_BOWL_FAIL = "IN_BOWL_FAIL";

export const INSURANCE_ORDERS = "INSURANCE_ORDERS";
export const INSURANCE_ORDERS_SUCCESS = "INSURANCE_ORDERS_SUCCESS";
export const INSURANCE_ORDERS_FAIL = "INSURANCE_ORDERS_FAIL";

export const INSURANCE = "INSURANCE";
export const INSURANCE_SUCCESS = "INSURANCE_SUCCESS";
export const INSURANCE_FAIL = "INSURANCE_FAIL";

export const ADD_INSURANCE_MATERIAL = "ADD_INSURANCE_MATERIAL";
export const ADD_INSURANCE_MATERIAL_SUCCESS = "ADD_INSURANCE_MATERIAL_SUCCESS";
export const ADD_INSURANCE_MATERIAL_FAIL = "ADD_INSURANCE_MATERIAL_FAIL";

export const REMOVE_INSURANCE_REPORT_MATERIALS =
  "REMOVE_INSURANCE_REPORT_MATERIALS";
export const REMOVE_INSURANCE_REPORT_MATERIALS_SUCCESS =
  "REMOVE_INSURANCE_REPORT_MATERIALS_SUCCESS";
export const REMOVE_INSURANCE_REPORT_MATERIALS_FAIL =
  "REMOVE_INSURANCE_REPORT_MATERIALS_FAIL";

export const CHANGE_INSURANCE_ORDER_STATUS = "CHANGE_INSURANCE_ORDER_STATUS";
export const CHANGE_INSURANCE_ORDER_STATUS_SUCCESS =
  "CHANGE_INSURANCE_ORDER_STATUS_SUCCESS";
export const CHANGE_INSURANCE_ORDER_STATUS_FAIL =
  "CHANGE_INSURANCE_ORDER_STATUS_FAIL";

