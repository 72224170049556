import { combineReducers } from "redux";
import usersReducer from "../redux/modules/Users/user";
import accoutingReducer from "../redux/modules/Accounting/accouting";
import machinesReducer from "../redux/modules/Machines/Machines";
import erpReducer from "../redux/modules/ERP";
import machineReducer from "./modules/Machines/machine";
import maintenancesReducer from "./modules/Maintenances";
import maintenanceReducer from "./modules/Maintenances/maintenance";
import partsReducer from "./modules/Parts";
import dashboardReducer from "./modules/Dashboard";
import AnalyticsReducer from "./modules/Analytics";

export default combineReducers({
  usersReducer,
  accoutingReducer,
  machinesReducer,
  erpReducer,
  machineReducer,
  maintenancesReducer,
  maintenanceReducer,
  partsReducer,
  dashboardReducer,
  AnalyticsReducer,
});
