import React from 'react'

// Styles
import styles from '../../index.module.css'
import { Empty } from 'antd';

// type LayoutType = "grid" | "list"

// interface IProps {
//     size: number,
//     small?: boolean,
//     layout?: LayoutType,
//     tiny?: boolean
// }

const InvalidComponent = ({ size, small = false, layout = "grid", tiny }) => {
    if(layout === 'grid'){
        return (
            <div 
                style={{ height: size }}
                className={styles.cardContainer}
            >
                <Empty 
                    description={null}
                    imageStyle={{
                        transform: small ?
                            'scale(.75) translate(-10%, 10%)' : 
                            tiny ? 'scale(.35) translate(-7.5%, -80%)' :
                            'inherit'
                    }}
                />
            </div>
        )
    }

    return(
        <div 
            style={{ height: size, width: size, borderRadius: 4 }}
            className={styles.cardContainer}
        >
            <Empty 
                description={null} 
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                imageStyle={{
                    transform: small ?
                    'scale(.4) translate(-60%, -210%)' :
                    'scale(.75) translate(-20%, -70%)',
                }}
            />   
        </div>
    )
}

export default InvalidComponent;