import React, { Component } from "react";

import * as actions from "../../../../../redux/modules/Maintenances/actions";

import { Link } from "react-router-dom";
import { Row, Col, List, Button, Alert, Modal } from "antd";
import { connect } from "react-redux";
import SizeType from "../../../../../helpers/deviceTypeSize";

import {
  isTechnicianPrimary,
  isTechnicianBusy,
  technicianOfOrder,
} from "../../../../../helpers/getSpecValueFromOrder";

import { sortWorkingOrderToTop } from "../../../../../helpers/sorting";

import OrderDetails from "./../OrderDetails";

import Spinner from "../../../../../components/LoadingScreen";

import Resaon from "../../PauseReason";
import OrderTimeView from "../../Order/OrderTimeView";
import OrderUsers from "../../Order/OrderUsers";
import Commands from "../../Order/Commands";

class Dashboard extends Component {
  state = {
    resaonModalVisible: false,
    order_id: 0,
    order: {
      technicians: [],
    },
    reason_type: "",
    // tmp_test_confirm: false,
    // confirmation: [{ order_id: 0 }],
  };

  componentDidMount() {
    this.props.onGetOrders();
  }

  componentDidUpdate(prevProps) {
    //   if (prevProps.orders.confirmations !== this.props.orders.confirmations) {
    //     if (this.props.orders.confirmations.length > 0) {
    //       this.setState({ tmp_test_confirm: true }); // confirmations
    //     } else {
    //       this.setState({ tmp_test_confirm: false });
    //     }
    //   }
  }

  toggleConfirmModal = (bool) => {
    this.setState({ tmp_test_confirm: bool });
  };

  toggleResaonModal = (bool, order_id, reason_type) => {
    this.setState({ resaonModalVisible: bool, order_id, reason_type });
  };

  showSuccess = () => {
    this.toggleResaonModal(false, 0);
  };

  handleSetUsersToOrder = (id) => {
    const data = {
      technicians: [{ account: this.props.currentUser.id, is_primary: 1 }],
    };
    this.props.onSetUsersToOrder(data, id, null);
  };

  handleStartOrder = (id, flag, technician_id, is_primary) => {
    // if (confirm) {
    //   // pop up users ak sam glavni tehničar
    //   this.setState({
    //     order: this.props.orders.data.find((item) => item.id === id),
    //   });
    // }

    this.props.onStartOrder(id, flag, { technician_id, is_primary });
  };

  handleFinishOrder = (id) => {
    const data = {
      report: {
        text: null,
      },
      schedule_id: this.props.orders.data.find((item) => item.id === id)
        .schedule,
    };
    this.props.onFinishOrder(id, data, null);
  };

  pauseOrder = (id, data, flag) => {
    // used also for reject dont ask anything
    if (flag === "reject") {
      this.props.onRejectOrder(
        id,
        data,
        flag,
        this.toggleResaonModal.bind(this)
      );
      return;
    }
    this.props.onPauseOrder(id, data, flag, this.toggleResaonModal.bind(this));
  };

  alertMessage = () => {
    alert("Funkcionalnost u izradi");
  };

  render() {
    const {
      orders,
      setUsersToOrder,
      startOrder,
      finishOrder,
      pauseOrder,
      currentUser,
    } = this.props;

    if (orders.status === "loading") {
      return <Spinner />;
    }

    orders.data = orders.data.sort(function (a, b) {
      return b.type.id - a.type.id;
    });

    const alert = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1" }}
        message="Dogodila se greša"
        description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
        type="error"
      />
    );

    return (
      <>
        {setUsersToOrder.status === "error" ||
        startOrder.status === "error" ||
        finishOrder.status === "error"
          ? alert
          : null}
        <Row>
          <Col xs={24} sm={25} md={8}>
            {" "}
            <List
              style={{
                padding:
                  SizeType() === "xs" || SizeType() === "sm" ? "6px" : "27px",
              }}
              header={<h3>Nedodljenjeni nalozi</h3>}
              itemLayout="vertical"
              dataSource={orders.data.filter(
                (item) => item.status === "in_bowl"
              )}
              renderItem={(item) => (
                <List.Item
                  extra={
                    <>
                      <Commands
                        item={item}
                        finishOrder={finishOrder}
                        toggleResaonModal={this.toggleResaonModal}
                        alertMessage={this.alertMessage}
                        pauseOrder={this.pauseOrder}
                        orders={orders}
                        startOrder={startOrder}
                        currentUser={currentUser}
                        handleSetUsersToOrder={this.handleSetUsersToOrder}
                        setUsersToOrder={setUsersToOrder}
                        is_primary={true}
                        busy={isTechnicianBusy(item, currentUser)}
                      />
                    </>
                  }
                >
                  <List.Item.Meta
                    title={item.name}
                    description={
                      <div>
                        <OrderDetails {...item} />

                        <div>
                          {" "}
                          <Link to={`/dashboard/order/${item.id}`}>IDI</Link>
                        </div>
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </Col>
          <Col xs={24} sm={25} md={8}>
            <List
              style={{
                padding:
                  SizeType() === "xs" || SizeType() === "sm" ? "6px" : "27px",
              }}
              header={<h3>Moji nalozi</h3>}
              itemLayout="vertical"
              dataSource={orders.data.filter(
                (item) => item.status === "appointed"
              )}
              renderItem={(item) => (
                <List.Item
                  extra={
                    <>
                      <Commands
                        item={item}
                        finishOrder={finishOrder}
                        toggleResaonModal={this.toggleResaonModal}
                        alertMessage={this.alertMessage}
                        pauseOrder={this.pauseOrder}
                        orders={orders}
                        startOrder={startOrder}
                        currentUser={currentUser}
                        handleStartOrder={this.handleStartOrder}
                        setUsersToOrder={setUsersToOrder}
                        is_primary={isTechnicianPrimary(item, currentUser)}
                        busy={isTechnicianBusy(item, currentUser)}
                      />
                    </>
                  }
                >
                  <List.Item.Meta
                    title={item.name}
                    description={
                      <div>
                        <OrderDetails {...item} />
                        <OrderUsers
                          order={item}
                          technicians={item.technicians}
                          currentUser={currentUser}
                        />
                        <div>
                          {" "}
                          <Link to={`/dashboard/order/${item.id}`}>IDI</Link>
                        </div>
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </Col>
          <Col xs={24} sm={25} md={8}>
            <List
              style={{
                padding:
                  SizeType() === "xs" || SizeType() === "sm" ? "6px" : "27px",
              }}
              header={<h3> Nalozi u Tijeku</h3>}
              itemLayout="vertical"
              dataSource={sortWorkingOrderToTop(
                orders.data.filter(
                  (item) =>
                    item.status === "in_progress" || item.status === "waiting"
                )
              )}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={item.name}
                    description={
                      <>
                        <OrderTimeView
                          status={item.status}
                          real_start={item.real_start}
                        />
                        <div style={{ height: "12px" }}></div>

                        <div>
                          <OrderDetails {...item} />
                          <OrderUsers
                            order={item}
                            currentUser={currentUser}
                            technicians={item.technicians}
                          />
                          <div>
                            {" "}
                            <Link to={`/dashboard/order/${item.id}`}>IDI</Link>
                          </div>
                        </div>
                        <div style={{ height: "12px" }}></div>
                        {/* {!technicianOfOrder(item, currentUser).is_working ? (
                          <div style={{ fontWeight: "bold" }}>
                            Nalog na čekanju.
                          </div>
                        ) : null} */}
                        <div style={{ paddingLeft: "0" }}>
                          <Commands
                            item={item}
                            finishOrder={finishOrder}
                            toggleResaonModal={this.toggleResaonModal}
                            alertMessage={this.alertMessage}
                            pauseOrder={this.pauseOrder}
                            orders={orders}
                            startOrder={startOrder}
                            currentUser={currentUser}
                            handleSetUsersToOrder={this.handleSetUsersToOrder}
                            setUsersToOrder={setUsersToOrder}
                            is_primary={isTechnicianPrimary(item, currentUser)}
                            busy={isTechnicianBusy(item, currentUser)}
                            handleFinishOrder={this.handleFinishOrder}
                            handleStartOrder={this.handleStartOrder}
                          />
                        </div>
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
        <Modal
          footer={null}
          onCancel={() => this.toggleResaonModal(false)}
          visible={this.state.resaonModalVisible}
          destroyOnClose={true}
        >
          <Resaon
            pauseOrder={this.props.pauseOrder}
            _pauseOrder={this.pauseOrder}
            order_id={this.state.order_id}
            orders={orders}
            currentUser={currentUser}
            reason_type={this.state.reason_type}
            rejectOrder={this.props.rejectOrder}
          ></Resaon>
        </Modal>
        {/* <Confrming
          toggleConfirmModal={this.toggleConfirmModal}
          confirm={this.state.tmp_test_confirm}
          currentUser={currentUser}
          startOrder={startOrder}
          confirmations={orders.confirmations}
        /> */}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onGetOrders: () => dispatch({ type: actions.GET_ORDERS }),
    onSetUsersToOrder: (data, order_id, callback) =>
      dispatch({ type: actions.SET_USERS_TO_ORDERS, data, order_id, callback }),
    onStartOrder: (order_id, flag, data, callback) =>
      dispatch({ type: actions.START_ORDER, order_id, data, flag, callback }),
    onFinishOrder: (order_id, data, callback) =>
      dispatch({ type: actions.FINISH_ORDER, order_id, data, callback }),
    onPauseOrder: (order_id, data, flag, callback) =>
      dispatch({ type: actions.PAUSE_ORDER, order_id, data, flag, callback }),
    onRejectOrder: (order_id, data, flag, callback) =>
      dispatch({ type: actions.REJECT_ORDER, order_id, data, flag, callback }),
  };
};

const mapStateToProps = (state) => {
  return {
    orders: state.maintenancesReducer.orders,
    setUsersToOrder: state.maintenancesReducer.setUsersToOrder,
    startOrder: state.maintenancesReducer.startOrder,
    currentUser: state.usersReducer.user.data,
    finishOrder: state.maintenancesReducer.finishOrder,
    pauseOrder: state.maintenancesReducer.pauseOrder,
    rejectOrder: state.maintenancesReducer.rejectOrder,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
