import childsOfParent, { newChildsOfParent } from "./cildsOfParent";

export const getItemsWithouChilds = (items, item, key) => {
  if (!item) {
    return items;
  }

  console.log(items, item, "data");
  let childs = childsOfParent([item], items, [], key);

  let arr = items.filter((x) => !childs.includes(x.id));

  return arr;
};

export const newGetItemsWithouChilds = (items, item) => {
  if (!item) {
    return items;
  }
  let childs = newChildsOfParent([item], items, []);

  let arr = items.filter((x) => !childs.includes(x.id));

  return arr;
};
