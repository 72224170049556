import React, { Component } from "react";
import { connect } from "react-redux";

import * as actions from "../../../../redux/modules/Accounting/actions";
import { setSupplierModel } from "../../../../helpers/accounting";
import {
  Row,
  Col,
  Form,
  Input,
  Alert,
  Button,
  Select,
} from "antd";

const { Option } = Select;
const { TextArea } = Input;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class SupplierForm extends Component {
  state = {
    successVisible: false,
    fileList: [],
  };

  componentDidMount() {}

  alertSuccess = () => {
    this.setState({ successVisible: true });
    this.props.form.resetFields();
    setTimeout(() => {
      this.setState({ successVisible: false });
    }, 2000);
  };

  beforeUpload = (file) => {
    this.setState((state) => ({
      fileList: [...state.fileList, file],
    }));
    return false;
  };

  removeFile = (file) => {
    let fileList = this.state.fileList.concat();
    const index = fileList.indexOf(file);
    fileList.splice(index, 1);
    this.setState({ fileList, fileList });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.is_internal = false;
        values.category = "SUP";
        let formData = new FormData();
        formData.append("data", JSON.stringify(values));

        this.state.fileList.forEach((item) => {
          formData.append("files", item);
        });

        if (this.props.update) {
          this.props.onUpdateUser(
            this.props.type,
            formData,
            this.props.user_id,
            this.alertSuccess.bind(this)
          );
        } else {
          this.props.onRegisterUser(
            this.props.type,
            formData,
            this.alertSuccess.bind(this)
          );
        }
      }
    });
  };
  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;

    const {
      user,
      prepareRegistration,
      registerUser,
      supplier,
      update,
    } = this.props;

    const model = setSupplierModel(supplier, update);

    console.log("MODEL => ", model, supplier);

    const alert = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1" }}
        message="Dogodila se greša"
        description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
        type="error"
      />
    );

    const alertSuccess = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1", maxWidth: "400px" }}
        message="Uspješno!"
        type="success"
      />
    );
    const formItemLayout = {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 18,
        offset: 0,
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 0,
        },
      },
    };

    const alertMinOneFeature = (
      <Alert
        style={{
          margin: "0 auto",
          marginBottom: "10px",
          zIndex: "1",
          maxWidth: "400px",
        }}
        message="Korisni mora imati minimalno jednu funkciju!"
        type="warning"
      />
    );

    const { categories } = this.props;

    return (
      <>
        <Row>
          <Col>
            {" "}
            <h2
              style={{
                textAlign: "center",
                margin: "0 auto",
                marginBottom: "20px",
                maxWidth: "100%",
              }}
            ></h2>
            <div>
              {registerUser.status === "error" ? alert : null}
              {this.state.successVisible ? alertSuccess : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {" "}
            <Form
              {...formItemLayout}
              layout="horizontal"
              onSubmit={this.handleSubmit}
            >
              <Form.Item label="Ime">
                {getFieldDecorator("first_name", {
                  initialValue: model.first_name,
                  rules: [
                    {
                      required: true,
                      message: "Unesite ime!",
                    },
                  ],
                })(<Input placeholder="Ime" />)}
              </Form.Item>
              <Form.Item label="Funkcija">
                {getFieldDecorator("categories", {
                  initialValue: model.categories,
                  rules: [{ required: true, message: "Odaberi funkciju" }],
                })(
                  <Select
                    mode="multiple"
                    allowClear={true}
                    showSearch
                    placeholder="Odaberi funkciju"
                    optionFilterProp="name"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {categories.map((item) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item label="Adresa">
                {getFieldDecorator("address", {
                  initialValue: model.address,
                  rules: [
                    {
                      required: true,
                    },
                  ],
                })(<Input placeholder="Adresa" />)}
              </Form.Item>
              <Form.Item label="ID/PDV">
                {getFieldDecorator("id_pdv", {
                  initialValue: model.id_pdv,
                  rules: [],
                })(<Input type="tel" placeholder="PDV broj" />)}
              </Form.Item>
              <Form.Item label="Web lokacija">
                {getFieldDecorator("link", {
                  initialValue: model.link,
                  rules: [
                    {
                      required: false,
                    },
                  ],
                })(<Input type="tel" placeholder="url" />)}
              </Form.Item>
              <Form.Item label="Broj telefona">
                {getFieldDecorator("phone_number", {
                  initialValue: model.phone_number,
                  rules: [
                    {
                      required: true,
                    },
                  ],
                })(<Input type="tel" placeholder="Broj telefona" />)}
              </Form.Item>
              <Form.Item label="Napomena">
                {getFieldDecorator("remark", {
                  initialValue: model.remark,
                  rules: [
                    {
                      required: false,
                    },
                  ],
                })(<TextArea rows={3} placeholder="Napomena" />)}
              </Form.Item>
              <Form.Item label="E-mail">
                {getFieldDecorator("email", {
                  initialValue: model.email,
                  rules: [
                    {
                      type: "email",
                      message: "Nepravilan unos E-mail!",
                    },
                    {
                      required: true,
                      message: "Unesite E-mail!",
                    },
                  ],
                })(<Input placeholder="E-mail" />)}
              </Form.Item>
            

              <Form.Item {...tailFormItemLayout} style={{ float: "right" }}>
                <Button
                  style={{ float: "right" }}
                  loading={registerUser.status === "loading"}
                  type="primary"
                  htmlType="submit"
                  disabled={hasErrors(getFieldsError())}
                >
                  Spremi
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    registerUser: state.accoutingReducer.registerUser,
    prepareRegistration: state.accoutingReducer.prepareRegistration,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRegisterUser: (topic, data, callback) =>
      dispatch({ type: actions.REGISTER_USER, topic, data, callback }),
    onUpdateUser: (topic, data, user_id, callback) =>
      dispatch({
        type: actions.UPDATE_USER,
        topic,
        data,
        user_id,
        isSupplier: true,
        callback,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SupplierForm));
