import React, { useState } from "react";

import {
  Form,
  Input,
  InputNumber,
  Select,
  TreeSelect,
  Button,
  Upload,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import nestedSorting from "../../../../helpers/NestedSorting";
import { SizeFilesOk } from "../../../../helpers/functions";
import { ADD_PART_TO_STORAGE } from "../../../../redux/modules/Parts/actions";
import { UNITS_OF_MESURE_CURRENCY } from "../../../../helpers/consts";

const FormQuantity = ({ form, part, update, close }) => {
  const { getFieldDecorator, getFieldsError, validateFields } = form;

  const [file, set_file] = useState(false);

  const { machines } = useSelector((state) => state.machinesReducer);
  const { accounts } = useSelector((state) => state.accoutingReducer);
  const { addPartToStorage } = useSelector((state) => state.partsReducer);

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (err) return;

      const formData = new FormData();

      if (file) {
        if (!SizeFilesOk(file)) {
          return;
        }
        formData.append("files", file);
      }
      formData.append(
        "data",
        JSON.stringify({
          ...values,
          qty_uom: part.uom,
          price_uom: UNITS_OF_MESURE_CURRENCY.find((x) => x.text === "Marka")
            .sign,
        })
      );

      dispatch({
        type: ADD_PART_TO_STORAGE,
        data: formData,
        id: part.id,
        errorCallback: () => {
          message.error("Dogodila se greška kod spremanja...");
        },
        successCallback: () => {
          close();
        },
      });
    });
  };

  const beforeUpload = (_file, files) => {
    set_file(_file);
    return false;
  };

  return (
    <div style={{ padding: "15px 27px" }}>
      <Form onSubmit={handleSubmit}>
        <Form.Item label={"Šifra"}>
          {getFieldDecorator("code", {
            initialValue: part?.code,
            rules: [{ required: true, message: "Ovo polje je obavezno!" }],
          })(
            <Input
              disabled={true}
              style={{ width: "100px" }}
              placeholder={""}
            />
          )}
        </Form.Item>

        <Form.Item className={"modal-header"} label={"Zaliha"}>
          {getFieldDecorator("qty", {
            initialValue: update ? part.qty : 1,
            rules: [{ required: true, message: "Ovo polje je obavezno!" }],
          })(<InputNumber style={{ width: "100px" }} placeholder={""} />)}
        </Form.Item>
        <Form.Item className={"modal-header"} label={"Cijena"}>
          {getFieldDecorator("price", {
            initialValue: update ? part.price : 1,
            rules: [{ required: true, message: "Ovo polje je obavezno!" }],
          })(<InputNumber style={{ width: "100px" }} placeholder={""} />)}
        </Form.Item>

        <Form.Item label="Lokacija">
          {getFieldDecorator("location", {
            initialValue: update ? part.location?.id : null,
            rules: [{ required: true, message: "Ovo polje je obavezno" }],
          })(
            <TreeSelect
              allowClear={true}
              multiple={false}
              treeData={nestedSorting(
                machines.data.filter((x) => x.is_warehouse),
                "parent"
              )}
              showSearch
              optionFilterProp="name"
              filterTreeNode={(input, option) => {
                return (
                  option.props.name
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }}
            ></TreeSelect>
          )}
        </Form.Item>
        <Form.Item label="Dobavljač">
          {getFieldDecorator("supplier", {
            initialValue: update ? part.supplier?.id : null,
          })(
            <Select
              mode="single"
              allowClear={true}
              showSearch
              optionFilterProp="name"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {accounts.data
                .filter((x) => x.category === "SUP")
                .map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.full_name}
                  </Select.Option>
                ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="File">
          <Upload
            beforeUpload={beforeUpload}
            showUploadList={true}
            accept=".png,.jpg,.jpeg,.pdf"
          >
            <Button>Učitaj</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <div style={{ textAlign: "right" }}>
            <Button
              loading={addPartToStorage.status === "loading"}
              type="primary"
              htmlType="submit"
              disabled={hasErrors(getFieldsError())}
            >
              {update ? "Uredi" : "Spremi"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Form.create()(FormQuantity);

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}
