import { RetweetOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { unstable_batchedUpdates } from "react-dom";

const PartByLocation = ({
  item,
  uom,
  set_spend,
  set_transportPart,
  set_partStorage,
}) => {
  return (
    <div
      style={{
        minWidth: 320,
        maxWidth: 450,
        margin: "21px 0",
        position: "relative",
      }}
    >
      {/* <Button
        onClick={() => set_transportPart(item)}
        style={{ position: "absolute", right: 0, top: -5 }}
      >
        <RetweetOutlined />
      </Button> */}

        <Information
          label="Količina"
          value={item.qty.toFixed(uom === "ko" ? 0 : 2)}
        />
      <Information label="Lokacija" value={item.storage.name} />
      <Information label="Adresa" value={item.storage.address} />
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={() => {
            unstable_batchedUpdates(() => {
              set_partStorage(item.storage);
              set_spend();
            }, []);
          }}
          type="primary"
        >
          Utroši
        </Button>
      </div>
    </div>
  );
};

export default PartByLocation;

const Information = ({ label = "", value = "" }) => {
  return (
    <div className="order-information" style={{ width: "100%" }}>
      <p className="label">{label || "-"}</p>

      <p style={{ color: "#434343" }} className="value">
        {value || "-"}
      </p>
    </div>
  );
};
