import React, { useState } from "react";

import SizeType from "../../helpers/deviceTypeSize";

import { Link } from "react-router-dom";

import { live_status } from "../../helpers/liveMaintenanceStatus";

import { Table, Button, Icon, Switch, Tag } from "antd";
import { connect } from "react-redux";

class MachinesTable extends React.Component {
  state = {
    filteredInfo: {
      manager: null,
      category: null,
      location: null,
      type: null,
      status: null,
    },
    only_parent_item: false,
  };

  componentDidMount() {
    this.setPage();
  }

  setPage = () => {
    try {
      document
        .querySelector(
          `li.ant-pagination-item.ant-pagination-item-${this.props.page}`
        )
        .click();
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (pagination, filters, type, extra) => {
    if (this.props.onPageChange) {
      this.props.onPageChange(pagination.current);
    }
    const filteredInfo = { ...this.state.filteredInfo };
    filteredInfo.manager = filters.manager || null;
    filteredInfo.category = filters.category || null;
    filteredInfo.location = filters.location || null;
    filteredInfo.type = filters.subcategory || null;
    filteredInfo.status = filters.status || null;
    this.setState({ filteredInfo });
  };

  clearFilters = () => {
    const filteredInfo = {
      manager: null,
      category: null,
      location: null,
      type: null,
      status: null,
    };
    this.setState({ filteredInfo });
  };

  render() {
    const tableData = StructAccountsForTable(
      this.props.data,
      this.state.filteredInfo || {},
      this.props.filters,
      this.props.assets
    );

    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "16px 0",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button onClick={this.clearFilters}>
              Očisti filtere <Icon type="delete" />
            </Button>
            <div style={{ marginLeft: "15px" }}>{this.props.search()}</div>
            <div>{this.props.button && this.props.button()}</div>
          </div>
        </div>
        <Table
          rowKey={(record) => record.id}
          scroll={{
            x: SizeType() === "xs" || SizeType() === "sm" ? true : false,
          }}
          dataSource={tableData.dataSource}
          columns={tableData.columns}
          onChange={this.handleChange}
        />
      </>
    );
  }
}

const getFitlers = (data, key) => {
  switch (key) {
    case "manager":
      return data.accounts
        .filter((item) => (item.category === "MAN" || item.category === "LDR"))
        .map((item) => ({
          text: item.user.first_name,
          value: item.user.first_name,
        }));
    case "category":
      return data.categories.map((item) => ({
        text: item.name,
        value: item.name,
      }));
    case "status":
      return live_status().map((item) => ({
        text: item.text,
        value: item.status,
      }));
    case "location":
      return data.locations.map((item) => ({
        text: item.name,
        value: item.name,
      }));
    default:
      break;
  }
  let categoryFilter = [{ text: "error", value: "error" }];
  return categoryFilter;
};

const StructAccountsForTable = (data, filteredInfo, filters, allAssets) => {
  const columns = [
    {
      title: "Naziv",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => (
        <Link to={`/machines/${record.id}/specifications`}>{text}</Link>
      ),
    },
    {
      title: "Nadimovina",
      dataIndex: "parent_item",
      key: "parent_item",
      render: (text, record, index) => {
        if (!record.parent_item) {
          return "-";
        }
        const _parent = allAssets.find((x) => x.id == record.parent_item);
        return (
          <Link to={`/machines/${_parent.id}/specifications`}>
            {_parent.name}
          </Link>
        );
      },
    },

    {
      title: "Kategorije",
      dataIndex: "category",
      key: "category",
    },

    {
      title: "Lokacija",
      dataIndex: "location",
      key: "location",
      filters: getFitlers(filters, "location"),
      filteredValue: filteredInfo.location || null,
      onFilter: (value, record) => {
        return record.location.includes(value);
      },
      ellipsis: true,
    },
  ];

  const dataSource = [];

  data.map((item) => {
    const _parent_item = item.parent_id || false;
    

    const machine = {
      id: item.id,
      name: item.name,
      category: item.categories ? item.categories.map((x) => x.name + " ") : "",
      status: item.status,
      location: item.info.location ? item.info.location.name : "",
      manager: item.info.manager ? item.info?.manager.full_name : "-",
      parent_item: _parent_item,
    };

    dataSource.push(machine);
  });

  return {
    columns,
    dataSource,
  };
};

const mapStateToProps = (state) => {
  return {
    assets: state.machinesReducer.machines.data,
  };
};

export default connect(mapStateToProps, null)(MachinesTable);
