import React from "react";
import { List } from "antd"

// Components
import ListItem from './ListItem'

export default function CategoriesList({ list }){
  console.log("LOIST => ", list)
  return (
    <List
      style={{ marginLeft: '32px', paddingTop: '0px', maxHeight: "75vh", overflow: "auto" }}
      header={<div className={'category-list'}>Postojeće kategorije</div>}
      itemLayout="horizontal"
      dataSource={list}
      renderItem={item => 
        <ListItem 
          item={item}
      />}
    ></List>
  );
}