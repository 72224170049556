import { OrderedListOutlined } from "@ant-design/icons";
import moment from "moment";

export const getMachineModel = (data, update) => {
  console.log(data, "LL");
  let model = {
    id: null,
    performance: "",
    name: "",
    function: null,
    categories: null,
    type: 2,
    parentMachine: null,
    description: "",
    supplier: null,
    location: null,
    manager: null,
    relevant_links: [],
    photos: [],
    documents: [],
    manufacturer: "",
    yearOfProduce: null,
    serialNumber: "",
    connected_to: [],
    model: "",
    color: "",
    insurers: [],
  };

  if (update) {
    model.id = data.id;
    model.name = data.name;
    model.categories = data.categories
      ? data.categories.map((x) => x.id)[0]
      : null;
    model.parentMachine = data.parent ? data.parent.id : null;
    model.description = data.info.description || "";
    model.supplier = data.info.supplier ? data.info.supplier.id : null;
    model.location = data.info.location?.id;
    model.manager = data.info.manager ? data.info.manager.id : null;
    model.relevant_links = data.relevant_links;
    model.manufacturer = data.info.manufacturer || null;
    model.yearOfProduce = data.info.year || null;
    model.serialNumber = data.info.serial_number || null;
    model.connected_to = data.connected_to.map((item) => item.id);
    model.model = data.info.model || null;
    model.color = data.info.color || null;
    model.insurers = data.insurers ? data.insurers.map((x) => x.id) : [];
    model.performance = data.performance || undefined
  }

  return model;
};

export const pipeArrOfTypes = (data, category_id) => {
  return data;
  const types = [];
  data.map((item) => {
    item.subcategories.map((i) => {
      if (category_id === 0) {
        types.push({
          category_id: item.id,
          name: i.name,
          id: i.id,
          category_name: item.name,
        });
      } else if (category_id === item.id) {
        types.push({
          category_id: item.id,
          name: i.name,
          id: i.id,
          category_name: item.name,
        });
      }
    });
  });
  return types;
};

export const getMaintenanceFormModel = (data, orderType) => {
  let model = {};
  switch (orderType.name) {
    case "machine":
      model = {
        subassets: [],
        name: "",
        group: null,
        model: "",
        categories: [],
        type: 1,
        technician: null,
        sub_technicians: [],
        description: "",
        maintance_time: null,
        maintance_time_type: "hour",
        estimated_time: null,
        planned_start: null,
        parts: null,
        tools: null,
        tasks: [],
        documents: [],
        photos: [],
        videos: [],
        asset: orderType.assetSelected ? orderType.assetSelected.id : null,
        work_capacity: null,
        production_work_order: ""
      };

      if (orderType.update) {
        model = {
          subassets: data.tags?.map(x => x.id) || [],
          name: data.name,
          group: null,
          model: data.model,
          categories:
            data.categories && data.categories.length > 0
              ? data.categories.map((item) => item.id)
              : [],
          type: data.type ? data.type.id : null,
          technician:
            data.technicians && data.technicians.length > 0
              ? data.technicians.find((item) => item.is_primary === true)
                  .account.id
              : null,
          sub_technicians:
            data.technicians && data.technicians.length > 0
              ? data.technicians
                  .filter((item) => item.is_primary === false)
                  .map((item) => item.account.id)
              : [],
          description: data.description,
          maintance_time_type: data.period,
          estimated_time: data.estimated_time,
          estimated_time_minute: data.estimated_time_minute,
          planned_start: data.planned_start,
          repeat_time: data.every,
          parts: data.parts,
          tools: data.tools,
          tasks: prepareTasks(data.tasks),
          asset: data.items[0].id,
          documents: [],
          photos: [],
          videos: [],
          work_capacity: data.work_capacity,
          production_work_order: data.production_work_order || ""
        };
      }
      return model;

    default:
      return model;
  }
};

const prepareTasks = (tasks) => {
  for (let i = 0; i < tasks.length; i++) {
    tasks[i].materials = [
      ...tasks[i].documents.map((item) => "d_" + item.id),
      ...tasks[i].videos.map((item) => "v_" + item.id),
      ...tasks[i].links.map((item) => "l_" + item.id),
      ...tasks[i].images.map((item) => "i_" + item.id),
    ];
  }

  console.log(tasks);

  return tasks;
};
