import { Button, Col, Drawer, message, Modal, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import PartByLocation from "../../Part/PartByLocation";
import css from "../../parts.module.scss";
import SpendForm from "./SpendForm";
import TransportPartForm from "./TransportPartForm";
import QRCode from "react-qr-code";
import NewPartImage from "./NewPartImage";
import NewDocuments from "./NewDocuments";
import { PlusSquareOutlined } from "@ant-design/icons";
import FormQuantity from "./FormQuantity";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GET_PART, GET_PART_SUCCESS } from "../../../../redux/modules/Parts/actions";
import Loading from "../../../../components/LoadingScreen";
import { useHistory } from "react-router-dom";
import FilesComponent from "../../../../components/Files";
import { getApiEndpoint } from "../../../../axios/endpoints";
import Axios from "axios";
import SpendPartWithoutOrder from "./SpendPartWithoutOrder";

export default function About({}) {
  const [spend, set_spend] = useState(null);
  const [transportPart, set_transportPart] = useState(null);
  const [partStorage, set_partStorage] = useState(null);
  const [image, set_image] = useState(false);
  const [documents, set_documents] = useState(false);
  const [addPartQuantity, set_addPartQuantity] = useState(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [delLoading, set_delLoading] = useState(false);


  

  useEffect(() => {
    dispatch({ type: GET_PART, id });
  }, []);

  const {
    part: { status, data },
    getPart,
  } = useSelector((state) => state.partsReducer);

  if (getPart.status === "loading") {
    return <Loading />;
  }

  if (getPart.data?.status_code === 404) {
    message.error("Dio kojem se pristupa je uklonjen iz baze...", 6, () => {
      history.replace("/parts/parts/");
    });
    return null;
  }


  const delMaterial = async (id) => {
    set_delLoading(true);
    let token = await localStorage.getItem("token");
    try {
      let res = await Axios.delete(`${getApiEndpoint()}api/parts/materials/${id}/`, {
        headers: {
          Authorization: `Token ${token}`
        }
      });

      let part_materials = [...data.part_materials];
      let index = part_materials.findIndex((item) => item.id === id);
      part_materials.splice(index, 1);

      dispatch({type: GET_PART_SUCCESS, data: {
        ...data,
        part_materials
      }});
    } catch (e) {
      message.error("Something went wrong");
    } finally {
      set_delLoading(false);
    }
  }

  return (
    <>
      <Row>
        <Col span={8}>
          {" "}
          <>
            {/* Informations */}

            {/* Ime dijela */}
            {information("Ime dijela", data.name)}

            {/* Šifra */}
            {information("Šifra", data.code)}

            {/* Količina */}
            <div style={{ position: "relative" }}>
              {information("Ukupna količina", data.total_current_qty)}{" "}
              <PlusSquareOutlined
                onClick={() => set_addPartQuantity(data)}
                style={{
                  fontSize: 24,
                  position: "absolute",
                  top: 3,
                  left: 144,
                  cursor: "pointer",
                }}
                size={40}
              />{" "}
            </div>

            {/* Kritična količina */}
            {information("Kritična količina", data.critical_qty)}

            {/* Cijena */}
            {information("Prosječna cijena", data.avg_price)}

            {/* Opis */}
            {information("Opis", data.description)}

            {/* Kategorija */}
            {information("Kategorija", data.category?.name)}
          </>
        </Col>
        <Col span={14}>
          <Row>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <QRCode size={180} value={window.location.href} />
              </div>
            </Col>
            <Col span={12}>
            <div style={{ textAlign: "right", paddingRight: 12 }}>
            <Button onClick={() => set_documents(true)} type="dashed">
              Učitaj dokumente
            </Button>
          </div>
          <Spin spinning={delLoading}>
            <FilesComponent 
            files={data.part_materials.map(x =>{ 
              let tmp = x.title.split(".");
              if(tmp.length > 0){
                tmp = tmp[tmp.length -1]
              }else{
                tmp = ""
              }
              return({
              id: x.id,
              name: x.title,
              extension: tmp,
              file: getApiEndpoint() + "media/" + x.path
            })}) || []}
              onDelete={(id) => delMaterial(id)}
              hideEditButton
              size="small"
              layout="list"
            />
            </Spin>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{ paddingTop: 66, textAlign: "center" }}></div>
            </Col>
            <Col span={12}>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {data.part_storage?.map((item, i) => (
              <div style={{ margin: "0 6px" }} key={i}>
                <PartByLocation
                  set_spend={() => set_spend(data)}
                  set_partStorage={set_partStorage}
                  // set_transportPart={set_transportPart}
                  item={item}
                  uom={data.uom || "ko"}
                />
              </div>
            ))}
          </div>
        </Col>
      </Row>
      <hr />
      <Row>
        {/* <Col span={24}>
         
        </Col> */}
      </Row>
      <Drawer
        width={450}
        visible={!!addPartQuantity}
        onClose={() => set_addPartQuantity(null)}
        destroyOnClose={true}
        title="Dodaj količinu"
      >
        <FormQuantity
          close={() => set_addPartQuantity(null)}
          part={addPartQuantity}
        />
      </Drawer>

      <Modal
        destroyOnClose={true}
        title={"Odaberi dokumente"}
        visible={!!documents}
        onCancel={() => set_documents(false)}
        footer={null}
        children={<NewDocuments close={() => set_documents(false)} />}
      />
   

      <Modal
        destroyOnClose={true}
        title={"Utroši dio"}
        visible={!!spend}
        onCancel={() => set_spend(null)}
        footer={null}
        children={
        spend &&  <SpendPartWithoutOrder close={() => set_spend(null)} _part={spend}  />
        }
      />
      
    </>
  );
}

const information = (label, value) => {
  return (
    <div className={css.infoItem}>
      <h3 className={css.infoItemLabel}>{label}: </h3>
      <h3 className={css.infoItemValue}>{value || "---"}</h3>
    </div>
  );
};
