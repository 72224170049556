import React, { useState } from "react";

import { Form, Button, Input, Alert, TreeSelect } from "antd";

import { useDispatch, useSelector } from "react-redux";

import SizeType from "../../../../helpers/deviceTypeSize";
import {
  ADD_PART_CATEGORY,
  UPDATE_PART_CATEGORY,
} from "../../../../redux/modules/Parts/actions";
import { newNestedSorting } from "../../../../helpers/functions";
import {
  getItemsWithouChilds,
  newGetItemsWithouChilds,
} from "../../../../helpers/getitemsWithouChilds";

const CForm = ({ update, form, categories, set_update }) => {
  const { getFieldDecorator, getFieldsError } = form;
  const [error, set_error] = useState();
  const [success, set_success] = useState(false);

  const setSuccess = () => {
    set_success(true);
    setTimeout(() => {
      set_success(false);
      set_update();
      form.resetFields();
    }, 2100);
  };

  const setError = () => {
    set_error(true);

    setTimeout(() => {
      set_error(false);
      set_update();
      form.resetFields();
    }, 2100);
  };
  const { addPartCategory, updatePartCategory } = useSelector(
    (state) => state.partsReducer
  );
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      let parent = null;
      if (values.category) {
        parent = values.category;
      }

      if (update) {
        dispatch({
          type: UPDATE_PART_CATEGORY,
          data: { ...values, category: parent },
          id: update.id,
          errorCallback: setError,
        });
        return;
      }
      dispatch({

        type: ADD_PART_CATEGORY,
        data: { ...values, category: parent },
        errorCallback: setError,
      });
    });
  };


  if (error) {
    return alert;
  }

  if (success) {
    return alertSuccess;
  }

  return (
    <Form
      // {...formItemLayout}
      layout="vertical"
      hideRequiredMark
      onSubmit={handleSubmit}
    >
      <Form.Item label="Nadkategorija">
        {getFieldDecorator("parent", {
          initialValue: update?.parent_id || null,
        })(
          <TreeSelect
            allowClear={true}
            placeholder="Odaberi nadkategoriju"
            // treeData={NestedSorting(

            //   "parent_id"
            // )}
            treeData={newNestedSorting(
              newGetItemsWithouChilds(categories, update ? update.id : null)
            )}
          ></TreeSelect>
        )}
      </Form.Item>
      <Form.Item className={"modal-header"} label={"Kategorija"}>
        {getFieldDecorator("name", {
          initialValue: update ? update.name : null,
          rules: [
            { required: true, message: "Unesite tekst!" },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button
          style={{ float: "right" }}
          loading={
            addPartCategory.status === "loading" ||
            updatePartCategory.status === "loading"
          }
          type="primary"
          htmlType="submit"
          disabled={hasErrors(getFieldsError())}
        >
          Spremi
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create()(CForm);

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

const alert = (
  <Alert
    style={{ marginBottom: "10px", zIndex: "1" }}
    message="Dogodila se greša"
    description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
    type="error"
  />
);

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol:
    SizeType() === "xs" || SizeType() === "sm"
      ? {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 0,
          },
        }
      : {
          span: 18,
          offset: 0,
        },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
};

const layoutType = "vertical";

const alertSuccess = (
  <Alert
    style={{ marginBottom: "10px", zIndex: "1" }}
    message="Uspješno"
    type="success"
  />
);
