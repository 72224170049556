import React from 'react'

// Styles
import styles from '../../index.module.css'

// type LayoutType = "grid" | "list"

// interface IProps {
//     src: string,
//     size: number,
//     layout?: LayoutType
// }

const VideoComponent = ({ src, size, layout = "grid" }) => {
    if(layout === 'grid'){
        return(
            <div 
                style={{ height: size }} 
                className={styles.cardContainer}
            >
                <video 
                    width='100%' 
                    height={size} 
                    preload="metadata"
                    className={styles.cardHoverStyle}
                    style={{ objectFit: 'cover' }}
                >
                    <source 
                        src={`${src}#t=1,5`} // #t=1 => Skip to first second of video, to get the thumbnail
                        type="video/mp4" 
                    />
                </video>
            </div>
        )
    }
    return(
        <div 
            style={{ height: size, width: size, borderRadius: 4 }} 
            className={styles.cardContainer}
        >
            <video 
                width='100%' 
                height={size} 
                preload="metadata"
                className={styles.cardHoverStyle}
                style={{ objectFit: 'cover' }}
            >
                <source 
                    src={`${src}#t=1,5`} // #t=1 => Skip to first second of video, to get the thumbnail
                    type="video/mp4" 
                />
            </video>
        </div>
    )
}

export default VideoComponent;