import { Button, Drawer, Icon, TreeSelect } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import nestedSorting from "../../../../helpers/NestedSorting";

import SizeType from "../../../../helpers/deviceTypeSize";
import {
  ASSET_MAINTENANCE_LIST_SUCCESS,
  TT_CABLES_FILTERS_SUCCESS,
} from "../../../../redux/modules/Analytics/actions";
import { ReloadOutlined } from "@ant-design/icons";

const Filters = ({ filtersVisible, set_filtersVisible }) => {
  const dispatch = useDispatch();
  const [filters, set_filters] = useState({
    maintenance_categories: [],
    locations: [],
    assets: [],
    assets_category: [],
    maintenance_types: [],
  });

  const { murgaReports } = useSelector((state) => state.AnalyticsReducer);

  const {
    prepareRegistration: {
      data: {
        locations,
        categories,
        maintenance_categories,
        maintenance_types,
      },
    },
    machines,
  } = useSelector((state) => state.machinesReducer);

  const onChange = (value, key) => {
    const _filters = { ...filters };
    _filters[key] = value;
    set_filters(_filters);

    // if (key === "maintenance_categories") {
    //   dispatch({ type: TT_CABLES_FILTERS_SUCCESS, data: filters });
    // }

  };

  const tProps = {
    showSearch: true,
    multiple: true,
    placeholder: "Odaberi",
    style: {
      width: "100%",
    },
    maxTagCount: 3,
    allowClear: true,
  };

  let cssClasses = "reportsFilters ";

  cssClasses += filtersVisible ? "height" : "";

  const drawerProps = {
    className: cssClasses,
    maskClosable: true,
    headerStyle: { padding: 6 },
    placement: "top",
    width: SizeType() === "xs" || SizeType() === "sm" ? "90%" : 850,
    height: 390,
    visible: filtersVisible,
    onClose: () => {
      set_filtersVisible(false);
        dispatch({ type: TT_CABLES_FILTERS_SUCCESS, data: filters });
      dispatch({
        type: ASSET_MAINTENANCE_LIST_SUCCESS,
        data: { total_failure_time: 0, data: [] },
      });
    },
    destroyOnClose: true,
    title: (
      <span>
        <Icon type="filter" theme="twoTone" /> Filteri
      </span>
    ),
  };


  return (
    <Drawer {...drawerProps}>
      <div
        style={{
          width: "96%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "row",
        }}
      >
        <div style={{ marginLeft: 18, width: "18%" }}>
          <span>Kategorije imovine</span>
          <TreeSelect
          
            defaultValue={murgaReports.filters.assets_category}
            onChange={(val) => {
              onChange(val, "assets_category");
            }}
            treeData={nestedSorting(categories, "parent")}
            {...tProps}
          />
        </div>
        <div style={{ marginLeft: 18, width: "18%" }}>
          <span>Imovina</span>
          <TreeSelect
            defaultValue={murgaReports.filters.assets}
            onChange={(val) => {
              onChange(val, "assets");
            }}
            treeData={nestedSorting(machines.data, "parent")}
            {...tProps}
          />
        </div>
        <div style={{ marginLeft: 18, width: "18%" }}>
          <span>Lokacije</span>
          <TreeSelect
            defaultValue={murgaReports.filters.locations}
            onChange={(val) => {
              onChange(val, "locations");
            }}
            treeData={nestedSorting(locations, "location_child")}
            {...tProps}
          />
        </div>
        <div style={{ marginLeft: 18, width: "18%" }}>
          <span>Kategorije održavanja</span>
          <TreeSelect
            defaultValue={murgaReports.filters.maintenance_categories}
            onChange={(val) => {
              onChange(val, "maintenance_categories");
            }}
            treeData={maintenance_categories.map((item) => ({
              key: item.id,
              value: item.id,
              title: item.name,
            }))}
            {...tProps}
          />
        </div>
        <div style={{ marginLeft: 18, width: "18%" }}>
          <span>Tipovi održavanja</span>
          <TreeSelect
            value={filters.maintenance_types || []}
            onChange={(val) => {
              onChange(val, "maintenance_types");
            }}
            treeData={maintenance_types.map((item) => ({
              key: item.id,
              value: item.id,
              title: item.name,
            }))}
            {...tProps}
          />
        </div>
        <div style={{ marginLeft: 18, width: "30px" }}>
          <span style={{ color: "#fff" }}>n</span>
          <Button
            type="primary"
            onClick={() => {
              dispatch({
                type: ASSET_MAINTENANCE_LIST_SUCCESS,
                data: { total_failure_time: 0, data: [] },
              });
              dispatch({
                type: TT_CABLES_FILTERS_SUCCESS,
                data: filters,
              });
            }}
            children={"Primijeni"}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default Filters;
