import React, { useState } from 'react'
import { List } from 'antd';
import { Portal } from 'react-portal';

// Components
import Card from './components/Card'
import ListItem from './components/ListItem'
import FileViewer from './components/FileViewer'

// type TImageExtensions = "jpg" | "jpeg" | "png";
// type TVideoExtensions = "mp4";
// type TDocumentExtensions = "pdf";

// export type MediaType = "image" | "video" | "document" | "invalid" | string
// type MediaExtension = TImageExtensions | TVideoExtensions | TDocumentExtensions | string

// type CardSize = "tiny" | "small" | "medium" | "large"
// type LayoutType = "grid" | "list"

// export interface MediaFile {
//     id: number,
//     file: string,
//     name: string,
//     type: MediaType,
//     extension: MediaExtension
// }

// export interface IFile {
//     name: string,
//     file: string,
//     extension: string,
//     id: number,
// }

// interface IProps {
//     files: IFile[],
//     size?: CardSize,
//     layout?: LayoutType,
//     hideEditButton?: boolean,
//     hideButtons?: boolean,
//     hideMeta?: boolean,
//     hideMetaType?: boolean,
//     hideMetaIcon?: boolean
//     onDelete: (id: number) => void,
//     onEdit?: (file: IFile | undefined) => void 
// }

const FilesComponent = (props) => {

    // Props
    const { 
        onDelete, 
        onEdit, 
        files, 
        hideEditButton = false, 
        size = "medium", 
        layout = "grid", 
        hideButtons = false,
        hideMeta = false,
        hideMetaType = false,
        hideMetaIcon = false
    } = props

    // Variables
    const [showFileViewer, setShowFileViewer] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [layoutType] = useState(layout)
    const media_files = []
    
    const generateMediaFile = (item) => {

        let type  = ""

        if(item.extension === "jpg" || item.extension === "jpeg" || item.extension === "png")
            type = "image"
        else if(item.extension === "mp4")
            type = "video"
        else if(item.extension === "pdf")
            type = "document"
        else
            type = "invalid"

        return{
            id: item.id,
            file: item.file,
            name: item.name,
            type,
            extension: item.extension,
        }
    }

    // Generating files
    files.map(item => media_files.push(generateMediaFile(item)))

    function onCloseFileViewer(event){
        event.stopPropagation()
        setShowFileViewer(false)
    }

    function onOpenFileViewer(index){
        setShowFileViewer(true)
        setCurrentIndex(index)
    }

    const ListSize = 
        size === 'medium' ? 'default' : 
        size === 'large' ? 'large' : 'small'

    const fileViewer = showFileViewer &&
        <Portal>
            <FileViewer
                files={media_files}
                index={currentIndex}
                onClose={onCloseFileViewer}
            />
        </Portal>

    return(
        <div style={{ display:'flex', flexDirection: 'row', flexWrap: 'wrap', position: 'relative', width: '100%' }}>
            {
                layoutType === 'grid' ?
                    media_files.map((item, index) => (
                        <Card
                            id={item.id}
                            key={index}
                            index={index}
                            name={item.file}
                            mediaType={item.type}
                            title={item.name}
                            size={size}
                            hideEditButton={hideEditButton}
                            hideButtons={hideButtons}
                            openFileViewer={onOpenFileViewer}
                            onDelete={onDelete}
                            onEdit={onEdit}
                        />
                    ))
                    :
                    <List
                        style={{ width: '100%' }}
                        dataSource={media_files}
                        size={ListSize}
                        renderItem={(item, index) =>
                            <ListItem
                                id={item.id}
                                key={index}
                                index={index}
                                name={item.file}
                                mediaType={item.type}
                                title={item.name}
                                size={size}
                                hideEditButton={hideEditButton}
                                hideButtons={hideButtons}
                                hideMeta={hideMeta}
                                hideMetaType={hideMetaType}
                                hideMetaIcon={hideMetaIcon}
                                openFileViewer={onOpenFileViewer}
                                onDelete={onDelete}
                                onEdit={onEdit}
                            />
                        }
                    />
            }

            {/* File Viewer */}
            { fileViewer }
        </div>
    )
}

export default FilesComponent;
