import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./redux/rootReducer";

import {
  watcherLogout,
  watcherSetTokenFromLocaleStorage,
  watcherGetUserByToken,
  watcherLogin,
  // watcherSocket,
  watcherChangePassword,
} from "./redux/modules/Users/user";

import {
  watcherSaveCategory,
  watcherUpdateCategory,
  watcherDeleteCategory,
  watcherRegisterUser,
  watcherUpdateUser,
  watcherDeleteUser,
  watcherGetPrepareRegistration,
  watcherAccounts,
  watcherSaveCompanyCategory,
  watcherAccount,
  watcherSupplier,
  watcher_addDocument,
  watcher_removeDocument,
  watcher_deleteSupplierCategory,
} from "./redux/modules/Accounting/accouting";

import {
  watcherGetERP_machines,
  watcherGetERP_users,
} from "./redux/modules/ERP";

import {
  watcherSaveLoaction,
  watcherDeleteLocation,
  watcherUpdateLocation,
  watcherPrepareMachineRegistration,
  watcherGetMachines,
  watcherAddMachine,
  watcher_updateMachineType,
  watcher_saveMachineFunction,
} from "./redux/modules/Machines/Machines";

import {
  watcherGetMachine,
  watcherUpdateMachine,
  watcherDeleteMachine,
  watcherAddMaterial,
  watcherDeleteMaterial,
} from "./redux/modules/Machines/machine";

import {
  watcherNewWorkOrder,
  watcherGetWorkOrders,
  watchersetUsersToOrder,
  watcherStartOrder,
  watcherFinishOrder,
  watcherPauseOrder,
  watcherConfirmOrder,
  watcherRejectOrder,
  watcherCalendarMaintenances,
  watcher_insuranceOrders,
  watcher_insurance,
  watcher_addInsuranceMaterial,
  watcher_removeInsuranceReportMaterials,
  watcher_changeInsuranceOrderStatus,
  watcherGetWorkOrders_v2
} from "./redux/modules/Maintenances";

import {
  watcherGetWorkOrder,
  watcherUpdateWorkOrder,
  watcherUpdateReport,
  watcherDeleteReport,
  watcher_inBowl,
} from "./redux/modules/Maintenances/maintenance";

import {
  watcher_addPart,
  watcher_addPartCategory,
  watcher_addPartToOrder,
  watcher_addPartToStorage,
  watcher_allPartStorages,
  watcher_confirmPartRemoval,
  watcher_confirmTransport,
  watcher_getPart,
  watcher_getPartEnteries,
  watcher_getParts,
  watcher_getPartsByStatus,
  watcher_getPartsCategories,
  watcher_getPartsInTransport,
  watcher_historyPartSpent,
  watcher_removePart,
  watcher_removePartCategory,
  watcher_removePartEntery,
  watcher_removePartSpent,
  watcher_spendPart,
  watcher_spentsWithoutParts,
  watcher_transportPart,
  watcher_updatePart,
  watcher_updatePartCategory,
  watcher_waitingForConfirmation,
} from "./redux/modules/Parts";
import {
  watcher_numOfDowntimesPerCategory,
  watcher_techniciansResponseTime,
  watcher_totalDowntime,
  watcher_totalDowntimePerAsset,
  watcher_workOrderWorkingAvgTime,
  watcher_mtbm,
  watcher_maintenanceNumberPerAsset,
  watcher_mttr,
  watcher_mtbf,
  watcher_techniciansLoginAndWorkTime,
  watcher_technicianAvailabilityTrend,
  watcher_technicianGenealEffect,
  watcher_assetMaintenanceSummary,
  watcher_assetMaintenanceList,
  watcher_reporterGeneralEffect,
  watcher_totalSpentParts,
  watcher_stockPurchase,
  watcher_reportForAdministration
} from "./redux/modules/Analytics";

// create the saga middleware
export const sagaMiddleware = createSagaMiddleware();

const initialState = {};

const storeCreating = compose(
  applyMiddleware(thunk, sagaMiddleware),
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f
)(createStore);

const store = storeCreating(rootReducer, initialState);

sagaMiddleware.run(watcherLogout);
sagaMiddleware.run(watcherLogin);
sagaMiddleware.run(watcherGetUserByToken);
sagaMiddleware.run(watcherSetTokenFromLocaleStorage);
sagaMiddleware.run(watcherChangePassword);
sagaMiddleware.run(watcherRegisterUser);
sagaMiddleware.run(watcherUpdateUser);
sagaMiddleware.run(watcherDeleteUser);
sagaMiddleware.run(watcherGetPrepareRegistration);
sagaMiddleware.run(watcherSaveCategory);
sagaMiddleware.run(watcherUpdateCategory);
sagaMiddleware.run(watcherDeleteCategory);
sagaMiddleware.run(watcherAccounts);
sagaMiddleware.run(watcherSupplier);
sagaMiddleware.run(watcherSaveCompanyCategory);
sagaMiddleware.run(watcherSaveLoaction);
sagaMiddleware.run(watcherDeleteLocation);
sagaMiddleware.run(watcherUpdateLocation);
sagaMiddleware.run(watcherPrepareMachineRegistration);
sagaMiddleware.run(watcherGetMachines);
sagaMiddleware.run(watcherAddMachine);
sagaMiddleware.run(watcherGetERP_machines);
sagaMiddleware.run(watcherGetERP_users);
sagaMiddleware.run(watcherGetMachine);
sagaMiddleware.run(watcherNewWorkOrder);
sagaMiddleware.run(watcherGetWorkOrders);
sagaMiddleware.run(watcherGetWorkOrders_v2)
sagaMiddleware.run(watchersetUsersToOrder);
sagaMiddleware.run(watcherStartOrder);
sagaMiddleware.run(watcherFinishOrder);
sagaMiddleware.run(watcherPauseOrder);
sagaMiddleware.run(watcherUpdateMachine);
sagaMiddleware.run(watcherDeleteMachine);
sagaMiddleware.run(watcherConfirmOrder);
sagaMiddleware.run(watcherRejectOrder);
sagaMiddleware.run(watcherGetWorkOrder);
sagaMiddleware.run(watcherUpdateWorkOrder);
sagaMiddleware.run(watcherAddMaterial);
sagaMiddleware.run(watcherDeleteMaterial);
sagaMiddleware.run(watcherUpdateReport);
sagaMiddleware.run(watcherDeleteReport);
sagaMiddleware.run(watcherAccount);
sagaMiddleware.run(watcher_addPartCategory);
sagaMiddleware.run(watcher_getParts);
sagaMiddleware.run(watcher_addPart);
sagaMiddleware.run(watcher_getPartsCategories);
sagaMiddleware.run(watcher_updatePartCategory);
sagaMiddleware.run(watcher_removePartCategory);
sagaMiddleware.run(watcher_updatePart);
sagaMiddleware.run(watcher_getPart);
sagaMiddleware.run(watcher_addPartToOrder);
sagaMiddleware.run(watcher_techniciansResponseTime);
sagaMiddleware.run(watcher_totalDowntime);
sagaMiddleware.run(watcher_workOrderWorkingAvgTime);
sagaMiddleware.run(watcher_numOfDowntimesPerCategory);
sagaMiddleware.run(watcher_updateMachineType);
sagaMiddleware.run(watcher_saveMachineFunction);
sagaMiddleware.run(watcherCalendarMaintenances);
sagaMiddleware.run(watcher_inBowl);
sagaMiddleware.run(watcher_totalDowntimePerAsset);
sagaMiddleware.run(watcher_mtbm);
sagaMiddleware.run(watcher_maintenanceNumberPerAsset);
sagaMiddleware.run(watcher_mttr);
sagaMiddleware.run(watcher_mtbf);
sagaMiddleware.run(watcher_techniciansLoginAndWorkTime);
sagaMiddleware.run(watcher_addDocument);
sagaMiddleware.run(watcher_removeDocument);
sagaMiddleware.run(watcher_technicianAvailabilityTrend);
sagaMiddleware.run(watcher_technicianGenealEffect);
sagaMiddleware.run(watcher_assetMaintenanceSummary);
sagaMiddleware.run(watcher_assetMaintenanceList);
sagaMiddleware.run(watcher_getPartsByStatus);
sagaMiddleware.run(watcher_confirmPartRemoval);
sagaMiddleware.run(watcher_reporterGeneralEffect);
sagaMiddleware.run(watcher_insuranceOrders);
sagaMiddleware.run(watcher_insurance);
sagaMiddleware.run(watcher_addInsuranceMaterial);
sagaMiddleware.run(watcher_removeInsuranceReportMaterials);
sagaMiddleware.run(watcher_changeInsuranceOrderStatus);
sagaMiddleware.run(watcher_deleteSupplierCategory);
sagaMiddleware.run(watcher_getPartEnteries);
sagaMiddleware.run(watcher_addPartToStorage);
sagaMiddleware.run(watcher_removePartEntery);
sagaMiddleware.run(watcher_removePart);
sagaMiddleware.run(watcher_transportPart);
sagaMiddleware.run(watcher_getPartsInTransport);
sagaMiddleware.run(watcher_spendPart);
sagaMiddleware.run(watcher_confirmTransport);
sagaMiddleware.run(watcher_waitingForConfirmation);
sagaMiddleware.run(watcher_removePartSpent);
sagaMiddleware.run(watcher_historyPartSpent);
sagaMiddleware.run(watcher_spentsWithoutParts);
sagaMiddleware.run(watcher_totalSpentParts);
sagaMiddleware.run(watcher_stockPurchase);
sagaMiddleware.run(watcher_allPartStorages);
sagaMiddleware.run(watcher_reportForAdministration);

export const rrfProps = {
  dispatch: store.dispatch,
};

export default store;
