import React, { Component } from "react";

import { Spin } from "antd";

class Loading extends Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "33vh",
        }}
      >
        <Spin size={this.props.size || "large"} />
      </div>
    );
  }
}

export default Loading;
