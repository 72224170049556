import React, { useState } from "react";

import { Row, Col, Tabs } from "antd";

import CategoriesList from "./CategoriesList";
import CategoryForm from "./CategoriesForm";
import { useSelector } from "react-redux";

const { TabPane } = Tabs;

const Categories = ({ categories, close }) => {
  const [state, set_state] = useState({
    update: null,
  });

  const category = useSelector(
    (state) => state.accoutingReducer.company_category
  );

  return (
    <>
      <Row>
        <Col md={12} sm={24}>
          <CategoryForm
            close={close}
            category={category}
            categories
            update={state.update}
            setUpdate={() => {
              set_state({ ...state, update: null });
            }}
          />
        </Col>
        <Col md={12} sm={24}>
          <CategoriesList
            setUpdate={(data) => {
              console.log(data);
              set_state({ ...state, update: data });
            }}
            list={categories}
          />
        </Col>
      </Row>
    </>
  );
};

export default Categories;
