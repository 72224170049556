import React, { Component } from "react";

import Form from "./Form";

import { connect } from "react-redux";


import * as actions from "../../../../../redux/modules/Maintenances/actions";

import { Alert } from "antd";

class NewOrder extends Component {
  state = {};

  render() {
    const { machines, prepareRegistration } = this.props;

    const alert = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1" }}
        message="Dogodila se greša"
        description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
        type="error"
      />
    );


    return (
      <>
        <Form
          prepareRegistration={prepareRegistration.data}
          machines={this.props.machines.data}
          saveOrder={this.props.onSaveWorkOrder}
          workOrder={this.props.workOrder}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveWorkOrder: (data, callback) =>
      dispatch({ type: actions.NEW_WORK_ORDER, data, callback }),
  };
};

const mapStateToProps = (state) => {
  return {
    prepareRegistration: state.machinesReducer.prepareRegistration,
    workOrder: state.maintenancesReducer.workOrder,
    machines: state.machinesReducer.machines,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewOrder);
