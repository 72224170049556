import React, { Component } from "react";

import { List, Icon } from "antd";

class CategoriesList extends Component {
  state = {};

  render() {
    const { list } = this.props;

    return (
      <List
        itemLayout="horizontal"
        dataSource={list}
        renderItem={(item) => (
          <List.Item
            style={{ padding: "3px", marginLeft: "2px" }}
            extra={
              <>
                <Icon
                  type="edit"
                  style={{
                    marginRight: "10px",
                    fontSize: "23px",
                    color: "#4890ff",
                  }}
                />
              </>
            }
          >
            <List.Item.Meta description={item.name} />
          </List.Item>
        )}
      ></List>
    );
  }
}

export default CategoriesList;
