import React, { useState } from "react";

import { Button, Form, Input, InputNumber, message, Select, TreeSelect } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { SPEND_PART } from "../../../../redux/modules/Parts/actions";
import { GET_ORDER_SUCCESS } from "../../../../redux/modules/Maintenances/actions";
import { newNestedSorting } from "../../../../helpers/functions";

const SpendPartWithoutOrder = ({  close, _part }) => {
  const [part, set_part] = useState(_part);


  const dispatch = useDispatch();
  const { spendPart } = useSelector((state) => state.partsReducer);

  const [location, set_location] = useState(_part.part_storage.length === 1 ? _part.part_storage[0]?.storage?.id : undefined);
  const [quantity, set_quantity] = useState(null);
  const [locations, set_locations] = useState(_part.part_storage.map((x) => x.storage) || []);
  const [note, set_note] = useState("");

  const onSpend = () => {
    if (!+quantity || !quantity || !location || !part) {
      message.warn("Sva polja su obavezna");
      return;
    }

    dispatch({
      type: SPEND_PART,
      data: {
        work_order: null,
        part: part.id,
        location,
        qty: +quantity,
        note,
      },
      errorCallback: () => {
        message.error("Dogodila se greška.");
      },
      successCallback: (data) => {
       
      
        close();
      },
    });
  };


  const maxQty = _part.part_storage.find(x => x.storage.id === location)?.qty || 0;

  return (
    <div
      style={{
        height: 369,
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        flexDirection: "column",
        // maxWidth: 360,
      }}
    >
      <div>
        <span>Dio</span>
        <div>
            <Input value={_part.name} disabled={true} />
        </div>
      </div>
      <div>
        <span>Skladište</span>
        <div>
          <TreeSelect
            style={{ width: "100%" }}
            allowClear={true}
            multiple={false}
            value={location}
            treeData={newNestedSorting(locations)}
            onSelect={set_location}
            showSearch
            optionFilterProp="name"
            filterTreeNode={(input, option) => {
              return (
                option.props.name.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              );
            }}
          />
        </div>
      </div>

      <div>
        <div>Količina</div>
        <InputNumber max={+maxQty} onChange={value =>{ 
             if(+value> maxQty){
                message.warning("Unesite količinu koja je manja ili jednaka maksimalnoj.");
                return;
            }
            set_quantity(value)}} />
        <i style={{marginLeft: 12}}>Maximalno {+maxQty}</i>
      </div>

      <div>
        <span>Napomena</span>
        <Input.TextArea
          rows={3}
          onChange={({ target: { value } }) => set_note(value)}
        />
      </div>

      {console.log(quantity || location || part)}

      <Button loading={spendPart.status === "loading"} onClick={() => {
           if(quantity> maxQty){
            message.warning("Unesite količinu koja je manja ili jednaka maksimalnoj.");
            return;
        }
        if(!quantity || !location || !part){
          message.warning("Unesite polja.");
          return;
        }
     
        onSpend();
      }}>
        Spremi
      </Button>
    </div>
  );
};

export default SpendPartWithoutOrder;
