import React, { Component } from "react";
import css from "../../../machine.module.scss";
import { Button, Form, Upload, Icon, Alert } from "antd";
import { ALLOWED_EXTENSIONS } from "../../../../../helpers/consts";



class MaterialForm extends Component {
  state = { alertSuccessVisible: false, fileList: [] };

  showSuccess = () => {
    this.setState({
      alertSuccessVisible: true,
      fileList: [],
    });

    setTimeout(() => {
      this.setState({ alertSuccessVisible: false });
    }, 3000);
  };

  addMaterial = (type) => {
    const formData = new FormData();

    this.state.fileList.forEach((item) => {
      formData.append("files", item);
    });


    this.props._addMaterial(
      this.props.id,
      formData,
      type,
      this.showSuccess.bind(this)
    );
  };

  beforeUpload = (file) => {
    this.setState((state) => ({
      fileList: [...state.fileList, file],
    }));
    return false;
  };

  removeFile = (file) => {
    let fileList = this.state.fileList.concat();
    const index = fileList.indexOf(file);
    fileList.splice(index, 1);
    this.setState({ fileList });
  };

  render() {

    const FILE_TYPE = ALLOWED_EXTENSIONS.find((item) => item.type === this.props.formType).text

    const alert = (
      <Alert
        style={{ zIndex: "1", marginTop: 32 }}
        message="Dogodila se greša"
        description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
        type="error"
      />
    );

    const alertSuccess = (
      <Alert
        style={{ zIndex: "1", marginTop: 32 }}
        message="Uspješno"
        description={`Uspješno ste dodali ${FILE_TYPE}.`}
        type="success"
      />
    );
    const { material } = this.props;
    return (
      <div style={{ width: 600, padding: "40px 32px", borderColor: "#dedede", borderStyle: "dashed", borderWidth: 1 }}>
        {/* <hr /> */}
        <div className={css.instructionsTitleContainer}>
          <div>
            <h1 className={css.instructionsTitleMainText}>Dodajte { FILE_TYPE }</h1>
            <h4 className={css.instructionsTitleSecondaryText}>Dozvoljene ekstenzije: { ALLOWED_EXTENSIONS.find((item) => item.type === this.props.formType).extensions }</h4>
          </div>
          <Button
            onClick={() => this.props.toggleMaterialForm(false)}
            style={{ overflow: "hidden", float: "right" }}
          >
            Zatvori
          </Button>
        </div>
        {/* <Alert
          style={{margin: "9px", maxWidth: "600px", marginLeft: 0}}
          description={
            "Dozvoljene ekstenzije dokumenta su: " +
            ALLOWED_EXTENSIONS.find((item) => item.type === this.props.formType)
              .extensions + "."
          }
          type="warning"
          showIcon
        /> */}
        <div style={{ display: "flex", flexDirection: "row", justifyContent:"space-between", alignItems:"flex-start" }}>
          <div style={{ marginTop: 32 }}>
            <Upload
              style={{ marginTop: "9px" }}
              beforeUpload={this.beforeUpload}
              onRemove={this.removeFile}
              multiple={true}
              fileList={this.state.fileList}
              showUploadList={true}
              accept={
                ALLOWED_EXTENSIONS.find(
                  (item) => item.type === this.props.formType
                ).extensions
              }
            >
              <Button type={"primary"}>
                <Icon type="upload" /> Učitajte { FILE_TYPE }
              </Button>
            </Upload>
          </div>
          <div style={{ marginTop: 32 }}>
            <Button
              onClick={() => this.addMaterial(this.props.formType)}
              type={"primary"}
              disabled={this.state.fileList.length === 0}
              loading={material.status === "loading"}
            >
              Spremi
            </Button>
          </div>
        </div>

        {/* Alerts */}
        {material.status === "error" ? alert : null}
        {this.state.alertSuccessVisible ? alertSuccess : null}

      </div>
    );
  }
}

export default Form.create()(MaterialForm);
