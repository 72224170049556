import axios from "axios";

import { getApiEndpoint } from "./endpoints";

const instance = (options) =>
  axios.create({
    baseURL: getApiEndpoint() + "reports/",
    timeout: 12000,
    headers: {
      Authorization: "Token " + options.token,
    },
  });

export default instance;
