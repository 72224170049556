import React, { useState } from "react";

import { Button, List } from "antd";

import { Link } from "react-router-dom";
import { ArrowsAltOutlined, ShrinkOutlined } from "@ant-design/icons";

const ICON_SIZE = 21;

const Asset = ({ item, assets, assetsTree, target }) => {
  const [visibleChilds, set_visibleChilds] = useState(item.parent_item ? false : true);

  const childs = item.replies && item.replies.length && (
    <List
      style={{ marginLeft: "33px", display: visibleChilds ? "block" : "none" }}
      itemLayout="horizontal"
      dataSource={item.replies}
      renderItem={(item) => (
        <Asset item={item} assetsTree={assetsTree} target={target} assets={assets} />
      )}
    />
  );

  return (
    <>
      <List.Item
        className={`hover listTreeItem ${childs !== 0 && "parentListTreeItem"}`}
        style={{padding: 6}}
        extra={
          childs !== 0 && (
            <Button onClick={() => set_visibleChilds(!visibleChilds)}>
              {visibleChilds ? <ShrinkOutlined style={{fontSize: ICON_SIZE}} /> : <ArrowsAltOutlined style={{fontSize: ICON_SIZE}} />}
            </Button>
          )
        }
      >
        <Link target={target} to={`/machines/${item.id}/specifications`}>{item.name}</Link>
      </List.Item>

      {/* ---------------------  CHILDS --------------- */}

      {childs !== 0 ? childs : null}
    </>
  );
};

export default Asset;
