import { Col, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TOTAL_DOWNTIME_PER_ASSET } from "../../../../redux/modules/Analytics/actions";
import css from "../assets.module.scss";
import Information from "./Cards/Information";
import { decimalPointsWithSuffix } from "../../../../helpers/functions";
import { Link } from "react-router-dom";

const AssetTimes = () => {
  const {
    pickedDate,
    assetsReports: { filters },
    totalDowntimePerAsset,
  } = useSelector((state) => state.AnalyticsReducer);

  const dispatch = useDispatch();
  const [COLUMNS, set_COLUMNS] = useState([
    {
      title: "Imovina",
      dataIndex: "asset_name",
      render: (text, value) => {
        return <Link to={`/machines/${value.asset_id}/specifications`}>{text}</Link>;
      },
    },
    {
      title: "Ukupan rad imovine",
      dataIndex: "total_work_time",
      render: (text, value) => {
        return value.total_work_time.toFixed(1) + " h";
      },
    },
    {
      title: "Ukupan zastoj imovine",
      dataIndex: "total_downtime",
      render: (text) => {
        return text.toFixed(1) + " h";
      },
    },
    {
      title: "Dostupnost imovine",
      dataIndex: "availability",
    },
  ]);

  useEffect(() => {
    const queryParams = {
      from: pickedDate.data.from,
      to: pickedDate.data.to,
      locations: filters.locations.join("|"),
      assets: "11", //Odlučeno da će se samo proizvodna imovina zakucatfilters.assets.join("|"),
      assets_category: filters.assets_category.join("|"),
    };
    getReports(queryParams);
  }, [pickedDate, filters]);

  const getReports = (queryParams) => {
    dispatch({ type: TOTAL_DOWNTIME_PER_ASSET, queryParams });
  };

  return (
    <div>
      <Row style={{ marginBottom: 20, marginTop: 20 }} gutter={24}>
        <Col span={8}>
          <Information
            hideHoursLabel={true}
            text={""}
            width={300}
            information={decimalPointsWithSuffix(
              Math.ceil(totalDowntimePerAsset.data?.total_work_time, {
                maxDecimalPoints: 1,
              }) || null,
              1,
              "h"
            )}
            label="Ukupan rad imovine"
          />
        </Col>
        <Col span={8}>
          <Information
            text={""}
            width={300}
            hideHoursLabel={true}
            information={decimalPointsWithSuffix(
              Math.ceil(totalDowntimePerAsset.data?.total_downtime, {
                maxDecimalPoints: 1,
              }) || null,
              1,
              "h"
            )}
            label="Ukupan zastoj imovine"
          />
        </Col>
        <Col span={8}>
          <Information
            hideHoursLabel={true}
            text={""}
            width={300}
            information={decimalPointsWithSuffix(totalDowntimePerAsset.data?.availability || null, 1, "")}
            suffix="%"
            label="Dostupnost imovine"
          />
        </Col>
      </Row>

      <div className={css.header} style={{ marginBottom: 24, marginTop: 40 }}>
        <p className={css.value} style={{ color: "unset" }}>
          Broj proizvodnih strojeva uključenih u izvještaj{": "}
          <span style={{ color: "#ff0000" }}>{totalDowntimePerAsset.data?.included_items || 0}</span>
        </p>
        <i>Podaci dolaze izravno sa strojeva</i>
      </div>

      <Table
        loading={totalDowntimePerAsset.status === "loading"}
        style={{ marginTop: 12 }}
        dataSource={totalDowntimePerAsset.data?.data || []}
        columns={COLUMNS}
        pagination={false}
      />
    </div>
  );
};

export default AssetTimes;
