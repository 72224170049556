import React from "react";
import css from "../machine.module.scss";

export default function SectionTitle({ title, subtext }){

  return (
    <>
        <h1 className={css.sectionTitle}>{ title }</h1>
        <p className={css.sectionTitleSubtext}>{ subtext }</p>
    </>
  );
};
