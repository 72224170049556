import React, { useState, useMemo } from "react";
import css from "../../../warehouse.module.scss";
import { Popover } from "antd";
import Content from "../../Popover";

import TOOLS_GREEN from '../../../../../../../assets/icons/tools_green.png'
import TOOLS_ORANGE from '../../../../../../../assets/icons/tools_orange.png'
import TOOLS_RED from '../../../../../../../assets/icons/tools_red.png'

export default function CriticalParts({
  label = "",
  information = 0,
  value = false,
  onClick,
  activeCard,
}) {
  // Variables
  const [popoverVisible, setPopoverVisible] = useState(false);
  const LIMITS = [0, 5];

  // Methods
  function togglePopover() {
    setPopoverVisible((previousState) => !previousState);
  }

  // Memo
  const icon = useMemo(() => {
    if (information <= LIMITS[0]) {
      return (
        <div
          className={css.informationIconBackground}
          style={{ backgroundColor: "#DDFFEB" }}
        >
          <img
            className={css.informationIcon}
            src={TOOLS_GREEN}
          />
        </div>
      );
    } else if (information > LIMITS[0] && information < LIMITS[1]) {
      return (
        <div
          className={css.informationIconBackground}
          style={{ backgroundColor: "#FFF3DC" }}
        >
          <img
            className={css.informationIcon}
            src={TOOLS_ORANGE}
          />
        </div>
      );
    } else {
      return (
        <div
          className={css.informationIconBackground}
          style={{ backgroundColor: "#FCE0EA" }}
        >
          <img
            className={css.informationIcon}
            src={TOOLS_RED}
          />
        </div>
      );
    }
  });

  return (
    // <Popover
    //   content={
    //     <Content
    //       close={setPopoverVisible}
    //       title={label}
    //       text="Neke informacije o kartici"
    //     />
    //   }
    // >
    <div
      style={{ borderLeft: activeCard && `1px solid #f3a300` }}
      id={css.informationCard}
      onClick={onClick}
    >
      {/* Icon */}
      {icon}

      {/* Informations */}
      <div className={css.infoContainer}>
        <p className={css.value}>
          {information}
          {value && "€"}
        </p>
        <p className={css.label}>{label}</p>
      </div>

      {/* Indicator */}
      {/* <div className={css.togglePopover}>
          <div className={css.togglePopoverCircle} />
          <div className={css.togglePopoverCircle} />
          <div className={css.togglePopoverCircle} />
        </div> */}
    </div>
    // </Popover>
  );
}
