import React from 'react'

import { InfoCircleOutlined } from "@ant-design/icons";
import css from './popover.module.scss'

export default function Content({ title, text }){

    return (
        <div className={css.popoverContainer}>
            {/* Title */}
            <div className={css.titleContainer}>
                <InfoCircleOutlined className={css.titleIcon} />
                <p className={css.title}>{ title }</p>
            </div>
            {/* Divider */}
            <div className={css.divider} />
            {/* Text */}
            <div className={css.text}>
                <p className={css.textlabel}>{ text }</p>
            </div>
        </div>
    )
}
