import React, { useState } from "react";
import Finish from "./Finish";
import { Button, Modal, message } from "antd";
import axios_maintenances from "../../../../axios/axios-maintenances";
import { GET_ORDERS_SUCCESS, GET_ORDER_SUCCESS } from "../../../../redux/modules/Maintenances/actions";
import { LOGOUT } from "../../../../redux/modules/Users/actions";
import { useDispatch } from "react-redux";



const Control = ({order}) => {

    const [finishModalVisible, set_finishModalVisible] = useState(false);
    const [loading, set_loading] = useState(false);
    const dispatch = useDispatch();
    const [files, set_files] = useState([]);
    const [text, set_text] = useState("");
    const {id} = order;

    const onFinish = async () => {
        set_loading(true);
        try {
          let token = await localStorage.getItem("token");
          let formData = new FormData();
          let data = { text: "Sve je ispravno", technician_id: -1, schedule_id: order.schedule?.id || null, is_success: true };
    
          formData.append("data", JSON.stringify(data));
    
          for (let index = 0; index < files.length; index++) {
            const element = files[index];
            formData.append("files", element);
          }
          let options = {
            token,
          };
          let res = await axios_maintenances(options).post(
            `work_orders/${id}/finish_work/`,
            formData
          );
    
          dispatch({ type: GET_ORDER_SUCCESS, data: res.data });
         
    
        } catch (error) {
          if (error.response && error.response.status === 401) {
            message.warning("Niste prijavljeni.");
            dispatch({ type: LOGOUT });
            return;
          }
          console.log(error);
          message.error("Dogodila se greska.");
        }
        set_loading(false);
      };

  return <div style={{marginRight: 72}} >
    <div style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

    }} >
        <Button onClick={() => set_finishModalVisible(true)} style={{marginRight: 12}}>
            Ne radi
        </Button >
        <Button onClick={onFinish} loading={loading} type="primary">
            Ok
        </Button>
    </div>
      <Modal
        title="Završi"
        visible={finishModalVisible}
        onCancel={() => set_finishModalVisible(false)}
        footer={null}
        destroyOnClose
      >
        <Finish technician_id={-1} close={() => {
          set_finishModalVisible(false);
        }} />
      </Modal>
        </div>;
}


export default Control;