import React, { Component } from "react";

import * as actions from "../../../../../redux/modules/Maintenances/actions";

import { Link } from "react-router-dom";
import { Row, Col, List, Button, Alert, Modal, Tabs } from "antd";
import { connect } from "react-redux";
import SizeType from "../../../../../helpers/deviceTypeSize";

import {
  isTechnicianPrimary,
  isTechnicianBusy,
  technicianOfOrder,
} from "../../../../../helpers/getSpecValueFromOrder";

import { sortWorkingOrderToTop } from "../../../../../helpers/sorting";

import OrderDetails from "./../OrderDetails";

import Spinner from "../../../../../components/LoadingScreen";

import Resaon from "../../PauseReason";
import OrderTimeView from "../../Order/OrderTimeView";
import OrderUsers from "../../Order/OrderUsers";
import Commands from "../../Order/Commands";

const { TabPane } = Tabs;

class Dashboard extends Component {
  state = {
    resaonModalVisible: false,
    order_id: 0,
    order: {
      technicians: [],
    },
    reason_type: "",
    active_key: "appointed",
  };

  componentDidMount() {
    this.props.onGetOrders();
  }

  toggleResaonModal = (bool, order_id, reason_type) => {
    this.setState({ resaonModalVisible: bool, order_id, reason_type });
  };

  setActiveKeyTab = (active_key) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.setState({ active_key });
  };

  showSuccess = () => {
    this.toggleResaonModal(false, 0);
  };

  handleSetUsersToOrder = (id) => {
    const data = {
      technicians: [{ account: this.props.currentUser.id, is_primary: 1 }],
    };
    this.props.onSetUsersToOrder(
      data,
      id,
      this.setActiveKeyTab.bind(this, "appointed")
    );
  };

  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  handleStartOrder = (id, flag, technician_id, is_primary) => {
    this.props.onStartOrder(
      id,
      flag,
      { technician_id, is_primary },
      flag === "start"
        ? this.setActiveKeyTab.bind(this, "in_progress")
        : this.scrollToTop.bind(this)
    );
  };

  handleFinishOrder = (id) => {
    const data = {
      report: {
        text: null,
      },
      schedule_id: this.props.orders.data.find((item) => item.id === id)
        .schedule,
    };
    this.props.onFinishOrder(id, data, null);
  };

  pauseOrder = (id, data, flag) => {
    // used also for reject dont ask anything
    if (flag === "reject") {
      this.props.onRejectOrder(
        id,
        data,
        flag,
        this.toggleResaonModal.bind(this)
      );
      return;
    }
    this.props.onPauseOrder(id, data, flag, this.toggleResaonModal.bind(this));
  };

  alertMessage = () => {
    alert("Funkcionalnost u izradi");
  };

  render() {
    const {
      orders,
      setUsersToOrder,
      startOrder,
      finishOrder,
      pauseOrder,
      currentUser,
    } = this.props;

    if (orders.status === "loading") {
      return <Spinner />;
    }

    orders.data = orders.data.sort(function (a, b) {
      return b.type.id - a.type.id;
    });

    const alert = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1" }}
        message="Dogodila se greša"
        description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
        type="error"
      />
    );

    return (
      <>
        {setUsersToOrder.status === "error" ||
        startOrder.status === "error" ||
        finishOrder.status === "error"
          ? alert
          : null}
        {/* {orderBadges(orders.data)} */}
        <div id="order-tabs">
          <Tabs
            activeKey={this.state.active_key}
            onChange={(active_key) => this.setState({ active_key })}
            id="order-tabs-tabs"
          >
            <TabPane tab={in_bowl(orders.data)} key="in_bowl">
              {" "}
              <List
                style={{
                  padding:
                    SizeType() === "xs" || SizeType() === "sm" ? "6px" : "27px",
                }}
                header={null}
                itemLayout="vertical"
                dataSource={orders.data.filter(
                  (item) => item.status === "in_bowl"
                )}
                renderItem={(item) => (
                  <List.Item
                    extra={
                      <>
                        <Commands
                          item={item}
                          finishOrder={finishOrder}
                          toggleResaonModal={this.toggleResaonModal}
                          alertMessage={this.alertMessage}
                          pauseOrder={this.pauseOrder}
                          orders={orders}
                          startOrder={startOrder}
                          currentUser={currentUser}
                          handleSetUsersToOrder={this.handleSetUsersToOrder}
                          setUsersToOrder={setUsersToOrder}
                          is_primary={true}
                          busy={isTechnicianBusy(item, currentUser)}
                        />
                      </>
                    }
                  >
                    <List.Item.Meta
                      title={item.name}
                      description={
                        <div>
                          <OrderDetails {...item} />
                          <div>
                            {" "}
                            <Link to={`/dashboard/order/${item.id}`}>IDI</Link>
                          </div>
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
            </TabPane>
            <TabPane tab={appopinted(orders.data)} key="appointed">
              <List
                style={{
                  padding:
                    SizeType() === "xs" || SizeType() === "sm" ? "6px" : "27px",
                }}
                header={null}
                itemLayout="vertical"
                dataSource={sortWorkingOrderToTop(
                  orders.data.filter((item) => item.status === "appointed")
                )}
                renderItem={(item) => (
                  <List.Item
                    extra={
                      <>
                        <Commands
                          item={item}
                          finishOrder={finishOrder}
                          toggleResaonModal={this.toggleResaonModal}
                          alertMessage={this.alertMessage}
                          pauseOrder={this.pauseOrder}
                          orders={orders}
                          startOrder={startOrder}
                          currentUser={currentUser}
                          handleStartOrder={this.handleStartOrder}
                          setUsersToOrder={setUsersToOrder}
                          is_primary={isTechnicianPrimary(item, currentUser)}
                          busy={isTechnicianBusy(item, currentUser)}
                        />
                      </>
                    }
                  >
                    <List.Item.Meta
                      title={item.name}
                      description={
                        <div>
                          <OrderDetails {...item} />
                          <OrderUsers
                            order={item}
                            technicians={item.technicians}
                            currentUser={currentUser}
                          />
                          <div>
                            {" "}
                            <Link to={`/dashboard/order/${item.id}`}>IDI</Link>
                          </div>
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
            </TabPane>
            <TabPane
              tab={in_progress(orders.data, currentUser)}
              key="in_progress"
            >
              <List
                style={{
                  padding:
                    SizeType() === "xs" || SizeType() === "sm" ? "6px" : "27px",
                }}
                header={null}
                itemLayout="vertical"
                dataSource={sortWorkingOrderToTop(
                  orders.data.filter(
                    (item) =>
                      item.status === "in_progress" || item.status === "waiting"
                  )
                )}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.name}
                      description={
                        <>
                          <OrderTimeView
                            status={item.status}
                            real_start={item.real_start}
                          />
                          <div style={{ height: "12px" }}></div>
                          <div>
                            <OrderDetails {...item} />
                            <div>
                              {" "}
                              <Link to={`/dashboard/order/${item.id}`}>
                                IDI
                              </Link>
                            </div>
                          </div>
                          <div style={{ height: "12px" }}></div>
                          <OrderUsers
                            order={item}
                            technicians={item.technicians}
                            currentUser={currentUser}
                          />
                          <div style={{ paddingLeft: "0" }}>
                            <Commands
                              item={item}
                              finishOrder={finishOrder}
                              toggleResaonModal={this.toggleResaonModal}
                              alertMessage={this.alertMessage}
                              pauseOrder={this.pauseOrder}
                              orders={orders}
                              startOrder={startOrder}
                              currentUser={currentUser}
                              handleSetUsersToOrder={this.handleSetUsersToOrder}
                              setUsersToOrder={setUsersToOrder}
                              is_primary={isTechnicianPrimary(
                                item,
                                currentUser
                              )}
                              busy={isTechnicianBusy(item, currentUser)}
                              handleFinishOrder={this.handleFinishOrder}
                              handleStartOrder={this.handleStartOrder}
                            />
                          </div>
                        </>
                      }
                    />
                  </List.Item>
                )}
              />
            </TabPane>
          </Tabs>
        </div>
        <Modal
          footer={null}
          onCancel={() => this.toggleResaonModal(false)}
          visible={this.state.resaonModalVisible}
          destroyOnClose={true}
        >
          <Resaon
            pauseOrder={this.props.pauseOrder}
            _pauseOrder={this.pauseOrder}
            order_id={this.state.order_id}
            orders={orders}
            currentUser={currentUser}
            reason_type={this.state.reason_type}
            rejectOrder={this.props.rejectOrder}
          ></Resaon>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onGetOrders: () => dispatch({ type: actions.GET_ORDERS }),
    onSetUsersToOrder: (data, order_id, callback) =>
      dispatch({ type: actions.SET_USERS_TO_ORDERS, data, order_id, callback }),
    onStartOrder: (order_id, flag, data, callback) =>
      dispatch({ type: actions.START_ORDER, order_id, data, flag, callback }),
    onFinishOrder: (order_id, data, callback) =>
      dispatch({ type: actions.FINISH_ORDER, order_id, data, callback }),
    onPauseOrder: (order_id, data, flag, callback) =>
      dispatch({ type: actions.PAUSE_ORDER, order_id, data, flag, callback }),
    onRejectOrder: (order_id, data, flag, callback) =>
      dispatch({ type: actions.REJECT_ORDER, order_id, data, flag, callback }),
  };
};

const mapStateToProps = (state) => {
  return {
    orders: state.maintenancesReducer.orders,
    setUsersToOrder: state.maintenancesReducer.setUsersToOrder,
    startOrder: state.maintenancesReducer.startOrder,
    currentUser: state.usersReducer.user.data,
    finishOrder: state.maintenancesReducer.finishOrder,
    pauseOrder: state.maintenancesReducer.pauseOrder,
    rejectOrder: state.maintenancesReducer.rejectOrder,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

const in_bowl = (orders) => {
  return (
    <h5>
      Nedodjeljeni
      <span
        style={{
          position: "absolute",
          top: "-2px",
          right: "12px",
          fontWeight: "800",
          fontSize: "15",
          color: "#FF4D4F",
        }}
      >
        {orders.filter(
          (item) => item.status === "in_bowl" && item.type.id === 2
        ).length || null}
      </span>
      <span
        style={{
          position: "absolute",
          top: "-2px",
          right: "0px",
          fontWeight: "800",
          fontSize: "15",
          color: "#fccb6b",
        }}
      >
        {orders.filter(
          (item) => item.status === "in_bowl" && item.type.id === 1
        ).length || null}
      </span>
    </h5>
  );
};

const appopinted = (orders) => {
  return (
    <h5>
      {" "}
      Moji
      <span
        style={{
          position: "absolute",
          top: "-2px",
          right: "12px",
          fontWeight: "800",
          fontSize: "15",
          color: "#FF4D4F",
        }}
      >
        {orders.filter(
          (item) => item.status === "appointed" && item.type.id === 2
        ).length || null}
      </span>
      <span
        style={{
          position: "absolute",
          top: "-2px",
          right: "0px",
          fontWeight: "800",
          fontSize: "15",
          color: "#fccb6b",
        }}
      >
        {orders.filter(
          (item) => item.status === "appointed" && item.type.id === 1
        ).length || null}
      </span>
    </h5>
  );
};

const in_progress = (orders, currentUser) => {
  if (!orders || orders.length === 0) {
    return <h5>U tijeku</h5>;
  }

  return (
    <h5>
      {" "}
      U tijeku
      <span
        style={{
          position: "absolute",
          top: "-2px",
          right: "21px",
          fontWeight: "800",
          fontSize: "15",
          color: "#ff9800",
        }}
      >
        {orders.filter((item) => item.status === "waiting").length || null}
      </span>
      <span
        style={{
          position: "absolute",
          top: "-2px",
          right: "0px",
          fontWeight: "800",
          fontSize: "15",
          color: "#52C41A",
          boxShadow: "0, 0, 0, 0 rgba(82, 196, 26, 1)",
          animation:
          technicianOfOrder(orders[0], currentUser) && technicianOfOrder(orders[0], currentUser).account.status === "busy"
              ? "pulse 2s infinite"
              : "",
          width: "20px",
          height: "20px",
          borderRadius: "50%",
        }}
      >
        {technicianOfOrder(orders[0], currentUser) && technicianOfOrder(orders[0], currentUser).account.status === "busy"
          ? 1
          : null}
      </span>
    </h5>
  );
};
