export const TECHNICIANS_RESPONSE_TIME = "TECHNICIANS_RESPONSE_TIME";
export const TECHNICIANS_RESPONSE_TIME_SUCCESS = "TECHNICIANS_RESPONSE_TIME_SUCCESS";
export const TECHNICIANS_RESPONSE_TIME_FAIL = "TECHNICIANS_RESPONSE_TIME_FAIL";

export const TOTAL_DOWNTIME = "TOTAL_DOWNTIME";
export const TOTAL_DOWNTIME_SUCCESS = "TOTAL_DOWNTIME_SUCCESS";
export const TOTAL_DOWNTIME_FAIL = "TOTAL_DOWNTIME_FAIL";

export const WORK_ORDER_WORKING_AVG_TIME = "WORK_ORDER_WORKING_AVG_TIME";
export const WORK_ORDER_WORKING_AVG_TIME_SUCCESS = "WORK_ORDER_WORKING_AVG_TIME_SUCCESS";
export const WORK_ORDER_WORKING_AVG_TIME_FAIL = "WORK_ORDER_WORKING_AVG_TIME_FAIL";

export const PICKED_DATE = "PICKED_DATE";
export const PICKED_DATE_SUCCESS = "PICKED_DATE_SUCCESS";
export const PICKED_DATE_FAIL = "PICKED_DATE_FAIL";

export const ASSETS_FILTERS_SUCCESS = "ASSETS_FILTERS_SUCCESS";

export const PARTS_FILTERS_SUCCESS = "PARTS_FILTERS_SUCCESS";

export const MAINTENANCES_FILTERS_SUCCESS = "MAINTENANCES_FILTERS_SUCCESS";

export const WORK_EFFECTIVENESS_FILTERS_SUCCESS = "WORK_EFFECTIVENESS_FILTERS_SUCCESS";

export const TT_CABLES_FILTERS_SUCCESS = "TT_CABLES_FILTERS_SUCCESS";

export const TOTAL_DOWNTIME_PER_ASSET = "TOTAL_DOWNTIME_PER_ASSET";
export const TOTAL_DOWNTIME_PER_ASSET_SUCCESS = "TOTAL_DOWNTIME_PER_ASSET_SUCCESS";
export const TOTAL_DOWNTIME_PER_ASSET_FAIL = "TOTAL_DOWNTIME_PER_ASSET_FAIL";

export const MTBM = "MTBM";
export const MTBM_SUCCESS = "MTBM_SUCCESS";
export const MTBM_FAIL = "MTBM_FAIL";

export const MAINTENANCE_NUMBER_PER_ASSET = "MAINTENANCE_NUMBER_PER_ASSET";
export const MAINTENANCE_NUMBER_PER_ASSET_SUCCESS = "MAINTENANCE_NUMBER_PER_ASSET_SUCCESS";
export const MAINTENANCE_NUMBER_PER_ASSET_FAIL = "MAINTENANCE_NUMBER_PER_ASSET_FAIL";

export const MTTR = "MTTR";
export const MTTR_SUCCESS = "MTTR_SUCCESS";
export const MTTR_FAIL = "MTTR_FAIL";

export const MTBF = "MTBF";
export const MTBF_SUCCESS = "MTBF_SUCCESS";
export const MTBF_FAIL = "MTBF_FAIL";

export const NUM_OF_DOWNTIMES_PER_CATEGORY = "NUM_OF_DOWNTIMES_PER_CATEGORY";
export const NUM_OF_DOWNTIMES_PER_CATEGORY_SUCCESS = "NUM_OF_DOWNTIMES_PER_CATEGORY_SUCCESS";
export const NUM_OF_DOWNTIMES_PER_CATEGORY_FAIL = "NUM_OF_DOWNTIMES_PER_CATEGORY_FAIL";

export const TECHNICIANS_LOGIN_AND_WORK_TIME = "TECHNICIANS_LOGIN_AND_WORK_TIME";
export const TECHNICIANS_LOGIN_AND_WORK_TIME_SUCCESS = "TECHNICIANS_LOGIN_AND_WORK_TIME_SUCCESS";
export const TECHNICIANS_LOGIN_AND_WORK_TIME_FAIL = "TECHNICIANS_LOGIN_AND_WORK_TIME_FAIL";

export const TECHNICIAN_AVAILABILITY_TREND = "TECHNICIAN_AVAILABILITY_TREND";
export const TECHNICIAN_AVAILABILITY_TREND_SUCCESS = "TECHNICIAN_AVAILABILITY_TREND_SUCCESS";
export const TECHNICIAN_AVAILABILITY_TREND_FAIL = "TECHNICIAN_AVAILABILITY_TREND_FAIL";

export const TECHNICIAN_GENEAL_EFFECT = "TECHNICIAN_GENEAL_EFFECT";
export const TECHNICIAN_GENEAL_EFFECT_SUCCESS = "TECHNICIAN_GENEAL_EFFECT_SUCCESS";
export const TECHNICIAN_GENEAL_EFFECT_FAIL = "TECHNICIAN_GENEAL_EFFECT_FAIL";

export const ASSET_MAINTENANCE_SUMMARY = "ASSET_MAINTENANCE_SUMMARY";
export const ASSET_MAINTENANCE_SUMMARY_SUCCESS = "ASSET_MAINTENANCE_SUMMARY_SUCCESS";
export const ASSET_MAINTENANCE_SUMMARY_FAIL = "ASSET_MAINTENANCE_SUMMARY_FAIL";

export const ASSET_MAINTENANCE_LIST = "ASSET_MAINTENANCE_LIST";
export const ASSET_MAINTENANCE_LIST_SUCCESS = "ASSET_MAINTENANCE_LIST_SUCCESS";
export const ASSET_MAINTENANCE_LIST_FAIL = "ASSET_MAINTENANCE_LIST_FAIL";

export const REPORTER_GENERAL_EFFECT = "REPORTER_GENERAL_EFFECT";
export const REPORTER_GENERAL_EFFECT_SUCCESS = "REPORTER_GENERAL_EFFECT_SUCCESS";
export const REPORTER_GENERAL_EFFECT_FAIL = "REPORTER_GENERAL_EFFECT_FAIL";

export const TOTAL_SPENT_PARTS = "TOTAL_SPENT_PARTS";
export const TOTAL_SPENT_PARTS_SUCCESS = "TOTAL_SPENT_PARTS_SUCCESS";
export const TOTAL_SPENT_PARTS_FAIL = "TOTAL_SPENT_PARTS_FAIL";

export const STOCK_PURCHASE = "STOCK_PURCHASE";
export const STOCK_PURCHASE_SUCCESS = "STOCK_PURCHASE_SUCCESS";
export const STOCK_PURCHASE_FAIL = "STOCK_PURCHASE_FAIL";

export const REPORT_FOR_ADMINISTRATION = "REPORT_FOR_ADMINISTRATION";
export const REPORT_FOR_ADMINISTRATION_SUCCESS = "REPORT_FOR_ADMINISTRATION_SUCCESS";
export const REPORT_FOR_ADMINISTRATION_FAIL = "REPORT_FOR_ADMINISTRATION_FAIL";
