import * as actions from "./actions";

const initialState = {
  alerts: {
    criticalParts: [],
    dummy: [],
    notifications: [],
  },
  dashboardTechnicians: {
    status: "loading",
    data: {
      accounts: [],
      in_total: 0,
    },
  },

  dashboardMaintenances: {
    status: "loading",
    data: [],
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.DASHBOARD_MAINTENANCES:
      return {
        ...state,
        dashboardMaintenances: {
          status: "loading",
          data: [],
          message: "",
        },
      };

    case actions.DASHBOARD_MAINTENANCES_SUCCESS:
      return {
        ...state,
        dashboardMaintenances: {
          status: "",
          data: action.data,
          message: action.message,
        },
      };

    case actions.DASHBOARD_MAINTENANCES_FAIL:
      return {
        ...state,
        dashboardMaintenances: {
          status: "error",
          data: [],
          message: action.message,
        },
      };
    case actions.ALERTS_CRITICAL_PARTS:
      return {
        ...state,
        alerts: {
          criticalParts: action.data,
          dummy: state.alerts.dummy,
          notifications: state.alerts.notifications,
        },
      };

    case actions.ALERTS_NOTIFICATIONS:
      return {
        ...state,
        alerts: {
          criticalParts: state.alerts.criticalParts,
          dummy: state.alerts.dummy,
          notifications: 
            action.data// Sorting notifications by date
              .sort((a,b) => new Date(b.timestamp) - new Date(a.timestamp))
              .reverse(),
        },
      };

    case actions.DASHBOARD_TECHNICIANS:
      return {
        ...state,
        dashboardTechnicians: {
          status: "loading",
          data: {
            accounts: [],
            in_total: 0,
          },
          message: "",
        },
      };

    case actions.DASHBOARD_TECHNICIANS_SUCCESS:
      return {
        ...state,
        dashboardTechnicians: {
          status: "",
          data: {
            accounts: action.data.accounts,
            in_total: action.data.in_total,
          },
          message: action.message,
        },
      };

    case actions.DASHBOARD_TECHNICIANS_FAIL:
      return {
        ...state,
        dashboardTechnicians: {
          status: "error",
          data: {
            accounts: [],
            in_total: 0,
          },
          message: action.message,
        },
      };
    default:
      return { ...state };
  }
}
