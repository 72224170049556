import React, { Component } from "react";

class Tools extends Component {
  state = {};
  render() {


  
    return <div>Alati</div>;
  }
}

export default Tools;
