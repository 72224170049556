import React from "react";
import css from "../maintenance.module.scss";
import { Tabs } from "antd";
import { ProfileOutlined, ToolOutlined, ReadOutlined, SettingOutlined, FileSearchOutlined, TeamOutlined, BulbOutlined } from "@ant-design/icons";

import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";

const { TabPane } = Tabs;

function MaintenanceTabs(props){

  // Variables

  //? Saving tab after page refresh
  let tab = props.location.pathname.slice(props.match.url.length + 1)

  const TABS = [
    { name: "Osnovno", path: 'about', count: 0, icon: <ProfileOutlined /> },
    { name: "Zadaci", path: 'tasks', count: props.order.data.tasks.length, icon: <FileSearchOutlined /> },
    { name: "Izvještaji", path: 'reports', count: props.order.data.reports.length, icon: <ReadOutlined /> },
    { name: "Tehničari", path: 'technicians', count: props.order.data.technicians.length, icon: <TeamOutlined /> },
    { name: "Dijelovi", path: 'parts', count: props.order.data.parts.length, icon: <BulbOutlined /> },
    // { name: "Alati", path: 'tools', count: props.order.data.tools.length, icon: <ToolOutlined /> }
  ]

  // Methods
  const handleClick = (key) => {
    props.history.replace(key);
    props.setActiveKey();
    props.setCounter()
  };

  return (
    <div style={{ marginBottom: 20 }}>
      <Tabs defaultActiveKey={tab} onChange={handleClick}>
        {
          TABS.map(item => 
            <TabPane
              tab={
                <>
                  { item.icon }
                  <NavLink to={`${props.match.url}/${item.path}`}>
                    { item.name }
                    { item.count !== 0 && <span className={css.tabsBadge}>{`  (${item.count})`}</span> }
                  </NavLink>
                </>
              }
              key={item.path}
            />
          )
        }
      </Tabs>
    </div>
  );
};

export default withRouter(MaintenanceTabs);
