import { Button, Input, message, TimePicker } from "antd";
import Axios from "axios";
import React, { useState } from "react";
import { getApiEndpoint } from "../../../axios/endpoints";

const EditTime = ({ orderToken, setOrder, set_editTime }) => {
  const [editTime, _set_editTime] = useState({ status: "" });

  const [time, set_time] = useState(null);

  const [minutes, set_minute] = useState(0);
  const [hours, set_hours] = useState(0);

  const onEditTime = () => {
    if (isNaN(minutes) || isNaN(hours)) {
      message.warning("Nepravilan unos", 3);
      return;
    }

    _set_editTime({ status: "loading" });
    console.log(time);
    Axios.post(
      `${getApiEndpoint()}maintenances/open_work_orders/${orderToken}/add_estimated_duration/`,
      {
        hours,
        minutes,
      }
    )
      .then((res) => {
        setOrder({ status: "", data: res.data });
        _set_editTime({ status: "" });
        set_editTime(false);
      })
      .catch((err) => {
        _set_editTime({ status: "error" });
        message.error("Dogodila se greška!", 3);
      });
  };

  return (
    <div
      style={{
        padding: 12,
        height: "30vh",
        display: "flex",
        alignItems: "top",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Input
            type={"numeric"}
            placeholder={"Sati"}
            onChange={(e) => {
              set_hours(e.target.value);
            }}
          />
          <div style={{ height: 21 }} />
          <Input
            placeholder={"Minute"}
            onChange={(e) => {
              set_minute(e.target.value);
            }}
          />
        </div>
        <div style={{ textAlign: "center", marginTop: 60 }}>
          <Button
            loading={editTime.status === "loading"}
            disabled={!set_time}
            type={"primary"}
            onClick={() => onEditTime()}
          >
            Spremi
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditTime;
