import axios from "axios";

import {getApiEndpoint} from "./endpoints";


const instance = (options) => axios.create({
    baseURL: getApiEndpoint() + "accounts/", 
    timeout: 15000, 
    headers: {
        Authorization: 'Token ' + options.token,
    }
})

export default instance;