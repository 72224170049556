import React, { useState } from "react";

import { Tabs, Button } from "antd";
import css from "./tt_cables.module.scss";
import {
  NavLink,
  useHistory,
  useRouteMatch,
  Route,
  Switch,
} from "react-router-dom";

import Reports_1 from "./components/Reports_1";
import Filters from "./components/Filters";
import Reports_2 from "./components/Reports_2";

const { TabPane } = Tabs;

const Murga = ({}) => {
  const match = useRouteMatch();
  const history = useHistory();

  const [activeKey, set_activeKey] = useState(
    history.location.pathname.split("/").slice(-1)[0]
  );

  const [filtersVisible, set_filtersVisible] = useState(false);

  return (
    <>
      <Tabs
        tabBarExtraContent={
          activeKey === "reports_1" && (
            <Button
              onClick={() => {
                set_filtersVisible(true);
              }}
            >
              Filteri
            </Button>
          )
        }
        defaultActiveKey={activeKey}
        onChange={(key) => {
          history.replace(`/analytics/tt_cables/${key}`);
          set_activeKey(key);
        }}
      >
        {TABS.map((item) => (
          <TabPane
            tab={
              <>
                {item.icon}
                <NavLink to={`${match.url}/${item.path}`}>{item.name}</NavLink>
              </>
            }
            key={item.path}
          />
        ))}
      </Tabs>

      <div id={css.ttCables_container}>
        <Switch>
          <Route
            path={`${match.path}/reports_1`}
            render={() => <Reports_1 />}
          />
          <Route
            path={`${match.path}/reports_2`}
            render={() => <Reports_2 />}
          />
        </Switch>
      </div>

      {activeKey === "reports_1" && (
        <Filters
          filtersVisible={filtersVisible}
          set_filtersVisible={set_filtersVisible}
        />
      )}
    </>
  );
};

export default Murga;

const TABS = [
  {
    name: "Zastoji",
    path: "reports_1",
  },
  {
    name: "Prijavitelji",
    path: "reports_2",
  },
];
