import React, { useRef, useEffect, useState } from 'react'

// Styles
import { Carousel } from 'antd';
import styles from '../../index.module.css'
import { RightOutlined, LeftOutlined, ArrowLeftOutlined,  FileImageFilled, FilePdfFilled, VideoCameraFilled, FileExclamationFilled } from '@ant-design/icons';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

// interface MediaFile {
//     file: string,
//     name: string,
//     type: string,
//     extension: string
// }

// interface IProps {
//     files: MediaFile[],
//     index: number,
//     onClose: (v) => void;
// }

const FileViewerComponent = ({ files, index, onClose }) => {

    // Refs
    const carouselRef = useRef(null)
    const videoRef = useRef(null)
    const [localIndex, setLocalIndex] = useState(index)

    useEffect(() => {
        carouselRef.current.goTo(index, false)
    }, [])

    const onChange = (index) => {
        // 
    }

    const PreviousArrow = (props) => {
        const { className, style, onClick } = props

        function handleClick(event){
            if(localIndex === 0) setLocalIndex(files.length - 1)
            else setLocalIndex(previousIndex => --previousIndex)
            event.stopPropagation()
            onClick()
        }

        return (
          <div
            className={`${className} ${styles.fileViewerCarouselPrevArrow}`}
            style={{
                ...style,
                fontSize: '18px',
                lineHeight: '0',
                paddingLeft: '-20px',
                paddingTop: '16px'
            }}
            onClick={handleClick}
          >
             <LeftOutlined style={{ color: "#fff", transform: 'translateX(-1px)' }} />
          </div>
        )
    }

    const NextArrow = (props) => {
        const { className, style, onClick } = props

        function handleClick(event){
            if(localIndex === files.length - 1) setLocalIndex(0)
            else setLocalIndex(previousIndex => ++previousIndex)
            event.stopPropagation()
            onClick()
        }

        return (
          <div
            className={`${className} ${styles.fileViewerCarouselNextArrow}`}
            style={{
                ...style,
                fontSize: '18px',
                lineHeight: '0',
                paddingLeft: '16px',
                paddingTop: '16px'
            }}
            onClick={handleClick}
          >
             <RightOutlined style={{ color: "#fff" }} />
          </div>
        )
    }

    const settings = {
        prevArrow: <PreviousArrow />,
        nextArrow: <NextArrow />
    }

    const typeIcon = {
        image: <FileImageFilled style={{ color: "#e50a0a", fontSize: 18, marginRight: 12 }} />,
        document: <FilePdfFilled style={{ color: "#0a19e5", fontSize: 18, marginRight: 12 }} />,
        video: <VideoCameraFilled style={{ color: "#02b111", fontSize: 18, marginRight: 12 }} />,
        invalid: <FileExclamationFilled style={{ color: "#f09001", fontSize: 18, marginRight: 12 }} />
    }

    const currentFile = files[localIndex]
    const _type = currentFile.type;
    const title = currentFile.name


    useEffect(() => {
        if(files.some(item => item.type === 'video')){
            if(currentFile.type !== "video")
                videoRef.current.pause()
            else
                videoRef.current.play()
        }
    }, [localIndex])

    return(
        <div key={index} className={styles.fileViewerContainer}>
            <div className={styles.fileViewerToolbar}>
                <div className={styles.fileViewerToolbarLeftSide}>
                    {/* Arrow */}
                    <ArrowLeftOutlined
                        onClick={onClose} 
                        style={{ 
                            color: "#fff", 
                            fontSize: 20, 
                            marginRight: 16 ,
                            opacity: .65
                        }} 
                    />
                    {/* Icon */}
                    { typeIcon[_type] }
                    {/* File name */}
                    <div 
                        style={{ 
                            color: "#fff", 
                            fontWeight: 600,
                            fontSize: 16, 
                            marginBottom: -2 
                        }}
                    >
                        { title }
                    </div>
                </div>

                {/* File index */}
                <div 
                    style={{ 
                        color: "#fff", 
                        fontSize: 16, 
                        marginBottom: -2 ,
                        fontWeight: 600
                    }}
                >
                    { `${localIndex + 1} / ${files.length}` }
                </div>
            </div>
            <div className={styles.fileViewerCarouselWrapper} onClick={onClose}>
                <div className={styles.fileViewerCarousel} onClick={event => event.stopPropagation()}>
                    <Carousel
                        ref={carouselRef}
                        dots={true}
                        dotPosition='bottom' 
                        afterChange={onChange} 
                        effect="fade"
                        arrows={true}
                        {...settings}
                    >
                        {
                            files.map((item, idx) => (
                                item.type === "image" ? 
                                    <TransformWrapper key={idx}>
                                        <TransformComponent>
                                            <div className={styles.fileViewerCarouselItemWrapper}>
                                                <img src={item.file} className={styles.fileViewerCarouselImageWrapper} />
                                            </div> 
                                        </TransformComponent>
                                    </TransformWrapper>
                                :
                                item.type === "video" ?
                                    <div key={idx} className={styles.fileViewerCarouselItemWrapper}>
                                        <video
                                            ref={videoRef}
                                            key={idx}
                                            controls={true}                                      
                                            width='100%' 
                                            height="100%" 
                                            preload="metadata"
                                            style={{ objectFit: 'contain' }}
                                        >
                                            <source 
                                                src={item.file}
                                                type="video/mp4" 
                                            />
                                        </video>
                                    </div>
                                :
                                item.type === "document" ?
                                    <div key={idx} className={styles.fileViewerCarouselItemWrapper}>
                                        <embed 
                                            src={item.file} 
                                            className={styles.document} 
                                            type="application/pdf"
                                            width="100%" 
                                            height="100%"
                                        />
                                    </div> 
                                :
                                    <div key={idx} className={styles.fileViewerCarouselItemFallback}>
                                        {"Greska na ucitavanje"}
                                    </div> 
                                )
                            )
                        }
                    </Carousel>
                </div>
            </div>
        </div>
    )
}

export default FileViewerComponent;