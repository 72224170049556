import React, { useState } from "react";

import { Row, Col, List, Alert } from "antd";

import Form from "./form";
import CategoryList from "./CategoryList";

import { useSelector } from "react-redux";
import { newNestedSorting } from "../../../../helpers/functions";

const Category = ({ categories, set_addingCategoryVisible }) => {
  const { removePartCategory } = useSelector((state) => state.partsReducer);

  const [error, set_error] = useState();
  const setError = () => {
    set_error(true);

    setTimeout(() => {
      set_error(false);
    }, 2100);
  };

  const [update, set_update] = useState(null);

  return (
    <>
      <Row style={{ height: "74vh", overflow: "auto" }} >
        <Col span={9}>
          <Form
            update={update}
            set_update={set_update}
            set_addingCategoryVisible={set_addingCategoryVisible}
            categories={categories}
          />
        </Col>
        <Col span={15}>
          <List
            style={{ marginLeft: "32px", paddingTop: "0px"}}
            header={
              <>
                <div className={"category-list"}>{"Kategorije"}</div>
                <div>{error && alert}</div>
              </>
            }
            itemLayout="horizontal"
            dataSource={newNestedSorting(categories)}
            renderItem={(item, index) => (
              <CategoryList
                deep={0}
                setError={setError}
                set_update={set_update}
                item={item}
              />
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default Category;

const alert = (
  <Alert
    style={{ marginBottom: "10px", zIndex: "1" }}
    message="Dogodila se greša"
    description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
    type="error"
  />
);
