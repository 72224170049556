import React, { Component } from "react";

class Order extends Component {
  state = {};
  render() {
    return <div>Radni nalog prikaz citavog pojedinosti i to</div>;
  }
}

export default Order;
