import React, { useState } from "react";
import css from "../../maintenance.module.scss";
import { Empty, Modal, Row, Col, Button, Typography, Checkbox, List } from 'antd';

import { getApiEndpoint } from "../../../../axios/endpoints";
import SectionTitle from '../SectionTitle'

const { Title } = Typography;

export default function Tasks({ order: { data: { tasks } } }) {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [index, setIndex] = useState(0)

    const showModal = index => {
        setIndex(index)
        setIsModalVisible(true);
    };
    
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>

            <SectionTitle title="Zadaci" subtext="Pregled zadataka na nalogu" />

            {
                tasks.length ?
                    tasks.map((item, index) =>{

                        const materialsCount = 
                            item.documents.length + 
                            item.images.length + 
                            item.links.length + 
                            item.videos.length

                        console.log(item.documents[0])

                        return(
                            <div key={`${item}.${index}`} className={css.taskContainer}>
                                <h1 className={css.taskTitle}>{ index + 1 }.  { item.title }</h1>
                                <h1 className={css.taskDescription}>{ item.description || 'Ovaj zadatak nema opis.' }</h1>

                                <div onClick={() => showModal(index)}>
                                    <h1 className={materialsCount ? css.taskMaterialsCount : css.taskMaterialsCountEmpty}>
                                        Ukupno <span style={{ fontWeight: 600 }}>{ materialsCount }</span> materijala
                                    </h1>
                                </div>
                            </div>
                        )   
                    }) :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }

            <Modal 
                title="Pregled materijala" 
                onCancel={handleCancel} 
                visible={isModalVisible}
                width={"80vw"}
                cancelText="Zatvori"
                okButtonProps={{ style:{ display: "none" }}}>
                    <Row>
                        <Col sm={24} xs={24} md={6} style={{ padding: "0 6px" }}>
                            <Title level={4}>Videozapisi</Title>
                            <>
                                <List
                                    dataSource={tasks[index]?.videos}
                                    renderItem={(item) => (
                                        <List.Item>
                                        <List.Item.Meta
                                            description={
                                            <div style={{ textAlign: "center" }}>
                                                <video width="300" height="230" controls>
                                                <source
                                                    src={getApiEndpoint() + item.path}
                                                    type="video/mp4"
                                                ></source>
                                                <source
                                                    src={getApiEndpoint() + item.path}
                                                    type="video/ogg"
                                                ></source>
                                                <source
                                                    src={getApiEndpoint() + item.path}
                                                    type="video/webm"
                                                ></source>
                                                </video>
                                                <div style={{ padding: "6px", textAlign: "center" }}>
                                                {" "}
                                                </div>
                                            </div>
                                            }
                                        />{" "}
                                        </List.Item>
                                    )}
                                />{" "}
                            </>
                        </Col>

                        <Col sm={24} xs={24} md={6} style={{ padding: "0 6px" }}>
                            <Title level={4}>Slike</Title>
                            <>
                                <List
                                dataSource={tasks[index]?.images}
                                renderItem={(item) => (
                                    <List.Item>
                                    {" "}
                                    <List.Item.Meta
                                        description={
                                        <>
                                            <div style={{ textAlign: "center" }}>
                                            <a
                                                style={{
                                                overflow: "hidden",
                                                display: "inline-block",
                                                }}
                                                href={getApiEndpoint() + item.path}
                                                target="_blank"
                                                style={{
                                                backgroundImage: `url(${
                                                    getApiEndpoint() + item.path
                                                })`,
                                                display: "inline-block",

                                                width: "200px",
                                                height: "200px",
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                                }}
                                            />
                                            </div>
                                            <div style={{ padding: "6px", textAlign: "center" }}>
                                            {" "}
                                            </div>
                                        </>
                                        }
                                    />{" "}
                                    </List.Item>
                                )}
                                />{" "}
                            </>
                        </Col>

                        <Col sm={24} xs={24} md={6} style={{ padding: "0 6px" }}>
                            <Title level={4}>Dokumenti</Title>
                            <>
                                <List
                                    style={{ width: "100%" }}
                                    dataSource={tasks[index]?.documents}
                                    renderItem={(item) => (
                                        <List.Item
                                        style={{ display: "flex" }}
                                        >
                                        <List.Item.Meta
                                            description={
                                            <a
                                                style={{ overflow: "hidden" }}
                                                href={getApiEndpoint() + item.path}
                                                target="_blank"
                                            >
                                                {item.name}
                                            </a>
                                            }
                                        />{" "}
                                        </List.Item>
                                    )}
                                />{" "}
                            </>
                        </Col>

                        <Col sm={24} xs={24} md={6} style={{ padding: "0 6px" }}>
                            <Title level={4}>Poveznice</Title>
                            <>
                                <div style={{ overflow: "hidden" }}></div>
                                <List
                                dataSource={tasks[index]?.links}
                                renderItem={(item) => (
                                    <List.Item
                                    >
                                    <List.Item.Meta
                                        description={
                                        <a target="_blank" href={item.link}>
                                            {item.name}
                                        </a>
                                        }
                                    />
                                    </List.Item>
                                )}
                                />{" "}
                            </>
                        </Col>

                    </Row>
            </Modal>
        </>
    );
}
