import React, { Component } from "react";

import { connect } from "react-redux";

import { Route, Switch, NavLink } from "react-router-dom";

import * as actions from "../../redux/modules/Machines/actions";

import Spinner from "../../components/LoadingScreen";
import SizeType from "../../helpers/deviceTypeSize";

import MachineTabs from "./components/MachineTabs";
import MachineHeader from "./components/MachineHeader";
import MachinesForm from "../Machines/components/MachinesForm";

import Specifications from "./components/Specifications";
import Tools from "./components/Tools";
import Reports from "./components/Reports";
import Maintenance from "./components/Maintenance";
import Parts from "./components/Parts";
import Instructions from "./components/Instructions";

import { Alert, PageHeader, Button, Typography, Drawer, Icon } from "antd";
import Warehouse from "./components/Warehouse";

class Machine extends Component {
  state = {
    maintenanceFormVisible: false,
    activeKey: "",
    updateFormVisible: false,
  };

  componentDidMount() {
    this.props.onGetMachine(this.props.match.params.id);
    this.setActiveKey();
  }

  showUpdateForm = () => {
    this.setState({ updateFormVisible: true });
  };

  hideUpdateForm = () => {
    this.setState({ updateFormVisible: false });
  };

  setActiveKey = () => {
    const activeKey =
      this.props.location.pathname.split("/").slice(-1)[0] || "specifications";
    this.setState({ activeKey });
  };

  componentWillUnmount() {}

  openCloseMaintenaceForm(bool) {
    if (!bool) {
      this.setState({ maintenanceFormVisible: bool });
      return;
    }
    this.setState({ maintenanceFormVisible: true });
  }

  render() {
    const { machine, match, prepareRegistration, machines } = this.props;

    const showButton =
      this.props.location.pathname ===
      `/machines/${match.params.id}/maintenance`;

    if (
      machine.status === "loading" ||
      prepareRegistration.status === "loading" ||
      machines.status === "loading"
    ) {
      return <Spinner />;
    }

    if (Object.keys(this.props.machine.data).length === 0) {
      return (
        <Typography.Title level={6}>Imovina ne postoji...</Typography.Title>
      );
    }


    return (
      <>
        <PageHeader
          title={
            <MachineHeader
              machine={machine}
              showUpdateForm={this.showUpdateForm}
            />
          }
        />
        {machine.status === "error" ? (
          <Alert
            style={{ marginBottom: "10px", zIndex: "1" }}
            message="Dogodila se greša"
            description="Dogodila se greška prilikom dohvata podataka molimo vas probajte ponovo!"
            type="error"
          />
        ) : null}

        <Drawer
          width={SizeType() === "xs" || SizeType() === "sm" ? "90%" : 640}
          visible={this.state.updateFormVisible}
          onClose={this.hideUpdateForm}
          destroyOnClose={true}
          title={
            <span>
              <Icon type="snippets" theme="twoTone" /> Uredi imovinu
            </span>
          }
        >
          <MachinesForm
            close={this.hideUpdateForm}
            machines={machines.data}
            prepareRegistration={prepareRegistration.data}
            machine={machine}
            saveMachine={this.state.onUpdateMachine}
            type={"company"}
            update={true}
            _updateMachine={this.props._updateMachine}
          />
        </Drawer>

        <MachineTabs
          setActiveKey={this.setActiveKey}
          activeKey={this.state.activeKey}
        />

        {/* Machine Tabs */}
        <div>
          <Switch>
            <Route
              path={`${match.path}/specifications`}
              render={() => (
                <Specifications
                  hideUpdateForm={this.hideUpdateForm}
                  showUpdateForm={this.showUpdateForm}
                  updateFormVisible={this.state.updateFormVisible}
                  prepareRegistration={prepareRegistration}
                  machines={machines}
                  machine={machine}
                  _updateMachine={this.props.onUpdateMachine}
                />
              )}
            />
            <Route path={`${match.path}/tools`} render={() => <Tools />} />

            <Route
              path={`${match.path}/maintenance`}
              render={() => (
                <Maintenance
                  maintenanceFormVisible={this.state.maintenanceFormVisible}
                  openCloseMaintenaceForm={this.openCloseMaintenaceForm.bind(
                    this
                  )}
                  prepareRegistration={prepareRegistration.data}
                  machines={machines.data}
                  machine={machine.data}
                  button={() => (
                    <Button
                      style={{ marginTop: 2 }}
                      type={this.state.maintenanceFormVisible ? "" : "primary"}
                      onClick={() =>
                        this.openCloseMaintenaceForm(
                          !this.state.maintenanceFormVisible
                        )
                      }
                    >
                      {this.state.maintenanceFormVisible ? "Zatvori" : "Dodaj"}
                    </Button>
                  )}
                />
              )}
            />
            <Route
              path={`${match.path}/instructions`}
              render={() => <Instructions {...machine.data} />}
            />
            <Route path={`${match.path}/reports`} render={() => <Reports />} />
            <Route path={`${match.path}/parts`} render={() => <Parts />} />
            <Route
              path={`${match.path}/warehouse`}
              render={() => <Warehouse asset={machine.data} />}
            />
          </Switch>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    machine: state.machineReducer.machine,
    machines: state.machinesReducer.machines,
    prepareRegistration: state.machinesReducer.prepareRegistration,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetMachine: (machine_id) =>
      dispatch({ type: actions.GET_MACHINE, machine_id }),

    onUpdateMachine: (id, data, callback) =>
      dispatch({ type: actions.UPDATE_MACHINE, id, data, callback }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Machine);
