import React, { Component } from "react";
import moment from "moment";
import "moment/locale/hr";
import css from "../../maintenance.module.scss";

import SectionTitle from "../SectionTitle";
import { Col, Row } from "antd";
import { getApiEndpoint } from "../../../../axios/endpoints";
import NameChecklist from "../NameChecklist";


const TYPES_1 = {
  hour: "sat",
  day: "dan",
  month: "mjesec",
  year: "godina",
};

const TYPES_2 = {
  hour: "h",
  day: "dana",
  month: "mjeseca",
  year: "godine",
};

export default class About extends Component {
  render() {
    const {
      order: { data },
    } = this.props;

    const machines = data.items && 
      data.items.length > 1
        ? data.items.map((item) => `${item.name} ${item.info.model} `)
        : data.items[0].name + " " + data.items[0].info.model;
    const categories =
      data.categories.length > 0
        ? data.categories.map((item) => `${item.name} `)
        : "-";
    const created_at_formatted =
      moment(data.created_at).locale("hr").format("LLL") + "h";
    const planned_start_formatted =
      moment(data.planned_start).locale("hr").format("LLL") + "h";
    const taking_at = data.taking_at
      ? moment(data.taking_at).locale("hr").format("LLL") + "h"
      : "";
    const real_start = data.real_start
      ? moment(data.real_start).locale("hr").format("LLL") + "h"
      : "";
    const real_end = data.real_end
      ? moment(data.real_end).locale("hr").format("LLL") + "h"
      : "";
      const confirmed_at = data.close?.closed_at
      ? moment(data.close.closed_at).locale("hr").format("LLL") + "h"
      : "";
      // const report_to_close_time = data.report_to_close_time ?
      // moment(data.report_to_close_time).locale("hr").format("LLL") + "h" : null;
      const report_to_close_time = data.report_to_close_time ?
      moment.duration(data.report_to_close_time).days() + " dana " + 
      moment.duration(data.report_to_close_time).hours() + "h " + moment.duration(data.report_to_close_time).minutes() + "m"
      : null;
    const repeat =
      data.every == null
        ? "---"
        : data.every +
          " " +
          (parseInt(data.every) === 1
            ? TYPES_1[data.period]
            : TYPES_2[data.period]);
    const estimated_time_formatted =
      data.estimated_time == null && data.estimated_time_minute == null
        ? "---"
        : (data.estimated_time || 0) +
          (data.estimated_time === 1 ? " sat " : " sata ") +
          (data.estimated_time_minute || 0) +
          " minuta";

    const filtered_planned_start =
      data.type.id !== 3 && data.type.id !== 2 && planned_start_formatted;

    const location = data.location?.name;

    const { currentUser } = this.props;
    return (
      <>
      <Row>
        <Col  style={{padding: 12}}  span={12}>
        <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center", borderBottom: "1px solid #ddd",  marginBottom: 12}}>
          <div className={css.aboutFlex}>
            <p>Opis naloga</p>
            {data.description_checklist ?  <NameChecklist order={data} currentUser={currentUser} /> : <h1>{ data.name }</h1> }
            
          </div>
          <div className={css.aboutFlex}>
            <p>Nalog br.</p>
            <h1>{ data.id }</h1>
          </div>
        </div>
        <div style={{ maxWidth: "600px" }}>
          {/* Podimovina */}
          {infoItem("Podimovina", data.tags?.map(x => x.name).join(", ") || "--")}

          {/* Kategorija */}
          {infoItem("Kategorija", categories)}

          {/* Tip */}
          {infoItem("Tip", data.type.name)}

          {/* Lokacija */}
          {infoItem("Lokacija", location)}

         


          {/* Datum stvaranja */}
          {infoItem("Vrijeme stvaranja (Prijava)", created_at_formatted)}

          {/* Stvarni završetak */}
          {/* {infoItem("Stvarno trajanje", report_to_close_time)} */}
          
          {/* Ponavljaj */}
          {/* {infoItem("Ponavljaj", repeat)} */}

          {/* Predviđeno Trajanje */}
          {/* {infoItem("Predviđeno trajanje", estimated_time_formatted)} */}

          {/*Stvarno Trajanje */}

          {/* Dragan zakomentirao jer gore ima vec jedan infoItem koji se zove stvarno trajanje i onda je prezbunjujuce*/}

          {/* Kapacitet imovine*/}
          {data.machine_run &&
            infoItem("Kapacitet imovine", data.work_capacity || "--" + "%")}

          {/* Opis
          {infoItem("Opis", data.description)} */}

          {/* Planirani početak */}
          {/* {infoItem("Planirani početak", filtered_planned_start)} */}

          {/* Vrijeme preuzimanja */}
          {infoItem("Radni nalog proizvodnje", data.production_work_order)}
          {infoItem("Vrijeme dodjele", taking_at)}

          {/* Vrijeme kada ej tehnicar zavrsio */}
          {infoItem("Vrijeme razduživanja", real_end)}

          {/* Stvarni početak */}
          {/* {infoItem("Stvarni početak", real_start)} */}

          {/* Stvarni završetak */}
          {infoItem("Vrijeme završetka (Odjava)", confirmed_at)}
        </div>

        </Col>
        <Col span={12}  style={{padding: 12}} >
        <SectionTitle
          title="Materijali"
        />
        {data.files?.map(x => <div style={{padding: "12px 6px"}} >
        <a href="#" onClick={() => {

                                    window.open(getApiEndpoint() + x.path.substring(1), '_blank', 'fullscreen=yes'); return false;
                                }}>{x.path.split("/").pop()}</a>
        </div>)}
       
        </Col>
      </Row>
       
      </>
    );
  }
}

const working_time_formatted = (time) => {
  // time: "00:00:11.012044"

  time = time.substring(0, 5);
  let hours_text = "sati";
  let [hours, minutes] = time.split(":");

  if (hours[0] === "0") {
    hours = hours.substring(1, 2);

    if (hours[0] === "1") {
      hours_text = "sat";
    }
  }

  if (minutes[0] === "0") {
    minutes = minutes.substring(1, 2);
  }

  return `${hours} ${hours_text} ${minutes} minuta`;
};

const working_time = (x) => {
  let arr = x.split([" "]);
  if (arr.length === 1) {
    console.log(arr);
    let tmp = arr[0].split(".")[0];
    return tmp.substring(0, tmp.length - 3) + "h";
  }

  let tmp = arr[0] == 1 ? arr[0] + " dan " : arr[0] + " dana ";

  tmp +=
    " " +
    arr[1].split(".")[0].substring(0, arr[1].split(".")[0].length - 3) +
    "h";

  return tmp;
};

const infoItem = (label, value) => {
  return (
    <div className={css.infoItem}>
      <h3 className={css.infoItemLabel}>{label}: </h3>
      <h3 className={css.infoItemValue}>{value || "---"}</h3>
    </div>
  );
};
