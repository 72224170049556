import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";

const { TabPane } = Tabs;

function PartsTabs({}) {
  // Variables

  const history = useHistory();
  const location = useLocation();

  const [activeKey, set_activeKey] = useState(location.pathname);

  const TABS = [
    { name: "Skladište", pathname: "/parts/parts" },
    { name: "Kritična zaliha", pathname: "/parts/parts/criticalqty" },
  ];

  useEffect(() => {
    set_activeKey(location.pathname);
  }, [location.pathname]);

  // Methods
  const handleClick = (key) => {
    history.push(key);
    set_activeKey(key);
  };

  return (
    <div style={{ marginBottom: 32, marginTop: 20 }}>
      <Tabs defaultActiveKey={activeKey} onChange={handleClick}>
        {TABS.map((item) => (
          <TabPane key={item.pathname} tab={<span>{item.name}</span>} />
        ))}
      </Tabs>
    </div>
  );
}

export default PartsTabs;
