import React, { useState } from "react";

import { Button, message, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { REMOVE_PART_SPENT } from "../../../../../redux/modules/Parts/actions";
import { GET_ORDER_SUCCESS } from "../../../../../redux/modules/Maintenances/actions";

const Card = ({ item, order_id }) => {
  const { removePartSpent } = useSelector((state) => state.partsReducer);

  const {
    order
  } = useSelector((state) => state.maintenanceReducer);

  console.log(order.data)

  const dispatch = useDispatch();

  const onSpend = () => {
    dispatch({
      type: REMOVE_PART_SPENT,
      id: item.id,
      data: { id: item.id },
      work_order: order_id,
      errorCallback: () => message.error("Dogodila se greška tokom brisanja"),
      successCallback: () => {
        const arr = [...order.data.parts];
        const index = arr.findIndex((x) => x.id === item.id);

        arr.splice(index, 1);
        dispatch({ type: GET_ORDER_SUCCESS, data: { ...order.data, parts: arr } });
      },
    });
  };


  return (
    <div style={{ marginTop: 16, marginBottom: 140, maxWidth: "400px" }}>
      <p style={{ fontSize: 20, fontWeight: 600, marginBottom: 20 }}>
        {item.part?.name || item?.name}
      </p>

      {Information("Kategorija", item.part?.category?.name || "")}
      {Information("Šifra", item.part?.code || "")}
      {Information("Dodana količina", item.qty)}

      <Button
        loading={removePartSpent.status === "loading"}
        style={{ float: "right", marginTop: 16 }}
        onClick={onSpend}
        type="primary"
      >
        Skini
      </Button>
    </div>
  );
};

export default Card;

const Information = (label = "---", value = "---") => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <p style={{ fontWeight: 300 }}>{label}: </p>
      <p style={{ fontWeight: 600 }}>{value}</p>
    </div>
  );
};
