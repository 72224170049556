import React from 'react'

// Styles
import styles from '../../index.module.css'

// type LayoutType = "grid" | "list"

// interface IProps {
//     src: string,
//     size: number
//     width: number,
//     layout?: LayoutType
// }

const DocumentComponent = ({ src, size, width, layout = "grid" }) => {
    if(layout === 'grid'){
        return(
            <div 
                style={{ height: size }} 
                className={styles.cardContainer}
            >
                <embed 
                    src={`${src}#toolbar=0`} 
                    className={styles.document} 
                    type="application/pdf"
                    width={width} 
                    height={size} 
                />
    
                <div className={styles.documentOverlay} />
                <div className={styles.documentLine} />
            </div>
        )
    }

    return(
        <div 
            style={{ height: size, width: size }} 
            className={styles.cardContainer}
        >
            <embed 
                src={`${src}#toolbar=0`} 
                className={styles.document} 
                type="application/pdf"
                width={width} 
                height={size} 
                style={{ borderRadius: 4 }}
            />

            <div className={styles.documentOverlay} />
            <div className={styles.documentLine} />
        </div>
    )
}

export default DocumentComponent;