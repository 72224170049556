export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const GET_SUPPLIER = "GET_SUPPLIER";
export const GET_SUPPLIER_SUCCESS = "GET_SUPPLIER_SUCCESS";
export const GET_SUPPLIER_FAIL = "GET_SUPPLIER_FAIL";

export const GET_PREPARE_REGISTRATION = "GET_PREPARE_REGISTRATION";
export const GET_PREPARE_REGISTRATION_SUCCESS =
  "GET_PREPARE_REGISTRATION_SUCCESS";
export const GET_PREPARE_REGISTRATION_FAIL = "GET_PREPARE_REGISTRATION_FAIL";

export const SAVE_CATEGORY = "SAVE_CATEGORY";
export const SAVE_CATEGORY_SUCCESS = "SAVE_CATEGORY_SUCCESS";
export const SAVE_CATEGORY_ERROR = "SAVE_CATEGORY_ERROR";

export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_ERROR = "UPDATE_CATEGORY_ERROR";

export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_ERROR = "DELETE_CATEGORY_ERROR";

export const ACCOUNTS = "ACCOUNTS";
export const ACCOUNTS_SUCCESS = "ACCOUNTS_SUCCESS";
export const ACCOUNTS_FAIL = "ACCOUNTS_FAIL";

export const ACCOUNT = "ACCOUNT";
export const ACCOUNT_SUCCESS = "ACCOUNT_SUCCESS";
export const ACCOUNT_FAIL = "ACCOUNT_FAIL";

export const SAVE_COMPANY_CATEGORY = "SAVE_COMPANY_CATEGORY";
export const SAVE_COMPANY_CATEGORY_SUCCESS = "SAVE_COMPANY_CATEGORY_SUCCESS";
export const SAVE_COMPANY_CATEGORY_FAIL = "SAVE_COMPANY_CATEGORY_FAIL";

export const SAVE_ACCOUNT_LOCATIONS = "SAVE_ACCOUNT_LOCATIONS";
export const SAVE_ACCOUNT_LOCATIONS_SUCCESS = "SAVE_ACCOUNT_LOCATIONS_SUCCESS";
export const SAVE_ACCOUNT_LOCATIONS_FAIL = "SAVE_ACCOUNT_LOCATIONS_FAIL";

export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS";
export const ADD_DOCUMENT_FAIL = "ADD_DOCUMENT_FAIL";

export const REMOVE_DOCUMENT = "REMOVE_DOCUMENT";
export const REMOVE_DOCUMENT_SUCCESS = "REMOVE_DOCUMENT_SUCCESS";
export const REMOVE_DOCUMENT_FAIL = "REMOVE_DOCUMENT_FAIL";

export const UPDATE_SUPPLIER_CATEGORY = "UPDATE_SUPPLIER_CATEGORY";
export const UPDATE_SUPPLIER_CATEGORY_SUCCESS =
  "UPDATE_SUPPLIER_CATEGORY_SUCCESS";
export const UPDATE_SUPPLIER_CATEGORY_FAIL = "UPDATE_SUPPLIER_CATEGORY_FAIL";
