import React from "react";
import { Tabs } from "antd";
import { ProfileOutlined, HistoryOutlined } from "@ant-design/icons";

import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";

const { TabPane } = Tabs;

function PartsTabs(props){

  // Variables
    
  //? Saving tab after page refresh
  let tab = props.location.pathname.slice(props.match.url.length + 1)

  const TABS = [
    { name: "Osnovno", path: 'about', icon: <ProfileOutlined /> },
    { name: "Povijest unosa", path: 'entry_history', icon: <HistoryOutlined /> },
    { name: "Povijest utroška", path: 'consumption_history', icon: <HistoryOutlined /> },
  ]

  // Methods
  const handleClick = (key) => {
    props.history.replace(key);
    props.setActiveKey();
  };

  return (
    <div style={{ marginBottom: 32, marginTop: 20 }}>
      <Tabs defaultActiveKey={tab} onChange={handleClick}>
        {
          TABS.map(item => 
            <TabPane
              key={item.path}
              tab={
                <>
                  { item.icon }
                  <NavLink to={`${props.match.url}/${item.path}`}>
                    { item.name }
                  </NavLink>
                </>
              }
            />
          )
        }
      </Tabs>
    </div>
  );
};

export default withRouter(PartsTabs);
