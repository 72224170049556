import React, { Component } from "react";

import { Form, Button, Col, Row, Input, Alert, message } from "antd";

import SizeType from "../../../../helpers/deviceTypeSize";
import {
  SAVE_COMPANY_CATEGORY,
  UPDATE_SUPPLIER_CATEGORY,
} from "../../../../redux/modules/Accounting/actions";
import { connect } from "react-redux";

const { TextArea } = Input;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

class CategoryForm extends Component {
  state = {
    sameCategoryErrorVisbile: false,
    alertSuccessVisible: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.props.update) {
          this.props.onUpdate(
            this.props.update.id,
            values,
            () => {
              message.error("Upss dogodila se greška pokušaj ponovo", 3);
            },
            () => {
              this.props.setUpdate(null);
              this.props.form.resetFields();
            }
          );
          return;
        }
        this.props.onSaveCategory(values, this.showSuccess.bind(this));
      }
    });
  };

  isExsitsCategory = () => {
    const check = this.props.categories.find(
      (item) => item === this.props.form.getFieldsValue("name")
    );
    console.log(check);
    if (check !== undefined && check !== -1) {
      this.setState({ sameCategoryErrorVisbile: true });
    } else {
      this.setState({ sameCategoryErrorVisbile: false });
    }
  };

  showSuccess = () => {
    this.setState({ alertSuccessVisible: true });
    // Clear text field
    this.props.form.setFieldsValue({
      ["name"]: "",
    });
    setTimeout(() => {
      this.setState({ alertSuccessVisible: false });
    }, 3000);
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;

    const alert = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1" }}
        message="Dogodila se greša"
        description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
        type="error"
      />
    );

    const alertSuccess = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1" }}
        message="Uspješno"
        description="Uspješno ste dodali kategoriju!"
        type="success"
      />
    );

    const alertSameCategory = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1" }}
        message="Dogodila se greša"
        description="Kategorija već postoji!"
        type="error"
      />
    );

    const formItemLayout = {
      labelCol: {
        span: 6,
      },
      wrapperCol:
        SizeType() === "xs" || SizeType() === "sm"
          ? {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 16,
                offset: 0,
              },
            }
          : {
              span: 18,
              offset: 0,
            },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };
    const layoutType =
      SizeType() === "xs" || SizeType() === "sm" ? "vertical" : "horizontal";

    const { category, updateSupplierCategory } = this.props;

    console.log(this.props.update);

    return (
      <React.Fragment>
        {this.state.alertSuccessVisible ? alertSuccess : null}
        {this.state.sameCategoryErrorVisbile ? alertSameCategory : null}
        {category.status === "error" ? alert : null}
        <Form
          {...formItemLayout}
          layout={layoutType}
          hideRequiredMark
          onSubmit={this.handleSubmit}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item className={"modal-header"} label="Funkcija:">
                {getFieldDecorator("name", {
                  initialValue: this.props.update?.name || null,
                  rules: [
                    { required: true, message: "Unesite tekst!" },
                    { min: 3, message: "Minimalno znakova 3!" },
                  ],
                })(<TextArea />)}
              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <Button
                  style={{ float: "right" }}
                  loading={
                    category.status === "loading" ||
                    updateSupplierCategory.status === "loading"
                  }
                  type="primary"
                  htmlType="submit"
                  disabled={hasErrors(getFieldsError())}
                >
                  Spremi
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    category: state.accoutingReducer.company_category,
    updateSupplierCategory: state.accoutingReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdate: (id, data, errorCallback, successCallback) => {
      dispatch({
        type: UPDATE_SUPPLIER_CATEGORY,
        id,
        data,
        errorCallback,
        successCallback,
      });
    },
    onSaveCategory: (data, callback) =>
      dispatch({ type: SAVE_COMPANY_CATEGORY, data, callback }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(CategoryForm));
