import React, { useState } from "react";
import { Input, Button, Icon, Upload, message, Row, Col } from "antd";
import { useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ADD_INSURANCE_MATERIAL } from "../../../redux/modules/Maintenances/actions";

const { TextArea } = Input;

const MaterialForm = ({ set_materialForm }) => {
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const { addInsuranceMaterial } = useSelector(
    (store) => store.maintenancesReducer
  );

  const [state, set_state] = useState({
    materialText: "",
    files: [],
  });

  const beforeUploadPhoto = (file, files) => {
    set_state({ ...state, files: [...state.files, ...files] });
    return false;
  };

  const removeFile = (file) => {
    let list = state.files.concat();
    const index = list.indexOf(file);
    list.splice(index, 1);
    set_state({ ...state, files: list });
  };

  const saveData = () => {
    let formData = new FormData();
    formData.append(
      "data",
      JSON.stringify({
        text: state.materialText,
        insurance_order_id: match.params.id,
      })
    );

    state.files.forEach((item) => {
      formData.append("files", item);
    });

    dispatch({
      type: ADD_INSURANCE_MATERIAL,
      data: formData,
      successCallback: () => {
        set_materialForm(false);
      },
      errorCallback: () => {
        message.error(
          "Upsss dogodila se greška prilikom spremanja na server...",
          3
        );
      },
    });
  };

  return (
    <div>
      <div style={{ marginBottom: 12 }}>
        <span>Opis</span>
        <TextArea
          value={state.materialText}
          onChange={(e) =>
            set_state({ ...state, materialText: e.target.value })
          }
          rows={5}
        />
      </div>

      <div style={{ marginBottom: 12 }}>
        <Row>
          <Col span={12}>
            <Upload
              beforeUpload={beforeUploadPhoto}
              onRemove={removeFile}
              multiple={true}
              showUploadList={true}
              fileList={state.files}
              accept=".mp4,.webm,.ogg,.png,.jpg,.jpeg,.pdf"
            >
              <Button>
                <Icon type="upload" /> Učitaj
              </Button>
            </Upload>
          </Col>
          <Col span={12}>
            {" "}
            <Button
            style={{float: "right"}}
              onClick={saveData}
              type="primary"
              loading={addInsuranceMaterial.status === "loading"}
              disabled={!state.materialText && !state.files.length}
            >
              Spremi
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MaterialForm;
