import { PlusCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Modal, Input, Button, Upload, message } from "antd";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getApiEndpoint } from "../../../../axios/endpoints";

import _Reports from "../../../Maintenance/components/Reports";

let uploadFIles = [];

const { TextArea } = Input;

const Reports = ({ order, orderToken }) => {

  const history = useHistory();

  const [report, set_report] = useState(null);
  const [files, set_files] = useState([]);

  const [saveReport, set_saveReport] = useState({ status: "" });

  const [text, set_text] = useState("");

  const omSaveReport = () => {
    set_saveReport({ status: "loading" });
    const formData = new FormData();

    formData.append("data", JSON.stringify({ text }));
    console.log(uploadFIles);
    uploadFIles.forEach((file) => {
      console.log(file);
      formData.append("files", file);
    });

    Axios.post(
      `${getApiEndpoint()}maintenances/open_work_orders/${orderToken}/add_report/`,
      formData
    )
      .then((res) => {
        set_saveReport({ status: "" });

        history.push(`/openorder/${orderToken}`);
      })
      .catch((err) => {
        set_saveReport({ status: "" });
        message.error("Dogodila se greška!", 3);
      });
  };

  const beforeUpload = (file) => {
    // console.log(files, file);
    // let _files = [...files];

    uploadFIles.push(file);

    set_files(new Date().getTime()); // da se rerendera pošto su filovi za upload u globalnoj variabli antd bug

    return false;
  };

  useEffect(() => {
    uploadFIles = [];
  }, []);

  const removeFile = (file) => {
    const index = uploadFIles.indexOf(file);
    uploadFIles.splice(index, 1);
    set_files(new Date().getTime()); // da se rerendera pošto su filovi za upload u globalnoj variabli antd bug
  };


  if (!order.reports) {
    order.reports = [];
  }

  return (
    <>
      <div
        onClick={() => {
          set_report(true);
        }}
        className={"hover"}
        style={{
          position: "fixed",
          bottom: 45,
          right: "9%",
          borderRadius: "50%",
          width: 47,
          height: 47,
          zIndex: 1,
          backgroundColor: "rgb(70, 117, 255)",
        }}
      >
        <PlusCircleOutlined
          color={"white"}
          style={{ fontSize: 30, color: "#fff", marginTop: 8, marginLeft: 8 }}
        />
      </div>
      <_Reports
        title={"Izvješća"}
        subtitle={"Pregled izvješća na nalogu"}
        reports={order?.data?.reports || []}
        openOrder={true}
      />
      <Modal
        destroyOnClose={true}
        visible={!!report}
        onCancel={() => set_report(null)}
        footer={null}
      >
        <div style={{ padding: 12, paddingBottom: 40 }}>
          <TextArea
            rows={6}
            onChange={(e) => {
              set_text(e.target.value);
            }}
          />

          <div style={{ minHeight: "21vh" }}>
            <Upload
              multiple={true}
              accept={".pdf,.jpg,.png,.jepg,.mp4"}
              showUploadList={true}
              beforeUpload={beforeUpload}
              onRemove={removeFile}
              fileList={uploadFIles}
            >
              <Button style={{ marginTop: 12 }}>
                <UploadOutlined />
                Učitaj
              </Button>
            </Upload>
          </div>
          <Button
            loading={saveReport.status === "loading"}
            style={{ marginTop: 12, float: "right" }}
            type="primary"
            disabled={!report}
            onClick={() => {
              omSaveReport();
            }}
          >
            Spremi
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Reports;
