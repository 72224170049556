import React, { Component } from "react";

import * as actions from "../../../../redux/modules/Maintenances/actions";

import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Drawer } from "antd";

import NewOrder from "./NewOrder";
import MaintenanceTable from "../../../../components/Table/maintenace";

class Maintenance extends Component {
  state = {
    functionsAddVisible: false,
    addingFunctionsType: "",
    addingTypesVisible: false,
  };

  functionsAdd = (bool, type) => {
    this.setState({ addingFunctionsType: type });
    this.setState({ functionsAddVisible: bool });
  };


  render() {
    const {
      match,
      maintenanceFormVisible,
    } = this.props;

    return (
      <div>
        <Drawer
          visible={maintenanceFormVisible}
          width={700}
          onClose={() => {
            this.props.openCloseMaintenaceForm(false);
          }}
        >
          <NewOrder
            prepareRegistration={this.props.prepareRegistration}
            machines={this.props.machines}
            asset={this.props.machine}
            orders={this.props.orders}
            update={false}
            order={{ data: this.props.machine }}
            maintenance_for={"machine"}
            openCloseMaintenaceForm={this.props.openCloseMaintenaceForm}
            assetSelected={true}
            planned_start={null}
          />
        </Drawer>
        <MaintenanceTable machine_id={match.params.id} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orders: state.maintenancesReducer.orders,
  };
};
 

export default withRouter(
  connect(mapStateToProps, null)(Maintenance)
);
