import React, { Component } from "react";

import {
  Form,
  Button,
  Col,
  Row,
  Input,
  Alert,
  Select,
  List,
  Checkbox,
  TreeSelect,
  Switch,
  InputNumber,
} from "antd";

import SizeType from "../../../helpers/deviceTypeSize";
import { connect } from "react-redux";

import {
  EyeOutlined,
  EyeInvisibleOutlined,
  FastForwardFilled,
} from "@ant-design/icons";
import { getMachineModel, pipeArrOfTypes } from "../../../helpers/machines";
import { erp_actions } from "../../../redux/modules/ERP";
import nestedSorting from "../../../helpers/NestedSorting";
import Popover from "./Popover";
import { UPDATE_MACHINE } from "../../../redux/modules/Machines/actions";
import childsOfParent from "../../../helpers/cildsOfParent";
import { getItemsWithouChilds } from "../../../helpers/getitemsWithouChilds";

const { TextArea } = Input;
const { Option } = Select;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

const { SHOW_PARENT } = TreeSelect;

class LocationForm extends Component {
  state = {
    sameMachineName: false,
    alertSuccessVisible: false,
    relevant_links: [],
    relevant_links_visible: true,
    insurers_visible: true,
    fileList: [],
    photoList: [],
    importFromERP: false,
    videoList: [],
    more_data: false,
    autopilot: false,
    is_warehouse: false,
    insurers: [],
  };

  componentDidMount() {
    if (this.props.update) {
      this.setState({
        relevant_links: [
          ...this.props.machine.data.relevant_links,
          ...this.state.relevant_links,
        ],
        more_data: true,
        autopilot: this.props.machine.data.autopilot,
        is_warehouse: this.props.machine.data.is_warehouse,
        insurers: [...this.props.machine.data.insurers, ...this.state.insurers],
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.erp_machines.status === "error" &&
      this.props.erp_machines.status !== prevProps.erp_machines.status
    ) {
      this.setState({ importFromERP: false });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.relevant_links = this.state.relevant_links;
        values.insurers = this.state.insurers;
        values.location = values.location ? values.location : null;
        values.autopilot = this.state.autopilot;
        values.is_warehouse = this.state.is_warehouse;
        values.performance = values.performance ? values.performance : null;
        values.categories = [values.categories]; // odustano od vise imovina
        values.name = this.state.importFromERP
          ? this.props.erp_machines.data.find(
              (item) => item.erp_id === +values.erp_id
            ).name
          : values.name;

        if (this.props.update) {
          this.props._updateMachine(
            this.props.machine.data.id,
            values,
            this.showSuccess.bind(this)
          );
          return;
        }

        let formData = new FormData();
        formData.append("data", JSON.stringify(values));

        this.state.fileList.forEach((item) => {
          formData.append("files", item);
        });
        this.state.photoList.forEach((item) => {
          formData.append("images", item);
        });
        this.state.videoList.forEach((item) => {
          formData.append("videos", item);
        });

        console.log(formData);
        this.props.saveMachine(formData, this.showSuccess.bind(this));
      }
    });
  };

  isExsitsMachineName = (name) => {
    return false;
    const check = this.props.machines.find(
      (item) => item.name.toLowerCase() === name.toLowerCase()
    );
    if (check !== undefined && check !== -1) {
      this.setState({ sameMachineName: true });
      let el = document.getElementsByClassName("ant-drawer-content");
      el[0].scroll({ top: 0 });
      return true;
    } else {
      this.setState({ sameMachineName: false });
      return false;
    }
  };

  showSuccess = () => {
    this.setState({
      alertSuccessVisible: true,
      fileList: [],
      photoList: [],
      relevant_links: [],
      videoList: [],
      autopilot: false,
      is_warehouse: false,
      insurers: [],
    });
    this.props.form.resetFields();
    if (this.props.update) {
      this.props.close();
    }
    let el = document.getElementsByClassName("ant-drawer-content");
    el[0].scroll({ top: 0 });

    setTimeout(() => {
      this.setState({ alertSuccessVisible: false });
    }, 3000);
  };

  addLinkToList = () => {
    const data = {
      name: this.props.form.getFieldValue("link_title"),
      link: this.props.form.getFieldValue("link"),
      id: new Date().getTime(),
    };
    let tmp = [...this.state.relevant_links];
    tmp.push(data);
    setTimeout(() => {
      this.setState({ relevant_links: tmp });
    }, 33);

    this.props.form.resetFields(["link_title", "link"]);
  };

  addPolicyToList = () => {
    let tmp_ins = this.props.prepareRegistration.accounts.find(
      (x) => x.id === this.props.form.getFieldValue("insurer")
    );
    const data = {
      insurer: tmp_ins,

      insurance_policy: this.props.form.getFieldValue("insurance_policy"),
      id: new Date().getTime(),
    };
    let tmp = [...this.state.insurers];
    tmp.push(data);
    this.setState({ insurers: tmp });

    this.props.form.resetFields(["insurer", "insurance_policy"]);
  };

  removePolicy = (id) => {
    let tmp = [...this.state.insurers];
    let index = tmp.find((item) => item.id === id);
    if (index !== undefined && index !== -1) {
      tmp.splice(index, 1);
      this.setState({ insurers: tmp });
    }
  };

  toggleLinks = () => {
    this.setState({
      relevant_links_visible: !this.state.relevant_links_visible,
    });
  };

  toggleInsurers = () => {
    this.setState({
      insurers_visible: !this.state.insurers_visible,
    });
  };

  beforeUpload = (file) => {
    this.setState((state) => ({
      fileList: [...state.fileList, file],
    }));
    return false;
  };

  removeFile = (file) => {
    let fileList = this.state.fileList.concat();
    const index = fileList.indexOf(file);
    fileList.splice(index, 1);
    this.setState({ fileList, fileList });
  };
  beforeUploadPhoto = (file) => {
    this.removeFilePhoto(this.state.photoList[0]);

    this.setState((state) => ({
      photoList: [file],
    }));

    return false;
  };

  removeFilePhoto = (file) => {
    let photoList = this.state.photoList.concat();
    const index = photoList.indexOf(file);
    photoList.splice(index, 1);
    this.setState({ photoList: [...this.state.photoList] });
    setTimeout(() => {
      this.forceUpdate();
    }, 36);
  };

  beforeUploadVideo = (file) => {
    this.setState((state) => ({
      videoList: [...state.videoList, file],
    }));
    return false;
  };

  removeFileVideo = (file) => {
    let videoList = this.state.videoList.concat();
    const index = videoList.indexOf(file);
    videoList.splice(index, 1);
    this.setState({ videoList: [...this.state.videoList] });
    setTimeout(() => {
      this.forceUpdate();
    }, 36);
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { machine } = this.props;

    const alert = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1" }}
        message="Dogodila se greša"
        description="Dogodila se greška prilikom spremanja na server molimo vas probajte ponovo!"
        type="error"
      />
    );

    const alertSuccess = (
      <Alert
        style={{ marginBottom: "10px", zIndex: "1" }}
        message="Uspješno"
        description="Uspješno ste dodali imovinu!"
        type="success"
      />
    );

    const formItemLayout = {
      labelCol: {
        span: 8,
      },
      wrapperCol:
        SizeType() === "xs" || SizeType() === "sm"
          ? {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 24,
                offset: 0,
              },
            }
          : {
              span: 16,
              offset: 0,
            },
    };
    const tailFormItemLayout = {
      wrapperCol:
        SizeType() === "xs" || SizeType() === "sm"
          ? {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 24,
                offset: 0,
              },
            }
          : { span: 24 },
    };

    const layoutType =
      SizeType() === "xs" || SizeType() === "sm" ? "vertical" : "horizontal";

    const {
      prepareRegistration,
      machines,
      erp_machines,
      update,
      updateMachine,
    } = this.props;

    const machineModel = getMachineModel(machine.data, !!update);

    const accounts = prepareRegistration.accounts;

    return (
      <React.Fragment>
        {this.state.alertSuccessVisible ? alertSuccess : null}

        {update ? null : (
          <div
            style={{
              display: "flex",
              padding: "9px 0",
              justifyContent: "flex-end",
            }}
          >
            <div style={{ marginRight: "9px" }}>Koristi ERP podatke:</div>
            <Switch
              checked={this.state.importFromERP}
              loading={erp_machines.status === "loading"}
              title="Uvezi iz ERP-a"
              onChange={() => {
                if (!this.state.importFromERP) {
                  this.props.onGetErpMachines();
                }
                this.setState({ importFromERP: !this.state.importFromERP });
              }}
            />
          </div>
        )}
        {erp_machines.status === "error" ? (
          <Alert
            style={{ marginBottom: "10px", zIndex: "1" }}
            message="Dogodila se greška"
            description="Dogodila se greška prilikom dohvata podataka sa ERP-a molimo vas probajte ponovo!"
            type="error"
          />
        ) : null}
        {this.state.sameMachineName ? (
          <Alert
            style={{ marginBottom: "10px", zIndex: "1" }}
            message="Dogodila se greška"
            description="Imovina već postoji!"
            type="error"
          />
        ) : null}

        <Form
          className={"machineForm"}
          {...formItemLayout}
          layout={layoutType}
          onSubmit={this.handleSubmit}
        >
          <Row type="flex" justify="start" gutter={16}>
            <Col span={24}>
              {this.state.importFromERP ? (
                <Form.Item label="Naziv:">
                  {getFieldDecorator("erp_id", {
                    rules: [
                      {
                        required: true,
                        message: "Odaberi stavku",
                      },
                    ],
                  })(
                    <Select
                      loading={erp_machines.status === "loading"}
                      mode="single"
                      allowClear={true}
                      showSearch
                      placeholder="Odaberi stavku"
                      optionFilterProp="name"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {erp_machines.data.map((item) => (
                        <Option key={item.erp_id} value={item.erp_id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              ) : (
                <Form.Item label="Naziv:">
                  {getFieldDecorator("name", {
                    initialValue: machineModel.name,
                    rules: [
                      { required: true, message: "Unesite naziv!" },
                      { min: 3, message: "Minimalno znakova 3!" },
                    ],
                  })(<Input placeholder="" />)}
                </Form.Item>
              )}

              <Form.Item label="Kategorija imovine">
                {getFieldDecorator("categories", {
                  initialValue: machineModel.categories,
                  rules: [{ required: true, message: "Unesite naziv!" }],
                })(
                  <TreeSelect
                    placeholder="Please select"
                    showSearch
                    multiple={false}
                    allowClear={true}
                    treeData={nestedSorting(
                      prepareRegistration.categories.map((x) => x),
                      "parent"
                    )}
                    optionFilterProp="title"
                    filterTreeNode={(input, option) => {
                      console.log(input, option);
                      return (
                        option.props.name
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item label="Model:">
                {getFieldDecorator("model", {
                  initialValue: machineModel.model,
                  rules: [
                    { required: true, message: "Unesite model imovine!" },
                  ],
                })(<Input placeholder="Model" />)}
              </Form.Item>
              <Form.Item label="Lokacija:">
                {getFieldDecorator("location", {
                  initialValue: machineModel.location,
                  rules: [{ required: false, message: "Postavite lokaciju" }],
                })(
                  <TreeSelect
                    placeholder="Please select"
                    showSearch
                    multiple={false}
                    allowClear={true}
                    treeData={nestedSorting(
                      prepareRegistration.locations,
                      "location_child",
                      []
                    )}
                    optionFilterProp="title"
                    filterTreeNode={(input, option) => {
                      return (
                        option.props.name
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  />
                )}
              </Form.Item>

              <Form.Item label="Nadimovina:">
                {getFieldDecorator("parent", {
                  initialValue: machineModel.parentMachine,
                  rules: [
                    {
                      required: false,
                      message: "Postavite nadimovinu",
                    },
                  ],
                })(
                  <TreeSelect
                    placeholder="Please select"
                    showSearch
                    multiple={false}
                    allowClear={true}
                    treeData={nestedSorting(
                      getItemsWithouChilds(machines, machineModel.id, "parent"),
                      "parent",
                      update && [machine.data.id]
                    )}
                    optionFilterProp="title"
                    filterTreeNode={(input, option) => {
                      return (
                        option.props.name
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item style={{ position: "relative" }} label="Autopilot">
                <Popover
                  text={
                    "Omogućite automatsko odobravanje prijavljenih održavanja"
                  }
                />
                <Checkbox
                  checked={this.state.autopilot}
                  onChange={() => {
                    this.setState({ autopilot: !this.state.autopilot });
                  }}
                />
              </Form.Item>
              <Form.Item style={{ position: "relative" }} label="Skladište">
                <Popover
                  text={"Označi imovinu kao skladište rezervnih dijelova"}
                />
                <Checkbox
                  checked={this.state.is_warehouse}
                  onChange={() => {
                    this.setState({ is_warehouse: !this.state.is_warehouse });
                  }}
                />
              </Form.Item>
            
              {!update && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    onClick={() => {
                      this.setState({ more_data: !this.state.more_data });
                    }}
                    type={"ghost"}
                  >
                    {this.state.more_data ? "Zatvori" : "Još podataka"}
                  </Button>
                </div>
              )}



              {this.state.more_data && (
                <Form.Item label="Dobavljač:">
                  {getFieldDecorator("supplier", {
                    initialValue: machineModel.supplier,
                  })(
                    <Select
                      mode="single"
                      allowClear={true}
                      showSearch
                      optionFilterProp="name"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {accounts
                        .filter((item) => item.category === "SUP")
                        .map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.company.first_name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              )}

              {this.state.more_data && (
                <Form.Item
                  style={{
                    width: "86%",
                    minWidth: "230px",
                    marginLeft: "29px",
                  }}
                  label="Osiguranje:"
                >
                  {getFieldDecorator("insurer", {
                    initialValue: null,
                  })(
                    <Select
                      mode="single"
                      allowClear={true}
                      showSearch
                      placeholder="Odaberi osiguranje"
                      optionFilterProp="name"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {accounts
                        .filter((item) => item.category === "SUP")
                        .map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.company.first_name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              )}

              {this.state.more_data && (
                <Form.Item className="dispalyNoneLabel" label=" ">
                  {getFieldDecorator("insurance_policy")(
                    <Input
                      style={{
                        width: "86%",
                        minWidth: "230px",
                        marginTop: "6px",
                      }}
                      placeholder="Unesi policu"
                    />
                  )}
                  <Button
                    disabled={
                      !this.props.form.getFieldValue("insurer") ||
                      !this.props.form.getFieldValue("insurance_policy")
                    }
                    style={{
                      position: "relative",
                      bottom: "21px",
                      left: "9px",
                    }}
                    onClick={() => this.addPolicyToList()}
                    type="primary"
                    icon="plus"
                  />
                  <div></div>
                  {this.state.insurers.length === 0 ? null : (
                    <List
                      style={{
                        maxHeight: "309px",
                        overflowY: "auto",
                        paddingTop: "12px",
                      }}
                      header={
                        <div>
                          <span>Police</span>{" "}
                          <span style={{ marginLeft: "9px", color: "#1890ff" }}>
                            {this.state.insurers.length}
                          </span>
                          <Button
                            style={{ float: "right", bottom: "6px" }}
                            onClick={this.toggleInsurers}
                          >
                            {this.state.insurers_visible ? (
                              <EyeInvisibleOutlined />
                            ) : (
                              <EyeOutlined />
                            )}
                          </Button>
                        </div>
                      }
                      dataSource={this.state.insurers}
                      renderItem={(item) => (
                        <List.Item
                          style={{
                            display: this.state.insurers_visible ? "" : "none",
                          }}
                          extra={
                            <Button
                              icon="delete"
                              onClick={() => this.removePolicy(item.id)}
                            />
                          }
                        >
                          <List.Item.Meta
                            description={
                              <span style={{ paddingLeft: "9px" }}>
                                {item.insurer.full_name}
                                <span
                                  style={{ display: "inline-block", width: 30 }}
                                ></span>
                                {item.insurance_policy}
                              </span>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  )}
                </Form.Item>
              )}
              {this.state.more_data && (
                <Form.Item label="Rukovoditelj:">
                  {getFieldDecorator("manager", {
                    initialValue: machineModel.manager,
                  })(
                    <Select
                      mode="single"
                      allowClear={true}
                      showSearch
                      placeholder="Odaberi nad rukovoditelja"
                      optionFilterProp="name"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {accounts
                        .filter((item) => item.category !== "SUP")
                        .map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.user.first_name + " " + item.user.last_name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              )}
              {false && (
                <Form.Item label="Priključen:">
                  {getFieldDecorator("connected_to", {
                    initialValue: machineModel.connected_to,
                  })(
                    <TreeSelect
                      disabled={!machineModel.parentMachine && update}
                      showSearch
                      multiple={true}
                      allowClear={true}
                      placeholder="Odaberi nadimovinu"
                      treeData={nestedSorting(
                        [...machines.filter((x) => x.id !== machineModel.id)],
                        "parent"
                      )}
                      optionFilterProp="title"
                      filterTreeNode={(input, option) => {
                        return (
                          option.props.name
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    />
                  )}
                </Form.Item>
              )}

              {this.state.more_data && (
                <Form.Item label="Opis:">
                  {getFieldDecorator("description", {
                    initialValue: machineModel.description,
                    rules: [{ min: 3, message: "Minimalno znakova 3!" }],
                  })(<TextArea />)}
                </Form.Item>
              )}
              {this.state.more_data && (
                <Form.Item
                  style={{ marginBottom: 0, paddingBottom: 0 }}
                  label="Poveznice:"
                >
                  {getFieldDecorator("link_title")(
                    <Input
                      style={{ width: "86%", minWidth: "230px" }}
                      placeholder="Naslov linka"
                    />
                  )}
                </Form.Item>
              )}

              {this.state.more_data && (
                <Form.Item className="dispalyNoneLabel" label=" ">
                  {getFieldDecorator("link")(
                    <Input
                      style={{
                        width: "86%",
                        minWidth: "230px",
                        marginTop: "6px",
                      }}
                      placeholder="link"
                    />
                  )}
                  <Button
                    disabled={
                      !this.props.form.getFieldValue("link") ||
                      !this.props.form.getFieldValue("link_title")
                    }
                    style={{
                      position: "relative",
                      bottom: "21px",
                      left: "9px",
                    }}
                    onClick={() => this.addLinkToList()}
                    type="primary"
                    icon="plus"
                  />
                  <div></div>
                  {this.state.relevant_links.length === 0 ? null : (
                    <List
                      style={{ maxHeight: "309px", overflowY: "auto" }}
                      header={
                        <div>
                          <span>Relevantni linkovi</span>{" "}
                          <span style={{ marginLeft: "9px", color: "#1890ff" }}>
                            {this.state.relevant_links.length}
                          </span>
                          <Button
                            style={{ float: "right", bottom: "6px" }}
                            onClick={this.toggleLinks}
                          >
                            {this.state.relevant_links_visible ? (
                              <EyeInvisibleOutlined />
                            ) : (
                              <EyeOutlined />
                            )}
                          </Button>
                        </div>
                      }
                      dataSource={this.state.relevant_links}
                      renderItem={(item) => (
                        <List.Item
                          style={{
                            display: this.state.relevant_links_visible
                              ? ""
                              : "none",
                          }}
                          extra={
                            <Button
                              icon="delete"
                              onClick={() => this.removeLink(item.id)}
                            />
                          }
                        >
                          <List.Item.Meta
                            description={
                              <a
                                style={{ paddingLeft: "9px" }}
                                href={item.link}
                                target="_blank"
                              >
                                {item.name}
                              </a>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  )}
                </Form.Item>
              )}
              {this.state.more_data && (
                <Form.Item label="Proizvođač:">
                  {getFieldDecorator("manufacturer", {
                    initialValue: machineModel.manufacturer,
                  })(<Input placeholder="Unesite proizvođača" />)}
                </Form.Item>
              )}
              {this.state.more_data && (
                <Form.Item label="Godina proizvodnje:">
                  {getFieldDecorator("year", {
                    initialValue: machineModel.yearOfProduce,
                  })(
                    <Select
                      mode="single"
                      allowClear={true}
                      showSearch
                      placeholder="Odaberi godinu"
                      optionFilterProp="name"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {getYears().map((item) => (
                        <Option key={item.name + "_!"} value={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              )}
              {this.state.more_data && (
                <Form.Item label="Serijski broj:">
                  {getFieldDecorator("serial_number", {
                    initialValue: machineModel.serialNumber,
                  })(<Input placeholder="Serijski broj" />)}
                </Form.Item>
              )}

              {this.state.more_data && (
                <Form.Item label="Boja:">
                  {getFieldDecorator("color", {
                    initialValue: machineModel.color,
                  })(<Input placeholder="Boja" />)}
                </Form.Item>
              )}
                {this.state.more_data && ( <Form.Item label="Performanse:">
                  {getFieldDecorator("performance", {
                    initialValue: machineModel.performance,
                  })(<InputNumber style={{width: 180}} placeholder="Performanse" />)}
                </Form.Item>)}
              {machine.status === "error" || updateMachine.status === "error"
                ? alert
                : null}
              <Form.Item
                style={{ marginTop: "21px", textAlign: "center" }}
                {...tailFormItemLayout}
              >
                <Button
                  loading={
                    machine.status === "loading" ||
                    updateMachine.status === "loading"
                  }
                  type="primary"
                  htmlType="submit"
                  disabled={hasErrors(getFieldsError())}
                >
                  Spremi
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

const getYears = () => {
  let years = [];
  for (let i = 1969; i <= +new Date().getFullYear(); i++) {
    years.push({
      name: i.toString(),
      value: i,
    });
  }
  return years;
};

const mapStateToProps = (state) => {
  return {
    erp_machines: state.erpReducer.machines,
    updateMachine: state.machineReducer.updateMachine,
    user: state.usersReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetErpMachines: () => dispatch({ type: erp_actions.ERP_MACHINES }),
    _updateMachine: (id, data, callback) =>
      dispatch({ type: UPDATE_MACHINE, id, data, callback }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(LocationForm));
