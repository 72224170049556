import React from 'react'
import css from '../../../../Suppliers.module.scss'
import moment from 'moment'
import 'moment/locale/hr' 


export default function About({ supplier }){

    const date_joined_formatted = moment(supplier.data.date_joined).locale('hr').format('LLL') + "h";
    const last_login_formatted = moment(supplier.data.last_login).locale('hr').format('LLL') + "h";



    return(
        <>
            {/* Supplier informations */}

            {/* Adresa */}
            {infoItem("Adresa", supplier.data.address)}

            {/* E-mail */}
            {infoItem("E-mail", supplier.data.email)}

            {/* Telefonski broj */}
            {infoItem("Telefonski broj", supplier.data.phone_number)}

            {/* ID/PDVI */}
            {infoItem("ID/PDV", supplier.data.company?.id_pdv)}

            {/* Datum kreiranja */}
            {infoItem("Datum kreiranja", date_joined_formatted)}

            {/* Datum zadnje prijave */}
            {infoItem("Datum zadnje prijave", last_login_formatted)}

            {/* Uloga */}
            {infoItem("Uloga", supplier.data.category)}

            {/* Napomena */}
            {infoItem("Napomena", supplier.data.remark)}

            {/* Link */}
            <div className={css.infoItem}>
                <h3 className={css.infoItemLabel}>Link: </h3>
                <a href={"http://" + supplier.data.link} target="_blank" className={css.infoItemValue}>{supplier.data.link || "---"}</a>
            </div>
        </>
    )
}

// Info item
const infoItem = (label, value) => {
    return (
      <div className={css.infoItem}>
        <h3 className={css.infoItemLabel}>{label}: </h3>
        <h3 className={css.infoItemValue}>{value || "---"}</h3>
      </div>
    );
};