import React, { useCallback } from "react";

import { Button, message } from "antd";

import {
  canUserTakeOrder,
  technicianOfOrder,
} from "../../../../../helpers/getSpecValueFromOrder";
import { canFinishOrder } from "../../../../../helpers/functions";

const Commands = (props) => {
  const technician = props.item.technicians.find(
    (tec) => tec.account.id === props.currentUser.id
  );

  const buttons = [
    {
      can_additional: true,
      types: ["in_progress", "waiting"],
      component: (key) => {
        if (
          props.item.technicians.filter((tec) => tec.is_working).length === 1
        ) {
          return null;
        }

        if (!technicianOfOrder(props.item, props.currentUser).is_working) {
          return null;
        }

        return (
          <Button
            key={key + "A"}
            style={{ marginRight: "6px", marginTop: "6px" }}
            loading={props.finishOrder.status === props.item.id}
            disabled={
              !!props.finishOrder.status && props.finishOrder.status !== "error"
            }
            onClick={() => {
              props.toggleResaonModal(true, props.item.id, "reject");
            }}
          >
            Odstupam
          </Button>
        );
      },
    },
    {
      can_additional: true,
      types: ["appointed"],
      component: (key) => (
        <Button
          key={key + "B"}
          style={{ marginRight: "6px",marginTop: "6px" }}
          onClick={props.alertMessage}
          disabled={false}
        >
          Preispitaj
        </Button>
      ),
    },
    {
      can_additional: false,
      types: ["in_progress"],
      component: (key) => (
        <Button
          key={key + "C"}
          style={{ marginRight: "6px", marginTop: "6px" }}
          disabled={
            (!!props.finishOrder.status &&
              props.finishOrder.status !== "error") ||
            (!!props.pauseOrder.status && props.pauseOrder.status !== "error")
          }
          onClick={() => {
            props.toggleResaonModal(true, props.item.id, "pause");
          }}
        >
          Pauziraj
        </Button>
      ),
    },
    {
      can_additional: true,
      types: ["waiting", "in_progress"],
      component: (key) => {
        if (props.busy === "busy") {
          if (technician.is_working) {
            return null;
          }

          return (
            <h5 key={key + "V"} style={{ padding: "3px" }}>
              Ne možte nastavit nalog zbog rada na nekom od drugih naloga!
            </h5>
          );
        }

        if (!technician.is_primary && props.item.status !== "in_progress") {
          return null;
        }

        return (
          <Button
            style={{ marginRight: "6px", marginTop: "6px" }}
            key={key + "D"}
            loading={props.startOrder.status === props.item.id}
            disabled={
              (!!props.startOrder.status &&
                props.startOrder.status !== "error") ||
              !canUserTakeOrder(
                props.item,
                props.orders.data,
                props.currentUser,
                "continue"
              ) ||
              props.busy === "busy"
            }
            onClick={() => {
              props.handleStartOrder(
                props.item.id,
                "continue",
                props.item.technicians.find(
                  (tec) => tec.account.id === props.currentUser.id
                ).id,
                props.item.technicians.find(
                  (tec) => tec.account.id === props.currentUser.id
                ).is_primary
              );
            }}
          >
            Nastavi
          </Button>
        );
      },
    },
    {
      can_additional: false,
      types: ["appointed"],
      component: (key) => (
        <Button
          style={{ marginTop: "9px" }}
          key={key + "E"}
          loading={props.startOrder.status === props.item.id}
          disabled={
            (!!props.startOrder.status &&
              props.startOrder.status !== "error") ||
            !canUserTakeOrder(
              props.item,
              props.orders.data,
              props.currentUser
            ) ||
            props.busy === "busy"
          }
          onClick={() => {
            props.handleStartOrder(
              props.item.id,
              "start",
              props.item.technicians.find(
                (tec) => tec.account.id === props.currentUser.id
              ).id,
              props.item.technicians.find(
                (tec) => tec.account.id === props.currentUser.id
              ).is_primary
            );
          }}
        >
          Kreni
        </Button>
      ),
    },
    {
      can_additional: false,
      types: ["in_progress"],
      component: (key) => (
        <Button
          key={key + "F"}
          style={{ marginRight: "6px", marginTop: "6px" }}
          loading={props.finishOrder.status === props.item.id}
          disabled={
            !!props.finishOrder.status && props.finishOrder.status !== "error"
          }
          onClick={() => {
            props.handleFinishOrder(props.item.id);
          }}
        >
          Završi
        </Button>
      ),
    },
    {
      can_additional: true,
      types: ["in_bowl"],
      component: (key) => (
        <Button
          style={{ marginRight: "6px", marginTop: "6px" }}
          key={key + "G"}
          loading={props.setUsersToOrder.status === props.item.id}
          disabled={
            (!!props.setUsersToOrder.status &&
              props.setUsersToOrder.status !== "error") ||
            !canUserTakeOrder(props.item, props.orders.data, props.currentUser) ||
            props.orders.data.filter(
              (item) =>
                (item.status === "appointed" ||
                  item.status === "in_progress") &&
                item.type.id === 2
            ).length > 0
          }
          onClick={() => {
            props.handleSetUsersToOrder(props.item.id);
          }}
        >
          Preuzmi
        </Button>
      ),
    },
  ];

  const render = [];

  buttons.map((item) => {
    let key = 0;
    key++;

    if (item.types.includes(props.item.status)) {
      if (!item.can_additional && props.is_primary) {
        render.push(item.component(key + " " + props.item.id));
      } else if (item.can_additional) {
        render.push(item.component(key + " " + props.item.id));
      }
    }
  });

  return render;
};

export default Commands;
