import React from "react";

import { ExclamationOutlined } from "@ant-design/icons";

import {
  isTechnicianPrimary,
  technicianOfOrder,
} from "../../../../helpers/getSpecValueFromOrder";

const OrderUsers = (props) => (
  <ul>
    {props.technicians
      .filter(
        (item) =>
          item.is_primary &&
          !isTechnicianPrimary(props.order, props.currentUser)
      )
      .map((tech) => (
        <li key={tech.id} style={{ fontWeight: "bold" }}>
          {tech.account.full_name} {tech.is_working ? null : null}{" "}
        </li>
      ))}

    {props.technicians
      .filter(
        (item) =>
          !item.is_primary &&
          item.account.id !== props.currentUser.id &&
          (props.order.status === "appointed" ? true : true)
      )
      .map((tech) => (
        <li key={tech.id}>
          {tech.account.full_name}{" "}
          {tech.account.status === "busy" ? <ExclamationOutlined /> : null}
        </li>
      ))}
  </ul>
);

export default OrderUsers;

const indicator = () => (
  <span
    style={{
      float: "left",
      fontWeight: "800",
      fontSize: "9",
      color: "#52C41A",
      boxShadow: "0, 0, 0, 0 rgba(82, 196, 26, 1)",
      animation: "pulse 2s infinite",
      width: "6px",
      height: "6px",
      borderRadius: "50%",
      position: "relative",
      left: "-16px",
      top: "9px",
    }}
  ></span>
);
