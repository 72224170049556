import { Table } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const CriticalParts = ({}) => {
  const {
    dashboardReducer: {
      alerts: { criticalParts },
    },
  } = useSelector((state) => state);

  const columns = [
    {
      title: "Naziv",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) => (
        <div>
          <Link target={"_blank"} to={`/parts/part/${record.id}/about`}>
            {record.name}
          </Link>
        </div>
      ),
    },
    {
      title: "Šifra",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Stanje",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Kritična razina",
      dataIndex: "critical_quantity",
      key: "critical_quantity",
    },
  ];

  const data = criticalParts.map((x) => ({
    key: x.id,
    id: x.id,
    name: x.name,
    code: x.code,
    quantity: x.quantity,
    critical_quantity: x.critical_quantity,
  }));

  return (
    <div>
      <Table columns={columns} dataSource={data} />
    </div>
  );
};

export default CriticalParts;
