import React from 'react'
import { getApiEndpoint } from '../../../../../../../../axios/endpoints'

// UI
import { List, message, Popconfirm } from 'antd'
import { DeleteTwoTone, LoadingOutlined } from '@ant-design/icons'

// Redux
import { useDispatch, useSelector } from "react-redux";
import { REMOVE_DOCUMENT } from '../../../../../../../../redux/modules/Accounting/actions';

export default function Documents({ item }) {

    // Variables
    const dispatch = useDispatch()
    let endpoint = getApiEndpoint().slice(0, -1)
    const removeDocument = useSelector(state => state.accoutingReducer.removeDocument)

    // Methods
    function successCallback(){
        message.success("Uspješno ste izbrisali dokument.")
    }

    function errorCallback(){
        message.error("Došlo je do pogreške prilikom brisanja dokumenta.")
    }

    function handleDelete() {
        dispatch({
            type: REMOVE_DOCUMENT,
            id: item.id,
            source: 'account',
            successCallback,
            errorCallback
        })
    }

    return(
        <List.Item>
            <List.Item.Meta
                description={
                    // Document
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <a href={endpoint + item.path} target="_blank">
                            {item.name}
                        </a>

                        <Popconfirm
                            title="Jeste li sigurni da želite izbristi dokument?"
                            onConfirm={handleDelete}
                            okText="Izbriši"
                            cancelText="Odustani"
                        >
                            {
                                removeDocument.status === "loading" + item.id ?
                                    <LoadingOutlined /> :
                                    <DeleteTwoTone />
                            }
                        </Popconfirm>
                    </div>
            }
            />
        </List.Item>
    )
}