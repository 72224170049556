import React from "react";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import css from "../machine.module.scss";
import { Tag, Switch, Button, Popconfirm, Spin, message } from "antd";
import { LeftCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { DELETE_MACHINE } from "../../../redux/modules/Machines/actions";
import { IN_BOWL } from "../../../redux/modules/Maintenances/actions";

const MachineHeader = ({ machine, showUpdateForm, history }) => {
  // Variables
  const dispatch = useDispatch();
  const { deleteMachine } = useSelector(
    (state) => state.machineReducer
  );

  // Methods
  function errorCallback() {
    message.error("Došlo je do pogreške prilikom brisanja imovine.");
  }

  function successCallback() {
    message.success("Uspješno ste izbrisali imovinu.");
    history.push("/machines");
  }

  function handleDelete() {
    dispatch({
      type: DELETE_MACHINE,
      id: machine.data.id,
      successCallback,
      errorCallback,
    });
  }

  return (
    <>
      <div className={css.machineHeaderContainer}>
        {/* Machine Image */}
        {/* <img src="#" 
                alt="Machine Image"
                className={css.machineImage}/> */}

        {/* Info wrapper */}
        <div className={css.info}>
          <div className={css.infoFlex}>
            {/* Machine name */}
            <div
              className={"hover"}
              onClick={() => {
                history.push("/machines");
              }}
              style={{ width: "40px" }}
            >
              <LeftCircleOutlined style={{ marginTop: "8px" }} />
            </div>
            <div className={css.machineName}>{machine.data.name}</div>
            {/* Status tag */}
             
          </div>

          {/* <div className={css.flex}>
            <div className={css.switchInfo}>U službi:</div>
            <Switch defaultChecked />
          </div> */}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "20px",
            }}
          >
            <Button
              style={{ marginRight: 20 }}
              type={"primary"}
              onClick={showUpdateForm}
            >
              Uredi
            </Button>
            <Popconfirm
              id="delete_user"
              title="Jeste li sigurni da želite izbrisati imovinu?"
              placement="left"
              okText="Izbriši"
              cancelText="Odustani"
              onConfirm={handleDelete}
            >
              <Button type="danger">
                {deleteMachine.status === "loading" ? (
                  <>
                    <LoadingOutlined /> Brisanje
                  </>
                ) : (
                  "Izbriši imovinu"
                )}
              </Button>
            </Popconfirm>
     
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(MachineHeader);
