import React, { useEffect, useState } from "react";
import { useParams, Switch, Route } from "react-router-dom";

import Spinner from "../../../../components/LoadingScreen";
import SupplierForm from "../Supplier/SupplierForm";
import SizeType from "../../../../helpers/deviceTypeSize";

import { LeftCircleOutlined } from "@ant-design/icons";
import css from "../../Suppliers.module.scss";
import { Button, message, Drawer, Icon, Popconfirm } from "antd";
import "moment/locale/hr";

import { connect, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../redux/modules/Accounting/actions";
import Tabs from "./components/Tabs";
import About from "./components/About";
import Documents from "./components/Documents";

function SupplierProfile({
  history,
  /* Redux  => */ supplier,
  prepareRegistration,
  onGetSupplier,
  match,
  location,
}) {
  // Variables

  const dispatch = useDispatch();
  const { id } = useParams();
  const [manageSupplierVisible, setManageSupplierVisible] = useState(false);
  const [activeKey, setActiveKey] = useState("");

  const { deleteUser } = useSelector((state) => state.accoutingReducer);
  const { user } = useSelector((state) => state.usersReducer);

  //Methods
  useEffect(() => {
    onGetSupplier(id);
  }, []);

  // Edit user method
  function handleEditSupplier() {
    message.info(`Uredi dobavljača s ID: ${supplier.id}`);
  }

  function _setActiveKey() {
    // Define active key
    const _activeKey = location.pathname.split("/").slice(-1)[0] || "about";
    setActiveKey(_activeKey);
  }

  // Update user
  function toggleDrawer(bool) {
    if (bool) {
      setManageSupplierVisible(bool);
      return;
    }
    setManageSupplierVisible(!!bool);
  }

  if (supplier.status === "loading") {
    return <Spinner />;
  }

  function successCallback() {
    message.success(
      `Uspješno ste izbrisali korisnika.`
    );
    history.push("/suppliers");
  }

  function errorCallback() {
    message.error("Došlo je do pogreške prilikom brisanja");
  }

  return (
    <>
      <div className={css.backFlexRow}>
        <div className={css.backFlexRow}>
          {/* Back icon with hover effect */}
          <div
            onClick={() => {
              history.replace("/suppliers");
            }}
            className={[css.hoverDiv + " hover"]}
          >
            {/* Icon */}
            <LeftCircleOutlined className={css.backIcon} />
          </div>

          <div>
            {/* Username */}
            <h1 className={css.username}>{supplier.data.full_name}</h1>
            {/* User ID */}
            <h1 className={css.userID}>ID partnera: {supplier.data.id}</h1>
          </div>
        </div>

        {/* Edit Supplier button */}
        <Button
          onClick={() => toggleDrawer(true)}
          style={{ marginTop: 20 }}
          type="primary"
        >
          Uredi
        </Button>
        <Popconfirm
          id="delete_user"
          title="Jeste li sigurni da želite izbrisati korisnika?"
          placement="left"
          okText="Izbriši"
          cancelText="Odustani"
          onConfirm={() => {
            dispatch({
              type: actions.DELETE_USER,
              user_id: id,
              successCallback,
              errorCallback,
            });
          }}
        >
          <Button
            style={{ marginTop: 20, marginLeft: 20 }}
            loading={deleteUser.status === "loading"}
            type="danger"
          >
            Izbriši
          </Button>
        </Popconfirm>
      </div>

      <Drawer
        width={SizeType() === "xs" || SizeType === "sm" ? "90%" : 690}
        visible={manageSupplierVisible}
        onClose={() => toggleDrawer(false)}
        title={
          <span>
            <Icon type="snippets" theme="twoTone" /> Uredi Partnera
          </span>
        }
      >
        <SupplierForm
          categories={prepareRegistration.data.company_categories}
          supplier={supplier.data}
          update={true}
          user_id={supplier.data.id}
        />
      </Drawer>

      {/* Profile tabs */}
      <Tabs
        setActiveKey={_setActiveKey}
        activeKey={activeKey}
        match={match}
        data={supplier}
      />

      {/* Conditional route displaying based on tab key */}
      <div>
        <Switch>
          {/* About supplier component */}
          <Route
            path={`${match.path}/about`}
            render={() => <About supplier={supplier} />}
          />

          {/* Supplier documents component */}
          <Route
            path={`${match.path}/documents`}
            render={() => <Documents />}
          />
        </Switch>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    accounts: state.accoutingReducer.accounts,
    supplier: state.accoutingReducer.supplier,
    prepareRegistration: state.accoutingReducer.prepareRegistration,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetSupplier: (id) =>
      dispatch({ type: actions.GET_SUPPLIER, user_id: id }),
    onGetPrepare: () => dispatch({ type: actions.GET_PREPARE_REGISTRATION }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierProfile);
