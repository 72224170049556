import { Button, Col, Input, Row, Typography, Upload, message } from "antd";
import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_ORDER_SUCCESS } from "../../../../redux/modules/Maintenances/actions";
import { LOGOUT } from "../../../../redux/modules/Users/actions";
import { ALLOWED_EXTENSIONS } from "../../../../helpers/consts";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { technicianOfOrder } from "../Reports/components/Report/addReportForm";
import { UploadOutlined } from "@ant-design/icons";
import axios_maintenances from "../../../../axios/axios-maintenances";
import { canFinishOrder } from "../../../../helpers/functions";



const Finish = ({close, technician_id}) => {



    const order = useSelector((state) => state.maintenanceReducer.order.data);
    const user = useSelector((state) => state.usersReducer.user.data);
    const {id} = useParams();
    const [files, set_files] = useState([]);
    const [text, set_text] = useState("");
    const [loading, set_loading] = useState(false);
    const dispatch = useDispatch();
    const onRemove = (file) => {
        let index = files.findIndex((x) => x === file);
        let tmpArr = [...files];
        tmpArr.splice(index, 1);
        set_files(tmpArr);
      };




  const onFinish = async () => {

  

    set_loading(true);
    try {
      let token = await localStorage.getItem("token");
      let formData = new FormData();
      let data = { text, technician_id: technician_id || technicianOfOrder(order, user).id, schedule_id: order.schedule?.id || null, is_success: technician_id ? false : true };

      formData.append("data", JSON.stringify(data));

      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        formData.append("files", element);
      }
      let options = {
        token,
      };
      let res = await axios_maintenances(options).post(
        `work_orders/${id}/finish_work/`,
        formData
      );

      dispatch({ type: GET_ORDER_SUCCESS, data: res.data });
     

      close()
    } catch (error) {
      if (error.response && error.response.status === 401) {
        message.warning("Niste prijavljeni.");
        dispatch({ type: LOGOUT });
        return;
      }
      console.log(error);
      message.error("Dogodila se greska.");
    }
    set_loading(false);
  };
    return (
        <div>
            <Typography.Text>Izvještaj</Typography.Text>
            <Input.TextArea onChange={({ target: { value } }) => set_text(value)} />
        <div style={{ height: 21 }} />
        <Row>
            <Col span={20}>
            <Upload
          onRemove={onRemove}
          showUploadList
          fileList={files}
          multiple={true}
          accept={ALLOWED_EXTENSIONS.map((x) => x.extensions).join(",")}
          beforeUpload={(file, _files) => {
            set_files([...files, ..._files]);
            return false;
          }}
        >
          <Button>
            Ucitaj <UploadOutlined />
          </Button>
        </Upload>
            </Col>
            <Col span={4}>
            <Button onClick={onFinish} loading={loading} type="primary">
                Spremi
            </Button>
            </Col>
        </Row>
      
        </div>
    );
}

export default Finish;

