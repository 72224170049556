import React, { useState } from "react";

import { Button, PageHeader, Icon, Drawer, List, Modal, message } from "antd";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "../../redux/modules/Machines/actions";

import SizeType from "../../helpers/deviceTypeSize";

import LocationsForm from "./components/Locations/LocationsForm";
import Location from "./components/Location";
import nestedSorting from "../../helpers/NestedSorting";
import { LOCATION_TYPES } from "../../helpers/consts";

import getWarehouseLocations from "../../helpers/getWarehouseLocations";

const Locations = ({ type, asset }) => {
  const dispatch = useDispatch();

  const { prepareRegistration, locations } = useSelector(
    (state) => state.machinesReducer
  );

  const [manageSupplierVisible, set_manageSupplierVisible] = useState();

  const locations_list =
    type === LOCATION_TYPES.WARHOUSE
      ? getWarehouseLocations(
          [...prepareRegistration.data.locations],
          [asset.info.location?.id]
        )
      : [...prepareRegistration.data.locations].filter(
          (x) => x.type === LOCATION_TYPES.GEOLOCATION
        );

  const locationTree = nestedSorting(locations_list, "location_child");

  return (
    <div>
      <PageHeader
        title={
          type === LOCATION_TYPES.WARHOUSE ? "Lokacije skladišta" : "Lokacije"
        }
        extra={
          <Button
            style={{ marginBottom: 32 }}
            type="primary"
            onClick={() => set_manageSupplierVisible(!manageSupplierVisible)}
          >
            {!manageSupplierVisible ? (
              <>
                Dodaj <Icon type="plus" />
              </>
            ) : (
              "Zatvori"
            )}
          </Button>
        }
      />

      <List
        style={{ marginTop: 32 }}
        itemLayout="horizontal"
        dataSource={locationTree}
        renderItem={(item) => (
          <Location
            item={item}
            locationTree={locationTree}
            locations={locations_list}
          />
        )}
      />

      <Drawer
        width={SizeType() === "xs" || SizeType === "sm" ? "90%" : 500}
        visible={manageSupplierVisible}
        onClose={() => {
          set_manageSupplierVisible(!manageSupplierVisible);
        }}
        title={
          <span>
            <Icon type="snippets" theme="twoTone" /> Dodaj lokaciju
          </span>
        }
      >
        <LocationsForm
          locationTree={locationTree}
          type={type || LOCATION_TYPES.GEOLOCATION}
          locations={locations}
          saveLocation={(data, callback) => {
            dispatch({ type: actions.SAVE_LOCATION, data, callback });
          }}
          locations_list={locations_list}
        />
      </Drawer>
    </div>
  );
};

export default Locations;
