import React, { useEffect } from "react";

import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Spin,
  TreeSelect,
} from "antd";
import nestedSorting from "../../../../helpers/NestedSorting";
import { useDispatch, useSelector } from "react-redux";
import {
  ALL_PART_STORAGES,
  TRANSPORT_PART,
} from "../../../../redux/modules/Parts/actions";
import { newNestedSorting } from "../../../../helpers/functions";

const TransportPratForm = ({ form, part, close, part_id }) => {
  const { machines } = useSelector((state) => state.machinesReducer);
  const { transportPart, allPartStorages } = useSelector(
    (state) => state.partsReducer
  );
  const dispatch = useDispatch();

  const { getFieldDecorator, getFieldsError, validateFields } = form;

  useEffect(() => {
    dispatch({
      type: ALL_PART_STORAGES,
      errorCallback: () =>
        message.error("Dogodila se greška kod dohvata svih skladišta!"),
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (err) return;
      values.from_storage = part.storage.id;
      values.qty_uom = part.uom;
      values.part = part_id;

      dispatch({
        type: TRANSPORT_PART,
        data: values,
        successCallback: () => {
          close();
        },
        errorCallback: () =>
          message.error("Dogodila se greška kod zahtjeva za transport"),
      });
    });
  };

  return (
    <div>
      <Spin spinning={allPartStorages.status === "loading"}>
        <Form onSubmit={handleSubmit}>
          <Form.Item label="Trenutna lokacija">
            {getFieldDecorator("from_storage", {
              initialValue: part.storage.name,
            })(<Input disabled />)}
          </Form.Item>
          <Form.Item style={{ width: "100px" }} label={"Količina"}>
            {getFieldDecorator("qty", {
              rules: [{ required: true, message: "Ovo polje je obavezno!" }],
              initialValue: 1,
            })(<InputNumber style={{ width: "100px" }} placeholder={""} />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("to_storage", {
              initialValue: null,
            })(
              <TreeSelect
                allowClear={true}
                multiple={false}
                treeData={newNestedSorting(
                  allPartStorages.data.data.filter(
                    (x) => part.storage.id !== x.id
                  )
                )}
                showSearch
                optionFilterProp="name"
                filterTreeNode={(input, option) => {
                  return (
                    option.props.name
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              />
            )}
          </Form.Item>
          <Form.Item label="Napomena">
            {getFieldDecorator("note", {
              initialValue: "",
            })(<Input.TextArea />)}
          </Form.Item>
          <Form.Item>
            <Button
              disabled={hasErrors(getFieldsError())}
              htmlType="submit"
              loading={transportPart.status === "loading"}
              type="primary"
            >
              Spremi
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default Form.create()(TransportPratForm);

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}
