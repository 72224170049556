export const deploy = process.env.REACT_APP_DEPLOYMENT; // production, dev, test

export const PARTNER = process.env.REACT_APP_PARTNER;
 
export const getApiEndpoint = () => {
  if (deploy === "dev") {
    return "http://162.55.48.87:8020/";
  } else if (deploy === "production") {
    if (PARTNER === "namex") {
      return "http://fiks-namex.ddns.net:8701/";
    } else if (PARTNER === "neuros") {
      return "http://api.fiks.neuros.hr:80/";
    } else if (PARTNER === "tt_cables") {
      return "https://api.fiks.ttkabeli.eu/";
    } else if (PARTNER === "frigo") {
      return "http://fiks-frigoterm.ddns.net:8801/";
    }
  } else if (deploy === "test") {
    return "http://162.55.48.87:8020/"
  }
};

// ferfef

export const getWebSocketEndpoint = () => {
  if (deploy === "dev") {
    return "ws://162.55.48.87:9001/";
  } else if (deploy === "production") {
    if (PARTNER === "tt_cables") {
      return "wss://mqtt.fiks.ttkabeli.eu:8083/";
    }
    return "ws://162.55.48.87:9001/";
  } else if (deploy === "test") {
    return "ws://162.55.48.87:9001/"
  }
};

export const pushNotificationsBaseUrl = () => {
  if (deploy === "dev") {
    return "http://neurostestsite.ddns.net:999/";
  } else if (deploy === "production") {
    return "https://productivity-tracker-224713.firebaseapp.com/";
  }
};

export const TOPICS = {
  ORDERS_TOPIC: "${user_topic}",
  DASHBOARD_TECHNICIANS: "${PARTNER}/fiks/dashboard/technicians/data_account_id".replace(
    "${PARTNER}",
    PARTNER
  ),
  DASHBOARD_PARTS: "${PARTNER}/fiks/dashboard/parts".replace(
    "${PARTNER}",
    PARTNER
  ),
  DASHBOARD_NOTIFICATIONS: "${PARTNER}/fiks/dashboard/notifications/data_account_id".replace(
    "${PARTNER}",
    PARTNER
  ),
};
