import React, { useEffect, useState } from "react";
import { Button, message, Spin, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  HISTORY_PART_SPENT,
  REMOVE_PART_SPENT,
} from "../../../../../redux/modules/Parts/actions";
import { LIMIT_FOR_LOAD_MORE } from "../../../../../helpers/consts";
import LoadMoreButton from "../../../../../components/LoadMoreButton";
import moment from "moment";
import { useParams } from "react-router-dom";

export default function Consumption() {
  const { id } = useParams();

  const { removePartSpent, historyPartSpent } = useSelector(
    (state) => state.partsReducer
  );

  const COLUMNS = [
    {
      title: "Kreirano",
      dataIndex: "created_at",
      render: (text, value) => (
        <span>{moment(text).format("YYYY-MM-DD HH:mm")}</span>
      ),
    },
    {
      title: "Kreirao",
      dataIndex: "created_by",
      render: (text, value) => value.created_by.name,
    },
    {
      title: "Količina",
      dataIndex: "qty",
      render: (text, value) => <span>{text}</span>,
    },
    {
      title: "Cijena",
      dataIndex: "total_price",
    },
    {
      title: "Sa lokacije",
      dataIndex: "storage",
      render: (text, value) => value.storage?.name,
    },
    {
      title: "",
      dataIndex: "action",
      render: (text, value) => (
        <Button
          type="danger"
          loading={removePartSpent.status === "loading" + value.id}
          onClick={() => removeSpend(value.id)}
        >
          Poništi
        </Button>
      ),
    },
  ];

  const dispatch = useDispatch();

  const getSpendPartHistoryFun = (next, restparams = {}) => {
    dispatch({
      type: HISTORY_PART_SPENT,
      limit: LIMIT_FOR_LOAD_MORE,
      id,
      queryParams: {
        ...restparams,
        order_by: "id",
        sort: "desc",
      },
      dataToMerge: next === true ? [] : historyPartSpent.data.data,
      next: next === true ? null : next,
      errorCallback: () => message.error("Dogodila se greška kod dohvata"),
    });
  };

  useEffect(() => {
    if (historyPartSpent.data.cursor.next) {
      getSpendPartHistoryFun(historyPartSpent.data.cursor.next);
    }
  }, []);

  // Variables

  let dataSource = [];

  // Methods

  const removeSpend = (id) => {
    dispatch({
      type: REMOVE_PART_SPENT,
      data: { id },
      id,
      errorCallback: () => message.error("Dogodila se greška na uklanjanju."),
    });
  };

  return (
    <>
      <Spin spinning={historyPartSpent.status === "loading"}>
        <Table
          pagination={false}
          columns={COLUMNS}
          dataSource={historyPartSpent.data.data}
        />
      </Spin>
      {historyPartSpent.data.cursor.next && (
        <LoadMoreButton
          loading={historyPartSpent.status === "loading"}
          onClick={() => {
            getSpendPartHistoryFun(historyPartSpent.data.cursornext);
          }}
        />
      )}
    </>
  );
}
